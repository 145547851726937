import React, { Component } from 'react'
import Styles from './WorkflowStatus.module.css'

import Axios from '../../components/Axios'
class WorkflowStatusDetailBlock extends Component {
    constructor(props) {
        super(props);
        this.state = {
          
        };
    }
   
   
    render() {

        
        return (   
          

            <div className={this.props.odd ? [Styles.detailblockrow, Styles.odd].join(" ") : [Styles.detailblockrow]}>

                <div className={Styles.detailblocktitle}>{this.props.title}</div><div className={Styles.detailblocktext}>{this.props.value}</div>

                </div>



        )
    }

}
export default WorkflowStatusDetailBlock;







