import React, { Component } from 'react';
import styles from '../css/Details_Information.module.css';
import Toggle from '../../../globalcomponents/Toggle';
import DegreeLookUp from '../../../globalcomponents/DegreeLookUp';
import SpecialtyLookUp from '../../../globalcomponents/SpecialtyLookUp';
import NotificationBox from '../../../globalcomponents/NotificationBox';
import firstCapital from '../../../helper/firstCapital';
import hcp from '../../../lookup/hcp.json';

class Details_Information extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationStatus: false,
      errorStatus: false,
      notificationType: '',
      errorByID: null,
    };
  }

  updateError(status, type, id) {
    let message = `You cannot ${!status ? 'add' : 'remove'} this professional from your ${type}.`;
    this.setState({
      notificationMessage: message,
      notificationStatus: true,
      errorStatus: true,
      notificationType: type,
      errorByID: id,
    });
  }

  updateNotification(status, type) {
    let message = `You switched ${type} to ${!status ? 'ON' : 'OFF'} for this professional.`;
    if (type === 'BOTH') {
      message = `You switched CRM and Spend to ON for this professional.`;
    }
    this.setState({
      notificationMessage: message,
      notificationStatus: true,
      errorStatus: false,
      notificationType: type,
    });
  }

  updateNotificationStatus() {
    this.setState({
      notificationStatus: false,
      notificationType: '',
      errorByID: null,
    });
  }

  secondaryAddresses() {

      let isSameTeam = false;

      //if (this.props.user.role == 'ADMIN' || this.props.data[`TERR_TM${this.props.user.team}`] != null ) { isSameTeam = true}

      if (this.props.addresses[2]) {
      let hasOne = false;
      let temp = [];


      for (let i = 0; i < this.props.addresses[2].length; i++) {

        //  if ((this.props.addresses[2][i].SECONDARY_ADDRESS1 !== this.props.addresses[0][0].PRI_ADDRESS1) && ((this.props.addresses[2][i].SECONDARY_ADDRESS2 !== "") && (this.props.addresses[2][i].SECONDARY_ADDRESS2 !== this.props.addresses[0][0].PRI_ADDRESS2))) {

              temp.push(
                  <div key={i} className={[styles.Content, styles.FullContent].join(' ')}>
                      { !hasOne ? (
                          <div className={styles.ContentTitle}>SECONDARY ADDRESS</div>
                      ) : (
                          ''
                      )}
             
              <div>
                {`${firstCapital(this.props.addresses[2][i].SECONDARY_ADDRESS1)} ${firstCapital(
                  this.props.addresses[2][i].SECONDARY_ADDRESS2
                )}, ${firstCapital(this.props.addresses[2][i].SECONDARY_CITY)}, ${this.props.addresses[2][i].SECONDARY_STATE} ${
                  this.props.addresses[2][i].SECONDARY_ZIP5
                }`}
                {this.props.addresses[2][i].SECONDARY_ADDRESS1 === this.props.addresses[1][0].BEST_ADDRESS1 ? (
                  <i className={[styles.best, 'fas', 'fa-check-circle'].join(' ')}></i>
                ) : (
                  ''
                )}
              </div>
            </div>
          );

          hasOne = true;
        //}
      }
      return temp;
    }
  }

  render() {
    let meView = hcp.details.me.view;
    let meEdit = hcp.details.me.edit;
 
      let add1 = '';
      let add2 = '';
      let city = '';
      let state = '';
      let zip = '';
     
      if (this.props.user.role.includes('DEVELOPMENT OPERATIONS') && this.props.user.role.includes('CTMS')) {
         
          add1 = this.props.ctmsAddress[0].CTMS_ADDRESS1;
          add2 = this.props.ctmsAddress[0].CTMS_ADDRESS2;
          city = this.props.ctmsAddress[0].CTMS_CITY;
          state = this.props.ctmsAddress[0].CTMS_STATE;
          zip = this.props.ctmsAddress[0].CTMS_ZIP5;
          
         
      } else {
          add1 = this.props.addresses[0][0].PRI_ADDRESS1;
          add2 = this.props.addresses[0][0].PRI_ADDRESS2;
          city = this.props.addresses[0][0].PRI_CITY;
          state = this.props.addresses[0][0].PRI_STATE;
          zip = this.props.addresses[0][0].PRI_ZIP5;
      }

      console.log('details_info refresh');

    let me =
       ( this.props.user.role.includes('ADMIN') || this.props.user.role.includes('COMMERCIAL DERM') || this.props.user.isDermAdmin == 'true' ||
      meView.teams.includes(this.props.user.team) ||
      meEdit.teams.includes(this.props.user.team) ||
      meView.roles.includes(this.props.user.role) ||
            meEdit.roles.includes(this.props.user.role)) && !this.props.user.role.includes('PHARMFORCE') ? (
        <div className={styles.Content}>
          <div className={styles.ContentTitle}>ME</div>
          <div>{this.props.data.MENUM ? this.props.data.MENUM : '—'}</div>
        </div>
      ) : (
        ''
      );
    let addressView = hcp.details.address.view;
    let addressEdit = hcp.details.address.edit;
    let address =
        this.props.user.role.includes('ADMIN') ||
            this.props.user.role.includes('PRT') ||
            this.props.user.role.includes('COMMERCIAL DERM') || this.props.user.isDermAdmin == 'true' ||
      addressView.teams.includes(this.props.user.team) ||
      addressEdit.teams.includes(this.props.user.team) ||
      addressView.roles.includes(this.props.user.role) ||
      addressEdit.roles.includes(this.props.user.role) ? (
        <div className={styles.MiniSection}>
          <div className={styles.TitleBar}>
            <div>ADDRESS</div>
                        {(this.props.user.role.includes('ADMIN') || 
            addressEdit.teams.includes(this.props.user.team) ||
                            addressEdit.roles.includes(this.props.user.role)) && !this.props.user.role.includes('PHARMFORCE') ? (
              <i
                className='far fa-edit'
                onClick={() => {
                  this.props.editScreen(4);
                }}></i>
            ) : (
              ''
            )}
          </div>
          <div className={[styles.Wrapper, styles.Column].join(' ')}>
            <div className={[styles.Content, styles.FullContent].join(' ')}>
              <div className={styles.ContentTitle}>
                <div className={styles.bestwrap}>
                  PRIMARY ADDRESS{' '}
                                    {add1 && add1.length !== 0 ? (
                                        add1 === this.props.addresses[1][0].BEST_ADDRESS1 ? (
                      <div className={styles.best}>
                        <i className='fas fa-check-circle'></i> BEST ADDRESS
                      </div>
                    ) : (
                      ''
                    )
                  ) : (
                    ''
                  )}
                </div>
              </div>
              {this.props.addresses[0] && this.props.addresses[0].length !== 0 ? (
                <div>
                  {`${firstCapital(add1)} ${firstCapital(add2
                  )}, ${firstCapital(city)}, ${state} ${
                    zip
                  }`}
                </div>
              ) : (
                <div>—</div>
              )}
              {this.secondaryAddresses()}
            </div>
          </div>
        </div>
      ) : (
        ''
      );
    let additionalView = hcp.details.additional.view;
      let additionalEdit = hcp.details.additional.edit;

      
      let editAdditional =
          this.props.user.role.includes('ADMIN') || this.props.user.role.includes('COMPLIANCE') ||
              additionalEdit.teams.includes(this.props.user.team) ||
              additionalEdit.roles.includes(this.props.user.role) ? (
                  <i
                      className='far fa-edit'
                      onClick={() => {
                       
                          this.props.editScreen(6);
                      }}></i>
              ) : (
                  ''
              );

    let complianceSpecialtyView = hcp.details.complianceSpecialty.view;
    let additional =
        this.props.user.role.includes('PRT') || this.props.user.role.includes('COMMERCIAL DERM') || this.props.user.isDermAdmin == 'true' ||this.props.user.role.includes('ADMIN') ||
      additionalView.teams.includes(this.props.user.team) ||
      additionalEdit.teams.includes(this.props.user.team) ||
      additionalView.roles.includes(this.props.user.role) ||
            additionalEdit.roles.includes(this.props.user.role) 
        ? (
        <div className={styles.MiniSection}>
          <div className={styles.TitleBar}>
                        <div>ADDITIONAL INFO</div>
                        {editAdditional}
          </div>
          <div className={styles.Wrapper}>
                        <div className={styles.Content}>
              <div className={styles.ContentTitle}>PDRP OPT OUT</div>
              <div>{this.props.data.PDRP_OPTOUT ? this.props.data.PDRP_OPTOUT : '—'}</div>
            </div>
            <div className={styles.Content}>
              <div className={styles.ContentTitle}>NO CONTACT</div>
              <div>{this.props.data.NO_CONTACT ? this.props.data.NO_CONTACT : '—'}</div>
            </div>
                        {this.props.user.role.includes('ADMIN') || this.props.user.role.includes('COMPLIANCE') ||
            complianceSpecialtyView.teams.includes(this.props.user.team) ||
            complianceSpecialtyView.roles.includes(this.props.user.role) ? (
              <div className={styles.Content}>
                <div className={styles.ContentTitle}>COMPLIANCE SPECIALTY</div>
                <div>{this.props.data.TAXONOMY_CODE ? this.props.data.TAXONOMY_CODE : '—'}</div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      ) : (
        ''
              );
     
    let mslView = hcp.details.msl.view;
    let mslEdit = hcp.details.msl.edit;

      let mslGoalsArray = [];
      mslGoalsArray.push(this.props.msl.SI1 ? '1' : null);
      mslGoalsArray.push(this.props.msl.SI2 ? '2' : null);
      mslGoalsArray.push(this.props.msl.SI3 ? '3' : null);
      mslGoalsArray.push(this.props.msl.SI4 ? '4' : null);
      mslGoalsArray.push(this.props.msl.SI5 ? '5' : null);
      mslGoalsArray.push(this.props.msl.SI6 ? '6' : null);
      mslGoalsArray.push(this.props.msl.SI7 ? '7' : null);
      mslGoalsArray.push(this.props.msl.SI8 ? '8' : null);
      mslGoalsArray.push(this.props.msl.SI9 ? '9' : null);
      mslGoalsArray.push(this.props.msl.SI10 ? '10' : null);
      mslGoalsArray.push(this.props.msl.SI11 ? '11' : null);
      mslGoalsArray.push(this.props.msl.SI12 ? '12' : null);
      mslGoalsArray = mslGoalsArray.filter(function (goal) {
          return goal != null;
      });
     // console.log(this.props.msl);
    let msl =
      this.props.user.role.includes('ADMIN') ||
      mslView.teams.includes(this.props.user.team) ||
      mslEdit.teams.includes(this.props.user.team) ||
      mslView.roles.includes(this.props.user.role) ||
      mslEdit.roles.includes(this.props.user.role) ? (
        <div className={styles.MiniSection}>
          <div className={styles.TitleBar}>
            <div>MSL INFO</div>
            {mslEdit.teams.includes(this.props.user.team) || mslEdit.roles.includes(this.props.user.role) ? (
              <i
                className='far fa-edit'
                onClick={() => {
                  this.props.editScreen(10);
                }}></i>
            ) : (
              ''
            )}
          </div>
          <div className={styles.Wrapper}>
            <div className={styles.Content}>
              <div className={styles.ContentTitle}>REGIONAL PLAN</div>
              <div>{this.props.msl.REGIONAL_PLAN_CURR ? this.props.msl.REGIONAL_PLAN_CURR : 'N'}</div>
            </div>
            <div className={styles.Content}>
              <div className={styles.ContentTitle}>GOAL</div>
                            <div>
                                {mslGoalsArray.length > 0 ? mslGoalsArray.join(", ") : '—'}
                            </div>
            </div>
            <div className={styles.Content}>
              <div className={styles.ContentTitle}>PRIMARY FOCUS AREA</div>
              <div>{this.props.msl.FOCUS_AREA_PRIMARY ? this.props.msl.FOCUS_AREA_PRIMARY : '—'}</div>
            </div>
            <div className={styles.Content}>
              <div className={styles.ContentTitle}>PRIMARY FOCUS TIER</div>
              <div>{this.props.msl.FOCUS_AREA_PRIMARY_TIER ? this.props.msl.FOCUS_AREA_PRIMARY_TIER : '—'}</div>
            </div>
            <div className={styles.Content}>
              <div className={styles.ContentTitle}>SECONDARY FOCUS AREA</div>
              <div>{this.props.msl.FOCUS_AREA_SECONDARY ? this.props.msl.FOCUS_AREA_SECONDARY : '—'}</div>
            </div>
            <div className={styles.Content}>
              <div className={styles.ContentTitle}>SECONDARY FOCUS TIER</div>
              <div>{this.props.msl.FOCUS_AREA_SECONDARY_TIER ? this.props.msl.FOCUS_AREA_SECONDARY_TIER : '—'}</div>
            </div>
            <div className={styles.Content}>
              <div className={styles.ContentTitle}>OTHER FOCUS AREA</div>
                    <div>
                        {
                            this.props.msl.FOCUS_AREA_OTHER ? this.props.msl.FOCUS_AREA_OTHER :
                            this.props.msl.FOCUS_AREA_TERTIARY ? this.props.msl.FOCUS_AREA_TERTIARY : '—'
                        }
                    </div>
            </div>
            <div className={styles.Content}>
              <div className={styles.ContentTitle}>OTHER FOCUS TIER</div>
                    <div>
                        {
                            this.props.msl.FOCUS_AREA_OTHER_TIER ? this.props.msl.FOCUS_AREA_OTHER_TIER :
                                this.props.msl.FOCUS_AREA_TERTIARY_TIER ? this.props.msl.FOCUS_AREA_TERTIARY_TIER : '—'
                        }
                    </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      );
    let hemView = hcp.details.hem.view;
    let hemEdit = hcp.details.hem.edit;
    let hem =
      this.props.user.role.includes('ADMIN') ||
      hemView.teams.includes(this.props.user.team) ||
      hemEdit.teams.includes(this.props.user.team) ||
      hemView.roles.includes(this.props.user.role) ||
      hemEdit.roles.includes(this.props.user.role) ? (
        <div className={styles.MiniSection}>
          <div className={styles.TitleBar}>
            <div>HEM INFO</div>
          </div>
          <div className={styles.Wrapper}>
            <div className={styles.Content}>
              <div className={styles.ContentTitle}>TARGET TYPE</div>
              <div>
                {this.props.data[`TARGET_TYPE_TM${this.props.user.team}`] ? this.props.data[`TARGET_TYPE_TM${this.props.user.team}`] : '—'}
              </div>
            </div>
            <div className={styles.Content}>
              <div className={styles.ContentTitle}>TARGET</div>
              <div>{this.props.data[`TARGET_FLAG_TM${this.props.user.team}`] ? 'Yes' : 'No'}</div>
            </div>
          </div>
        </div>
      ) : (
        ''
      );
    let specialtyView = hcp.details.specialty.view;
    let specialtyEdit = hcp.details.specialty.edit;
      let specialty = this.props.user.role.includes('PRT') ||
          this.props.user.role.includes('COMMERCIAL DERM') || this.props.user.isDermAdmin == 'true' ||
      (this.props.user.role.includes('ADMIN') ||
      specialtyView.teams.includes(this.props.user.team) ||
            specialtyView.roles.includes(this.props.user.role))
      && !this.props.user.role.includes('PRT') && !this.props.user.role.includes('COMMERCIAL DERM') ? (
        <div className={styles.MiniSection}>
          <div className={styles.TitleBar}>
            <div>SPECIALTY</div>
            {this.props.user.role.includes('ADMIN') ||
            specialtyEdit.teams.includes(this.props.user.team) ||
            specialtyEdit.roles.includes(this.props.user.role) ? (
              <i
                className='far fa-edit'
                onClick={() => {
                  this.props.editScreen(2);
                }}></i>
            ) : (
              ''
            )}
          </div>
          <div className={[styles.Wrapper, styles.Column].join(' ')}>
            <div className={[styles.Content, styles.FullContent].join(' ')}>
              <div className={styles.ContentTitle}>SPECIALTY</div>
              <div>
                {this.props.data.SPECIALTY
                  ? this.props.data.SPECIALTY + ' - ' + firstCapital(SpecialtyLookUp[this.props.data.SPECIALTY])
                  : '—'}
              </div>
            </div>
            <div className={[styles.Content, styles.FullContent].join(' ')}>
              <div className={styles.ContentTitle}>SECONDARY SPECIALTY</div>
              <div>
                {this.props.data.OTHER_SPECIALTY
                  ? this.props.data.OTHER_SPECIALTY + ' - ' + firstCapital(SpecialtyLookUp[this.props.data.OTHER_SPECIALTY])
                  : '—'}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      );

    let lymView = hcp.details.lym.view;
    let lymEdit = hcp.details.lym.edit;
    let lym =
        (lymView.roles.includes(this.props.user.role) || lymEdit.roles.includes(this.props.user.role) &&
        (lymView.teams.includes(this.props.user.team) || lymEdit.teams.includes(this.props.user.team))) || this.props.user.role.includes("ADMIN") ? (
        <div className={styles.MiniSection}>
          <div className={styles.TitleBar}>
            <div>LYM INFO</div>
          </div>
          <div className={styles.Wrapper}>
            <div className={styles.Content}>
              <div className={styles.ContentTitle}>TARGET TYPE</div>
              <div>{this.props.data.TARGET_TYPE_TM21 ? this.props.data.TARGET_TYPE_TM21 : '—'}</div>
            </div>
            <div className={styles.Content}>
              <div className={styles.ContentTitle}>TARGET</div>
              <div>{this.props.data.TARGET_FLAG_TM21 ? this.props.data.TARGET_FLAG_TM21 : 'N'}</div>
            </div>
            <div className={styles.Content}>
              <div className={styles.ContentTitle}>ACADEMIC</div>
              <div>—</div>
            </div>
            <div className={styles.Content}>
              <div className={styles.ContentTitle}>COMMUNITY</div>
              <div>—</div>
            </div>
          </div>
        </div>
      ) : (
        ''
      );

      let dermView = hcp.details.derm.view;
      let dermEdit = hcp.details.derm.edit;
      let derm =
          this.props.user.role.includes("PRT") || this.props.user.role.includes("COMMERCIAL DERM")  ||this.props.user.isDermAdmin == 'true' || dermView.teams.includes(this.props.user.team) ||
              dermEdit.teams.includes(this.props.user.team) ||
              dermView.roles.includes(this.props.user.role) ||
              dermEdit.roles.includes(this.props.user.role) ? (
                  <div className={styles.MiniSection}>
                      <div className={styles.TitleBar}>
                          <div>DERM INFO</div>
                      </div>
                      <div className={styles.Wrapper}>
                          <div className={styles.Content}>
                              <div className={styles.ContentTitle}>TARGET TYPE</div>
                              <div>{this.props.data.TARGET_TYPE_TM31 ? this.props.data.TARGET_TYPE_TM31 : '—'}</div>
                          </div>
                          <div className={styles.Content}>
                              <div className={styles.ContentTitle}>TARGET</div>
                              <div>{this.props.data.TARGET_FLAG_TM31 ? this.props.data.TARGET_FLAG_TM31 : 'N'}</div>
                          </div>
                      </div>
                  </div>
              ) : (
                  ''
              );

    let hashes = this.props.data;
    let id = this.props.data.PROF_ID;

    let aggspendActive = hashes.spend || this.props.data.AGGSPEND_FLAG === 'Y';
    let aggspendToggle = (
      <Toggle
        active={aggspendActive}
        click={() => {
          this.props.addTo(this.props.number, 0, aggspendActive);
          this.updateNotification(aggspendActive, 'Spend');
        }}
        disabled={aggspendActive}
        errorCallback={() => {
          this.updateError(aggspendActive, 'Spend', id);
        }}
        error={this.state.errorByID === id && this.state.notificationType === 'Spend'}
      />
    );

      let isAdmin = ['ADMIN'].includes(this.props.user.role)
      let isPRT = ['PRT'].includes(this.props.user.role)
      let isComDerm = ['COMMERCIAL DERM'].includes(this.props.user.role)
    let regex = new RegExp(`^${this.props.user.terr}`, 'g')
    //let matchesTerritory = (this.props.user.team === '51') || (this.props.data.TERR_ID.match(regex))
      let matchesTerritory = false


    let overRide = this.props.data.MDM_OVERRIDE !== 'X';
      if (overRide) {
          matchesTerritory = (["51", "53"].includes(this.props.user.team)) || (this.props.data.TERR_ID && this.props.data.TERR_ID.match(regex))
      } else {
          matchesTerritory = ["51", "53"].includes(this.props.user.team) ||
              (this.props.data.TERR_ID && (this.props.data.TERR_ID.match(regex) || (this.props.data.MDM_TERR_ID && this.props.data.MDM_TERR_ID.match(regex))))
      }


      let universeFlag = this.props.data.UNIVERSE_FLAG === 'Y';
      let teamUniverseFlag = this.props.data[`UNIVERSE_FLAG_TM${this.props.user.team}`] === 'Y';
      let isDisabled = !isAdmin && !matchesTerritory && !isPRT && !isComDerm;
      let isGlobal = !isAdmin && !matchesTerritory && teamUniverseFlag && overRide;



      ////T12 - 11 alignment hack
      //let regexTemp = new RegExp(`^${11}`, 'g')
      //if (this.props.user.team == "12" && isDisabled && teamUniverseFlag && this.props.data.TERR_ID.match(regexTemp)) {
      //    isDisabled = false;
      //}

      let crmActive = false;
      if (this.props.user.isDermAdmin == 'true' || this.props.user.role == 'COMMERCIAL DERM' || this.props.user.role == 'PRT') {
          //crmActive = (hashes.crm !== undefined) ? (hashes.crm[id] !== undefined ? hashes.crm[id] : teamUniverseFlag) : teamUniverseFlag;
          crmActive = hashes.crm !== undefined ? hashes.crm : teamUniverseFlag;
         // isGlobal = !teamUniverseFlag; 
      } else {
          //crmActive = hashes.crm[id] !== undefined ? hashes.crm[id] : overRide && ((isAdmin && universeFlag) || teamUniverseFlag);
          crmActive = hashes.crm !== undefined ? hashes.crm : overRide && ((isAdmin && universeFlag) || teamUniverseFlag);
      }
    let crmToggle = (
      <Toggle
        active={crmActive}
        click={() => {
          this.props.addTo(this.props.number, 1, crmActive, !aggspendActive ? true : false);
          this.updateNotification(crmActive, !aggspendActive ? 'BOTH' : 'CRM');
            }}
            overRide={!overRide}
        disabled={isDisabled}
        global={isGlobal}
        errorCallback={() => {
          this.updateError(crmActive, 'CRM', id);
        }}
        error={this.state.errorByID === id && this.state.notificationType === 'CRM'}
      />
    );

    let ctmsActive = hashes.ctms !== undefined ? hashes.ctms : this.props.data.CTMS_FLAG === 'Y';
    let ctmsToggle = (
        <Toggle
            overRide={!overRide}
        active={ctmsActive}
        click={() => {
          this.props.addTo(this.props.number, 2, ctmsActive);
          this.updateNotification(ctmsActive, 'CTMS');
        }}
      />
    );

    let crmView = hcp.details.crm.view;
    let spendView = hcp.details.spend.view;
    let ctmsView = hcp.details.ctms.view;
    let universe = (
      <div className={styles.MiniSection}>
        <div className={styles.TitleBar}>
          <div>UNIVERSE</div>
            </div>

        <div className={styles.WrapperToggle}>
                {(this.props.user.role.includes('ADMIN') ||
                    this.props.user.role.includes("PRT")  ||
          crmView.roles.includes('ALL') ||
          crmView.teams.includes(this.props.user.team) ||
                    crmView.roles.includes(this.props.user.role)) && !this.props.user.role.includes('PHARMFORCE') ? (
            <div className={styles.Content}>
              <div className={styles.ContentTitle}>CRM</div>
              <div className={styles.Toggle}>
                                {crmToggle}
                <div className={styles.ErrorMessage}>{this.state.CRMErrorMessage}</div>
              </div>
            </div>
          ) : (
            ''
          )}
                {this.props.user.role.includes('ADMIN') ||
                    this.props.user.role.includes("PRT") || this.props.user.role.includes("COMMERCIAL DERM") || this.props.user.isDermAdmin == 'true' ||
          spendView.roles.includes('ALL') ||
          spendView.teams.includes(this.props.user.team) ||
          spendView.roles.includes(this.props.user.role) ? (
            <div className={styles.Content}>
              <div className={styles.ContentTitle}>SPEND</div>
              <div className={styles.Toggle}>
                {aggspendToggle}
                <div className={styles.ErrorMessage}>{this.state.SpendErrorMessage}</div>
              </div>
            </div>
          ) : (
            ''
          )}
          {this.props.user.role.includes('ADMIN') ||
          ctmsView.roles.includes('ALL') ||
          ctmsView.teams.includes(this.props.user.team) ||
          ctmsView.roles.includes(this.props.user.role) ? (
            <div className={styles.Content}>
              <div className={styles.ContentTitle}>CTMS</div>
              <div className={styles.Toggle}>{ctmsToggle}</div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    );

    let ctmsEmailView = hcp.details.ctmsEmail.view;
    let ctmsEmailEdit = hcp.details.ctmsEmail.edit;
    let ctmsEmail =
      this.props.user.role.includes('ADMIN') ||
      ctmsEmailView.teams.includes(this.props.user.team) ||
      ctmsEmailEdit.teams.includes(this.props.user.team) ||
      ctmsEmailView.roles.includes(this.props.user.role) ||
      ctmsEmailEdit.roles.includes(this.props.user.role) ? (
        <div className={[styles.Content, styles.FullContent].join(' ')}>
          <div className={styles.ContentTitle}>CTMS EMAIL</div>
          <div>{this.props.data.CTMS_EMAIL ? this.props.data.CTMS_EMAIL : '—'}</div>
        </div>
      ) : (
        ''
      );

    let email = (
      <div className={[styles.Content, styles.FullContent].join(' ')}>
        <div className={styles.ContentTitle}>EMAIL</div>
        <div>{this.props.data.EMAIL ? this.props.data.EMAIL : '—'}</div>
      </div>
    );
    let editDetailsEdit = hcp.details.editDetails.edit;
    let editDetails =
        this.props.user.role.includes('ADMIN') || this.props.user.role.includes('PRT') ||
            this.props.user.role.includes('COMMERCIAL DERM') ||
            this.props.user.role.includes('PHARMFORCE')|| this.props.user.isDermAdmin == 'true' ||
      editDetailsEdit.teams.includes(this.props.user.team) ||
      editDetailsEdit.roles.includes(this.props.user.role) ? (
        <i
          className='far fa-edit'
          onClick={() => {
            this.props.editScreen(1);
          }}></i>
      ) : (
        ''
      );

      let twitterView = hcp.details.twitter.view;
      let twitterEdit = hcp.details.twitter.edit;
      let twitter =
          this.props.user.role.includes('ADMIN') ||
              twitterView.teams.includes(this.props.user.team) ||
              twitterEdit.teams.includes(this.props.user.team) ||
              twitterView.roles.includes(this.props.user.role) ||
              twitterEdit.roles.includes(this.props.user.role) ? (
                  <div className={[styles.Content, styles.FullContent].join(' ')}>
                      <div className={styles.ContentTitle}><i className="fab fa-twitter"></i> &nbsp; TWITTER</div>
                      <div>{this.props.data.TWITTER ? this.props.data.TWITTER : '—'}</div>
                  </div>
        ) : (
            ''
        );

    let editContactEdit = hcp.details.editContact.edit;
    let editContact =
      this.props.user.role.includes('ADMIN') ||
      editContactEdit.teams.includes(this.props.user.team) ||
      editContactEdit.roles.includes(this.props.user.role) ? (
        <i
          className='far fa-edit'
          onClick={() => {
            this.props.editScreen(5);
          }}></i>
      ) : (
        ''
      );

    let notification = this.state.notificationStatus ? (
      <NotificationBox
        message={this.state.notificationMessage}
        callback={this.updateNotificationStatus.bind(this)}
        errorStatus={this.state.errorStatus}
      />
    ) : (
      ''
          );

      if (this.props.user.role.includes('PHARMFORCE')) {
          additional = ''
          crmToggle = ''
          derm = ''
      }
    return (
      <div className={styles.Information}>
        <div className={styles.MiniSection}>
          <div className={styles.TitleBar}>
            <div>PERSONAL</div>
            {editDetails}
          </div>
          <div className={styles.Wrapper}>
            <div className={styles.Name}>{`${this.props.data.FIRST_NAME} ${
              this.props.data.MIDDLE_NAME ? this.props.data.MIDDLE_NAME : ''
            } ${this.props.data.LAST_NAME}`}</div>
            <div className={styles.Content}>
              <div className={styles.ContentTitle}>STATUS</div>
                        <div>{
                            !this.props.data.STATUS ? 'Active' :
                            this.props.data.STATUS === 'Active' ? 'Active' :
                            this.props.data.STATUS === 'DEAD' ? 'Deceased' :
                            this.props.data.STATUS === 'RETIRED' ? 'Retired' : 'Inactive'}
                        </div>
            </div>
            <div className={styles.Content}>
              <div className={styles.ContentTitle}>DEGREE</div>
              <div>{this.props.data.DEGREE ? this.props.data.DEGREE + ' - ' + DegreeLookUp[this.props.data.DEGREE] : '—'}</div>
            </div>
            {/*<div className={styles.Content}>*/}
            {/*  <div className={styles.ContentTitle}>BIRTH YEAR</div>*/}
            {/*  <div>{this.props.data.BIRTHYEAR ? this.props.data.BIRTHYEAR : '—'}</div>*/}
            {/*</div>*/}
            <div className={styles.Content}>
              <div className={styles.ContentTitle}>GENDER</div>
              <div>{this.props.data.GENDER ? this.props.data.GENDER : '—'}</div>
            </div>
          </div>
        </div>
        {specialty}
        <div className={styles.MiniSection}>
          <div className={styles.TitleBar}>
            <div>IDENTIFIERS</div>
          </div>
          <div className={styles.Wrapper}>
            <div className={styles.Content}>
              <div className={styles.ContentTitle}>PROF ID</div>
              <div>{this.props.data.PROF_ID ? this.props.data.PROF_ID : '—'}</div>
            </div>
            <div className={styles.Content}>
              <div className={styles.ContentTitle}>NPI</div>
              <div>{this.props.data.NPINUM ? this.props.data.NPINUM : '—'}</div>
            </div>
            <div className={styles.Content}>
              <div className={styles.ContentTitle}>DEA</div>
              <div>{this.props.data.DEANUM ? this.props.data.DEANUM : '—'}</div>
            </div>
      
          </div>
        </div>
        {universe}
        {address}
        <div className={styles.MiniSection}>
          <div className={styles.TitleBar}>
            <div>CONTACT INFO</div>
            {editContact}
          </div>
          <div className={[styles.Wrapper, styles.Column].join(' ')}>
            {email}
            {ctmsEmail}
            {twitter}
            <div className={[styles.Content, styles.FullContent].join(' ')}>
              <div className={styles.ContentTitle}>MOBILE</div>
              <div>{this.props.data.MOBILE ? this.props.data.MOBILE : '—'}</div>
            </div>
            <div className={[styles.Content, styles.FullContent].join(' ')}>
              <div className={styles.ContentTitle}>WEBSITE</div>
              <div>{this.props.data.WEBSITE ? this.props.data.WEBSITE : '—'}</div>
            </div>
          </div>
        </div>
            {msl}
            {hem}
            {lym}
            {derm}
            {notification}
            {additional}
      </div>
    );
  }
}

export default Details_Information;
