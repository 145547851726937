import React, {useState} from 'react'
import {Link} from "react-router-dom";
import styles from '../css/Result.module.css'
import ChangeMoveTypeDrop from './ChangeMoveTypeDrop'
import Axios from '../../../../components/Axios'

function Result(props) {

    const [custom, setCustom] = useState(props.data)

    let name = null
    name = `${props.data.FIRST_NAME}${props.data.MIDDLE_NAME ? " " + props.data.MIDDLE_NAME : ""} ${props.data.LAST_NAME}`

    const updateCustomColumn = (temp) => {
        Axios(`/api/targeting/update-custom-columns`, temp, (data) => {
            for (let i = 0; i < data.length; i++) {
                let customColumns = JSON.parse(data[i].CUSTOM_COLUMNS)
                data[i] = {
                    ...data[i],
                    ...customColumns
                }
            }
        })
    }

    let cols = []
    for (let i = 0; i < Object.keys(props.config).length; i++) {
        if (Object.values(props.config)[i].show) {
            let value = Object.values(props.config)[i]
            if (value.title === "HEALTHCARE PROFESSIONAL") {
                cols.push(
                    <td key={value.title} className={[styles.col, styles.left, props.selected === i ? styles.selected : ""].join(" ")} style={{width: window.innerWidth * (value.flex / 100)}}>
                        <div className={styles.info}>
                            {custom.recordConflicts ? <i className={[styles.icon, "fas", "fa-exclamation-circle"].join(" ")}></i>
                            : <div className={styles.placeholder}></div>}
                            {props.preview
                                ?
                                <div className={styles.name}>
                                    {name}
                                </div>
                                :
                                <Link to={{
                                    pathname: `/hcp/details/${props.data.PROF_ID}`,
                                    state: {
                                        from: props.location.pathname
                                    }
                                }}>
                                    <div className={styles.name}>
                                        {name}
                                    </div>
                                </Link>
                            }
                            <div className={styles.placeholder}></div>
                        </div>
                    </td>
                )
            }
            else if (value.col === "CURRENT_ADDRESS") {
                cols.push(
                    <td className={[styles.col, styles.center, props.selected === i ? styles.selected : ""].join(' ')} key={value.title} style={{width: window.innerWidth * (value.flex / 100)}}>
                        <div>{props.data.PRIMARY_ADDRESS1}</div>
                        <div>{props.data.PRIMARY_CITY} {props.data.PRIMARY_STATE}, {props.data.PRIMARY_ZIP5}</div>
                    </td>
                )
            }
            else if (value.col === "BEST_ADDRESS") {
                cols.push(
                    <td className={[styles.col, styles.center, props.selected === i ? styles.selected : ""].join(' ')} key={value.title} style={{width: window.innerWidth * (value.flex / 100)}}>
                        <div>{props.data.BEST_ADDRESS1}</div>
                        <div>{props.data.BEST_CITY} {props.data.BEST_STATE}, {props.data.BEST_ZIP5}</div>
                    </td>
                )
            }
            else if (value.col === "MOVE_TYPE") {
                cols.push(
                    <td className={[styles.col, styles.center, props.selected === i ? styles.selected : ""].join(' ')} key={value.title} style={{width: window.innerWidth * (value.flex / 100)}}>
                        <div className={styles.flexcenter}>
                            <ChangeMoveTypeDrop {...props} custom={custom} setCustom={setCustom}/>
                        </div>
                    </td>
                )
            }
            else {
                if (value.options === "LOV") {
                    let options = [<option key={"blank"} value={""}></option>]
                    let start = value.headerChecked ? 1 : 0
                    for (let j = start; j < value.LOV.length; j++) {
                        options.push(
                            <option key={value.LOV[j]} value={value.LOV[j]}>{value.LOV[j]}</option>
                        )
                    }
                    cols.push(
                        <td key={value.title} className={[styles.col, styles.center, props.selected === i ? styles.selected : ""].join(' ')} style={{width: window.innerWidth * (value.flex / 100)}}>
                            <div className={styles.flexcenter}>
                                <select className={styles.setInput} value={custom[value.col]} onChange={(event) => {
                                        let temp = {
                                            profID: props.data.PROF_ID,
                                            key: value.col,
                                            value: event.target.value
                                        }
                                        updateCustomColumn(temp)
                                        setCustom(event.target.value)
                                    }} disabled={props.lock}>
                                    {options}
                                </select>
                            </div>
                        </td>
                    )
                }
                else if (value.options === "FreeText") {
                    cols.push(
                        <td key={value.title} className={[styles.col, styles.center, props.selected === i ? styles.selected : ""].join(' ')} style={{width: window.innerWidth * (value.flex / 100)}}>
                            <div className={styles.flexcenter}>
                                <input className={styles.setInput} value={custom[value.col]} style={{width: window.innerWidth * (value.flex / 100)}} onChange={(event) => {
                                    let temp = {
                                        profID: props.data.PROF_ID,
                                        key: value.col,
                                        value: event.target.value
                                    }
                                    updateCustomColumn(temp)
                                    setCustom(event.target.value)
                                }} disabled={props.lock}/>
                            </div>
                        </td>
                    )
                }
                else {
                    cols.push(<td className={[styles.col, styles.center, props.selected === i ? styles.selected : ""].join(' ')} key={value.title} style={{width: window.innerWidth * (value.flex / 100)}}>
                        <div className={styles.flexcenter}>
                            {props.data[value.col] || "-"}
                        </div>
                    </td>)
                }

            }
        }
    }
    return (
        <tr className={[styles.Result, custom.recordConflicts ? styles.conflict : ""].join(" ")}>
            {cols}
        </tr>
    )
}

export default Result
