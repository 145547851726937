import React, { useState, useEffect } from 'react';
import Styles from '../css/DialogBoxAddUser.module.css';
import Axios from '../../../components/Axios/Axios';
import validation from './../../../globalcomponents/validation';

export default function DialogBoxAddUser(props) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [role, setRole] = useState('');
  const [email, setEmail] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState('REQUIRED FIELD *');
  const [roleOptions, setRoleOptions] = useState('');
  const [wiggle, setWiggle] = useState(false);

  useEffect(() => {
    Axios('/api/admin/get-user-roles', {}, (results) => {
      let roleOptions = [];
      for (let i = 0; i < results.length; i++) {
        roleOptions.push(
          <option key={i} value={results[i].Name}>
            {results[i].Name}
          </option>
        );
      }
      setRoleOptions(roleOptions);
    });
  }, []);

  const addNewUser = () => {
    let stateInfo = {};
    stateInfo = {
      FIRST_NAME: firstName,
      LAST_NAME: lastName,
      PHONE_NUMBER: phoneNumber,
      EMAIL: email,
      ROLE: role,
    };
    Axios('/api/admin/add-user', stateInfo, (results) => {
        //props.setData(results);
        props.changeAdminView(1, stateInfo);
    });
  };

  return (
    <div className={Styles.editScreen}>
      <div className={Styles.title}>
        <div></div>
        <div className={Styles.TitleText}>Create User</div>
        <div
          className={Styles.close}
          onClick={() => {
            props.changeAdminView(-1);
          }}>
          <i className={'fas fa-times'}></i>
        </div>
      </div>
      <form>
        <div className={Styles.body}>
          <div className={Styles.errorMessage}>{errorMessage}</div>
          <div className={Styles.toprow}>
            <div className={Styles.set}>
              <div className={Styles.inputTitle}>
                FIRST NAME
                <div className={Styles.required}>*</div>
              </div>
              <input
                type={'text'}
                className={isValid ? Styles.DefaultInputForm : Styles.ErrorInputForm}
                value={firstName}
                onChange={(event) => {
                  setFirstName(event.target.value);
                }}
                autoFocus
              />
            </div>
            <div className={Styles.set}>
              <div className={Styles.inputTitle}>
                LAST NAME
                <div className={Styles.required}>*</div>
              </div>
              <input
                type={'text'}
                className={isValid ? Styles.DefaultInputForm : Styles.ErrorInputForm}
                value={lastName}
                onChange={(event) => {
                  setLastName(event.target.value);
                }}
              />
            </div>
          </div>
          <div className={Styles.middlerow}>
            <div className={Styles.set}>
              <div className={Styles.inputTitle}>PHONE NUMBER</div>
              <input
                type={'number'}
                className={Styles.DefaultInputForm}
                value={phoneNumber}
                onChange={(event) => {
                  setPhoneNumber(event.target.value);
                }}
                placeholder={'xxx-xxx-xxxx'}
              />
            </div>
            <div className={Styles.set}>
              <div className={Styles.inputTitle}>
                ROLE
                <div className={Styles.required}>*</div>
              </div>
              <select
                className={isValid ? Styles.DefaultInputForm : Styles.ErrorInputForm}
                value={role}
                onChange={(event) => {
                  setRole(event.target.value);
                }}>
                <option value=''></option>
                {roleOptions}
              </select>
            </div>
          </div>
          <div className={Styles.middlerow}>
            <div className={Styles.set}>
              <div className={Styles.inputTitle}>
                EMAIL
                <div className={Styles.required}>*</div>
              </div>
              <input
                type={'text'}
                className={isValid ? Styles.DefaultInputForm : Styles.ErrorInputForm}
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
            </div>
          </div>
          <div className={Styles.buttonRow}>
            <div
              id={Styles.cancel}
              onClick={() => {
                props.changeAdminView(-1);
              }}>
              CANCEL
            </div>
            <div
              id={Styles.submit}
              className={wiggle && wiggle ? Styles.Wiggle : ''}
              onClick={() => {
                if (firstName !== '' && lastName !== '' && role !== '' && email !== '') {
                  if (validation.isValidEmail(email)) {
                    addNewUser();
                  } else {
                    setWiggle(true);
                    setIsValid(false);
                    setErrorMessage('Please provide a valid email address.');
                    setTimeout(() => {
                      setWiggle(false);
                    }, 500);
                  }
                } else {
                  setWiggle(true);
                  setIsValid(false);
                  setErrorMessage('REQUIRED FIELD *');
                  setTimeout(() => {
                    setWiggle(false);
                  }, 500);
                }
              }}>
              SUBMIT
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
