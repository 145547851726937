import React, {Component} from 'react';
import styles from '../css/Details_ChangeRequests.module.css';
import t from '../../../assets/t-arrow_v2.png'
import arrow from '../../../assets/arrow_v2.png'
import button from '../../../assets/light-button_v2.png'
import clickedButton from '../../../assets/Dark_Button_line_v2.png'

class DetailsChangeRequestsRecord extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showChild: false
        };
        // console.log(props);
    }

    mapStatus(status) {
        let ret = {}
        switch (status) {
            case "Automatically Approved":
                ret.status = "Approved"
                ret.comments = false
                break;
            case "Approved":
                ret.status = "Approved"
                ret.comments = false
                break;
            case "NEEDS MR":
                ret.status = "Open"
                ret.comments = false
                break;
            case "REQUEST_RCVD":
                ret.status = "Open"
                ret.comments = true
                break;
            case "In Progress":
                ret.status = "In Progress"
                ret.comments = false
                break;
            case 'InProcess':
            case 'IN PROCESS':
                ret.status = 'In Process'
                ret.comments = false
                break;
            case "DECLINED":
                ret.status = "Cancelled"
                ret.comments = true
                break;
            case "Rubber Stamped":
                ret.status = "Approved"
                ret.comments = true
                break;
            case "COMPLETED":
                ret.status = "Approved"
                ret.comments = true
                break;
        }
        return ret
    }

    render() {
        let children = []
        if (this.props.children) {
            for (let i = 0; i < this.props.children.length; i++) {
                children.push(<div className={[styles.result, styles.child].join(' ')}>
                    <div className={styles.content}>
                        <div className={styles.icon}>{this.props.children.length > i + 1 ? <img src={t} alt={"Couldn't Load"} /> : <img src={arrow} alt={"Couldn't Load"} /> }</div>
                        <div className={[styles.Content, styles.actionID].join(' ')} onClick={() => {
                                this.props.editScreen(8, this.props.children[i])
                            }}>{this.props.children[i].ACTION_ID ? this.props.children[i].ACTION_ID : "—"}</div>
                        <div className={styles.Content}>{this.props.children[i].RESOLVE_TYPE ? this.mapStatus(this.props.children[i].RESOLVE_TYPE).status : "—"}</div>
                        <div className={styles.Content}>{this.props.children[i].REQUEST_DATE ? this.props.children[i].REQUEST_DATE.replace(/[Tt].*/, "") : "—"}</div>
                        <div className={styles.Content}>{this.props.children[i].ORIGINAL_REQUST_TYPE ? this.props.children[i].ORIGINAL_REQUST_TYPE : "—"}</div>
                        <div className={styles.Content}>{this.props.children[i].ORIGINAL_REQUSTOR_NAME ? this.props.children[i].ORIGINAL_REQUSTOR_NAME : "—"}</div>
                        <div className={styles.Content}>{this.props.children[i].RESOLVE_TYPE ? this.mapStatus(this.props.children[i].RESOLVE_TYPE).comments : "—"}</div>
                        <div className={styles.Content}>{this.props.children[i].RESOLVE_DATE ? this.props.children[i].RESOLVE_DATE.replace(/[Tt].*/, "") : "—"}</div>
                    </div>
                </div>)
            }
        }


        return (
            <>
                <div className={this.props.odd ? [styles.Results, styles.Odd].join(' ') : styles.Results}>
                    <div className={styles.icon} onClick={() => {
                            this.setState({showChild: !this.state.showChild})
                        }}>{this.props.children !== undefined ? this.state.showChild ? <img src={clickedButton} alt={"Couldn't Load"} /> : <img src={button} alt={"Couldn't Load"} /> : ""}</div>
                    <div className={[styles.Content, styles.actionID].join(' ')} onClick={() => {
                            this.props.editScreen(8, this.props.data)
                        }}>{this.props.data.ACTION_ID ? this.props.data.ACTION_ID : "—"}</div>
                    <div className={styles.Content}>{this.props.data.RESOLVE_TYPE ? this.mapStatus(this.props.data.RESOLVE_TYPE).status : "—"}</div>
                    <div className={styles.Content}>{this.props.data.REQUEST_DATE ? this.props.data.REQUEST_DATE.replace(/[Tt].*/, "") : "—"}</div>
                    <div className={styles.Content}>{this.props.data.ORIGINAL_REQUST_TYPE ? this.props.data.ORIGINAL_REQUST_TYPE : "—"}</div>
                    <div className={styles.Content}>{this.props.data.ORIGINAL_REQUSTOR_NAME ? this.props.data.ORIGINAL_REQUSTOR_NAME : "—"}</div>
                    <div className={styles.Content}>{this.props.data.RESOLVE_TYPE ? this.mapStatus(this.props.data.RESOLVE_TYPE).comments : "—"}</div>
                    <div className={styles.Content}>{this.props.data.RESOLVE_DATE ? this.props.data.RESOLVE_DATE.replace(/[Tt].*/, "") : "—"}</div>
                </div>
                {this.state.showChild ? children : ""}
            </>
        );
    }
}

export default DetailsChangeRequestsRecord;
