import React, {Component} from 'react';
import styles from '../css/RegionalPlanResult.module.css';

class RegionalPlanResult extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: props.data,
            odd : (props.number % 2 === 0 ? false : true)
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data !== this.props.data) {
            this.setState({data: this.props.data});
        }
    }

    render() {
        let start = new Date(this.state.data.START_DATE)
        let end = new Date(this.state.data.END_DATE)
        let today = new Date()
        return (
            <div className={this.state.odd ? [styles.Result, styles.odd].join(' ') : styles.Result}>
                <div className={start.getTime() < today.getTime() && today.getTime() < end.getTime() ? [styles.long, styles.greenIcon].join(' ') : [styles.long, styles.greyIcon].join(' ')}>{start.getTime() < today.getTime() && today.getTime() < end.getTime() ? <i className="fas fa-check-circle" id={styles.StatusActive}></i> : <i className="fas fa-times-circle" id={styles.StatusInactive}></i>}</div>
                <div className={styles.long}>{this.state.data.NAME}</div>
                <div className={styles.long}>{this.state.data.TEAM}</div>
                <div className={styles.long}>{this.state.data.START_DATE}</div>
                <div className={styles.long}>{this.state.data.END_DATE}</div>
                <div className={[styles.long, styles.links].join(' ')}>
                    <div className={styles.link} onClick={() => {
                            this.props.edit(this.props.number);
                        }}>EDIT</div>
                    <div className={styles.link} onClick={() => {
                            this.props.delete(this.props.number);
                        }}>DELETE</div>
                </div>
            </div>
        );
    }
}

export default RegionalPlanResult;
