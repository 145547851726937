import React from 'react'
import {Link} from "react-router-dom";
import styles from '../../css/Result.module.css';
import Axios from '../../../../../components/Axios/Axios'

function Result(props) {
    if (props.data) {
        let links = []

        let terrID = props.data.TERR_ID == null ? props.data.SUB_TERR_ID : props.data.TERR_ID;

        links.push(<Link key={"view"} to={`${props.path}/search/${props.ID}/${terrID}`}>
            <div className={styles.link}>View</div>
        </Link>)

        if (["ADMIN"].includes(props.user.role)) {
            links.push(<div className={styles.link} key={'A2'} onClick={() => {
                    if (!props.approved) {
                        if (props.data.STATUS_ID < 6) {
                            let val = props.data.STATUS_ID
                            if (val === 4) {
                                val = 6
                            }
                            else {
                                val = val + 1
                            }
                            let temp = {
                                statusID: val,
                                ID: props.targetID,
                                terr: props.data.TERR_ID
                            }
                            Axios('/api/targeting/submit-status', temp, (data) => {
                                props.getData()
                            })
                        }
                    }
                }}>Approve</div>)
            links.push(<div className={styles.link} key={'A3'} onClick={() => {
                    if (!props.approved) {
                        if (props.data.STATUS_ID > 1) {
                            let val = props.data.STATUS_ID
                            if (val === 4) {
                                val = 5
                            }
                            else {
                                val = val - 1
                            }
                            let temp = {
                                statusID: val,
                                ID: props.targetID,
                                terr: props.data.TERR_ID
                            }
                            Axios('/api/targeting/submit-status', temp, (data) => {
                                props.getData()
                            })
                        }
                    }
                }}>Reject</div>)
        }
        let statusOptions = []
        for (let i = 0; i < props.statusData.length; i++) {
            statusOptions.push(<option key={props.statusData[i].STATUS} value={i+1}>{props.statusData[i].STATUS}</option>)
        }

        let status
        if (["ADMIN"].includes(props.user.role)) {
            status = (
                <select className={[styles.setInput, props.data.STATUS === "APPROVED" ? styles.approved : "", props.data.STATUS === "REJECTED" ? styles.rejected : ""].join(" ")} value={props.data.STATUS_ID} disabled={props.approved} onChange={(event) => {
                        let val = parseInt(event.target.value)
                        let temp = {
                            statusID: val,
                            ID: props.targetID,
                            terr: props.data.TERR_ID
                        }
                        Axios('/api/targeting/submit-status', temp, (data) => {
                            props.getData()
                        })
                    }}>
                    {statusOptions}
                </select>
            )
        }
        else {
            status = props.data.STATUS
        }

        let dynamicBox = ""
        let terr = props.user.role === "ADMIN" ? props.adminExercise.TEAM.toString() : props.user.terr

        if (terr.startsWith("11") || terr.startsWith("31") || terr.startsWith("32")) {
            dynamicBox = (
                [<div className={[styles.col, styles.short, styles.center].join(' ')}>{props.data.FINAL_TOTAL_C || "-"}</div>
                /*<div className={[styles.col, styles.short, styles.center].join(' ')}>{props.data.FINAL_TOTAL_E || "-"}</div>*/])
        }
        else if (terr.startsWith("21")) {
            dynamicBox = (
                [<div className={[styles.col, styles.short, styles.center].join(' ')}>{props.data.FINAL_TOTAL_C || "-"}</div>
                    //,
                    //<div className={[styles.col, styles.short, styles.center].join(' ')}>{props.data.FINAL_TOTAL_NPP || "-"}</div>
                ])
        }

        let dynamicTerr = (props.data.TERR_ID == null && props.data.TERR_NAME == null) ? "VACANT" :props.data.TERR_ID + " - " + props.data.TERR_NAME
        let dynamicName = (props.data.FIRST_NAME == null && props.data.LAST_NAME == null) ? "VACANT":props.data.FIRST_NAME + " " + props.data.LAST_NAME

        return (<div className={styles.result}>
            <div className={[styles.col, styles.long].join(' ')}>{dynamicTerr}</div>
            <div className={[styles.col, styles.short, styles.center].join(' ')}>{dynamicName}</div>
            <div className={[styles.col, styles.short, styles.center].join(' ')}>{props.data.FINAL_TOTAL_A || "-"}</div>
            <div className={[styles.col, styles.short, styles.center].join(' ')}>{props.data.FINAL_TOTAL_B || "-"}</div>
            {dynamicBox}
          {/*  <div className={[styles.col, styles.short, styles.center].join(' ')}>{props.data.FINAL_TOTAL_NT || "-"}</div>*/}
            <div className={[styles.col, styles.select, styles.center].join(' ')}>{status}</div>
            <div className={[styles.links, styles.col, styles.long, styles.center].join(' ')}>
                {links}
            </div>
        </div>);
    }
    else if (props.totals) {
        return (
            <div className={styles.result} style={{fontWeight: "bold"}}>
                {props.totals}
            </div>
        )
    }
    else {
        return ""
    }
}

export default Result
