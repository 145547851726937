import React from 'react'
import styles from '../css/DisputeCard.module.css'

function DisputeCard(props) {
    return (
        <div className={[styles.border, props.selected === props.type ? styles.active : ""].join(" ")} onClick={() => {
            props.setSelected(props.type)
        }}>
            <input type='radio' checked={props.selected === props.type} onChange={() => {
                props.setSelected(props.type)
            }}/>
            <div className={styles.title}>
                <div>
                    Keep At
                </div>
                <div>
                    {props.title}
                </div>
            </div>
            <div>
                <div className={styles.addressType}>
                    {props.title}:
                </div>
                <div  className={styles.normal}>
                    {props.location}
                </div>
            </div>
            <div className={styles.user}>
                <div>
                    {`${props.selectedBy} selected this`}
                </div>
                <div className={styles.normal}>
                    {`Reason: ${props.reason ? props.reason : ""}`}
                </div>
            </div>
        </div>
    )
}

export default DisputeCard