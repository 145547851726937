import { makeAutoObservable, observable, computed, action, reaction, runInAction } from "mobx";
import Axios from '../components/Axios/Axios'

export default class ExusStore {

    constructor(rootStore) {
        this.rootStore = rootStore;
        this.user = this.rootStore.userStore.user;
        makeAutoObservable(this);
    }

    alignment = observable({});
    profId = observable(0);
    loading = observable(true);
    user = observable({});

    addressAlignment = action((profId, puser) => {

        runInAction(() => {
            this.loading = true;
        });

        this.profId = profId;
        let teamID = puser.team ? puser.team : 11;


        


        const params = {
            profId: profId,
            teamId: teamID,
            role: puser.role 
          
        }

        Axios('/api/exus/xus/alignment', params, (results) => {


            runInAction(() => {
                this.alignment = results;
                this.loading = false;
            });


        })


       


        //Axios('/xus/detail/alignment', params, (data) => {
        
        //}, (error) => console.error(error));
    })
}