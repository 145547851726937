import React, {Component} from 'react';
import styles from '../css/Details_Edit_Msl.module.css';

class Details_Edit_Msl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            PREV_FOCUS_AREA_PRIMARY: this.props.msl.FOCUS_AREA_PRIMARY === undefined || this.props.msl.FOCUS_AREA_PRIMARY === null ? '' : this.props.msl.FOCUS_AREA_PRIMARY,
            PREV_FOCUS_AREA_PRIMARY_TIER: this.props.msl.FOCUS_AREA_PRIMARY_TIER === undefined || this.props.msl.FOCUS_AREA_PRIMARY_TIER === null? '' : this.props.msl.FOCUS_AREA_PRIMARY_TIER,
            PREV_FOCUS_AREA_SECONDARY: this.props.msl.FOCUS_AREA_SECONDARY === undefined || this.props.msl.FOCUS_AREA_SECONDARY === null ? '' : this.props.msl.FOCUS_AREA_SECONDARY,
            PREV_FOCUS_AREA_SECONDARY_TIER: this.props.msl.FOCUS_AREA_SECONDARY_TIER === undefined || this.props.msl.FOCUS_AREA_SECONDARY_TIER === null? '' : this.props.msl.FOCUS_AREA_SECONDARY_TIER,
            PREV_FOCUS_AREA_TERTIARY: this.props.msl.FOCUS_AREA_TERTIARY === undefined || this.props.msl.FOCUS_AREA_TERTIARY === null ? '' : this.props.msl.FOCUS_AREA_TERTIARY,
            PREV_FOCUS_AREA_TERTIARY_TIER: this.props.msl.FOCUS_AREA_TERTIARY_TIER === undefined || this.props.msl.FOCUS_AREA_TERTIARY_TIER === null ? '' : this.props.msl.FOCUS_AREA_TERTIARY_TIER,
            PREV_SI1: this.props.msl.SI1 === 'Y' ? true : false,
            PREV_SI2: this.props.msl.SI2 === 'Y' ? true : false,
            PREV_SI3: this.props.msl.SI3 === 'Y' ? true : false,
            PREV_SI4: this.props.msl.SI4 === 'Y' ? true : false,
            FOCUS_AREA_PRIMARY: this.props.msl.FOCUS_AREA_PRIMARY === undefined || this.props.msl.FOCUS_AREA_PRIMARY === null ? '' : this.props.msl.FOCUS_AREA_PRIMARY,
            FOCUS_AREA_PRIMARY_TIER: this.props.msl.FOCUS_AREA_PRIMARY_TIER === undefined || this.props.msl.FOCUS_AREA_PRIMARY_TIER === null? '' : this.props.msl.FOCUS_AREA_PRIMARY_TIER,
            FOCUS_AREA_SECONDARY: this.props.msl.FOCUS_AREA_SECONDARY === undefined || this.props.msl.FOCUS_AREA_SECONDARY === null ? '' : this.props.msl.FOCUS_AREA_SECONDARY,
            FOCUS_AREA_SECONDARY_TIER: this.props.msl.FOCUS_AREA_SECONDARY_TIER === undefined || this.props.msl.FOCUS_AREA_SECONDARY_TIER === null? '' : this.props.msl.FOCUS_AREA_SECONDARY_TIER,
            FOCUS_AREA_TERTIARY: this.props.msl.FOCUS_AREA_TERTIARY === undefined || this.props.msl.FOCUS_AREA_TERTIARY === null ? '' : this.props.msl.FOCUS_AREA_TERTIARY,
            FOCUS_AREA_TERTIARY_TIER: this.props.msl.FOCUS_AREA_TERTIARY_TIER === undefined || this.props.msl.FOCUS_AREA_TERTIARY_TIER === null ? '' : this.props.msl.FOCUS_AREA_TERTIARY_TIER,
            SI1: this.props.msl.SI1 === 'Y' ? true : false,
            SI2: this.props.msl.SI2 === 'Y' ? true : false,
            SI3: this.props.msl.SI3 === 'Y' ? true : false,
            SI4: this.props.msl.SI4 === 'Y' ? true : false,
            ISVALID: true,
            MESSAGE: 'If you select a focus area, you must select a corresponding tier. At least one goal must be selected.',
            CHANGED: {}
        }
    }

    sendRequest() {
        let json = this.state.CHANGED;
        if (this.state.PREV_SI1 !== this.state.SI1) {
            json.SI1 = this.state.SI1 ? 'Y' : 'N'
        }
        if (this.state.PREV_SI2 !== this.state.SI2) {
            json.SI2 = this.state.SI2 ? 'Y' : 'N'
        }
        if (this.state.PREV_SI3 !== this.state.SI3) {
            json.SI3 = this.state.SI3 ? 'Y' : 'N'
        }
        if (this.state.PREV_SI4 !== this.state.SI4) {
            json.SI4 = this.state.SI4 ? 'Y' : 'N'
        }
        json.REGIONAL_PLAN_OWNER = this.props.user.empID;
        json.REQUEST_TYPE = 'Edit MSL Information';
        this.props.submit(json);
    }

    render() {
        const focusarea = [
          "ALL",
          "AML",
          "BLADDER",
          "BREAST",
          "CANCER IMMUNOLOGY",
          "CANCER IMMUNOTHERAPY",
          "CCA",
          "CLL",
          "CML",
          "COLORECTAL",
          "ESOPHAGEAL",
          "GVHD",
          "HEAD AND NECK",
          "HEM",
          "HEPATOCELLULAR",
          "LEUKEMIA-OTHER",
          "LUNG",
          "LYMPHOMA",
          "MDS",
          "MED-ONC",
          "MELANOMA",
          "MPN",
          "MULTIPLE MYELOMA",
          "NEUROLOGICAL TUMORS",
          "OVARIAN",
          "PANCREATIC",
          "PATHOLOGY",
          "PEDIATRIC CANCERS",
          "PHASE 1",
          "PRECLINICAL RESEARCH",
          "PROSTATE",
          "RCC",
          "SARCOMA",
          "SICKLE CELL",
          "SURGERY",
          "THROMBOSIS",
          "THYMUS",
          "TRANSPLANT"
      ]

    let focusoptions = [<option key='null' value={null}></option>]
        for (let i = 0; i < focusarea.length; i++) {
            focusoptions.push(<option key={i} value={focusarea[i]}>{focusarea[i]}</option>)
        }

        return (<div className={styles.editScreen}>
            <div className={styles.wrapper}>
                <div className={styles.title}>
                    <div></div>
                    <div className={styles.TitleText}>
                            Edit MSL
                        </div>
                    <div className={styles.close} onClick={() => {
                            this.props.close()
                        }}>
                        <i className="fas fa-times"></i>
                    </div>
                </div>
                <form>
                    <div className={styles.body}>
                        <div className={this.state.ISVALID ? styles.DefaultMessage : styles.ErrorMessage}>{this.state.MESSAGE}</div>
                        <div className={styles.toprow}>
                            <div className={styles.set}>
                                <div className={styles.inputTitle}>PRIMARY FOCUS AREA
                                    <div className={styles.required}>*</div>
                                </div>
                                <select type="text" className={this.state.ISVALID
                                        ? styles.DefaultInputForm
                                        : styles.ErrorInputForm} value={this.state.FOCUS_AREA_PRIMARY} onChange={(event) => {
                                        let temp = this.state.CHANGED;
                                        if (this.state.PREV_FOCUS_AREA_PRIMARY !== event.target.value) {
                                            temp.FOCUS_AREA_PRIMARY = event.target.value;
                                        }
                                        else {
                                            delete temp.FOCUS_AREA_PRIMARY;
                                        }
                                        this.setState({
                                            FOCUS_AREA_PRIMARY: event.target.value,
                                            CHANGED: temp
                                        })
                                    }}>
                                    {focusoptions}
                                </select>
                            </div>
                            <div className={styles.set}>
                                <div className={styles.inputTitle}>PRIMARY TIER
                                    <div className={styles.required}>*</div>
                                </div>
                                <select type="text" className={this.state.ISVALID
                                        ? styles.DefaultInputForm
                                        : styles.ErrorInputForm} value={this.state.FOCUS_AREA_PRIMARY_TIER} onChange={(event) => {
                                        let temp = this.state.CHANGED;
                                        if (this.state.PREV_FOCUS_AREA_PRIMARY_TIER !== event.target.value) {
                                            temp.FOCUS_AREA_PRIMARY_TIER = event.target.value;
                                        }
                                        else {
                                            delete temp.FOCUS_AREA_PRIMARY_TIER;
                                        }
                                        this.setState({
                                            FOCUS_AREA_PRIMARY_TIER: event.target.value,
                                            CHANGED: temp
                                        })
                                    }}>
                                    <option value={null}></option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                </select>
                            </div>
                            <div className={styles.set}>
                                <div className={styles.inputTitle}>SECONDARY FOCUS AREA</div>
                                <select type="text" className={styles.DefaultInputForm} value={this.state.FOCUS_AREA_SECONDARY} onChange={(event) => {
                                        let temp = this.state.CHANGED;
                                        if (this.props.msl.FOCUS_AREA_SECONDARY !== event.target.value) {
                                            temp.FOCUS_AREA_SECONDARY = event.target.value;
                                        }
                                        else {
                                            delete temp.FOCUS_AREA_SECONDARY;
                                        }
                                        this.setState({
                                            FOCUS_AREA_SECONDARY: event.target.value,
                                            CHANGED: temp
                                        })
                                    }}>
                                    {focusoptions}
                                </select>
                            </div>
                            <div className={styles.set}>
                                <div className={styles.inputTitle}>SECONDARY TIER</div>
                                <select type="text" className={styles.DefaultInputForm} value={this.state.FOCUS_AREA_SECONDARY_TIER} onChange={(event) => {
                                        let temp = this.state.CHANGED;
                                        if (this.props.msl.FOCUS_AREA_SECONDARY_TIER !== event.target.value) {
                                            temp.FOCUS_AREA_SECONDARY_TIER = event.target.value;
                                        }
                                        else {
                                            delete temp.FOCUS_AREA_SECONDARY_TIER;
                                        }
                                        this.setState({
                                            FOCUS_AREA_SECONDARY_TIER: event.target.value,
                                            CHANGED: temp
                                        })
                                    }}>
                                    <option value={null}></option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                </select>
                            </div>
                            <div className={styles.set}>
                                <div className={styles.inputTitle}>OTHER FOCUS AREA</div>
                                <select type="text" className={styles.DefaultInputForm} value={this.state.FOCUS_AREA_TERTIARY} onChange={(event) => {
                                        let temp = this.state.CHANGED;
                                        if (this.props.msl.FOCUS_AREA_TERTIARY !== event.target.value) {
                                            temp.FOCUS_AREA_TERTIARY = event.target.value;
                                        }
                                        else {
                                            delete temp.FOCUS_AREA_TERTIARY;
                                        }
                                        this.setState({
                                            FOCUS_AREA_TERTIARY: event.target.value,
                                            CHANGED: temp
                                        })
                                    }}>
                                    {focusoptions}
                                </select>
                            </div>
                            <div className={styles.set}>
                                <div className={styles.inputTitle}>OTHER TIER</div>
                                <select type="text" className={styles.DefaultInputForm} value={this.state.FOCUS_AREA_TERTIARY_TIER} onChange={(event) => {
                                        let temp = this.state.CHANGED;
                                        if (this.props.msl.FOCUS_AREA_TERTIARY_TIER !== event.target.value) {
                                            temp.FOCUS_AREA_TERTIARY_TIER = event.target.value;
                                        }
                                        else {
                                            delete temp.FOCUS_AREA_TERTIARY_TIER;
                                        }
                                        this.setState({
                                            FOCUS_AREA_TERTIARY_TIER: event.target.value,
                                            CHANGED: temp
                                        })
                                    }}>
                                    <option value={null}></option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                </select>
                            </div>
                            <div className={[styles.set, styles.checkbox].join(' ')}>
                                <div className={styles.inputTitle}>GOAL 1</div>
                                <input type="checkbox" defaultChecked={this.state.SI1} onChange={() => {
                                    this.setState({
                                        SI1: !this.state.SI1,
                                    })
                                }}></input>
                            </div>
                            <div className={[styles.set, styles.checkbox].join(' ')}>
                                <div className={styles.inputTitle}>GOAL 2</div>
                                <input type="checkbox" defaultChecked={this.state.SI2} onChange={() => {
                                    this.setState({
                                        SI2: !this.state.SI2,
                                    })
                                }}></input>
                            </div>
                            <div className={[styles.set, styles.checkbox].join(' ')}>
                                <div className={styles.inputTitle}>GOAL 3</div>
                                <input type="checkbox" defaultChecked={this.state.SI3} onChange={() => {
                                    this.setState({
                                        SI3: !this.state.SI3,
                                    })
                                }}></input>
                            </div>
                            <div className={[styles.set, styles.checkbox].join(' ')}>
                                <div className={styles.inputTitle}>GOAL 4</div>
                                <input type="checkbox" defaultChecked={this.state.SI4} onChange={() => {
                                    this.setState({
                                        SI4: !this.state.SI4,
                                    })
                                }}></input>
                            </div>
                        </div>
                        <div className={styles.buttonRow}>
                            <div id={styles.cancel} onClick={() => {
                                    this.props.close()
                                }
                            }>CANCEL</div>
                            <div type="submit" id={styles.submit} className={this.state.Wiggle && this.state.Wiggle !== undefined
                                    ? styles.Wiggle
                                    : ""}
                                    onClick={() => {
                                        if (this.state.FOCUS_AREA_PRIMARY !== '' && this.state.FOCUS_AREA_PRIMARY_TIER !== '') {
                                            if (this.state.SI1 || this.state.SI2 || this.state.SI3 || this.state.SI4) {
                                                if ((this.state.FOCUS_AREA_SECONDARY !== '' && this.state.FOCUS_AREA_SECONDARY_TIER === '') ||
                                                    (this.state.FOCUS_AREA_SECONDARY === '' && this.state.FOCUS_AREA_SECONDARY_TIER !== '') ||
                                                    (this.state.FOCUS_AREA_TERTIARY !== '' && this.state.FOCUS_AREA_TERTIARY_TIER === '') ||
                                                    (this.state.FOCUS_AREA_TERTIARY === '' && this.state.FOCUS_AREA_TERTIARY_TIER !== '')) {
                                                        this.setState({Wiggle: true, ISVALID: false});
                                                        setTimeout(() => {this.setState({Wiggle: false})}, 500);
                                                }
                                                else {
                                                    this.sendRequest()
                                                }
                                            }
                                            else {
                                                this.setState({Wiggle: true, ISVALID: false});
                                                setTimeout(() => {this.setState({Wiggle: false})}, 500);
                                            }
                                        }
                                        else {
                                            this.setState({Wiggle: true, ISVALID: false});
                                            setTimeout(() => {this.setState({Wiggle: false})}, 500);
                                        }
                                    }
                            }>SUBMIT</div>
                        </div>
                    </div>
                </form>
            </div>
        </div>)
    }

}

export default Details_Edit_Msl;
