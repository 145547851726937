import React, {Component} from 'react';
import styles from '../css/AddHCO_P1.module.css';
import validateRequestNotes from '../../../helper/validateRequestNotes';

class AddHCO_P1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ORG_NAME: "",
            ORG_ID: "",
            ORG_TYPE: "",
            REQUEST_TYPE: "",
            REQUEST_NOTE: "",
            ERROR_MESSAGE: "",
            ISVALID: true
        };
    }

    componentDidMount() {
        let temp = {}
        for (let i = 0; i < Object.keys(this.props.info).length; i++) {
            if (Object.keys(this.props.info)[i]) {
                temp[Object.keys(this.props.info)[i]] = this.props.info[Object.keys(this.props.info)[i]]
            }
        }

        temp.REQUEST_TYPE = temp.ORIGINAL_REQUST_TYPE

        this.setState(temp)
    }

    render() {
        let reqReasons = []
        if (["OCNE", "NAM", "SA NAM", "DTS", "DBD"].includes(this.props.user.role) || ["11", "21", "31", "51", "53"].includes(this.props.user.team)) {
            reqReasons = [<option key={0} value="New HCO Add - CRM">CRM</option>,
                          <option key={1} value="New HCO Add - Spend">Spend</option>]
        }
        else if (["DEVELOPMENT OPERATIONS-CTMS", "COMPLIANCE"].includes(this.props.user.role)) {
            reqReasons = [<option key={0} value="New HCO Add - CTMS">CTMS</option>,
                          <option key={1} value="New HCO Add - Spend">Spend</option>]
        }
        else if (["ADMIN"].includes(this.props.user.role)) {
            reqReasons = [<option key={0} value="New HCO Add - CRM">CRM</option>,
                          <option key={1} value="New HCO Add - CTMS">CTMS</option>,
                          <option key={2} value="New HCO Add - Spend">Spend</option>]
        }
        else {
            reqReasons = [<option key={0} value="New HCO Add - Spend">Spend</option>]
        }

        let orgTypeFilter = ""
        if (["DIR"].includes(this.props.role)) {
            orgTypeFilter = ""
        }
        else {
            orgTypeFilter = (
            <div className={styles.set}>
                <div className={styles.inputTitle}>ORG TYPE  <div className={styles.required}>*</div></div>
                <select  className={this.state.ISVALID ? styles.DefaultInputForm : styles.ErrorInputForm} type='text' value={this.state.ORG_TYPE == "" ? "" : this.state.ORG_TYPE} onChange={(event) => {
                    if (event.target.value == "") {
                        this.setState({ ORG_TYPE: ""})
                        this.areAllBlank(Object.keys(this.state), 7)
                    }
                    else {
                        this.setState({
                            ORG_TYPE: event.target.value,
                            ActiveButton: true
                        })
                    }
                }}>
                        <option value=""></option>
                        <option value="ACADEMIC INSTITUTION">ACADEMIC INSTITUTION</option>
                        <option value="ASC">ASC</option>
                        <option value="FOUNDATION">FOUNDATION</option>
                        <option value="GPO">GPO</option>
                        <option value="GRP">GRP</option>
                        <option value="HEALTH NETWORK">HEALTH NETWORK</option>
                        <option value="HOME HEALTH">HOME HEALTH</option>
                        <option value="HOSPICE">HOSPICE</option>
                        <option value="HOSPITAL">HOSPITAL</option>
                        <option value="KAISER">KAISER</option>
                        <option value="MCO">MCO</option>
                        <option value="MEDICAL ASSOCIATION">MEDICAL ASSOCIATION</option>
                        <option value="MILITARY HOSPITAL">MILITARY HOSPITAL</option>
                        <option value="ONCOLOGY NETWORK">ONCOLOGY NETWORK</option>
                        <option value="PATIENT GROUP">PATIENT GROUP</option>
                        <option value="PHARMACY">PHARMACY</option>
                        <option value="RADIOLOGY CENTER">RADIOLOGY CENTER</option>
                        <option value="TEACHING INSTITUTION">TEACHING INSTITUTION</option>

                </select>
            </div>
            )
        }

        return (
            <div>
                <div className={styles.wrapper}>
                    <div className={styles.title}>
                        <div></div>
                        <div className={styles.TitleText}>Add Organization</div>
                        <div className={styles.close} onClick={() => {
                            this.props.close()
                        }}><i className="fas fa-times"></i></div>
                    </div>
                    <div className={styles.body}>
                        <div className={styles.required}>{this.state.ERROR_MESSAGE}</div>
                        <div className={styles.required}>* REQUIRED FIELD</div>
                        <div className={styles.toprow}>
                            <div className={styles.set}>
                                <div className={styles.inputTitle}>ORG NAME  <div className={styles.required}>*</div></div>
                                    <input className={this.state.ISVALID ? styles.DefaultInputForm : styles.ErrorInputForm} value={this.state.ORG_NAME !== "" ? this.state.ORG_NAME : ""} onChange={(event) => {
                                        if (event.target.value === "") {
                                            this.setState({ ORG_NAME: ""})
                                        }
                                        else {
                                            this.setState({ORG_NAME: event.target.value.toUpperCase()})
                                        }
                                    }}/>
                            </div>
                        </div>

                        <div className={styles.middlerow}>
                            {orgTypeFilter}
                            <div className={styles.set}>
                                <div className={styles.inputTitle}>ADD REASON  <div className={styles.required}>*</div></div>
                                <select className={this.state.ISVALID ? styles.DefaultInputForm : styles.ErrorInputForm} value={this.state.REQUEST_TYPE !== "" ? this.state.REQUEST_TYPE : ""} onChange={(event) => {
                                    if (event.target.value == "") {
                                        this.setState({ REQUEST_TYPE: ""})
                                    }
                                    else {
                                        this.setState({REQUEST_TYPE: event.target.value})
                                    }
                                }}>
                                    <option value=""></option>
                                    {reqReasons}
                                </select>
                            </div>
                            <div className={styles.set}>
                                <div className={styles.inputTitle}>COMMENTS</div>
                                <textarea className={styles.DefaultInputForm} value={this.state.REQUEST_NOTE !== "" ? this.state.REQUEST_NOTE : ""} onChange={(event) => {
                                    if (event.target.value === "") {
                                        this.setState({ REQUEST_NOTE: ""})
                                    }
                                    else {
                                        this.setState({ REQUEST_NOTE: validateRequestNotes(event.target.value) });
                                    }
                                }} />
                            </div>

                        </div>

                        <div className={styles.buttonRow}>
                            <div id={styles.cancel} onClick={() => {
                                this.props.close();
                            }}>CANCEL</div>
                            <div className={this.state.Wiggle && this.state.Wiggle !== undefined ? styles.Wiggle : ""} id={styles.submit} onClick={() => {
                                if (this.state.ORG_NAME !== "" && this.state.ORG_TYPE !== "" && this.state.REQUEST_TYPE !== "") {
                                    let temp = this.state
                                    delete temp.ISVALID
                                    if (this.state.ORG_NAME.length > 255) {
                                        this.setState({ ERROR_MESSAGE: 'Org name should be less than 255 characters \n' });
                                    }
                                    else if (this.state.ORG_NAME.includes("\t")) {
                                        this.setState({ ERROR_MESSAGE: 'Org name contains tab space characters. Please remove the tab space characters \n' });
                                    }
                                    else if (this.state.ORG_NAME.includes("\r\n")) {
                                        this.setState({ ERROR_MESSAGE: 'Org name contains line feed characters. Please remove the line feed characters \n' });
                                    }
                                    else {
                                        this.props.nextPage(1, temp);
                                    }
                                }
                                else {
                                    this.setState({
                                        Wiggle: true,
                                        ISVALID: false
                                    })
                                    setTimeout(() => {
                                        this.setState({Wiggle: false})
                                    }, 500);
                                }
                            }}>NEXT</div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

}

export default AddHCO_P1;
