import React from 'react'
import Popup from '../Popup'
import Spinner from '../Spinner'
import icon from '../../assets/export.png'

const downloadFile = async (props) => {
    const header = []
    for (let i = 0; i < Object.keys(props.config).length; i++) {
        if (Object.values(props.config)[i].col === "Multiple") {
            header.push("NAME")
        }
        else {
            header.push(Object.values(props.config)[i].col)
        }
    }
    let csv = [header]
    for (let i = 0; i < props.data.length; i++) {
        let row = []
        for (let j = 0; j < header.length; j++) {
            if (header[j] === "NAME") {
                row.push(`${props.data[i].FIRST_NAME} ${props.data[i].LAST_NAME}`)
            }
            else if (header[j] === "CHANGED_TARGET_VALUE") {
                row.push(`${props.data[i].TARGET_TYPE ? props.data[i].TARGET_TYPE : ''}`)
            }
            else if (header[j] === "CURRENT_ADDRESS") {
                row.push(`${props.data[i].PRIMARY_ADDRESS1} ${props.data[i].PRIMARY_CITY} ${props.data[i].PRIMARY_STATE} ${props.data[i].PRIMARY_ZIP5}`)
            }
            else if (header[j] === "BEST_ADDRESS") {
                row.push(`${props.data[i].BEST_ADDRESS1} ${props.data[i].BEST_CITY} ${props.data[i].BEST_STATE} ${props.data[i].BEST_ZIP5}`)
            }
            else {
                row.push(props.data[i][header[j]])
            }
        }
        csv.push(row)
    }
    csv = csv.join('\r\n');
    const fileName = `${props.name} - TERRITORY ${props.terr}`;
    const blob = new Blob([csv], { type: 'text/csv' });
    const href = await URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = fileName + ".csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return true
}

function Export(props) {
    let body = <Spinner />
    if (downloadFile(props)) {
        body = (
            <div className={"pop-body"}>
                <div className={"pop-titleRow"}>
                    <div className={"pop-side"}></div>
                    <div className={"pop-directions"}></div>
                    <div className={"pop-side"}></div>
                </div>
                <div className={"pop-content"}>
                    <div className={"pop-icon"}><img src={icon} alt={"Could not load..."}/></div>
                    <div className={"pop-text"}>Your file will begin exporting automatically.</div>
                </div>
                <div className={"pop-buttonRow"}>
                    <button type="button" id={"pop-submit"} onClick={() => {
                            props.close()
                        }
                    }>CLOSE</button>
                </div>
            </div>
        )
    }
    return (
        <Popup {...props} body={body} title={"Export"} />
    )

}

export default Export
