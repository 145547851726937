import React, { Component } from 'react'
import Styles from './WorkflowStatus.module.css'
import Axios from '../../components/Axios'
import axios from 'axios'

class WorkflowFile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDownLoaded: this.props.isDownloaded,
            buttonStyle: 'newbuttonsmall'
        };
    }
    componentDidMount() {

      
    }

    openFile() {

        let json = {};
        json.INSTANCE_ID = this.props.data.WORKFLOW_INSTANCE_ID
        json.FILE_ID = this.props.file.FILE_ID;
        json.USER_NAME = this.props.username;
        let contType = this.props.file.CONTENT_TYPE;

        axios({
            method: 'post',
            responseType: 'arraybuffer', //Force to receive data in a Blob Format
            url: '/api/workflow/get_workflow_file',
            data: json
        }).then(res => {
            let fileName = `${this.props.file.FILE_NAME}`;
            const blob = new Blob([res.data], {type: 'application/octet-stream'})
            const link = document.createElement('a');
   
            link.href = window.URL.createObjectURL(blob);
            link.download = `${fileName}`;
            link.click();
        }).catch(error => {
            console.log(error.message);
        });

        
    }
    render() {

        var hasBeenDownloaded = this.props.downloaded ? 'DOWNLOADED' : '';

        return (   

             <div className={this.props.odd ? [Styles.detailblockrow, Styles.odd].join(" ") : [Styles.detailblockrow]}>

                <div className={[Styles[this.state.buttonStyle]].join(" ")} onClick={(e) => {

                    this.openFile();
                    this.props.refreshFiles();

                }}>OPEN</div><div className={Styles.fileblocktitle}>{this.props.file.FILE_NAME}</div>
                <div className={Styles.downloaded}>
                   <i> {hasBeenDownloaded}</i>
                </div>

             </div>



          


        )
    }

}
export default WorkflowFile;







