import React, { Component } from 'react';
import styles from '../css/ChangeRequestRow.module.css';

class ChangeRequestRow extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    exists(str) {
        if (str) {
            return str
        }
        else {
            return ""
        }
    }

    render() {
        let ret = []
        if (this.props.type.includes("EXUS") || this.props.type.includes("Ex-US")) {



            if (this.props.type === "EXUS Add to - CRM" || this.props.type === "EXUS Remove from - CRM") {




                let title = ""
                let type = ""
                let toggle = false;
                if (this.props.type === "EXUS Add to - CRM" || this.props.type === "EXUS Remove from - CRM") {
                    title = "CRM"
                    type = this.props.user.team ? `UNIVERSE_FLAG_TM${this.props.user.team}` : "UNIVERSE_FLAG"
                    toggle = true;
                }
                else if (this.props.type === "EXUS Add to - Spend" || this.props.type === "EXUS Remove from - Spend") {
                    title = "Spend"
                    type = `AGGSPEND_FLAG`
                    toggle = true;
                }
                if (toggle) {
                    if (this.props.prev !== undefined && this.props.prev !== null) {
                        ret.push(<div key={1} className={styles.row}>
                            <div className={[styles.prev, styles.item].join(' ')}>
                                <div className={styles.subtitle}>{`PREVIOUS ${title}`}</div>
                                <div className={styles.content}>{this.props.prev !== undefined && this.props.prev !== null ? this.props.prev[type] === "Y" ? "Added" : "Removed" : ""}</div>
                            </div>
                            <div className={[styles.arrow, styles.item].join(' ')}>
                                <i className="fas fa-long-arrow-alt-right"></i>
                            </div>
                            <div className={[styles.next, styles.item].join(' ')}>
                                <div className={styles.subtitle}>{`UPDATED ${title}`}</div>
                                <div className={styles.content}>{this.props.prev[type] !== "Y" ? "Added" : "Removed"}</div>
                            </div>
                        </div>)
                    }
                    else {
                        ret.push(<div key={1} className={styles.row}>
                            <div className={[styles.next, styles.item].join(' ')}>
                                <div className={styles.subtitle}>{`UPDATED ${title}`}</div>
                                <div className={styles.content}>{this.props.prev[type] !== "Y" ? "Added" : "Removed"}</div>
                            </div>
                        </div>)
                    }
                }
                else {
                    for (let i = 0; i < Object.keys(this.props.next).length; i++) {
                        if (Object.keys(this.props.next)[i] !== "PROF_ID" && Object.keys(this.props.next)[i] !== "ORG_ID") {
                            if (this.props.prev !== undefined && this.props.prev !== null) {
                                ret.push(<div key={i} className={styles.row}>
                                    <div className={[styles.prev, styles.item].join(' ')}>
                                        <div className={styles.subtitle}>{`PREVIOUS ${Object.keys(this.props.next)[i]}`}</div>
                                        <div className={styles.content}>{this.props.prev[Object.keys(this.props.next)[i]]}</div>
                                    </div>
                                    <div className={[styles.arrow, styles.item].join(' ')}>
                                        <i className="fas fa-long-arrow-alt-right"></i>
                                    </div>
                                    <div className={[styles.next, styles.item].join(' ')}>
                                        <div className={styles.subtitle}>{`UPDATED ${Object.keys(this.props.next)[i]}`}</div>
                                        <div className={styles.content}>{this.props.next[Object.keys(this.props.next)[i]]}</div>
                                    </div>
                                </div>)
                            }
                            else {
                                ret.push(<div key={i} className={styles.row}>
                                    <div className={[styles.next, styles.item].join(' ')}>
                                        <div className={styles.subtitle}>{`UPDATED ${Object.keys(this.props.next)[i]}`}</div>
                                        <div className={styles.content}>{this.props.next[Object.keys(this.props.next)[i]]}</div>
                                    </div>
                                </div>)
                            }
                        }
                    }
                }




            } else  {

                let prev = '', arrow = ''
                let title = '', address1 = '', address2 = '', city = '', state = '', zip = ''
                if (this.props.type === "EXUS Set Secondary Address" || "EXUS Add Secondary Address" || this.props.type === "EXUS Set Primary Address") {
                    title = 'NEW ADDRESS'
                    address1 = this.props.next.ADDRESS1
                    address2 = this.props.next.ADDRESS2
                    city = this.props.next.CITY
                    state = this.props.next.STATE
                    zip = this.props.next.ZIPCODE
               
                } else if (this.props.type.includes("Deactivate")) {
                    title = 'DEACTIVATE ADDRESS'
                    address1 = this.props.next.ADDRESS1
                    address2 = this.props.next.ADDRESS2
                    city = this.props.next.CITY
                    state = this.props.next.STATE
                    zip = this.props.next.ZIPCODE
                }
                ret.push(<div key={2} className={styles.row}>
                    {prev}
                    {arrow}
                    <div className={[styles.next, styles.item].join(' ')}>
                        <div className={styles.subtitle}>{title}</div>
                        <div className={styles.content}>{address1}{address2 ? ` ${address2}` : ""}, {city}, {state} {zip}</div>
                    </div>
                </div>)
            }

        }
        else if (this.props.type.includes("New HCP Add")) {
            ret.push(<div key={1} className={styles.row}>
                <div className={[styles.next, styles.item].join(' ')}>
                    <div className={styles.subtitle}>{this.props.type}</div>
                    <div className={styles.middlerow}>
                        <div className={[styles.set, styles.odd].join(' ')}>
                            <div className={styles.contentTitle}>NAME</div>
                            <div className={styles.content}>{this.exists(this.props.data.FIRST_NAME)} {this.exists(this.props.data.MIDDLE_NAME)} {this.exists(this.props.data.LAST_NAME)}</div>
                        </div>
                        <div className={styles.set}>
                            <div className={styles.contentTitle}>SPECIALTY</div>
                            <div className={styles.content}>{this.exists(this.props.data.SPECIALTY)}</div>
                        </div>
                        <div className={[styles.set, styles.odd].join(' ')}>
                            <div className={styles.contentTitle}>DEGREE</div>
                            <div className={styles.content}>{this.exists(this.props.data.DEGREE)}</div>
                        </div>
                        <div className={styles.set}>
                            <div className={styles.contentTitle}>PRIMARY ADDRESS</div>
                            <div className={styles.content}>{this.exists(this.props.data.ADDRESS1)} {this.exists(this.props.data.ADDRESS2)} {this.exists(this.props.data.CITY)}, {this.exists(this.props.data.STATE)}, {this.exists(this.props.data.ZIPCODE)}</div>
                        </div>
                        <div className={[styles.set, styles.odd].join(' ')}>
                            <div className={styles.contentTitle}>COMMENTS</div>
                            <div className={styles.content}>{this.exists(this.props.data.REQUEST_NOTE)}</div>
                        </div>
                    </div>
                </div>
            </div>)
        }
        else if (this.props.type.includes("New HCO Add")) {
            ret.push(<div key={1} className={styles.row}>
                <div className={[styles.next, styles.item].join(' ')}>
                    <div className={styles.subtitle}>{this.props.type}</div>
                    <div className={styles.middlerow}>
                        <div className={[styles.set, styles.odd].join(' ')}>
                            <div className={styles.contentTitle}>ORG NAME</div>
                            <div className={styles.content}>{this.exists(this.props.data.ORG_NAME)}</div>
                        </div>
                        <div className={styles.set}>
                            <div className={styles.contentTitle}>ORG TYPE</div>
                            <div className={styles.content}>{this.exists(this.props.data.ORG_TYPE)}</div>
                        </div>
                        <div className={[styles.set, styles.odd].join(' ')}>
                            <div className={styles.contentTitle}>PRIMARY ADDRESS</div>
                            <div className={styles.content}>{this.exists(this.props.data.ADDRESS1)} {this.exists(this.props.data.ADDRESS2)} {this.exists(this.props.data.CITY)}, {this.exists(this.props.data.STATE)}, {this.exists(this.props.data.ZIPCODE)}</div>
                        </div>
                        <div className={styles.set}>
                            <div className={styles.contentTitle}>COMMENTS</div>
                            <div className={styles.content}>{this.exists(this.props.data.REQUEST_NOTE)}</div>
                        </div>
                    </div>
                </div>
            </div>)
        } else if (this.props.type.includes("SLN")) {

            ret.push(<div key={1} className={styles.row}>
                <div className={[styles.next, styles.item].join(' ')}>
                    <div className={styles.subtitle}>{this.props.type}</div>
                    <div className={styles.middlerow}>
                        <div className={[styles.set, styles.odd].join(' ')}>
                            <div className={styles.contentTitle}>SLN NUMBER</div>
                            <div className={styles.content}>{this.props.next.SLN_NUMBER}</div>

                        </div>
                        <div className={[styles.set, styles.odd].join(' ')}>
                            <div className={styles.contentTitle}>SLN EXPIRATION</div>
                            <div className={styles.content}>{this.props.next.SLN_EXPIRATION}</div>
                        </div>
                        {/*<div className={[styles.set, styles.odd].join(' ')}>*/}
                        {/*    <div className={styles.contentTitle}>SLN STATUS</div>*/}
                        {/*    <div className={styles.content}>{this.props.next.STATUS}</div>*/}
                        {/*</div>*/}
                        <div className={[styles.set, styles.odd].join(' ')}>
                            <div className={styles.contentTitle}>OVERALL SAMPLEABILITY</div>
                            <div className={styles.content}>{this.props.next.SLNOVERALLSAMPLEABILITY}</div>
                        </div>
                        <div className={[styles.set, styles.odd].join(' ')}>
                            <div className={styles.contentTitle}>SLN ADDRESS</div>
                            <div className={styles.content}>{this.props.next.ADDRESS1 + ", " + this.props.next.CITY + " " + this.props.next.STATE + " " + this.props.next.ZIPCODE}</div>
                        </div>


                    </div>
                </div>
            </div>)

        }
        else if (this.props.next.ADDRESS1) {
            let prev = '', arrow = ''
            let title = '', address1 = '', address2 = '', city = '', state = '', zip = ''
            if (this.props.type === "Set Secondary Address" || "Add Secondary Address" || this.props.type === "Set Primary Address") {
                title = 'NEW ADDRESS'
                address1 = this.props.next.ADDRESS1
                address2 = this.props.next.ADDRESS2
                city = this.props.next.CITY
                state = this.props.next.STATE
                zip = this.props.next.ZIPCODE
            }
            else if (this.props.type === "HCO Address Change" || this.props.type === "Update Request") {
                title = 'NEW ADDRESS'
                address1 = this.props.next.ADDRESS1
                address2 = this.props.next.ADDRESS2
                city = this.props.next.CITY
                state = this.props.next.STATE
                zip = this.props.next.ZIPCODE
                prev = (<div className={[styles.prev, styles.item].join(' ')}>
                    <div className={styles.subtitle}>{'PREVIOUS ADDRESS'}</div>
                    <div className={styles.content}>{this.props.prev.ADDRESS1}{this.props.prev.ADDRESS2 ? ` ${this.props.prev.ADDRESS2}` : ""}, {this.props.prev.CITY}, {this.props.prev.STATE} {this.props.prev.ZIP5}</div>
                </div>)
                arrow = (<div className={[styles.arrow, styles.item].join(' ')}>
                    <i className="fas fa-long-arrow-alt-right"></i>
                </div>)
            } else if (this.props.type.includes("Deactivate")) {
                title = 'DEACTIVATE ADDRESS'
                address1 = this.props.next.ADDRESS1
                address2 = this.props.next.ADDRESS2
                city = this.props.next.CITY
                state = this.props.next.STATE
                zip = this.props.next.ZIPCODE
            }
            ret.push(<div key={2} className={styles.row}>
                {prev}
                {arrow}
                <div className={[styles.next, styles.item].join(' ')}>
                    <div className={styles.subtitle}>{title}</div>
                    <div className={styles.content}>{address1}{address2 ? ` ${address2}` : ""}, {city}, {state} {zip}</div>
                </div>
            </div>)
        }
        else if (this.props.type === "Add affiliation to professional" || this.props.type === "Remove affiliation from professional") {
            ret.push(<div key={2} className={styles.row}>
                <div className={[styles.next, styles.item].join(' ')}>
                    <div className={styles.subtitle}>{this.props.type}</div>
                    <div className={styles.content}>
                        {`${this.props.prev.FIRST_NAME ? this.props.prev.FIRST_NAME : ''} ${this.props.prev.LAST_NAME ? this.props.prev.LAST_NAME : ''}
                        ${this.props.type === "Add affiliation to professional" ? "to" : "from"}
                        ${this.props.prev.LAST_NAME ? this.props.next.ORG_ID : this.props.next.PROF_ID}`}
                    </div>
                </div>
            </div>)
        }
        else {
            if (this.props.type === "New HCP Add - CRM" || this.props.type === "New HCP Add - CTMS" || this.props.type === "New HCP Add - Spend") {
                ret.push(<div key={2} className={styles.row}>
                    <div className={[styles.next, styles.item].join(' ')}>
                        <div className={styles.subtitle}>{this.props.type}</div>
                        <div className={styles.content}>{`${this.props.next.FIRST_NAME} ${this.props.next.LAST_NAME}`}</div>
                    </div>
                </div>)
                if (!this.props.hcp) {
                    this.props.newHCP()
                }
            }
            else if (this.props.type === "New HCO Add - CRM" || this.props.type === "New HCO Add - CTMS" || this.props.type === "New HCO Add - Spend") {
                ret.push(<div key={2} className={styles.row}>
                    <div className={[styles.next, styles.item].join(' ')}>
                        <div className={styles.subtitle}>{this.props.type}</div>
                        <div className={styles.content}>{`${this.props.next.ORG_NAME}`}</div>
                    </div>
                </div>)
                if (!this.props.hco) {
                    this.props.newHCO()
                }
            }
            else {




                let title = ""
                let type = ""
                let toggle = false;
                if (this.props.type === "Add to - CRM" || this.props.type === "Remove from - CRM") {
                    title = "CRM"
                    type = this.props.user.team ? `UNIVERSE_FLAG_TM${this.props.user.team}` : "UNIVERSE_FLAG"
                    toggle = true;
                }
                else if (this.props.type === "Add to - CTMS" || this.props.type === "Remove from - CTMS") {
                    title = "CTMS"
                    type = `CTMS_FLAG`
                    toggle = true;
                }
                else if (this.props.type === "Add to - Spend" || this.props.type === "Remove from - Spend") {
                    title = "Spend"
                    type = `AGGSPEND_FLAG`
                    toggle = true;
                }
                if (toggle) {
                    if (this.props.prev !== undefined && this.props.prev !== null) {
                        ret.push(<div key={1} className={styles.row}>
                            <div className={[styles.prev, styles.item].join(' ')}>
                                <div className={styles.subtitle}>{`PREVIOUS ${title}`}</div>
                                <div className={styles.content}>{this.props.prev !== undefined && this.props.prev !== null ? this.props.prev[type] === "Y" ? "Added" : "Removed" : ""}</div>
                            </div>
                            <div className={[styles.arrow, styles.item].join(' ')}>
                                <i className="fas fa-long-arrow-alt-right"></i>
                            </div>
                            <div className={[styles.next, styles.item].join(' ')}>
                                <div className={styles.subtitle}>{`UPDATED ${title}`}</div>
                                <div className={styles.content}>{this.props.prev[type] !== "Y" ? "Added" : "Removed"}</div>
                            </div>
                        </div>)
                    }
                    else {
                        ret.push(<div key={1} className={styles.row}>
                            <div className={[styles.next, styles.item].join(' ')}>
                                <div className={styles.subtitle}>{`UPDATED ${title}`}</div>
                                <div className={styles.content}>{this.props.prev[type] !== "Y" ? "Added" : "Removed"}</div>
                            </div>
                        </div>)
                    }
                }
                else {
                    for (let i = 0; i < Object.keys(this.props.next).length; i++) {
                        if (Object.keys(this.props.next)[i] !== "PROF_ID" && Object.keys(this.props.next)[i] !== "ORG_ID") {
                            if (this.props.prev !== undefined && this.props.prev !== null) {
                                ret.push(<div key={i} className={styles.row}>
                                    <div className={[styles.prev, styles.item].join(' ')}>
                                        <div className={styles.subtitle}>{`PREVIOUS ${Object.keys(this.props.next)[i]}`}</div>
                                        <div className={styles.content}>{this.props.prev[Object.keys(this.props.next)[i]]}</div>
                                    </div>
                                    <div className={[styles.arrow, styles.item].join(' ')}>
                                        <i className="fas fa-long-arrow-alt-right"></i>
                                    </div>
                                    <div className={[styles.next, styles.item].join(' ')}>
                                        <div className={styles.subtitle}>{`UPDATED ${Object.keys(this.props.next)[i]}`}</div>
                                        <div className={styles.content}>{this.props.next[Object.keys(this.props.next)[i]]}</div>
                                    </div>
                                </div>)
                            }
                            else {
                                ret.push(<div key={i} className={styles.row}>
                                    <div className={[styles.next, styles.item].join(' ')}>
                                        <div className={styles.subtitle}>{`UPDATED ${Object.keys(this.props.next)[i]}`}</div>
                                        <div className={styles.content}>{this.props.next[Object.keys(this.props.next)[i]]}</div>
                                    </div>
                                </div>)
                            }
                        }
                    }
                }











            }














        }
        return (ret)
    }
}

export default ChangeRequestRow;
