import React, {useState} from 'react'
import styles from './Titles.module.css'

function Titles(props) {
    const [columnSorting, setColumnSorting] = useState(-1)
    const [descending, setDescending] = useState(false)
    const [sorting, setSorting] = useState(false)

    const sort = (i, title) => {
        if (props.selected !== undefined) {
            props.setSelected(i)
        }
        if(!sorting) {
            let temp = JSON.parse(JSON.stringify(props.data))
            let des
            if (columnSorting === i) {
                des = !descending
            }
            else {
                des = true
            }
            setSorting(true)
            temp.sort((a,b) => {
                let valA, valB
                valA = a[title]
                valB = b[title]
                if (valA === null) {
                    return des ? 1 : -1
                }
                if (valB === null) {
                    return des ? -1 : 1
                }
                if (!isNaN(valA && valB) ? parseInt(valA) < parseInt(valB) : valA < valB) {
                    return des ? -1 : 1;
                }
                if (!isNaN(valA && valB) ? parseInt(valA) > parseInt(valB) : valA > valB) {
                    return des ? 1 : -1;
                }
                return 0
            })
            props.setData(temp)
            setColumnSorting(i)
            setDescending(des)
            setSorting(false)
        }
    }
    
    let cols = []
    if (props.titles) {
        for (let i = 0; i < props.titles.length; i++) {
            const title = props.titles[i]
            const arrow = columnSorting === i ?
                descending
                    ? <i className="fas fa-caret-down"></i>
                    : <i className="fas fa-caret-up"></i>
                : ""
            cols.push(
                <th className={[styles.contentTitles, styles.col].join(' ')} key={title} onClick={() => sort(i, title)}>
                    <div className={[styles.flexContainer, title.left ? styles.left : styles.center, styles.wrap, props.selected === i ? styles.selected : styles.border].join(" ")}>
                        <div className={styles.placeholder}></div>
                        {title}
                        <div className={styles.placeholder}>
                            {arrow}
                        </div>
                    </div>
                </th>
            )
        }
    }

    return (
        <tr className={styles.titleRow}>
            {cols}
        </tr>
    )
}

export default Titles
