import React, {Component} from 'react';
import AddressLookUpEXUS from './../../../globalcomponents/AddressLookUpExUs'

class EditAddressEXUS extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ADDRESS1: props.next.ADDRESS1,
            ADDRESS2: props.next.ADDRESS2,
            CITY: props.next.CITY,
            STATE: props.next.STATE,
            ZIPCODE: props.next.ZIPCODE,
            values: Object.values(props.next),
            keys: Object.keys(props.next),
            ISVALID: true
        };
    }

    updateAddress(address) {

      
        this.setState({
            ADDRESS1: address.entADDRESS1,
            ADDRESS2: address.entADDRESS2,
            CITY: address.entCITY,
            COUNTRY_CODE: address.entCOUNTRY_CODE,
            ZIPCODE: address.entZIPCODE
        }, () => {
            this.props.setAddress(this.state);
        });

    }

    render() {
        return (<AddressLookUpEXUS updateAddress={this.updateAddress.bind(this)} error={this.state.ISVALID} info={this.state}/>);
    }
}

export default EditAddressEXUS;
