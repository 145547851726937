import React, {Component} from 'react';
import styles from '../css/Result.module.css';
import Toggle from './../../../globalcomponents/Toggle'
import Axios from './../../../components/Axios'

class Result extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: props.data,
            odd : (props.number % 2 === 0 ? false : true)
        };
    }

    componentWillReceiveProps(props) {
        this.setState({ data: props.data})
    }

   
    render() {

        var teamID = this.props.data.TEAM_ID;
        if (this.props.data.TEAM_ID == '12' && this.props.data.EMP_TYPE == 'OBD') { teamID = '11'; }
        else {
            teamID = this.props.data.TEAM_ID ? this.props.data.TEAM_ID : "-";
        }


        return (
            <div className={this.state.odd ? [styles.Result, styles.odd].join(' ') : styles.Result}>
                <div className={styles.longlink} onClick={() => {
                    Axios('/api/admin/impersonate/start', { userId: this.props.data.ID }, (results) => {
                    });
                }}>
                    {this.props.data.USER_ID ? this.props.data.USER_ID : "-"}
                </div>
                <div className={styles.longname}>
                    {(this.props.data.FIRST_NAME || this.props.data.LAST_NAME) ? this.props.data.FIRST_NAME + " " + this.props.data.LAST_NAME: "-"}
                </div>
                <div className={styles.shortrole}>
                    {this.props.data.EMP_TYPE ? this.props.data.EMP_TYPE : "-"}
                </div>
                <div className={styles.short}>{this.props.data.TERR_ID ? this.props.data.TERR_ID : "-"}</div>
                <div className={styles.long}>{this.props.data.TERR_NAME ? this.props.data.TERR_NAME : "-"}</div>
                <div className={styles.long}>{this.props.data.PARENT_ID ? this.props.data.PARENT_ID : "-"}</div>
                <div className={styles.short}>{teamID}</div>
                <div className={styles.short}>{this.props.data.EMP_TERR_STATUS ? <Toggle click={() => {
                    if (this.props.data.EMP_TERR_STATUS === 'ACTIVE') {
                        this.props.changeAdminView(4, undefined, this.props.number, this.props.data.ID, false);
                    }
                    if (this.props.data.EMP_TERR_STATUS === 'INACTIVE') {
                        this.props.changeAdminView(3, undefined, this.props.number, this.props.data.ID, false);
                    }
                }} active={this.props.data.EMP_TERR_STATUS === 'ACTIVE' ? true : false}/> : "-"}</div>
                <div className={[styles.action, styles.long].join(' ')}>
                    <div className={styles.link} onClick={() => {
                            this.props.changeAdminView(2, undefined, this.props.number, this.props.data.ID);
                        }}>Edit</div>
                </div>
            </div>
        );
    }
}

export default Result;
