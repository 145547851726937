import React, {useState} from 'react'
import styles from './css/ExerciseAdminPage.module.css'
import TopBar from '../TopBar'
import UserPanel from './components/UserPanel'
import StatusPanel from './components/StatusPanel'

const ExerciseAdminPage = props => {

    const [statusData, setStatusData] = useState({})
    const [approvePopup, setApprovePopup] = useState(false)

    return (
        <div className={styles.admin}>
            {approvePopup ? <props.approve {...props} close={() => {
                setApprovePopup(false)
            }}/> : ""}
            {props.extraPage ? <props.extraPage {...props} close={() => {
                props.setExtraPage(false)
            }}/> : ""}
            <TopBar name={props.exercise.NAME}/>
            <div className={styles.pannel}>
                <div className={styles.buttonRow}>
                    {["ADMIN"].includes(props.user.role) ? (
                        <div className={styles.buttonWrapper}>
                            <button className={[props.approved || props.locked ? styles.approved :"", styles.appoveAll].join(" ")} onClick={() => {
                                    setApprovePopup(true)
                                }} disabled={props.approved || props.locked}>EXECUTE EXERCISE UPDATES</button>
                        </div>
                    ) : ''}
                    {props.extraButtons ? [...props.extraButtons] : ""}
                </div>
                <div className={styles.wrapper}>
                    <UserPanel {...props} adminExercise={props.adminExercise} statusData={statusData}/>
                    <StatusPanel {...props} adminExercise={props.adminExercise} statusData={statusData} setStatusData={setStatusData}/>
                </div>
            </div>
        </div>
    )
}

export default ExerciseAdminPage