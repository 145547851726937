import React from 'react';
import styles from '../css/TitleButton.module.css'

function TitleButton(props) {
    let buttons = [<button key={'firstButton'} className={styles.button} onClick={props.buttonClick}>{props.buttonText}</button>]
    if (props.moreButtons) {
        for (let i = 0; i < props.moreButtons.length; i++) {
            buttons.push(<button key={i} className={styles.button} onClick={() => {
                props.moreButtons[i].onClick()}}>{props.moreButtons[i].buttonText}</button>)
        }
    }

    return (
        <div className={styles.titleBar}>
            <div className={styles.title}>{props.title}</div>
            {buttons}
        </div>
    )
}

export default TitleButton
