import React, {useState} from 'react'
import Popup from '../../../../components/Popup'
import Success from '../../../../components/Success'
import Axios from '../../../../components/Axios'

function Approve(props) {
    const [success, setSuccess] = useState()

    const submit = () => {
        setSuccess(null)
        let temp = {
            ID: props.user.role === "ADMIN" ? props.adminExercise.ID : props.exercise.ID,
            type: props.user.role === "ADMIN" ? props.adminExercise.CUSTOMER_TYPE : props.exercise.CUSTOMER_TYPE,
            team: props.user.role === "ADMIN" ? props.adminExercise.TEAM : props.exercise.TEAM
        }
        Axios('/api/targeting/get-approved-exercises', temp, (data) => {
            setSuccess(true)
            props.setApproved(true)
        });
    }

    let body = (
        <div className={"pop-body"}>
            <div className={"pop-required"}></div>
            <div className={"pop-required"}></div>
            <div className={"pop-titleRow"}>
                <div className={"pop-side"}></div>
                <div className={"pop-directions"}>Are you sure you want to execute the updates from the Targeting Exercise?</div>
                <div className={"pop-side"}></div>
            </div>
            <div className={"pop-buttonRow"}>
                <button type="button" id={"pop-cancel"} onClick={() => {
                        props.close()
                    }
                }>CLOSE</button>
                <button id={"pop-submit"} onClick={submit}>SUBMIT</button>
            </div>
        </div>
    )
    if (success !== undefined) {
        return (
            <Success {...props} success={success} successMessage={"Success! You have successfully executed the updates for the Targeting exercise."}/>
        )
    }
    else {
        return (
            <Popup {...props} body={body} title={"Execute Exercise Updates"} />
        )
    }
}

export default Approve
