import React from 'react';
import styles from '../css/Details_Address_History.module.css';
import firstCapital from '../../../helper/firstCapital';

function Details_Address_History(props) {

    // build address history results 

    let staticAddresses = []

    if (props.addresses.length > 0) {

        let pri = props.addresses[0][0].PRI_ADDRESS1 + ' ' + (props.addresses[0][0].PRI_ADDRESS2 == null ? '' : props.addresses[0][0].PRI_ADDRESS2) + ' ' + props.addresses[0][0].PRI_CITY + ' ' + props.addresses[0][0].PRI_STATE + ' ' + props.addresses[0][0].PRI_ZIP5;
        let best = props.addresses[1][0].BEST_ADDRESS1 + ' ' + (props.addresses[1][0].BEST_ADDRESS2 == null ? '' : props.addresses[1][0].BEST_ADDRESS2) + ' ' + props.addresses[1][0].BEST_CITY + ' ' + props.addresses[1][0].BEST_STATE + ' ' + props.addresses[1][0].BEST_ZIP5;


        for (let j = 0; j < props.addresses[3].length; j++) {
            let style = j % 2 === 0 ? [styles.Content, styles.Odd].join(' ') : styles.Content
            let otr = props.addresses[3][j].ADDRESS1 + ' ' + (props.addresses[3][j].ADDRESS2 == null ? '' : props.addresses[3][j].ADDRESS2) + ' ' + props.addresses[3][j].CITY + ' ' + props.addresses[3][j].STATE + ' ' + props.addresses[3][j].ZIP5;
            let dt = new Date(props.addresses[3][j].LAST_UPDATE_DATE);

            staticAddresses.push([
                <div key={1} className={styles.Results}>
                    <div className={style}>{otr}</div>
                    <div className={style}>{props.addresses[3][j].SRC}</div>
                    <div className={style}>{ dt.toJSON().slice(0, 10).replace(/-/g, '/') }</div>

                </div>
            ])

        }
    }

    let addressHistory = (<div className={styles.Affiliations}>
        <div className={styles.AffiliationsTopBar}>
            <div>Address History</div>

        </div>
        <div className={styles.titles}>
            <div className={styles.Title}>ADDRESS</div>
            <div className={styles.Title}>TYPE</div>
            <div className={styles.Title}>DATE</div>

            
        </div>
        <div className={styles.ResultsWrapper}>
            {
                props.addresses.length > 0 ?
                    staticAddresses
                :
                <div className={styles.Results}>
                    <div className={[styles.Content, styles.Odd].join(' ')}>—</div>
                    <div className={[styles.Content, styles.Odd].join(' ')}>—</div>
                    <div className={[styles.Content, styles.Odd].join(' ')}>—</div>
           
                </div>
            }
        </div>
    </div>) 

    return addressHistory;
}

export default Details_Address_History;
