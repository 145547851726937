import React, {Component} from 'react';
import DegreeLookUp from '../../../globalcomponents/DegreeLookUp';
import SpecialtyLookUp from '../../../globalcomponents/SpecialtyLookUp';
import OrgTypeLookup from '../../../globalcomponents/OrgTypeLookUp';
import styles from '../css/EditField.module.css';

class EditField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value
        };
    }

    render() {
        let options = []
        if (["Degree Update"].includes(this.props.type)) {
            for (var i = 0; i < Object.keys(DegreeLookUp).length; i++) {
                options.push(<option key={i} value={Object.keys(DegreeLookUp)[i]}>{Object.keys(DegreeLookUp)[i]} - {Object.values(DegreeLookUp)[i]}</option>)
            }
        }
        else if (["Gender Update"].includes(this.props.type)) {
            options.push(<option key={0} value="M">Male</option>)
            options.push(<option key={1} value="F">Female</option>)
        }
        else if (["Specialty Update"].includes(this.props.type)) {
            for (let i = 0; i < Object.keys(SpecialtyLookUp).length; i++) {
                options.push(<option key={i} value={Object.keys(SpecialtyLookUp)[i]}>{Object.keys(SpecialtyLookUp)[i]} - {Object.values(SpecialtyLookUp)[i]}</option>)
            }
        }
        else if (["Update Org Type"].includes(this.props.type)) {
            for (let i = 0; i < Object.keys(OrgTypeLookup).length; i++) {
                options.push(<option key={i} value={Object.keys(OrgTypeLookup)[i]}>{Object.values(OrgTypeLookup)[i]}</option>)
            }
        }
        else if (["Update HCO Status", "HCP Status Change", "HCP Active", "HCP Deceased", "HCP Retired", "HCP Unknown"].includes(this.props.type)) {
            options.push(<option key={0} value="Active">Active</option>)
            options.push(<option key={1} value="Retired">Retired</option>)
            options.push(<option key={2} value="Deceased">Deceased</option>)
            options.push(<option key={3} value="Unknown">Inactive</option>)
        }

        if (options.length > 0) {
            return (
                <select className={styles.select} value={this.state.value} onChange={(event) => {
                let temp = event.target.value
                    this.setState({value: temp}, ()=> {
                        this.props.setValue(this.props.index, temp)
                    })
                }}>
                {options}
                </select>
            )
        }
        else {
            return (<input className={styles.select} value={this.state.value} onChange={(event) => {
                let temp = event.target.value
                    this.setState({value: temp}, ()=> {
                        this.props.setValue(this.props.index, temp)
                    })
                }}></input>)
        }
    }
}

export default EditField;
