import { makeAutoObservable, observable, computed, action, reaction, runInAction } from "mobx";
import Axios from '../components/Axios/Axios'

export default class ProfStore {

    constructor(rootStore) {
        this.rootStore = rootStore;
        this.user = this.rootStore.userStore.user;
        makeAutoObservable(this);
    }

    alignment = observable({});
    profId = observable(0);
    loading = observable(true);
    user = observable({});

    addressAlignment = action((profId, puser) => {

        runInAction(() => {
            this.loading = true;
        });

        this.profId = profId;
        let teamID = puser.team ? puser.team : 11;
        if (puser.role == 'PRT' || puser.role == 'COMMERCIAL DERM' || puser.isDermAdmin == 'true') { teamID = 31; }
        const params = {
            profId: profId,
            teamId: teamID,
            isAdmin: puser.role == "ADMIN" ? 1 : 0,
            isSphase: puser.role == "SPHASE" ? 1 : 0
        }

        Axios('/api/details/hcp/alignment', params, (data) => {
            runInAction(() => {
                this.alignment = data;
                this.loading = false;
            });
        }, (error) => console.error(error));
    })
}