import React, { Component } from 'react'
import Styles from './WorkflowDialogBox.module.css'
import './react-datepicker.css';
import options from './options.json';
import DatePicker from 'react-datepicker';
import { forEach } from 'underscore';

class WorkflowDialogField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            required: this.props.required,
            checked: false,
            uploadFiles: [],
            uploadFileNames: [],
            fileDisplay:[],
            cssClass: [Styles.wfinput]
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
        this.handleGroupFiles = this.handleGroupFiles.bind(this);
        this.handleFiles = this.handleFiles.bind(this);
        this.isValid = this.isValid.bind(this);
        this.resetWiggle = this.resetWiggle.bind(this);
    }
    componentDidMount() {

        var cssClass = [Styles.wfinput];
        if (this.props.required) {
            cssClass = [Styles.wfinput, Styles.Wiggle].join(" ");
        }
        if (this.props.value != null && this.props.value != "") {
            cssClass = [Styles.wfinput].join(" ");
        }

        switch (this.props.field.DataType) {
            case "fileuploadgroup":
                
                this.updateFileDisplay(this.props.fileArr);

                break;
            case "fileupload":
                this.setState({ value: this.props.value, cssClass: cssClass })
                break;
            case "bool":

                var val = this.props.value;

                this.setState({ checked: val, cssClass: cssClass  })
                break;
            case "datetime":
                var dtvalue = null;
                if (this.props.value && this.props.value != undefined && this.props.value != '') {
                    dtvalue = new Date(this.props.value)

                }
                this.setState({ value: dtvalue, cssClass: cssClass  })
                break;
            default:
                this.setState({ value: this.props.value, cssClass: cssClass  })
                break;

        }

    }
    isValid(value) {
        if (this.state.required && (value == undefined || value == null || value == '' || value == "")) {

            return (false);

        } else {
            return(true)
        }
    }
    resetWiggle(value) {

        if (this.props.disabled) {

            //all controls are disabled here
            this.setState({
               // cssClass: [Styles.wfinput, Styles.disablecontrol].join(" ")
            })


        } else {

            if (this.isValid(value)) {
                this.setState({
                    cssClass: [Styles.wfinput]
                })

            } else {

                this.setState({
                    cssClass: [Styles.wfinput, Styles.Wiggle].join(" ")
                })
            }

        }
        
       
    }
    handleChange(event) {


        
     //   alert(event.target.checked);

        if (this.state.required && (event.target.value == undefined || event.target.value == null || event.target.value == '' || event.target.value == "")) {

            this.setState({
                cssClass: [Styles.wfinput, Styles.Wiggle].join(" "), value: event.target.value
            })

        } else {
            this.setState({
                cssClass: [Styles.wfinput], value: event.target.value
            })
        }
        
        this.props.setValue(event.target, this.props.field.ActionName, this.props.field.IsDisabled);
    }
    handleCheckChange(event) {

        var CheckBoxValue = event.target.checked;

       
        this.setState({ checked: CheckBoxValue })

        this.props.setCheckValue(event.target, this.props.field.ActionName, CheckBoxValue);
    }
    updateFileDisplay(fileArr) {

        var fileDisplay = [];

        fileArr.forEach((element) => {
           
            fileDisplay.push(<div className={Styles.filerow}> <div className={Styles.fileleft}>{element.title} </div> <div className={Styles.fileright}>{element.name}</div><br /></div>);
        

        });
        this.setState({ fileDisplay: fileDisplay });
    }
    handleFiles(event) {
        var fn = event.currentTarget.files[0].name;

        var newName = this.props.pn + "_" + event.currentTarget.name.replace("wfdata_", "") + "." + fn.substring(fn.lastIndexOf('.') + 1, fn.length);
        this.props.setUploadFile(event.currentTarget.files[0], newName);

    

    }
    handleGroupFiles(event) {
       

        var fn = event.currentTarget.files[0].name;
        var newName = this.props.pn + "_" + event.currentTarget.name.replace("wfdata_", "") + "." + fn.substring(fn.lastIndexOf('.') + 1, fn.length);

        var fileArr = this.props.setUploadFile(event.currentTarget.name.replace("wfdata_", ""), event.currentTarget.files[0], newName);

        this.updateFileDisplay(fileArr);

    }
    getOptions() {

        let optionsarr = [];
        var jsonArray = [];
        var list = options.options;
        var matchName = this.props.field.SelectOptions;

        for (let i = 0; i < list.length; i++) {
            var optionName = list[i].name;
            if (optionName == matchName) {
                jsonArray = list[i].data;
                for (let j = 0; j < jsonArray.length; j++) {
                    optionsarr.push(<option value={jsonArray[j].shortname} >{jsonArray[j].shortname}</option>);
                }
            }
        }

        return optionsarr;
    }
    getFieldDiv() {

        var returnField = (<div></div>);


        //Tooltip
        var hideToolTip = true;
        if (this.props.field.ToolTip != null && this.props.field.ToolTip != undefined && this.props.field.ToolTip != '') {
            hideToolTip = false;
        }

        switch (this.props.field.DataType) {

            case "text":
                returnField = (
                    <div className={hideToolTip ? Styles.tooltiphidden : Styles.tooltip}>
                        <input type='text' name={this.props.field.FieldName} id={this.props.field.FieldName} disabled={this.props.disabled} className={this.state.cssClass} value={this.state.value}
                            onChange={this.handleChange} />
                        <div className={Styles.tooltiptext}>{this.props.field.ToolTip} </div>
                    </div>);
                break;
            case "fileupload":
                returnField = (<div><div>
                    <input type='file' name={this.props.field.FieldName} id={this.props.field.FieldName} className={[Styles.wfinput, this.state.cssClass].join(" ")} value={this.props.value} onChange={this.handleFiles} />
                </div></div >);
                break;
            case "seperator":
                returnField = (<div>&nbsp;</div >);
                break;
            case "filelist":
                returnField = (<div ><div className={Styles.fileList} >
                    {this.state.uploadFiles}
                </div></div>);
                break;
            case "fileuploadgroup":
                returnField = (<div >
                   
                    <div >
                        <div className={Styles.fileHeader}>W9</div>
                        <input type='file' name={"W9"} id={"W9"} className={[Styles.wfinput, this.state.cssClass].join(" ")} onChange={this.handleGroupFiles} />
                    </div >

                    <div className={Styles.stretch}><div className={Styles.fileHeader}>Completed Application</div><input type='file' name={"Application"} id={"Application"} className={[Styles.wfinput].join(" ")} onChange={this.handleGroupFiles} /></div >

                    <div className={Styles.stretch}><div className={Styles.fileHeader}>Agenda</div><input type='file' name={"Agenda"} id={"Agenda"} className={[Styles.wfinput, this.state.cssClass].join(" ")} onChange={this.handleGroupFiles} /></div >
                    <div className={Styles.stretch}> <div className={Styles.fileHeader}>Prospectus</div><input type='file' name={"Prospectus"} id={"Prospectus"} className={[Styles.wfinput, this.state.cssClass].join(" ")} onChange={this.handleGroupFiles} /></div >
                    <div className={Styles.stretch}><div className={Styles.fileHeader}>Request Letter</div> <input type='file' name={"Request"} id={"Request"} className={[Styles.wfinput, this.state.cssClass].join(" ")} onChange={this.handleGroupFiles} /></div >
                    <div><div className={Styles.fileHeader}>Other File</div><input type='file' name={"Other"} id={"Other"} className={[Styles.wfinput, this.state.cssClass].join(" ")} onChange={this.handleGroupFiles} /></div >

                    <div>&nbsp;</div >

                    
                    <div className={Styles.fileList} >
                        {this.state.fileDisplay}

                    </div>
                </div >);
                break;
            case "currency":
                returnField = (<div className={hideToolTip ? Styles.tooltiphidden : Styles.tooltip}><input name={this.props.field.FieldName}
                    id={this.props.field.FieldName} type='number' className={this.state.cssClass} value={this.state.value} onChange={this.handleChange} /><div className={Styles.tooltiptext}>{this.props.field.ToolTip} </div></div>);
                break;
            case "phone":
                returnField = (<div className={hideToolTip ? Styles.tooltiphidden : Styles.tooltip}><input name={this.props.field.FieldName}
                    id={this.props.field.FieldName} type='text' placeholder={ "(xxx) xxx-xxxx"} className={this.state.cssClass} value={this.state.value} onChange={this.handleChange} /><div className={Styles.tooltiptext}>{this.props.field.ToolTip} </div></div>);
                break;
            case "datetime":

                returnField = (
                    <DatePicker className={Styles.reactdatetime} selected={this.state.value} onChange={(event) => {

                        // var dt = new Date(event);
                        // alert(dt.getDate());

                        this.props.setDateValue(this.props.field.FieldName, event)
                        this.setState({ value: event })

                    }}  minDate={new Date()} />);

                break;
            case "search":
                returnField = (<div className={Styles.tooltip}><input name={this.props.field.FieldName} id={this.props.field.FieldName} type='text' className={this.state.cssClass} value={this.state.value} /> <i className="fas fa-search"></i><div className={Styles.tooltiptext}>{this.props.field.ToolTip} </div></div>);
                break;
            case "select":
                returnField = (<div className={hideToolTip ? Styles.tooltiphidden : Styles.tooltip}><select name={this.props.field.FieldName} id={this.props.field.FieldName} className={this.state.cssClass} value={this.state.value}
                    onChange={this.handleChange} >
                    <option value='' ></option>
                    {this.getOptions()}
                </select> <div className={Styles.tooltiptext}>{this.props.field.ToolTip} </div></div>
                );
                break;
            case "bool":
                var isChecked = this.state.checked ? true : false;

                returnField = (<div className={hideToolTip ? Styles.tooltiphidden : Styles.tooltip}>
                    <input name={this.props.field.FieldName} id={this.props.field.FieldName} type='checkbox' checked={this.state.checked} onChange={this.handleCheckChange} />
                    <div className={Styles.tooltiptext}>{this.props.field.ToolTip} </div></div>);
                break;
            case "textarea":
                returnField = (<div className={hideToolTip ? Styles.tooltiphidden : Styles.tooltip}><div></div><textarea name={this.props.field.FieldName} id={this.props.field.FieldName}
                    className={this.state.cssClass} onChange={this.handleChange} >{this.props.value}</textarea><div className={hideToolTip ? Styles.tooltiphidden : Styles.tooltip}> </div></div>);
                break;
            default:
                break;
        }

        return returnField;

    }

    render() {
        return (


            <div className={Styles.formdiv}>
                {this.props.field.DataType == 'fileuploadgroup' ? '' : <div className={Styles.formtitle}>{this.props.field.FieldTitle}</div>}
                <div className={this.props.field.DataType == 'fileuploadgroup' ? Styles.filescontrol :Styles.formcontrol}>{this.getFieldDiv()}</div>
            </div>

        )

    }

}
export default WorkflowDialogField;