import React, {useState, useEffect} from 'react';
import {Switch, Route, useRouteMatch} from "react-router-dom";
import Search from './Search/Search'
import Admin from './Admin/Admin'
import Spinner from '../../components/Spinner'
import Axios from '../../components/Axios'
import ResolveDisputes from './ResolveDisputes/ResolveDisputes';

function BestAddress(props) {
    let {path, url} = useRouteMatch()

    const [loaded, setLoaded] = useState(false)
    const [approved, setApproved] = useState(false)

    useEffect(() => {
        if (props.exercise) {
            setLoaded(true)
        }
    }, [props.exercise])

    useEffect(() => {
        if (loaded) {
            Axios('/api/best-address-exercise/approval-check', {ID: props.exercise.ID}, (data) => {
                setApproved(data.length > 0)
            });
        }
    }, [loaded])


    if (loaded) {
        if (["ADMIN", "OAS", "OBD"].includes(props.user.role)) {
            return (<Switch>
                <Route path={`${path}/search/:idSlug/:terrSlug`}>
                    <Search url={url} approved={approved} {...props}/>
                </Route>
                <Route exact path={`${path}/`}>
                    <Admin path={`${path}`} approved={approved} setApproved={setApproved} {...props}/>
                </Route>
                <Route exact path={`${path}/disputes`}>
                    <ResolveDisputes approved={approved} setApproved={setApproved} {...props}/>
                </Route>
            </ Switch>)
        } else {
            return (<Search url={url} approved={approved} {...props}/>)
        }
    }
    return (<Spinner/>)
}

export default BestAddress
