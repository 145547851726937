import React from 'react'
import styles from '../css/Result.module.css';

function UserTitles(props) {
    return (
        <div className={styles.contentTitles}>
            <div className={[styles.col, styles.short].join(' ')}><div className={styles.placeholder} />REP NAME</div>
            <div className={[styles.col, styles.short, styles.center].join(' ')}>TERRITORY</div>
            <div className={[styles.col, styles.short, styles.center].join(' ')}>MOVE IN COUNT</div>
            <div className={[styles.col, styles.short, styles.center].join(' ')}>MOVE OUT COUNT</div>
            <div className={[styles.col, styles.select, styles.center].join(' ')}>STATUS</div>
            <div className={[styles.col, styles.short, styles.center].join(' ')}>ACTIONS</div>
        </div>
    );
}

export default UserTitles