import React, { Component } from 'react'
import Styles from './WorkflowStatus.module.css'

import Axios from '../../components/Axios'
class WorkflowApproverBlock extends Component {
    constructor(props) {
        super(props);
        this.state = {
          
        };
    }
   
   
    render() {

        
        return (   
          

            <div className={Styles.approverblock}>
                <div className={Styles.apptitle}>{this.props.name} ({this.props.role})</div>
  
                <div className={Styles.appemail}>{this.props.email} </div>
                <div className={ Styles[this.props.color]}>{this.props.status} </div>
            </div>



        )
    }

}
export default WorkflowApproverBlock;







