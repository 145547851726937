import { makeAutoObservable, observable, computed, action, reaction, runInAction } from "mobx";
import authService from '../api/api-authorization/AuthorizeService'
import axios from 'axios'

export default class UserStore {

    constructor(rootStore) {
        this.rootStore = rootStore
        this.isAuthenticated = this.getAuthServiceAuthenticated();
        this.user = this.getAuthServiceUser();
        this.token = this.getAuthServiceToken();

        makeAutoObservable(this, {
            user: observable,
            token: observable,
            isAuthenticated: observable,
            isAdmin: computed,
            isExUsUser: computed,
            IsEDAdmin: computed,
            IsOcneAdmin: computed,
            isDermAdmin: computed,
            IsHemoncOps: computed,
            IsDermOps: computed,
            IsHemoncComp: computed,
            IsDermComp: computed,
            IsMarketAccessRole: computed

        })
    }

    removeCookies() {
        document.cookie = "path=/; cookiename= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
    }

    get isAdmin() {
        return this.user.role === 'ADMIN';
    }

    get isExUsUser() {

        let exUsUser = false;

        if (this.user.isExUsUser === 'true') {

            exUsUser = true;
        }
        return exUsUser;
    }

    //get isExUsUser() {

    //    let exUsUser = false;

    //    if (this.user.isExUsUser === 'true') {

    //        exUsUser = true;
    //    }
    //    return exUsUser;
    //}
    get IsEDAdmin() {

        let ed = false;

        if (this.user.IsEDAdmin === 'true') {

            ed = true;
        }
        return ed;
    }
    get IsHemoncOps() {

        let mops = false;

        if (this.user.IsHemoncOps === 'true') {

            mops = true;
        }
        return mops;
    }
    get IsDermOps() {

        let mops = false;

        if (this.user.IsDermOps === 'true') {

            mops = true;
        }
        return mops;
    }
    get IsHemoncComp() {

        let mops = false;

        if (this.user.IsHemoncComp === 'true') {

            mops = true;
        }
        return mops;
    }
    get IsOcneAdmin() {

        let mops = false;

        if (this.user.IsOcneAdmin === 'true') {

            mops = true;
        }
        return mops
    }
    get IsDermComp() {

        let mops = false;

        if (this.user.IsDermComp === 'true') {

            mops = true;
        }
        return mops;
    }
    get IsMarketAccessRole() {

        let mar = false;

        if (this.user.IsMarketAccessRole === 'true') {

            mar = true;
        }
        return mar;
    }
    getAuthServiceAuthenticated = action(async (callback) => {

        let authenticated;

        await authService.isAuthenticated().then((res) => {
            authenticated = res;
        });

        runInAction(() => {
            this.isAuthenticated = authenticated
        });

        if (callback) callback();
    });

    getAuthServiceUser = action(async (callback) => {

        let user;

        await authService.getUser().then((res) => {
            user = res;
        });

        runInAction(() => {
            this.user = user
            this.getAuthServiceToken()
        });

        if (callback) callback();
    });

    getAuthServiceToken = action(async (callback) => {

        let token;

        await authService.getAccessToken().then((res) => {
            token = res
        });

        runInAction(() => {
            this.token = token
            this.cacheToken()
        });

        if (callback) callback();
    });

    cacheToken = action((callback) => {
        axios.interceptors.request.use(
            (config) => {
                if (this.token) {
                    config.headers.Authorization = `Bearer ${this.token}`;
                }
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );
        if (callback) callback();
    });

    doLogout = action(async () => {
        runInAction(async () => {
            await authService.signOut(this.user);
            this.user = null
            this.isAuthenticated = null
            this.token = null
            localStorage.clear()
            this.removeCookies();
            console.log('Sucessfully logged out.')
        });
    });
}
