import React from 'react';
import styles from '../css/ResultTitles.module.css';

function ResultTitles(props) {
    let arr = [];
    for (let i = 0; i < props.Titles.length; i++) {
        if (!props.nonsortable.includes(i)) {
            arr.push(<div key={i} onClick={() => {
                    props.Sort(i)
                }} className={props.long.includes(i) ? [styles.Title, styles.hasArrow, styles.long].join(' ') : [styles.Title, styles.hasArrow].join(' ')}>
                <div className={styles.holder}></div>
                <div className={styles.content}>{props.Titles[i]}</div>
                <div className={props.sortBy === i
                    ? [styles.down, styles.sortBy].join(' ')
                    : styles.down}>
                    {
                        props.ascending
                            ? <i className="fas fa-caret-up"></i>
                            : <i className="fas fa-caret-down"></i>
                    }
                </div>
            </div>)
        } else {
            if (i === 0) {
                arr.push(<div key={i} className={[styles.Title, styles.status].join(' ')}>{props.Titles[i]}</div>)
            } else {
                arr.push(<div key={i} className={props.long.includes(i) ? [styles.Title, styles.long].join(' ') : styles.Title}>{props.Titles[i]}</div>)
            }
        }
    }
    
    return (<div className={styles.ResultTitles}>
        {arr}
    </div>);
}

export default ResultTitles;
