import React, {useState} from 'react'
import Styles from '../css/DialogBoxEditUserPasswordReset.module.css'
import Axios from '../../../components/Axios/Axios'

export default function DialogBoxEditUserPasswordReset(props) {
    const [firstName] = useState(props.data[props.recordIndex].FIRST_NAME)
    const [lastName] = useState(props.data[props.recordIndex].LAST_NAME)
    const [workEmail] = useState(props.data[props.recordIndex].WORK_EMAIL)
    const [username] = useState(props.data[props.recordIndex].USER_ID)
    const [requestorId] = useState(props.user.email)
    const [requestorName] = useState(props.user.userName)
    const [requestorTeam] = useState(props.user.team)
    const [requestorTerritory] = useState(props.user.terr)

    const sendPasswordResetEmail = () => {
        let stateInfo = {
            EMAIL: workEmail
        }
        Axios('/api/admin/reset-password', stateInfo, () => {
            props.changeAdminView(-1)
        })
    }

    return (
        <div className={Styles.editScreen}>
            <div className={Styles.title}>
                <div></div>
                <div className={Styles.TitleText}>Send Password Reset Email</div>
                <div className={Styles.close} onClick={() => {props.changeAdminView(2)}}><i className='fas fa-times'></i></div>
            </div>
            <div className={Styles.body}>
                <div className={Styles.TextBox}>
                    Are you sure you want to send a password reset email to:
                    <div className={Styles.BoldText}>{firstName} {lastName}</div>
                    {workEmail}
                </div>
                <div className={Styles.buttonRow}>
                    <div id={Styles.cancel} onClick={() => {props.changeAdminView(2)}}>CANCEL</div>
                    <div id={Styles.submit} onClick={() => {sendPasswordResetEmail()}}>SEND</div>
                </div>
            </div>
        </div>
    )
}