import axios from 'axios'

function Axios(endpoint, json, callback) {
    axios.post(`${endpoint}`, json)
    .then((res) => {
        if (callback !== undefined) {
            callback(res.data)
        }
    })
    .catch((error) => {
        console.error(error);
    });
}

export default Axios;
