import React, { useState, useEffect, useContext } from 'react'
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom'

import Styles from './App.module.css'
import Spinner from './components/Spinner/Spinner'
import Ie from './components/Ie'

import { RootStoreContext } from "./_stores/rootStore";
import Axios from './components/Axios'
import AuthorizeRoute from './api/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './api/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './api/api-authorization/ApiAuthorizationConstants';
import Navbar from './components/Navbar/Navbar'

//pages
import HcpDetails from './pages/HcpDetails/HcpDetails'
import HcoDetails from './pages/HcoDetails/HcoDetails'
import Home from './pages/Home/Home'
import Search from './pages/Search/Search'
import Xsearch from './pages/ExUsSearch/Xsearch'
import ExUsDetails from './pages/ExUsDetails/ExUsDetails'
import RegionalPlan from './pages/RegionalPlan/RegionalPlan'
import BestAddress from './pages/BestAddress/BestAddress'
import Admin from './pages/Admin/Admin'
import Targeting from './pages/Targeting/Targeting'
import './global.css';
import Workflow from './pages/Workflow/Workflow'

export default function App(props) {
    const rootStore = useContext(RootStoreContext)
    const { user, token, isAuthenticated } = rootStore.userStore;
    const [searchState, setSearchState] = useState(null)
    const [exercises, setExercises] = useState([])
    const [ie] = useState(!!window.document.documentMode)
    const [hashes, setHashes] = useState({ ctms: {}, spend: {}, crm: {} })
    const [loaded, setLoaded] = useState(false)

    const history = useHistory();

    useEffect(() => {
        if (!!isAuthenticated && !!user & !!token) {
            getRegionalPlanExercises()
            getTargetingExercises()
        }
    }, [loaded])

    const hash = (profid, type, active) => {
        let ctms = { ...hashes.ctms }
        let spend = { ...hashes.spend }
        let crm = { ...hashes.crm }
        if (type === 0) {
            spend[profid] = active
        } else if (type === 1) {
            crm[profid] = active
        } else if (type === 2) {
            ctms[profid] = active
        }
        setHashes({ ctms, spend, crm })
    }

    const getRegionalPlanExercises = () => {

        if (user.isDermAdmin == 'true' || user.role == 'PRT' || user.role == 'COMMERCIAL DERM' || user.role == 'PHARMFORCE') { user.team = '31' }
        //if (user.team = '12' && user.role == 'OBD') { user.team = '11' }

        if ((['ADMIN'].includes(user.role) || ['51', '53'].includes(user.team))) {
            Axios('/api/admin/regional-plan-details', user, (results) => {
                if (results) {
                    for (let i = 0; i < results.length; i++) {
                        let start = new Date(results[i].START_DATE)
                        let end = new Date(results[i].END_DATE)
                        let today = new Date()
                        if ((start.getTime() < today.getTime()) && (today.getTime() < end.getTime())) {
                            setExercises({ ...exercises, regionalPlan: results[i] })
                            setLoaded(true)
                            break;
                        }
                    }
                }
            })
        }
        else {
            setLoaded(true)
        }
    }

    const getTargetingExercises = () => {

        if (user.isDermAdmin == 'true' || user.role == 'PRT' || user.role == 'COMMERCIAL DERM' || user.role == 'PHARMFORCE' ) { user.team = '31' }

        if (['ADMIN'].includes(user.role) || (user.role == 'DBD' && user.team == '31')) {
            Axios('/api/targeting/get-all-exercises', user, (results) => {
                if (results) {
                    for (let i = 0; i < results.length; i++) {
                        results[i].END_DATE = results[i].END_DATE.slice(0, 10).replace(/-/g, '/').replace(/\//g, '-');
                        results[i].START_DATE = results[i].START_DATE.slice(0, 10).replace(/-/g, '/').replace(/\//g, '-');
                    }
                    setExercises({ ...exercises, targeting: results })
                    setLoaded(true)
                }
            });
        }
        else if (['11', '12', '21', '31', '32'].includes(user.team)) {
            Axios('/api/targeting/get-my-exercise', user, (results) => {
                if (results) {
                    results.END_DATE = results.END_DATE.slice(0, 10).replace(/-/g, '/').replace(/\//g, '-')
                    results.START_DATE = results.START_DATE.slice(0, 10).replace(/-/g, '/').replace(/\//g, '-')
                    setExercises({ ...exercises, targeting: results })
                    setLoaded(true)
                }
            })
        }
        else {
            setLoaded(true)
        }
    }
    const getBestAddressExercises = () => {
        if (user.isDermAdmin == 'true' || user.role == 'PRT' || user.role == 'COMMERCIAL DERM' || user.role == 'PHARMFORCE') { user.team = '31' }

        if (['ADMIN'].includes(user.role)) {
            Axios('/api/admin/bestaddressexercise/get-exercises', user, (results) => {
                if (results) {
                    results.END_DATE = results.END_DATE.slice(0, 10).replace(/-/g, '/').replace(/\//g, '-')
                    results.START_DATE = results.START_DATE.slice(0, 10).replace(/-/g, '/').replace(/\//g, '-')
                    setExercises({ ...exercises, targeting: results })
                    setLoaded(true)
                }
            })
        }
        else {
            setLoaded(true)
        }
    }

    const saveState = (newSearchState) => {
        setSearchState(newSearchState)
    }

    if (ie) {
        return <Ie />
    }

    const api_regex = /^\/api\/.*/
    const connect_regex = /^\/connect\/.*/
    const swagger_regex = /^\/swagger\/.*/
    // if using "/api/" in the pathname, don't use React Router
    if (api_regex.test(window.location.pathname) ||
        connect_regex.test(window.location.pathname) ||
        swagger_regex.test(window.location.pathname)
            ) {
        return <div /> // must return at least an empty div
    } else {
        if (!loaded && !isAuthenticated && !user && !token) {
            return <Spinner />
        } else {
            return (
                <Router history={history}>
                    <div className={Styles.App}>
                        <div className={Styles.MainView}>
                            <Switch>
                                <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
                                <AuthorizeRoute
                                    path='/'
                                    component={Navbar}
                                    exercises={exercises}
                                />
                            </Switch>
                            <AuthorizeRoute
                                path='/'
                                exact
                                component={Home}
                                exercises={exercises}
                            />
                            <AuthorizeRoute
                                path='/hcp'
                                exact
                                component={Search}
                                saveState={saveState}
                                searchState={searchState}
                                user={user}
                                hash={hash}
                                hashes={hashes}
                            />
                            <AuthorizeRoute
                                path='/xus'
                                exact
                                component={Xsearch}
                                saveState={saveState}
                                searchState={searchState}
                                user={user}
                                hash={hash}
                                hashes={hashes}
                            />
                            <AuthorizeRoute
                                path='/hcp/details/:PROF_ID'
                                exact
                                component={HcpDetails}
                                user={user}
                                hash={hash}
                                hashes={hashes}
                            />
                            <AuthorizeRoute
                                path='/hco'
                                exact
                                component={Search}
                                saveState={saveState}
                                searchState={searchState}
                                user={user}
                                hash={hash}
                                hashes={hashes}
                            />
                            <AuthorizeRoute
                                path='/xus/details/:PROF_ID'
                                exact
                                component={ExUsDetails}
                                user={user}
                                hash={hash}
                                hashes={hashes}
                            />
                            <AuthorizeRoute
                                path='/Workflow'
                                user={user}
                                component={Workflow}

                            />
                            <AuthorizeRoute
                                path='/hco/details/:ORG_ID'
                                exact
                                component={HcoDetails}
                                user={user}
                                hash={hash}
                                hashes={hashes}
                            />
                            <AuthorizeRoute
                                path='/regionalplan/:RPID'
                                exact
                                component={RegionalPlan}
                                rp={exercises.regionalPlan}
                                user={user}
                            />
                            <AuthorizeRoute
                                path='/targeting'
                                component={Targeting}
                                user={user}
                                exercise={exercises.targeting}
                            />
                            <AuthorizeRoute path='/bestaddress' component={BestAddress} user={user} exercise={exercises.bestAddress} />
                            <AuthorizeRoute path='/admin' exact component={Admin} user={user} />
                        </div>
                    </div>
                </Router>
            )
        }
    }
}