import React, {Component} from 'react';
import styles from '../css/Details_ChangeRequests.module.css';
import DetailsChangeRequestsRecord from './Details_ChangeRequests_Record'

class Details_ChangeRequests extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    firstCapital(str) {
        if (str !== undefined) {
            return str.toLowerCase().replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();})
        }
        return "";
    }

    mapStatus(status) {
        let ret = {}
        if (status === "Automatically Approved") {
            ret.status = "Approved"
            ret.comments = false
        }
        if (status === "Approved") {
            ret.status = "Approved"
            ret.comments = false
        }
        if (status === "NEEDS MR") {
            ret.status = "Open"
            ret.comments = false
        }
        if (status === "REQUEST_RCVD") {
            ret.status = "Open"
            ret.comments = true
        }
        if (status === "In Progress") {
            ret.status = "In Progress"
            ret.comments = false
        }
        if (status === "DECLINED") {
            ret.status = "Declined"
            ret.comments = true
        }
        if (status === "Rubber Stamped") {
            ret.status = "Approved"
            ret.comments = true
        }
        if (status === "Completed") {
            ret.status = "Approved"
            ret.comments = true
        }
        return ret
    }

    render() {
        let temp = [] //the returned records
        if (this.props.data) {

            let reversed = []
            if (Array.isArray(this.props.data)) {
                reversed = this.props.data.slice().reverse() 
            } else {
                reversed = this.props.data
            }
         //   let reversed = this.props.data.slice().reverse() //reverses the records to show latest first
            let hash = {}
            let parents = [] //array of all parent requests
            for (let i = 0; i < reversed.length; i++) {
                hash[reversed[i].ACTION_ID] = {record: reversed[i], children: [], childrenRecords: []}
                if ((!["Cancel Request", "Update Request", "HCP Update Info", "HCP Update Address", "HCO Update Info"].includes(reversed[i].ORIGINAL_REQUST_TYPE))) {
                    parents.push(reversed[i].ACTION_ID)
                }
            }
            for (let i = 0; i < parents.length; i++) {
                temp.push(<DetailsChangeRequestsRecord key={parents[i]} odd={i % 2 === 0 ? true : false} data={hash[parents[i]].record} editScreen={this.props.editScreen} />);
            }
        }
        return (
            <div className={styles.ChangeRequests}>
                <div className={styles.TopBar}>Change Requests</div>
                <div className={styles.titles}>
                    <div className={styles.icon}></div>
                    <div className={styles.Title}>ACTION ID</div>
                    <div className={styles.Title}>STATUS</div>
                    <div className={styles.Title}>REQUEST DATE</div>
                    <div className={styles.Title}>REQUEST TYPE</div>
                    <div className={styles.Title}>REQUESTOR NAME</div>
                    <div className={styles.Title}>COMMENTS</div>
                    <div className={styles.Title}>LAST MODIFIED DATE</div>
                </div>
                <div className={styles.ResultsWrapper}>
                    {
                        this.props.data.length > 0 ?
                        temp
                        :
                        <div className={styles.Results}>
                            <div className={[styles.Odd, styles.record].join(" ")}>
                                <div className={styles.icon}></div>
                                <div className={styles.Content}>—</div>
                                <div className={styles.Content}>—</div>
                                <div className={styles.Content}>—</div>
                                <div className={styles.Content}>—</div>
                                <div className={styles.Content}>—</div>
                                <div className={styles.Content}>—</div>
                                <div className={styles.Content}>—</div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default Details_ChangeRequests;
