import { createContext } from 'react';
import { configure } from 'mobx'
import UserStore from "./userStore";
import ProfStore from "./profStore";
import OrgStore from "./orgStore";
import ExusStore from "./exusStore";

configure({ enforceActions: 'always' })

class RootStore {
    constructor() {
        this.userStore = new UserStore(this);
        this.profStore = new ProfStore(this);
        this.orgStore = new OrgStore(this);
        this.exusStore = new ExusStore(this);
    }
}

export const RootStoreContext = createContext(new RootStore());

