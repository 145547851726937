import React, {Component} from 'react';
import styles from './NotificationBox.module.css';

class NotificationBox extends Component {
    render() {
        return (
            <div className={this.props.errorStatus ? styles.errorAlert : styles.defaultAlert}>
              <span className={styles.closebtn} onClick={() => {
                this.props.callback()
              }}>&times;</span> 
              {this.props.message}
            </div>
        )
    }
}

export default NotificationBox;
