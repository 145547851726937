import React, {Component} from 'react';
import styles from './AddressLookUp.module.css';
import Axios from './Axios';
import StateLookUp from './StateLookUp'

class AddressLookUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            streetOne: `${this.props.info.ADDRESS1 ? this.props.info.ADDRESS1 : ''}`,
            streetTwo: `${this.props.info.ADDRESS2 ? this.props.info.ADDRESS2 : ''}`,
            city: `${this.props.info.CITY ? this.props.info.CITY : ''}`,
            state: `${this.props.info.STATE ? this.props.info.STATE : ''}`,
            zip: `${this.props.info.ZIPCODE ? this.props.info.ZIPCODE : ''}`,
            addresses: [],
            clicked: false,
            clickedAddress: 0,
            validated: false,
            validatedMessage: 'Address is not verified.',
            loaded: false,
            ERROR_MESSAGE: ''
        };
    }

    componentDidMount() {
        if (this.state.streetOne !== '' || this.state.streetTwo !== '' || this.state.city !== '' || this.state.state !== '' || this.state.zip !== '') {
            this.checkAddress()
        }
        else {
            this.setState({
                loaded: true
            })
        }
    }

    updateStreetOne(streetOne) {
        this.setState({streetOne: streetOne}, () => {
            if (this.state.streetOne !== '' && this.state.streetOne.length > 3) {
                Axios('/api/global/address-lookup', this.state, (response) => {
                    if (response.status) {
                        let validated = false
                        let validatedMessage = ''
                        for (let i = 0; i < response.data.length; i++) {
                            if (response.data[i].ADDRESS1 !== streetOne) {
                                validated = false
                                validatedMessage = 'Address is not verified.'
                                break;
                            }
                            else {
                                validated = true
                                validatedMessage = 'Address is verified.'
                                response = []
                            }
                        }
                        this.setState({
                            addresses: response.data,
                            clicked: false,
                            clickedAddress: 0,
                            validated: validated,
                            validatedMessage: validatedMessage,
                            loaded: true
                        });
                    }
                });
            }
            else {
                this.setState({
                    addresses: [],
                    clicked: false,
                    clickedAddress: 0,
                    validated: false,
                    validatedMessage: 'Address is not verified.',
                    loaded: true
                });
            }
            let address = {
                streetOne: streetOne,
                streetTwo: this.state.streetTwo,
                city: this.state.city,
                state: this.state.state,
                zip: this.state.zip
            }
            this.props.updateAddress(address)
        });
    }

    checkAddress() {
        let address = {
            streetOne: this.state.streetOne,
            streetTwo: this.state.streetTwo,
            city: this.state.city,
            state: this.state.state,
            zip: this.state.zip
        }
        Axios('/api/global/address-check', address, (response) => {
            if (response.status) {
                this.setState({
                    validated: true,
                    validatedMessage: 'Address is verified.',
                    loaded: true
                });
            }
            else {
                this.setState({
                    validated: false,
                    validatedMessage: 'Address is not verified.',
                    loaded: true
                });
            }
        });
        this.props.updateAddress(address)
    }

    validateAddress(fieldName, value, displayName) {
        if (value.length > 100) {
            this.setState({ ERROR_MESSAGE: `${displayName} should be less than 100 characters` });
        }
        else {
            this.setState({ ERROR_MESSAGE: '' })
        }
        this.setState({ [fieldName]: value.replace(/\t|\n|\r/g, '') });

    }

    render() {
        let stateOptions = []
        for (var i = 0; i < Object.keys(StateLookUp).length; i++) {
            stateOptions.push(<option key={i} value={Object.keys(StateLookUp)[i]}>{Object.keys(StateLookUp)[i]}</option>)
        }
        let addresses = [];
        for (let i = 0; i < this.state.addresses.length; i++) {
            addresses.push(
                <div key={i} className={styles.address} onClick={() => {
                    let address = {
                        streetOne: this.state.addresses[i].ADDRESS1
                    }
                    if (this.state.addresses[i].ADDRESS2 !== undefined) {
                        address.streetTwo = this.state.addresses[i].ADDRESS2
                    }
                    else {
                        address.streetTwo = ''
                    }
                    if (this.state.addresses[i].CITY !== undefined) {
                        address.city = this.state.addresses[i].CITY
                    }
                    else {
                        address.city = ''
                    }
                    if (this.state.addresses[i].STATE !== undefined) {
                        address.state = this.state.addresses[i].STATE
                    }
                    else {
                        address.state = ''
                    }
                    if (this.state.addresses[i].ZIP5 !== undefined) {
                        address.zip = this.state.addresses[i].ZIP5
                    }
                    else {
                        address.zip = ''
                    }
                    this.props.updateAddress(address)
                    this.setState({
                        streetOne: address.streetOne,
                        streetTwo: address.streetTwo,
                        city: address.city,
                        state: address.state,
                        zip: address.zip,
                        addresses: [],
                        clicked: true,
                        clickedAddress: i,
                        validated: true,
                        validatedMessage: 'Address is verified.'
                    })
                }}>{this.state.addresses[i].ADDRESS1} {this.state.addresses[i].ADDRESS2 ? this.state.addresses[i].ADDRESS2 : ''} {this.state.addresses[i].CITY}, {this.state.addresses[i].STATE}, {this.state.addresses[i].ZIP5}</div>
            );
        }
        let verificationStatus = []
        if (this.state.loaded) {
            verificationStatus = (<div id={styles.validationIcon} className={this.state.validated ? styles.valid : styles.invalid}>
                                     <i className="far fa-check-circle"></i> <span className={styles.validationMessage}>{this.state.validatedMessage}</span>
                                 </div>);
        }
        else {
            verificationStatus = (<div className={styles.loading}>
                                     <i className="fas fa-spinner fa-pulse"></i>
                                  </div>);
        }
        return (
        <div className={styles.addressLookUp}>
            {verificationStatus}
            <div id={styles.inputStyles}>
                <div className={styles.container}>
                    <div className={styles.long}>
                    <div className={styles.required}>{this.state.ERROR_MESSAGE}</div>
                        <div>STREET 1</div>
                        <input type="text" className={this.props.error ? styles.defaultInputForm : styles.errorInputForm} onChange={(event) => {
                            this.updateStreetOne(event.target.value)
                                this.setState({ loaded: false })
                                this.validateAddress('streetOne', event.target.value, 'STREET 1')  
                        }} value={this.state.streetOne}/>
                    </div>
                </div>

                <div className={styles.addresses}>{addresses}</div>

                <div className={styles.container}>
                    <div className={styles.long}>
                        <div>STREET 2 (Optional)</div>
                        <input type="text" className={styles.defaultInputForm} onChange={(event) => {
                            this.setState({streetTwo: event.target.value}, () => {
                                this.setState({loaded: false})
                                this.checkAddress()
                            })
                            this.validateAddress('streetTwo', event.target.value, 'STREET 2')
                        }} value={this.state.streetTwo} />
                    </div>
                </div>
                <div className={styles.container}>
                    <div className={styles.long}>
                        <div>CITY</div>
                        <input type="text" className={this.props.error ? styles.defaultInputForm : styles.errorInputForm} onChange={(event) => {
                            this.setState({city: event.target.value}, () => {
                                this.setState({loaded: false})
                                this.checkAddress()
                            })
                            this.validateAddress('city', event.target.value, 'CITY')  
                        }} value={this.state.city}/>
                    </div>
                    <div className={styles.short}>
                        <div>STATE</div>
                        <select className={this.props.error ? styles.defaultInputForm : styles.errorInputForm} onChange={(event) => {
                            this.setState({state: event.target.value}, () => {
                                this.setState({loaded: false})
                                this.checkAddress()
                            })
                        }} value={this.state.state}>
                        <option value=""></option>
                        {stateOptions}
                        </select>
                    </div>
                    <div className={styles.short}>
                        <div>ZIP</div>
                        <input type="number" className={this.props.error ? styles.defaultInputForm : styles.errorInputForm} onChange={(event) => {
                            this.setState({zip: event.target.value}, () => {
                                this.setState({loaded: false})
                                this.checkAddress()
                            })
                        }} value={this.state.zip}/>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

export default AddressLookUp;
