import React from 'react';
import './Set.css';

export default function Set(props) {
    if ((props.roles.includes(props.user.role) || props.roles.includes("ALL"))
        || (props.teams.includes(props.user.team) || props.teams.includes("ALL"))) {

        if (props.type === 'input') {
            return (
                <div className='set'>
                    <div>{props.title}</div>
                    <input type="text" value={props.value} onChange={(event) => {
                            props.setValue(event.target.value)
                        }} />
                </div>
            )
        }
        else if (props.type === "select") {

            //if (props.title == "COUNTRY") {
            //    if (typeof (props.value) != "undefined")
            //        props.returnkey = props.value
            //} 
           
            let options = [<option key={"A01"} value=""></option>]
            let teamOptions = [];
            for (let i = 0; i < Object.values(props.options).length; i++) {
                if (typeof Object.values(props.options)[i] === "object") {
                    if (Object.values(props.options)[i].roles.includes("ALL") || Object.values(props.options)[i].roles.includes(props.user.role) || Object.values(props.options)[i].teams.includes(props.user.team)) {

                        if (props.user.role == "PRT" || props.user.role == "COMMERCIAL DERM" || props.user.role == "PHARMFORCE"|| props.user.isDermAdmin == 'true') {
                            if (Object.values(props.options)[i].title == "ALL" || Object.values(props.options)[i].title == "Spend") {
                                options.push(<option key={i} value={Object.keys(props.options)[i]}>{Object.values(props.options)[i].title}</option>)
                            }
                            
                        }
                        else {
                            options.push(<option key={i} value={Object.keys(props.options)[i]}>{Object.values(props.options)[i].title}</option>)
                        }

                        
                    }
                }
                else {
                    options.push(<option key={i} value={Object.keys(props.options)[i]}>{Object.values(props.options)[i]}</option>)
                }
            }
            if (props.teamOptions !== null && props.teamOptions !== undefined) {
                for (let i = 0; i < Object.values(props.teamOptions).length; i++) {
                    if ((Object.keys(props.teamOptions)[i] === "11") && (props.team === "11")) {
                        teamOptions.push(<option key={i} value={Object.keys(props.teamOptions)[i]}>{Object.values(props.teamOptions)[i]}</option>)
                    }
                    if ((Object.keys(props.teamOptions)[i] === "21") && (props.team === "21")) {
                        teamOptions.push(<option key={i} value={Object.keys(props.teamOptions)[i]}>{Object.values(props.teamOptions)[i]}</option>)
                    }
                    if (props.role === "ADMIN" || props.role === "COMMERCIAL") {
                        teamOptions.push(<option key={i} value={Object.keys(props.teamOptions)[i]}>{Object.values(props.teamOptions)[i]}</option>)
                    }
                }
            }
            
            return (
                <div className='set'>
                    <div>{props.title}</div>
                    <select type="text" value={props.value} isclearable="true" onChange={(event) => {
                            if (event.key === 'Backspace' || event.key === 'Delete') {
                                props.setValue("")
                            }
                            else {
                                props.setValue(event.target.value)
                            }
                        }}>
                        {options}
                        {teamOptions}
                    </select>
                </div>
            );
        }
        else if (props.type === "check") {

            return (
                <div className='setcheck'>
                  
                    <input type="checkbox" value={props.value} isclearable="true" onChange={(event) => {
                       // var isChecked = event.target.checked;
                  
                        props.setValue(event.target.checked);
           
                    }} /><div className='checktitle'>{props.title}</div>
                </div>
            );
        }
    }
    else {
        return ""
    }
}
