import React from 'react';
import styles from './Toggle.module.css';

function Toggle(props) {
    return (<div className={styles.toggle}>
        <div className={styles.switch}>
            <div className={[(props.error ? styles.errortrack : styles.track), (props.active ? styles.active : ""), (props.global ? styles.global : "")].join(" ")}>
                <input type="checkbox" onClick={() => {
                 //  alert("disabled: " + props.disabled + ", overRide: " + props.overRide + ", active: " + props.active)
                    if (props.disabled == true) {
                  
                            props.errorCallback()
                            return
                   
                    }
                    if (props.clickError) {
                        props.clickError(props.errorType)
                    }
                    else if (props.click) {
                        props.click(props.number, props.type, props.active)
                    }
                }}/>
                <div className={styles.ball}></div>
            </div>
        </div>
    </div>)
}

export default Toggle;
