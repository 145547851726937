import React from 'react'
import styles from '../css/ColumnReviewBox.module.css'
import Toggle from '../../../globalcomponents/Toggle'

function ColumnReviewBox(props) {
    return (
        <div className={styles.wrapper}>
            <div className={styles.order}>{props.order}</div>
            <div className={styles.columnReviewBox}>
                <input type="text" className={styles.disabled} value={props.data.col} disabled/>
                <div className={styles.set}>
                    <div className={styles.inputTitle}>TITLE <div className={styles.required}>*</div></div>
                    <input type="text" className={styles.default} value={props.data.title} disabled/>
                </div>
                <div className={styles.set}>
                    <div className={styles.inputTitle}>SELECT COLUMN FUNCTIONALITY <div className={styles.required}>*</div></div>
                    <select type="text" className={styles.default} value={props.data.options} disabled>
                        <option value="Show">Show</option>
                        <option value="LOV">List of values</option>
                        <option value="FreeText">Free Text</option>
                    </select>
                </div>
                <div className={styles.set}>
                    <Toggle active={props.data.show} global={true}/>
                </div>
            </div>
        </div>

    )
}

export default ColumnReviewBox
