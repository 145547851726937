import React, {Component} from 'react';
import styles from '../css/RegionalPlan.module.css';
import RegionalPlanResult from './RegionalPlanResult'
import Axios from './../../../components/Axios'

class RegionalPlan extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loaded: false,
            edit: false,
            editIndex: -1,
            data: this.props.data
        };
    }

    componentDidMount() {
        let temp = {}
        temp.state = this.state
        Axios('/api/admin/regional-plan-details', temp, (data) => {
            this.setState({
                loaded: true,
            })
            this.props.setData(data)
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data !== this.props.data) {
            this.setState({data: this.props.data});
        }
    }

    edit(i) {
        this.props.changeAdminView(7, this.state.data[i])
    }

    delete(i) {
        this.props.changeAdminView(8, this.state.data[i])
    }

    close() {
        this.setState({editIndex: -1})
    }

    setData(d) {
        this.setState({data: d})
    }

    changeView(i) {
        this.setState({editIndex: i})
    }

    render() {
        let results = []
        if (this.state.data) {
            for (let i = 0; i < this.state.data.length; i++) {
                results.push(<RegionalPlanResult key={i} data={this.state.data[i]} setData={this.setData.bind(this)} close={this.close.bind(this)} edit={this.edit.bind(this)} delete={this.delete.bind(this)} number={i}/>)
            }
        }
        return (
            <div className={styles.RegionalPlan}>
                <div className={styles.titleBar}>
                    <div className={styles.title}>Regional Plan Exercises</div>
                    <div className={styles.addUser} onClick={() => {this.props.changeAdminView(6)}}>ADD EXERCISES</div>
                </div>
                <div className={styles.blueBar}>Exercise History</div>
                <div className={styles.history}>
                    <div className={styles.resultTitles}>
                        <div>STATUS</div>
                        <div>NAME</div>
                        <div>TEAM</div>
                        <div>START DATE</div>
                        <div>END DATE</div>
                        <div>ACTIONS</div>
                    </div>
                    <div className={styles.results}>
                        {this.state.loaded ? results : "Loading . . ."}
                    </div>
                </div>
            </div>
        );
    }
}

export default RegionalPlan;
