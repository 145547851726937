import React, {useState, useEffect} from 'react'
import styles from '../css/UserPanel.module.css'
import Spinner from '../../../components/Spinner'

function UserPanel(props) {

    useEffect(() => {
        props.getData()
    }, [])

    let results = []
    for (let i = 0; i < props.data.length; i++) {
        results.push(
            <props.result
                {...props}
                key={props.data[i].TERR_ID}
                user={props.user}
                targetID={props.adminExercise ? props.adminExercise.TARGETING_EXERCISE_ID : props.exercise.TARGETING_EXERCISE_ID}
                ID={props.adminExercise ? props.adminExercise.ID : props.exercise.ID}
                data={props.data[i]}
                getData={props.getData}
                statusData={props.statusData}
                index={i}
                path={props.path}
            />
        )
    }
    if (props.totals) {
        results.push(<props.totals key="totals" data={props.data} />)
    }


    return (
        <div className={styles.resultsWrapper}>
            <div className={styles.sectionTitle}>{props.headerTitle}</div>
            <div className={styles.results}>
                <props.titles />
                {props.loaded ? results : <Spinner />}
            </div>
        </div>
    )
}

export default UserPanel
