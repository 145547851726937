import React, { Component } from 'react';
import styles from '../css/Details_Information.module.css';
import Toggle from '../../../globalcomponents/Toggle';
import DegreeLookUp from '../../../globalcomponents/DegreeLookUp';
import SpecialtyLookUp from '../../../globalcomponents/SpecialtyLookUp';
import NotificationBox from '../../../globalcomponents/NotificationBox';
import firstCapital from '../../../helper/firstCapital';
import hcp from '../../../lookup/hcp.json';

class Details_Information extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notificationStatus: false,
            errorStatus: false,
            notificationType: ''
        };
    }
    updateNotification(status, type) {
        let message = `You switched ${type} to ${!status ? 'ON' : 'OFF'} for this professional.`;
        if (type === 'BOTH') {
            message = `You switched CRM and Spend to ON for this professional.`;
        }
        this.setState({
            notificationMessage: message,
            notificationStatus: true,
            errorStatus: false,
            notificationType: type,
        });
    }
    updateError(status, type, id) {
        let message = `You cannot ${!status ? 'add this professional to your ' : 'remove this professional from your '} ${type}.`;
        this.setState({
            notificationMessage: message,
            notificationStatus: true,
            errorStatus: true,
            notificationType: type,
            errorByID: id,
        });
    }
    updateNotificationStatus() {
        this.setState({
            notificationStatus: false,
            notificationType: '',
            errorByID: null,
        });
    }
    secondaryAddresses() {

        let isSameTeam = false;

        //if (this.props.user.role == 'ADMIN' || this.props.data[`TERR_TM${this.props.user.team}`] != null ) { isSameTeam = true}

        if (this.props.addresses) {
            let hasOne = false;
            let temp = [];

            let stateCode = '';

            for (let i = 0; i < this.props.addresses.length; i++) {

                //  if ((this.props.addresses[2][i].SECONDARY_ADDRESS1 !== this.props.addresses[0][0].PRI_ADDRESS1) && ((this.props.addresses[2][i].SECONDARY_ADDRESS2 !== "") && (this.props.addresses[2][i].SECONDARY_ADDRESS2 !== this.props.addresses[0][0].PRI_ADDRESS2))) {
                stateCode = this.props.data[i].STATE.replace(this.props.data[i].COUNTRY_CODE, '');
                temp.push(
                    <div key={i} className={[styles.Content, styles.FullContent].join(' ')}>
                        {!hasOne ? (
                            <div className={styles.ContentTitleSec}>SECONDARY ADDRESS</div>
                        ) : (
                            ''
                        )}

                        <div>


                            {`${firstCapital(this.props.addresses[i].ADDRESS1)} ${firstCapital(this.props.addresses[i].ADDRESS2)},
                                        ${firstCapital(this.props.addresses[i].CITY)}, ${stateCode} ${this.props.addresses[i].COUNTRY_CODE}
                                        ${this.props.addresses[i].POSTAL_CODE}`}


                            {this.props.addresses[i].SECONDARY_ADDRESS1 === this.props.addresses[i].BEST_ADDRESS1 ? (
                                /* <i className={[styles.best, 'fas', 'fa-check-circle'].join(' ')}></i>*/
                                ''
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                );

                hasOne = true;
                //}
            }
            return temp;
        }
    }

    render() {
        let hashes = this.props.data[0];
        let id = this.props.data.PROF_ID;

        let notification = this.state.notificationStatus ? (
            <NotificationBox
                message={this.state.notificationMessage}
                callback={this.updateNotificationStatus.bind(this)}
                errorStatus={this.state.errorStatus}
            />
        ) : (
            ''
        );

        let aggspendActive = hashes.spend || this.props.data[0].AGGSPEND_FLAG === 'Y';
        let aggspendToggle = (
            <Toggle
                active={aggspendActive}
                click={() => {
                    this.props.addTo(this.props.number, 0, aggspendActive);
                    this.updateNotification(aggspendActive, 'Spend');
                }}
                disabled={aggspendActive}
                errorCallback={() => {
                    this.updateError(aggspendActive, 'Spend', id);
                }}
                error={this.state.errorByID === id && this.state.notificationType === 'Spend'}
            />
        );

        let isAdmin = ['ADMIN'].includes(this.props.user.role)
        let isPRT = ['PRT'].includes(this.props.user.role)
        let isComDerm = ['COMMERCIAL DERM'].includes(this.props.user.role)
        let regex = new RegExp(`^${this.props.user.terr}`, 'g')
        //let matchesTerritory = (this.props.user.team === '51') || (this.props.data.TERR_ID.match(regex))

        //let matchesTerritory = this.props.data[0].TERR_ID ? this.props.data[0].TERR_ID.match(regex) : false;

        let overRide = this.props.data.MDM_OVERRIDE !== 'X';
        //if (overRide) {
        //    matchesTerritory = (["51", "53", "81", "82"].includes(this.props.user.team)) || (this.props.data[0].TERR_ID && this.props.data[0].TERR_ID.match(regex))
        //} else {
        //    matchesTerritory = ["51", "53", "81", "82"].includes(this.props.user.team) ||
        //        (this.props.data.TERR_ID && (this.props.data[0].TERR_ID.match(regex) || (this.props.data[0].MDM_TERR_ID && this.props.data[0].MDM_TERR_ID.match(regex))))
        //}

        let matchesTerritory = this.props.data[0].TERR_ID ? this.props.data[0].TERR_ID.match(regex) : false;
        let universeFlag = this.props.data[0].UNIVERSE_FLAG === 'Y';
        let teamUniverseFlag = this.props.data[0][`UNIVERSE_FLAG_TM${this.props.user.team}`] === 'Y';
        let isDisabled = !isAdmin && !matchesTerritory && !isPRT && !isComDerm;
        let isGlobal = !isAdmin && !matchesTerritory && teamUniverseFlag && overRide;



        ////T12 - 11 alignment hack
        //let regexTemp = new RegExp(`^${11}`, 'g')
        //if (this.props.user.team == "12" && isDisabled && teamUniverseFlag && this.props.data.TERR_ID.match(regexTemp)) {
        //    isDisabled = false;
        //}

        let crmActive = false;
        if (this.props.user.isDermAdmin == 'true' || this.props.user.role == 'COMMERCIAL DERM' || this.props.user.role == 'PRT') {
            //crmActive = (hashes.crm !== undefined) ? (hashes.crm[id] !== undefined ? hashes.crm[id] : teamUniverseFlag) : teamUniverseFlag;
            crmActive = hashes.crm !== undefined ? hashes.crm : teamUniverseFlag;
            // isGlobal = !teamUniverseFlag; 
        } else {
            //crmActive = hashes.crm[id] !== undefined ? hashes.crm[id] : overRide && ((isAdmin && universeFlag) || teamUniverseFlag);
            crmActive = hashes.crm !== undefined ? hashes.crm : ((isAdmin && universeFlag) || teamUniverseFlag);
        }
        let crmToggle = (
            <Toggle
                active={crmActive}
                click={() => {
                    this.props.addTo(this.props.number, 1, crmActive, !aggspendActive ? true : false);
                    this.updateNotification(crmActive, !aggspendActive ? 'BOTH' : 'CRM');
                }}
                overRide={!overRide}
                disabled={isDisabled}
                global={isGlobal}
                errorCallback={() => {
                    this.updateError(crmActive, 'CRM', id);
                }}
                error={this.state.errorByID === id && this.state.notificationType === 'CRM'}
            />
        );


        let validAddress = this.props.data[0].ADDRESS_STATUS == 'Valid' ? (<div className={styles.validaddress}>VALID</div>) : (<div className={styles.invalidaddress}>INVALID</div>);
        let editDetails =
            //this.props.user.role.includes('ADMIN') || this.props.user.team.includes('81','82')
            true ? (
                <i
                    className='far fa-edit'
                    onClick={() => {
                        this.props.editScreen(1);
                    }}></i>
            ) : (
                ''
            );

        let editSpecialty =
            //this.props.user.role.includes('ADMIN') || this.props.user.team.includes('81','82')
            true ? (
                <i className='far fa-edit'
                    onClick={() => {
                        this.props.editScreen(2);
                    }}></i>
            ) : (
                ''
            );

        let editContact =
            //this.props.user.role.includes('ADMIN') || this.props.user.team.includes('81','82')
            true ? (
                <i className='far fa-edit'
                    onClick={() => {
                        this.props.editScreen(5);
                    }}></i>
            ) : (
                ''
            );

        let editAddress =
            //this.props.user.role.includes('ADMIN') || this.props.user.team.includes('81','82')
            true ? (
                <i className='far fa-edit'
                    onClick={() => {
                        this.props.editScreen(4);
                    }}></i>
            ) : (
                ''
            );


        let address =
            true ? (
                <div className={styles.MiniSection}>
                    <div className={styles.TitleBar}>
                        <div>ADDRESS</div>
                        {true ? (
                            <i
                                className='far fa-edit'
                                onClick={() => {
                                    this.props.editScreen(4);
                                }}></i>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className={[styles.Wrapper, styles.Column].join(' ')}>
                        <div className={[styles.Content, styles.FullContent].join(' ')}>
                            <div className={styles.ContentTitle}>
                                <div className={styles.bestwrap}>
                                    PRIMARY ADDRESS{' '}
                                    {true ? (
                                        true ? (
                                            <div className={styles.best}>
                                                <i className='fas fa-check-circle'></i> BEST ADDRESS
                                            </div>
                                        ) : (
                                            ''
                                        )
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                            {true ? (
                                <div>



                                    {`${firstCapital(this.props.data[0].ADDRESS1)} ${firstCapital(this.props.data[0].ADDRESS2)}, 
                                        ${firstCapital(this.props.data[0].CITY)}, ${this.props.data[0].STATE.replace(this.props.data[0].COUNTRY_CODE, '')} ${this.props.data[0].COUNTRY_CODE}  
                                        ${this.props.data[0].POSTAL_CODE}`}





                                </div>
                            ) : (
                                <div>—</div>
                            )}
                            {this.secondaryAddresses()}
                        </div>
                    </div>
                </div>
            ) : (
                ''
            );


        return (
            <div className={styles.Information}>
                <div className={styles.MiniSection}>
                    <div className={styles.TitleBar}>
                        <div>PERSONAL</div>
                        {editDetails}
                    </div>
                    <div className={styles.Wrapper}>
                        <div className={styles.Name}>{`${this.props.data[0].FIRST_NAME} ${this.props.data[0].MIDDLE_NAME ? this.props.data[0].MIDDLE_NAME : ''
                            } ${this.props.data[0].LAST_NAME}`}</div>
                        <div className={styles.Content}>
                            <div className={styles.ContentTitle}>STATUS</div>
                            <div>{
                                !this.props.data[0].STATUS ? 'Active' :
                                    this.props.data[0].STATUS === 'Active' ? 'Active' :
                                        this.props.data[0].STATUS === 'DEAD' ? 'Deceased' :
                                            this.props.data[0].STATUS === 'RETIRED' ? 'Retired' : 'Inactive'}
                            </div>
                        </div>
                        <div className={styles.Content}>
                            <div className={styles.ContentTitle}>DEGREE</div>
                            <div>{this.props.data[0].DEGREE ? this.props.data[0].DEGREE + ' - ' + DegreeLookUp[this.props.data[0].DEGREE] : '—'}</div>
                        </div>

                        <div className={styles.Content}>
                            <div className={styles.ContentTitle}>GENDER</div>
                            <div>{this.props.data[0].GENDER ? this.props.data[0].GENDER : '—'}</div>
                        </div>
                    </div>
                </div>

                <div className={styles.MiniSection}>
                    <div className={styles.TitleBar}>
                        <div>SPECIALTY</div>
                        {editSpecialty}
                    </div>
                    <div className={[styles.Wrapper, styles.Column].join(' ')}>
                        <div className={[styles.Content, styles.FullContent].join(' ')}>
                            <div className={styles.ContentTitle}>SPECIALTY</div>
                            <div>
                                {this.props.data[0].SPECIALTY
                                    ? this.props.data[0].SPECIALTY + ' - ' + firstCapital(SpecialtyLookUp[this.props.data[0].SPECIALTY])
                                    : '—'}
                            </div>
                        </div>

                    </div>
                    <div className={[styles.Wrapper, styles.Column].join(' ')}>
                        <div className={[styles.Content, styles.FullContent].join(' ')}>
                            <div className={styles.ContentTitle}>SECONDARY SPECIALTY</div>
                            <div>
                                {'-'}
                            </div>
                        </div>

                    </div>
                </div>

                <div className={styles.MiniSection}>
                    <div className={styles.TitleBar}>
                        <div>IDENTIFIERS</div>
                    </div>
                    <div className={styles.Wrapper}>
                        <div className={styles.Content}>
                            <div className={styles.ContentTitle}>PROF ID</div>
                            <div>{this.props.data[0].PROF_ID}</div>
                        </div>

                    </div>
                    <div className={styles.Wrapper}>
                        <div className={styles.Content}>
                            <div className={styles.ContentTitle}>ONEKEY ID</div>
                            <div>{this.props.data[0].SRC_ID2}</div>
                        </div>

                    </div>
                </div>


                <div className={styles.MiniSection}>
                    <div className={styles.TitleBar}>
                        <div>UNIVERSE</div>
                    </div>

                    <div className={styles.WrapperToggle}>
                        <div className={styles.Content}>
                            <div className={styles.ContentTitle}>CRM</div>
                            <div className={styles.Toggle}>
                                {crmToggle}
                                <div className={styles.ErrorMessage}>{ }</div>
                            </div>
                        </div>
                        <div className={styles.Content}>
                            <div className={styles.ContentTitle}>SPEND</div>
                            <div className={styles.Toggle}>
                                {aggspendToggle}
                                <div className={styles.ErrorMessage}>{ }</div>
                            </div>
                        </div>



                    </div>
                </div>

                {/*<div className={styles.MiniSection}>*/}
                {/*    <div className={styles.TitleBar}>*/}
                {/*        <div>ADDRESS</div>*/}
                {/*          { editAddress }*/}
                {/*    </div>*/}
                {/*    <div className={[styles.Wrapper, styles.Column].join(' ')}>*/}
                {/*        <div className={[styles.Content, styles.FullContent].join(' ')}>*/}
                {/*            <div className={styles.ContentTitle}>*/}
                {/*                <div className={styles.bestwrap}>*/}
                {/*                    PRIMARY ADDRESS{' '}*/}

                {/*                    <div className={styles.best}>*/}
                {/*                        <i className='fas fa-check-circle'></i> BEST ADDRESS */}
                {/*                    </div>*/}

                {/*                </div>*/}
                {/*                <div>*/}
                {/*                    {`${firstCapital(this.props.data[0].ADDRESS1)} ${firstCapital(this.props.data[0].ADDRESS2*/}
                {/*                    )}, ${firstCapital(this.props.data[0].CITY)}, ${this.props.data[0].STATE} ${this.props.data[0].COUNTRY_CODE*/}
                {/*                        }  ${this.props.data[0].POSTAL_CODE*/}
                {/*                        }`}*/}


                {/*                    {validAddress}*/}


                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className={styles.ContentTitle}>*/}
                {/*                <div className={styles.bestwrap}>*/}
                {/*                    SECONDARY ADDRESS{' '}*/}




                {/*                </div>*/}
                {/*                <div>*/}
                {/*                    -*/}
                {/*                </div>*/}
                {/*            </div>*/}

                {/*        </div>*/}

                {/*    </div>*/}
                {/*</div>*/}
                {address}

                <div className={styles.MiniSection}>
                    <div className={styles.TitleBar}>
                        <div>CONTACT INFO</div>
                        {editContact}
                    </div>
                    <div className={[styles.Wrapper, styles.Column].join(' ')}>

                        <div className={[styles.Content, styles.FullContent].join(' ')}>
                            <div className={styles.ContentTitle}>EMAIL</div>
                            <div>{this.props.data[0].EMAIL ? this.props.data[0].EMAIL : '—'}</div>
                        </div>



                        <div className={[styles.Content, styles.FullContent].join(' ')}>
                            <div className={styles.ContentTitle}>MOBILE</div>
                            <div>{this.props.data[0].MOBILE ? this.props.data[0].MOBILE : '—'}</div>
                        </div>
                        <div className={[styles.Content, styles.FullContent].join(' ')}>

                            <div className={[styles.Content, styles.FullContent].join(' ')}>
                                <div className={styles.ContentTitle}>TWITTER</div>
                                <div>{'—'}</div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className={styles.MiniSection}>
                    <div className={styles.TitleBar}>
                        <div>DERM CA INFO</div>
                    </div>
                    <div className={styles.Wrapper}>
                        <div className={styles.Content}>
                            <div className={styles.ContentTitle}>PROVINCIAL SEGMENT</div>
                            <div> {this.props.data[0].PROVINCIAL_SEGMENT ? this.props.data[0].PROVINCIAL_SEGMENT : '—'}</div>
                        </div>

                    </div>
                    <div className={styles.Wrapper}>
                        <div className={styles.Content}>
                            <div className={styles.ContentTitle}>NATIONAL SEGMENT</div>
                            <div>{this.props.data[0].NATIONAL_SEGMENT ? this.props.data[0].NATIONAL_SEGMENT : '—'}</div>
                        </div>

                    </div>
                    {notification}
                </div>

            </div>
        );
    }
}

export default Details_Information;

