import React, {useState, useEffect} from 'react'
import Styles from '../css/DialogBoxEditUser.module.css'
import Axios from '../../../components/Axios/Axios'
import validation from './../../../globalcomponents/validation'
import Toggle from './../../../globalcomponents/Toggle'

export default function DialogBoxEditUser(props) {
    const [firstName, setFirstName] = useState(props.data[props.recordIndex].FIRST_NAME)
    const [lastName, setLastName] = useState(props.data[props.recordIndex].LAST_NAME)
    const [phoneNumber, setPhoneNumber] = useState(formatPhoneNumber(props.data[props.recordIndex].PHONE_NUMBER))
    const [role, setRole] = useState(props.data[props.recordIndex].EMP_TYPE)
    const [username] = useState(props.data[props.recordIndex].USER_ID)
    const [email, setEmail] = useState(props.data[props.recordIndex].WORK_EMAIL)
    const [active, setActive] = useState(props.data[props.recordIndex].EMP_TERR_STATUS || "INACTIVE")
    const [isValid, setIsValid] = useState(true)
    const [errorMessage, setErrorMessage] = useState('REQUIRED FIELD *')
    const [userId] = useState(props.data[props.recordIndex].ID)
    const [adminFlag, setAdminFlag] = useState(props.data[props.recordIndex].IsAdminUser)
    const [exUsFlag, setExUsFlag] = useState(props.data[props.recordIndex].IsExUsUser)
    const [edFlag, setEDFlag] = useState(props.data[props.recordIndex].IsEDAdmin)
    const [ocneFlag, setOCNEFlag] = useState(props.data[props.recordIndex].IsOCNE_ADMIN)
    const [roleOptions, setRoleOptions] = useState([])
    const [wiggle, setWiggle] = useState(false)

    const [hemoncOpsFlag, setHemoncOpsFlag] = useState(props.data[props.recordIndex].IsHemoncOps)
    const [dermOpsFlag, setDermOpsFlag] = useState(props.data[props.recordIndex].IsDermOps)
    const [hemoncCompFlag, setHemoncCompFlag] = useState(props.data[props.recordIndex].IsHemoncComp)
    const [dermCompFlag, setDermCompFlag] = useState(props.data[props.recordIndex].IsDermComp)

    useEffect(() => {
        Axios('/api/admin/get-user-roles', {}, (results) => {
            let roleOptions = []
            for (let i = 0; i < results.length; i++) {
                roleOptions.push(<option key={i} value={results[i].Name}>{results[i].Name}</option>)
            }
            setRoleOptions(roleOptions)
        })
    }, [])

    function formatPhoneNumber(tel) {
        var toString = String(tel),
            phoneNumber = toString.replace(/[^0-9]/g, ""),
            countArrayStr = phoneNumber.split(""),
            numberVar = countArrayStr.length,
            closeStr = countArrayStr.join("");
        if (numberVar == 10) {
            var phone = closeStr.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"); // Change number symbols here for numbers 10 digits in length. Just change the periods to what ever is needed.
        } else if (numberVar > 10) {
            var howMany = closeStr.length,
                subtract = (10 - howMany),
                phoneBeginning = closeStr.slice(0, subtract),
                phoneExtention = closeStr.slice(subtract),
                disX = "x", // Change the extension symbol here
                phoneBeginningReplace = phoneBeginning.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"), // Change number symbols here for numbers greater than 10 digits in length. Just change the periods and to what ever is needed. 
                array = [phoneBeginningReplace, disX, phoneExtention],
                afterarray = array.splice(1, 0, " "),
                phone = array.join("");

        } else {
            var phone = '';
        }
        return phone;
    }

    const updateUser = () => {
        let temp = {}
        temp = {
            ID: userId,
            FIRST_NAME: firstName,
            LAST_NAME: lastName,
            PHONE_NUMBER: phoneNumber,
            ROLE: role,
            EMAIL: email,
            ACTIVE: active,
            ADMIN_FLAG: adminFlag,
            EXUS_FLAG: exUsFlag,
            ED_FLAG: edFlag,
            HEMONC_OPS_FLAG: hemoncOpsFlag,
            DERM_OPS_FLAG: dermOpsFlag,
            HEMONC_COMP_FLAG: hemoncCompFlag,
            DERM_COMP_FLAG: dermCompFlag,
            OCNE_ADMIN_FLAG: ocneFlag
        }
        Axios('/api/admin/update-user', temp, (results) => {
            let temp = {}
            Axios('/api/admin/search', temp, (data) => {
                props.setData(data)
            })
            //props.setData(results)
            props.changeAdminView(-1)
        })
    }

    return (
        <div className={Styles.editScreen}>
            <div className={Styles.title}>
                <div></div>
                <div className={Styles.TitleText}>Edit User</div>
                <div className={Styles.close} onClick={() => {props.changeAdminView(-1)}}><i className='fas fa-times'></i></div>
            </div>
            <div className={Styles.body}>
            <div className={Styles.errorMessage}>{errorMessage}</div>
                <div className={Styles.toprow}>
                    <div className={Styles.set}>
                        <div className={Styles.inputTitle}>FIRST NAME <div className={Styles.required}>*</div></div>
                        <input type='text'
                            className={isValid ? Styles.DefaultInputForm: Styles.ErrorInputForm}
                            value={firstName}
                            onChange={(event) => {setFirstName(event.target.value)}}
                        autoFocus/>
                    </div>
                    <div className={Styles.set}>
                        <div className={Styles.inputTitle}>LAST NAME <div className={Styles.required}>*</div></div>
                        <input type='text'
                            className={isValid ? Styles.DefaultInputForm: Styles.ErrorInputForm}
                            value={lastName}
                            onChange={(event) => {setLastName(event.target.value)}}
                        />
                    </div>
                </div>
                <div className={Styles.middlerow}>
                    <div className={Styles.set}>
                        <div className={Styles.inputTitle}>PHONE NUMBER</div>
                        <input type='text'
                            className={Styles.DefaultInputForm}
                            value={phoneNumber}
                            onChange={(event) => { setPhoneNumber(event.target.value) }}
                            placeholder={'xxx-xxx-xxxx'}
                        />
                    </div>
                    <div className={Styles.set}>
                        <div className={Styles.inputTitle}>ROLE <div className={Styles.required}>*</div></div>
                        <select
                            className={isValid ? Styles.DefaultInputForm: Styles.ErrorInputForm}
                            value={role}
                            onChange={(event) => {setRole(event.target.value)}}>
                            <option value=''></option>
                            {roleOptions}
                        </select>
                    </div>
                </div>
                <div className={Styles.middlerow}>
                    <div className={Styles.set}>
                        <div className={Styles.inputTitle}>EMAIL <div className={Styles.required}>*</div></div>
                        <input type='text'
                            className={isValid ? Styles.DefaultInputForm: Styles.ErrorInputForm}
                            value={email}
                            onChange={(event) => {setEmail(event.target.value)}}
                        />
                    </div>
                </div>
                <div className={Styles.middlerow}>
                    <div className={Styles.set}>
                        <div className={Styles.innerSet}>
                            <div className={Styles.inputTitle}>STATUS</div><br/>
                            {active ? <Toggle click={() => {
                                if (active === 'ACTIVE') {
                                    setActive('INACTIVE')
                                }
                                if (active === 'INACTIVE') {
                                    setActive('ACTIVE')
                                }
                            }} active={active === 'ACTIVE' ? true : false}/> : '-'}
                        </div>
                    </div>
                    <div className={Styles.roleRow}>
                        <div className={Styles.innerSet}>
                            <div>ADMIN</div>
                            <input type='checkbox' checked={adminFlag} onChange={(event) => {
                                if (event.target.checked) {
                                    setAdminFlag(true)
                                }
                                else {
                                    setAdminFlag(false)
                                }
                            }} style={{
                                width: 'auto',
                                marginLeft: 10
                            }} />
                        </div>
                        <div className={Styles.innerSet}>
                            <div>EX-US</div>
                            <input type='checkbox' checked={exUsFlag} onChange={(event) => {
                                if (event.target.checked) {
                                    setExUsFlag(true)
                                }
                                else {
                                    setExUsFlag(false)
                                }
                            }} style={{
                                width: 'auto',
                                marginLeft: 10
                            }} />
                        </div>

                      
                        <div className={Styles.innerSet}>
                            <div>E&D ADMIN</div>
                            <input type='checkbox' checked={edFlag} onChange={(event) => {
                                if (event.target.checked) {
                                    setEDFlag(true)
                                }
                                else {
                                    setEDFlag(false)
                                }
                            }} style={{
                                width: 'auto',
                                marginLeft: 10
                            }} />
                        </div>


                        <div className={Styles.innerSet}>
                            <div>OCNE DIR</div>
                            <input type='checkbox' checked={ocneFlag} onChange={(event) => {
                                if (event.target.checked) {
                                    setOCNEFlag(true)
                                }
                                else {
                                    setOCNEFlag(false)
                                }
                            }} style={{
                                width: 'auto',
                                marginLeft: 10
                            }} />
                        </div>

                    </div>

                    <div className={Styles.roleRow}>
       

                        <div className={Styles.innerSet}>
                            <div>HEMONC OPS</div>
                            <input type='checkbox' checked={hemoncOpsFlag} onChange={(event) => {
                                if (event.target.checked) {
                                    setHemoncOpsFlag(true)
                                }
                                else {
                                    setHemoncOpsFlag(false)
                                }
                            }} style={{
                                width: 'auto',
                                marginLeft: 10
                            }} />
                        </div>
                        <div className={Styles.innerSet}>
                            <div>DERM OPS</div>
                            <input type='checkbox' checked={dermOpsFlag} onChange={(event) => {
                                if (event.target.checked) {
                                    setDermOpsFlag(true)
                                }
                                else {
                                    setDermOpsFlag(false)
                                }
                            }} style={{
                                width: 'auto',
                                marginLeft: 10
                            }} />
                        </div>

                        <div className={Styles.innerSet}>
                            <div>HEMONC COMPLIANCE</div>
                            <input type='checkbox' checked={hemoncCompFlag} onChange={(event) => {
                                if (event.target.checked) {
                                    setHemoncCompFlag(true)
                                }
                                else {
                                    setHemoncCompFlag(false)
                                }
                            }} style={{
                                width: 'auto',
                                marginLeft: 10
                            }} />
                        </div>
                        <div className={Styles.innerSet}>
                            <div>DERM COMPLIANCE</div>
                            <input type='checkbox' checked={dermCompFlag} onChange={(event) => {
                                if (event.target.checked) {
                                    setDermCompFlag(true)
                                }
                                else {
                                    setDermCompFlag(false)
                                }
                            }} style={{
                                width: 'auto',
                                marginLeft: 10
                            }} />
                        </div>
                      

                    </div>


                    <div className={Styles.set}>
                        <div id={Styles.Link} onClick={() => {props.changeAdminView(5)}}>Reset Password</div>
                    </div>
                </div>
                <div className={Styles.buttonRow}>
                    <div id={Styles.cancel} onClick={() => {props.changeAdminView(-1)}}>CANCEL</div>
                    <div
                        id={Styles.submit}
                        className={wiggle && wiggle ? Styles.Wiggle : ''}
                        onClick={() => {
                            if (firstName !== '' && lastName !== '' && role !== '' && email !== '' && username !== '') {
                                if (validation.isValidEmail(email)) {
                                    updateUser()
                                }
                                else {
                                    setWiggle(true)
                                    setIsValid(false)
                                    setErrorMessage('Please provide a valid email address.')
                                    setTimeout(() => {setWiggle(false)}, 500)
                                }
                            }
                            else {
                                setWiggle(true)
                                setIsValid(false)
                                setErrorMessage('REQUIRED FIELD *')
                                setTimeout(() => {setWiggle(false)}, 500)
                            }
                        }
                    }>SAVE</div>
                </div>
            </div>
        </div>
    )
}