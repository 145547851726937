import React, {useState, useEffect} from 'react'
import styles from './Titles.module.css'

function Titles(props) {
    const [columnSorting, setColumnSorting] = useState(-1)
    const [descending, setDescending] = useState(false)
    const [sorting, setSorting] = useState(false)

    useEffect(() => {
        if (props.resetSort) {
            setColumnSorting(-1)
            setDescending(false)
            props.setResetSort(false)
        }
    }, [props.resetSort])

    let cols = []
    if (props.config) {
        for (let i = 0; i < Object.keys(props.config).length; i++) {
            if (props.config[i].show) {
                let title = props.config[i]
                cols.push(<th className={[styles.contentTitles, styles.col].join(' ')} key={title.title} style={{width: window.innerWidth * (title.flex / 100)}} onClick={() => {
                    if (props.selected !== undefined) {
                        props.setSelected(i)
                    }
                    else if(!sorting) {
                        let temp = JSON.parse(JSON.stringify(props.data))
                        let des
                        if (columnSorting === i) {
                            des = !descending
                        }
                        else {
                            des = true
                        }
                        setSorting(true)
                        temp.sort((a,b) => {
                            let valA, valB
                            if (typeof title.sortBy === "object") {
                                for (let i = 0; i < title.sortBy.length; i++) {
                                    const element = title.sortBy[i];
                                    valA = a[element]
                                    valB = b[element]
                                }
                            } else {
                                valA = title.sortBy ? a[title.sortBy] : a[title.col]
                                valB = title.sortBy ? b[title.sortBy] : b[title.col]
                            }
                            if (valA === null) {
                                return des ? 1 : -1
                            }
                            if (valB === null) {
                                return des ? -1 : 1
                            }
                            if (!isNaN(valA && valB) ? parseInt(valA) < parseInt(valB) : valA < valB) {
                                return des ? -1 : 1;
                            }
                            if (!isNaN(valA && valB) ? parseInt(valA) > parseInt(valB) : valA > valB) {
                                return des ? 1 : -1;
                            }
                            return 0
                        })
                        props.setData(temp)
                        setColumnSorting(i)
                        setDescending(des)
                        setSorting(false)
                    }
                }}>
                <div className={[styles.flexContainer, title.left ? styles.left : styles.center, styles.wrap, props.selected === i ? styles.selected : styles.border].join(" ")}>
                    <div className={title.col === "Multiple" ? styles.multipleMargin : styles.placeholder}></div>
                    {title.title}
                    <div className={styles.placeholder}>
                        {columnSorting === i
                            ?
                                descending
                                    ? <i className="fas fa-caret-down"></i>
                                    : <i className="fas fa-caret-up"></i>
                            :
                                ""
                        }
                    </div>
                </div>
            </th>)
            }
        }
    }
    return (
        <tr className={styles.titleRow}>
            {cols}
        </tr>
    )
}

export default Titles
