import React, { Component } from 'react'
import Styles from './WorkflowDialogBox.module.css'
import Axios from '../../components/Axios'

//import axios from 'axios'

class WorkflowPostEventDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentScreenIndex: 1,
            id: this.props.id,
            partcomments: '',
            benefits: '',
            contractual: '',
            participation: '',
            attendance: '',
            interactions: '',
            interaction_time: '',
            theaters: '',
            category: '',
            err: '',
            showtheater: false,
            theater_desc: ''
        };

        
    }
    componentDidMount() {

        let json = {};
        json.user = this.props.user;
        json.id = this.props.id;
        
        Axios('/api/workflow/get-workflow-detail', json, (data) => {

            var org = data.data[0].ORG_NAME;
            var program = data.data[0].PROGRAM_TITLE;
            var username = data.data[0].USER_ID;
            var start = new Date(data.data[0].START_DT).toDateString();
            var end = new Date(data.data[0].END_DT).toDateString();
            var mdmid = data.data[0].ORG_ID;

            this.setState({ orgName: org, program: program, enddt: end, startdt: start, mdm_id: mdmid });

          
        });


    }
    movePrev() {

        this.setState({ currentScreenIndex: 1 })
       
    }
    moveNext() {
        this.setState({ currentScreenIndex: 2 })
        
    }
    validateFields() {

       

    }
    render() {

        return (

            <div className={Styles.editScreen}>
                <div className={Styles.title}>
                    <div>Event Close Dialog</div>
                    <div className={Styles.TitleText}>{this.state.dialogtitle}</div>
                    <div className={Styles.close} onClick={() => {
                        this.props.close();
                    }}><i className="fas fa-times"></i></div>
                </div>

               


                <div className={this.state.currentScreenIndex === 1 ? Styles.body : Styles.hiddenform}>


                    <div className={Styles.formdiv}>
                        <div className={Styles.formtitle}>Org Name:</div>
                        <div className={Styles.formcontrol}>{this.state.orgName}</div>
                    </div>
                    <div className={Styles.formdiv}>
                        <div className={Styles.formtitle}>Program Name:</div>
                        <div className={Styles.formcontrol}>{this.state.program}</div>
                    </div>
                    <div className={Styles.formdiv}>
                        <div className={Styles.formtitle}>MDM ID:</div>
                        <div className={Styles.formcontrol}>{this.state.mdm_id}</div>
                    </div>
                    <div className={Styles.formdiv}>
                        <div className={Styles.formtitle}>Event Start Date:</div>
                        <div className={Styles.formcontrol}>{this.state.startdt}</div>
                    </div>
                    <div className={Styles.formdiv}>
                        <div className={Styles.formtitle}>Event End Date:</div>
                        <div className={Styles.formcontrol}>{this.state.enddt}</div>
                    </div>

                    <hr />


                    <div className={Styles.formdiv}>

                        <div className={Styles.formtitle}>Rep Participation:</div>
                        <div className={[Styles.formcontrol, Styles.Wiggle].join(" ")} >

                            <select onChange={(event) => { this.setState({ participation: event.target.value }) }}>
                                <option value=""></option>
                                <option value="Rep Attended">Rep Attended</option>
                                <option value="Not Attended - Other">Not Attended - Other</option>
                                <option value="Not Attended - Designee Attended">Not Attended - Designee Attended</option>
                                <option value="Not Attended - Illness">Not Attended - Illness</option>

                            </select>
                        </div>
                    </div>
                    <br />
                    <div className={Styles.formdiv}>
                        <div className={Styles.formtitle}>Participation Comments:</div>
                        <div className={Styles.formcontrol}><textarea onChange={(event) => { this.setState({ partcomments: event.target.value }) }}>{this.state.partcomments}</textarea></div>
                    </div>
                    <br />
                    <div className={Styles.formdiv}>
                        <div className={Styles.formtitle}>In addition to exhibit space, were all contractual benefits received?</div>
                        <div className={[Styles.formcontrol, Styles.Wiggle].join(" ")}>
                            <select onChange={(event) => { this.setState({ contractual: event.target.value }) }}>
                                <option value=""></option>
                                <option value="YES">Yes</option>
                                <option value="NO">No</option>
                                <option value="Not Applicable">Not Applicable</option>

                            </select>
                        </div>
                    </div>
                    <br />
                    <div className={Styles.formdiv}>
                        <div className={Styles.formtitle}>Please descibe benefits recieved:</div>
                        <div className={Styles.formcontrol}><textarea onChange={(event) => { this.setState({ benefits: event.target.value }) }}>{this.state.benefits}</textarea></div>
                    </div>

                    <div className={Styles.error}>{this.state.err}</div>
                </div>


                <div className={this.state.currentScreenIndex === 2 ? Styles.body : Styles.hiddenform}>


                    <div className={Styles.formdiv}>
                        <div className={Styles.formtitle}>Org Name:</div>
                        <div className={Styles.formcontrol}>{this.state.orgName}</div>
                    </div>
                    <div className={Styles.formdiv}>
                        <div className={Styles.formtitle}>Program Name:</div>
                        <div className={Styles.formcontrol}>{this.state.program}</div>
                    </div>
                    <div className={Styles.formdiv}>
                        <div className={Styles.formtitle}>MDM ID:</div>
                        <div className={Styles.formcontrol}>{this.state.mdm_id}</div>
                    </div>
                    <div className={Styles.formdiv}>
                        <div className={Styles.formtitle}>Event Start Date:</div>
                        <div className={Styles.formcontrol}>{this.state.startdt}</div>
                    </div>
                    <div className={Styles.formdiv}>
                        <div className={Styles.formtitle}>Event End Date:</div>
                        <div className={Styles.formcontrol}>{this.state.enddt}</div>
                    </div>

                    <hr />


                    <div className={Styles.formdiv}>

                        <div className={Styles.formtitle}>HCP Attendance (Approx):</div>
                        <div className={[Styles.formcontrol]} >

                            <select className={[Styles.formcontrol, Styles.Wiggle].join(" ")}  onChange={(event) => { this.setState({ attendance: event.target.value }) }}>
                                <option value=""></option>
                                <option value="10-25">10-25 Prescribers</option>
                                <option value="25-50">25-50 Prescribers</option>
                                <option value="50-75">50-75 Prescribers</option>
                                <option value="75+">75+ Prescribers</option>



                            </select>
                        </div>
                    </div>
                    <br />

                    <div className={Styles.formdiv}>

                        <div className={Styles.formtitle}>Number of Meaningful HCP Interactions:</div>
                        <div className={[Styles.formcontrol].join(" ")} >

                            <select className={[Styles.formcontrol, Styles.Wiggle].join(" ")}  onChange={(event) => { this.setState({ interactions: event.target.value }) }}>
                                <option value=""></option>
                                <option value="0-5">0-5 Total at Conference</option>
                                <option value="5-10">5-10 Total at Conference</option>
                                <option value="10-20">10-20 Total at Conference</option>
                                <option value="20-40">20-40 Total at Conference</option>
                            </select>
                        </div>
                    </div>
                    <br />

                    <div className={Styles.formdiv}>

                        <div className={Styles.formtitle}>Total Time of all HCP Interactions (Approx):</div>
                        <div className={[Styles.formcontrol].join(" ")} >

                            <select className={[Styles.formcontrol, Styles.Wiggle].join(" ")}  onChange={(event) => { this.setState({ interaction_time: event.target.value }) }}>
                                <option value=""></option>
                                <option value="30-60">30-60 Minutes</option>
                                <option value="60-120">60-120 Minutes</option>
                                <option value="120-180">120-180 Minutes</option>
                                <option value="180+">180+ Minutes</option>

                            </select>
                        </div>
                    </div>
                    <br />

                    <div className={Styles.formdiv}>

                        <div className={Styles.formtitle}>Incyte Product Theaters:</div>
                        <div className={[Styles.formcontrol].join(" ")} >

                            <select className={[Styles.formcontrol, Styles.Wiggle].join(" ")}  onChange={(event) => {
                                let showT = false;

                                if (event.target.value === 'Other') {
                                    showT = true;
                                }

                                this.setState({ theaters: event.target.value, showtheater: showT })


                            }}>
                                <option value=""></option>
                                <option value="Jakafi">Jakafi</option>
                                <option value="Monjuvi">Monjuvi</option>
                                <option value="Pemazyre">Pemazyre</option>
                                <option value="Other">Other (Explain)</option>

                            </select>
                           
                        </div>
                    </div>

                    <br />
                    <div className={Styles.formdiv}>

                        <div className={Styles.formtitle}>Theaters Other Reason:</div>
                        <div className={[Styles.formcontrol].join(" ")} >
                            <input type="text" className={this.state.showtheater ? Styles.showtheater : Styles.hidetheater} onChange={(event) => { this.setState({ theater_desc: event.target.value }) }}></input>
                        </div>
                    </div>

                    <br />

                    <div className={Styles.formdiv}>

                        <div className={Styles.formtitle}>Category:</div>
                        <div className={[Styles.formcontrol].join(" ")} >

                            <select className={[Styles.formcontrol, Styles.Wiggle].join(" ")}  onChange={(event) => { this.setState({ category: event.target.value }) }}>
                                <option value=""></option>
                                <option value="A">Type A: Must Attend</option>
                                <option value="B">Type B: Participate with Limitations</option>
                                <option value="C">Type C: Participate if Budget Allows</option>


                            </select>
                        </div>
                    </div>
                    <br />

                    <div className={Styles.error}>{this.state.err}</div>
                </div>

                <div className={Styles.buttonRow}>
                    <div id={Styles.submit} onClick={() => {
                        this.props.close();
                    }}>CANCEL</div>


                    <div id={this.state.currentScreenIndex === 1 ? Styles.disable : Styles.submit} onClick={() => {
                        this.movePrev();
                    }}>PREV</div>
                    <div id={this.state.currentScreenIndex === 1 ? Styles.submit : Styles.disable} onClick={() => {

                        var part = this.state.participation;
                        var partComments = this.state.partcomments;

                        var contractual = this.state.contractual;
                        var benefits = this.state.benefits;
                        if (part === "") {
                            this.setState({ err: 'Rep Participation is required.' });
                            return;
                        }


                        if (part.includes("Other") && partComments == "") {
                            this.setState({ err: 'Comments are required for Other Participation.' });
                            return;
                        }

                        if (contractual === "") {
                            this.setState({ err: '"Were all contractual benefits received" is required.' });
                            return;
                        } 
                        this.setState({ err: '' });

                        this.moveNext();
                    }}>NEXT</div>


                    <div id={this.state.currentScreenIndex === 2 ? Styles.submit : Styles.disable} onClick={() => {

                        var att = this.state.attendance;
                        var inter = this.state.interactions;
                        var intertime = this.state.interaction_time;
                        var thea = this.state.theaters;
                        var theadesc = this.state.theater_desc;
                        var cate = this.state.category;

                        if (att === "") {
                            this.setState({ err: 'Attendance is required.' });
                            return;
                        } 
                        if (inter === "") {
                            this.setState({ err: 'Interactions is required.' });
                            return;
                        } 
                        if (intertime === "") {
                            this.setState({ err: 'Interaction Time is required.' });
                            return;
                        } 
                        if (thea === "") {
                            this.setState({ err: 'Theater is required.' });
                            return;
                        } 
                        if (this.state.theaters === "Other" && theadesc === "") {
                            this.setState({ err: 'Explaination is required when Theater is set to Other.' });
                            return;
                        } 
                        if (cate === "") {
                            this.setState({ err: 'Category is required.' });
                            return;
                        } 
                        this.props.submit(this.state.id, this.state.participation,
                            this.state.partcomments, this.state.contractual, this.state.benefits,
                            this.state.attendance, this.state.interactions, this.state.interaction_time, this.state.theaters, this.state.theater_desc, this.state.category);

                        
                    }}>SUBMIT</div>
                </div>
            </div>
        )
    }

}
export default WorkflowPostEventDialog;







