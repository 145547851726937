import React, { Component } from 'react'
import Styles from './WorkflowStatus.module.css'
import WorkflowStatusDetailBlock from './WorkflowStatusDetailBlock'
import options from './options.json';
import WorkflowApproverBlock from './WorkflowApproverBlock'
import WorkflowFile from './WorkflowFile'
import WorkflowStatusDiagramBlock from './WorkflowStatusDiagramBlock'
import TransactionHistoryItem from './TransactionHistoryItem'
import CloseoutItem from './CloseoutItem'
import Axios from '../../components/Axios'
import WorkflowApprovalDialog from './WorkflowApprovalDialog'

class WorkflowStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            wfJson: '',
            files: [],
            orgName: '',
            Amount: 0,
            programTitle: '',
            submitted: '',
            approvers: [],
            trans_history: [],
            trans_closeout: [],
            diagramNodes: [],
            optionalNodes: [],
            allFilesDownloaded: false,
            enableApproveButton: false,
            enableRejectButton: false,
            enableCancelButton: false,
            view: '',
            refresh: -1
        };
    }
    componentDidMount() {
        let json = {};
        json.user = this.props.user;
        json.id = this.props.id;

        Axios('/api/workflow/get-workflow-detail', json, (data) => {

            this.setState({ data: data });

            this.buildUI(data);
            this.updateButtonStatus(data);

        });


    }
    updateButtonStatus(data) {

        var _enableRejectButton = this.getRejButtonStatus(data);

        var btnStatus = this.getButtonStatus(data);
        var allFiles = this.allFilesHaveBeenDownloaded(data);
        var enableCanButton = this.getCancelButtonStatus(data);
        var _enableApproveButton = btnStatus && allFiles;


        this.setState({ enableApproveButton: _enableApproveButton, enableRejectButton: _enableRejectButton, enableCancelButton: enableCanButton });



    }
    getOptionValue(key, value) {
        var list = options.options;
        var ret = '';

        for (let i = 0; i < list.length; i++) {

            var optionName = list[i].name;
            if (optionName == key) {
                list[i].data.forEach(optionItem => {
                    if (optionItem.value == value) {
                        ret = optionItem.longname;
                    }
                })
            }
        }
        return ret;
    }
    closedialog() {
        this.setState({
            view: ''
        })


    }
    allFilesHaveBeenDownloaded(data) {

        var FilesDownloaded = true;
        var hasBeenDownLoaded = false;
        data.files.forEach(file => {
            hasBeenDownLoaded = this.isDownloaded(data.file_downloads, file.FILE_ID, this.props.user.email);
            if (!hasBeenDownLoaded) {
                FilesDownloaded = false;
            }
        });

        return FilesDownloaded;
    }
    isDownloaded(downloads, file_id, username) {

        var returnVar = false;

        downloads.forEach(file => {
            var userid = file.USER_ID.toLowerCase();



            if (file.FILE_ID == file_id && username.toLowerCase().includes(userid)) {



                returnVar = true;


            }

        });
        return returnVar;
    }
    refreshFiles() {

        let json = {};
        json.user = this.props.user;
        json.org_id = this.props.id;
        json.id = this.props.id;

        Axios('/api/workflow/get-workflow-detail', json, (data) => {

            this.setState({ data: data });

            this.updateButtonStatus(data);

        });



    }
    buildUI(data) {

        var kv_state = [];
        var returnArray = [];
        var valueArray = [];
        var valueTemp = '';
        var keyTemp = '';
        var value = '';
        var titleTemp = "";
        var odd = false;
        var org = '';
        var program = '';
        var submitted = '';
        var username = '';
        var nodearray = [];
        var filesArr = [];
        var diagramArr = [];
        var optionalArr = [];
        var keycompare = '';
        var valuecompare = '';
        var EditRemit = false;
        var FilesDownloaded = true;

        kv_state = data.data[0].WORKFLOW_JSON.split("|");
        org = data.data[0].ORG_NAME;
        program = data.data[0].PROGRAM_TITLE;
        username = data.data[0].USER_ID;

        var user_role = data.data[0].USER_ROLE;

        submitted = data.data[0].SUBMIT_DATE;


        nodearray = data.def.Nodes;
        kv_state.forEach(kvpair => {
            var key = kvpair.split("=")[0];
            var name = kvpair.split("=")[0];
            var value = kvpair.split("=")[1];

            nodearray.forEach(node => {

                node.Fields.forEach(field => {

                    if (key == field.FieldName.replace("wfdata_", "")) {
                        name = field.FieldTitle;
                        switch (field.DataType) {
                            case "bool":
                                //if (keyTemp.includes('EditRemit') && valuecompare == 'true') {
                                //    EditRemit = true;
                                //}
                                value = value;
                                break;
                            case "datetime":
                                value = new Date(value).toDateString();
                                break;
                            case "search":
                                value = value;
                                break;
                            case "select":
                                value = value;
                                break;
                            default:
                                value = value;
                                break;
                        }
                    }

                });
            });




            returnArray.push(< WorkflowStatusDetailBlock key={key} title={name} value={value} odd={odd} />);
            odd = !odd;
        });



        //
        //nodearray.forEach(node => {

        //    node.Fields.forEach(field => {

        //        keyTemp = field.FieldName.replace("wfdata_", " ");

        //        titleTemp = field.FieldTitle;

        //        value = '';

        //        kv_state.forEach(kvpair => {

        //            keycompare = kvpair.split("=")[0];

        //            valuecompare = kvpair.split("=")[1];

        //            if (keycompare.trim() == keyTemp.trim()) {
        //                switch (field.DataType) {
        //                    case "bool":
        //                        //if (keyTemp.includes('EditRemit') && valuecompare == 'true') {
        //                        //    EditRemit = true;
        //                        //}
        //                        value = valuecompare;
        //                        break;
        //                    case "datetime":
        //                        value = new Date(valuecompare).toDateString();
        //                        break;
        //                    case "search":
        //                        value = valuecompare;
        //                        break;
        //                    case "select":
        //                        value = valuecompare;
        //                        break;
        //                    default:
        //                        value = valuecompare;
        //                        break;
        //                }
        //            }
        //        })



        //        returnArray.push(< WorkflowStatusDetailBlock key={keyTemp} title={titleTemp} value={value} odd={odd} />);

        //        odd = !odd;
        //    });
        //});

        var approvers = [];
        var transHistory = [];

        var appjson = data.data[0].APPROVAL_JSON;
        var wfArray = data.workflow_status;
        var isFirst = true;
        var isRejected = false;

        var amt = data.data[0].AMOUNT;
        var paymthd = data.data[0].PAY_METHOD;

        var hasBeenDownLoaded = false;

        data.files.forEach(file => {


            hasBeenDownLoaded = this.isDownloaded(data.file_downloads, file.FILE_ID, this.props.user.email);


            if (!hasBeenDownLoaded) {
                FilesDownloaded = false;
            }

            filesArr.push(<WorkflowFile data={data.data[0]} refreshFiles={this.refreshFiles.bind(this)} downloaded={hasBeenDownLoaded} isDownloaded={false} key={file.FILE_NAME} username={this.props.user.email.toLowerCase()} file={file} />);

        });


        diagramArr.push(<WorkflowStatusDiagramBlock isLast={'false'} key={0} color={'green'} text={'SUBMITTED'} />);
        approvers.push(<WorkflowApproverBlock key={0} name={username} role={user_role == "MARKET ACCESS" ? "MA" : user_role} color={'greenfont'} status={'SUBMITTED'} email={username} />);


        wfArray.forEach(approver => {

            var color = 'black';
            var colortext = '';

            if (approver.transaction_type == 'APPROVED' || approver.transaction_type == 'COMPLETED') {
                color = 'green';
                colortext = 'greenfont';
            } else if (approver.transaction_type == 'REJECTED') {
                color = 'red';
                colortext = 'redfont';
                isRejected = true;
            }




            //isFirst = false;
            diagramArr.push(<WorkflowStatusDiagramBlock isLast={'false'} key={approver.Index} color={color} text={approver.Role == "MARKET ACCESS" ? "MA" : approver.Role} />);

            if (amt > 5000) {

                if (EditRemit) {

                } else {
                    if (approver.Role == "OPS" || approver.Role == 'COMPLIANCE' || approver.Role == 'VP') {
                        color = 'grey';
                        //  colortext = 'greyfont';
                    }
                }
                approvers.push(<WorkflowApproverBlock key={approver.Index} name={approver.Name} role={approver.Role == "MARKET ACCESS" ? "MA" : approver.Role} color={colortext} status={approver.transaction_type} email={approver.Email} />);


            } else {

                if (EditRemit) {

                } else {

                    //under 5k + NO edit remit - needs only one level
                    if (approver.Role == "OPS" || approver.Role == 'COMPLIANCE' || approver.Role == 'VP') {
                        color = 'grey';
                        //   colortext = 'greyfont';
                    }
                }
                approvers.push(<WorkflowApproverBlock key={approver.Index} name={approver.Name} role={approver.Role == "MARKET ACCESS" ? "MA" : approver.Role} color={colortext} status={approver.transaction_type} email={approver.Email} />);

            }

        });

        var finalText = "IN-PROGRESS";
        var finalColor = "grey"

        switch (data.data[0].STATUS) {
            case "APPROVED":
                finalColor = 'green';
                finalText = 'APPROVED';
                break;
            case "REJECTED":
                finalColor = 'red';
                finalText = 'REJECTED';
                break;
            case "CANCELED":
                finalColor = 'red';
                finalText = 'CANCELED';
                break;
            case "COMPLETED":
                finalColor = 'green';
                finalText = 'COMPLETED';
                break;
            case "OCCURRED":
                finalColor = 'green';
                finalText = 'OCCURRED';
                break;
            default:

                break;
        }

        if (isRejected) {
            finalColor = 'red';
            finalText = 'REJECTED';
        }
        diagramArr.push(<WorkflowStatusDiagramBlock key={'99'} isLast={'true'} color={finalColor} text={finalText} />);

        var oddrow = false;

        var history = [];
        data.transaction_history.forEach(transaction => {

            history.push(<TransactionHistoryItem odd={oddrow} key={transaction.WORKFLOW_TRANSACTION_ID} type={transaction.TRANSACTION_TYPE} note={transaction.TRANSACTION_NOTE} user={transaction.USER_NAME} date={transaction.TRANSACTION_DATE} />);
            oddrow = !oddrow;
        });

        var closeout = [];

        closeout.push(<CloseoutItem odd={false} title={"Rep Participation"} value={data.REP_PARTICIPATION} />);
        closeout.push(<CloseoutItem odd={true} title={"Participation Comments"} value={data.PARTICIPATION_NOTES} />);
        closeout.push(<CloseoutItem odd={false} title={"Contractual Benefits Received"} value={data.CONTRACTUAL} />);
        closeout.push(<CloseoutItem odd={true} title={"Benefit Description"} value={data.CONTRACTUAL_NOTES} />);

        closeout.push(<CloseoutItem odd={true} title={"Attendance"} value={data.ATTENDANCE} />);
        closeout.push(<CloseoutItem odd={true} title={"Interactions"} value={data.INTERACTIONS} />);
        closeout.push(<CloseoutItem odd={true} title={"Interaction Time"} value={data.INTERACTION_TIME} />);
        closeout.push(<CloseoutItem odd={true} title={"Theater"} value={data.THEATERS + ' ' + data.THEATER_DESC} />);
        closeout.push(<CloseoutItem odd={true} title={"Category"} value={data.CATEGORY} />);

        var disablereject = this.getButtonStatus(data.data[0]);

        this.setState({ trans_closeout: closeout, trans_history: history, wfJson: returnArray, orgName: org, programTitle: program, submitted: submitted, approvers: approvers, files: filesArr, diagramNodes: diagramArr, Amount: amt, allFilesDownloaded: FilesDownloaded, disableReject: disablereject })
    }
    getButtonStatus(data) {

        var role = this.props.user.role;
        var isAdmin = data.IsAdmin;
        var isEDAdmin = data.IsEDAdmin;
        var currentUserName = this.props.user.email.toLowerCase();

        var mystatus = data.STATUS;

        if (mystatus == undefined) {
            mystatus = data.current_workflow_status;
        }
        if (mystatus == "APPROVED" || mystatus == "CANCELED") {
            return false;
        }

        if (isAdmin || isEDAdmin) {
            return true;
        }

        //if (role == 'OHS') {
        //    return 'disabled';
        //}

        if (data.IS_NEXT_APPROVER) {

            return true;

        }
        return false;
    }
    getCancelButtonStatus(data) {


        var mystatus = data.STATUS;
        if (mystatus == undefined) {
            mystatus = data.current_workflow_status;
        }

        if (mystatus == "COMPLETED" || mystatus == "CANCELED") {
            return false;
        }

        return true;
    }
    getRejButtonStatus(data) {

        var role = this.props.user.role;
        var isAdmin = data.IsAdmin;
        var isEDAdmin = data.IsEDAdmin;
        var currentUserName = this.props.user.userName.toLowerCase();

        var mystatus = data.current_workflow_status;

        if (mystatus == "APPROVED" || mystatus == "CANCELED" || mystatus == "REJECTED") {
            return false;
        }

        if (isAdmin || isEDAdmin) {
            return true;
        }

        //if (role == 'OHS') {
        //    return 'disabled';
        //}

        if (data.IS_NEXT_APPROVER) {

            return true;

        }
        return false;
    }
    render() {



        return (

            <div className={Styles.editScreen}>
                <div className={Styles.title}>
                    <div></div>
                    <div className={Styles.TitleText}>{this.state.orgName} {this.state.programTitle}</div>
                    <div className={Styles.close} onClick={() => {
                        this.props.close()
                    }}><i className="fas fa-times"></i></div>
                </div>
                {this.state.view}
                <div className={Styles.workflowdisplay}>

                    <ul className={Styles.progressnav}>

                        {this.state.diagramNodes}

                    </ul>

                    <div className={Styles.workflowdisplaykey}>
                        <div className={Styles.keyitem}>
                            <div className={Styles.keyitemblockcomplete}></div>
                            <div className={Styles.keyitemblocklabel}>APPROVED</div>

                        </div>
                        <div className={Styles.keyitem}>
                            <div className={Styles.keyitemblockincomplete}></div>
                            <div className={Styles.keyitemblocklabel}>INCOMPLETE</div>
                        </div>
                        <div className={Styles.keyitem}>
                            <div className={Styles.keyitemblockna}></div>
                            <div className={Styles.keyitemblocklabel}>NOT REQUIRED</div>
                        </div>
                        <div className={Styles.keyitem}>
                            <div className={Styles.keyitem}>
                                <div className={Styles.keyitemblockatn}></div>
                                <div className={Styles.keyitemblocklabel}>REQUIRES ATTENTION</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={Styles.body}>
                    <b>ORG:</b> <i>{this.state.orgName}</i>&nbsp;&nbsp;&nbsp;&nbsp; <b>PROGRAM:</b> <i>{this.state.programTitle}</i>
                    &nbsp;&nbsp;&nbsp;&nbsp;<b>SUBMITTED:</b> <i>{new Date(this.state.submitted).toDateString()}</i>
                    &nbsp;&nbsp;&nbsp;&nbsp;<b>AMOUNT: </b> <i>${this.state.Amount}</i>
                    <hr />

                    <div className={Styles.blockTitle}>APPROVAL CHAIN</div>
                    <div >
                        <div className={Styles.approvalblock}>
                            {this.state.approvers}
                        </div>

                    </div>

                    <div>
                        <div className={Styles.leftcol}>
                            <div className={Styles.blockTitle}>TRANSACTION HISTORY</div>
                            <div className={Styles.historyblock}>
                                {this.state.trans_history}
                            </div>
                        </div>
                        <div className={Styles.rightcol}>
                            <div className={Styles.blockTitle}>REQUEST DETAIL</div>
                            <div >
                                <div className={Styles.detailblock}>
                                    {this.state.wfJson}
                                </div>

                            </div>
                        </div>
                    </div>

                    <div>
                        <div className={Styles.leftcol}>
                            <div className={Styles.blockTitle}>ATTACHMENTS</div>
                            <div >

                                <div className={Styles.attachmentblock}>
                                    {this.state.files}
                                </div>

                            </div>

                        </div>

                        <div className={Styles.rightcolclose}>
                            <div className={Styles.blockTitle}>EVENT CLOSE</div>
                            <div >
                                <div className={Styles.detailblockclose}>
                                    {this.state.trans_closeout}
                                </div>

                            </div>
                        </div>
                    </div>


                </div>
                <div className={Styles.buttonRow}>
                    <div id={Styles.submit} onClick={() => {
                        this.props.close();
                    }}>CLOSE</div>

                    <div className={this.state.enableCancelButton ? Styles.enableButton : Styles.disableButton} id="cancelevent" onClick={() => {
                        if (this.state.enableCancelButton) {
                            this.props.cancelEvent(this.state.data.data[0].WORKFLOW_INSTANCE_ID, this.state.data.data[0].ORG_NAME, this.state.data.data[0].PROGRAM_TITLE);
                        }


                    }}>CANCEL EVENT</div>



                    <div id={'approve'} className={this.state.enableApproveButton ? Styles.enableButton : Styles.disableButton} onClick={() => {

                        if (this.state.enableApproveButton) {

                            var x = this.state.data.data[0].USER_ID;
                            // alert(x);
                            this.props.approve(this.state.data.data[0].WORKFLOW_INSTANCE_ID,
                                'APPROVED', this.state.data.data[0].ORG_NAME,
                                this.state.data.data[0].PROGRAM_TITLE,
                                new Date(this.state.data.data[0].START_DT).toLocaleDateString('en-US'),
                                x
                            );



                        }

                    }}>APPROVE</div>

                    <div id={'reject'} className={this.state.enableRejectButton ? Styles.enableButton : Styles.disableButton} onClick={() => {


                        if (this.state.enableRejectButton) {



                            this.props.approve(this.state.data.data[0].WORKFLOW_INSTANCE_ID,
                                'REJECTED', this.state.data.data[0].ORG_NAME,
                                this.state.data.data[0].PROGRAM_TITLE,
                                new Date(this.state.data.data[0].START_DT).toLocaleDateString('en-US'),
                                this.state.data.data[0].USER_ID
                            );



                        }

                    }}>REJECT</div>




                </div>
            </div>


        )
    }

}
export default WorkflowStatus;







