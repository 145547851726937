import {Component} from 'react';

class Popup extends Component {
    componentWillUnmount() {
        document.body.style.overflow = "auto"
    }

    render() {
        document.body.style.overflow = "hidden"
        return (this.props.component)
    }
}
export default Popup;
