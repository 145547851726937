import React, {Component} from 'react';
import styles from '../css/OneKeySearch.module.css';
import Result from './OneKeyResult';
import Axios from './../../../components/Axios';
import Pagination from './Pagination';

class OneKeySearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            count: 0,
            selRecordID: -1,
            foundOne: false, 
            recCount: 1,
            currentPage: 0,
            arrData: [],
            arrPageData: [],
            searchCriteria: ''
        };

        
        
    }

    componentDidMount() {
        this.buildResults();
    }
    setSelectedRecord(id) {

     
        this.setState({ selRecordID: id });


    }
    buildResults() {

     
        let temp = {
            user:  this.props.user,
            FIRST_NAME: this.props.searchcriteria.FIRST_NAME,
            LAST_NAME: this.props.searchcriteria.LAST_NAME, //'Binder',
            ADDRESS1: this.props.searchcriteria.ADDRESS1,
            CITY: this.props.searchcriteria.CITY,
            STATE: this.props.searchcriteria.STATE,
            COUNTRY: this.props.searchcriteria.COUNTRY_CODE,
            POSTAL_CODE: this.props.searchcriteria.ZIP,
            RESULT_SIZE: '250'
        }

        let _foundOne = false;

        var arr = [];
       
        Axios('/api/search/onekey', temp, (results) => {

            let x = '';
            let _odd = false;

            for (var i = 0; i < results.length; i++) {

                arr.push(<Result key={i} rownumber={i} odd={_odd} setID={this.setSelectedRecord.bind(this)} data={results[i]} />);

                _odd = !_odd;
            }
            this.setState({ arrData: arr, recCount: i, searchCriteria: temp, loaded: true });
            this.changePage(0, arr);

           });


        return _foundOne;
    }

    changePage(newPageIndex, arr) {

        let xCount = arr.length;


        if (newPageIndex !== undefined && newPageIndex >= 0 && (newPageIndex * 10) < xCount ) {

            let newStart = newPageIndex * 10;  
            let _odd = false;
            let _pageArr = [];
            for (var i = newStart; i < newStart + 10; i++) {
                _pageArr.push(arr[i]);
                _odd = !_odd;
            }
            this.setState({ arrPageData: _pageArr, currentPage: newPageIndex });
        }

    }
    render() {

        if (this.state.recCount < 1) {
            this.props.switchOneKey(); 
        }
      
        return (
            <div className={styles.editScreen}>
                <div className={styles.wrapper}>
                    <div className={styles.title}>
                        <div></div>
                        <div className={styles.TitleText}>OneKey</div>
                        <div className={styles.close} onClick={() => {
                            this.props.close();
                        }}><i className="fas fa-times"></i></div>
                    </div>
                    <div className={styles.body}>

                        <div className={styles.onekeypagination}>
                            {this.state.loaded ? this.state.arrPageData : (<div className={styles.loading }>Loading One Key Data . . .</div>)}
                        </div>

                        <Pagination resultsPerPage={10} searchData={this.state.arrData } totalResults={this.state.recCount} currentPage={this.state.currentPage} setCurrentPage={ this.changePage.bind(this)} />


                    <div className={styles.buttonRow}>
                        <div id={styles.cancel} onClick={() => {
                            this.props.close();
                            }}>CANCEL</div>
                            <div id={styles.cancel} onClick={() => {
                                this.props.switchOneKey();
                            }}>ENTER MANUALLY</div>
                        <div className={this.state.Wiggle && this.state.Wiggle !== undefined ? styles.Wiggle : ""} id={styles.submit} onClick={() => {

                                let _id = this.state.selRecordID;
                             
                                if (_id < 0) {
                                    alert("Please Select a Record to Continue");
                                } else {
                                    let arDataRecord = this.state.arrData[_id].props.data;
                                   // let arDataString = arDataRecord/toString();

                                    let temp = {
                                        FIRST_NAME: arDataRecord.FIRST_NAME,
                                        LAST_NAME: arDataRecord.LAST_NAME,
                                        MIDDLE_NAME: arDataRecord.MIDDLE_NAME,
                                        ADDRESS1: arDataRecord.ADDRESS1,
                                        CITY: arDataRecord.CITY,
                                        STATE: arDataRecord.STATE,
                                        COUNTRY: arDataRecord.COUNTRY,
                                        POSTAL_CODE: arDataRecord.POSTAL_CODE,
                                        ONEKEY_ID: arDataRecord.IND_ONEKEY_ID,
                                        IS_VALID: arDataRecord.IS_VALID,
                                        SPECIALTY: arDataRecord.SPECIALTY,
                                        FULL_REQUEST: arDataRecord.FULL_REQUEST
                                    }

                                    this.props.submit(temp);
                                }

                            }}>NEXT</div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default OneKeySearch;
