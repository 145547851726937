import React, {useState, useEffect} from 'react';
import TitleButton from './components/TitleButton'
import Results from './components/Results'
import NewExerciseContainer from '../CreateExerciseWizard'
import EditExercise from './components/EditExercise'
import DeleteExercise from './components/DeleteExercise'
import EditReasons from './components/EditReasons'
import Popup from '../PopupWindow/Popup'
import Axios from '../Axios'

function AdminExerciseTemplate(props) {
    const [data, setData] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [newExercise, setNewExercise] = useState(false)
    const [editExercise, setEditExercise] = useState(false)
    const [editIndex, setEditIndex] = useState()
    const [deleteExercise, setDeleteExercise] = useState(false)
    const [deleteIndex, setDeleteIndex] = useState()
    const [editReasons, setEditReasons] = useState()

    useEffect(() => {
        let temp = { type: props.type }
        Axios(props.endpoints.getExercises, temp, (data) => {
            setData(data)
            setLoaded(true)
        })
    }, [])

    return (
        <div>
            {newExercise ?
                (<Popup component={NewExerciseContainer} type={props.type} EXERCISE_ID_KEY={props.EXERCISE_ID_KEY} defaultColumns={props.defaultColumns} user={props.user} endpoints={props.endpoints} ChangeWidth={props.ChangeWidth} data={data} setData={setData} close={() => {
                    setNewExercise(false)
                }} />)
                    : ""}
            {editExercise ?
                (<Popup component={EditExercise} endpoints={props.endpoints} type={props.type} data={data[editIndex]} setData={setData} index={editIndex} close={() => {
                    setEditExercise(false)
                }} />)
                    : ""}
            {deleteExercise ?
                (<Popup component={DeleteExercise} endpoints={props.endpoints} data={data[deleteIndex]} setData={setData} index={deleteIndex} close={() => {
                    setDeleteExercise(false)
                }} />)
                    : ""}
            {editReasons ?
                (<Popup component={EditReasons} endpoints={props.endpoints} data={data} setData={setData} type={props.type} close={() => {
                    setEditReasons(false)
                }} />)
                    : ""}

            <TitleButton title={props.title} buttonText={"ADD EXERCISE"} buttonClick={() => {
                setNewExercise(true)
            }} moreButtons={false}/>
            <Results barTitle={"Exercise History"} loaded={loaded} data={data} setData={setData} setEditExercise={setEditExercise} setEditIndex={setEditIndex} setDeleteExercise={setDeleteExercise} setDeleteIndex={setDeleteIndex}/>
        </div>
    )
}

export default AdminExerciseTemplate
