import React, {Component} from 'react';
import styles from '../css/AdminResult.module.css';
import Axios from './../../../components/Axios'

class AdminResult extends Component {
    constructor(props) {
        super(props)
        this.state = {
            statusOptions: props.stateOptions,
            refresh: false,
            data: props.data,
            odd: (
                props.number % 2 === 0
                ? true
                    : false),
            status_id: props.data.STATUS_ID

        };
    }

    render() {
        let links = []

        let _statusOptions = []
        for (let i = 0; i < this.props.statusOptions.length; i++) {
            _statusOptions.push(<option key={this.props.statusOptions[i].STATUS_ID} value={this.props.statusOptions[i].STATUS_ID}>{this.props.statusOptions[i].STATUS}</option>)
        }

        let status
        let statID = this.state.data.STATUS_ID

        if (["ADMIN"].includes(this.props.user.role)) {
            status = (
                <select value={this.state.status_id} className={[styles.setInput, this.props.data.STATUS_ID === 1 ? styles.approved : "", this.props.data.STATUS_ID === 2 ? styles.rejected : ""].join(" ")} selid={this.props.data.ID}  disabled={this.props.approved} onChange={(event) => {

                    let temp = {
                            statusID: event.target.value,
                            ID: event.target.attributes['selid'].nodeValue,
                            rpId: this.state.data.REGIONAL_PLAN_ID,
                            rpOwnerId: this.state.data.EMP_ID
                    }

                    Axios('/api/regional-plan/submit-status', temp, (data) => {


                        this.setState({
                            status_id: temp.statusID
                        })


                        this.props.refreshStatusAfterChanged(data)
                        
                    })
                   
                    }}>
                        {_statusOptions}
                    </select>
            )
        }
        else {
            status = this.props.data.STATUS
        }

        links.push(<div className={styles.link} key={'A1'} onClick={() => {
                this.props.changeView(this.props.data, this.props.number)
            }}>View</div>)
        if (this.props.admin) {
            links.push(<div className={styles.link} key={'A2'} onClick={() => {
                    this.props.approve(this.props.number)
                }}>Approve</div>)
            links.push(<div className={styles.link} key={'A3'} onClick={() => {
                    this.props.reject(this.props.number)
                }}>Reject</div>)
        }
        return (<div className={this.state.odd
                ? [styles.results, styles.odd].join(' ')
                : styles.results}>
            <div className={[styles.result, styles.long].join(' ')}>{`${this.props.data.TERR_ID} - ${this.props.data.TERR_NAME}`}</div>
            <div className={[styles.result, styles.long].join(' ')}>{`${this.props.data.EMP_FIRST_NAME} ${this.props.data.EMP_LAST_NAME}`}</div>
            <div className={[styles.result, styles.long].join(' ')}>{this.props.data.REGIONAL_PLAN_COUNT}</div>
            <div className={[styles.result, styles.short].join(' ')}>{status}</div>
            <div className={[styles.links].join(' ')}>
                {links}
            </div>
        </div>);
    }
}
export default AdminResult;
