import React from 'react'
import styles from './../css/TargetColorBox.module.css'

function TargetColorBox(props) {
    return (
        <div className={[styles.statusBoxColor, styles[props.target], props.small ? styles.small : ""].join(" ")}>{props.target}</div>
    )
}

export default TargetColorBox
