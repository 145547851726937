import React from 'react'
import styles from './../css/StatusRecord.module.css'

function StatusRecord(props) {
    return (
        <div className={props.odd
                ? [styles.result, styles.odd].join(' ')
                : styles.result}>
            <div className={[styles.col, styles.short].join(' ')}>{props.data.STATUS}</div>
            <div className={[styles.col, styles.long].join(' ')}>{props.data.DESCRIPTION}</div>
        </div>
    )
}

export default StatusRecord
