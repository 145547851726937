import React, {useState, useEffect, useCallback} from 'react'
import styles from '../css/ChangeWidth.module.css'
import logo from '../../../assets/logo.png'
import Axios from '../../Axios'

function ChangeWidth(props) {
    const [config, setConfig] = useState()
    const [loaded, setLoaded] = useState()

    const [selected, setSelected] = useState(0)

    const createDiv = (height) => {
        let div = document.createElement('div');
        div.style.top = 0;
        div.style.right = '-7px'
        div.style.width = '10px'
        div.style.position = 'absolute'
        div.style.cursor = 'col-resize'
        div.style.userSelect = 'none'
        div.style.height = height+'px'
        return div;
    }

    const setListeners = (div, config, setConfig) => {
        let pageX,curCol,nxtCol,curColWidth,nxtColWidth, dragging
        div.addEventListener('mousedown', function (e) {
            curCol = e.target.parentElement
            nxtCol = curCol.nextElementSibling
            pageX = e.pageX
            curColWidth = curCol.offsetWidth
            if (nxtCol)
                nxtColWidth = nxtCol.offsetWidth
            dragging = true
        })

        document.addEventListener('mousemove', function (e) {
            if (dragging) {
                if (curCol) {
                    let diffX = e.pageX - pageX

                    if (nxtCol)
                        nxtCol.style.width = (nxtColWidth - (diffX))+'px'
                    curCol.style.width = (curColWidth + diffX)+'px'
                }
            }
        })

        document.addEventListener('mouseup', function (e) {
            if (dragging) {
                let total = 0
                let temp = config
                let arr = []
                curCol.parentElement.parentElement.childNodes[0].childNodes.forEach((node, i) => {
                    total = total + node.offsetWidth
                    arr.push(node.offsetWidth)
                });
                for (let i = 0; i < Object.keys(config).length; i++) {
                    temp[i].flex = 100 * (arr[i] / total)
                }
                setConfig(temp)
            }
            dragging = false
        })
    }

    const tableRef = useCallback((node) => {
        if (node) {
            let row = node.getElementsByTagName('tr')[0]
            let cols = row ? row.children : undefined
            if (!cols) return
            for (let i = 0; i < cols.length; i++) {
                let div = createDiv(node.offsetHeight)
                cols[i].appendChild(div)
                let ele = cols[i].getElementsByTagName('div')[0]
                ele.style.position = 'relative'
                setListeners(div, config, setConfig)
            }
        }
    }, [loaded]);

    useEffect(() => {
        Axios(props.endpoints.getExercise, { id: props.data.ID }, (data) => {
            console.log(data)
            setConfig(JSON.parse(data[0].COLUMNS))
            setLoaded(true)
        })
    }, [])

    return (
        <div className={styles.frame}>
            <div className={styles.NavBar}>
                <div className={styles.Wrapper}>
                    <img className={styles.Logo} src={logo} alt=""/>
                </div>
                <ul>
                    <li>
                        <div className={styles.Pages} onClick={() => {}}>Home</div>
                    </li>
                    <li>
                        <div className={styles.Pages} onClick={() => {}}>Professionals</div>
                    </li>
                    <li>
                        <div className={styles.Pages} onClick={() => {}}>Organizations</div>
                    </li>

                    <li className={styles.preview}>
                        <div className={styles.greenBlock}>
                            ADMIN PREVIEW
                        </div>
                    </li>
                    <li className={styles.buttons}>
                        <div className={styles.text}>Adjust the column manually by clicking the edge of the column and dragging.</div>
                        <button className={[styles.button, styles.cancel].join(" ")} onClick={() => {
                                props.setView(props.view - 1)
                            }}>CANCEL</button>
                        <button className={[styles.button, styles.save].join(" ")} onClick={() => {
                                let temp = {
                                    id: props.data.ID,
                                    config: config
                                }
                                Axios(props.endpoints.editExerciseWidth, temp, (data) => {
                                    props.setView(props.view - 1)
                                })
                            }}>SAVE</button>
                    </li>
                </ul>
            </div>
            <props.ChangeWidth {...props} selected={selected} setSelected={setSelected} preview={true} exercise={props.data} tableRef={tableRef} config={config} setConfig={setConfig}/>
        </div>
    )
}
export default ChangeWidth
