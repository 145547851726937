import React, {Component} from 'react';
import styles from '../css/Details_Edit_PriAddress.module.css';
import DegreeLookUp from '../../../globalcomponents/DegreeLookUp';
import AddressLookUp from './../../../globalcomponents/AddressLookUp'

class Details_Edit_PriAddress extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ADDRESS1: this.props.data.ADDRESS1,
            ADDRESS2: this.props.data.ADDRESS2,
            CITY: this.props.data.CITY,
            STATE: this.props.data.STATE,
            ZIPCODE: this.props.data.ZIP5,
            Wiggle: false,
            changed: {},
            ISVALID: true
        };
    }

    firstCapital(str) {
        if (str !== undefined) {
            return str.toLowerCase().replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();})
        }
        return "";
    }

    updateAddress(address) {
        //console.log(address);
        this.setState({
            ADDRESS1: address.streetOne,
            ADDRESS2: address.streetTwo,
            CITY: address.city,
            STATE: address.state,
            ZIPCODE: address.zip
        });
    }

    render() {
        let DegreeOptions = []
        for (var i = 0; i < Object.keys(DegreeLookUp).length; i++) {
            DegreeOptions.push(<option key={i} value={Object.keys(DegreeLookUp)[i]}>{Object.keys(DegreeLookUp)[i]}</option>)
        }
        return (
            <div className={styles.editScreen}>
                <div className={styles.wrapper}>
                    <div className={styles.title}>
                        <div></div>
                        <div className={styles.TitleText}>Edit Address</div>
                        <div className={styles.close} onClick={() => {
                            this.props.close()
                        }}><i className="fas fa-times"></i></div>
                    </div>
                    <div className={styles.body}>
                        <AddressLookUp updateAddress={this.updateAddress.bind(this)} error={this.state.ISVALID} info={this.state}/>
                        <div className={styles.buttonRow}>
                            <div id={styles.cancel} onClick={() => {
                                this.props.close();
                            }}>CANCEL</div>
                            <div className={this.state.Wiggle && this.state.Wiggle !== undefined ? styles.Wiggle : ""} id={styles.submit} onClick={() => {
                                if (this.state.ADDRESS1 !== '' && this.state.CITY !== '' && this.state.STATE !== '' && this.state.ZIPCODE.length === 5) {
                                    let temp = {
                                        ADDRESS1: this.state.ADDRESS1,
                                        ADDRESS2: this.state.ADDRESS2,
                                        CITY: this.state.CITY,
                                        STATE: this.state.STATE,
                                        ZIPCODE: this.state.ZIPCODE,
                                        REQUEST_TYPE: 'HCO Address Change'
                                    }
                                    this.props.submit(temp);
                                }
                                else {
                                    this.setState({
                                        Wiggle: true,
                                        ISVALID: false
                                    })
                                    setTimeout(() => {
                                        this.setState({Wiggle: false})
                                    }, 500);
                                }
                            }}>SUBMIT</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default Details_Edit_PriAddress;
