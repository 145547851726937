import React, {useState, useEffect} from 'react'
import styles from '../css/CustomizeColumns.module.css'
import ColumnEditBox from './ColumnEditBox'

function CustomizeColumns(props) {
    const [tooMany, setTooMany] = useState(false)
    const [customCols, setCustomCols] = useState(props.customColumns || {})
    const [selected, setSelected] = useState(0)

    const [wiggle, setWiggle] = useState(false)
    const [valid, setValid] = useState(true)

    useEffect(() => {
        if (customCols) {
            let columnsSelected = 0
            for (let i = 0; i < Object.keys(customCols).length; i++) {
                if (Object.values(customCols)[i].show) {
                    columnsSelected++
                }
                if (Object.values(customCols)[i].options === "LOV") {
                    props.setHasLov(true)
                }
            }
            setTooMany(columnsSelected > 16)
            setSelected(columnsSelected)
        }
    }, [customCols])

    let customColumnBoxes = []
    if (customCols) {
        for (let i = 0; i < Object.keys(customCols).length; i++) {
            let key = Object.keys(customCols)[i]
            customColumnBoxes.push(<ColumnEditBox key={i} data={customCols} setData={setCustomCols} dataKey={key} valid={valid}/>)
        }
    }

    return (
        <div className={styles.editScreen}>
            <div className={styles.title}>
                <div></div>
                <div className={styles.titleText}>Add Exercise - Customize Columns</div>
                <div className={styles.close} onClick={() => {
                    props.close()
                }}><i className="fas fa-times"></i></div>
            </div>
            <form>
                <div className={styles.body}>
                    <div className={styles.titleRow}>
                        <div className={styles.side}></div>
                        <div className={styles.directions}>This data for new columns has been imported from the table. If you want to add any of these columns, toggle on and edit the Title and select the column functionality.</div>
                        <div className={styles.side}>
                            <div className={styles.customizeColumns}>
                                <div className={[styles.selected, tooMany ? styles.red : ""].join(" ")}>{selected}</div>
                                <div>Columns Selected</div>
                                <div>(Max 16)</div>
                            </div>
                        </div>
                    </div>
                    {tooMany ? <div className={[styles.directions, styles.red].join(" ")}>You have too many columns selected. The maximum is 16.</div> : ""}
                    <div className={styles.content}>
                        {customColumnBoxes}
                    </div>
                    <div className={styles.buttonRow}>
                        <div id={styles.cancel}
                            onClick={() => {
                                props.setCustomColumns(customCols)
                                props.setView(props.view - 1)
                            }
                        }>BACK</div>
                        <div type="submit" id={styles.submit} className={wiggle ? styles.Wiggle : ""} onClick={() => {
                                let temp = true
                                for (let i = 0; i < Object.keys(customCols).length; i++) {
                                    let value = Object.values(customCols)[i]
                                    if (value.show && !value.title) {
                                        temp = false
                                    }
                                }
                                if (temp && !tooMany)  {
                                    props.setCustomColumns(customCols)
                                    props.setView(props.view + 1)
                                }
                                else {
                                    setWiggle(true)
                                    setValid(false)
                                    setTimeout(() => {
                                        setWiggle(false)
                                    }, 500);
                                }
                            }
                        }>CONFIRM</div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default CustomizeColumns
