import React, { Component } from 'react';
import styles from '../css/ActivityRecord.module.css';
import Axios from './../../../globalcomponents/Axios'

class ActivityRecord extends Component {
    constructor(props) {
        super(props)
        this.state = {
            seen: false
        };
    }

    render() {
        return (
            <div className={this.props.odd ? [styles.result, styles.odd].join(' ') : styles.result} >
                <div className={[styles.link, styles.arcontentstart].join(' ')} onClick={() => {
                    this.props.openChangeRequest(this.props.data)
                }}>{this.props.data.ACTION_ID}</div>


                <div className={styles.arcontentsmall}>{this.props.data.RESOLVE_DATE ? this.props.data.RESOLVE_DATE.replace(/[Tt].*/, "") : ""}</div>
                <div className={styles.arcontent}> {this.props.data.REQUEST_TYPE}</div>

            </div>
        );
    }
}

export default ActivityRecord;
