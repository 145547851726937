import React, {useState} from 'react'
import Popup from '../../../../components/Popup'
import Spinner from '../../../../components/Spinner'
import TargetColorBox from './TargetColorBox'
import styles from '../css/EditTarget.module.css'
import arrow from '../../../../assets/purple-arrow.png'

function EditTarget(props) {

    const [reason, setReason] = useState(props.reason || "")
    const [notes, setNotes] = useState(props.notes || "")
    const [error, setError] = useState("")
    const [isValid, setIsValid] = useState(true)
    const [wiggle, setWiggle] = useState(false)

    let optionsList = [<option value=""></option>]
    if (props.options) {
        for (let i = 0; i < props.options.length; i++) {
            optionsList.push(<option value={props.options[i]}>{props.options[i]}</option>)
        }
    }
    //if (optionsList.length == 1) {
    //    optionsList.push(<option value='Deceased'>Deceased</option>)
    //    optionsList.push(<option value='Not Currently Practicing'>Not Currently Practicing</option>)        
    //}

    let name = props.exercise.CUSTOMER_TYPE === "HCP" ? `${props.data.FIRST_NAME}${props.data.MIDDLE_NAME ? " " + props.data.MIDDLE_NAME + " " : " "}${props.data.LAST_NAME}, ${props.data.DEGREE}` : props.data.NAME
    let body = <Spinner />
    if (props.success) {
        body = (
            <div className={"pop-body"}>
                <div className={"pop-required"}>REQUIRED FIELD *</div>
                <div className={"pop-required"}>{error}</div>
                <div className={"pop-titleRow"}>
                    <div className={"pop-side"}></div>
                    <div className={"pop-directions"}>You are downgrading the target type of</div>
                    <div className={"pop-side"}></div>
                </div>
                <div className={"pop-content"}>
                    <div className={styles.name}>{name}</div>
                    <div className={styles.middle}>
                        <div className={styles.setmiddle}>
                            <div className={styles.key}>CURRENT TARGET TYPE</div>
                            <TargetColorBox target={props.data.CURRENT_TARGET_VALUE}/>
                        </div>
                        <img className={styles.arrow} src={arrow} alt={<i class="fas fa-long-arrow-alt-right"></i>}/>
                        <div className={styles.setmiddle}>
                            <div className={styles.key}>CHANGE TARGET TYPE</div>
                            <TargetColorBox target={props.targetType}/>
                        </div>
                    </div>
                    <div className={"flex-col flex-justify-start"}>
                        <div className={styles.set}>
                            <div className={styles.reason}>REASON <div className={"pop-required"}>*</div></div>
                            <select className={isValid ? styles.default : styles.error} value={reason} onChange={(event) => {
                                    setReason(event.target.value)
                            }} disabled={props.lock} style={props.lock ? {backgroundColor: "#d9d5d4", border: "none"} : {}}>
                                {optionsList}
                            </select>
                        </div>
                        <div className={styles.set}>
                            <div>NOTES</div>
                            <textarea className={[styles.notes, styles.default].join(" ")} value={notes} onChange={(event) => {
                                    setNotes(event.target.value)
                            }} disabled={props.lock} style={props.lock ? {backgroundColor: "#d9d5d4", border: "none"} : {}}/>
                        </div>
                    </div>
                    <div className={"pop-buttonRow"}>
                        <button type="button" id={"pop-cancel"} onClick={() => {
                                props.close()
                            }
                        }>CLOSE</button>
                    <button className={wiggle ? "wiggle" : ""} id={"pop-submit"} onClick={() => {
                            if (reason !== "") {
                                props.submit(props.targetType, {
                                    reason: reason,
                                    notes: notes
                                })
                            }
                            else {
                                setWiggle(true)
                                setIsValid(false)
                                setError("PLEASE SELECT A REASON FOR DOWNGRADING")
                                setTimeout(() => {
                                    setWiggle(false)
                                }, 500);
                            }
                        }} disabled={props.lock} style={props.lock ? {backgroundColor: "#d9d5d4", border: "none"} : {}}>SUBMIT</button>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <Popup {...props} body={body} title={"Edit Target"} />
    )

}

export default EditTarget
