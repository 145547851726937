import React, { Component } from "react";
import styles from "../css/Details_Edit_TDDD.module.css";
import DegreeLookUp from "../../../globalcomponents/DegreeLookUp";
import StateLookUp from "./../../../globalcomponents/StateLookUp";
import Axios from "./../../../components/Axios";

class Details_TDDD_Edit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            LICENSE: "",
            EXP: "",
            STATE: "",
            DEGREE: "",
            STATUS: "",
            OVERALL_SAMPLEABILITY: "",
            LAST_VAL_DATE: "",
            ISVALID: false,
            SUBCAT: "",
            FORM_SUBMITTED: false
        };
    }

    render() {

        let stateOptions = []
        for (var i = 0; i < Object.keys(StateLookUp).length; i++) {
            stateOptions.push(<option key={i} value={Object.keys(StateLookUp)[i]}>{Object.values(StateLookUp)[i]}</option>)
        }

        let statusOptions = []
        statusOptions.push(<option key="Active" value="Active">Active</option>)
        statusOptions.push(<option key="Inactive" value="Inactive">Inactive</option>)


        return (
            <div className={styles.editScreen}>
                <div className={styles.wrapper}>
                    <div className={styles.title}>
                        <div></div>
                        <div className={styles.TitleText}>Edit State Licenses</div>
                        <div className={styles.close} onClick={() => {
                            this.props.close();
                        }}><i className="fas fa-times"></i>
                        </div>
                    </div>

                    <form>
                        <div className={styles.body}>
                            <div className={this.state.ISVALID ? styles.DefaultMessage : styles.ErrorMessage}>{this.state.MESSAGE}</div>
                            <div className={styles.toprow}>
                                <div className={styles.set}>
                                    <div className={styles.inputTitle}>LICENSE NUMBER  <div className={styles.required}>*</div></div>
                                    <input className={this.state.ISVALID ? styles.DefaultInputForm : styles.ErrorInputForm} value={this.state.LICENSE !== "" ? this.state.LICENSE : ""} onChange={(event) => {
                                        if (event.target.value === "") {
                                            this.setState({ LICENSE: "" })
                                        }
                                        else {
                                            this.setState({ LICENSE: event.target.value })
                                        }
                                    }} />
                                </div>

                                <div className={styles.set}>
                                    <div className={styles.inputTitle}>EXPIRATION DATE  <div className={styles.required}>*</div></div>
                                    <input type="date" className={this.state.ISVALID ? styles.DefaultInputForm : styles.ErrorInputForm} onChange={(event) => {
                                        if (event.target.value === "") {
                                            this.setState({ EXP: "" })
                                        }
                                        else {
                                            this.setState({ EXP: event.target.value })
                                        }
                                    }} />
                                </div>

                            </div>

                            <div className={styles.middlerow}>
                                <div className={styles.set}>
                                    <div className={styles.inputTitle}>STATE  <div className={styles.required}>*</div></div>
                                    <select className={this.state.ISVALID ? styles.DefaultInputForm : styles.ErrorInputForm} onChange={(event) => {
                                        if (event.target.value === "") {
                                            this.setState({ STATE: "" })
                                        }
                                        else {
                                            this.setState({ STATE: event.target.value })
                                        }
                                    }} value={this.state.STATE}>
                                        <option value=""></option>
                                        <option value="OH">Ohio</option>
                                    </select>
                                </div>


                                <div className={styles.set}>
                                    <div className={styles.inputTitle}>STATUS  <div className={styles.required}>*</div></div>
                                    <select className={this.state.ISVALID ? styles.DefaultInputForm : styles.ErrorInputForm} onChange={(event) => {
                                        if (event.target.value === "") {
                                            this.setState({ STATUS: "" })
                                        }
                                        else {
                                            this.setState({ STATUS: event.target.value })
                                        }
                                    }} value={this.state.STATUS}>
                                        <option value=""></option>
                                        {statusOptions}
                                    </select>
                                </div>



                            </div>
                            <div className={styles.middlerow}>

                                <div className={styles.set}>

                                    <div className={styles.inputTitle}>SUB CATEGORY  <div className={styles.required}>*</div></div>
             


                                    <select type="text" className={this.state.ISVALID ? styles.DefaultInputForm : styles.ErrorInputForm} onChange={(event) => {
                                        if (event.target.value === "") {
                                            this.setState({ SUBCAT: "" })
                                        }
                                        else {
                                            this.setState({ SUBCAT: event.target.value })
                                        }
                                    }} value={this.state.SUBCAT}>
                                        <option value=""></option>
                                        <option value="02">02</option>
                                        <option value="03">03</option>
                                        <option value="12">12</option>
                                        <option value="13">13</option>

                                    </select>
                                </div>



                            </div>
                            <div className={styles.buttonRow}>
                                <div id={styles.cancel} onClick={() => {
                                    this.props.close()
                                }
                                }>CANCEL</div>
                                <div type="submit" id={styles.submit} className={this.state.Wiggle && this.state.Wiggle !== undefined ? styles.Wiggle : ""} onClick={() => {
                                    if (this.state.SUBCAT !== "" && this.state.LICENSE !== "" && this.state.STATE !== "" && this.state.STATUS !== "" && this.state.EXP !== "" && this.state.FORM_SUBMITTED !== true) {
                                        let temp = this.state

                                        this.props.addTDDD(temp)
                                        this.setState({ FORM_SUBMITTED: true })
                                    }
                                    else {
                                        this.setState({
                                            Wiggle: true,
                                            ISVALID: false
                                        })
                                        setTimeout(() => {
                                            this.setState({ Wiggle: false })
                                        }, 500);
                                    }

                                }}>SUBMIT</div>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        );
    }
}

export default Details_TDDD_Edit;
