import React, { Component } from "react";
import styles from "./CallDialogSelection.module.css";


class CallDialogSelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mdm_id: this.props.mdm_id ,
            is_hcp: this.props.is_hcp,
            orgname: this.props.org_name
        };
    }

    firstCapital(str) {
        if (str !== undefined) {
            return str.toLowerCase().replace(/\w\S*/g, function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
        }
        return "";
    }

   

    render() {


        return (
            <div className={styles.editScreen}>
                <div className={styles.wrapper}>
                    <div className={styles.title}>
                        <div></div>
                        <div className={styles.TitleText}>FPM Action</div>
                        <div
                            className={styles.close}
                            onClick={() => {
                                this.props.close();
                            }}
                        >
                            <i className="fas fa-times"></i>
                        </div>
                    </div>


                    <div className={styles.body}>
               {/*         <div className={styles.menuTitle}>What would you like to do?</div>*/}
                        <div className={styles.errorMessage}>{this.state.errorMessage}</div>
                        <div className={styles.menuButton}>
                            <div
                                id={styles.submit}
                                onClick={() => {
                                    this.props.openCallForm(this.state.mdm_id, this.state.is_hcp, this.state.orgname);
                                }}
                            >
                                CALL FORM
                            </div>
                            <div
                                id={styles.submit}
                                onClick={() => {
                                    this.props.openPharmForm(this.state.mdm_id, this.state.is_hcp, this.state.orgname);
                                }}
                            >
                                PHARMACY ACTION
                            </div>

                        </div>
                    </div>


                </div>
            </div>
        );
    }
}

export default CallDialogSelection;
