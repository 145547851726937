import React from 'react'
import styles from '../css/LovEditBox.module.css'

function LovEditBox(props) {
    return (
        <div className={styles.columnEditBox}>
            <div className={styles.set}>
                <div className={styles.inputTitle}>TITLE <div className={styles.required}>*</div></div>
                <input type="text" className={props.valid || props.data.title !== "" ? styles.default : styles.error} value={props.data.title} onChange={(event) => {
                        props.setData({
                            ...props.data,
                            title: event.target.value
                        })
                    }} autoFocus disabled={props.data.disabled}/>
            </div>
            <div className={styles.set}>
                <div className={styles.inputTitle}>CSV FILE <div className={styles.required}>*</div></div>
                <input type="file" className={props.valid || props.data.LOV !== undefined ? styles.default : styles.error} accept=".csv" onChange={(event) => {
                        let fr = new FileReader()
                        fr.readAsText(event.target.files[0])
                        fr.onload = (event) => {
                            let csv = event.target.result
                            let array = csv.split(/\r\n|\n/)
                            props.setData({
                                ...props.data,
                                LOV: array
                            })
                        }
                    }}/>
            </div>
        </div>
    )
}

export default LovEditBox
