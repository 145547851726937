import React, { useState } from 'react'
import Styles from './WorkflowApprovalDialog.module.css';


export default function CancelationConfirmationDialog(props) {

    const [id, setID] = useState(props.id)

  return (
    <div className={Styles.editScreen}>
      <div className={Styles.title}>
        <div></div>
              <div className={Styles.TitleText}>Cancel Event</div>
        <div
          className={Styles.close}>
        </div>
      </div>
      <div className={Styles.body}>
       
        <div className={Styles.firstLine}>

                  Do you wish to CANCEL this event? <br /><br />
                  <b>ORG NAME:</b> {props.orgName}<br /><br />
                  <b>PROGRAM:</b> {props.programName}<br /><br />
               
                 
        </div>
          </div>
          <div className={Styles.buttonRow}>
              <div id={Styles.submit} onClick={() => {
                  props.cancelCancelation();
              }}>DO NOT CANCEL</div>

         
              <div id={Styles.submit} onClick={() => {
                  props.approveCancelation(id);
              }}>CANCEL EVENT</div>
          
          </div>
    </div>
  );
}
