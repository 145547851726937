import React, {useState} from 'react'
import Export from '../Export'
import styles from './TopBar.module.css'

function ExportButton(props) {
    const [exportData, setExportData] = useState(false)
    let button = ""
    if (props.export) {
        button = (
            <div>
                <div id={styles.exportButton} onClick={() => {
                    setExportData(true)
                }}>
                    <i className="fas fa-download"></i>
                </div>
                {exportData ? <Export {...props} close={() => {setExportData(false)}}></Export> : ''}
            </div>
        )
    }
    return button
}

export default ExportButton