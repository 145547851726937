import React, { useState } from 'react'
import styles from '../css/AddExercise.module.css'

function AddExercise(props) {

    const [name, setName] = useState(props.exercise.name || "")
    const [startDate, setStartDate] = useState(props.exercise.startDate || "")
    const [endDate, setEndDate] = useState(props.exercise.endDate || "")

    const [wiggle, setWiggle] = useState(false)
    const [valid, setValid] = useState(true)

    return (
        <div className={styles.editScreen}>
            <div className={styles.title}>
                <div></div>
                <div className={styles.titleText}>Add Exercise</div>
                <div className={styles.close} onClick={() => {
                    props.close()
                }}><i className="fas fa-times"></i></div>
            </div>
            <form>
                <div className={styles.body}>
                    <div className={styles.required}>REQUIRED FIELD *</div>
                    <div className={styles.content}>
                        <div className={styles.set}>
                            <div className={styles.inputTitle}>NAME <div className={styles.required}>*</div></div>
                            <input type="text" className={valid || name !== "" ? styles.default : styles.error} value={name} onChange={(event) => {
                                setName(event.target.value)
                            }} autoFocus />
                        </div>
                        <div className={styles.set}>
                            <div className={styles.inputTitle}>TEAM <div className={styles.required}>*</div></div>
                            <select type="text" className={valid || props.team !== "" ? styles.default : styles.error} value={props.team} onChange={(event) => {
                                props.setTeam(event.target.value)
                            }}>
                                <option value=""></option>
                                <option value="11">11</option>
                                <option value="21">21</option>
                                <option value="31">31</option>
                                <option value="32">32</option>
                                <option value="61">61</option>
                            </select>
                        </div>
                        <div className={styles.set}>
                            <div className={styles.inputTitle}>START DATE <div className={styles.required}>*</div></div>
                            <input type="date" max={endDate || undefined} className={valid || startDate !== "" ? styles.default : styles.error} value={startDate} onChange={(event) => {
                                setStartDate(event.target.value)
                            }} />
                        </div>
                        <div className={styles.set}>
                            <div className={styles.inputTitle}>END DATE <div className={styles.required}>*</div></div>
                            <input type="date" min={startDate || undefined} className={valid || endDate !== "" ? styles.default : styles.error} value={endDate} onChange={(event) => {
                                setEndDate(event.target.value)
                            }} />
                        </div>
                       
                    </div>
                    <div className={styles.buttonRow}>
                        <div id={styles.cancel}
                            onClick={() => {
                                props.close()
                            }
                            }>CANCEL</div>
                        <div type="submit" id={styles.submit} className={wiggle ? styles.Wiggle : ""} onClick={() => {
                            if (name !== "" && props.team !== "" && startDate !== "" && endDate !== "") {
                                props.setExercise({
                                    ...props.exercise,
                                    name: name,
                                    team: props.team,
                                    startDate: startDate,
                                    endDate: endDate
                                })
                                props.setView(props.view + 1)
                            }
                            else {
                                setWiggle(true)
                                setValid(false)
                                setTimeout(() => {
                                    setWiggle(false)
                                }, 500);
                            }
                        }
                        }>SUBMIT</div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default AddExercise
