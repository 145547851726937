import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import styles from '../css/Result.module.css';
import Toggle from '../../../globalcomponents/Toggle'

class Result extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: props.data,
            odd: (
                props.number % 2 === 0
                ? true
                : false)
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data !== this.props.data) {
            this.setState({data: this.props.data});
        }
    }

    TogCheck(tog, nonTog) {
        if (parseInt(this.props.data.TOG_OWNER) === parseInt(this.props.id) || parseInt(this.props.data.REGIONAL_PLAN_OWNER) === parseInt(this.props.id)) {
            if (tog !== null) {
                return tog
            } else {
                if (nonTog === null) {
                    return "-"
                } else {
                    return nonTog
                }
            }
        } else {
            if (nonTog === null) {
                return "-"
            } else {
                return nonTog
            }
        }
    }

    render() {
        let tog = false
        let goal1 = (this.props.data.Goal1 === 'Y'? 1: null)
        let goal2 = (this.props.data.Goal2 === 'Y'? 2: null)
        let goal3 = (this.props.data.Goal3 === 'Y'? 3: null)
        let goal4 = (this.props.data.Goal4 === 'Y' ? 4 : null)
        let goal5 = (this.props.data.Goal4 === 'Y' ? 5 : null)
        let goal6 = (this.props.data.Goal4 === 'Y' ? 6 : null)
        let goal7 = (this.props.data.Goal4 === 'Y' ? 7 : null)

        if (this.props.data.TOG_OWNER === null) {
            if (parseInt(this.props.data.REGIONAL_PLAN_OWNER) === parseInt(this.props.id)) {
                if (this.props.data.REGIONAL_PLAN_CURR === 'Y') {
                    tog = true
                }
                if (this.props.data.TOG_GOAL1 !== null) {
                    goal1 = (this.props.data.TOG_GOAL1 === 'Y'? 1: null)
                }
                if (this.props.data.TOG_GOAL2 !== null) {
                    goal2 = (this.props.data.TOG_GOAL2 === 'Y'? 2: null)
                }
                if (this.props.data.TOG_GOAL3 !== null) {
                    goal3 = (this.props.data.TOG_GOAL3 === 'Y'? 3: null)
                }
                if (this.props.data.TOG_GOAL4 !== null) {
                    goal4 = (this.props.data.TOG_GOAL4 === 'Y'? 4: null)
                }
                if (this.props.data.TOG_GOAL5 !== null) {
                    goal4 = (this.props.data.TOG_GOAL5 === 'Y' ? 5 : null)
                }
                if (this.props.data.TOG_GOAL6 !== null) {
                    goal4 = (this.props.data.TOG_GOAL6 === 'Y' ? 6 : null)
                }
                if (this.props.data.TOG_GOAL7 !== null) {
                    goal4 = (this.props.data.TOG_GOAL7 === 'Y' ? 7 : null)
                }
            }
        } else {
            if (parseInt(this.props.data.TOG_OWNER) === parseInt(this.props.id)) {
                if (this.props.data.TOG === 'Y') {
                    tog = true
                }
                if (this.props.data.TOG_GOAL1 !== null) {
                    goal1 = (this.props.data.TOG_GOAL1 === 'Y'? 1: null)
                }
                if (this.props.data.TOG_GOAL2 !== null) {
                    goal2 = (this.props.data.TOG_GOAL2 === 'Y'? 2: null)
                }
                if (this.props.data.TOG_GOAL3 !== null) {
                    goal3 = (this.props.data.TOG_GOAL3 === 'Y'? 3: null)
                }
                if (this.props.data.TOG_GOAL4 !== null) {
                    goal4 = (this.props.data.TOG_GOAL4 === 'Y'? 4: null)
                }
                if (this.props.data.TOG_GOAL4 !== null) {
                    goal5 = (this.props.data.TOG_GOAL5 === 'Y' ? 5 : null)
                }
                if (this.props.data.TOG_GOAL4 !== null) {
                    goal6 = (this.props.data.TOG_GOAL6 === 'Y' ? 6 : null)
                }
                if (this.props.data.TOG_GOAL4 !== null) {
                    goal7 = (this.props.data.TOG_GOAL7 === 'Y' ? 7 : null)
                }
            }
        }
        let glob = false //if the result is on someones toggle but not yours
         if (tog === false) {
            if (this.props.data.REGIONAL_PLAN_CURR === 'Y' || this.props.data.TOG === 'Y') {
                if (parseInt(this.props.data.REGIONAL_PLAN_OWNER) !== parseInt(this.props.id) && parseInt(this.props.data.TOG_OWNER) !== parseInt(this.props.id)) {
                    glob = true
                }
            }
        }
        let allgoal = [goal1, goal2, goal3, goal4, goal5, goal6, goal7]
        let goals = []
        for (let i = 0; i < 7; i++) {
            if (allgoal[i] !== null) {
                goals.push(allgoal[i])
            }
        }
        let allowMSL = true
        if (this.props.user.role == "MSL" && this.props.statusid === 3) {
           // tog = false
          //  glob = false
            allowMSL = false
        }
        return (<div className={this.state.odd
                ? [styles.results, styles.odd].join(' ')
                : styles.results}>
            <div className={[styles.Info, styles.result, styles.long].join(' ')}>
                <Link className={styles.Name} to={`/hcp/details/${this.props.data.PROF_ID}`} target="_blank">
                    <div>{
                        `${this.props.data.FIRST_NAME !== null
                            ? this.props.data.FIRST_NAME 
                            : ""}${this.props.data.MIDDLE_NAME
                                ? " " + this.props.data.MIDDLE_NAME
                                : ""} ${this.props.data.LAST_NAME !== null
                                    ? this.props.data.LAST_NAME
                                    : ""}${this.props.data.DEGREE !== null
                                        ? ", " + this.props.data.DEGREE
                                        : ""}`
                    }</div>
                </Link>
                <div className={styles.Address}>{
                        `${this.props.data.ADDRESS1 !== null
                            ? this.props.data.ADDRESS1
                            : ""} ${this.props.data.ADDRESS2 !== null
                                ? this.props.data.ADDRESS2
                                : ""}, ${this.props.data.CITY !== null
                                    ? this.props.data.CITY
                                    : ""}, ${this.props.data.STATE !== null
                                        ? this.props.data.STATE
                                        : ""} ${this.props.data.ZIP5 !== null
                                            ? this.props.data.ZIP5
                                            : ""}`
                    }</div>
            </div>


            <div className={this.props.warning.includes(this.props.number)
                    ? [styles.warning, styles.result, styles.short, styles.toggle].join(' ')
                    : [styles.result, styles.short, styles.toggle].join(' ')}>

                    <Toggle active={glob || tog} click={this.props.addTo} global={glob} number={this.props.number}/>
                    {
                        tog
                            ? <div className={styles.edit} onClick={() => {
                            if ((this.props.statusid === 1 || this.props.statusid === 3 || this.props.statusid === 2 || this.props.statusid === 4) && allowMSL) {
                                            this.props.showEdit(this.props.data, this.props.number)
                                        }
                        }}>

                                    <i className="far fa-edit"></i>
                                </div>
                            : this.props.warning.includes(this.props.number)
                                ? "* USER IS ALREADY IN ANOTHER REGIONAL PLAN"
                                : ""
                    }

            </div>
            <div className={[styles.result, styles.short].join(' ')}>{this.TogCheck(this.props.data.TOG_FOCUS_AREA_PRIMARY, this.props.data.FOCUS_AREA_PRIMARY)}</div>
            <div className={[styles.result, styles.short].join(' ')}>{this.TogCheck(this.props.data.TOG_FOCUS_AREA_PRIMARY_TIER, this.props.data.FOCUS_AREA_PRIMARY_TIER)}</div>
            <div className={[styles.result, styles.short].join(' ')}>{goals.join(', ')}</div>
            <div className={[styles.result, styles.short].join(' ')}>{this.TogCheck(this.props.data.TOG_FOCUS_AREA_SECONDARY, this.props.data.FOCUS_AREA_SECONDARY)}</div>
            <div className={[styles.result, styles.short].join(' ')}>{this.TogCheck(this.props.data.TOG_FOCUS_AREA_SECONDARY_TIER, this.props.data.FOCUS_AREA_SECONDARY_TIER)}</div>
            <div className={[styles.result, styles.short].join(' ')}>{this.TogCheck(this.props.data.TOG_FOCUS_AREA_TERTIARY, this.props.data.FOCUS_AREA_TERTIARY)}</div>
            <div className={[styles.result, styles.short].join(' ')}>{this.TogCheck(this.props.data.TOG_FOCUS_AREA_TERTIARY_TIER, this.props.data.FOCUS_AREA_TERTIARY_TIER)}</div>
        </div>);
    }
}

export default Result;
