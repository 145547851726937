import React, {Component} from 'react';
import styles from '../css/Details_Edit_AffiliationsConfirmation.module.css';

class Details_Edit_AffiliationsConfirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className={styles.editScreen}>
                <div className={styles.title}>
                    <div></div>
                    <div className={styles.TitleText}>Change Affiliations</div>
                    <div className={styles.close} onClick={() => {
                        this.props.close();
                    }}><i className="fas fa-times"></i></div>
                </div>
                <div className={styles.body}>
                    <div id={styles.SuccessIcon}>
                        <i className="far fa-check-circle"></i>
                    </div>
                    <div className={styles.TextBox}>
                        Affiliations have been updated!
                    </div>
                </div>
            </div>
        )
    }
}

export default Details_Edit_AffiliationsConfirmation;
