import React, { Component } from 'react';
import ADDEXUSP1 from './AddExUs_P1';
import ADDEXUSP2 from './AddExUs_P2';
import ADDEXUSP3 from './AddExUs_P3';
import Popup from '../../../globalcomponents/Popup';
import AddExUsSuccess from './AddExUsSuccess';


class AddExUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: [],
            activePage: 0,
            info: {},
            loaded: false
        };
    }

    componentDidMount() {
        this.setState({
            page: [
                <ADDEXUSP1 user={this.props.user} info={this.state.info} nextPage={this.nextPage.bind(this)} close={this.props.close} />,
                <ADDEXUSP2 user={this.props.user} info={this.state.info} nextPage={this.nextPage.bind(this)} close={this.props.close} />,
                <ADDEXUSP3 user={this.props.user} info={this.state.info} nextPage={this.nextPage.bind(this)} close={this.props.close} />,
                <AddExUsSuccess close={this.props.close} />,
            ],
            loaded: true
        })
    }

    nextPage(i, info) {
        if (info !== undefined) {
            let temp = this.state.info
            Object.assign(temp, info)
            this.setState({
                activePage: i,
                info: temp
            });
        }
        else {
            this.setState({
                activePage: i
            });
        }
    }

    render() {
        return (
            <div>
                {this.state.loaded > 0 ? <Popup component={this.state.page[this.state.activePage]} /> : ""}
            </div>
        )
    }
}

export default AddExUs;
