import React from 'react'
import styles from './Table.module.css'

function Table(props) {
    return (
        <table className={[styles.table].join(" ")}>
            <thead>
                {props.children[0]}
            </thead>
            <tbody className={styles.results}>
                {props.children[1]}
            </tbody>
        </table>
    )
}

export default Table