import React, {Component} from 'react';
import {Link} from 'react-router-dom'
import styles from '../css/Preview.module.css';
import SpecialtyLookUp from '../../../globalcomponents/SpecialtyLookUp';
import Axios from './../../../components/Axios'
import firstCapital from '../../../helper/firstCapital';

class Preview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false
        };
    }

    componentDidMount() {
        var teamID = this.props.user.role == 'PRT' || this.props.user.role == 'COMMERCIAL DERM' || this.props.user.isDermAdmin == 'true' ? '31' : this.props.user.team;
        
        Axios(`/api/details${this.props.type}/preview`, (
            this.props.type === '/hcp'
            ? {
                    PROF_ID: this.props.data.PROF_ID,
                    TEAM: this.props.user.team
            }
            : {
                ORG_ID: this.props.data.ORG_ID,
                    TEAM: teamID
            }), (data) => {
            if (this.props.type === '/hcp') {
                this.setState({secondaryAddresses: data, loaded: true})
            } else {
                this.setState({affiliations: data, loaded: true})
            }
        })

        if (this.props.type === '/hcp') {
            Axios(`/api/details/hcp/affiliations-preview`, ({
                    PROF_ID: this.props.data.PROF_ID,
                TEAM: this.props.user.team
            }), (data) => {
                this.setState({affiliations: data, loaded: true})
            })
            if (this.props.user.role.includes('DEVELOPMENT OPERATIONS') && this.props.user.role.includes('CTMS')) {

                Axios(`/api/details/hcp/preview-ctms`, ({
                    PROF_ID: this.props.data.PROF_ID
                }), (data) => {
                    this.setState({ ctmsAddress: data})
                })


            }
           
        }
        
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data !== this.props.data) {
            Axios(`/api/details${this.props.type}/preview`, (
                this.props.type === '/hcp'
                ? {
                        PROF_ID: this.props.data.PROF_ID,
                        TEAM: this.props.user.team
                }
                : {
                    ORG_ID: this.props.data.ORG_ID,
                    TEAM: this.props.user.team
                }), (data) => {
                if (this.props.type === '/hcp') {
                    this.setState({secondaryAddresses: data, loaded: true})
                } else {
                    this.setState({affiliations: data, loaded: true})
                }
            })
            if (this.props.type === '/hcp') {
                Axios(`/api/details/hcp/affiliations-preview`, ({
                        PROF_ID: this.props.data.PROF_ID,
                        TEAM: this.props.user.team
                }), (data) => {
                    this.setState({affiliations: data, loaded: true})
                })
            }
        }
    }

    status(key) {
        if (this.props.data[key] === null) {
            return 'Active'
        } else if (this.props.data[key] === 'DEAD') {
            return 'Deceased'
        } else if (this.props.data[key] === 'RETIRED') {
            return 'Retired'
        } else {
            return 'Inactive'
        }
    }

    window() {
        let ret = ''
        if (this.props.type === '/hcp') {
            let nonSPHASE = ""
            if (!["SPHASE"].includes(this.props.role) || !["PHARMFORCE"].includes(this.props.role)) {
                nonSPHASE = (<div className={styles.SectionRow}>
                    <div className={styles.ContentWrap}>
                        <div className={styles.ContentTitle}>PDRP OPT OUT</div>
                        <div className={styles.Content}>{
                            (this.props.data.PDRP_OPTOUT !== null && this.props.data.PDRP_OPTOUT !== undefined)
                                ? this.props.data.PDRP_OPTOUT
                                    : "-"
                            }</div>
                    </div>
                    <div className={styles.ContentWrap}>
                        <div className={styles.ContentTitle}>NO CONTACT</div>
                        <div className={styles.Content}>{
                            (this.props.data.NO_CONTACT !== null && this.props.data.NO_CONTACT !== undefined)
                                ? this.props.data.NO_CONTACT
                                    : "-"
                            }</div>
                    </div>
                </div>)
            }

            let add1 = '';
            let add2 = '';
            let city = 'z';
            let state = 'x';
            let zip = '111';

            if (this.props.user.role.includes('DEVELOPMENT OPERATIONS') && this.props.user.role.includes('CTMS')) {

                if (this.state.ctmsAddress !== undefined) {

                    add1 = (<div className={[styles.ContentWrap, styles.Address].join(' ')}>
                        <div className={styles.ContentTitle}>PRIMARY ADDRESS</div>
                        <div className={styles.Content}>{`${firstCapital(this.state.ctmsAddress.CTMS_ADDRESS1)} ${firstCapital(this.state.ctmsAddress.CTMS_ADDRESS2)}`}</div>
                        <div className={styles.Content}>{`${firstCapital(this.state.ctmsAddress.CTMS_CITY)}, ${this.state.ctmsAddress.CTMS_STATE} ${this.state.ctmsAddress.CTMS_ZIP5}`}</div>
                    </div>)

                } else {

                    add1 = (<div className={[styles.ContentWrap, styles.Address].join(' ')}>
                        <div className={styles.ContentTitle}>PRIMARY ADDRESS</div>
                        <div className={styles.Content}>{`${firstCapital(this.props.data.PRI_ADDRESS1)} ${firstCapital(this.props.data.PRI_ADDRESS2)}`}</div>
                        <div className={styles.Content}>{`${firstCapital(this.props.data.PRI_CITY)}, ${this.props.data.PRI_STATE} ${this.props.data.PRI_ZIP5}`}</div>
                    </div>)
                }
              

            } else {

                add1 = (<div className={[styles.ContentWrap, styles.Address].join(' ')}>
                    <div className={styles.ContentTitle}>PRIMARY ADDRESS</div>
                    <div className={styles.Content}>{`${firstCapital(this.props.data.PRI_ADDRESS1)} ${firstCapital(this.props.data.PRI_ADDRESS2)}`}</div>
                    <div className={styles.Content}>{`${firstCapital(this.props.data.PRI_CITY)}, ${this.props.data.PRI_STATE} ${this.props.data.PRI_ZIP5}`}</div>
                </div>)

            }



            let secondaryAddresses = []
            if (this.state.secondaryAddresses !== undefined) {

                if (this.state.secondaryAddresses.length > 0) {

                    for (let i = 0; i < this.state.secondaryAddresses.length; i++) {
                        secondaryAddresses.push(<div className={i % 2 === 0
                            ? [styles.even, styles.Content, styles.resultWrapper].join(' ')
                            : [styles.odd, styles.Content, styles.resultWrapper].join(' ')} key={i}>
                            <div>{`${firstCapital(this.state.secondaryAddresses[i].SECONDARY_ADDRESS1)}${firstCapital(this.state.secondaryAddresses[i].SECONDARY_ADDRESS2)
                                ? ' ' + firstCapital(this.state.secondaryAddresses[i].SECONDARY_ADDRESS2)
                                : ''},`}</div>
                            <div>{`${firstCapital(this.state.secondaryAddresses[i].SECONDARY_CITY)}, ${this.state.secondaryAddresses[i].SECONDARY_STATE} ${this.state.secondaryAddresses[i].SECONDARY_ZIP5}`}</div>
                        </div>)
                    }
                } else {
                    if (this.state.secondaryAddresses.SECONDARY_ADDRESS1 !== undefined) {
                        secondaryAddresses.push(<div className={1 % 2 === 0
                            ? [styles.even, styles.Content, styles.resultWrapper].join(' ')
                            : [styles.odd, styles.Content, styles.resultWrapper].join(' ')} key={1}>
                            <div>{`${firstCapital(this.state.secondaryAddresses.SECONDARY_ADDRESS1)}${firstCapital(this.state.secondaryAddresses.SECONDARY_ADDRESS2)
                                ? ' ' + firstCapital(this.state.secondaryAddresses.SECONDARY_ADDRESS2)
                                : ''},`}</div>
                            <div>{`${firstCapital(this.state.secondaryAddresses.SECONDARY_CITY)}, ${this.state.secondaryAddresses.SECONDARY_STATE} ${this.state.secondaryAddresses.SECONDARY_ZIP5}`}</div>
                        </div>)
                    } else {
                        secondaryAddresses = ' - '
                    }
                }

            } else {
                secondaryAddresses = ' - '
            }

            let affiliations = []
            if (this.state.affiliations !== undefined && this.state.affiliations !== null && this.state.affiliations.length > 0) {
                for (let i = 0; i < this.state.affiliations.length; i++) {
                    affiliations.push(<div className={i % 2 === 0
                            ? [styles.even, styles.Content, styles.resultWrapper].join(' ')
                            : [styles.odd, styles.Content, styles.resultWrapper].join(' ')} key={i}>
                        <div>{firstCapital(this.state.affiliations[i].ORG_NAME)}</div>
                    </div>)
                }
            } else {
                affiliations = ' - '
            }
            ret = (<div className={styles.window}>
                <div className={styles.Name}>{`${this.props.data.FIRST_NAME} ${this.props.data.LAST_NAME}, ${this.props.data.DEGREE}`}</div>
                <div className={styles.Status}>
                    {
                        this.props.data.STATUS === null
                            ? <i className="fas fa-check-circle" id={styles.StatusActive}></i>
                            : <i className="fas fa-times-circle" id={styles.StatusInactive}></i>
                    }
                    <div className={styles.Content}>{this.status("STATUS")}</div>
                </div>
                <div className={styles.scroll}>
                    <div className={styles.SectionColumn}>


                        { add1 }


                        <div className={[styles.ContentWrap, styles.full].join(' ')}>
                            <div className={styles.ContentTitle}>SECONDARY ADDRESS</div>
                            {
                                this.state.loaded
                                    ? <div className={styles.resultWrapper}>
                                        {secondaryAddresses}
                                    </div>
                                    : <div className={styles.loading}>
                                            <i className="fas fa-spinner fa-pulse"></i>
                                        </div>
                            }
                        </div>
                    </div>
                    <div className={styles.Bar}></div>
                        <div className={styles.SectionColumn}>
                            <div className={[styles.ContentWrap, styles.Address].join(' ')}>
                                <div className={styles.ContentTitle}>AFFILIATIONS</div>
                                {
                                    this.state.loaded
                                        ? <div className={styles.resultWrapper}>
                                            {affiliations}
                                        </div>
                                        : <div className={styles.loading}>
                                                <i className="fas fa-spinner fa-pulse"></i>
                                            </div>
                                }
                            </div>
                        </div>
                    <div className={styles.Bar}></div>
                    <div className={styles.SectionColumn}>
                        <div className={styles.ContentWrap}>
                            <div className={styles.ContentTitle}>SPECIALTY</div>
                            <div className={styles.Content}>{
                                    this.props.data.SPECIALTY
                                        ? firstCapital(SpecialtyLookUp[this.props.data.SPECIALTY])
                                        : "None"
                                }</div>
                        </div>
                        <div className={styles.ContentWrap}>
                            <div className={styles.ContentTitle}>SECONDARY SPECIALTY</div>
                            <div className={styles.Content}>{
                                    this.props.data.OTHER_SPECIALTY
                                        ? firstCapital(SpecialtyLookUp[this.props.data.OTHER_SPECIALTY])
                                        : "None"
                                }</div>
                        </div>
                    </div>
                    <div className={styles.Bar}></div>
                    <div className={styles.SectionRow}>
                        <div className={styles.ContentWrap}>
                            <div className={styles.ContentTitle}>PROF ID</div>
                            <div className={styles.Content}>{this.props.data.PROF_ID}</div>
                        </div>
                        <div className={styles.ContentWrap}>
                            <div className={styles.ContentTitle}>NPI</div>
                            <div className={styles.Content}>{
                                    this.props.data.NPINUM
                                        ? this.props.data.NPINUM
                                        : "None"
                                }</div>
                        </div>
                    </div>
                    <div className={styles.Bar}></div>
                    <div className={styles.SectionRow}>
                        {/*<div className={styles.ContentWrap}>*/}
                        {/*    <div className={styles.ContentTitle}>BIRTH YEAR</div>*/}
                        {/*    <div className={styles.Content}>{*/}
                        {/*            this.props.data.BIRTHYEAR*/}
                        {/*                ? this.props.data.BIRTHYEAR*/}
                        {/*                : "None"*/}
                        {/*        }</div>*/}
                        {/*</div>*/}
                        <div className={styles.ContentWrap}>
                            <div className={styles.ContentTitle}>GENDER</div>
                            <div className={styles.Content}>{this.props.data.GENDER}</div>
                        </div>
                    </div>
                    <div className={styles.Bar}></div>
                    {nonSPHASE}
                    {
                        nonSPHASE !== ""
                            ? <div className={styles.Bar}></div>
                            : ""
                    }
                </div>
            </div>)
        } else {
            let affiliations = []
            if (this.state.affiliations !== undefined && this.state.affiliations !== null) {
                for (let i = 0; i < this.state.affiliations.length; i++) {
                    affiliations.push(<div className={i % 2 === 0
                            ? [styles.even, styles.Content, styles.resultWrapper].join(' ')
                            : [styles.odd, styles.Content, styles.resultWrapper].join(' ')} key={i}>
                        <div>{`${this.state.affiliations[i].FIRST_NAME} ${this.state.affiliations[i].MIDDLE_NAME !== null ? this.state.affiliations[i].MIDDLE_NAME + " " : ""}${this.state.affiliations[i].LAST_NAME}`}</div>
                    </div>)
                }
            } else {
                affiliations = ' - '
            }
            ret = (<div className={styles.window}>
                <div className={styles.Name}>{
                        ["MSL"].includes(this.props.user.role)
                            ? this.props.data.CTMS_NAME
                            : this.props.data.PREFERRED_NAME
                    }</div>
                <div className={styles.Status}>
                    {
                        this.props.data.ORG_STATUS === null
                            ? <i className="fas fa-check-circle" id={styles.StatusActive}></i>
                            : <i className="fas fa-times-circle" id={styles.StatusInactive}></i>
                    }
                    <div className={styles.Content}>{this.status("ORG_STATUS")}</div>
                </div>
                <div className={styles.scroll}>
                    <div className={styles.SectionColumn}>
                        <div className={[styles.ContentWrap, styles.full].join(' ')}>
                            <div className={styles.ContentTitle}>PRIMARY ADDRESS</div>
                            <div className={styles.Content}>{`${firstCapital(this.props.data.ADDRESS1)} ${firstCapital(this.props.data.ADDRESS2)}`}</div>
                            <div className={styles.Content}>{`${firstCapital(this.props.data.CITY)}, ${this.props.data.STATE} ${this.props.data.ZIP5}`}</div>
                        </div>
                    </div>
                    <div className={styles.Bar}></div>
                    <div className={styles.SectionColumn}>
                        <div className={styles.ContentWrap}>
                            <div className={styles.ContentTitle}>PREFERRED NAME</div>
                            <div className={styles.Content}>{
                                    ["MSL"].includes(this.props.user.role)
                                        ? this.props.data.CTMS_NAME
                                        : this.props.data.PREFERRED_NAME
                                }</div>
                        </div>
                    </div>
                    <div className={styles.Bar}></div>
                    <div className={styles.SectionColumn}>
                        <div className={styles.ContentWrap}>
                            <div className={styles.ContentTitle}>ORG TYPE</div>
                            <div className={styles.Content}>{
                                    this.props.data.ORG_TYPE
                                        ? firstCapital(this.props.data.ORG_TYPE)
                                        : "None"
                                }</div>
                        </div>
                    </div>
                    <div className={styles.Bar}></div>
                    <div className={styles.SectionRow}>
                        <div className={styles.ContentWrap}>
                            <div className={styles.ContentTitle}>ORG ID</div>
                            <div className={styles.Content}>{this.props.data.ORG_ID}</div>
                        </div>
                        <div className={styles.ContentWrap}>
                            <div className={styles.ContentTitle}>NPI</div>
                            <div className={styles.Content}>{
                                    this.props.data.NPINUM
                                        ? this.props.data.NPINUM
                                        : "None"
                                }</div>
                        </div>
                        <div className={styles.ContentWrap}>
                            <div className={styles.ContentTitle}>TIN</div>
                            <div className={styles.Content}>{
                                    this.props.data.TIN
                                        ? this.props.data.TIN
                                        : "None"
                                }</div>
                        </div>
                    </div>
                    <div className={styles.Bar}></div>
                    <div className={styles.SectionRow}>
                        <div className={[styles.ContentWrap, styles.full].join(' ')}>
                            <div className={styles.ContentTitle}>AFFILIATIONS</div>
                                {
                                    this.state.loaded
                                        ? <div className={styles.resultWrapper}>
                                            {affiliations}
                                        </div>
                                        : <div className={styles.loading}>
                                                <i className="fas fa-spinner fa-pulse"></i>
                                            </div>
                                }
                        </div>
                    </div>
                    <div className={styles.Bar}></div>
                </div>
            </div>)
        }
        return ret
    }

    render() {
        return (<div className={styles.Preview}>
            <div className={styles.Close}>
                <div className={styles.CloseButton} onClick={() => {
                        this.props.close()
                    }}>CLOSE PREVIEW<i className="fas fa-times"></i>
                </div>
            </div>
            {this.window()}
            <div className={[styles.ContentWrap, styles.ViewButton].join(' ')}>
                <Link to={{
                        pathname: `${this.props.type}/details/${this.props.type === '/hcp' ? this.props.data.PROF_ID : this.props.data.ORG_ID}`,
                        state: {
                            from: this.props.type
                        }
                    }}>
                    <button className={styles.ViewDetails}>VIEW DETAILS</button>
                </Link>
            </div>
        </div>)
    }
}

export default Preview;
