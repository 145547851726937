import React, { Component } from "react";
import styles from "../css/Result.module.css";
import Toggle from "../../../globalcomponents/Toggle";
import { Link } from "react-router-dom";
import firstCapital from "../../../helper/firstCapital";
import YesNoDialog from './../../../globalcomponents/YesNoDialogBox';
import SpecialtyLookUp from '../../../globalcomponents/SpecialtyLookUp';

class OneKeyResult extends Component {
    constructor(props) {
        super(props);

    }

    render() {

        let middleName = this.props.data.MIDDLE_NAME == null ? '' : this.props.data.MIDDLE_NAME;
        let name = this.props.data.FIRST_NAME + ' ' + middleName + ' ' + this.props.data.LAST_NAME;

        let address1 = this.props.data.ADDRESS1 !== null && this.props.data.ADDRESS1 !== undefined ? `${firstCapital(this.props.data.ADDRESS1)}, ` : "";
        let address2 = "";
        let city = this.props.data.CITY;
        let state = this.props.data.STATE;
        let zip = this.props.data.POSTAL_CODE;
        let specialty = this.props.data.SPECIALTY;
        let isValid = this.props.data.IS_VALID;
        let _key = this.props.rownumber;
        let degree = this.props.data.DEGREE;
        let specialtyText = specialty == 'UNK' ? '' : specialty;

        for (let i = 0; i < Object.keys(SpecialtyLookUp).length; i++) {

            if (Object.keys(SpecialtyLookUp)[i] == specialty) {
                specialtyText = Object.values(SpecialtyLookUp)[i];
            }
           // OtherSpecialtyOptions.push(<option key={i} value={Object.keys(SpecialtyLookUp)[i]}>{Object.keys(SpecialtyLookUp)[i]} - {Object.values(SpecialtyLookUp)[i]}</option>)
        }

        return (
            <div>
                <div className={this.props.odd === true ?  styles.OddOneKey: styles.Result}>
                    <div className={styles.Status}>
                        <input type="radio" name="selectedrecord" id="selectedrecord" value="{_key}" onClick={() => {

                            this.props.setID(`${_key}`);
                        }}
                        />
                    </div>
                    <div className={styles.Info}>

                        <div className={styles.Name}>{name}

                        
                        </div>
                        <div className={styles.degree}>{(degree == null || degree == '') ? '' : degree.toUpperCase()}{(specialtyText == null || specialtyText == '') ? '' : ' - ' + specialtyText}</div>
                        <div className={styles.Address}>{address1}  {city}  {state}  {zip}</div>
                    </div>


                </div>

            </div>
        );
    }
}

export default OneKeyResult;
