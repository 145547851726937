import React from 'react'
import styles from './../css/StatusLock.module.css'
import Axios from '../../../../components/Axios'

function StatusLock(props) {
    const submit = (index) => {
        let temp = {
            statusID: index,
            ID: (props.user.role === "DBD" && props.user.team == 31) ? props.adminExercise.TARGETING_EXERCISE_ID : props.exercise.TARGETING_EXERCISE_ID,
            terr: props.terr,
            username: props.user.userName
        }
        Axios('/api/targeting/submit-status', temp, (data) => {
            props.getTotals()
        })

        switch (index) {
            case 3:
                // 3 -- UNDER REVIEW -- OAS needs to review/make changes and submit
                emailRequest(index)
                break;
            case 4:
                // 4 -- PENDING APPROVAL -- OBD needs to Approve the Target List
                emailRequest(index)
                break;
            case 5:
                // 5 -- REJECTED -- OBD has sent exercise back to OAS for changes
                emailRequest(index)
                break;
            case 6:
                // 6 -- APPROVED -- Targeting exercise for the territory is finalized
                emailRequest(index)
                break;
            default:
                // do nothing
        }
    }

    const emailRequest = (index) => {

        let temp = {
            statusID: index,
            team: props.user.role === "ADMIN" || (props.user.role === "DBD" && props.user.team == 31) ? props.adminExercise.TEAM : props.exercise.TEAM
        }

        Axios('/api/targeting/get-status-by-id', temp, (status) => {

            let json = {}
            json.REQUEST_TYPE = "IMP TgtEx Submit";
            json.EXERCISE_ID = props.id
            json.EXERCISE_TERR = props.terr
            json.STATUS_ID = index
            json.STATUS_NAME = status.STATUS
            json.STATUS_DESCRIPTION = status.DESCRIPTION
            json.REQUESTOR_ID = props.user.email
            json.REQUESTOR_NAME = props.user.userName
            json.REQUESTOR_TEAM = props.user.team
            json.REQUESTOR_TERR = props.user.terr

            Axios('/api/global/change-request', json, (data) => {
            })

        })
    }

    if (!props.approved) {
        if (["ADMIN", "OTS", "OHS", "OCS", "DTS"].includes(props.user.role) && (props.statusIndex === 0 || props.statusIndex === 1 || props.statusIndex === 2)) {
            return (
                <div key={"Lock"} className={styles.wrapper}>
                    <div className={styles.statusText}>Progress is automatically saved.</div>
                    <button className={styles.submit} onClick={() => {
                        submit((props.exercise.CUSTOMER_TYPE === "HCP" && ["ADMIN", "OTS", "OHS"].includes(props.user.role) && (props.user.team != "11" && props.user.team != "12" && props.user.team != "21")) ? 3 : 4)
                        }}>SUBMIT</button>
                </div>
            )
        }
        else if (["ADMIN", "OAS", "OCS", "DTS", "OHS"].includes(props.user.role) && (props.statusIndex === 3 || props.statusIndex === 5)) {
            return (
                <div key={"Lock"} className={styles.wrapper}>
                    <div className={styles.statusText}>Progress is automatically saved.</div>
                    <button className={styles.submit} onClick={() => {
                            submit(4)
                        }}>SUBMIT</button>
                </div>
            )
        }
        else if (["ADMIN", "OBD", "DBD"].includes(props.user.role) && (props.statusIndex === 4 /*|| props.statusIndex === 5*/)) {
            return (
                <div key={"Lock"} className={styles.wrapper}>
                    <div className={styles.statusText}>Progress is automatically saved.</div>
                    <button className={styles.submit} onClick={() => {
                                submit(5)
                        }}>REJECT</button>
                    <button className={styles.submit} onClick={() => {
                                submit(6)
                        }}>APPROVE</button>
                </div>
            )
        }
    }
    return (
        <div key={"Lock"} className={styles.wrapper}>
            <i className="fas fa-lock"></i>
            <div className={styles.statusText}>Exercise is now locked for editing</div>
            <button disabled className={styles.deactive}>SUBMIT</button>
        </div>
    )
}

export default StatusLock
