import React from 'react';
import styles from '../css/Details_Affiliations.module.css';
import firstCapital from '../../../helper/firstCapital';
import hcp from '../../../lookup/hcp.json'

function Details_Affiliations(props) {

    let displayRes = []
    for (let i = 0; i < props.data.length; i++) {
        let style = i % 2 === 0 ? [styles.Content, styles.Odd].join(' ') : styles.Content
        displayRes.push([
            <div key={i} className={styles.Results}>
                <div className={style}>{firstCapital(props.data[i].ORG_NAME)}</div>
                <div className={style}>{props.data[i].ORG_TYPE}</div>
                <div className={style}>{`${firstCapital(props.data[i].ADDRESS1)} ${firstCapital(props.data[i].ADDRESS2)}, ${firstCapital(props.data[i].CITY)}, ${props.data[i].STATE}, ${props.data[i].ZIP5}`}</div>
                <div className={style}>{props.data[i].PROF_COUNT}</div>
                <div className={style}>{props.data[i].ADD_DATE ? props.data[i].ADD_DATE.replace(/[Tt].*/, "") : ""}</div>
            </div>
        ])
    }

    let affiliationsView = hcp.details.affiliations.view
    let affiliationsEdit = hcp.details.affiliations.edit
    let affiliations = ((props.user.role.includes("PRT") || props.user.role.includes("COMMERCIAL DERM") || props.user.isDermAdmin == 'true' || (props.user.role.includes("ADMIN") || affiliationsView.teams.includes(props.user.team) || affiliationsEdit.teams.includes(props.user.team)) || (affiliationsView.roles.includes(props.user.role) || affiliationsEdit.roles.includes(props.user.role))) && props.user.role != 'PHARMFORCE') ? 
    (<div className={styles.Affiliations}>
        <div className={styles.AffiliationsTopBar}>
            <div>Affiliations</div>
            {
                props.user.role.includes("ADMIN") || affiliationsEdit.teams.includes(props.user.team) || affiliationsEdit.roles.includes(props.user.role) ? (<div className={styles.AffiliationsEditButton}>
                    <i className="far fa-edit" onClick={() => { props.editScreen(9);}}></i>
                </div>) : ""
            }
        </div>
        <div className={styles.titles}>
            <div className={styles.Title}>ORG NAME</div>
            <div className={styles.Title}>ORG TYPE</div>
            <div className={styles.Title}>ADDRESS</div>
            <div className={styles.Title}>AFFILIATED PROF COUNT</div>
            <div className={styles.Title}>LAST MODIFIED</div>
        </div>
        <div className={styles.ResultsWrapper}>
            {
                props.data.length > 0 ?
                displayRes
                :
                <div className={styles.Results}>
                    <div className={[styles.Content, styles.Odd].join(' ')}>—</div>
                    <div className={[styles.Content, styles.Odd].join(' ')}>—</div>
                    <div className={[styles.Content, styles.Odd].join(' ')}>—</div>
                    <div className={[styles.Content, styles.Odd].join(' ')}>—</div>
                    <div className={[styles.Content, styles.Odd].join(' ')}>—</div>
                </div>
            }
        </div>
    </div>) : ""

    return affiliations;
}

export default Details_Affiliations;
