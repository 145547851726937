import React, {useState} from 'react'
import Styles from '../css/DialogBoxEditUserActivate.module.css'
import Axios from '../../../components/Axios/Axios'

export default function DialogBoxEditUserDeactivate(props) {
    const [active] = useState(true)

    const updateUser = () => {
        let stateInfo = {}
        stateInfo = {
            ID: props.data[props.recordIndex].ID,
            ACTIVE: !active
        }
        Axios('/api/admin/user-activation', stateInfo, (results) => {
            let temp = {}
            Axios('/api/admin/search', temp, (data) => {
                props.setData(data)
            })
        })
        props.changeAdminView(-1)
    }

    return (
        <div className={Styles.editScreen}>
            <div className={Styles.title}>
                <div></div>
                <div className={Styles.TitleText}>Deactivate User</div>
                <div className={Styles.close} onClick={() => {props.changeAdminView(-1)}}><i className='fas fa-times'></i></div>
            </div>
            <div className={Styles.body}>
                <div className={Styles.TextBox}>
                    Are you sure you want to deactivate this user?
                    <div className={Styles.BoldText}>{props.data[props.recordIndex].FIRST_NAME} {props.data[props.recordIndex].LAST_NAME}</div>
                </div>
                <div className={Styles.buttonRow}>
                    <div id={Styles.cancel} onClick={() => {props.changeAdminView(-1)}}>CANCEL</div>
                    <div id={Styles.submit} onClick={() => {
                            updateUser()
                            props.changeAdminView(-1)
                        }
                    }>SAVE</div>
                </div>
            </div>
        </div>
    )
}