import React, {useEffect} from 'react';
import styles from './Popup.module.css'

function Popup(props) {
    document.body.style.overflow = "hidden"

    useEffect(() => {
        return (function() {
            document.body.style.overflow = "auto"
        })
    })

    return (
        <div className={styles.Popup} onClick={(e) => {
        }}>
            <div className={styles.container} onClick={(e) => {
                e.stopPropagation()
            }}>
                <props.component {...props} />
            </div>
        </div>
    )
}

export default Popup
