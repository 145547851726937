import React, { Component } from 'react';
import styles from '../css/AddExUs_P2.module.css';
import AddressLookUpExUs from './../../../globalcomponents/AddressLookUpExUs'
import CountryLookup from '../../../lookup/countrylookup';
import countrystatelookup from '../../../lookup/countrystatelookup';
import StateLookUp from './../../../globalcomponents/StateLookUp'

class AddEXUS_P2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            entADDRESS1: `${this.props.info.entADDRESS1 ? this.props.info.entADDRESS1 : ''}`,
            entADDRESS2: `${this.props.info.entADDRESS2 ? this.props.info.entADDRESS2 : ''}`,
            entCITY: `${this.props.info.entCITY ? this.props.info.entCITY : ''}`,
            entCOUNTRY_CODE: `${this.props.info.entCOUNTRY_CODE ? this.props.info.entCOUNTRY_CODE : ''}`,
            entSTATE: `${this.props.info.entSTATE ? this.props.info.entSTATE : ''}`,
            entZIPCODE: `${this.props.info.entZIPCODE ? this.props.info.entZIPCODE : ''}`,
            valADDRESS1: `${this.props.info.valADDRESS1 ? this.props.info.valADDRESS1 : ''}`,
            valADDRESS2: `${this.props.info.valADDRESS2 ? this.props.infovalADDRESS2 : ''}`,
            valCITY: `${this.props.info.valCITY ? this.props.info.valCITY : ''}`,
            valCOUNTRY_CODE: `${this.props.info.valCOUNTRY_CODE ? this.props.info.valCOUNTRY_CODE : ''}`,
            valSTATE: `${this.props.info.valSTATE ? this.props.info.valSTATE : ''}`,
            valZIPCODE: `${this.props.info.valZIPCODE ? this.props.info.valZIPCODE : ''}`,
            PROF_ID: "",
            FIRST_NAME: `${this.props.info.FIRST_NAME ? this.props.info.FIRST_NAME : ''}`,
            MIDDLE_NAME: `${this.props.info.MIDDLE_NAME ? this.props.info.MIDDLE_NAME : ''}`,
            LAST_NAME: `${this.props.info.LAST_NAME ? this.props.info.LAST_NAME : ''}`,
            SUFFIX: `${this.props.info.SUFFIX ? this.props.info.SUFFIX : ''}`,
            EMAIL: `${this.props.info.EMAIL ? this.props.info.EMAIL : ''}`,
            SPECIALTY: `${this.props.info.SPECIALTY ? this.props.info.SPECIALTY : ''}`,
            DEGREE: `${this.props.info.DEGREE ? this.props.info.DEGREE : ''}`,
            REQUEST_TYPE: `${this.props.info.REQUEST_TYPE ? this.props.info.REQUEST_TYPE : ''}`,
            REQUEST_NOTE: `${this.props.info.REQUEST_NOTE ? this.props.info.REQUEST_NOTE : ''}`,
            MELISSA_ENGINE_INFO: `${this.props.info.MELISSA_ENGINE_INFO ? this.props.info.MELISSA_ENGINE_INFO : ''}`,
            selectedcountry: "",
            validated: false,
            validatedPressed: 'false',
            validatedMessage: 'Address not verified',
            countrylookup: [],
            ISVALID: false
        };
    }
    componentDidMount() {


    }

    updateAddress(address) {

        this.setState({
            entADDRESS1: address.entADDRESS1,
            entADDRESS2: address.entADDRESS2,
            entCITY: address.entCITY,
            entCOUNTRY_CODE: address.entCOUNTRY_CODE,
            entSTATE: address.entSTATE,
            entZIPCODE: address.entZIPCODE
        });

        // console.log('l');

    }
    //updateValidatedAddress(jsonData) {

    //    this.setState({
    //        valADDRESS1: jsonData.Records[0]["AddressLine1"],
    //        valADDRESS2: jsonData.Records[0]["AddressLine2"],
    //        valCITY: jsonData.Records[0]["Locality"],
    //        valSTATE: jsonData.Records[0]["CountrySubdivisionCode"],
    //        valZIPCODE: jsonData.Records[0]["PostalCode"]
    //    })
    //    console.log('l');
    //}

    hasValidAddress() {

        console.log(this.state);

        if (this.state.entADDRESS1 !== '' && this.state.entCOUNTRY_CODE !== '' && this.state.entZIPCODE !== '' && this.state.entCITY !== '') {
            return true;
        } else {
            return false;
        }


    }

    getMelissaStateKey(statename) {
        statekey = "";
        if (statename != "") {
            var statekey = Object.keys(countrystatelookup).find(itemkey => countrystatelookup[itemkey] === statename)

            if (statekey != "") {
                statekey = statekey.substr(-2);
            }

        }

        return statekey;
    }

    getMelissaCountryKey(countryname) {
        var countrykey = Object.keys(CountryLookup).find(itemkey => CountryLookup[itemkey] === countryname)
        return countrykey;
    }

    getMelissaUserInput() {
        var userInput = "";
        userInput = "&a1=" + this.state.entADDRESS1 +
            "&a2=" + this.state.entADDRESS2 +
            "&a3=&a4=&a5=&a6=&a7=&a8=&ddeploc=&deploc=" +
            "&loc=" + this.state.entCITY +
            "&subadmarea=" +
            "&admarea=" + this.state.entSTATE +
            "&postal=" + this.state.entZIPCODE +
            "&subnatarea=" +
            "&ctry=" + this.state.entCOUNTRY_CODE +
            "&last=" + "";

        return userInput;
    }

    getOpt() {
        var opt = "";
        opt += "DeliveryLines:" + "On" + ",";
        opt += "OutputGeo:" + "On";
        return opt;
    }

    returnCodesValid(codes) {

        let isValidated = false;
        let msg = 'Address has not been validated';

        //(wiki.melissadata.com/index.php?title=Result_Codes)
        let hasGeneralError = codes.includes("GE");  //also called Geocode Error
        let hasGeneralWarning = codes.includes("GW");
        let hasServiceError = codes.includes("SE");

        let hasAddressError = codes.includes("AE");
        let hasAddressChange = codes.includes("AC");
        let hasAddressValidation = codes.includes("AV");

        //this function will validate the return codes from Melissa
        //known error codes - error first

        if (hasGeneralError || hasGeneralWarning || hasServiceError || hasAddressError) {

            msg = "Service Error: " + codes;
            if (hasAddressError) { msg = "Address could not be validated."; }
        }

        if (hasAddressChange || hasAddressValidation) {

            //valid address - could be changes made
            if (hasAddressChange) {
                msg = 'Address has been updated: ';
                let hasOne = false;

                if (codes.includes("AC03")) { msg += 'City '; hasOne = true; }
                if (codes.includes("AC02")) { msg += hasOne ? ', State ' : 'State '; hasOne = true; }
                if (codes.includes("AC01")) { msg += hasOne ? ', Postal Code ' : 'Postal Code '; hasOne = true; }

                this.setState({ melissaMessage: msg })
            }

            if (codes.includes("AV21", "AV22", "AV23", "AV24", "AV25")) {
                msg = 'Validated Address';
            }
            if (codes.includes("GS05", "AV24")) {
                msg = 'Validated to Premise';
            }
            isValidated = true;
        }

        this.setState({ melissaMessage: msg })
        return isValidated;
    }

    updateMelissaStatus(jsonData, enteredAddress) {

        let recCount = jsonData.TotalRecords;
        if (recCount == 1) {

            let returnCodes = jsonData.Records[0].Results;
            if (returnCodes !== '') {

                if (this.returnCodesValid(returnCodes)) {

                    this.setState({
                        valADDRESS1: jsonData.Records[0]["AddressLine1"],
                        valADDRESS2: jsonData.Records[0]["AddressLine2"],
                        valCITY: jsonData.Records[0]["Locality"],
                        valSTATE: jsonData.Records[0]["CountrySubdivisionCode"],
                        valZIPCODE: jsonData.Records[0]["PostalCode"],
                        MELISSA_ENGINE_INFO: jsonData.Records[0]
                    })

                    //  this.updateValidatedAddress(jsonData);
                    console.log(this.state)
                }
                else {

                    this.setState({
                        validated: false
                    });


                }
            }
        }
        else {
            if (jsonData.TransmissionResults.includes("GE")) {
                this.setState({ validatedMessage: 'Address is not verified.', validated: false, melissaMessage: 'Transmission Error: ' + jsonData.TransmissionResults })
                return false;
            }
        }
    }

    updateMelissaError(error) {
        console.log(error);
    }




    render() {

        return (
            <div className={styles.editScreen}>
                <div className={styles.wrapper}>
                    <div className={styles.title}>
                        <div></div>
                        <div className={styles.TitleText}>Add Ex-US Professional</div>
                        <div className={styles.close} onClick={() => {
                            this.props.close()
                        }}><i className="fas fa-times"></i></div>
                    </div>
                    <div className={styles.body}>
                        <AddressLookUpExUs updateAddress={this.updateAddress.bind(this)} error={this.state.ISVALID} info={this.state} />

                        <div className={styles.buttonRow}>
                            <div id={styles.cancel} onClick={() => {
                                let temp = this.state;
                                this.props.nextPage(0, temp);
                            }}>BACK</div>
                            <div className={this.state.Wiggle && this.state.Wiggle !== undefined ? styles.Wiggle : ""} id={styles.submit} onClick={() => {



                                if (this.hasValidAddress()) {

                                    var url = "https://address.melissadata.net/v3/WEB/GlobalAddress/doGlobalAddress?";
                                    url += "t=&id=" + encodeURIComponent("or26EJurRbIVKwq0_ERuQU**");
                                    url += "&opt=" + this.getOpt().replace(/,\s*$/, "");
                                    url += this.getMelissaUserInput();
                                    url += "&format=JSON";
                                    fetch(url)
                                        .then(response => response.json())
                                        .then((jsonData) => {


                                            let recCount = jsonData.TotalRecords;
                                            if (recCount == 1) {

                                                let returnCodes = jsonData.Records[0].Results;
                                                if (returnCodes !== '') {


                                                    var isValid = this.returnCodesValid(returnCodes);

                                                    let DataJson = {
                                                        entADDRESS1: this.state.entADDRESS1,
                                                        entADDRESS2: this.state.entADDRESS2,
                                                        entCITY: this.state.entCITY,
                                                        entSTATE: this.state.entSTATE,
                                                        entZIPCODE: this.state.entZIPCODE,
                                                        entCOUNTRY_CODE: this.state.entCOUNTRY_CODE,
                                                        valADDRESS1: jsonData.Records[0]["AddressLine1"],
                                                        valADDRESS2: jsonData.Records[0]["AddressLine2"],
                                                        valCITY: jsonData.Records[0]["Locality"],
                                                        valSTATE: jsonData.Records[0]["CountrySubdivisionCode"],
                                                        valZIPCODE: jsonData.Records[0]["PostalCode"],
                                                        MELISSA_ENGINE_INFO: jsonData.Records[0],
                                                        validated: isValid
                                                    };
                                                    this.props.nextPage(2, DataJson);





                                                }
                                            }



                                        })
                                        .catch((error) => {
                                            console.error(error)
                                            this.setState({ verified: -1 });
                                        })

                                }
                                else {

                                    this.setState({
                                        Wiggle: true,
                                        ISVALID: false
                                    })
                                    setTimeout(() => {
                                        this.setState({ Wiggle: false })
                                    }, 500);
                                }
                            }}>NEXT</div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }

}

export default AddEXUS_P2;