import React, {useRef} from 'react'
import styles from '../css/ColumnRecord.module.css'

function ColumnRecord(props) {
    const container = useRef(null)
    const item = useRef(null)
    const drop = e => {
        e.preventDefault()
        props.setDropped(props.order)
    }

    const dragOver = e => {
        e.preventDefault()
        props.setDropped(props.order)
    }

    const dragStart = e => {
        setTimeout(function () {
            item.current.style.backgroundColor = '#bfbfbf'
        }, 10);
        props.setDragged(props.order)
    }

    const drag = e => {

    }

    const dragEnd = e => {
        e.preventDefault()
        setTimeout(function () {
            item.current.style.backgroundColor = '#E6E6E6'
        }, 10);
        props.setDragged(-1)
        props.setDropped(-1)
    }

    return (
        <div className={styles.columnRecord} ref={container} onDrop={drop} onDragOver={dragOver} onDragEnd={dragEnd}>
            <div className={styles.content} ref={item} draggable="true" onDrag={drag} onDragStart={dragStart}>{props.data.title}</div>
        </div>
    )
}

export default ColumnRecord
