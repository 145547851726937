import React, {Component} from 'react';
import styles from './WiggleButton.module.css';

export default class WiggleButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            wiggle: false
        };
    }

    render() {
        return (
            <button className={this.state.wiggle
                    ? [styles.Wiggle, styles.button].join(' ')
                    : styles.button} type="submit" onClick={(event) => {
                    event.preventDefault();
                    let totalItemCount = 0;
                    let count = 0;
                    let items = this.props.required;
                    for (var key in items) {
                        var item = items[key];
                        for (var subkey in items[key]) {
                            if (item[subkey] === '') {
                                totalItemCount++;
                                count++;
                            }
                            else {
                                totalItemCount++;
                            }
                        }
                    }
                    if (totalItemCount !== count) {
                        this.props.action(this.props.send)
                    }
                    else {
                        this.setState({wiggle: true})
                        setTimeout(() => {
                            this.setState({wiggle: false})
                        }, 500);
                    }
                }}>
                {this.props.icon !== undefined ? this.props.icon : ""} {this.props.text !== undefined ? this.props.text : ""}
            </button>
        );
    }
}
