import React, {useState} from 'react'
import styles from '../css/DemotionReason.module.css'

function DemotionReason(props) {
    const [remove, setRemove] = useState(false)

    let icon = remove ? <i className="fas fa-undo-alt"></i> : <i className="far fa-trash-alt"></i>

    return (
        <div className={[styles.wrapper, remove ? styles.remove : ""].join(" ")}>
            <input className={[styles.input, remove ? styles.remove : ""].join(" ")} value={props.data} disabled={remove} onChange={(event) => {
                props.changeReason(event.target.value, props.index)
            }}/>
            <div className={styles.icon} onClick={() => {
                setRemove(!remove)
                props.deleteReason(props.index, remove)
            }}>
                {icon}
            </div>
        </div>
    )
}

export default DemotionReason
