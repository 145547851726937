import React from 'react';
import Styles from '../css/AddHCOSuccess.module.css'

export default function AddHCOSuccess(props) {
    return (
        <div className={Styles.editScreen}>
            <div className={Styles.wrapper}>
                <div className={Styles.title}>
                    <div></div>
                    <div>Add Organization</div>
                    <div
                        className={Styles.close}
                        onClick={() => {
                            props.close();
                        }}>
                        <i className='fas fa-times'></i>
                    </div>
                </div>
                <div className={Styles.body}>
                    <div className={Styles.toprow}>
                        <div className={[Styles.set, Styles.mainTitle].join(' ')}>
                            <div className={Styles.titleText}>
                                <i className='far fa-check-circle'></i>
                            </div>
                        </div>
                    </div>
                    <div className={Styles.middlerow}>
                        <div className={Styles.set}>
                            <div className={Styles.contentTitle}>Thank you!</div>
                            <div className={Styles.content}>Your request has been submitted.</div>
                        </div>
                    </div>
                    <div className={Styles.buttonRow}>
                        <div
                            id={Styles.submit}
                            onClick={() => {
                                props.close();
                            }}>
                            CLOSE
            </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
