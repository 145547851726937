import React, {Component} from 'react';
import styles from '../css/Details_Edit_Specialty.module.css';
import SpecialtyLookUp from '../../../globalcomponents/SpecialtyLookUp';
import { buildBatchedRequests } from '../../../helper/handleBatchedRequests';
import validateRequestNotes from '../../../helper/validateRequestNotes';

class Details_Edit_Specialty extends Component {
    constructor(props) {
        super(props);
        this.state = {
            SPECIALTY: this.props.data.SPECIALTY ? this.props.data.SPECIALTY : -1,
            OTHER_SPECIALTY: this.props.data.OTHER_SPECIALTY ? this.props.data.OTHER_SPECIALTY : -1,
            PREV_SPECIALTY: this.props.data.SPECIALTY ? this.props.data.SPECIALTY : -1,
            PREV_OTHER_SPECIALTY: this.props.data.OTHER_SPECIALTY ? this.props.data.OTHER_SPECIALTY : -1,
            REQUEST_NOTE: null,
            changed: {},
            ISVALID: true
        };
    }

    firstCapital(str) {
        if (str !== undefined) {
            return str.toLowerCase().replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();})
        }
        return "";
    }

    render() {
        let SpecialtyOptions = [<option key={'001'} value={""}></option>]
        for (let i = 0; i < Object.keys(SpecialtyLookUp).length; i++) {
            SpecialtyOptions.push(<option key={i} value={Object.keys(SpecialtyLookUp)[i]}>{Object.keys(SpecialtyLookUp)[i]} - {Object.values(SpecialtyLookUp)[i]}</option>)
        }
        let OtherSpecialtyOptions = [<option key={'001'} value={""}></option>]
        for (let i = 0; i < Object.keys(SpecialtyLookUp).length; i++) {
            OtherSpecialtyOptions.push(<option key={i} value={Object.keys(SpecialtyLookUp)[i]}>{Object.keys(SpecialtyLookUp)[i]} - {Object.values(SpecialtyLookUp)[i]}</option>)
        }
        return (
            <div>
                <div className={styles.title}>
                    <div></div>
                    <div className={styles.TitleText}>Edit Specialty</div>
                    <div className={styles.close} onClick={() => {
                        this.props.close()
                    }}><i className="fas fa-times"></i></div>
                </div>
                <div className={styles.body}>
                    <div className={styles.required}>REQUIRED FIELD *</div>
                    <div className={styles.toprow}>
                        <div className={styles.set}>
                            <div className={styles.inputTitle}>SPECIALTY <div className={styles.required}>*</div></div>
                            <select className={this.state.ISVALID ? styles.DefaultInputForm : styles.ErrorInputForm} value={this.state.SPECIALTY !== -1 ? this.state.SPECIALTY : "-1"} onChange={(event) => {
                                let temp = this.state.changed;
                                if (this.state.PREV_SPECIALTY !== event.target.value) {
                                    if (event.target.value === "-1") {
                                        temp.SPECIALTY = "";
                                    }
                                    else {
                                        temp.SPECIALTY = event.target.value;
                                    }
                                    this.setState({changed: temp});
                                }
                                else {
                                    delete temp.SPECIALTY;
                                    this.setState({changed: temp});
                                }
                                if (event.target.value === "-1") {
                                    this.setState({SPECIALTY: ""})
                                }
                                else {
                                    this.setState({SPECIALTY: event.target.value})
                                }
                            }}>
                                {SpecialtyOptions}
                            </select>
                        </div>
                        <div className={styles.set}>
                            <div className={styles.inputTitle}>SECONDARY SPECIALTY</div>
                            <select className={styles.DefaultInputForm} value={this.state.OTHER_SPECIALTY !== -1 ? this.state.OTHER_SPECIALTY : "-1"} onChange={(event) => {
                                let temp = this.state.changed;
                                if (this.state.PREV_OTHER_SPECIALTY !== event.target.value) {
                                    if (event.target.value === "-1") {
                                        temp.OTHER_SPECIALTY = "";
                                    }
                                    else {
                                        temp.OTHER_SPECIALTY = event.target.value;
                                    }
                                    this.setState({changed: temp});
                                }
                                else {
                                    delete temp.OTHER_SPECIALTY;
                                    this.setState({changed: temp});
                                }
                                if (event.target.value === "-1") {
                                    this.setState({OTHER_SPECIALTY: ""})
                                }
                                else {
                                    this.setState({OTHER_SPECIALTY: event.target.value})
                                }
                            }}>
                                {SpecialtyOptions}
                            </select>
                        </div>
                    </div>

                    <div className={styles.set}>
                        <div className={styles.inputTitle}>REVISION REASON</div>
                        <input className={styles.DefaultInputForm} value={this.state.REQUEST_NOTE} onChange={(event) => {
                            this.setState({ REQUEST_NOTE: validateRequestNotes(event.target.value) });
                        }} />
                    </div>

                    <div className={styles.buttonRow}>
                        <div id={styles.cancel} onClick={() => {
                            this.props.close();
                        }}>CANCEL</div>
                        <div className={this.state.Wiggle && this.state.Wiggle !== undefined ? styles.Wiggle : ""} id={styles.submit} onClick={() => {
                            if (Object.keys(this.state.changed).length > 0 && this.state.SPECIALTY !== -1) {
                                let changed = this.state.changed
                                let requestData = {
                                    PROF_ID: this.props.data.PROF_ID,
                                    REQUESTOR_ID: this.props.user.email,
                                    REQUESTOR_NAME: this.props.user.userName,
                                    REQUESTOR_TEAM: this.props.user.team,
                                    REQUESTOR_TERR: this.props.user.terr,
                                    REQUEST_NOTE: this.state.REQUEST_NOTE
                                }
                                let batch = {
                                    PROF_ID: this.props.data.PROF_ID,
                                    REQUESTOR_ID: this.props.user.email,
                                    REQUESTOR_NAME: this.props.user.userName,
                                    REQUESTOR_TEAM: this.props.user.team,
                                    REQUESTOR_TERR: this.props.user.terr,
                                    REQUEST_TYPE: 'HCP Update Info'
                                }
                                buildBatchedRequests(requestData, changed, batch, this.props.submit.bind(this), "HCP")
                            }
                            else {
                                this.setState({
                                    Wiggle: true,
                                    ISVALID: false
                                })
                                setTimeout(() => {
                                    this.setState({Wiggle: false})
                                }, 500);
                            }
                        }}>SUBMIT</div>
                    </div>
                </div>
            </div>
        )
    }

}

export default Details_Edit_Specialty;
