import React, {useState} from 'react'
import {Link} from "react-router-dom"
import styles from '../css/Result.module.css'
import Dispute from '../../components/Dispute'
import Axios from '../../../../components/Axios'

function Result(props) {
    const [dispute, setDispute] = useState(false)
    const [resolved, setResolved] = useState(![null, ""].includes(props.data.FINAL_MOVE_TYPE))

    const close = () => {
        setDispute(false)
    }

    const resolve = (finalMoveType) => {
        let temp = {
            ID: props.exercise.ID,
            profID: props.data.PROF_ID,
            finalMoveType: finalMoveType === 0 ? "N" : "Y",
            finalReasons: null
        }
        Axios('/api/best-address-exercise/resolve-conflict', temp, (data) => {
            close()
            props.getData()
        })
    }

    if (props.data) {
        let links = []
        links.push(
            <div className={styles.link} onClick={() => {
                setDispute(true)
            }}>View Dispute Details</div>
        )
        let conflict = false
        if (props.conflictsHash && props.conflictsHash[props.data.TERR_ID]) {
            conflict = true
        }

        let popup = ""
        if (dispute) {
            popup = <Dispute {...props} finalMoveType={props.data.FINAL_MOVE_TYPE} id={props.exercise.ID} reason={props.data.REASON} success={true} successMessage={"Test"} submit={resolve} close={close}/>
        }
        const newLocation = props.data.BEST_CITY + ", " + props.data.BEST_STATE 
        const oldLocation = props.data.PRIMARY_CITY + ", " + props.data.PRIMARY_STATE
        return (
            <tr className={[styles.result, conflict ? styles.conflict : ""].join(" ")}>
                <td>
                    {popup}
                    <Link key={"view"} to={`/hcp/details/${props.data.PROF_ID}`}>
                        <div className={[styles.col, styles.short, styles.link].join(' ')}>{`${props.data.FIRST_NAME} ${props.data.LAST_NAME}`}</div>
                    </Link>
                </td>
                <td>
                    <div className={[styles.col, styles.short, styles.center].join(' ')}>{props.data.PRIMARY_ADDRESS1},</div>
                    <div className={[styles.col, styles.short, styles.center].join(' ')}>{oldLocation}</div>
                </td>
                <td>
                    <div className={[styles.col, styles.short, styles.center].join(' ')}>{props.data.CURR_TERR_NAME}</div>
                </td>
                <td>
                    <div className={[styles.col, styles.short, styles.center].join(' ')}>{props.data.BEST_ADDRESS1},</div>
                    <div className={[styles.col, styles.short, styles.center].join(' ')}>{newLocation}</div>
                </td>
                <td>
                    <div className={[styles.col, styles.short, styles.center].join(' ')}>{props.data.BEST_TERR_NAME}</div>
                </td>
                <td className={[styles.col, styles.short, styles.center].join(' ')}>
                    <select className={[styles.setInput].join(" ")} value={props.data.FINAL_MOVE_TYPE} disabled={props.approved} onChange={(event) => {
                        if (event.target.value !== "") {
                            let temp = {
                                ID: props.exercise.ID,
                                profID: props.data.PROF_ID,
                                finalMoveType: event.target.value,
                                finalReasons: null
                            }
                            Axios('/api/best-address-exercise/resolve-conflict', temp, (data) => {
                                props.getData()
                            })
                            setResolved(true)
                        }
                    }}>
                        <option key={'blank'} value={""}></option>
                        <option key={'BEST ADDRESS'} value={"Y"}>BEST ADDRESS</option>
                        <option key={'CURRENT ADDRESS'} value={"N"}>CURRENT ADDRESS</option>
                    </select>
                </td>
                <td className={[styles.links, styles.col, styles.short, styles.center].join(' ')}>
                    {resolved ? <div className={styles.resolved}>Dispute Resolved</div> : links}
                </td>
            </tr>
        );
    }
    else {
        return ""
    }
}

export default Result
