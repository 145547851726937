import React, {Component} from 'react';
import styles from '../css/ChangeRequestCancel.module.css';

class ChangeRequestCancel extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (<div className={styles.window}>
            <div className={styles.title}>
                <div></div>
                <div className={styles.TitleText}>Cancel Change Request</div>
                <div className={styles.close} onClick={() => {
                        this.props.close()
                    }}>
                    <i className="fas fa-times"></i>
                </div>
            </div>

            <div className={styles.body}>
                <div className={styles.content}>Are you sure you want to cancel this request?</div>
                <div className={styles.buttonRow}>
                    <div id={styles.cancel} onClick={() => {
                            this.props.close()
                        }}>NO, CHANGE NOTHING</div>
                    <div className={this.state.Wiggle && this.state.Wiggle !== undefined
                            ? styles.Wiggle
                            : ""} id={styles.submit} onClick={() => {
                            //stuff here
                            this.props.cancelRequest();
                        }}>YES, CANCEL REQUEST</div>
                </div>
            </div>
        </div>)
    }

}

export default ChangeRequestCancel;
