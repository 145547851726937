import React from 'react';
import styles from './SearchLanding.module.css';

function SearchLanding(props) {
    let searchText = ""
    let icon = ""
    let type = ""
    if (props.type === '/hcp') {
        searchText = 'Enter one or more of the search fields in the form above and click SEARCH. Click CLEAR to clear the search fields. For best results, search by a complete identifier (i.e. PROF ID, NPI, DEA, ME).';
        icon = "fas fa-user-plus"
        type = 'PROFESSIONAL'
    } else if (props.type === '/xus') {

        searchText = 'Enter one or more of the search fields in the form above and click SEARCH. Click CLEAR to clear the search fields..';
        icon = "fas fa-user-plus"
        type = 'Ex-US PROFESSIONAL'
    } else {
        searchText = 'Enter one or more of the search fields in the form above and click SEARCH. Click CLEAR ALL to clear the search fields as well as any additional filters.';
        icon = "fas fa-building"
        type = 'ORGANIZATION'
    }
    return (<div className={styles.SearchLanding}>
        <div className={styles.Section}>
            <i id={styles.SearchIcon} className="fas fa-search"></i>
            <div>
                <div>SEARCH</div>
                <div className={styles.Description}>{searchText}</div>
            </div>
        </div>
        <div className={styles.Section}>
            <i id={styles.FilterIcon} className="fas fa-sliders-h"></i>
            <div>
                <div>FILTER</div>
                <div className={styles.Description}>Choose one or more options, then click APPLY FILTER to filter your results. Click CLEAR FILTER to reset the filters.</div>
            </div>
        </div>
        <div className={styles.Section}>
            <i id={styles.PreviewIcon} className="fas fa-eye"></i>
            <div>
                <div>QUICK PREVIEW</div>
                <div className={styles.Description}>Click the Preview icon to toggle the quick preview panel.</div>
            </div>
        </div>
        <div className={styles.Section}>
            <i id={styles.AddIcon} className={icon}></i>
            <div>
                <div>ADD {type}</div>
                <div className={styles.Description}>After a search has been submitted, the ADD {type} button will become available.</div>
            </div>
        </div>
    </div>);
}

export default SearchLanding;
