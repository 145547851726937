import React from 'react';
import styles from '../css/Results.module.css'
import Result from './Result'
import Spinner from '../../Spinner/Spinner'

function Frame(props) {
    let results = []
    if (props.data) {
        for (let i = 0; i < props.data.length; i++) {
            results.push(<Result key={props.data[i].ID} {...props} data={props.data[i]} index={i}/>)
        }
    }

    return (
        <div className={styles.frame}>
            <div className={styles.blueBar}>{props.barTitle}</div>
            <div className={styles.results}>
                <div className={styles.contentTitles}>
                    <div className={[styles.col, styles.short, styles.center].join(' ')}>STATUS</div>
                    <div className={[styles.col, styles.short, styles.center].join(' ')}>NAME</div>
                    <div className={[styles.col, styles.short, styles.center].join(' ')}>TEAM</div>
                    <div className={[styles.col, styles.short, styles.center].join(' ')}>START DATE</div>
                    <div className={[styles.col, styles.short, styles.center].join(' ')}>END DATE</div>
                    <div className={[styles.col, styles.short, styles.center].join(' ')}>CUSTOMER TYPE</div>
                    <div className={[styles.col, styles.short, styles.center].join(' ')}>ACTIONS</div>
                </div>
                {props.loaded ? results : <Spinner />}
            </div>
        </div>
    )
}

export default Frame
