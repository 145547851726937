import React, {useState} from 'react'
import styles from '../css/EditExercise.module.css'
import Axios from '../../Axios/Axios'
import EditReasons from './EditReasons'
import Popup from '../../PopupWindow/Popup'

function EditExercise(props) {

    const [name, setName] = useState(props.data.NAME || "")
    const [team, setTeam] = useState(props.data.TEAM || "")
    const [startDate, setStartDate] = useState(props.data.START_DATE || "")
    const [endDate, setEndDate] = useState(props.data.END_DATE || "")
    const [editReasons, setEditReasons] = useState()

    const [wiggle, setWiggle] = useState(false)
    const [valid, setValid] = useState(true)

    return (
        <div className={styles.editScreen}>
            <div className={styles.title}>
                <div>
                    {editReasons ?
                        (<Popup component={EditReasons} endpoints={props.endpoints} data={props.data} setData={props.setData} type={props.type} close={() => {
                            setEditReasons(false)
                        }} />)
                        : ""}
                </div>
                <div className={styles.titleText}>Edit Exercise</div>
                <div className={styles.close} onClick={() => {
                    props.close()
                }}><i className="fas fa-times"></i></div>
            </div>
            <form>
                <div className={styles.body}>
                    <div className={styles.required}>REQUIRED FIELD *</div>
                    <div className={styles.content}>
                        <div className={styles.set}>
                            <div className={styles.inputTitle}>NAME <div className={styles.required}>*</div></div>
                            <input type="text" className={valid || name !== "" ? styles.default : styles.error} value={name} onChange={(event) => {
                                    setName(event.target.value)
                                }} autoFocus/>
                        </div>
                        <div className={styles.set}>
                            <div className={styles.inputTitle}>TEAM</div>
                            <input type="text" className={styles.default} value={team} disabled />
                        </div>
                        <div className={styles.set}>
                            <div className={styles.inputTitle}>START DATE <div className={styles.required}>*</div></div>
                                <input type="date" className={valid || startDate !== "" ? styles.default : styles.error} value={startDate.slice(0, 10).replace(/[/]/g, "-")} onChange={(event) => {
                                        setStartDate(event.target.value)
                                    }} />
                        </div>
                        <div className={styles.set}>
                            <div className={styles.inputTitle}>END DATE <div className={styles.required}>*</div></div>
                                <input type="date" className={valid || endDate !== "" ? styles.default : styles.error} value={endDate.slice(0, 10).replace(/[/]/g, "-")} onChange={(event) => {
                                        setEndDate(event.target.value)
                                    }} />
                        </div>
                        <div className={styles.set}>
                            <div className={styles.inputTitle}>EXERCISE ID</div>
                            <input type="text" className={styles.default} value={props.data.ID} disabled />
                        </div>
                        <div className={styles.reasonRow}>
                            <div className={styles.set}>
                                <div className={styles.inputTitle}></div>
                                <div id={styles.reasons}
                                    onClick={() => {
                                        setEditReasons(true)
                                    }
                                    }><i className="far fa-edit"></i>{`  EDIT REASONS`}</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.buttonRow}>
                        <div id={styles.cancel}
                            onClick={() => {
                                props.close()
                            }
                        }>CANCEL</div>
                        <div type="submit" id={styles.submit} className={wiggle ? styles.Wiggle : ""} onClick={() => {
                                if (name !== "" && team !== "" && startDate !== "" && endDate !== "") {
                                    let temp = {
                                        name: name,
                                        team: team,
                                        startDate: startDate,
                                        endDate: endDate,
                                        id: props.data.ID,
                                        type: props.data.CUSTOMER_TYPE
                                    }
                                    Axios(props.endpoints.editExercise, temp, (data) => {
                                        props.setData(data)
                                        props.close()
                                    })
                                }
                                else {
                                    setWiggle(true)
                                    setValid(false)
                                    setTimeout(() => {
                                        setWiggle(false)
                                    }, 500);
                                }
                            }
                        }>SUBMIT</div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default EditExercise
