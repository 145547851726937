import React, {Component} from 'react';
import styles from '../css/TopBar.module.css';

class TopBar extends Component {
    constructor(props) {
        super(props)
        this.state = {

        };
    }

    render() {
        return (
            <div className={styles.TopBar}>ADMIN</div>
        );
    }
}

export default TopBar;
