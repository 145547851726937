import React, {useState} from 'react'
import styles from '../css/LovReviewBox.module.css'

function LovReviewBox(props) {

    const [headerChecked, setHeaderChecked] = useState(props.data.headerChecked)

    let data = JSON.parse(JSON.stringify(props.data.LOV))
    if (headerChecked) {
        data.shift();
    }

    return (
        <div className={styles.columnEditBox}>
            <div className={styles.set}>
                <div className={styles.inputTitle}>TITLE <div className={styles.required}>*</div></div>
                <input type="text" className={props.valid || props.data.title !== "" ? styles.default : styles.error} value={props.data.title} onChange={(event) => {
                        props.setData({
                            ...props.data,
                            title: event.target.value
                        })
                    }} autoFocus disabled={props.data.disabled}/>
            </div>
            <div className={styles.set}>
                <div className={styles.inputTitle}>LIST OF VALUES <div className={styles.required}>*</div></div>
                <textarea type="file" className={[styles.csv, styles.default].join(" ")} value={String(data).toUpperCase()} disabled/>
            </div>
            <div className={styles.set}>
                <input style={{width: "auto"}} type="checkbox" checked={headerChecked} onClick={() => {
                        setHeaderChecked(!headerChecked);
                        props.setData({
                            ...props.data,
                            headerChecked: !headerChecked
                        })
                    }}/> Remove header from list
            </div>
        </div>
    )
}

export default LovReviewBox
