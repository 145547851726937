import React from 'react';
import styles from '../css/Details_SLN_History.module.css';


function Details_SLN(props) {

    let SLNs = []

    if (props.slnHistory.length > 0) {

        for (let j = 0; j < props.slnHistory.length; j++) {
            let style = j % 2 === 0 ? [styles.Content, styles.Odd].join(' ') : styles.Content
            let state = props.slnHistory[j].ST
            let licNbr = props.slnHistory[j].STLICENSENBR
            let exp = props.slnHistory[j].SLN_EXP;  //new Date(props.slnHistory[j].SLN_EXP)
            let status = props.slnHistory[j].SLNSTATUS
            let deg = props.slnHistory[j].DEGREE
            let overallSample = props.slnHistory[j].SLNOVERALLSAMPLEABILITY
           // let controlSample = props.slnHistory[j].SLNCONTROLLEDSAMPLEABILITY
            let lastValDate = props.slnHistory[j].VALIDATION_DATE; // new Date(props.slnHistory[j].VALIDATION_DATE.slice(0, 4), props.slnHistory[j].VALIDATION_DATE.slice(5, 2), props.slnHistory[j].VALIDATION_DATE.slice(7, 2))
            let source = props.slnHistory[j].SLN_SOURCE;

            SLNs.push([
                <div key={1} className={styles.Results}>
                    <div className={style}>{state}</div>
                    <div className={style}>{licNbr}</div>
                    <div className={style}>{ deg }</div>
                    <div className={style}>{exp}</div>
                    <div className={style}>{status}</div>
                    <div className={style}>{lastValDate}</div>
                    <div className={style}>{overallSample}</div>
                    <div className={style}>{source}</div>
                </div>
            ])
        }
    }

    let slnHistory = (<div className={styles.Affiliations}>
        <div className={styles.AffiliationsTopBar}>
            <div>State License</div>
            {
                (props.user.role.includes("ADMIN")) ? (<div className={styles.AffiliationsEditButton}>
                    <i className="far fa-edit" onClick={() => { props.addSLN(); }}></i>
                </div>) : ""
            }
        </div>
        <div className={styles.titles}>
            <div className={styles.Title}>STATE</div>
            <div className={styles.Title}>SLN</div>
            <div className={styles.Title}>DEGREE</div>
            <div className={styles.Title}>EXPIRATION DATE</div>
            <div className={styles.Title}>STATUS</div>
            <div className={styles.Title}>LAST VALIDATION DATE</div>
            <div className={styles.Title}>OVERALL SAMPLEABILITY</div>
            <div className={styles.Title}>SOURCE</div>
        </div>
        <div className={styles.ResultsWrapper}>
            {
                props.slnHistory.length > 0 ?
                    SLNs
                :
                <div className={styles.Results}>
                    <div className={[styles.Content, styles.Odd].join(' ')}>—</div>
                    <div className={[styles.Content, styles.Odd].join(' ')}>—</div>
                    <div className={[styles.Content, styles.Odd].join(' ')}>—</div>
                    <div className={[styles.Content, styles.Odd].join(' ')}>—</div>
                    <div className={[styles.Content, styles.Odd].join(' ')}>—</div>
                        <div className={[styles.Content, styles.Odd].join(' ')}>—</div>
                        <div className={[styles.Content, styles.Odd].join(' ')}>—</div>
                        <div className={[styles.Content, styles.Odd].join(' ')}>—</div>
                </div>
            }
        </div>
    </div>) 

    return slnHistory;
}

export default Details_SLN;
