import React, {useState} from 'react'
import {Link} from "react-router-dom";
import styles from './css/Admin.module.css'
import ExerciseAdminPage from '../../../components/ExerciseAdminPage'
import UserTitles from './components/UserTitles'
import Result from './components/Result'
import Approve from './components/Approve'
import Axios from '../../../components/Axios'

function Admin(props) {

    const [data, setData] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [conflictsHash, setConflictsHash] = useState({})
    const [quickDisputesPopup, setQuickDisputesPopup] = useState(false)
    
    const getData = () => {
        let sendObject = {
            terr: (props.user.role === "ADMIN" || (props.user.role == 'DBD' && props.user.team == '31')) ? props.exercise.TEAM : props.user.terr,
            id: props.exercise.ID,
            byTerr: true
        }
        Axios('/api/best-address-exercise/get-current-totals', sendObject, (data) => {
            Axios('/api/best-address-exercise/get-all-conflicts-by-terr', sendObject, (conflicts) => {
                let hash = {}
                for (let i = 0; i < conflicts.length; i++) {
                    const element = conflicts[i];
                    hash[element.TERR_ID] = true
                }
                setConflictsHash(hash)
            })
            setData(data)
            setLoaded(true)
        })
    }

    const endpoints = {
        status: '/api/best-address-exercise/get-statuses',
        editStatus: '/api/best-address-exercise/edit-status'
    }

    const extraButtons = [(
        ["ADMIN", "OBD"].includes(props.user.role) ? (
            <div className={styles.buttonWrapper} key="QuickDispute">
                <Link to={{
                    pathname: '/bestaddress/disputes',
                    state: {
                        from: props.location.pathname
                    }
                }}>
                    <button
                        className={[props.approved ? styles.approved :"", styles.appoveAll].join(" ")}
                        onClick={() => {
                            setQuickDisputesPopup(true)
                        }}
                        disabled={props.approved}
                    >
                        <i className={[styles.icon, "fas", "fa-exclamation-circle"].join(" ")}></i>RESOLVE ALL DISPUTES
                    </button>
                </Link>
            </div>
        ) : <div></div>
    )]
    
    return (
        <ExerciseAdminPage
            {...props}
            headerTitle={'Best Address Exercises'}
            getData={getData}
            data={data}
            setData={setData}
            loaded={loaded}
            setLoaded={setLoaded}
            endpoints={endpoints}
            titles={UserTitles}
            result={Result}
            approve={Approve}
            conflictsHash={conflictsHash}
            locked={Object.keys(conflictsHash).length > 0}
            extraPage={quickDisputesPopup}
            setExtraPage={setQuickDisputesPopup}
            extraPagePopup={<div></div>}
            extraButtons={extraButtons}
        />
    )
}

export default Admin
