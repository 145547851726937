import React from 'react'
import styles from './Ie.module.css'
import icon from '../../assets/exclamation-circle-solid.svg'
import logo from '../../assets/MDM_white.png'

function Ie() {
    return (
        <div className={styles.background}>
            <div className={styles.wrapper}>
                <img src={icon} alt={"Something went wrong."}/>
                <img src={logo} alt={"Something went wrong."}/>
                <div>The MDM portal is not supported in this browser due to security reasons. Please use a different browser.</div>
            </div>
        </div>
    )
}

export default Ie;
