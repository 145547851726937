import React from 'react';
import styles from '../css/Details_Address_History.module.css';
import firstCapital from '../../../helper/firstCapital';

function Details_Address_History(props) {

    // build address history results 

    let staticAddresses = [];


    if (props.addresses.length > 0) {

        for (let j = 0; j < props.addresses.length; j++) {

            let _address = (<div className={styles.Results}>
                <div className={[styles.Content, styles.Odd].join(' ')}>{props.addresses[j].ADDRESS1} {props.addresses[j].ADDRESS2} {props.addresses[j].CITY}, {props.addresses[j].STATE}  {props.addresses[j].POSTAL_CODE}</div>
                <div className={[styles.Content, styles.Odd].join(' ')}>{props.addresses[j].LAST_ADDR_TYPE}</div>
                <div className={[styles.Content, styles.Odd].join(' ')}>{props.addresses[j].ADD_DATE}</div>

            </div>);

            staticAddresses.push(_address);

        }
    }

    let addressHistory = (<div className={styles.Affiliations}>
        <div className={styles.AffiliationsTopBar}>
            <div>Address History</div>

        </div>
        <div className={styles.titles}>
            <div className={styles.Title}>ADDRESS</div>
            <div className={styles.Title}>TYPE</div>
            <div className={styles.Title}>DATE</div>

            
        </div>
        <div className={styles.ResultsWrapper}>
            {
               
                staticAddresses
            }
        </div>
    </div>) 

    return addressHistory;
}

export default Details_Address_History;
