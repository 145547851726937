import React, {Component} from 'react'
import Styles from './HcoDetails.module.css'
import Axios from '../../components/Axios/Axios'
import Loading from '../../components/Loading/Loading'
import DetailsInformation from './components/Details_Information'
//import DetailsPriAddress from './components/Details_PriAddress'
import AddressAlignment from "./components/address-alignment/AddressAlignment";
import DetailsAffiliations from './components/Details_Affiliations'
import DetailsChangeRequests from './components/Details_ChangeRequests'
import DetailsEdit from './components/Details_Edit'
import TdddDetails from './components/Details_TDDD'
import TdddDetailsEdit from './components/Details_TDDD_Edit'
import HcoRequestSuccess from './components/HcoRequestSuccess'

class HcoDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            spinning: false,
            pathError: false,
            editsubmitted: false,
            addTdddsuccess: false,
            hasTeamAffiliations: false
        };
    }

    addTo(index, i, added, addSpend) {
        let json = {}
        if (added) {
            if (i === 0) {
                json.REQUEST_TYPE = "Remove from - Spend";
            } else if (i === 1) {
                json.REQUEST_TYPE = "Remove from - CRM";
            } else if (i === 2) {
                json.REQUEST_TYPE = "Remove from - CTMS";
            }
        } else {
            if (i === 0) {
                json.REQUEST_TYPE = "Add to - Spend";
            } else if (i === 1) {
                json.REQUEST_TYPE = "Add to - CRM";
            } else if (i === 2) {
                json.REQUEST_TYPE = "Add to - CTMS";
            }
        }

        this.props.hash(this.state.data.ORG_ID, i, !added)

        json.ORG_ID = this.state.data.ORG_ID
        json.REQUESTOR_ID = this.props.user.email
        json.REQUESTOR_NAME = this.props.user.userName
        json.REQUESTOR_TEAM = this.props.user.team
        json.REQUESTOR_TERR = this.props.user.terr

        Axios('/api/global/change-request', json, (data) => {
            if (addSpend) {
                let json = {}
                json.REQUEST_TYPE = "Add to - Spend";
                json.ORG_ID = this.state.data.ORG_ID
                json.REQUESTOR_ID = this.props.user.email
                json.REQUESTOR_NAME = this.props.user.userName
                json.REQUESTOR_TEAM = this.props.user.team
                json.REQUESTOR_TERR = this.props.user.terr
                this.props.hash(this.state.data.ORG_ID, 0, !added)
                Axios('/api/global/change-request', json, (data) => {})
            }
        })
    }

    closeEdit() {
        this.setState({editScreen: undefined})
    }

    refresh(data) {
        let temp = this.state.data;
        let keys = Object.keys(data[0])
        for (let i = 0; i < Object.keys(data[0]).length; i++) {
            if (data[0][keys[i]] !== null) {
                temp[keys[i]] = data[0][keys[i]]
            }
        }
        this.setState({data: temp})
    }

    submitEdit(json, list) {

      //  if (this.state.editsubmitted === true) { return; }

        this.setState({ editsubmitted: true })

        if (!list) {
            json.ORG_ID = this.state.data.ORG_ID
            json.REQUESTOR_ID = this.props.user.email
            json.REQUESTOR_NAME = this.props.user.userName
            json.REQUESTOR_TEAM = this.props.user.team
            json.REQUESTOR_TERR = this.props.user.terr
        }
        Axios('/api/global/change-request', json, (data) => {
            this.editScreen(11);
            this.setChange(data);
        })
    }

    editScreen(i, id) {
        this.setState({
            editScreen: <DetailsEdit
                editScreen={this.editScreen.bind(this)}
                affiliations={this.state.affiliations}
                data={this.state.data}
                tdddHistory={this.state.tdddHistory}
                submit={this.submitEdit.bind(this)}
                change={this.state.change}
                close={this.closeEdit.bind(this)}
                id={id}
                index={i}
                user={this.props.user}
                setChange={this.setChange.bind(this)}
                updateChanges={this.updateChanges.bind(this)}
            />
        })
    }

    editScreenChangeRequest(i, data) {
        this.setState({
            editScreen: <DetailsEdit
                editScreen={this.editScreen.bind(this)}
                affiliations={this.state.affiliations}
                data={this.state.data}
                submit={this.submitEdit.bind(this)}
                change={data}
                close={this.closeEdit.bind(this)}
                index={i}
                user={this.props.user}
                setChange={this.setChange.bind(this)}
                updateChanges={this.updateChanges.bind(this)}
            />
        })
    }

    openTdddEdit() {
    
            this.setState({
                 editScreen: <TdddDetailsEdit close={this.closeTdddEdit.bind(this)} addTDDD={this.addTDDD.bind(this)} />
                })
    }
    closeTdddEdit() {
        this.setState({editScreen: undefined})
    }
    closeTdddSuccess() {
            this.setState({successScreen: undefined,addTdddsuccess: false})
    }
    addTDDD(data) {
            //this.setState({
                //    editScreen: undefined
                //})
        
           let json = {}
            
           json.PROF_ID = this.state.data.PROF_ID
           json.REQUESTOR_ID = this.props.user.email
           json.REQUESTOR_NAME = this.props.user.userName
           json.REQUESTOR_TEAM = this.props.user.team
           json.REQUESTOR_TERR = this.props.user.terr
           json.REQUEST_TYPE = "Add to - TDDD";
           json.EXPIRATION_DATE = data.EXP;
           json.SLN_NUMBER = data.LICENSE;
           json.STATUS = data.STATUS;
           json.STATE = data.STATE;
           json.ORG_ID = this.state.data.ORG_ID;
           json.SUBCAT = data.SUBCAT;
    
           Axios('/api/global/change-request', json, (data) => {
            
                this.setState({
                    addTdddsuccess: true,
                    editScreen: undefined
                            })
            })

    }

    componentDidMount() {
        this.loadChange();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data !== this.props.data || prevProps.hashes !== this.props.hashes) {
            this.loadChange();
        }
    }

    loadChange() {
        var oid = this.props.match.params.ORG_ID;
        let json = {
            ORG_ID: this.props.match.params.ORG_ID,
            TEAM: this.props.user.role == 'PRT' || this.props.user.role == 'COMMERCIAL DERM' || this.props.user.role == 'PHARMFORCE' || this.props.user.isDermAdmin == 'true' ? '31' : this.props.user.team 
        }
        Axios('/api/details/hco', json, (results) => {
            if (this.props.hashes !== undefined) {
                let orgID = results.data[0].ORG_ID
                results.data[0].ctms = this.props.hashes.ctms[orgID]
                results.data[0].crm = this.props.hashes.crm[orgID]
                results.data[0].spend = this.props.hashes.spend[orgID]
            }
            if (results.data[0].ORG_ID != oid) {

                this.setState({
                    pathError: true
                })

                console.log('OrgID = ' + results.data[0].ORG_ID + ' query string = ' + oid)

            } else {
                this.setData(results)
            }
        })
    }

    setData(data) {

        let addresses = [data.primaryAddress, data.bestAddress, data.secondaryAddresses, data.addresses]
        for (let i = 0; i < addresses.length; i++) {
            if (addresses[i] === null) {
                addresses[i] = []
            }
        }

        let affiliations = data.affiliations
        if (data.affiliations) {
            affiliations = data.affiliations
            for (let i = 0; i < data.affiliations.length; i++) {
                if (affiliations[i].TEAM_ID == this.props.user.team) {
                    this.setState({ hasTeamAffiliations: true })
                }
            }
        }
        else {
            affiliations = []
        }
        let tdddHistory = data.tdddHistory
                   if (data.tdddHistory) {
                            tdddHistory = data.tdddHistory
                            }
                else {
                        tdddHistory =[]

                        }

        let change = []
        if (data.changeRequests) {
            change = data.changeRequests
        }
        else {
            change = []
        }

        let msl = {}
        if (data.regionalPlan !== undefined && data.regionalPlan !== null) {
            for (let i = 0; i < data.regionalPlan.length; i++) {
                msl[data.regionalPlan[i].ATTR_NAME] = data.regionalPlan[i].ATTR_VALUE
            }
        }
        this.setState({
            addresses: addresses,
            affiliations: affiliations,
            tdddHistory: tdddHistory,
            change: change,
            msl: msl,
            loaded: true,
            data: data.data[0]
        })
    }

    setChange() {

        var url = '/api/details/hco/change-Requests';

        Axios(url, { ORG_ID: this.state.data.ORG_ID, TEAM: this.props.user.role == 'PRT' || this.props.user.role == 'COMMERCIAL DERM' || this.props.user.isDermAdmin == 'true' ? '31' : this.props.user.team }, (data) => {
            if (this.state.editScreen) {
                this.editScreen(11)
            }
            this.setState({
                change: data
            })
        })
    }

    updateChanges() {
        this.loadChange()
    }

    close() {
        this.setState({changeViewValue: -1})
    }


    render() {
        let successDialog = this.state.addTdddsuccess ? (<HcoRequestSuccess close={this.closeTdddSuccess.bind(this)} />) : ""
        if (this.state.loaded) {
            return (
                <div className={Styles.Details}>
                    {this.state.editScreen ? this.state.editScreen : ""}
                    <div className={Styles.TopBar}>
                        <div className={Styles.Back} onClick={() => {
                                if (this.props.history.location.state !== undefined && this.props.history.location.state.from === '/hco') {
                                    this.props.history.push({
                                        pathname: '/hco',
                                        state: {
                                            from: '/hco/details'
                                        }
                                    })
                                }
                                else {
                                    if (document.referrer !== window.location.origin) {
                                        window.location.href = "/";
                                    }
                                    else {
                                        this.props.history.go(-1)
                                    }
                                }
                            }}><i className="fas fa-angle-left"></i>BACK TO SEARCH</div>
                    </div>
                    <div className={Styles.pageWrapper}>
                        <div className={Styles.Wrapper}>
                            {this.state.data !== undefined ? <DetailsInformation hasAffiliations={this.state.hasTeamAffiliations } editScreen={this.editScreen.bind(this)} submitEdit={this.submitEdit.bind(this)} user={this.props.user} data={this.state.data} loadChange={this.loadChange.bind(this)} number={this.props.number} addresses={this.state.addresses} msl={this.state.msl} addTo={this.addTo.bind(this)}/> : ""}
                        </div>
                        <div className={Styles.Wrapper}>
                            <AddressAlignment />
                        </div>
                        <div className={Styles.Wrapper}>
                            <DetailsAffiliations editScreen={this.editScreen.bind(this)} user={this.props.user} data={this.state.affiliations}/>
                        </div>
                        <div className={Styles.Wrapper}>
                                                        <TdddDetails user={this.props.user} data={this.state.tdddHistory} addTDDD={this.openTdddEdit.bind(this)} />
                        </div>
                        <div className={Styles.Wrapper}>
                            <DetailsChangeRequests data={this.state.change} prev={this.props.data} editScreen={this.editScreenChangeRequest.bind(this)}/>
                        </div>
                    </div>
                    {successDialog}
                </div>
            );
        }
        else {
            if (this.state.pathError) {
                return (<div ><br />&nbsp;&nbsp;Error loading data.  Please refresh the page.</div>)
            } else {
                return (<Loading />)
            }
        }
    }
}

export default HcoDetails;
