import React, { useState, useEffect } from 'react'
import AddExercise from './components/AddExercise'
import Review from './components/Review'
import CustomizeColumns from './components/CustomizeColumns'
import UploadLov from './components/UploadLov'
import ReviewLov from './components/ReviewLov'
import OrderColumns from './components/OrderColumns'
import OrderColumnsReview from './components/OrderColumnsReview'
import ChangeWidth from './components/ChangeWidth'
import Success from './components/Success'
import Axios from '../Axios'

function CreateExerciseWizard(props) {
    const [exercise, setExercise] = useState({})
    const [team, setTeam] = useState()
    const [defaultColumns, setDefaultColumns] = useState(props.defaultColumns)
    const [customColumns, setCustomColumns] = useState()
    const [columns, setColumns] = useState()
    const [order, setOrder] = useState()
    const [submitted, setSubmitted] = useState(false)
    const [success, setSuccess] = useState(null)
    const [view, setView] = useState(0)
    const [actualView, setActualView] = useState(0)
    const [hasLov, setHasLov] = useState(false)
    const [hasCustomColumns, setHasCustomColumns] = useState(false)

    useEffect(() => {

        console.log('here');

        Axios(props.endpoints.getCustomColumns, { type: props.type, team: team }, (data) => {
            let json
            let custCol = {}
            if (data.CUSTOM_COLUMNS) {
                json = JSON.parse(data.CUSTOM_COLUMNS)
                for (let i = 0; i < Object.keys(json).length; i++) {
                    custCol[i] = {
                        col: Object.keys(json)[i],
                        title: String(Object.keys(json)[i]).replace(/_/g, " "),
                        options: "Show",
                        show: true,
                        flex: 100 / Object.keys(json).length,
                    }
                }
                setHasCustomColumns(true)
            }
            setExercise({ ...exercise, ID: data[props.EXERCISE_ID_KEY] })

            setCustomColumns(custCol)
        })
    }, [team])

    useEffect(() => {
        if (submitted) {
            submit(props, Axios, exercise, defaultColumns, customColumns)
        }
    }, [submitted])

    useEffect(() => {
        let flag = false
        for (let i = 0; i < Object.keys(defaultColumns).length; i++) {
            const element = Object.values(defaultColumns)[i]
            if (element.options === "LOV" && !element.LOV) {
                flag = true
            }
        }
        for (let i = 0; customColumns && i < Object.keys(customColumns).length; i++) {
            const element = Object.values(customColumns)[i]
            if (element.options === "LOV" && !element.LOV) {
                flag = true
            }
        }
        //setHasLov(flag)
        setActualView(view)
    }, [defaultColumns, customColumns, view])

    const submit = () => {

        let temp = { ...exercise }

        temp.columns = {}
        for (let i = 0; i < order.length; i++) {
            if (columns[order[i]].show) {
                temp.columns[i] = columns[order[i]]
            }
        }

        temp.columns = JSON.stringify(temp.columns)
        temp.preview = true
        temp.type = props.type




        Axios(props.endpoints.addExercise, temp, (data) => {
            props.setData(data)
            setSuccess(true)
        }, () => {
            setSuccess(false)
        })
    }

    let pages = [
        <AddExercise {...props} exercise={exercise} setExercise={setExercise} team={team} setTeam={setTeam} view={view} setView={setView} />,
        <Review {...props} defaultColumns={defaultColumns} setDefaultColumns={setDefaultColumns} setHasLov={setHasLov} view={view} setView={setView} />
    ]
    if (hasCustomColumns) {
        pages.push(<CustomizeColumns {...props} customColumns={customColumns} setCustomColumns={setCustomColumns} setHasLov={setHasLov} view={view} setView={setView} />)
    }
    if (hasLov) {
        pages.push(<UploadLov {...props} setHasLov={setHasLov} defaultColumns={defaultColumns} setDefaultColumns={setDefaultColumns} customColumns={customColumns} setCustomColumns={setCustomColumns} view={view} setView={setView} />)
        pages.push(<ReviewLov {...props} setHasLov={setHasLov} defaultColumns={defaultColumns} setDefaultColumns={setDefaultColumns} customColumns={customColumns} setCustomColumns={setCustomColumns} view={view} setView={setView} />)
    }
    pages = [...pages, <OrderColumns {...props} setSubmitted={setSubmitted} columns={columns} setColumns={setColumns} order={order} setOrder={setOrder} defaultColumns={defaultColumns} customColumns={customColumns} view={view} setView={setView} />,
    <OrderColumnsReview {...props} columns={columns} order={order} setSubmitted={setSubmitted} view={view} setView={setView} />,
    <Success {...props} success={success} view={view} setView={setView} />,
    <ChangeWidth {...props} data={props.data[props.data.length - 1]} view={view} setView={setView} />
    ]

    return pages[actualView]
}
export default CreateExerciseWizard
