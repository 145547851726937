import React, {useState, useEffect} from 'react'
import {useParams} from "react-router-dom"
import styles from './css/Search.module.css'
import TopBar from '../../../components/TopBar'
import Results from '../../../components/Results'
import Titles from '../../../components/TitlesByConfig/Titles'
import Pagination from '../../../components/Pagination'
import Spinner from '../../../components/Spinner'
import Axios from '../../../components/Axios'
import Field from '../../../components/Field'
import Result from './components/Result'
import StatusBar from './components/StatusBar'
import Table from '../../../components/Table'

function Search(props) {
    const [config, setConfig] = useState()
    const [id, setId] = useState('')
    const [terrID, setTerrID] = useState('')
    const [data, setData] = useState([])
    const [prevData, setPrevData] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const [resultsPerPage, setResultsPerPage] = useState(20)
    const [totalResults, setTotalResults] = useState(0)
    const [searchParams, setSearchParams] = useState({
        FIRST_NAME: '',
        LAST_NAME: '',
        MOVE_TYPE: '',
        RX_FLAG: ''
    })
    const [status, setStatus] = useState()
    const [lock, setLock] = useState()
    const [loaded, setLoaded] = useState(false)
    const {idSlug, terrSlug} = useParams()
    const [options, setOptions] = useState()
    const [conflicts, setConflicts] = useState()
    const [resetSort, setResetSort] = useState(false)
    
    useEffect(() => {
        let id = idSlug ? idSlug : props.config ? -1 : props.exercise.ID
        let terr = terrSlug ? terrSlug : props.config ? -1 : props.user.terr
        setId(id)
        setTerrID(terr)
        getTotals(terr, id)
        let tempConfig = JSON.parse(props.exercise.COLUMNS)
        for (let i = 0; i < Object.keys(tempConfig).length; i++) {
            const element = Object.values(tempConfig)[i]
            if (["Multiple"].includes(element.col)) {
                element.left = true
                element.sortBy = ["FIRST_NAME", "LAST_NAME"]
            }
            else if (element.col === "CURRENT_ADDRESS") {
                element.sortBy = ["PRIMARY_ADDRESS1", "PRIMARY_CITY", "PRIMARY_STATE", "PRIMARY_ZIP5"]
            }
            else if (element.col === "BEST_ADDRESS") {
                element.sortBy = ["BEST_ADDRESS1", "BEST_CITY", "BEST_STATE", "BEST_ZIP5"]
            }
        }
        setConfig(tempConfig)
        Axios(`/admin/best-address-exercise/get-demotion-reasons`, {}, (data) => {
            let newData = []
            data.forEach((temp, i) => {
                newData.push(temp.REASON)
            });
            setOptions(newData)
        })
        if (["ADMIN", "OBD"].includes(props.user.role)) {
            Axios('/api/best-address-exercise/get-all-conflicts', {id: id}, (data) => {
                let hash = {}
                for (let i = 0; i < data.length; i++) {
                    hash[data[i].PROF_ID] = 1
                }
                setConflicts(hash)
            })
        }
        else {
            setConflicts({})
        }
    }, [])

    const getTotals = (exTerr, exId) => {
        exTerr = terrID ? terrID : exTerr
        exId = id ? id : exId
        Axios('/api/best-address-exercise/get-current-totals', {terr: exTerr, id: exId}, (data) => {
            setStatus(data[0])
        })
    }

    const search = () => {
        setResetSort(true)
        setLoaded(false)
        let request = {
            ...searchParams,
            id: id,
            terr: terrID
        }
        Axios(`/api/best-address-exercise/get-source-table-data`, request, (data) => {
            for (let i = 0; i < data.length; i++) {
                let customColumns
                let recordConflicts = false
                if (data[i].CUSTOM_COLUMNS) {
                    customColumns = JSON.parse(data[i].CUSTOM_COLUMNS)
                }
                if (conflicts) {
                    if (conflicts[data[i].PROF_ID]) {
                        recordConflicts = true
                    }
                }
                data[i] = {
                    ...data[i],
                    ...customColumns,
                    recordConflicts
                }
            }
            setData(data)
            setLoaded(true)
        })
    }

    const clear = () => {
        setSearchParams({
            FIRST_NAME: '',
            LAST_NAME: '',
            MOVE_TYPE: '',
            RX_FLAG: ''
        })
        setData(prevData)
        setResetSort(true)
    }

    useEffect(() => {
        if (prevData.length === 0 && data !== []) {
            setPrevData(data)
        }
    }, [data, prevData])

    useEffect(() => {
        setTotalResults(data.length)
    }, [data.length])

    useEffect(() => {
        if (!props.approved) {
            if (status) {
                if (["ADMIN", "OTS"].includes(props.user.role) && (status.STATUS_ID === 1 || status.STATUS_ID === 2)) {
                    setLock(false)
                }
                else if (["ADMIN", "OAS"].includes(props.user.role) && (status.STATUS_ID === 3 || status.STATUS_ID === 5)) {
                    setLock(false)
                }
                else if (["ADMIN", "OBD"].includes(props.user.role) && (status.STATUS_ID === 4 || status.STATUS_ID === 5)) {
                    setLock(false)
                }
                else {
                    setLock(true)
                }
            }
        }
    }, [status, props.approved])

    if (!config) {
        return (
            <Spinner />
        )
    }
    else {
        let rxOptions = [
            <option key="Any" value="">Any</option>,
            <option key="Yes" value="Y">Yes</option>,
            <option key="No" value="N">No</option>
        ]
        let moveOptions = [
            <option key="Any" value="">Any</option>,
            <option key="IN" value="IN">In</option>,
            <option key="OUT" value="OUT">Out</option>
        ]
        return (
            <div className={styles.search} style={props.preview ? {height: 'calc(100% - 50px)'} : undefined}>
                <TopBar {...props} loaded={config && conflicts} config={config} data={data} searchParams={searchParams} backButtonUsers={["ADMIN", "OAS", "OBD"]} name={props.exercise.NAME} search={search} setData={setData} setTotalResults={setTotalResults} setLoaded={setLoaded} export={true} clear={clear}>
                    <Field title="FIRST NAME" index={'FIRST_NAME'} value={searchParams} onChange={setSearchParams} />
                    <Field title="LAST NAME" index={'LAST_NAME'} value={searchParams} onChange={setSearchParams} />
                    <Field title="MOVE TYPE" index={'MOVE_TYPE'} options={moveOptions} value={searchParams} onChange={setSearchParams} />
                    <Field title="RX FLAG" index={'RX_FLAG'} options={rxOptions} value={searchParams} onChange={setSearchParams} />
                </TopBar>
                <StatusBar {...props} status={status} id={id} terr={terrID} getTotals={getTotals} />
                <div className={styles.results} ref={props.tableRef}>
                    <Table styles={[styles.table]}>
                        <Titles {...props} config={config} data={data} setData={setData} resetSort={resetSort} setResetSort={setResetSort} />
                        <Results {...props} status={status} id={id} terr={terrID} getTotals={getTotals} idField={"PROF_ID"} result={Result} config={config} data={data} loaded={loaded} lock={props.approved || lock} currentPage={currentPage} resultsPerPage={resultsPerPage} options={options} />
                    </Table>
                </div>
                
                
                <Pagination {...props} currentPage={currentPage} setCurrentPage={setCurrentPage} resultsPerPage={resultsPerPage} setResultsPerPage={setResultsPerPage} totalResults={totalResults} setTotalResults={setTotalResults} setProperties={(newCurrentPage, newResultsPerPage) => {
                        if (newCurrentPage !== currentPage) {
                            setCurrentPage(newCurrentPage)
                        }
                        if (newResultsPerPage !== resultsPerPage) {
                            setResultsPerPage(newResultsPerPage)
                        }
                    }}/>
            </div>
        )
    }
}

export default Search
