const focusarea = [
    "",
    "ALL",
    "AML",
    "BLADDER",
    "BREAST",
    "CANCER IMMUNOLOGY",
    "CANCER IMMUNOTHERAPY",
    "CCA",
    "CLL",
    "CML",
    "COLORECTAL",
    "ESOPHAGEAL",
    "GVHD",
    "HEAD AND NECK",
    "HEM",
    "HEPATOCELLULAR",
    "LEUKEMIA-OTHER",
    "LUNG",
    "LYMPHOMA",
    "MDS",
    "MED-ONC",
    "MELANOMA",
    "MPN",
    "MULTIPLE MYELOMA",
    "NEUROLOGICAL TUMORS",
    "OVARIAN",
    "PANCREATIC",
    "PATHOLOGY",
    "PEDIATRIC CANCERS",
    "PHASE 1",
    "PRECLINICAL RESEARCH",
    "PROSTATE",
    "RCC",
    "SARCOMA",
    "SICKLE CELL",
    "SURGERY",
    "THROMBOSIS",
    "THYMUS",
    "TRANSPLANT"
]

export default focusarea
