import React, { useState, useEffect, useContext } from 'react';
import Styles from './css/Home.module.css';
import Axios from '../../components/Axios/Axios';
import ChangeRequest from '../ChangeRequests/ChangeRequest';
import Activity from './components/Activity';
import PlanCard from './components/PlanCard';
import Submitted from './components/Submitted';
import { RootStoreContext } from '../../_stores/rootStore';

export default function Home(props) {
    const rootStore = useContext(RootStoreContext);
    const { user, isAuthenticated } = rootStore.userStore;
    const [viewValue, setViewValue] = useState(-1);
    const [editIDValue, setEditIDValue] = useState('');
    const [submitted, setSubmitted] = useState('');
    const [activities, setActivities] = useState('');
    const [showCommmentBut, setCommentButton] = useState(false);

    useEffect(() => {
        Axios('/api/home/details', user, (results) => {
            let submitted = [];
            let activity_list = [];

            if (results.submitted) {
                for (let i = 0; i < results.submitted.length; i++) {
                    if (!['Approve Regional Plan Exercise', 'CP_PASSWORD_RESET'].includes(results.submitted[i].REQUEST_TYPE)) {
                        submitted.push(results.submitted[i]);
                    }
                }
                setSubmitted(submitted.reverse());
            }
            if (results.activity) {
                for (let i = 0; i < results.activity.length; i++) {

                    activity_list.push(results.activity[i]);

                }
                setActivities(activity_list);

            }



        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const changeHomeView = (value) => {
        setViewValue(value);
    };

    const close = () => {
        setViewValue(-1);
    };

    const setEditID = (id) => {
        setCommentButton(false);
        setEditIDValue(id);
        changeHomeView(0);
    };
    const setEditIDWithComments = (id) => {
        setCommentButton(true);
        setEditIDValue(id);
        changeHomeView(0);
    };
    const updateSubmitted = () => {
        let json = {
            userName: user.userName,
        };
        Axios('/api/home/changeRequests', json, (result) => {
            setSubmitted(result.reverse());
            
        });
        setViewValue(-1);
    };

    let activityPanel =
        (['DIR', 'MSL', 'OCNE', 'NAM', 'SA NAM', 'ADMIN', 'DTS', 'DBD', 'PRT', 'COMMERCIAL DERM'].includes(user.role) || user.isDermAdmin == 'true' ||
            ['11', '12', '21', '31'].includes(user.team)) && user.role != 'PHARMFORCE' ? (
            <div className={Styles.box}>
                <div className={Styles.boxTitle}>Activity</div>

                <Activity user={user} activities={activities} setEditID={setEditIDWithComments} />
            </div>
        ) : (
            ''
        );

    let exerciseCards = []

    if (props.exercises) {
        if (props.exercises.regionalPlan) {
            exerciseCards.push(
                <PlanCard
                    key={0}
                    {...props}
                    exercise={props.exercises.regionalPlan}
                    destination={`/regionalplan/${props.exercises.regionalPlan.REGIONAL_PLAN_ID}`}
                    exerciseType={'Regional Plan Exercise'}
                    date={`${props.exercises.regionalPlan.START_DATE} until ${props.exercises.regionalPlan.END_DATE}`}
                    icon={<i className='fas fa-map-marked-alt'> </i>}
                />
            );
        }
        if (["ADMIN"].includes(user.role) || (user.role == 'DBD' && user.team == '31')) {
            if (props.exercises.targeting) {
                for (let i = 0; i < props.exercises.targeting.length; i++) {
                    exerciseCards.push(<PlanCard key={props.exercises.targeting[i].ID} {...props} destination={`/targeting/admin/${props.exercises.targeting[i].ID}`} name={props.exercises.targeting[i].NAME} exerciseType={"Targeting Exercise"} date={`${props.exercises.targeting[i].START_DATE} until ${props.exercises.targeting[i].END_DATE}`} icon={<i className="fas fa-bullseye"></i>} />)
                }
            }
        }
        else {
            if (props.exercises.targeting) {
                exerciseCards.push(<PlanCard key={1} {...props} destination={'/targeting'} name={props.exercises.targeting.NAME} exerciseType={"Targeting Exercise"} date={`${props.exercises.targeting.START_DATE} until ${props.exercises.targeting.END_DATE}`} icon={<i className="fas fa-bullseye"></i>} />)
            }
        }
        if (props.exercises.bestAddress) {
            exerciseCards.push(<PlanCard key={2} {...props} exercise={props.exercises.bestAddress} destination={'/bestAddress'} exerciseType={"Best Address Exercise"} date={`${props.exercises.bestAddress.START_DATE} until ${props.exercises.bestAddress.END_DATE}`} icon={<i className="fas fa-home"></i>} />)
        }
    }


    let exercisePanel =
        (['DIR', 'MSL', 'OCNE', 'NAM', 'SA NAM', 'ADMIN', 'DTS', 'DBD'].includes(user.role) ||
            ['11', '12', '21', '31'].includes(user.team) && (!['PRT'].includes(user.role))) && user.role != 'PHARMFORCE' ? (
            <div className={Styles.box}>
                <div className={Styles.boxTitle}>Exercises</div>
                <div className={Styles.wrapper}>{exerciseCards}</div>
            </div>
        ) : (
            ''
        );

    return (
        <div
            className={
                ['DIR', 'MSL', 'OCNE', 'NAM', 'SA NAM', 'ADMIN', 'DTS', 'DBD'].includes(user.role) ||
                    ['11', '21', '31'].includes(user.team)
                    ? Styles.Home
                    : Styles.HomeChanges
            }>
            {viewValue !== -1 ? <ChangeRequest data={editIDValue} callback={updateSubmitted} user={user} close={close} showComments={showCommmentBut} /> : ''}
            <div className={Styles.row}>
                {activityPanel}
                {exercisePanel}
            </div>
            <div className={Styles.row}>
                <div className={Styles.box}>
                    <div className={Styles.boxTitle}>Your Submitted Requests</div>
                    <div className={Styles.titles}>
                        <div className={Styles.content}>ACTION ID</div>
                        <div className={Styles.content}>STATUS</div>
                        <div className={Styles.content}>REQUEST DATE</div>
                        <div className={Styles.content}>HCP/ORG NAME</div>
                        <div className={Styles.content}>REQUEST TYPE</div>
                        <div className={Styles.content}>COMMENTS</div>
                        <div className={Styles.content}>LAST MODIFIED DATE</div>
                    </div>
                    <Submitted data={submitted} setEditID={setEditID} changeHomeView={changeHomeView} />
                </div>
            </div>
        </div>
    );
}