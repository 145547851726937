import React, {useEffect} from 'react';
import styles from './Popup.css'

function Popup(props) {
    document.body.style.overflow = "hidden"

    useEffect(() => {
        return (function() {
            document.body.style.overflow = "auto"
        })
    })

    useEffect(() => {
        //console.log("CHANGED");
    }, [props.body])

    return (
        <div className={"pop"} onClick={(e) => {}}>
            <div className={"pop-container"} onClick={(e) => {
                e.stopPropagation()
            }}>
                <div className={"pop-editScreen"}>
                    <div className={"pop-title"}>
                        <div></div>
                        <div className={"pop-titleText"}>{props.title}</div>
                        <div className={"pop-close"} onClick={() => {
                            props.close()
                        }}><i className="fas fa-times"></i></div>
                    </div>
                    {props.body}
                </div>
            </div>
        </div>
    )
}

export default Popup
