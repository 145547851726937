let SpecificationLookUp = {
    HO: 'HEMATOLOGY/ONCOLOGY',
    ON: 'ONCOLOGY',
    HEM: 'HEMATOLOGY - INTERNAL MEDICINE',
    NRP: 'NURSE PRACTITIONER',
    IM: 'INTERNAL MEDICINE',
    PHA: 'PHYSICIAN ASSISTANT',
    PHAR: 'PHARMACEUTICAL MEDICINE',
    PHO: 'PEDIATRIC HEMATOLOGY/ONCOLOGY',
    A: 'ALLERGY',
    ABS: 'ABDOMINAL SURGERY',
    ACA: 'ADULT CARDIOTHORACIC ANESTHESIOLOGY',
    ACC: 'ANESTHESIOLOGY CRITICAL CARE MEDICINE-EMERGENCY MEDICINE',
    ADL: 'ADOLESCENT MEDICINE - PEDIATRICS',
    ADM: 'ADDICTION MEDICINE',
    ADP: 'ADDICTION PSYCHIATRY',
    AHF: 'ADVANCED HEART FAILURE AND TRANSPLANT CARDIOLOGY',
    AI: 'ALLERGY & IMMUNOLOGY',
    ALI: 'ALLERGY & IMMUNOLOGY - DIAGNOSTIC LABORATORY I',
    AM: 'AEROSPACE MEDICINE',
    AMF: 'ADOLESCENT MEDICINE - FAMILY MEDICINE',
    AMI: 'ADOLESCENT MEDICINE - INTERNAL MEDICINE',
    AN: 'ANESTHESIOLOGY',
    AOM: 'ANTI-OBESITY MEDICINE',
    APM: 'ANESTHESIOLOGY - PAIN MANAGEMENT',
    AR: 'ABDOMINAL RADIOLOGY',
    ARN: 'ADVANCED REGISTERED NURSE',
    AS: 'ABDOMINAL SURGERY',
    ASO: 'ADVANCED SURGICAL ONCOLOGY',
    ATP: 'ANATOMIC PATHOLOGY',
    BBK: 'BLOOD BANKING - TRANSFUSION MEDICINE',
    BIN: 'BRAIN INJURY MEDICINE - NEUROLOGY/PSYCHIATRY',
    BIP: 'BRAIN INJURY MEDICINE - PHYSICAL MEDICINE & REHAB',
    BMG: 'CLINICAL BIOCHEMICAL - MOLECULAR GENETICS',
    CAP: 'CHILD ABUSE PEDIATRICS',
    CBG: 'CLINICAL BIOCHEMICAL GENETICS',
    CCA: 'CRITICAL CARE - ANESTHESIOLOGY',
    CCE: 'CRITICAL CARE MEDICINE - EMERGENCY MEDICINE',
    CCG: 'CLINICAL CYTOGENETICS',
    CCM: 'CRITICAL CARE MEDICINE - INTERNAL MEDICINE',
    CCP: 'CRITICAL CARE - PEDIATRICS',
    CCS: 'CRITICAL CARE SURGERY',
    CD: 'CARDIOVASCULAR DISEASES',
    CDS: 'CARDIOVASCULAR SURGERY',
    CFS: 'CRANIOFACIAL SURGERY',
    CG: 'CLINICAL GENETICS',
    CHD: 'ADULT CONGENITAL HEART DISEASE',
    CHN: 'CHILD NEUROLOGY',
    CHP: 'CHILD AND ADOLESCENT PSYCHIATRY',
    CHS: 'CONGENITAL CARDIAC SURGERY - THORACIC SURGERY',
    CID: 'CLINICAL INFORMATICS - PEDIATRICS',
    CIE: 'CLINICAL INFORMATICS - EMERGENCY MEDICINE',
    CIF: 'CLINICAL INFORMATICS - FAMILY MEDICINE',
    CIM: 'CLINICAL INFORMATICS - PREVENTIVE MEDICINE',
    CIP: 'CLINICAL INFORMATICS - PATHOLOGY',
    CLI: 'CLINICAL AND LABORATORY IMMUNOLOGY',
    CLP: 'CLINICAL PATHOLOGY',
    CMG: 'CLINICAL MOLECULAR GENETICS',
    CN: 'CLINICAL NEUROPHYSIOLOGY',
    CNA: 'CERTIFIED NURSE ANESTHETIST',
    CNM: 'NURSE MIDWIFE',
    CNS: 'CLINICAL NURSE SPECIALIST',
    CPP: 'CHILD & ADOLESCENT PSYCHIATRY - PEDIATRICS',
    CRS: 'COLON & RECTAL SURGERY',
    CS: 'COSMETIC SURGERY',
    CTR: 'CARDIOTHORACIC RADIOLOGY',
    CTS: 'CARDIOTHORACIC SURGERY',
    D: 'DERMATOLOGY',
    DBP: 'DEVELOPMENTAL BEHAVIORAL PEDIATRICS',
    DDL: 'DERMATOLOGICAL IMMUNOLOGY',
    DEN: 'DENTISTRY',
    DGP: 'DENTIST',
    DIA: 'DIABETES',
    DLI: 'DIAGNOSTIC LABORATORY IMMUNOLOGY',
    DMP: 'DERMATOPATHOLOGY',
    DNN: 'DIAGNOSTIC RADIOLOGY - NUCLEAR MEDICINE',
    DR: 'DIAGNOSTIC RADIOLOGY',
    DS: 'DERMATOLOGIC SURGERY',
    EFM: 'EMERGENCY MEDICINE - FAMILY MEDICINE',
    EM: 'EMERGENCY MEDICINE',
    EMP: 'EMERGENCY MEDICINE - PEDIATRICS',
    EMS: 'EMERGENCY MEDICAL SERVICES',
    END: 'ENDOCRINOLOGY',
    ENR: 'ENDOVASCULAR SURGICAL NEURORADIOLOGY (NEUROLOGY)',
    EP: 'EPIDEMIOLOGY',
    EPL: 'EPILEPSY',
    ES: 'ENDOVASCULAR SURGICAL NEURORADIOLOGY',
    ESM: 'SPORTS MEDICINE - EMERGENCY MEDICINE',
    ESN: 'ENDOVASCULAR SURGICAL NEURORADIOLOGY - RADIOLOGY',
    ETX: 'EMERGENCY MEDICINE - MEDICAL TOXICOLOGY',
    FLX: 'FLEXIBLE',
    FM: 'FAMILY MEDICINE',
    FMP: 'FAMILY MEDICINE - PREVENTATIVE MEDICINE',
    FOP: 'FORENSIC PATHOLOGY',
    FP: 'FAMILY PRACTICE',
    FPG: 'FAMILY PRACTICE - GERIATRIC MEDICINE',
    FPP: 'FAMILY PRACTICE - PSYCHIATRY',
    FPR: 'FEMALE PELVIC MEDICINE & RECONSTRUCTIVE SURGERY - OBSTETRICS/GYN',
    FPS: 'FACIAL PLASTIC SURGERY',
    FSM: 'FAMILY PRACTICE - SPORTS MEDICINE',
    GE: 'GASTROENTEROLOGY',
    GO: 'GYNECOLOGICAL ONCOLOGY',
    GP: 'GENERAL PRACTICE',
    GPM: 'GENERAL PREVENTIVE MEDICINE',
    GS: 'GENERAL SURGERY',
    GYN: 'GYNECOLOGY',
    HEP: 'HEPATOLOGY - INTERNAL MEDICINE',
    HMP: 'HEMATOLOGY - PATHOLOGY',
    HNS: 'HEAD & NECK SURGERY',
    HOS: 'HOSPITALIST',
    HPA: 'HOSP/PALLIATIVE MEDICINE - ANESTHESIOLOGY',
    HPD: 'HOSP/PALLIATIVE MEDICINE - RADIOLOGY',
    HPE: 'HOSP/PALLIATIVE MEDICINE - EMERGENCY MEDICINE',
    HPF: 'HOSP/PALLIATIVE MEDICINE - FAMILY MEDICINE',
    HPI: 'HOSP/PALLIATIVE MEDICINE - INTERNAL MEDICINE',
    HPM: 'HOSPICE & PALLIATIVE MEDICINE',
    HPN: 'HOSP/PALLIATIVE MEDICINE - PSYCHIATRY & NEUROLOGY',
    HPO: 'HOSP/PALLIATIVE MEDICINE - OBSTETRICS & GYNECOLOGY',
    HPP: 'HOSP/PALLIATIVE MEDICINE - PEDIATRICS',
    HPR: 'HOSP/PALLIATIVE MEDICINE - PHYSICAL MEDICINE & REHABILITATION',
    HPS: 'HOSPICE & PALLIATIVE MEDICINE - SURGERY',
    HS: 'HAND SURGERY',
    HSO: 'HAND SURGERY - ORTHOPEDIC',
    HSP: 'HAND SURGERY - PLASTIC',
    HSS: 'HAND SURGERY - GENERAL',
    IAN: 'CLINICAL INFORMATICS - ANESTHESIOLOGY',
    IC: 'INTERVENTIONAL CARDIOLOGY',
    ICE: 'CARDIAC ELECTROPHYSIOLOGY',
    ID: 'INFECTIOUS DISEASES - INTERNAL MEDICINE',
    IEC: 'INTERNAL MEDICINE - EMERGENCY MEDICINE - CRITICAL CARE MEDICINE',
    IFP: 'INTERNAL MEDICINE - FAMILY PRACTICE',
    IG: 'IMMUNOLOGY',
    ILI: 'CLINICAL/LAB IMMUNOLOGY - INTERNAL MEDICINE',
    IMA: 'INTERNAL MEDICINE - ANESTHESIOLOGY',
    IMD: 'INTERNAL MEDICINE - DERMATOLOGY RESIDENT',
    IMG: 'INTERNAL MEDICINE - GERIATRIC MEDICINE',
    INM: 'INTERNAL MEDICINE - NUCLEAR MEDICINE',
    IPM: 'INTERNAL MEDICINE - PREVENTIVE MEDICINE',
    IRD: 'INTERVENTIONAL RADIOLOGY - DIAGNOSTICS',
    IRI: 'INTERVENTIONAL RADIOLOGY - INTEGRATED',
    ISM: 'INTERNAL MEDICINE - SPORTS MEDICINE',
    LM: 'LEGAL MEDICINE',
    LPN: 'LICENSED PRACTICAL NURSE',
    MBG: 'MEDICAL BIOCHEMICAL GENETICS',
    MDG: 'INTERNAL MEDICINE - MEDICAL GENETICS',
    MDM: 'MEDICAL MANAGEMENT',
    MDP: 'MEDICAL PHYSICS - RADIOLOGY',
    MEM: 'INTERNAL MEDICINE - EMERGENCY MEDICINE',
    MFM: 'MATERNAL & FETAL MEDICINE',
    MG: 'MEDICAL GENETICS',
    MGG: 'MOLECULAR GENETIC PATHOLOGY - MEDICAL GENETICS',
    MGP: 'MOLECULAR GENETIC PATHOLOGY - PATHOLOGY',
    MM: 'MEDICAL MICROBIOLOGY',
    MN: 'INTERNAL MEDICINE - NEUROLOGY',
    MP: 'INTERNAL MEDICINE - PSYCHIATRY',
    MPD: 'INTERNAL MEDICINE - PEDIATRICS',
    MPH: 'PUBLIC HEALTH AND GENERAL PREVENTIVE MEDICINE',
    MPM: 'INTERNAL MEDICINE - PHYSICAL MEDICINE & REHABILITATION',
    MSR: 'MUSCULOSKELETAL RADIOLOGY',
    MXR: 'MAXILLOFACIAL RADIOLOGY',
    N: 'NEUROLOGY',
    NC: 'NUCLEAR CARDIOLOGY',
    NCC: 'NEURO SURGERY - CRITICAL CARE',
    NDN: 'NEURODEVELOPMENTAL DISABILITIES - PSYCHIATRY & NEUROLOGY',
    NDP: 'NEURODEVELOPMENTAL DISABILITIES - PEDIATRICS',
    NEP: 'NEPHROLOGY',
    NM: 'NUCLEAR MEDICINE',
    NMD: 'NEUROMUSCULAR DISORDERS',
    NMN: 'NEUROMUSCULAR MEDICINE',
    NMP: 'NEUROMUSCULAR MEDICINE - PHYSICAL MEDICINE & REHABILITATION',
    NNM: 'NEUROLOGY - NUCLEAR MEDICINE',
    NO: 'OTOLOGY - NEUROETHOLOGY - OTOLARYNGOLOGY',
    NP: 'NEUROPATHOLOGY',
    NPM: 'NEONATAL - PERINATAL MEDICINE',
    NPR: 'NEUROLOGY - PHYSICAL MEDICINE & REHABILITATION',
    NR: 'NUCLEAR RADIOLOGY',
    NRN: 'NEUROLOGY/DIAG RADIOLOGY/NEURORADIOLOGY',
    NS: 'NEUROLOGICAL SURGERY',
    NSP: 'NEUROLOGICAL SURGERY - PEDIATRIC',
    NTR: 'NUTRITION',
    NUP: 'NEUROPSYCHIATRY',
    OAN: 'OBSTETRIC ANESTHESIOLOGY',
    OAR: 'ADULT RECONSTRUCTIVE ORTHOPEDICS',
    OBG: 'OBSTETRICS/GYNECOLOGY',
    OBS: 'OBSTETRICS',
    OCC: 'OBSTETRICS/GYNECOLOGY - CRITICAL CARE MEDICINE',
    OFA: 'FOOT AND ANKLE - ORTHOPEDICS',
    OM: 'OCCUPATIONAL MEDICINE',
    OMF: 'ORAL & MAXILLOFACIAL SURGERY',
    OMM: 'OSTEO MANIPULATIVE MEDICINE',
    OMO: 'MUSCULOSKELETAL ONCOLOGY',
    ONM: 'OSTEOPATHIC NEUROMUSCULOSKELETAL MEDICINE',
    OP: 'ORTHOPEDIC PEDIATRICS',
    OPH: 'OPHTHALMOLOGY',
    OPR: 'OPHTHALMIC PLASTIC AND RECONSTRUCTIVE SURGERY',
    OPT: 'OPTOMETRIST',
    ORS: 'ORTHOPEDIC SURGERY',
    OS: 'OTHER SPECIALTY',
    OSM: 'ORTHOPEDIC SPORTS MEDICINE',
    OSS: 'ORTHOPEDIC SURGERY OF THE SPINE',
    OT: 'OTOLOGY',
    OTO: 'OTOLARYNGOLOGY',
    OTR: 'ORTHOPEDIC TRAUMA',
    P: 'PSYCHIATRY',
    PA: 'CLINICAL PHARMACOLOGY',
    PAN: 'PEDIATRIC ANESTHESIOLOGY',
    PCC: 'PULMONARY CRITICAL CARE',
    PCH: 'CHEMICAL PATHOLOGY',
    PCP: 'CYTOPATHOLOGY',
    PCS: 'PEDIATRIC CARDIOTHORACIC SURGERY',
    PD: 'PEDIATRICS',
    PDA: 'PEDIATRIC ALLERGY',
    PDC: 'PEDIATRIC CARDIOLOGY',
    PDD: 'PEDIATRIC DERMATOLOGY',
    PDE: 'PEDIATRIC ENDOCRINOLOGY',
    PDI: 'PEDIATRIC INFECTIOUS DISEASES',
    PDM: 'PEDIATRICS - DERMATOLOGY RESIDENTS',
    PDN: 'PEDIATRICS ANESTHESIOLOGY',
    PDO: 'PEDIATRIC OTOLARYNGOLOGY',
    PDP: 'PEDIATRIC PULMONOLOGY',
    PDR: 'PEDIATRIC RADIOLOGY',
    PDS: 'PEDIATRIC SURGERY',
    PDT: 'PEDIATRIC MEDICAL TOXICOLOGY',
    PE: 'EMERGENCY MEDICINE - PEDIATRICS',
    PEM: 'PEDIATRIC EMERGENCY MEDICINE',
    PEX: 'PHYSICIAN EXECUTIVE',
    PFP: 'FORENSIC PSYCHIATRY',
    PG: 'PEDIATRIC GASTROENTEROLOGY',
    PH: 'PUBLIC HEALTH AND GENERAL PREVENTIVE MEDICINE',
    PHL: 'PHLEBOLOGY',
    PHM: 'PHARMACEUTICAL MEDICINE',
    PHP: 'PUBLIC HEALTH AND GENERAL PREVENTIVE MEDICINE',
    PIP: 'IMMUNOPATHOLOGY',
    PLI: 'PEDIATRICS - DIAGNOSTIC LABORATORY IMMUNOLOGY',
    PLM: 'PALLIATIVE MEDICINE',
    PM: 'PHYSICAL MEDICINE & REHABILITATION',
    PMD: 'PAIN MEDICINE',
    PME: 'PAIN MANAGEMENT',
    PMG: 'PEDIATRICS - MEDICAL GENETICS',
    PMM: 'PAIN MEDICINE - PHYSICAL MEDICINE & REHABILITATION',
    PMN: 'PAIN MANAGEMENT - NEUROLOGY',
    PMP: 'PEDIATRIC PHYSICAL MEDICINE & REHABILITATION',
    PMR: 'PAIN MANAGEMENT - PHYSICAL MEDICINE & REHABILITATION',
    PN: 'PEDIATRIC NEPHROLOGY',
    PO: 'PEDIATRIC OPHTALMOLOGY',
    POD: 'PODIATRIST',
    PP: 'PEDIATRIC PATHOLOGY',
    PPM: 'PEDIATRICS - PHYSICAL MEDICINE & REHABILITATION',
    PPN: 'PAIN MEDICINE - PSYCHIATRY',
    PPR: 'PEDIATRIC RHEUMATOLOGY',
    PRD: 'PROCEDURAL DERMATOLOGY',
    PRM: 'PEDIATRIC REHABILITATION MEDICINE',
    PRO: 'PROCTOLOGY',
    PRS: 'SPORTS MEDICINE - PHYSICAL MEDICINE & REHABILITATION',
    PS: 'PLASTIC SURGERY',
    PSH: 'PLASTIC SURGERY FOR HEAD & NECK',
    PSI: 'PLASTIC SURGERY - INTEGRATED',
    PSM: 'PEDIATRIC SPORTS MEDICINE',
    PSO: 'PLASTIC SURGERY FOR HEAD & NECK - OTOLARYNGOLOGY',
    PSP: 'PLASTIC SURGERY FOR HEAD & NECK - PLASTIC SURGERY',
    PTH: 'ANATOMIC/CLINICAL PATHOLOGY',
    PTP: 'PEDIATRIC TRANSPLANT HEPATOLOGY',
    PTX: 'PREVENTIVE MEDICINE TOXICOLOGY',
    PUD: 'PULMONARY DISEASE',
    PYA: 'PSYCHOANALYSIS',
    PYG: 'GERIATRIC PSYCHIATRY',
    PYM: 'PSYCHOSOMATIC MEDICINE',
    PYN: 'PSYCHIATRY/NEUROLOGY',
    R: 'RADIOLOGY',
    RAA: 'REGIONAL ANESTHESIOLOGY & ACUTE PAIN MEDICINE',
    REN: 'REPRODUCTIVE ENDOCRINOLOGY & INFERTILITY',
    RHU: 'RHEUMATOLOGY',
    RIP: 'RADIOISOTOPHIC PATHOLOGY',
    RN: 'REGISTERED NURSE',
    RNR: 'NEURORADIOLOGY',
    RO: 'RADIATION ONCOLOGY',
    RP: 'RADIOLOGICAL PHYSICS',
    RPM: 'PEDIATRIC REHABILITATION MEDICINE',
    RS: 'RETINAL SPECIALIST',
    SCI: 'SPINAL CORD INJURY MEDICINE',
    SM: 'SLEEP MEDICINE',
    SMA: 'SLEEP MEDICINE - ANESTHESIOLOGY',
    SME: 'SLEEP MEDICINE',
    SMI: 'SLEEP MEDICINE - INTERNAL MEDICINE',
    SMN: 'SLEEP MEDICINE - PSYCHIATRY & NEUROLOGY',
    SMO: 'SLEEP MEDICINE - OTOLARYNGOLOGY',
    SMP: 'SLEEP MEDICINE - PEDIATRICS',
    SO: 'SURGICAL ONCOLOGY',
    SP: 'SELECTIVE PATHOLOGY',
    SPS: 'SURGERY - PLASTIC SURGERY',
    THP: 'TRANSPLANT HEPATOLOGY',
    TR: 'THERAPEUTIC RADIOLOGY',
    TRS: 'TRAUMA SURGERY',
    TS: 'THORACIC SURGERY',
    TSI: 'THORACIC SURGERY - INTEGRATED',
    TTS: 'TRANSPLANT SURGERY',
    TY: 'TRANSITION YEAR',
    U: 'UROLOGICAL SURGERY',
    UCM: 'URGENT CARE MEDICINE',
    UM: 'UNDERSEA & HYPERBARIC MEDICINE - PREVENTIVE MEDICINE',
    UME: 'UNDERSEA & HYPERBARIC MEDICINE - EMERGENCY MEDICINE',
    UP: 'PEDIATRIC UROLOGY',
    UPR: 'FEMALE PELVIC MEDICINE & RECONSTRUCTIVE SURGERY - UROLOGY',
    US: 'UNSPECIFIED',
    VET: 'VETERINARIAN',
    VIR: 'VASCULAR AND INTERVENTIONAL RADIOLOGY',
    VM: 'VASCULAR MEDICINE',
    VMI: 'VASCULAR MEDICINE - INTERNAL MEDICINE',
    VN: 'VASCULAR NEUROLOGY',
    VS: 'VASCULAR SURGERY',
    VSI: 'VASCULAR SURGERY - INTEGRATED'
}

export default SpecificationLookUp;
