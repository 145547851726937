let DegreeLookUp = {
    MD: 'Medical Doctor',
    DO: 'Doctor Of Osteopathic Medicine',
    NP: 'Nurse Practitioner',
    PA: 'Physician Assistant',
    RN: 'Registered Nurse',
    PHAR: 'Pharmacist',
    PHARD: 'Doctor Of Pharmacology',
    LPN: 'Licensed Practical Nurse',
    PHD: 'Doctor Of Philosophy',
    URC: 'Unlicensed Research Coordinator',
    OFF: 'Office Staff',
    APRN: 'Advanced Practice Registered Nurse',
    BCOD: 'Biologic Coordinator of Dermatology',
    CNM: '(NM) Certified Nurse Midwife',
    CNS: 'Certified Nurse Specialist',
    CPHI: 'Pharmacist Intern',
    CPHT: 'Certified Pharmacy Technician',
    CRA: 'Researcher',
    CRNA: 'Certified Nurse Anesthetist',
    DDS: 'Doctor Of Dental Surgery',
    DPM: 'Doctor Of Podiatric Medicine',
    DVM: 'Doctor Of Veterinary Medicine',
    LVN: 'Licensed Vocational Nurse',
    MA: 'Medical Assistant',
    MEDT: 'Medical Technician',
    MLT: 'Medical Laboratory Technician',
    OD: 'Doctor Of Optometry',
    OT: 'Occupational Therapist',
    OTH: 'Other',
    PSY: 'Psychologist',
    PT: 'Physical Therapist',
    RD: 'Registered Dietitian',
    RT: 'Radiology Technician',
    STUD: 'Student',
    SW: 'Social Worker'
}

export default DegreeLookUp;
