import React, {Component} from 'react';
import styles from '../css/Details_Edit_Affiliations.module.css';
import ResultTitles from './../../../globalcomponents/ResultTitles';
import Toggle from './../../../globalcomponents/Toggle'
import Axios from './../../../components/Axios'
import DetailsEditAffiliationsConfirmation from './Details_Edit_AffiliationsConfirmation';

class Details_Edit_Affiliations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            TEAM: this.props.user.team,
            PROF_ID: this.props.data.PROF_ID,
            READY: false,
            CURRENT_AFFILIATIONS: this.props.affiliations,
            AFFILIATIONS: [],
            AFFILIATIONS_TO_BE_ADDED: [],
            AFFILIATIONS_TO_BE_REMOVED: [],
            ORG_NAME: '',
            PREFERRED_NAME: '',
            ORG_TYPE: '',
            ORG_ID: '',
            CITY: '',
            STATE: '',
            ZIP: '',
            SORT_BY_CURRENT: -1,
            SORT_BY_ALL: -1,
            confirmed: false,
            ActiveButton: false,
        };
    }

    componentDidMount() {
        //Axios('/api/details/get-affiliations-professionals', { state: this.state }, (data) => {
        //    let temp = data;
        //    let current = this.props.affiliations;
        //    for (let i = 0; i < data.length; i++) {
        //        for (let j = 0; j < current.length; j++) {
        //            if (data[i]) {
        //                if (data[i].PARENT_MDM_ID === current[j].ORG_ID) {
        //                    temp.splice(i, 1)
        //                }
        //            }
        //        }
        //    }
        //    this.setState({ READY: true, AFFILIATIONS: temp});
        //});
    }

    sortCurrentColumns(i) {
        let temp = ['ORG_NAME', 'PREFERRED_NAME', 'ORG_TYPE', 'PROF_COUNT', undefined]
        let ret = {data: []}
        this.setState({
            SORT_BY_CURRENT: i
        });
        ret.data = this.state.CURRENT_AFFILIATIONS.sort((a, b) => {
            if (i === 0 || i === 1 || i === 2 || i === 5) {
                if (a[temp[i]] === undefined) {
                    return 1;
                }
                if (b[temp[i]] === undefined) {
                    return -1;
                }
                if (`${a[temp[i]].toLowerCase()}` < `${b[temp[i]].toLowerCase()}`) {
                    return -1;
                }
                if (`${a[temp[i]].toLowerCase()}` > `${b[temp[i]].toLowerCase()}`) {
                    return 1;
                }
            }
            if (i === 3 || i === 4 || i === 6 || i === 7) {
                if (a[temp[i]] === undefined) {
                    return 1;
                }
                if (b[temp[i]] === undefined) {
                    return -1;
                }
                if (parseInt(a[temp[i]]) < parseInt(b[temp[i]])) {
                    return -1;
                }
                if (parseInt(a[temp[i]]) > parseInt(b[temp[i]])) {
                    return 1;
                }
            }
            return 0;
        })
        this.setState({
            CURRENT_AFFILIATIONS: ret.data
        })
    }

    sortAllColumns(i) {
        let temp = ['ORG_NAME', 'PREFERRED_NAME', 'ORG_TYPE', 'PROF_COUNT', undefined]
        let ret = {data: []}
        this.setState({
            SORT_BY_ALL: i
        });
        ret.data = this.state.AFFILIATIONS.sort((a, b) => {
            if (i === 0 || i === 1 || i === 2 || i === 5) {
                if (a[temp[i]] === undefined) {
                    return 1;
                }
                if (b[temp[i]] === undefined) {
                    return -1;
                }
                if (`${a[temp[i]].toLowerCase()}` < `${b[temp[i]].toLowerCase()}`) {
                    return -1;
                }
                if (`${a[temp[i]].toLowerCase()}` > `${b[temp[i]].toLowerCase()}`) {
                    return 1;
                }
            }
            if (i === 3 || i === 4 || i === 6 || i === 7) {
                if (a[temp[i]] === undefined) {
                    return 1;
                }
                if (b[temp[i]] === undefined) {
                    return -1;
                }
                if (parseInt(a[temp[i]]) < parseInt(b[temp[i]])) {
                    return -1;
                }
                if (parseInt(a[temp[i]]) > parseInt(b[temp[i]])) {
                    return 1;
                }
            }
            return 0;
        })
        this.setState({
            AFFILIATIONS: ret.data
        })
    }

    areAllBlank(keys, index) {
        for (let i = 0; i < this.state.length; i++) {
            if (this.state[keys[i]] !== -1 && i !== index) {
                this.setState({ ActiveButton: true })
                return false
            }
        }
        return true;
    }

    search() {
        //Axios('/api/details/get-affiliations-professionals', { state: this.state }, (data) => {
        //    let temp = data;
        //    let current = this.props.affiliations;
        //    for (let i = 0; i < data.length; i++) {
        //        for (let j = 0; j < current.length; j++) {
        //            if (data[i]) {
        //                if (data[i].PARENT_MDM_ID === current[j].ORG_ID) {
        //                    temp.splice(i, 1)
        //                }
        //            }
        //        }
        //    }
        //    this.setState({ READY: true, AFFILIATIONS: temp, AFFILIATIONS_TO_BE_ADDED: [], AFFILIATIONS_TO_BE_REMOVED: []});
        //});
    }

    addFromCurrentAffiliations(index) {
        let affiliation = this.state.CURRENT_AFFILIATIONS[index];
        let affiliations = this.state.AFFILIATIONS_TO_BE_REMOVED;
        affiliations.splice(affiliations.indexOf(affiliation), 1);
        this.setState({AFFILIATIONS_TO_BE_REMOVED: affiliations});
    }

    removeFromCurrentAffiliations(index) {
        let affiliation = this.state.CURRENT_AFFILIATIONS[index];
        let affiliations = this.state.AFFILIATIONS_TO_BE_REMOVED;
        affiliations.push(affiliation)
        this.setState({AFFILIATIONS_TO_BE_REMOVED: affiliations});
    }

    addAffiliation(index) {
        let affiliation = this.state.AFFILIATIONS[index];
        let affiliations = this.state.AFFILIATIONS_TO_BE_ADDED;
        affiliations.splice(affiliations.indexOf(affiliation), 1);
        this.setState({AFFILIATIONS_TO_BE_ADDED: affiliations});
    }

    removeAffiliation(index) {
        let affiliation = this.state.AFFILIATIONS[index];
        let affiliations = this.state.AFFILIATIONS_TO_BE_ADDED;
        affiliations.push(affiliation)
        this.setState({AFFILIATIONS_TO_BE_ADDED: affiliations});
    }

    sendAffiliationChanges() {
        let temp = [];
        if (this.state.AFFILIATIONS_TO_BE_REMOVED.length > 0) {
            for (let i = 0; i < this.state.AFFILIATIONS_TO_BE_REMOVED.length; i++) {
                let json = {};
                json.PROF_ID = this.state.PROF_ID;
                json.ORG_ID = this.state.AFFILIATIONS_TO_BE_REMOVED[i].ORG_ID;
                // json.AFFILIATION = JSON.stringify(state.AFFILIATIONS_TO_BE_REMOVED[i]);
                json.REQUESTOR_ID = this.props.user.email;
                json.REQUESTOR_NAME = this.props.user.userName;
                json.REQUESTOR_TEAM = this.props.user.team;
                json.REQUESTOR_TERR = this.props.user.terr;
                json.REQUEST_TYPE = 'Remove affiliation from professional';
                temp.push(json);
            }
        }
        if (this.state.AFFILIATIONS_TO_BE_ADDED.length > 0) {
            for (let i = 0; i < this.state.AFFILIATIONS_TO_BE_ADDED.length; i++) {
                let json = {};
                json.PROF_ID = this.state.PROF_ID;
                json.ORG_ID = this.state.AFFILIATIONS_TO_BE_ADDED[i].ORG_ID;
                // json.AFFILIATION = JSON.stringify(state.AFFILIATIONS_TO_BE_ADDED[i]);
                json.REQUESTOR_ID = this.props.user.email;
                json.REQUESTOR_NAME = this.props.user.userName;
                json.REQUESTOR_TEAM = this.props.user.team;
                json.REQUESTOR_TERR = this.props.user.terr;
                json.REQUEST_TYPE = 'Add affiliation to professional';
                temp.push(json)
            }
        }
        this.props.submit(temp, true);
    }

    render() {
        let currentAffiliations = [];
        for (let i = 0; i < this.props.affiliations.length; i++) {
            currentAffiliations.push(<div key={i} className={i % 2 === 0 ? [styles.Result, styles.odd].join(' ') : styles.Result}>
                <div className={[styles.item, styles.nameSet].join(' ')}>
                    <div className={styles.Name}>{this.props.affiliations[i].ORG_NAME}</div>
                    <div className={styles.Address}>
                        {`${this.props.affiliations[i].ADDRESS1 ? this.props.affiliations[i].ADDRESS1 : ""}, ${this.props.affiliations[i].ADDRESS2 ? this.props.affiliations[i].ADDRESS2 : ""}
                        ${this.props.affiliations[i].CITY ? this.props.affiliations[i].CITY : ""}, ${this.props.affiliations[i].STATE ? this.props.affiliations[i].STATE : ""}
                        ${this.props.affiliations[i].ZIP5 ? this.props.affiliations[i].ZIP5 : ""}`}
                    </div>
                </div>

                <div className={styles.item}>{this.props.affiliations[i].PREFERRED_NAME}</div>
                <div className={styles.item}>{this.props.affiliations[i].ORG_TYPE}</div>
                <div className={styles.item}>{this.props.affiliations[i].PROF_COUNT}</div>
                <div className={styles.item}><Toggle number={i} active={this.state.AFFILIATIONS_TO_BE_REMOVED.includes(this.props.affiliations[i])
                    ? false
                    : true} click={() => {
                    if (!this.state.AFFILIATIONS_TO_BE_REMOVED.includes(this.props.affiliations[i])) {
                        this.removeFromCurrentAffiliations(i);
                    } else {
                        this.addFromCurrentAffiliations(i);
                    }
                }}/></div>
            </div>);
        }
        let addAffiliations = [];
        for (let i = 0; i < this.state.AFFILIATIONS.length; i++) {
            addAffiliations.push(<div key={i} className={i % 2 === 0 ? [styles.Result, styles.odd].join(' ') : styles.Result}>
                <div className={[styles.item, styles.nameSet].join(' ')}>
                    <div className={styles.Name}>{this.state.AFFILIATIONS[i].ORG_NAME}</div>
                    <div className={styles.Address}>
                        {`${this.state.AFFILIATIONS[i].ADDRESS1 ? this.state.AFFILIATIONS[i].ADDRESS1 : ""}, ${this.state.AFFILIATIONS[i].ADDRESS2 ? this.state.AFFILIATIONS[i].ADDRESS2 : ""}
                        ${this.state.AFFILIATIONS[i].CITY ? this.state.AFFILIATIONS[i].CITY : ""}, ${this.state.AFFILIATIONS[i].STATE ? this.state.AFFILIATIONS[i].STATE : ""} ${this.state.AFFILIATIONS[i].ZIP5 ? this.state.AFFILIATIONS[i].ZIP5 : ""}`}
                    </div>
                </div>
                <div className={styles.item}>{this.state.AFFILIATIONS[i].PREFERRED_NAME}</div>
                <div className={styles.item}>{this.state.AFFILIATIONS[i].ORG_TYPE}</div>
                <div className={styles.item}>{this.state.AFFILIATIONS[i].PROF_COUNT}</div>
                <div className={styles.item}><Toggle number={i} active={this.state.AFFILIATIONS_TO_BE_ADDED.includes(this.state.AFFILIATIONS[i])
                    ? true
                    : false} click={() => {
                    if (!this.state.AFFILIATIONS_TO_BE_ADDED.includes(this.state.AFFILIATIONS[i])) {
                        this.removeAffiliation(i);
                    } else {
                        this.addAffiliation(i);
                    }
                }}/></div>

            </div>);
        }

        if (this.state.READY) {
            if (this.state.confirmed) {
                return <DetailsEditAffiliationsConfirmation close={this.props.close}/>
            }
            else {
                return (
                <div className={styles.wrapper}>
                    <div className={styles.title}>
                        <div></div>
                        <div className={styles.TitleText}>Edit Affiliations</div>
                        <div className={styles.close} onClick={() => {
                            this.props.close()
                        }}><i className="fas fa-times"></i></div>
                    </div>
                    <div className={styles.body}>
                        <div className={styles.MiniTitleBar}>
                            Current Affiliations
                        </div>
                        <ResultTitles className={styles.SearchResultsTitles} sortBy={this.state.SORT_BY_CURRENT} Sort={this.sortCurrentColumns.bind(this)} skip={[4]} even={true} Titles={['ORG NAME', 'NAME', 'ORG TYPE', 'AFFILIATED PROF COUNT', 'AFFILIATED']}/>
                        <div id={styles.CurrentAffiliations}>
                            {currentAffiliations}
                        </div>
                        <div className={styles.MiniTitleBar}>
                            Add Affiliations
                        </div>
                        <div className={styles.TopBar}>
                            <div className={styles.Bar}>
                                <form>
                                    <div className={styles.row}>
                                        <div className={styles.set}>
                                            <div>ORG NAME</div>
                                            <input autoFocus id={styles.ORG_NAME} type="text" value={this.state.ORG_NAME === -1
                                                    ? ""
                                                    : this.state.ORG_NAME} onChange={(event) => {
                                                    if (event.target.value === "") {
                                                        this.setState({ORG_NAME: ''})
                                                        this.areAllBlank(Object.keys(this.state), 0)
                                                    } else {
                                                        this.setState({ORG_NAME: event.target.value, ActiveButton: true})
                                                    }
                                                }}/>
                                        </div>
                                        <div className={styles.set}>
                                            <div>PREFERRED NAME</div>
                                            <input id={styles.PREFERRED_NAME} type="text" value={this.state.PREFERRED_NAME === -1
                                                    ? ""
                                                    : this.state.PREFERRED_NAME} onChange={(event) => {
                                                    if (event.target.value === "") {
                                                        this.setState({PREFERRED_NAME: ''})
                                                        this.areAllBlank(Object.keys(this.state), 1)
                                                    } else {
                                                        this.setState({PREFERRED_NAME: event.target.value, ActiveButton: true})
                                                    }
                                                }}/>
                                        </div>
                                        <div className={styles.set}>
                                            <div>ORG ID</div>
                                            <input id={styles.ORG_ID} type="text" value={this.state.ORG_ID === -1
                                                ? ""
                                                : this.state.ORG_ID} onChange={(event) => {
                                                    if (event.target.value === "") {
                                                        this.setState({ ...this.state, ORG_ID: '' })
                                                        this.areAllBlank(Object.keys(this.state), 2)
                                                    } else {
                                                        this.setState({ ...this.state, ORG_ID: event.target.value, ActiveButton: true })
                                                    }
                                                }} />
                                        </div>
                                        <div className={[styles.set, styles.select].join(' ')}>
                                            <div>ORG TYPE</div>
                                            <select id={styles.ORG_TYPE} type="text" value={this.state.ORG_TYPE === -1
                                                    ? ""
                                                    : this.state.ORG_TYPE} onChange={(event) => {
                                                    if (event.target.value === "") {
                                                        this.setState({ORG_TYPE: ''})
                                                        this.areAllBlank(Object.keys(this.state), 3)
                                                    } else {
                                                        this.setState({ORG_TYPE: event.target.value, ActiveButton: true})
                                                    }
                                                }}>
                                                <option value=""></option>
                                                <option value="ACADEMIC INSTITUTION">ACADEMIC INSTITUTION</option>
                                                    <option value="ASC">ASC</option>
                                                    <option value="FOUNDATION">FOUNDATION</option>
                                                    
                                                <option value="GPO">GPO</option>
                                                <option value="GRP">GRP</option>
                                                <option value="HEALTH NETWORK">HEALTH NETWORK</option>
                                                <option value="HOME HEALTH">HOME HEALTH</option>
                                                <option value="HOSPICE">HOSPICE</option>
                                                <option value="HOSPITAL">HOSPITAL</option>
                                                <option value="KAISER">KAISER</option>
                                                <option value="MCO">MCO</option>
                                                <option value="MEDICAL ASSOCIATION">MEDICAL ASSOCIATION</option>
                                                <option value="MILITARY HOSPITAL">MILITARY HOSPITAL</option>
                                                <option value="ONCOLOGY NETWORK">ONCOLOGY NETWORK</option>
                                                <option value="PATIENT GROUP">PATIENT GROUP</option>
                                                <option value="PHARMACY">PHARMACY</option>
                                                <option value="RADIOLOGY CENTER">RADIOLOGY CENTER</option>
                                                <option value="TEACHING INSTITUTION">TEACHING INSTITUTION</option>
                                            </select>
                                        </div>
                                        <div className={styles.set}>
                                            <div>CITY</div>
                                            <input id={styles.CITY} type="text" value={this.state.CITY === -1
                                                    ? ""
                                                    : this.state.CITY} onChange={(event) => {
                                                    if (event.target.value === "") {
                                                        this.setState({CITY: ''})
                                                        this.areAllBlank(Object.keys(this.state), 4)
                                                    } else {
                                                        this.setState({CITY: event.target.value, ActiveButton: true})
                                                    }
                                                }}/>
                                        </div>
                                        <div className={[styles.set, styles.select].join(' ')}>
                                            <div>STATE</div>
                                            <select id={styles.STATE} type="text" value={this.state.STATE === -1
                                                    ? ""
                                                    : this.state.STATE} onChange={(event) => {
                                                    if (event.target.value === "") {
                                                        this.setState({STATE: ''})
                                                        this.areAllBlank(Object.keys(this.state), 5)
                                                    } else {
                                                        this.setState({STATE: event.target.value, ActiveButton: true})
                                                    }
                                                }}>
                                                <option value=""></option>
                                                <option value="AL">AL</option>
                                                <option value="AK">AK</option>
                                                <option value="AR">AR</option>
                                                <option value="AZ">AZ</option>
                                                <option value="CA">CA</option>
                                                <option value="CO">CO</option>
                                                <option value="CT">CT</option>
                                                <option value="DC">DC</option>
                                                <option value="DE">DE</option>
                                                <option value="FL">FL</option>
                                                <option value="GA">GA</option>
                                                <option value="HI">HI</option>
                                                <option value="IA">IA</option>
                                                <option value="ID">ID</option>
                                                <option value="IL">IL</option>
                                                <option value="IN">IN</option>
                                                <option value="KS">KS</option>
                                                <option value="KY">KY</option>
                                                <option value="LA">LA</option>
                                                <option value="MA">MA</option>
                                                <option value="MD">MD</option>
                                                <option value="ME">ME</option>
                                                <option value="MI">MI</option>
                                                <option value="MN">MN</option>
                                                <option value="MO">MO</option>
                                                <option value="MS">MS</option>
                                                <option value="MT">MT</option>
                                                <option value="NC">NC</option>
                                                <option value="NE">NE</option>
                                                <option value="NH">NH</option>
                                                <option value="NJ">NJ</option>
                                                <option value="NM">NM</option>
                                                <option value="NV">NV</option>
                                                <option value="NY">NY</option>
                                                <option value="ND">ND</option>
                                                <option value="OH">OH</option>
                                                <option value="OK">OK</option>
                                                <option value="OR">OR</option>
                                                <option value="PA">PA</option>
                                                <option value="PR">PR - Puerto Rico</option>
                                                <option value="RI">RI</option>
                                                <option value="SC">SC</option>
                                                <option value="SD">SD</option>
                                                <option value="TN">TN</option>
                                                <option value="TX">TX</option>
                                                <option value="UT">UT</option>
                                                <option value="VT">VT</option>
                                                <option value="VA">VA</option>
                                                <option value="VI">VI - Virgin Islands</option>
                                                <option value="WA">WA</option>
                                                <option value="WI">WI</option>
                                                <option value="WV">WV</option>
                                                <option value="WY">WY</option>
                                            </select>
                                        </div>
                                        <div className={styles.set}>
                                            <div>ZIP</div>
                                            <input id={styles.ZIP} type="text" value={this.state.ZIP === -1
                                                    ? ""
                                                    : this.state.ZIP} onChange={(event) => {
                                                    if (event.target.value === "") {
                                                        this.setState({ZIP: ''})
                                                        this.areAllBlank(Object.keys(this.state), 6)
                                                    } else {
                                                        this.setState({ZIP: event.target.value, ActiveButton: true})
                                                    }
                                                }}/>
                                        </div>
                                        <div className={[styles.set, styles.buttonSet].join(' ')}>
                                            <button className={this.state.searchWiggle && this.state.searchWiggle !== undefined
                                                    ? styles.Wiggle
                                                    : ""} id="searchButton" type="submit" onClick={(event) => {
                                                    event.preventDefault();
                                                    if (this.state.ActiveButton) {
                                                        this.search();
                                                    } else {
                                                        this.setState({ActiveButton: false, searchWiggle: true})
                                                        setTimeout(() => {
                                                            this.setState({searchWiggle: false})
                                                        }, 500);
                                                    }
                                                }}>
                                                <i className={["fas", "fa-search", styles.IconButton].join(' ')}></i> SEARCH</button>
                                        </div>
                                        <div className={styles.Line}></div>
                                        <div className={[styles.set, styles.buttonSet].join(' ')}>
                                            <button id={styles.clearButton} type="button" onClick={() => {
                                                    this.setState({
                                                        ORG_NAME: '',
                                                        PREFERRED_NAME: '',
                                                        ORG_ID: '',
                                                        ORG_TYPE: '',
                                                        CITY: '',
                                                        STATE: '',
                                                        ZIP: '',
                                                        ActiveButton: false
                                                    })
                                                }}>CLEAR ALL</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <ResultTitles className={styles.SearchResultsTitles} sortBy={this.state.SORT_BY_ALL} Sort={this.sortAllColumns.bind(this)} skip={[4]} even={true} Titles={['ORG NAME', 'NAME', 'ORG TYPE', 'AFFILIATED PROF COUNT', 'AFFILIATED']}/>
                        <div id={styles.AddAffiliations}>
                            {addAffiliations}
                        </div>
                        <div className={styles.buttonRow}>
                            <div id={styles.cancel} onClick={() => {
                                    this.props.close();
                                }}>CANCEL</div>
                            <div id={styles.submit} className={this.state.saveWiggle && this.state.saveWiggle !== undefined
                                    ? styles.Wiggle
                                    : ""} onClick={() => {
                                    if (this.state.AFFILIATIONS_TO_BE_REMOVED.length > 0 || this.state.AFFILIATIONS_TO_BE_ADDED.length > 0) {
                                        this.sendAffiliationChanges();
                                    } else {
                                        this.setState({saveWiggle: true})
                                        setTimeout(() => {
                                            this.setState({saveWiggle: false})
                                        }, 500);
                                    }
                                }}>SAVE</div>
                        </div>
                    </div>
                </div>)
            }
        }
        else {
            return '';
        }
    }

}

export default Details_Edit_Affiliations;
