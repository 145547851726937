import React from 'react'
import styles from './Field.module.css'

function Field(props) {
    let value, setValue
    if (typeof props.value === 'object') {
        value = props.value[props.index]
        setValue = (event) => {
            props.onChange({...props.value, [props.index]: event.target.value})
        }
    }
    else {
        value = props.value ? props.value : ""
        setValue = props.onChange ? props.onChange : () => {console.log("You forgot to give me an onChange prop!");}
    }

    if (props.options) {
        let options = []
        if (props.options) {
            props.options.forEach((element, i) => {
                options.push(element)
            });
        }
        return (
            <div className={styles.wrapper}>
                <div className={styles.title}>{props.title ? props.title : ""}</div>
                <select
                    className={styles.select}
                    value={value}
                    onChange={setValue}
                >
                    {options}
                </select>
            </div>
        )
    } else {
        return (
            <div className={styles.wrapper}>
                <div className={styles.title}>{props.title ? props.title : ""}</div>
                <input
                    className={styles.input}
                    value={value}
                    onChange={setValue}
                />
            </div>
        )
    }
}

export default Field