import React, { Component } from 'react'
import styles from './WorkflowForm.module.css'
import options from './options.json';


class WorkflowResult extends Component {
    constructor(props) {
        super(props);
        
    }

    getTin() {

        var arr = this.props.data.WORKFLOW_JSON.split('|');
        var json_tin = '';
        var data_tin = this.props.data.TIN;

        for (let i = 0; i < arr.length; i++) {
            let name = arr[i].split('=')[0];
            if (name==='TIN') {
                json_tin = arr[i].split('=')[1];
            }
        }

        let first2 = json_tin.replace('-', '').substring(0, 2);
        let last7 = json_tin.replace('-', '').substring(2, 9);

        let formattedStr = first2 + '-' + last7;
        return formattedStr;
    }
    render() {

        var color = 'black';

        var enableDelete = 'disabled';

        if (this.props.data.PROGRAM_TITLE.includes("**TEST**")) {
            enableDelete = 'enabled';
        }
        switch (this.props.data.STATUS) {
            case 'CANCELED':
                color = 'red';
                break;
            case 'APPROVED':
                color = 'green';
                break;
            case 'REJECTED':
                color = 'red';
                break;
            default:
                color = 'black';
        }

       
        return (


            <div className={[this.props.odd ? styles.odd : styles.even, styles.contentrow].join(" ")}>

                <div className={[styles.contentitemstatus, styles[color]].join(" ")}>{this.props.data.STATUS}</div>
                <div className={[styles.contentitemshort].join(" ")}>{this.props.data.SUBMIT_NAME}</div>
                <div className={styles.contentitemxshort}>{new Date(this.props.data.SUBMIT_DATE).toLocaleDateString('en-US')}</div>
                <div className={styles.contentitemlong}>{this.props.data.ORG_NAME}</div>
                <div className={styles.contentitemlong}>{this.props.data.PROGRAM_TITLE}</div>
                <div className={styles.contentitemshort}>${this.props.data.AMOUNT}</div>
                <div className={styles.contentitemshort}>{this.props.data.PAYMENT_METHOD}</div>
                <div className={styles.contentitemxshort}>{new Date(this.props.data.START_DT).toLocaleDateString('en-US')}</div>
                <div className={styles.contentitemxshort}>{new Date(this.props.data.END_DT).toLocaleDateString('en-US')}</div>
                <div className={styles.contentitemsap}><i className={["fa", this.props.data.IS_SAP ? "fa-check" : ""].join(" ")} ></i></div>
                <div className={styles.contentitemtin}>{this.getTin()}</div>
                <div className={styles.contentaction}>
                    <div className={styles[this.props.changeViewColor]} onClick={() => {
                        this.props.showStatus(this.props.data.WORKFLOW_INSTANCE_ID)
                    }}>VIEW</div>

                    <div className={styles[this.props.disableEdit]} onClick={() => {

                        if (this.props.disableEdit == 'enabled') {

                            this.props.edit(this.props.data.WORKFLOW_INSTANCE_ID, this.props.data.ORG_ID);
                        }

                    }}>EDIT</div>


                    <div className={styles[this.props.diableCloseout]} onClick={() => {
                        if (this.props.diableCloseout == 'enabled') {
                            this.props.showCloseout(this.props.data.WORKFLOW_INSTANCE_ID);
                        }

                    }}>CLOSE</div>
                    <div className={styles[this.props.diableExport]} onClick={() => {
                        this.props.export(this.props.data.WORKFLOW_INSTANCE_ID);
                    }}>EXPORT</div>
                    <div className={styles[enableDelete]} onClick={() => {
                        if (this.props.data.PROGRAM_TITLE.includes("**TEST**")) {
                            this.props.delete(this.props.data.WORKFLOW_INSTANCE_ID);
                        }
                        
                    }}>DELETE</div>
                </div>

            </div>
        )
    }

}
export default WorkflowResult;







