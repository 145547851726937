import React, { Component } from "react";
import styles from "../css/Result.module.css";
import Toggle from "../../../globalcomponents/Toggle";
import { Link } from "react-router-dom";
import firstCapital from "../../../helper/firstCapital";
import CountryLookUp from "../../../lookup/countrylookup";

class Result extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: props.active === props.number ? true : false,
            odd: parseInt(props.number) % 2 === 0,
            preview: props.preview,
            display: props.display,
            show_details: CountryLookUp[this.props.data.COUNTRY_CODE] == 'Canada' && ["81", "82"].includes(this.props.user.team) ? true: false
        };
    }

    componentWillReceiveProps(newProp) {
        this.setState({
            active: newProp.active === newProp.number ? true : false,
            preview: newProp.preview,
        });
    }

    render() {
        let hashes = this.props.hashes;


        let id = null;
        let status = null;
        let name = null;
        let preview = null;
        let degree = null;
        let specialty = null;
        let sim = null;
        let countryName = "";
        let hasCountryName = false;
        let middleName = this.props.data.MIDDLE_NAME !== null ? " " +  this.props.data.MIDDLE_NAME + " ": " ";
         
        name = this.props.data.FIRST_NAME + middleName + this.props.data.LAST_NAME;
        degree = this.props.data.DEGREE;
        specialty = this.props.data.SPECIALTY;

        let address1 = this.props.data.ADDRESS1 !== null && this.props.data.ADDRESS1 !== undefined ? `${firstCapital(this.props.data.ADDRESS1)}, ` : "";
        let address2 = this.props.data.ADDRESS2 !== null && this.props.data.ADDRESS2 !== undefined ? `${firstCapital(this.props.data.ADDRESS2)}, ` : "";
        let countryCODE = this.props.data.COUNTRY_CODE !== null && this.props.data.COUNTRY_CODE !== undefined ? ` ${CountryLookUp[this.props.data.COUNTRY_CODE]}, ` : "";
        let city = this.props.data.CITY !== null && this.props.data.CITY !== undefined ? ` ${firstCapital(this.props.data.CITY)}, ` : "";
        let state = this.props.data.STATE !== null && this.props.data.STATE !== undefined ? ` ${this.props.data.STATE.replace(this.props.data.COUNTRY_CODE, '')} ` : "";
        let zip = this.props.data.POSTAL_CODE !== null && this.props.data.POSTAL_CODE !== undefined ? ` ${this.props.data.POSTAL_CODE}` : "";

        if (state.includes('..')) {
            state = "";
        }


        id = this.props.data.PROF_ID;

        let aggspendActive = hashes.spend[id] || this.props.data.AGGSPEND_FLAG === "Y";
        let aggspendToggle = (
            <Toggle
                active={aggspendActive}
                click={() => {
                    this.props.addTo(this.props.number, 0, aggspendActive);
                    this.props.updateNotification(aggspendActive, "Spend");
                }}
                disabled={aggspendActive}
                errorCallback={() => {
                    this.props.updateError(aggspendActive, "Spend", id);
                }}
                error={this.props.errorByID === id && this.props.notificationType === "Spend"}
            />
        );


        let showDetails = false;
        let isAdmin = ["ADMIN"].includes(this.props.user.role);
        let address = address1 + address2 + city + state  + " " + countryCODE + " " + zip;
        id = this.props.data.PROF_ID;

      
        let isPRT = ['PRT'].includes(this.props.user.role)
        let isCommDerm = ['COMMERCIAL DERM'].includes(this.props.user.role)
        let regex = new RegExp(`^${this.props.user.terr}`, 'g')
        let matchesTerritory = this.props.data.TERR_ID ? this.props.data.TERR_ID.match(regex) : false;

       

        let universeFlag = this.props.data.UNIVERSE_FLAG === "Y";
        let teamUniverseFlag = this.props.data[`UNIVERSE_FLAG_TM${this.props.user.team}`] === "Y";

        let crmActive = false;
        let isDisabled = !isAdmin && !matchesTerritory && !isPRT && !isCommDerm;
        let isGlobal = !isAdmin && !matchesTerritory && teamUniverseFlag ;

        if (this.props.user.isDermAdmin == 'true' || this.props.user.role == 'COMMERCIAL DERM' || this.props.user.role == 'PRT') {
            crmActive = hashes.crm[id] !== undefined ? hashes.crm[id] : teamUniverseFlag;
            if (teamUniverseFlag == true) { isGlobal = false; }
        } else {
            crmActive = hashes.crm[id] !== undefined ? hashes.crm[id] : ((isAdmin && universeFlag) || teamUniverseFlag);
        }

        let hasAffiliations = this.props.data.AFFILIATION_COUNT > 0;
        //   let sameTeam = this.props.data[`TARGET_FLAG_TM${this.props.user.team}`] === "Y";

        let crmToggle = (
            <Toggle
                active={crmActive}
                click={() => {
                    if (crmActive === true && hasAffiliations) {
                        this.setState({
                            showWarning: true,
                            crmActiveState: crmActive,
                            aggActive: aggspendActive
                        });
                    }
                    else {
                        this.props.addTo(this.props.number, 1, crmActive, !aggspendActive ? true : false);
                        this.props.updateNotification(crmActive, !aggspendActive ? "BOTH" : "CRM");
                    }

                }}
                overRide={false}
                disabled={isDisabled}
                global={isGlobal}
                errorCallback={() => {
                    this.props.updateError(crmActive, "CRM", id);
                }}
                error={this.props.errorByID === id && this.props.notificationType === "CRM"}
            />
        );

        let previewName = (<div className={styles.Name} onClick={() => {this.props.display(); }}>{name.toUpperCase()}</div>);
        let detailsName = (<Link to={{pathname: `xus/details/${this.props.data.PROF_ID}`,state: {from: this.props.type,},}}><div className={styles.Name}>{name}</div></Link>);


        return (
            <div >
                <div
                    className={this.state.odd ? (this.state.active ? [styles.Result, styles.Odd, styles.Active].join(" ") : [styles.Result, styles.Odd].join(" ")) : this.state.active ? [styles.Result, styles.Active].join(" ") : styles.Result}>
                    <div className={styles.Status}>
                        {this.props.data.STATUS === null || this.props.data.ORG_STATUS === null ?
                            <i className="fas fa-check-circle" id={styles.StatusActive}></i> :
                            <i className="fas fa-check-circle" id={styles.StatusActive}></i>}
                    </div>

                    <div onClick={this.props.deactivate} className={styles.Info}>

                        {/*<div className={styles.Name} onClick={() => {*/}



                        {/*if (this.state.show_details == true) {*/}
                        {/*        this.props.showDetails(this.props.data.PROF_ID);*/}
                        {/*} else {*/}
                        {/*        this.props.display();*/}
        
                        {/*}*/}

                        {/*}}>{name.toUpperCase()}</div>*/}

                        <div onClick={this.props.deactivate} className={styles.Info}>

                            
                            {this.state.show_details == true ? detailsName : previewName}
                              

                            
                           
                        </div>
                           
                            <div className={styles.Address}>{address}</div>
                     

                    </div>
                    <div
                        className={styles.Preview}
                        onClick={() => {
                            this.props.display();
                        }}
                    >
                        <i id={this.state.preview ? styles.Eye : styles.whiteEye} className="fas fa-eye"></i>
                    </div>
                    <div className={styles.Degree}>
                        {degree}
                    </div>
                    <div className={styles.Specialty}>
                        {specialty}
                    </div>
                    <div onClick={this.props.deactivate} className={styles.CRM}>
                        {crmToggle}
                    </div>
                    <div onClick={this.props.deactivate} className={styles.Spend}>
                        {aggspendToggle}
                    </div>
                </div>
            </div>
        );
    }
}

export default Result;
