import React, { Component } from 'react';
import styles from '../css/AddHCP_P1.module.css';
import DegreeLookUp from '../../../globalcomponents/DegreeLookUp';
import SpecialtyLookUp from '../../../globalcomponents/SpecialtyLookUp';
import SpecialtyLookUpDerm from '../../../globalcomponents/SpecialtyLookUpDerm';
import validateRequestNotes from '../../../helper/validateRequestNotes';

class AddHCP_P1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            FIRST_NAME: "",
            MIDDLE_NAME: "",
            LAST_NAME: "",
            SUFFIX: "",
            NPI_NUMBER: "",
            EMAIL: "",
            SPECIALTY: "",
            DEGREE: "",
            REQUEST_TYPE: "",
            REQUEST_NOTE: "",
            ERROR_MESSAGE: "",
            ISVALID: false
        };
    }

    componentDidMount() {
        let temp = {}
        for (let i = 0; i < Object.keys(this.props.info).length; i++) {
            if (Object.keys(this.props.info)[i]) {
                temp[Object.keys(this.props.info)[i]] = this.props.info[Object.keys(this.props.info)[i]]
            }
        }
        this.setState(temp)
    }

    isValidEmail(email) {
    var isValid = false;
    if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email) || (email === undefined || email === "")) {
        isValid = true;
        };
    return isValid;
    }

    render() {

        let DegreeOptions = []
        let SpecialtyOptions = []
        for (let i = 0; i < Object.keys(DegreeLookUp).length; i++) {
            DegreeOptions.push(<option key={i} value={Object.keys(DegreeLookUp)[i]}>{Object.keys(DegreeLookUp)[i]} - {Object.values(DegreeLookUp)[i]}</option>)
        }

        if (["DTS", "DBD", "PRT", "COMMERCIAL DERM","PHARMFORCE"].includes(this.props.user.role) || this.props.user.isDermAdmin == 'true' ||  ["31", "53"].includes(this.props.user.team)) {
            for (let i = 0; i < Object.keys(SpecialtyLookUpDerm).length; i++) {
                SpecialtyOptions.push(<option key={i} value={Object.keys(SpecialtyLookUpDerm)[i]}>{Object.keys(SpecialtyLookUpDerm)[i]} - {Object.values(SpecialtyLookUpDerm)[i]}</option>)
            }
        }
        else {
            for (let i = 0; i < Object.keys(SpecialtyLookUp).length; i++) {
                SpecialtyOptions.push(<option key={i} value={Object.keys(SpecialtyLookUp)[i]}>{Object.keys(SpecialtyLookUp)[i]} - {Object.values(SpecialtyLookUp)[i]}</option>)
            }
        }

        let reqReasons = []
        if (["OCNE", "NAM", "SA NAM", "DTS", "DBD"].includes(this.props.user.role) || ["11", "12",  "21", "31", "51", "53"].includes(this.props.user.team)) {
            reqReasons = [<option key={0} value="New HCP Add - CRM">CRM & Spend</option>,
                          <option key={1} value="New HCP Add - Spend">Spend</option>]
        }
        else if (["DEVELOPMENT OPERATIONS-CTMS", "COMPLIANCE"].includes(this.props.user.role)) {
            reqReasons = [<option key={0} value="New HCP Add - CTMS">CTMS</option>,
                          <option key={1} value="New HCP Add - Spend">Spend</option>]
        }
        else if (["ADMIN"].includes(this.props.user.role)) {
            reqReasons = [<option key={0} value="New HCP Add - CRM">CRM & Spend</option>,
                          <option key={1} value="New HCP Add - CTMS">CTMS</option>,
                          <option key={2} value="New HCP Add - Spend">Spend</option>]
        }
        else if (["PRT", "COMMERCIAL DERM","PHARMFORCE"].includes(this.props.user.role) || this.props.user.isDermAdmin == 'true') {
            reqReasons = [<option key={0} value="New HCP Add - Spend">Spend</option>]
        }
        else {
            reqReasons = [<option key={0} value="New HCP Add - Spend">Spend</option>]
        }
        if (["PHARMFORCE"].includes(this.props.user.role)) {
            reqReasons = [<option key={0} value="New HCP Add - Spend">Spend</option>]
        }
        return (
            <div className={styles.editScreen}>
                <div className={styles.wrapper}>
                    <div className={styles.title}>
                        <div></div>
                        <div className={styles.TitleText}>Add Professional</div>
                        <div className={styles.close} onClick={() => {
                            this.props.close()
                        }}><i className="fas fa-times"></i></div>
                    </div>
                    <div className={styles.body}>
                        <div className={styles.required}>{this.state.ERROR_MESSAGE}</div>
                        <div className={styles.required}>REQUIRED FIELD<span className={styles.required}>*</span></div>
                        <div className={styles.toprow}>
                            <div className={styles.set}>
                                <div className={styles.inputTitle}>FIRST NAME<div className={styles.required}>*</div></div>
                                <input className={this.state.ISVALID ? styles.DefaultInputForm : styles.ErrorInputForm} value={this.state.FIRST_NAME !== "" ? this.state.FIRST_NAME : ""} onChange={(event) => {
                                    if (event.target.value === "") {
                                        this.setState({ FIRST_NAME: "" })
                                    }
                                    else {
                                        this.setState({ FIRST_NAME: event.target.value.toUpperCase() })
                                    }
                                }} />
                            </div>
                            <div className={styles.set}>
                                <div>MIDDLE NAME</div>
                                <input className={styles.DefaultInputForm} value={this.state.MIDDLE_NAME !== "" ? this.state.MIDDLE_NAME : ""} onChange={(event) => {
                                    if (event.target.value === "") {
                                        this.setState({ MIDDLE_NAME: "" })
                                    }
                                    else {
                                        this.setState({ MIDDLE_NAME: event.target.value.toUpperCase() })
                                    }
                                }} />
                            </div>
                            <div className={styles.set}>
                                <div className={styles.inputTitle}>LAST NAME  <div className={styles.required}>*</div></div>
                                <input className={this.state.ISVALID ? styles.DefaultInputForm : styles.ErrorInputForm} value={this.state.LAST_NAME !== "" ? this.state.LAST_NAME : ""} onChange={(event) => {
                                    if (event.target.value === "") {
                                        this.setState({ LAST_NAME: "" })
                                    }
                                    else {
                                        this.setState({ LAST_NAME: event.target.value.toUpperCase() })
                                    }
                                }} />
                            </div>
                            <div className={styles.set}>
                                <div className={styles.inputTitle}>SUFFIX</div>
                                <input className={styles.DefaultInputForm} value={this.state.SUFFIX !== "" ? this.state.SUFFIX : ""} onChange={(event) => {
                                    if (event.target.value === "") {
                                        this.setState({ SUFFIX: "" })
                                    }
                                    else {
                                        this.setState({ SUFFIX: event.target.value.toUpperCase() })
                                    }
                                }} />
                            </div>
                        </div>

                        <div className={styles.middlerow}>
                            <div className={styles.set}>
                                <div className={styles.inputTitle}>NPI</div>
                                <input className={styles.DefaultInputForm} value={this.state.NPI_NUMBER !== "" ? this.state.NPI_NUMBER : ""} onChange={(event) => {
                                    if (event.target.value === "") {
                                        this.setState({ NPI_NUMBER: "" })
                                    }
                                    else {
                                        this.setState({ NPI_NUMBER: event.target.value })
                                    }
                                }} />
                            </div>
                            <div className={styles.set}>
                                <div className={styles.inputTitle}>EMAIL</div>
                                <input type="email" className={styles.DefaultInputForm} value={this.state.EMAIL !== "" ? this.state.EMAIL : ""} onChange={(event) => {
                                    if (event.target.value === "") {
                                        this.setState({ EMAIL: "" })
                                    }
                                    else {
                                        this.setState({ EMAIL: event.target.value })
                                    }
                                }} />
                            </div>
                            <div className={styles.set}>
                                <div className={styles.inputTitle}>SPECIALTY<div className={styles.required}>*</div></div>
                                <select className={this.state.ISVALID ? styles.DefaultInputForm : styles.ErrorInputForm} value={this.state.SPECIALTY !== "" ? this.state.SPECIALTY : ""} onChange={(event) => {
                                    if (event.target.value === "") {
                                        this.setState({ SPECIALTY: "" })
                                    }
                                    else {
                                        this.setState({ SPECIALTY: event.target.value })
                                    }
                                }}>
                                    <option value=""></option>
                                    {SpecialtyOptions}
                                </select>
                            </div>
                            <div className={styles.set}>
                                <div className={styles.inputTitle}>DEGREE  <div className={styles.required}>*</div></div>
                                <select className={this.state.ISVALID ? styles.DefaultInputForm : styles.ErrorInputForm} value={this.state.DEGREE !== "" ? this.state.DEGREE : ""} onChange={(event) => {
                                    if (event.target.value === "") {
                                        this.setState({ DEGREE: "" })
                                    }
                                    else {
                                        this.setState({ DEGREE: event.target.value })
                                    }
                                }}>
                                    <option value=""></option>
                                    {DegreeOptions}
                                </select>
                            </div>
                            <div className={styles.set}>
                                <div className={styles.inputTitle}>ADD REASON <div className={styles.required}>*</div></div>
                                <select className={this.state.ISVALID ? styles.DefaultInputForm : styles.ErrorInputForm} value={this.state.REQUEST_TYPE !== "" ? this.state.REQUEST_TYPE : ""} onChange={(event) => {
                                    if (event.target.value === "") {
                                        this.setState({ REQUEST_TYPE: "" })
                                    }
                                    else {
                                        this.setState({ REQUEST_TYPE: event.target.value })
                                    }
                                }}>
                                    <option value=""></option>
                                    {reqReasons}
                                </select>
                            </div>
                            <div className={styles.set}>
                                <div className={styles.inputTitle}>COMMENTS</div>
                                <textarea className={styles.DefaultInputForm} value={this.state.REQUEST_NOTE !== "" ? this.state.REQUEST_NOTE : ""} onChange={(event) => {
                                    if (event.target.value === "") {
                                        this.setState({ REQUEST_NOTE: "" })
                                    }
                                    else {
                                        this.setState({ REQUEST_NOTE: validateRequestNotes(event.target.value) });
                                    }
                                }} />
                            </div>

                        </div>

                        <div className={styles.buttonRow}>
                            <div id={styles.cancel} onClick={() => {
                                this.props.close();
                            }}>CANCEL</div>
                            <div className={this.state.Wiggle && this.state.Wiggle !== undefined ? styles.Wiggle : ""} id={styles.submit} onClick={() => {
                                if (this.state.FIRST_NAME !== "" && this.state.LAST_NAME !== "" && this.state.SPECIALTY !== "" && this.state.DEGREE !== "" && this.state.REQUEST_TYPE !== "") {
                                    let temp = this.state

                                    delete temp.ISVALID
                                    if (temp.REQUEST_TYPE === "New HCP Add - CRM") {
                                        temp.REQUEST_REASON = 0
                                    }
                                    if (temp.REQUEST_TYPE === "New HCP Add - Spend") {
                                        temp.REQUEST_REASON = 1
                                    }
                                    if (temp.REQUEST_TYPE === "New HCP Add - CTMS") {
                                        temp.REQUEST_REASON = 2
                                    }
                                    if (this.state.FIRST_NAME.length > 50) {
                                        this.setState({ ERROR_MESSAGE: 'First name should be less than 50 characters \n' });
                                    }
                                    else if (this.state.FIRST_NAME.includes("\t")) {
                                        this.setState({ ERROR_MESSAGE: 'First name contains tab space characters. Please remove the tab space characters \n' });
                                    }
                                    else if (this.state.FIRST_NAME.includes("\r\n")) {
                                        this.setState({ ERROR_MESSAGE: 'First name contains line feed characters. Please remove the line feed characters \n' });
                                    }
                                    else if (this.state.LAST_NAME.length > 50) {
                                        this.setState({ ERROR_MESSAGE: 'Last name should be less than 50 characters \n' });
                                    }
                                    else if (this.state.LAST_NAME.includes("\t")) {
                                        this.setState({ ERROR_MESSAGE: 'Last name contains tab space characters. Please remove the tab space characters \n' });
                                    }
                                    else if (this.state.LAST_NAME.includes("\r\n")) {
                                        this.setState({ ERROR_MESSAGE: 'Last name contains line feed characters. Please remove the line feed characters \n' });
                                    }
                                    else if (this.state.MIDDLE_NAME.length > 50) {
                                        this.setState({ ERROR_MESSAGE: 'Middle name should be less than 50 characters \n' });
                                    }
                                    else if (this.state.MIDDLE_NAME.includes("\t")) {
                                        this.setState({ ERROR_MESSAGE: 'Middle name contains tab space characters. Please remove the tab space characters \n' });
                                    }
                                    else if (this.state.MIDDLE_NAME.includes("\r\n")) {
                                        this.setState({ ERROR_MESSAGE: 'Middle name contains line feed characters. Please remove the line feed characters \n' });
                                    }
                                    else if (this.state.EMAIL !== "") {
                                        if (!this.isValidEmail(this.state.EMAIL)) {
                                            this.setState({ ERROR_MESSAGE: 'Please enter a valid email address. A valid email address must contain an \'@\' symbol.\n' });
                                        }
                                        else {
                                            this.props.nextPage(1, temp);
                                        }
                                    }
                                    else {
                                        this.props.nextPage(1, temp);
                                    }
                                }                                     
                                
                                else {
                                    this.setState({
                                        Wiggle: true,
                                        ISVALID: false
                                    })
                                    setTimeout(() => {
                                        this.setState({ Wiggle: false })
                                    }, 500);
                                }
                            }}>NEXT</div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

}

export default AddHCP_P1;