import React, {Component} from 'react';
import styles from '../css/AdminRegionalAdd.module.css';
import Axios from '../../../components/Axios';

class AdminRegionalAdd extends Component {
    constructor(props) {
        super(props);
        if (!props.data) {
            this.state = {
                NAME: '',
                TEAM: '',
                START_DATE: '',
                END_DATE: '',
                ISVALID: true
            }
        }
        else {
            this.state = {
                NAME: props.data.NAME,
                TEAM: props.data.TEAM,
                START_DATE: props.data.START_DATE,
                END_DATE: props.data.END_DATE,
                REGIONAL_PLAN_ID: props.data.REGIONAL_PLAN_ID,
                ISVALID: true
            }
        }
    }

    addExercise() {
        let temp = {}
        temp.state = this.state
        delete temp.ISVALID
        if (!this.props.data) {
            // temp.state.END_DATE = temp.state.endMili
            // temp.state.START_DATE = temp.state.startMili
            // delete temp.state.endMili
            // delete temp.state.startMili
            //console.log(temp);
            Axios('/api/admin/add-regional-plan', temp, (data) => {
                this.props.setData(data);
            });
        }
        else {
            Axios('/api/admin/update-regional-plan', temp, (data) => {
                this.props.setData(data);
            });
        }
        this.props.close()
    }

    render() {
        return (
            <div className={styles.editScreen}>
                <div className={styles.title}>
                    <div></div>
                    <div className={styles.TitleText}>Add Exercise</div>
                    <div className={styles.close} onClick={() => {
                        this.props.close()
                    }}><i className="fas fa-times"></i></div>
                </div>
                <form>
                    <div className={styles.body}>
                        <div className={styles.required}>REQUIRED FIELD *</div>
                        <div className={styles.toprow}>
                            <div className={styles.set}>
                                <div className={styles.inputTitle}>NAME <div className={styles.required}>*</div></div>
                                <input type="text"
                                    className={this.state.ISVALID ? styles.DefaultInputForm: styles.ErrorInputForm}
                                    value={this.state.NAME}
                                    onChange={(event) => {this.setState({NAME: event.target.value})}}
                                autoFocus/>
                            </div>
                            <div className={styles.set}>
                                <div className={styles.inputTitle}>MSL TEAM <div className={styles.required}>*</div></div>
                                    <select type="text"
                                        className={this.state.ISVALID ? styles.DefaultInputForm: styles.ErrorInputForm}
                                        value={this.state.TEAM}
                                        onChange={(event) => {this.setState({TEAM: event.target.value})}}>
                                        <option value=""></option>
                                        <option value="51">51</option>
                                    </select>
                            </div>
                            <div className={styles.set}>
                                <div className={styles.inputTitle}>START DATE <div className={styles.required}>*</div></div>
                                <input type="date"
                                    className={this.state.ISVALID ? styles.DefaultInputForm: styles.ErrorInputForm}
                                    value={this.state.START_DATE}
                                    onChange={(event) => {
                                        // let date = Date.parse(event.target.value)
                                        this.setState({
                                            START_DATE: event.target.value
                                        })
                                    }}/>
                            </div>
                            <div className={styles.set}>
                                <div className={styles.inputTitle}>END DATE <div className={styles.required}>*</div></div>
                                <input type="date"
                                    className={this.state.ISVALID ? styles.DefaultInputForm: styles.ErrorInputForm}
                                    value={this.state.END_DATE}
                                    onChange={(event) => {
                                        // let date = Date.parse(event.target.value)
                                        this.setState({
                                            END_DATE: event.target.value
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className={styles.buttonRow}>
                            <div id={styles.cancel}
                                onClick={() => {
                                    this.props.close()
                                }
                            }>CANCEL</div>
                            <div type="submit"
                                id={styles.submit}
                                className={this.state.Wiggle && this.state.Wiggle !== undefined ? styles.Wiggle : ""}
                                onClick={() => {
                                    if (this.state.NAME !== '' && this.state.TEAM !== '' && this.state.START_DATE !== '' && this.state.END_DATE !== '') {
                                        this.addExercise()
                                    }
                                    else {
                                        this.setState({
                                            Wiggle: true,
                                            ISVALID: false,
                                            ERROR_MESSAGE: 'REQUIRED FIELD *'
                                        });
                                        setTimeout(() => {
                                            this.setState({Wiggle: false})
                                        }, 500);
                                    }
                                }
                            }>SUBMIT</div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }

}

export default AdminRegionalAdd;
