import React from 'react'
import {Link} from 'react-router-dom';
import styles from './TopBar.module.css'

function BackButton(props) {
    let button = ""
    if (props.hasBackButton || (props.backButtonUsers && props.backButtonUsers.includes(props.user.role))) {
        button = (
            <Link to={`${props.url}`}>
                <div className={styles.backButton}>
                    <i className="fas fa-angle-left"></i>
                </div>
            </Link>
        )
    }
    return button
}

export default BackButton