import React, {Component} from 'react'
import Styles from './HcpDetails.module.css'
import Axios from '../../components/Axios/Axios'
import Loading from '../../components/Loading/Loading'
import DetailsInformation from './components/Details_Information'
//import DetailsPriAddress from './components/Details_PriAddress';
import AddressAlignment from "./components/address-alignment/AddressAlignment";
import DetailsAffiliations from './components/Details_Affiliations'
import AddressHistory from './components/Details_Address_History'
import SlnHistory from './components/Details_SLN'
import SlnEdit from './components/Details_SLN_Edit'
import DetailsChangeRequests from './components/Details_ChangeRequests'
import DetailsEdit from './components/Details_Edit'
import AddHCPSuccess from './components/AddHcpSuccess'

class HcpDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            spinning: false,
            addslnsuccess: false,
            pathError: false,
            editsubmitted: false
        };
    }

    addTo(index, i, added, addSpend) {
        let json = {}
        if (added) {
            if (i === 0) {
                json.REQUEST_TYPE = "Remove from - Spend";
            } else if (i === 1) {
                json.REQUEST_TYPE = "Remove from - CRM";
            } else if (i === 2) {
                json.REQUEST_TYPE = "Remove from - CTMS";
            }
        } else {
            if (i === 0) {
                json.REQUEST_TYPE = "Add to - Spend";
            } else if (i === 1) {
                json.REQUEST_TYPE = "Add to - CRM";
            } else if (i === 2) {
                json.REQUEST_TYPE = "Add to - CTMS";
            }
        }

        this.props.hash(this.state.data.PROF_ID, i, !added)

        json.PROF_ID = this.state.data.PROF_ID
        json.REQUESTOR_ID = this.props.user.email
        json.REQUESTOR_NAME = this.props.user.userName
        json.REQUESTOR_TEAM = this.props.user.team
        json.REQUESTOR_TERR = this.props.user.terr

        Axios('/api/global/change-request', json, (data) => {
            if (addSpend) {
                let json = {}
                json.REQUEST_TYPE = "Add to - Spend";
                json.PROF_ID = this.state.data.PROF_ID
                json.REQUESTOR_ID = this.props.user.email
                json.REQUESTOR_NAME = this.props.user.userName
                json.REQUESTOR_TEAM = this.props.user.team
                json.REQUESTOR_TERR = this.props.user.terr
                this.props.hash(this.state.data.PROF_ID, 0, !added)
                Axios('/api/global/change-request', json, (data) => {})
            }
        })
    }

    addSLN(data) {
        let json = {}

        json.PROF_ID = this.state.data.PROF_ID
        json.ADDRESS1 = data.ADDRESS1
        json.ADDRESS2 = data.ADDRESS2
        json.ZIPCODE = data.ZIP
        json.CITY = data.CITY
        json.REQUESTOR_ID = this.props.user.email
        json.REQUESTOR_NAME = this.props.user.userName
        json.REQUESTOR_TEAM = this.props.user.team
        json.REQUESTOR_TERR = this.props.user.terr
        json.REQUEST_TYPE = "Add to - SLN"; 
        json.DEGREE = data.DEGREE; 
        //json.SLN_EXPIRATION_DATE = data.EXP; 
        json.SLN_EXPIRATION = data.EXP; 
        json.SLN_NUMBER = data.LICENSE; 
        json.STATUS = data.STATUS;
        json.STATE = data.STATE;
        json.LIC_STATE = data.LIC_STATE;
        json.SLNOVERALLSAMPLEABILITY = data.OVERALL_SAMPLEABILITY;
        json.SLN_REASON = data.SLN_REASON;

        Axios('/api/global/change-request', json, (data) => {

            this.setState({
                editScreen: undefined,
                addslnsuccess: true
            })


        })
    }

    closeEdit() {
        this.setState({editScreen: undefined})
    }

    refresh(data) {
        let temp = this.state.data;
        let keys = Object.keys(data[0])
        for (let i = 0; i < Object.keys(data[0]).length; i++) {
            if (data[0][keys[i]] !== null) {
                temp[keys[i]] = data[0][keys[i]]
            }
        }
        this.setState({data: temp})
    }

    submitEdit(json, list) {

        if (this.state.editsubmitted === true) { return; }

        this.setState({editsubmitted: true})
        if (!list) {
            json.PROF_ID = this.state.data.PROF_ID
            json.REQUESTOR_ID = this.props.user.email
            json.REQUESTOR_NAME = this.props.user.userName
            json.REQUESTOR_TEAM = this.props.user.team
            json.REQUESTOR_TERR = this.props.user.terr
        }
        Axios('/api/global/change-request', json, (data) => {

            //console.log("show success");
            this.editScreen(11);
            this.setChange(data);
        })
    }

    editScreen(i, id) {

       // console.log("id= " + id + " i= " + i);

        this.setState({
            editScreen: <DetailsEdit
                editScreen={this.editScreen.bind(this)}
                affiliations={this.state.affiliations}
                data={this.state.data}
                submit={this.submitEdit.bind(this)}
                change={this.state.change}
                addresses={this.state.addresses}
                close={this.closeEdit.bind(this)}
                id={id}
                index={i}
                user={this.props.user}
                msl={this.state.msl}
                setChange={this.setChange.bind(this)}
                updateChanges={this.updateChanges.bind(this)}
            />
        })


    }

    editScreenChangeRequest(i, data) {
        this.setState({
            editScreen: <DetailsEdit
                editScreen={this.editScreen.bind(this)}
                affiliations={this.state.affiliations}
                data={this.state.data}
                submit={this.submitEdit.bind(this)}
                change={data}
                addresses={this.state.addresses}
                close={this.closeEdit.bind(this)}
                index={i}
                user={this.props.user}
                msl={this.state.msl}
                setChange={this.setChange.bind(this)}
                updateChanges={this.updateChanges.bind(this)}
            />
        })
    }

    componentDidMount() {
        this.loadChange();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data !== this.props.data || prevProps.hashes !== this.props.hashes) {
            this.loadChange();
        }
    }

    loadChange() {

        var pid = this.props.match.params.PROF_ID;

        let json = {
            PROF_ID: this.props.match.params.PROF_ID,
            TEAM: this.props.user.role == 'PRT' || this.props.user.role == 'COMMERCIAL DERM' || this.props.user.isDermAdmin == 'true' ? '31' : this.props.user.team 
        }
        Axios('/api/details/hcp', json, (results) => {
            if (this.props.hashes !== undefined) {
                let profID = results.data[0].PROF_ID
                                
                results.data[0].ctms = this.props.hashes.ctms[profID]
                results.data[0].crm = this.props.hashes.crm[profID]
                results.data[0].spend = this.props.hashes.spend[profID]
            }
            if (results.data[0].PROF_ID != pid) {

                this.setState({
                    pathError: true
                })

                console.log('ProfID = ' + results.data[0].PROF_ID + ' query string = ' + pid)

            } else {
                this.setData(results)
            }
            
        })
       
    }

    setData(newData) {

        let addresses = [newData.primaryAddress, newData.bestAddress, newData.secondaryAddresses, newData.addresses]
        let affiliations = newData.affiliations
        let change = []
        let sln = []
        let msl = {}
        let ctmsAddress = newData.ctmsAddress;
        let degree = newData.data[0].DEGREE;

        for (let i = 0; i < addresses.length; i++) {
            if (addresses[i] === null) {
                addresses[i] = []
            }
        }

        if (newData.affiliations) {
            affiliations = newData.affiliations
        }
        else {
            affiliations = []
        }

        if (newData.changeRequests) {
            change = newData.changeRequests
        }
        else {
            change = []
        }

        if (newData.regionalPlan && newData.regionalPlan !== null) {
            for (let i = 0; i < newData.regionalPlan.length; i++) {
                msl[newData.regionalPlan[i].ATTR_NAME] = newData.regionalPlan[i].ATTR_VALUE
            }
        }

        if (newData.slnHistory) {
            sln = newData.slnHistory
        } else {
            sln = []
        }

        this.setState({
            addresses: addresses,
            affiliations: affiliations,
            change: change,
            degree: degree,
            msl: msl,
            sln: sln,
            ctmsAddress: ctmsAddress,
            loaded: true,
            data: newData.data[0]
        })

    }

    setChange() {
        Axios('/api/details/hcp/change-requests', {PROF_ID: this.state.data.PROF_ID}, (data) => {
            if (this.state.editScreen) {
                this.editScreen(11)
            }
            this.setState({
                change: data
            })
        })
    }

    updateChanges() {
        this.loadChange()
    }

    close() {
        this.setState({changeViewValue: -1})
    }

    openSLNEdit() {

        this.setState({
            editScreen: <SlnEdit close={this.closeSlnEdit.bind(this)} degree={this.state.degree} address={this.state.addresses[0]} addSln={this.addSLN.bind(this)} />
        })
    }
    closeSlnEdit() {
        this.setState({
            editScreen: undefined
        })
    }
    closeSlnSuccess() {
        this.setState({
            successScreen: undefined,
            addslnsuccess: false
        })
    }
    
    render() {
        let successDialog = this.state.addslnsuccess? (<AddHCPSuccess close={this.closeSlnSuccess.bind(this)} />) : ""

       

        if (this.state.loaded) {
            return (
                <div className={Styles.Details}>
                    {this.state.editScreen ? this.state.editScreen : ""}
                    <div className={Styles.TopBar}>
                        <div className={Styles.Back} onClick={() => {
                                if (this.props.history.location.state !== undefined && this.props.history.location.state.from === '/hcp') {
                                    this.props.history.push({
                                        pathname: '/hcp',
                                        state: {
                                            from: '/hcp/details'
                                        }
                                    })
                                }
                                else {
                                    if (document.referrer !== window.location.origin) {
                                        window.location.href = "/";
                                    }
                                    else {
                                        this.props.history.go(-1)
                                    }
                                }
                            }}><i className="fas fa-angle-left"></i>BACK TO SEARCH</div>
                    </div>
                    <div className={Styles.pageWrapper}>
                        <div className={Styles.Wrapper}>
                            {this.state.data !== undefined ? <DetailsInformation editScreen={this.editScreen.bind(this)} submitEdit={this.submitEdit.bind(this)} user={this.props.user} data={this.state.data} loadChange={this.loadChange.bind(this)} number={this.props.number} addresses={this.state.addresses} msl={this.state.msl} ctmsAddress={this.state.ctmsAddress} addTo={this.addTo.bind(this)}/> : ""}
                        </div>
                        <div className={Styles.Wrapper}>
                            <AddressAlignment/>
                        </div>
                        <div className={Styles.Wrapper}>
                            <AddressHistory addresses={this.state.addresses} />
                        </div>
                        <div className={Styles.Wrapper}>
                            <SlnHistory slnHistory={this.state.sln} addSLN={this.openSLNEdit.bind(this)} user={this.props.user} />
                        </div>
                        <div className={Styles.Wrapper}>
                            <DetailsAffiliations editScreen={this.editScreen.bind(this)} user={this.props.user} data={this.state.affiliations}/>
                        </div>
                        <div className={Styles.Wrapper}>
                            <DetailsChangeRequests data={this.state.change} prev={this.props.data} editScreen={this.editScreenChangeRequest.bind(this)}/>
                        </div>
                    </div>
                    {successDialog}
                </div>
            );

        }
        else {
            if (this.state.pathError) {
                return (<div ><br/>&nbsp;&nbsp;Error loading data.  Please refresh the page.</div>)
            } else {
                return (<Loading />)
            }
            
        }
    }
}

export default HcpDetails;
