import React, {useState} from 'react'
import styles from '../css/Review.module.css'
import ColumnEditBox from './ColumnEditBox'


function Review(props) {
    const [wiggle, setWiggle] = useState(false)
    const [valid, setValid] = useState(true)
    let columnEditBoxes = []
    for (let i = 0; i < Object.keys(props.defaultColumns).length; i++) {
        const key = Object.keys(props.defaultColumns)[i]
        columnEditBoxes.push(<ColumnEditBox key={i} data={props.defaultColumns} setData={props.setDefaultColumns} dataKey={key} valid={valid}/>)
    }
    return (
        <div className={styles.editScreen}>
            <div className={styles.title}>
                <div></div>
                <div className={styles.titleText}>Add Exercise - Review</div>
                <div className={styles.close} onClick={() => {
                    props.close()
                }}><i className="fas fa-times"></i></div>
            </div>
            <form>
                <div className={styles.body}>
                    <div className={styles.titleRow}>
                        <div className={styles.side}></div>
                        <div className={styles.directions}>The default columns for this exercise are listed below and toggled on. If you wish to eliminate any of these columns, toggle off. If you wish to customize and add additional columns, select the “add additional columns button.</div>
                        <div className={styles.side}></div>
                    </div>
                    <div className={styles.content}>

                        {columnEditBoxes}
                        
                    </div>
                    <div className={styles.buttonRow}>
                        <div id={styles.cancel}
                            onClick={() => {
                                props.setView(props.view - 1)
                            }
                        }>BACK</div>
                        <div type="submit" id={styles.submit} className={wiggle ? styles.Wiggle : ""} onClick={() => {
                                let checkEach = true
                                for (let i = 0; i < Object.keys(props.defaultColumns).length; i++) {
                                    const element = Object.values(props.defaultColumns)[i];
                                    if (element.title === "" && element.show) {
                                        checkEach = false
                                    }
                                }
                                if (checkEach)  {
                                    props.setView(props.view + 1)
                                }
                                else {
                                    setWiggle(true)
                                    setValid(false)
                                    setTimeout(() => {
                                        setWiggle(false)
                                    }, 500);
                                }
                            }
                        }>CONFIRM</div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Review
