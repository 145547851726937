import React, {useState} from 'react'
import styles from '../css/OrderColumnsReview.module.css'
import ColumnReviewBox from './ColumnReviewBox'

function OrderColumnsReview(props) {
    const [tooMany] = useState(Object.keys(props.order).length > 12 ? true : false)
    const [wiggle] = useState(false)


    let customColumnBoxes = []
    for (let i = 0; i < Object.keys(props.order).length; i++) {
        let temp = props.columns[props.order[i]]
        customColumnBoxes.push(<ColumnReviewBox key={temp.col} data={temp} order={parseInt(Object.keys(props.order)[i]) + 1}/>)
    }

    return (
        <div className={styles.editScreen}>
            <div className={styles.title}>
                <div></div>
                <div className={styles.titleText}>Add Exercise - Confirm Columns</div>
                <div className={styles.close} onClick={() => {
                    props.close()
                }}><i className="fas fa-times"></i></div>
            </div>
            <form>
                <div className={styles.body}>
                    <div className={styles.titleRow}>
                        <div className={styles.side}></div>
                        <div className={styles.directions}>Below is the list of columns you’ve selected in the order that you’ve selected. Once you select “confirm” you will be unable to edit these columns.</div>
                        <div className={styles.side}></div>
                    </div>
                    {tooMany ? <div className={[styles.directions, styles.red].join(" ")}>Warning: You have selected more than 12 columns. Columns will appear very close together.</div> : ""}
                    <div className={styles.content}>
                        {customColumnBoxes}
                    </div>
                    <div className={styles.buttonRow}>
                        <div id={styles.cancel}
                            onClick={() => {
                                props.setView(props.view - 1)
                            }
                        }>BACK</div>
                        <div type="submit" id={styles.submit} className={wiggle ? styles.Wiggle : ""} onClick={() => {
                                props.setView(props.view + 1)
                                props.setSubmitted(true)
                            }
                        }>CONFIRM</div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default OrderColumnsReview
