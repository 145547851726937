import React, {Component} from 'react';
import styles from '../css/AdminRegionalUpdate.module.css';
import Axios from './../../../components/Axios';

class AdminRegionalUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    updateExercise() {
        let temp = {
            REGIONAL_PLAN_ID: this.props.id
        }
        Axios('/api/regional-plan/get-approved', temp, (response) => {
            let index = 0;
            response.forEach(element => {
                let request = {
                    REQUESTOR_ID: this.props.user.email,
                    REQUESTOR_NAME: this.props.user.userName,
                    REQUESTOR_TEAM: this.props.user.team,
                    REQUESTOR_TERR: this.props.user.terr,
                    REQUEST_TYPE: 'Approve Regional Plan Exercise'
                }

                for (let i = 0; i < Object.keys(element).length; i++) {
                    if (Object.values(element)[i] !== null) {
                        if (Object.keys(element)[i] !== 'TOGGLE') {
                            request[Object.keys(element)[i]] = Object.values(element)[i]
                        }
                        else {
                            request.REGIONAL_PLAN_CURR = Object.values(element)[i]
                        }
                    }
                }

                Axios('/api/global/change-request', request, (data) => {
                    index++;
                    if (data !== undefined) {
                        if (index === response.length) {
                            this.props.setViewOn('confirm')
                            this.props.setViewOff('update')
                            this.props.close('update')
                        }
                    }
                });
            });
        });
    }

    render() {
        return (
            <div className={styles.editScreen}>
                <div className={styles.title}>
                    <div></div>
                    <div className={styles.TitleText}>Execute Exercise Updates</div>
                    <div className={styles.close} onClick={() => {
                        this.props.close('update')
                    }}><i className="fas fa-times"></i></div>
                </div>
                <form>
                    <div className={styles.body}>
                        <div className={styles.errorMessage}>{this.state.ERROR_MESSAGE}</div>
                        <div className={styles.toprow}>
                            <div className={styles.set}>
                                <div className={styles.inputTitle}>Are you sure you want to execute the updates from the Regional Plan Exercise?</div>
                            </div>
                        </div>
                        <div className={styles.buttonRow}>
                            <div id={styles.cancel}
                                onClick={() => {
                                    this.props.close('update')
                                }
                            }>CANCEL</div>
                            <div type="submit"
                                id={styles.submit}
                                className={this.state.Wiggle && this.state.Wiggle !== undefined ? styles.Wiggle : ""}
                                onClick={() => {
                                    this.updateExercise()
                                }
                            }>CONFIRM</div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }

}

export default AdminRegionalUpdate;
