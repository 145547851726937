import React from 'react';
import Styles from './YesNoDialogBox.module.css';


export default function YesNoDialogBox(props) {
  return (
    <div className={Styles.editScreen}>
      <div className={Styles.title}>
        <div></div>
              <div className={Styles.TitleText}>{ props.title}</div>
        <div
          className={Styles.close}>
        </div>
      </div>
      <div className={Styles.body}>
       
        <div className={Styles.firstLine}>
                  {props.msgFirstLine}
          <br />
          <div className={Styles.secondLine}>
                      {props.msgSecondLine}
          </div>
         
        </div>
      </div>
          <div className={Styles.buttonRow}>
              <div id={Styles.cancel} onClick={() => {
                  props.leftClick();
              }}>{props.leftButton}</div>
              <div id={Styles.submit} onClick={() => {
                  props.rightClick();
              }}>{props.rightButton}</div>
          </div>
    </div>
  );
}
