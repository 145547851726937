import React from 'react';
import styles from './Pagination.module.css';

export default function Pagination(props) {
    return (
        <div className={styles.pagination}>
            <div className={styles.section}>
                {/*{props.button !== undefined ? (<button className={styles.button} onClick={() => {*/}
                {/*    props.buttonClick()*/}
                {/*}}>{props.buttonIcon ? props.buttonIcon : ''} {props.buttonName}</button>) : ""}*/}
            </div>
            <div className={styles.pages}>
                <button className={styles.arrow} onClick={() => {
                        if (props.currentPage > 0) {
                            if (props.currentPage - 1 < 0) {
                                props.setProperties(0, props.resultsPerPage)
                            }
                            else {
                                props.setProperties(props.currentPage - 1, props.resultsPerPage)
                            }
                        }
                    }}><i className="fas fa-angle-left"></i></button>
                <div>
                    {`${Math.round(props.currentPage * props.resultsPerPage) + 1}-${props.totalResults > ((props.currentPage * props.resultsPerPage) + props.resultsPerPage) ? Math.round((props.currentPage * props.resultsPerPage) + props.resultsPerPage) : props.totalResults}`} of {props.totalResults}
                </div>
                <button className={styles.arrow} onClick={() => {

                   // let x = props.currentPage + 1 < props.totalResults / props.resultsPerPage;
                   // alert('cp=' + props.currentPage + ' R/RPP=' +props.totalResults / props.resultsPerPage);

                    if (props.currentPage < Math.round(props.totalResults / props.resultsPerPage)) {

                            
                            props.setProperties(props.currentPage + 1, props.resultsPerPage)
                    }



                    }}><i className="fas fa-angle-right"></i></button>
            </div>
            <div className={[styles.viewWrapper, styles.section].join(" ")}>
            {/*    <div className={styles.views}>View</div>*/}
            {/*    <div className={props.resultsPerPage === 20 ? [styles.views, styles.active].join(' ') : styles.views} onClick={() => {*/}
            {/*            props.setProperties((props.currentPage * props.resultsPerPage) / 20, 20)*/}
            {/*        }}>20</div>*/}
            {/*    <div className={styles.views}>|</div>*/}
            {/*    <div className={props.resultsPerPage === 40 ? [styles.views, styles.active].join(' ') : styles.views} onClick={() => {*/}
            {/*        props.setProperties((props.currentPage * props.resultsPerPage) / 40, 40)*/}
            {/*        }}>40</div>*/}
            {/*    <div className={styles.views}>|</div>*/}
            {/*    <div className={props.resultsPerPage === 60 ? [styles.views, styles.active].join(' ') : styles.views} onClick={() => {*/}
            {/*        props.setProperties((props.currentPage * props.resultsPerPage) / 60, 60)*/}
            {/*        }}>60</div>*/}
            {/*    <div className={styles.views}>|</div>*/}
            {/*    <div className={props.resultsPerPage === 80 ? [styles.views, styles.active].join(' ') : styles.views} onClick={() => {*/}
            {/*        props.setProperties((props.currentPage * props.resultsPerPage) / 80, 80)*/}
            {/*        }}>80</div>*/}
            {/*    <div className={styles.views}>|</div>*/}
            {/*    <div className={props.resultsPerPage === 100 ? [styles.views, styles.active].join(' ') : styles.views} onClick={() => {*/}
            {/*        props.setProperties((props.currentPage * props.resultsPerPage) / 100, 100)*/}
            {/*        }}>100</div>*/}
           </div>
        </div>
    );
}
