import React, {useState} from 'react'
import Popup from '../../../components/Popup'
import Success from '../../../components/Success'
import Spinner from '../../../components/Spinner'
import Axios from '../../../components/Axios'
import styles from '../css/EditStatus.module.css'
import Input from './Input.js'


function EditStatus(props) {
    const [statusData] = useState(props.statusData)
    const [changes, setChanges] = useState({})
    const [success, setSuccess] = useState(false)
    const [isValid, setIsValid] = useState(true)
    const [wiggle, setWiggle] = useState(false)

    let body = <Spinner />
    let statuses = []
    let descriptions = []

    if (success) {
        return (
            <Success successMessage={"Success! You have successfully updated the Status and Descriptions."} close={props.close} success/>
        )
    }
    else {
        for (let i = 0; i < props.statusData.length; i++) {
            statuses.push(
                <Input disabled={true }  key={props.statusData[i].STATUS_ID} isValid={isValid} changes={changes} setChanges={setChanges} data={props.statusData[i]} type={"STATUS"} />
            )
            descriptions.push(
                <Input key={props.statusData[i].STATUS_ID} isValid={isValid} changes={changes} setChanges={setChanges} data={props.statusData[i]} type={"DESCRIPTION"} />
            )
        }
        body = (
            <div className={"pop-body"}>
                <div className={"pop-titleRow"}>
                    <div className={"pop-directions"}></div>
                </div>
                <div className={"pop-content"} style={{width: "100%"}}>
                    <div className={"flex-row"} style={{justifyContent: "space-evenly"}}>
                        <div className={"flex-col flex-justify-start " + styles.status}>
                            <div className={styles.title}>STATUS</div>
                            {statuses}
                        </div>
                        <div className={"flex-col flex-justify-start " + styles.description}>
                            <div className={styles.title}>DESCRIPTION</div>
                            {descriptions}
                        </div>
                    </div>
                    <div className={"pop-buttonRow"}>
                        <button type="button" id={"pop-cancel"} onClick={() => {
                                props.close()
                            }
                        }>CLOSE</button>
                    <button className={wiggle ? "wiggle" : ""} id={"pop-submit"} onClick={() => {
                            let flag = true
                            for (let i = 0; i < Object.keys(changes).length; i++) {
                                if (Object.values(changes)[i].STATUS === "") {
                                    flag = false
                                }
                                if (Object.values(changes)[i].DESCRIPTION === "") {
                                    flag = false
                                }
                            }
                            if (flag) {
                                
                                Axios('/api/regional-plan/edit-status', {status: changes}, (data) => {
                                    props.refreshStatusData(data)
                                    setSuccess(true)
                                })
                            }
                            else {
                                setWiggle(true)
                                setIsValid(false)
                                setTimeout(() => {
                                    setWiggle(false)
                                }, 500);
                            }
                           
                        }}>SUBMIT</button>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <Popup {...props} title={"Edit Status Language and Descriptions"} body={body}/>
    )

}

export default EditStatus
