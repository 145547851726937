import React, { Component } from "react";
import styles from "../css/Details_Edit_Address.module.css";
import DegreeLookUp from "../../../globalcomponents/DegreeLookUp";
import AddressLookUp from "./../../../globalcomponents/AddressLookUp";
import Axios from "./../../../components/Axios";
import validateRequestNotes from '../../../helper/validateRequestNotes';

class Details_Edit_Address extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Wiggle: false,
            REQUEST_NOTE: null,
            view: 0,
            ADDRESS1: "",
            ADDRESS2: "",
            CITY: "",
            STATE: "",
            ZIPCODE: "",
            ISVALID: true,
            errorMessage: "",
        };
    }

    firstCapital(str) {
        if (str !== undefined) {
            return str.toLowerCase().replace(/\w\S*/g, function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
        }
        return "";
    }

    updateAddress(address) {
        this.setState({
            ADDRESS1: address.streetOne,
            ADDRESS2: address.streetTwo,
            CITY: address.city,
            STATE: address.state,
            ZIPCODE: address.zip,
        });
    }

    render() {

        let isCTMS = (this.props.user.role.includes('DEVELOPMENT OPERATIONS') && this.props.user.role.includes('CTMS'));



        //DEGREE ARRAY
        let DegreeOptions = [];

        for (var i = 0; i < Object.keys(DegreeLookUp).length; i++) {
            DegreeOptions.push(
                <option key={i} value={Object.keys(DegreeLookUp)[i]}>
                    {Object.keys(DegreeLookUp)[i]}
                </option>
            );
        }

        //SEC ADDRESS ARRAY
        let sec = [];

        for (let i = 0; i < this.props.addresses[2].length; i++) {
            sec.push(
                <div
                    key={i}
                    className={this.state.checked === i ? [styles.address, styles.active].join(" ") : styles.address}
                    onClick={() => {
                        this.setState({
                            checked: i,
                        });
                    }}
                >
                    <input type="radio" name="secondary" checked={this.state.checked === i ? true : false} className={styles.radio} />
                    {`${this.props.addresses[2][i].SECONDARY_ADDRESS1}, ${this.props.addresses[2][i].SECONDARY_ADDRESS2 ?? ""} ${this.props.addresses[2][i].SECONDARY_CITY}, ${this.props.addresses[2][i].SECONDARY_STATE} ${this.props.addresses[2][i].SECONDARY_ZIP5}`}
                </div>
            );
        }

        let index = this.props.addresses[2].length;

        //ADDITIONAL ADDRESSES
        for (let i = 0; i < this.props.addresses[3].length; i++) {
            sec.push(
                <div
                    key={i}
                    className={this.state.checked === i + index ? [styles.address, styles.active].join(" ") : styles.address}
                    onClick={() => {
                        //console.log(i + index);
                        this.setState({
                            checked: i + index,
                        });
                    }}
                >
                    <input type="radio" name="secondary" checked={this.state.checked === i + index ? true : false} className={styles.radio} />
                    {`${this.props.addresses[3][i].SRC} - ${this.props.addresses[3][i].ADDRESS1}, ${this.props.addresses[3][i].ADDRESS2 ?? ""} ${this.props.addresses[3][i].CITY}, ${this.props.addresses[3][i].STATE} ${this.props.addresses[3][i].ZIP5}`}
                </div>
            );
        }


        //CHANGE PRIMARY
        let changePri = (
            <div className={styles.body}>
                <div className={styles.changePri}>
                    <div className={styles.menuTitle}>Which address would you like to change to primary?</div>
                    {sec}
                    <div className={styles.submenu}>
                        Don't see the one you're looking for?{" "}
                        <div
                            className={styles.link}
                            onClick={() => {
                                this.setState({
                                    view: 5,
                                });
                            }}
                        >
                            Add a new address
                        </div>
                    </div>
                    <div className={styles.buttonRow}>
                        <div
                            id={styles.cancel}
                            onClick={() => {
                                this.setState({ view: 0 });
                            }}
                        >
                            BACK
                        </div>
                        <div
                            className={this.state.Wiggle && this.state.Wiggle !== undefined ? styles.Wiggle : ""}
                            id={styles.submit}
                            onClick={() => {

                                if (this.state.checked === undefined) {
                                    this.setState({ Wiggle: true, });
                                } else {
                                    this.setState({ view: 2, Wiggle: false });
                                }




                            }}
                        >
                            NEXT
                        </div>
                    </div>
                </div>
            </div>
        );

        let oldPri = (
            <div className={styles.body}>
                <div className={styles.changePri}>
                    <div className={styles.menuTitle}>How would you like to assign the previous primary address?</div>
                    <div
                        className={this.state.doWithOldPri === 0 ? [styles.address, styles.active].join(" ") : styles.address}
                        onClick={() => {
                            this.setState({
                                doWithOldPri: 0,
                            });
                        }}
                    >
                        <input type="radio" name="secondary" checked={this.state.doWithOldPri === 0 ? true : false} className={styles.radio} />
                        Secondary
                    </div>
                    <div
                        className={this.state.doWithOldPri === 1 ? [styles.address, styles.active].join(" ") : styles.address}
                        onClick={() => {
                            this.setState({
                                doWithOldPri: 1,
                            });
                        }}
                    >
                        <input type="radio" name="secondary" checked={this.state.doWithOldPri === 1 ? true : false} className={styles.radio} />
                        Inactive
                    </div>
                    <div className={styles.buttonRow}>
                        <div
                            id={styles.cancel}
                            onClick={() => {
                                this.setState({ view: 1 });
                            }}
                        >
                            BACK
                        </div>
                        <div
                            className={this.state.Wiggle && this.state.Wiggle !== undefined ? styles.Wiggle : ""}
                            id={styles.submit}
                            onClick={() => {

                                let priRequest = {};
                                var checkedIndex = this.state.checked;
                                var oldPriFlag = this.state.doWithOldPri;

                                if (oldPriFlag === undefined) {

                                    this.setState({ Wiggle: true, });

                                    return;
                                }

                                if (this.state.newPriFlag) {
                                    priRequest = {
                                        ADDRESS1: this.state.newPri.ADDRESS1,
                                        ADDRESS2: this.state.newPri.ADDRESS2,
                                        CITY: this.state.newPri.CITY,
                                        STATE: this.state.newPri.STATE,
                                        ZIPCODE: this.state.newPri.ZIPCODE,
                                        REQUEST_TYPE: isCTMS ? `CTMS Set Primary Address` : `Set Primary Address`,
                                        PROF_ID: this.props.data.PROF_ID,
                                        REQUESTOR_ID: this.props.user.email,
                                        REQUESTOR_NAME: this.props.user.userName,
                                        REQUESTOR_TEAM: this.props.user.team,
                                        REQUESTOR_TERR: this.props.user.terr,
                                    };
                                } else {
                                    if (this.state.checked < index) {
                                        priRequest = {
                                            ADDRESS1: this.props.addresses[2][this.state.checked].SECONDARY_ADDRESS1,
                                            ADDRESS2: this.props.addresses[2][this.state.checked].SECONDARY_ADDRESS2,
                                            CITY: this.props.addresses[2][this.state.checked].SECONDARY_CITY,
                                            STATE: this.props.addresses[2][this.state.checked].SECONDARY_STATE,
                                            ZIPCODE: this.props.addresses[2][this.state.checked].SECONDARY_ZIP5,
                                            REQUEST_TYPE: isCTMS ? `CTMS Set Primary Address` : `Set Primary Address`,
                                            PROF_ID: this.props.data.PROF_ID,
                                            REQUESTOR_ID: this.props.user.email,
                                            REQUESTOR_NAME: this.props.user.userName,
                                            REQUESTOR_TEAM: this.props.user.team,
                                            REQUESTOR_TERR: this.props.user.terr,
                                        };
                                    } else {
                                        priRequest = {
                                            ADDRESS1: this.props.addresses[3][this.state.checked - index].ADDRESS1,
                                            ADDRESS2: this.props.addresses[3][this.state.checked - index].ADDRESS2,
                                            CITY: this.props.addresses[3][this.state.checked - index].CITY,
                                            STATE: this.props.addresses[3][this.state.checked - index].STATE,
                                            ZIPCODE: this.props.addresses[3][this.state.checked - index].ZIP5,
                                            REQUEST_TYPE: isCTMS ? `CTMS Set Primary Address` : `Set Primary Address`,
                                            PROF_ID: this.props.data.PROF_ID,
                                            REQUESTOR_ID: this.props.user.email,
                                            REQUESTOR_NAME: this.props.user.userName,
                                            REQUESTOR_TEAM: this.props.user.team,
                                            REQUESTOR_TERR: this.props.user.terr,
                                        };
                                        //console.log(priRequest);
                                    }
                                }

                                if (this.state.doWithOldPri === 0) {
                                    let batch = {
                                        PROF_ID: this.props.data.PROF_ID,
                                        REQUESTOR_ID: this.props.user.email,
                                        REQUESTOR_NAME: this.props.user.userName,
                                        REQUESTOR_TEAM: this.props.user.team,
                                        REQUESTOR_TERR: this.props.user.terr,
                                        REQUEST_TYPE: isCTMS ? "CTMS HCP Update Address" : "HCP Update Address"
                                    };

                                    let secRequest = {
                                        ADDRESS1: this.props.addresses[0][0].PRI_ADDRESS1,
                                        ADDRESS2: this.props.addresses[0][0].PRI_ADDRESS2,
                                        CITY: this.props.addresses[0][0].PRI_CITY,
                                        STATE: this.props.addresses[0][0].PRI_STATE,
                                        ZIPCODE: this.props.addresses[0][0].PRI_ZIP5,
                                        PROF_ID: this.props.data.PROF_ID,
                                        REQUESTOR_ID: this.props.user.email,
                                        REQUESTOR_NAME: this.props.user.userName,
                                        REQUESTOR_TEAM: this.props.user.team,
                                        REQUESTOR_TERR: this.props.user.terr,
                                        REQUEST_TYPE: "Set Secondary Address",
                                    };
                                    //console.log([priRequest, secRequest]);
                                    Axios("/api/global/change-request", batch, (parent) => {
                                        //   priRequest.ACTION_NUM = parent[0][parent.length - 1].ACTION_ID;
                                        //   secRequest.ACTION_NUM = parent[0][parent.length - 1].ACTION_ID;

                                        //  priRequest.ACTION_NUM = parent.ACTION_ID;
                                        //  secRequest.ACTION_NUM = parent.ACTION_ID;

                                        this.props.submit([priRequest, secRequest], true);
                                    });
                                } else {
                                    this.props.submit(priRequest);
                                }
                            }}
                        >
                            SUBMIT
                        </div>
                    </div>
                </div>
            </div>
        );

        let remove = [
            <div
                key={0}
                className={this.state.checked === null ? [styles.address, styles.active].join(" ") : styles.address}
                onClick={() => {
                    this.setState({
                        checked: null,
                    });
                }}
            >
                <input type="radio" name="secondary" checked={this.state.checked === null ? true : false} className={styles.radio} />
                {`${this.props.addresses[0][0].PRI_ADDRESS1}, ${this.props.addresses[0][0].PRI_CITY}, ${this.props.addresses[0][0].PRI_STATE} ${this.props.addresses[0][0].PRI_ZIP5}`}
            </div>,
        ];
        for (let i = 0; i < this.props.addresses[2].length; i++) {
            remove.push(
                <div
                    key={i}
                    className={this.state.checked === i ? [styles.address, styles.active].join(" ") : styles.address}
                    onClick={() => {
                        this.setState({
                            checked: i,
                        });
                    }}
                >
                    <input type="radio" name="secondary" checked={this.state.checked === i ? true : false} className={styles.radio} />
                    {`${this.props.addresses[2][i].SECONDARY_ADDRESS1}, ${this.props.addresses[2][i].SECONDARY_CITY}, ${this.props.addresses[2][i].SECONDARY_STATE} ${this.props.addresses[2][i].SECONDARY_ZIP5}`}
                </div>
            );
        }

        let removeAddress = (
            <div className={styles.body}>
                <div className={styles.changePri}>
                    <div className={styles.menuTitle}>Which address would you like to remove?</div>
                    <div className={styles.required} style={{ margin: "10px" }}>
                        {this.state.checked === null ? "Error: You cannot remove a primary address. If the HCP has moved, submit an address change or move to unknown." : ""}
                    </div>
                    {remove}
                    <div className={styles.buttonRow}>
                        <div
                            id={styles.cancel}
                            onClick={() => {
                                this.setState({ view: 0 });
                            }}
                        >
                            BACK
                        </div>
                        <div
                            className={this.state.Wiggle && this.state.Wiggle !== undefined ? styles.Wiggle : ""}
                            id={styles.submit}
                            onClick={() => {
                                if (this.state.checked !== null && this.props.addresses[2].length > 0) {
                                    let temp = {
                                        ADDRESS1: this.props.addresses[2][this.state.checked].SECONDARY_ADDRESS1,
                                        ADDRESS2: this.props.addresses[2][this.state.checked].SECONDARY_ADDRESS2,
                                        CITY: this.props.addresses[2][this.state.checked].SECONDARY_CITY,
                                        STATE: this.props.addresses[2][this.state.checked].SECONDARY_STATE,
                                        ZIPCODE: this.props.addresses[2][this.state.checked].SECONDARY_ZIP5,
                                        REQUEST_TYPE: "Deactivate Address",
                                    };
                                    this.props.submit(temp);
                                } else {
                                    this.setState({
                                        Wiggle: true,
                                        ISVALID: false,
                                    });
                                    setTimeout(() => {
                                        this.setState({ Wiggle: false });
                                    }, 500);
                                }
                            }}
                        >
                            SUBMIT
                        </div>
                    </div>
                </div>
            </div>
        );

        let addSecondary = (
            <div className={styles.body}>
                <AddressLookUp updateAddress={this.updateAddress.bind(this)} error={this.state.ISVALID} info={this.state} />
                <div className={styles.buttonRow}>
                    <div
                        id={styles.cancel}
                        onClick={() => {
                            this.setState({ view: 0 });
                        }}
                    >
                        BACK
                    </div>
                    <div
                        className={this.state.Wiggle && this.state.Wiggle !== undefined ? styles.Wiggle : ""}
                        id={styles.submit}
                        onClick={() => {
                            if (this.state.ADDRESS1 !== -1 && this.state.CITY !== -1 && this.state.STATE !== -1 && this.state.ZIPCODE !== -1) {
                                let temp = {
                                    ADDRESS1: this.state.ADDRESS1,
                                    ADDRESS2: this.state.ADDRESS2,
                                    CITY: this.state.CITY,
                                    STATE: this.state.STATE,
                                    ZIPCODE: this.state.ZIPCODE,
                                    REQUEST_TYPE: `Add Secondary Address`,
                                };
                                this.props.submit(temp);
                            } else {
                                this.setState({
                                    Wiggle: true,
                                    ISVALID: false,
                                });
                                setTimeout(() => {
                                    this.setState({ Wiggle: false });
                                }, 500);
                            }
                        }}
                    >
                        SUBMIT
                    </div>
                </div>
            </div>
        );

        let newPrimary = (
            <div className={styles.body}>
                <AddressLookUp updateAddress={this.updateAddress.bind(this)} error={this.state.ISVALID} info={this.state} />
                <div className={styles.buttonRow}>
                    <div
                        id={styles.cancel}
                        onClick={() => {
                            this.setState({ view: 0 });
                        }}
                    >
                        BACK
                    </div>
                    <div
                        className={this.state.Wiggle && this.state.Wiggle !== undefined ? styles.Wiggle : ""}
                        id={styles.submit}
                        onClick={() => {
                            if (this.state.ADDRESS1 !== "" && this.state.CITY !== "" && this.state.STATE !== "" && this.state.ZIPCODE.length === 5) {
                                let temp = {
                                    ADDRESS1: this.state.ADDRESS1,
                                    ADDRESS2: this.state.ADDRESS2,
                                    CITY: this.state.CITY,
                                    STATE: this.state.STATE,
                                    ZIPCODE: this.state.ZIPCODE,
                                };
                                this.setState({
                                    newPri: temp,
                                    newPriFlag: true,
                                    view: 2,
                                });
                            } else {
                                this.setState({
                                    Wiggle: true,
                                    ISVALID: false,
                                });
                                setTimeout(() => {
                                    this.setState({ Wiggle: false });
                                }, 500);
                            }
                        }}
                    >
                        SUBMIT
                    </div>
                </div>
            </div>
        );

        let success = (
            <div className={styles.body}>
                <div className={styles.toprow}>
                    <div className={[styles.set, styles.mainTitle].join(" ")}>
                        <div className={styles.titleText}>
                            <i className="far fa-check-circle"></i>
                        </div>
                    </div>
                </div>

                <div className={styles.middlerow}>
                    <div className={styles.set}>
                        <div className={styles.contentTitle}>Thank you!</div>
                        <div className={styles.content}>Your request has been submitted.</div>
                    </div>
                </div>

                <div className={styles.buttonRow}>
                    <div
                        id={styles.cancel}
                        onClick={() => {
                            this.props.close();
                        }}
                    >
                        CLOSE
                    </div>
                </div>
            </div>
        );

        let unkown = (
            <div className={styles.body}>
                <div className={styles.menuTitle}>Move a Professional to unknown</div>
                <div className={styles.set}>
                    <div className={styles.inputTitle}>REVISION REASON</div>
                    <input
                        value={this.state.REQUEST_NOTE ? this.state.REQUEST_NOTE : ""}
                        onChange={(event) => {
                            this.setState({ REQUEST_NOTE: validateRequestNotes(event.target.value) });
                        }}
                    />
                </div>
                <div className={styles.buttonRow}>
                    <div
                        id={styles.submit}
                        onClick={() => {
                            if (this.state.REQUEST_NOTE !== undefined ? this.state.REQUEST_NOTE : "") {
                                let temp = {
                                    REQUEST_NOTE: this.state.REQUEST_NOTE,
                                    REQUEST_TYPE: `HCP move to unknown`,
                                };
                                this.props.submit(temp);
                            }
                        }}
                    >
                        CONTINUE
                    </div>
                </div>
            </div>
        );

        let menu = (
            <div className={styles.body}>
                <div className={styles.menuTitle}>What would you like to do?</div>
                <div className={styles.errorMessage}>{this.state.errorMessage}</div>
                <div className={styles.menuButton}>
                    <div
                        id={styles.submit}
                        onClick={() => {
                            this.setState({ view: 1 });
                        }}
                    >
                        CHANGE PRIMARY
                    </div>
                    <div
                        id={styles.submit}
                        onClick={() => {
                            this.setState({ view: 3 });
                        }}
                    >
                        ADD SECONDARY
                    </div>
                    <div
                        id={styles.submit}
                        onClick={() => {
                            if (this.props.addresses[2].length > 0) {
                                this.setState({ view: 4 });
                            } else {
                                this.setState({
                                    errorMessage: "You can not deactivate this address as it is the only primary address.",
                                });
                            }
                        }}
                    >
                        DEACTIVATE ADDRESS
                    </div>
                    <div
                        id={styles.submit}
                        onClick={() => {
                            this.setState({ view: 6 });
                        }}
                    >
                        MOVE TO UNKNOWN
                    </div>
                </div>
            </div>
        );

        let view = "";
        if (this.state.view === 0) {
            view = menu;
        }
        if (this.state.view === 1) {
            view = changePri;
        }
        if (this.state.view === 2) {
            view = oldPri;
        }
        if (this.state.view === 3) {
            view = addSecondary;
        }
        if (this.state.view === 4) {
            view = removeAddress;
        }
        if (this.state.view === 5) {
            view = newPrimary;
        }
        if (this.state.view === 6) {
            view = unkown;
        }
        if (this.state.view === 10) {
            view = success;
        }

        return (
            <div className={styles.editScreen}>
                <div className={styles.wrapper}>
                    <div className={styles.title}>
                        <div></div>
                        <div className={styles.TitleText}>Edit Address</div>
                        <div
                            className={styles.close}
                            onClick={() => {
                                this.props.close();
                            }}
                        >
                            <i className="fas fa-times"></i>
                        </div>
                    </div>
                    {view}
                </div>
            </div>
        );
    }
}

export default Details_Edit_Address;
