import React, {Component} from 'react';
import styles from '../css/Preview.module.css';
import SpecialtyLookUp from '../../../globalcomponents/SpecialtyLookUp';
import Axios from './../../../globalcomponents/Axios'

class Preview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            secondaryAddresses: [],
            loaded: false
        };
    }

    componentDidMount() {
        Axios('/api/hcp/preview', {PROF_ID: this.props.data.PROF_ID, TEAM: this.props.data.TEAM_ID }, (data) => {
            this.setState({
                secondaryAddresses: data[0],
                loaded: true
            })
        })
    }

    firstCapital(str) {
        if (str !== undefined && str !== null) {
            return str.toLowerCase().replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();})
        }
        return "";
    }

    status(){
        if(this.props.data.STATUS === null){
          return 'Active'
        }
        else if(this.props.data.STATUS === 'DEAD'){
            return 'Deceased'
        }
        else if(this.props.data.STATUS === 'RETIRED'){
            return 'Retired'
        }
        else{
            return 'Inactive'
        }

    }

    render() {

        let nonSPHASE = ""
        if (!["SPHASE"].includes(this.props.role) || !["PHARMFORCE"].includes(this.props.role)) {
            nonSPHASE = (
                <div className={styles.SectionRow}>
                    <div className={styles.ContentWrap}>
                        <div className={styles.ContentTitle}>PDRP OPT OUT</div>
                        <div className={styles.Content}>{this.props.data.PDRP_OPTOUT ? "Yes" : "No"}</div>
                    </div>
                    <div className={styles.ContentWrap}>
                        <div className={styles.ContentTitle}>NO CONTACT</div>
                        <div className={styles.Content}>{this.props.data.NO_CONTACT ? "Yes" : "No"}</div>
                    </div>
                </div>
            )
        }
        let secondaryAddresses = []
        if (this.state.secondaryAddresses !== undefined && this.state.secondaryAddresses !== null) {
            for (let i = 0; i < this.state.secondaryAddresses.length; i++) {
                if (i % 2 === 0) {
                    secondaryAddresses.push(
                        <div key={i}>
                            <div className={styles.even}>
                                <div className={styles.Content}>
                                    {`${this.firstCapital(this.state.secondaryAddresses[i].SECONDARY_ADDRESS1)}${this.firstCapital(this.state.secondaryAddresses[i].SECONDARY_ADDRESS2) ? ' ' + this.firstCapital(this.state.secondaryAddresses[i].SECONDARY_ADDRESS2) : ''}, `}
                                    {`${this.firstCapital(this.state.secondaryAddresses[i].SECONDARY_CITY)}, ${this.state.secondaryAddresses[i].SECONDARY_STATE} ${this.state.secondaryAddresses[i].SECONDARY_ZIP5}`}
                                </div>
                            </div>
                        </div>
                    )
                }
                else {
                    secondaryAddresses.push(
                        <div key={i}>
                            <div className={styles.odd}>
                                <div className={styles.Content}>
                                    {`${this.firstCapital(this.state.secondaryAddresses[i].SECONDARY_ADDRESS1)}${this.firstCapital(this.state.secondaryAddresses[i].SECONDARY_ADDRESS2) ? ' ' + this.firstCapital(this.state.secondaryAddresses[i].SECONDARY_ADDRESS2) : ''}, `}
                                    {`${this.firstCapital(this.state.secondaryAddresses[i].SECONDARY_CITY)}, ${this.state.secondaryAddresses[i].SECONDARY_STATE} ${this.state.secondaryAddresses[i].SECONDARY_ZIP5}`}
                                </div>
                            </div>
                        </div>
                    )
                }
            }
        }
        else {
            secondaryAddresses = 'No secondary addresses to show'
        }
        return (
            <div className={styles.Preview}>
                <div className={styles.Close}>
                    <div className={styles.CloseButton} onClick={() => {
                        this.props.close()
                    }}>CLOSE PREVIEW<i className="fas fa-times"></i></div>
                </div>
                <div className={styles.Name}>{`${this.props.data.FIRST_NAME} ${this.props.data.LAST_NAME}, ${this.props.data.DEGREE}`}</div>
                    <div className={styles.Status}>
                        {this.props.data.STATUS === null ? <i className="fas fa-check-circle" id={styles.StatusActive}></i> : <i className="fas fa-times-circle" id={styles.StatusInactive}></i>}
                        <div className={styles.Content}>{this.status()}</div>
                    </div>

                    <div className={styles.SectionColumn}>
                        <div className={[styles.ContentWrap, styles.Address].join(' ')}>
                            <div className={styles.ContentTitle}>PRIMARY ADDRESS</div>
                            <div className={styles.Content}>{`${this.firstCapital(this.props.data.ADDRESS1)} ${this.firstCapital(this.props.data.ADDRESS2)}`}</div>
                            <div className={styles.Content}>{`${this.firstCapital(this.props.data.CITY)}, ${this.props.data.STATE} ${this.props.data.ZIP5}`}</div>
                        </div>
                        <div className={[styles.ContentWrap, styles.Address].join(' ')}>
                            <div className={styles.ContentTitle}>SECONDARY ADDRESS</div>
                            {this.state.loaded ? secondaryAddresses : <div className={styles.loading}><i className="fas fa-spinner fa-pulse"></i></div>}
                        </div>
                    </div>
                    <div className={styles.Bar}></div>
                    <div className={styles.SectionColumn}>
                        <div className={styles.ContentWrap}>
                            <div className={styles.ContentTitle}>SPECIALTY</div>
                            <div className={styles.Content}>{this.props.data.SPECIALTY ? this.firstCapital(SpecialtyLookUp[this.props.data.SPECIALTY]) : "None"}</div>
                        </div>
                        <div className={styles.ContentWrap}>
                            <div className={styles.ContentTitle}>SECONDARY SPECIALTY</div>
                            <div className={styles.Content}>{this.props.data.OTHER_SPECIALTY ? this.firstCapital(SpecialtyLookUp[this.props.data.OTHER_SPECIALTY]) : "None"}</div>
                        </div>
                    </div>
                    <div className={styles.Bar}></div>
                    <div className={styles.SectionRow}>
                        <div className={styles.ContentWrap}>
                            <div className={styles.ContentTitle}>PROF ID</div>
                            <div className={styles.Content}>{this.props.data.PROF_ID}</div>
                        </div>
                        <div className={styles.ContentWrap}>
                            <div className={styles.ContentTitle}>NPI</div>
                            <div className={styles.Content}>{this.props.data.NPINUM ? this.props.data.NPINUM : "None"}</div>
                        </div>
                    </div>
                    <div className={styles.Bar}></div>
                    <div className={styles.SectionRow}>
                        <div className={styles.ContentWrap}>
                            <div className={styles.ContentTitle}>BIRTH YEAR</div>
                            <div className={styles.Content}>{this.props.data.BIRTHYEAR ? this.props.data.BIRTHYEAR : "None"}</div>
                        </div>
                        <div className={styles.ContentWrap}>
                            <div className={styles.ContentTitle}>GENDER</div>
                            <div className={styles.Content}>{this.props.data.GENDER}</div>
                        </div>
                    </div>
                    <div className={styles.Bar}></div>
                    {nonSPHASE}
            </div>
        )
    }

}

export default Preview;
