import React from 'react';
import styles from '../css/Details_TDDD_History.module.css';


function Details_TDDD(props) {

    let TDDDs = []

    if (props.data.length > 0) {

        for (let j = 0; j < props.data.length; j++) {
            let style = j % 2 === 0 ? [styles.Content, styles.Odd].join(' ') : styles.Content
            let state = props.data[j].STATE
            let licNbr = props.data[j].LIC_NUM
            let exp = props.data[j].LIC_DATE
            let status = props.data[j].STATUS
            
            TDDDs.push([
                <div key={1} className={styles.Results}>
                    <div className={style}>{state}</div>
                    <div className={style}>{licNbr}</div>
                    <div className={style}>{exp}</div>
                    <div className={style}>{status}</div>
                </div>
            ])
        }
    }

    let tdddHistory = (<div className={styles.Affiliations}>
        <div className={styles.AffiliationsTopBar}>
            <div>State License</div>
            {
                ((props.user.role.includes("ADMIN") || props.user.role.includes("DTS") || props.user.role.includes("DBD") || props.user.role.includes("COMMERCIAL DERM")) && (props.user.team !== "31" && props.user.team !== "32"))? (<div className={styles.AffiliationsEditButton}>
                    <i className="far fa-edit" onClick={() => { props.addTDDD(); }}></i>
                </div>) : ""
            }
        </div>
        <div className={styles.titles}>
            <div className={styles.Title}>STATE</div>
            <div className={styles.Title}>LICENSE NUMBER</div>
            <div className={styles.Title}>EXPIRATION DATE</div>
            <div className={styles.Title}>STATUS</div>
        </div>
        <div className={styles.ResultsWrapper}>
            {
                props.data.length > 0 ?
                    TDDDs
                :
                <div className={styles.Results}>
                    <div className={[styles.Content, styles.Odd].join(' ')}>—</div>
                    <div className={[styles.Content, styles.Odd].join(' ')}>—</div>
                    <div className={[styles.Content, styles.Odd].join(' ')}>—</div>
                    <div className={[styles.Content, styles.Odd].join(' ')}>—</div>
                </div>
            }
        </div>
    </div>) 

    return tdddHistory;
}

export default Details_TDDD;
