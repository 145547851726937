import React, {Component} from 'react';
import styles from '../css/AdminRegionalUpdateConfirmation.module.css';

class AdminRegionalUpdateConfirmation extends Component {

    render() {
        return (
            <div className={styles.editScreen}>
                <div className={styles.title}>
                    <div></div>
                    <div className={styles.TitleText}>Success</div>
                    <div className={styles.close} onClick={() => {
                        this.props.close('confirm')
                    }}><i className="fas fa-times"></i></div>
                </div>
                <div className={styles.body}>
                    <div id={styles.SuccessIcon}>
                        <i className="far fa-check-circle"></i>
                    </div>
                    <div className={styles.TextBox}>
                        Success! You have successfully executed the updates for the Regional Plan exercise.
                    </div>
                </div>
            </div>
        )
    }

}

export default AdminRegionalUpdateConfirmation;
