import React, {Component} from 'react';
import styles from '../css/ResultsView.module.css';
import SearchBar from './SearchBar';
import Result from './Result.js';
import Axios from './../../../components/Axios'

class ResultsView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            USER_ID: '',
            WORK_EMAIL: '',
            NAME: '',
            ROLE: '',
            PHONE: '',
            TERR_ID: '',
            TERR_NAME: '',
            PARENT_ID: '',
            TEAM_ID: '',
            results: [],
            CurrentPage: 0
        };
    }

    componentDidMount() {
        let temp = {}
        temp = this.state
        Axios('/api/admin/search', temp, (data) => {
            this.props.setData(data)
            this.props.setOrgData(data)
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data !== this.props.data) {
            this.setState({data: this.props.data});
        }
    }

    update(props) {
        let arr = []
        let ResultsPerPage = props.ResultsPerPage;
        let CurrentPage = props.CurrentPage;
        let tempStart = Math.round(CurrentPage * ResultsPerPage);
        for (let i = tempStart; (i < props.data.length) && (i < parseInt(tempStart) + parseInt(ResultsPerPage)); i++) {
            arr.push(<Result changeAdminView={this.props.changeAdminView} key={i} number={i} data={props.data[i]} />)
        }
        this.setState({results: arr});
    }

    render() {
        let results = []
        let tempStart = Math.round(this.props.CurrentPage * this.props.ResultsPerPage);
        for (let i = tempStart; (i < this.props.data.length) && (i < parseInt(tempStart) + parseInt(this.props.ResultsPerPage)); i++) {
            results.push(<Result changeAdminView={this.props.changeAdminView} key={i} number={i} data={this.props.data[i]} />)
        }
        return (
            <div className={styles.ResultsView}>
                <div className={styles.titleBar}>
                    <div className={styles.title}>User Management</div>
                    <div className={styles.addUser} onClick={() => {this.props.changeAdminView(0)}}>CREATE USER</div>
                </div>
                <div className={styles.searchBar}>
                    <SearchBar setData={this.props.setData} data={this.props.data} orgData={this.props.orgData}/>
                </div>
                <div className={styles.results}>
                    {results.length !== 0 ? results : "Loading..."}
                </div>

            </div>
        );
    }
}

export default ResultsView;
