import { makeAutoObservable, observable, computed, action, reaction, runInAction } from "mobx";
import Axios from '../components/Axios/Axios'

export default class OrgStore {

    constructor(rootStore) {
        this.rootStore = rootStore;
        this.user = this.rootStore.userStore.user;
        makeAutoObservable(this);
    }

    alignment = observable({});
    orgId = observable(0);
    loading = observable(true);
    user = observable({});

    addressAlignment = action((orgId, puser) => {

        runInAction(() => {
            this.loading = true;
        });

        this.orgId = orgId;
        let teamID = puser.team ? puser.team : 11;
        if (puser.role == 'PRT' || puser.role == 'COMMERCIAL DERM' || puser.isDermAdmin == 'true') { teamID = 31; }

        const params = {
            orgId: orgId,
            teamId: teamID
        }
        
        Axios('/api/details/hco/alignment', params, (data) => {
            runInAction(() => {
                this.alignment = data;
                this.loading = false;
            });
        }, (error) => console.error(error));
    })
}