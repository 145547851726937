import React, {useEffect} from 'react'
import styles from './ResultsPannel.module.css'
import Spinner from '../Spinner'
import Results from '../Results'
import Titles from '../Titles'
import Table from '../Table'

function ResultsPannel(props) {
    useEffect(() => {
        props.getData()
    }, [])


    return (
        <div className={styles.resultsWrapper}>
            <div className={styles.sectionTitle}>{props.headerTitle}</div>
            <Table className={styles.results}>
                <Titles titles={props.keys} />
                {props.loaded ? <Results {...props} /> : <Spinner />}
            </Table>
        </div>
    )
}

export default ResultsPannel
