import React, { Component } from 'react';
import styles from './css/ChangeRequest.module.css';
import ChangeRequestRow from './components/ChangeRequestRow'
import ChangeRequestCancel from './components/ChangeRequestCancel'
import ChangeRequestEdit from './components/ChangeRequestEdit'
import ChangeRequestEditEXUS from './components/ChangeRequestEditEXUS'
import Popup from '../../globalcomponents/Popup';
import Loading from '../../globalcomponents/Loading'
import AddHcp from './components/AddHcp';
import AddHco from './components/AddHco';
import Axios from './../../components/Axios'

class ChangeRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            view: 0
        };
    }

    removeExtraFields(data, prev) {
        let ret = {}
        for (let i = 0; i < Object.keys(data).length; i++) {
            if (![
                "ACTION_ID",
                "REQUEST_ID",
                "NEW_REQUEST",
                "FULL_REQUEST",
                "REQUESTOR_ID",
                "REQUESTOR_NAME",
                "REQUESTOR_TEAM",
                "REQUESTOR_TERR",
                "REQUEST_DATE",
                "REQUEST_NOTE",
                "REQUEST_REASON",
                "RESOLVE_DATE",
                "RESOLVE_REASON",
                "RESOLVE_TYPE",
                "RESOLVE_USER",
                "REQUEST_TYPE",
                "NEW_VALUE",
                "PREV_FIRST_NAME",
                "PREV_LAST_NAME",
                "PREV_ORG_NAME",
                "ACTION_NUM"
            ].includes(Object.keys(data)[i])) {
                if (Object.keys(data)[i] === 'STATUS') {
                    if (Object.values(data)[i] === null) {
                        ret[Object.keys(data)[i]] = 'Active'
                    } else {
                        ret[Object.keys(data)[i]] = Object.values(data)[i]
                    }
                } else {
                    ret[Object.keys(data)[i]] = Object.values(data)[i]
                }
            }
        }
        if (prev === undefined) {
            prev = this.props.data
        }
        for (let i = 0; i < Object.keys(prev).length; i++) {
            if (Object.keys(prev)[i] === 'STATUS') {
                if (Object.values(prev)[i] === null) {
                    prev[Object.keys(prev)[i]] = 'Active'
                } else {
                    prev[Object.keys(prev)[i]] = Object.values(prev)[i]
                }
            } else {
                prev[Object.keys(prev)[i]] = Object.values(prev)[i]
            }
        }
        this.setState({
            prev: prev,
            next: ret,
            loaded: true,
            approved: (["Automatically Approved", "APPROVED", "DECLINED", "COMPLETED"].includes(this.props.next ? this.props.next.RESOLVE_TYPE : this.props.data.RESOLVE_TYPE)),
            myChange: (data.REQUESTOR_NAME === this.props.user.userName),
            requestID: (
                this.props.details
                    ? this.props.next.ACTION_ID
                    : this.props.data.ACTION_ID),
            type: data.REQUEST_TYPE ? data.REQUEST_TYPE : this.props.data.REQUEST_TYPE
        })
    }

    componentDidMount() {
        //break into multiple funcitons
        if (this.props.details) {
            //if loaded from details, it already has the next property
            //next is the change
            //prev is the record from the db
            //console.log(this.props);
            this.removeExtraFields(JSON.parse(this.props.next.FULL_REQUEST))
        } else {
            if (this.props.data.PROF_ID) {
                let temp = {
                    PROF_ID: this.props.data.PROF_ID,
                    user: this.props.user
                }
                Axios('/api/home/hcp', temp, (json) => {
                    this.removeExtraFields(JSON.parse(this.props.data.FULL_REQUEST), json[0])
                })
            } else {
                let temp = {
                    ORG_ID: this.props.data.ORG_ID,
                    user: this.props.user
                }
                Axios('/api/home/hco', temp, (json) => {
                    this.removeExtraFields(JSON.parse(this.props.data.FULL_REQUEST), json[0])
                })
            }
        }
    }

    back() {
        this.setState({ view: 0 });
    }

    changeView(num) {
        this.setState({ view: num });
    }

    cancelRequest() {
        let json = {}
        if (this.props.data.PROF_ID) {
            json.PROF_ID = this.props.data.PROF_ID
        }
        if (this.props.data.ORG_ID) {
            json.ORG_ID = this.props.data.ORG_ID
        }
        json.REQUEST_TYPE = "Cancel Request"
        json.REQUEST_ID = this.state.requestID
        json.REQUESTOR_ID = this.props.user.email
        json.REQUESTOR_NAME = this.props.user.userName
        json.REQUESTOR_TEAM = this.props.user.team
        json.REQUESTOR_TERR = this.props.user.terr
        Axios('/api/global/change-request', json, (data) => {
            this.props.callback(data);
        })
    }

    submitRequest(json, type) {
        let newreq = {}
        for (let i = 0; i < Object.keys(json).length; i++) {
            if (Object.values(json)[i] === -1) {
                json[Object.keys(json)[i]] = null
            }
        }
        if (json.ADDRESS1) {
            delete json.keys
            delete json.values
            newreq = {
                ...json
            }
            json = {}
            Object.assign(json, {
                ...this.state.next
            });
        } else {
            delete json.ADDRESS1
            delete json.ADDRESS2
            delete json.CITY
            delete json.STATE
            delete json.ZIPCODE
            Object.assign(json, {
                ...this.state.next
            });
            for (let i = 0; i < json.keys.length; i++) {
                newreq[json.keys[i]] = json.values[i]
            }
            delete json.keys
            delete json.values
        }
        let teamID = this.props.user.team;
        if (this.props.user.role == 'PRT' || this.props.user.role == 'PHARMFORCE'|| this.props.user.role == 'COMMERCIAL DERM' || this.props.user.isDermAdmin == 'true') { teamID = 31; } 
        //info added for the update
        json.REQUEST_TYPE = "Update Request"
        json.REQUEST_ID = this.state.requestID
        json.REQUESTOR_ID = this.props.user.email
        json.REQUESTOR_NAME = this.props.user.userName
        json.REQUESTOR_TEAM = teamID
        json.REQUESTOR_TERR = this.props.user.terr

        //info added for the 3rd request
        if (this.state.next.PROF_ID) {
            newreq.PROF_ID = this.state.next.PROF_ID
        }
        if (this.state.next.ORG_ID) {
            newreq.ORG_ID = this.state.next.ORG_ID
        }
        newreq.REQUEST_TYPE = type
        newreq.REQUEST_ID = this.state.requestID
        newreq.REQUESTOR_ID = this.props.user.email
        newreq.REQUESTOR_NAME = this.props.user.userName
        newreq.REQUESTOR_TEAM = teamID
        newreq.REQUESTOR_TERR = this.props.user.terr

        json.NEW_REQUEST = JSON.stringify({
            ...newreq
        })
        //console.log(json);
        Axios('/api/global/change-request', json, (data) => {
            this.props.callback(data);
        })
    }

    newHCO() {
        this.setState({ newHCO: true });
    }

    newHCP() {
        this.setState({ newHCP: true });
    }

    render() {
        let changeBox = [<ChangeRequestRow details={this.props.details} data={this.props.data} newHCO={this.newHCO.bind(this)} hco={this.state.newHCO} hcp={this.state.newHCP} newHCP={this.newHCP.bind(this)} user={this.props.user} prev={this.state.prev} next={this.state.next} type={this.state.type} />]
        let changeRequestWindows = [
            <ChangeRequestCancel changeView={this.changeView.bind(this)} back={this.back.bind(this)} close={this.props.close} cancelRequest={this.cancelRequest.bind(this)} />,
            <ChangeRequestEdit changeView={this.changeView.bind(this)} back={this.back.bind(this)} prev={this.state.prev} next={this.state.next} close={this.props.close} submitRequest={this.submitRequest.bind(this)} cancelRequest={this.cancelRequest.bind(this)} type={this.state.type} />,
            <ChangeRequestEdit changeView={this.changeView.bind(this)} back={this.back.bind(this)} prev={this.state.prev} next={this.state.next} close={this.props.close} submitRequest={this.submitRequest.bind(this)} type={this.state.type} />,
            <AddHcp submit={this.submitRequest.bind(this)} close={this.props.close} type={this.state.type} back={this.back.bind(this)} user={this.props.user} data={this.props.data} />,
            <AddHco submit={this.submitRequest.bind(this)} close={this.props.close} type={this.state.type} back={this.back.bind(this)} user={this.props.user} data={this.props.data} />,
            <ChangeRequestEditEXUS changeView={this.changeView.bind(this)} back={this.back.bind(this)} prev={this.state.prev} next={this.state.next} close={this.props.close} submitRequest={this.submitRequest.bind(this)} type={this.state.type} />,

        ]



        let main = (<div className={styles.window}>
            <div className={styles.title}>
                <div></div>
                <div className={styles.TitleText}>Change Request</div>
                <div className={styles.close} onClick={() => {
                    this.props.close()
                }}>
                    <i className="fas fa-times"></i>
                </div>
            </div>

            <div className={styles.body}>
                <div className={styles.contentWrapper}>
                    {
                        this.state.loaded
                            ? changeBox[this.state.view]
                            : ""
                    }
                    {
                        this.state.loaded
                            ? ""
                            : <Loading />
                    }

                </div>
                {
                    this.state.loaded && this.state.approved
                        ? <div className={styles.approved}>REQUEST HAS BEEN COMPLETED.</div>
                        : ""
                }
                {
                    this.state.loaded && !this.state.approved && !this.state.myChange
                        ? <div className={styles.approved}>THIS CHANGE REQUEST WAS SUBMITTED BY A DIFFERENT USER.</div>
                        : ""
                }
                <div className={styles.buttonRow}>
                    <div id={this.state.approved || !this.state.myChange
                        ? styles.approvedCancel
                        : styles.cancel} onClick={() => {
                            if (!this.state.approved && this.state.myChange) {
                                this.setState({ view: 1 })
                            }
                        }}>CANCEL REQUEST</div>
                    <div className={this.state.Wiggle && this.state.Wiggle !== undefined
                        ? styles.Wiggle
                        : ""} id={this.state.approved || !this.state.myChange || this.state.newHCO || this.state.newHCP || this.state.type.includes("Deactivate Primary Address") || this.state.type.includes("affiliation") || this.state.type.includes("Add to") || this.state.type.includes("Remove from")
                            ? styles.approvedSubmit
                            : styles.submit} onClick={() => {
                                if (!this.state.approved && this.state.myChange && !this.state.newHCO && !this.state.newHCP) {
                                    if (!this.state.type.includes("Deactivate") && !this.state.type.includes("affiliation") && !this.state.type.includes("Add to") && !this.state.type.includes("Remove from")) {
                                       
                                        if (this.state.type.includes("EXUS") || this.state.type.includes("Ex-US")) {
                                            this.setState({ view: 6 });
                                        }
                                        else if (this.state.type.includes("New HCP Add")) {
                                            this.setState({ view: 4 });
                                        }
                                        else if (this.state.type.includes("New HCO Add")) {
                                            this.setState({ view: 5 });
                                        }
                                        else {
                                            this.setState({ view: 2 });
                                        }
                                    }
                                }
                            }}>EDIT REQUEST</div>
                </div>
            </div>
        </div>)
        let rend = (<div className={this.props.details
            ? ""
            : styles.ChangeRequest}>
            {
                this.state.view === 0
                    ? main
                    : changeRequestWindows[this.state.view - 1]
            }
        </div>)
        return (<Popup component={rend} />)
    }
}

export default ChangeRequest;
