import React, {Component} from 'react';
import styles from '../css/Details_Edit_Additional.module.css';
import validateRequestNotes from '../../../helper/validateRequestNotes';

class Details_Edit_Additional extends Component {
    constructor(props) {
        super(props);
        this.state = {
            PDRP_OPTOUT: this.props.data.PDRP_OPTOUT,
            NO_CONTACT: this.props.data.NO_CONTACT,
            COMPLIANCE_SPECIALTY: this.props.data.COMPLIANCE_SPECIALTY,
            PREV_PDRP_OPTOUT: this.props.data.PDRP_OPTOUT,
            PREV_NO_CONTACT: this.props.data.NO_CONTACT,
            PREV_COMPLIANCE_SPECIALTY: this.props.data.COMPLIANCE_SPECIALTY,
            REQUEST_NOTE: null,
            REQUEST_TYPE: `Update HCP Compliance Specialty`,
            changed: {

            }
        };
    }

    firstCapital(str) {
        if (str !== undefined) {
            return str.toLowerCase().replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();})
        }
        return "";
    }

    render() {
        return (
            <div>
                <div className={styles.title}>
                    <div></div>
                    <div>Edit Additional</div>
                    <div className={styles.close} onClick={() => {
                        this.props.close()
                    }}><i className="fas fa-times"></i></div>
                </div>
                <div className={styles.body}>
                    <div className={styles.required}>REQUIRED FIELD *</div>
                    <div className={styles.middlerow}>
                        <div className={styles.set}>
                            <div className={styles.inputTitle}>PDRP OPTOUT</div>
                            <select disabled  value={this.state.PDRP_OPTOUT ? "YES" : ""} onChange={(event) => {
                                let temp = this.state.changed;
                                if (this.state.PREV_PDRP_OPTOUT !== event.target.value) {
                                    temp.PDRP_OPTOUT = event.target.value;
                                    this.setState({changed: temp});
                                }
                                else {
                                    delete temp.PDRP_OPTOUT;
                                    this.setState({changed: temp});
                                }
                                this.setState({PDRP_OPTOUT: event.target.value})
                            }}>
                                <option value="YES">Yes</option>
                                <option value="">No</option>//problems will come from this
                            </select>
                        </div>
                        <div className={styles.set}>
                            <div className={styles.inputTitle}>NO CONTACT</div>
                            <select disabled  value={this.state.NO_CONTACT ? "Y" : ""} onChange={(event) => {
                                let temp = this.state.changed;
                                if (this.state.PREV_NO_CONTACT !== event.target.value) {
                                    temp.NO_CONTACT = event.target.value;
                                    this.setState({changed: temp});
                                }
                                else {
                                    delete temp.NO_CONTACT;
                                    this.setState({changed: temp});
                                }
                                this.setState({NO_CONTACT: event.target.value})
                            }}>
                                <option value="Y">Yes</option>
                                <option value="">No</option>//problems will come from this
                            </select>
                        </div>
                        <div className={styles.set}>
                            <div className={styles.inputTitle}>COMPLIANCE SPECIALTY</div>
                            <input value={this.state.COMPLIANCE_SPECIALTY !== -1 ? this.state.COMPLIANCE_SPECIALTY : "-1"} onChange={(event) => {
                                let temp = this.state.changed;
                                if (this.state.PREV_COMPLIANCE_SPECIALTY !== event.target.value) {
                                    if (event.target.value === "-1") {
                                        temp.COMPLIANCE_SPECIALTY = "";
                                    }
                                    else {
                                        temp.COMPLIANCE_SPECIALTY = event.target.value;
                                    }
                                    this.setState({changed: temp});
                                }
                                else {
                                    delete temp.COMPLIANCE_SPECIALTY;
                                    this.setState({changed: temp});
                                }
                                if (event.target.value === "-1") {
                                    this.setState({COMPLIANCE_SPECIALTY: ""})
                                }
                                else {
                                    this.setState({COMPLIANCE_SPECIALTY: event.target.value})
                                }
                            }} />
                        </div>



                    </div>

                    <div className={styles.set}>
                        <div className={styles.inputTitle}>REVISION REASON</div>
                        <input value={this.state.REQUEST_NOTE} onChange={(event) => {
                            this.setState({ REQUEST_NOTE: validateRequestNotes(event.target.value) });
                        }} />
                    </div>

                    <div className={styles.buttonRow}>
                        <div id={styles.cancel} onClick={() => {
                            this.props.close();
                        }}>CANCEL</div>
                        <div className={this.state.Wiggle && this.state.Wiggle !== undefined ? styles.Wiggle : ""} id={styles.submit} onClick={() => {
                            if (Object.keys(this.state.changed).length > 0) {
                                let temp = this.state.changed
                                temp.REQUEST_TYPE = "Update HCP Compliance Specialty"
                                temp.REQUEST_NOTE = this.state.REQUEST_NOTE
                                temp.TAXONOMY_CODE = this.state.COMPLIANCE_SPECIALTY
                                this.props.submit(temp);
                            }
                            else {
                                this.setState({
                                    Wiggle: true
                                })
                                setTimeout(() => {
                                    this.setState({Wiggle: false})
                                }, 500);
                            }
                        }}>SUBMIT</div>
                    </div>

                </div>
            </div>
        )
    }

}

export default Details_Edit_Additional;
