import React, { Component } from 'react';
import styles from '../css/Details_Information.module.css';
import Toggle from '../../../globalcomponents/Toggle';
import NotificationBox from '../../../globalcomponents/NotificationBox';
import firstCapital from '../../../helper/firstCapital';
import hco from '../../../lookup/hco.json';
import YesNoDialog from './../../../globalcomponents/YesNoDialogBox';

class Details_Information extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationStatus: false,
      errorStatus: false,
      notificationType: '',
      errorByID: null,
        showWarning: false,
        cancelCRM: false,
        crmActiveState: false,
        aggActive: false,
    };
  }

  updateError(status, type, id) {
    let message = `You cannot ${!status ? 'add' : 'remove'} this organization from your ${type}.`;
    this.setState({
      notificationMessage: message,
      notificationStatus: true,
      errorStatus: true,
      notificationType: type,
      errorByID: id,
    });
  }

  updateNotification(status, type) {
    let message = `You switched ${type} to ${!status ? 'ON' : 'OFF'} for this organization.`;
    if (type === 'BOTH') {
      message = `You switched CRM and Spend to ON for this organization.`;
    }
    this.setState({
      notificationMessage: message,
      notificationStatus: true,
      errorStatus: false,
      notificationType: type,
    });
  }

  updateNotificationStatus() {
    this.setState({
      notificationStatus: false,
      notificationType: '',
      errorByID: null,
    });
  }

  secondaryAddresses() {
    if (this.props.addresses[2]) {
      let temp = [];
      for (let i = 0; i < this.props.addresses[2].length; i++) {
        if (this.props.addresses[2][i].SECONDARY_ADDRESS1 !== this.props.addresses[0][0].PRI_ADDRESS1) {
          temp.push(
            <div key={i} className={[styles.Content, styles.FullContent].join(' ')}>
              <div className={styles.ContentTitle}>SECONDARY ADDRESS</div>
              <div>
                {`${firstCapital(this.props.addresses[2][i].SECONDARY_ADDRESS1)} ${firstCapital(
                  this.props.addresses[2][i].SECONDARY_ADDRESS2
                )}, ${firstCapital(this.props.addresses[2][i].SECONDARY_CITY)}, ${this.props.addresses[2][i].SECONDARY_STATE} ${
                  this.props.addresses[2][i].SECONDARY_ZIP5
                }`}
                {this.props.addresses[2][i].SECONDARY_ADDRESS1 === this.props.addresses[1][0].BEST_ADDRESS1 ? (
                  <i className={[styles.best, 'fas', 'fa-check-circle'].join(' ')}></i>
                ) : (
                  ''
                )}
              </div>
            </div>
          );
        }
      }
      return temp;
    }
  }
    leftClick() {
        this.setState({ showWarning: false });

    }
    rightClick() {
        

        this.props.addTo(this.props.number, 1, this.state.crmActiveState, !this.state.aggActive ? true : false);
        this.updateNotification(this.state.crmActiveState, !this.state.aggActive ? 'BOTH' : 'CRM');
        this.setState({ showWarning: false });
    }
  render() {
      let tinView = hco.details.tin.view
      let tinEdit = hco.details.tin.edit
      let tin = (this.props.user.role.includes("ADMIN") || tinView.teams.includes(this.props.user.team) || tinEdit.teams.includes(this.props.user.team)) || (tinView.roles.includes(this.props.user.role) || tinEdit.roles.includes(this.props.user.role)) ?
          (<div className={styles.Content}>
              <div className={styles.ContentTitle}>TIN</div>
              <div>{this.props.data.TIN ? this.props.data.TIN : "—"}</div>
          </div>
          ) : ""

      let identifiers = (
          <div className={styles.MiniSection}>
              <div className={styles.TitleBar}>
                  <div>IDENTIFIERS</div>
                  {
                      this.props.user.role.includes("ADMIN") || tinEdit.teams.includes(this.props.user.team) || tinEdit.roles.includes(this.props.user.role) ?
                          (<i className="far fa-edit" onClick={() => {
                              this.props.editScreen(5);
                          }}></i>) : ""
                  }
              </div>
              <div className={styles.Wrapper}>
                  <div className={styles.Content}>
                      <div className={styles.ContentTitle}>ORG ID</div>
                      <div>{this.props.data.ORG_ID ? this.props.data.ORG_ID : "—"}</div>
                  </div>
                  <div className={styles.Content}>
                      <div className={styles.ContentTitle}>NPI</div>
                      <div>{this.props.data.NPI ? this.props.data.NPI : "—"}</div>
                  </div>
                  {tin}
              </div>
          </div>
      )

      let addressView = hco.details.address.view
      let addressEdit = hco.details.address.edit
      let address = (this.props.user.role.includes("ADMIN") || this.props.user.role.includes("COMMERCIAL DERM") || this.props.user.isDermAdmin == 'true' || addressView.teams.includes(this.props.user.team) || addressEdit.teams.includes(this.props.user.team)) || (addressView.roles.includes(this.props.user.role) || addressEdit.roles.includes(this.props.user.role)) ?
          (<div className={styles.MiniSection}>
              <div className={styles.TitleBar}>
                  <div>ADDRESS</div>
                  {/*{(this.props.user.role.includes("ADMIN") || addressEdit.teams.includes(this.props.user.team) || addressEdit.roles.includes(this.props.user.role)) && !this.props.user.role.includes('PHARMFORCE') ? (<i className="far fa-edit" onClick={() => {*/}
                  {/*    this.props.editScreen(3);*/}
                  {/*}}></i>) : ""}*/}
              </div>
              <div className={[styles.Wrapper, styles.Column].join(' ')}>
                  <div className={[styles.Content, styles.FullContent].join(' ')}>
                      <div className={styles.ContentTitle}>ADDRESS</div>
                      <div>{`${firstCapital(this.props.data.ADDRESS1)} ${firstCapital(this.props.data.ADDRESS2)}, ${firstCapital(this.props.data.CITY)}, ${this.props.data.STATE} ${this.props.data.ZIP5}`}</div>
                  </div>
              </div>
          </div>) : ""

      let lymView = hco.details.lym.view
      let lymEdit = hco.details.lym.edit
      let lym = (lymView.teams.includes(this.props.user.team) || lymEdit.teams.includes(this.props.user.team)) && (lymView.roles.includes(this.props.user.role) || lymEdit.roles.includes(this.props.user.role)) || this.props.user.role.includes("ADMIN")
          ? (<div className={styles.MiniSection}>
              <div className={styles.TitleBar}>
                  <div>LYM INFO</div>
              </div>
              <div className={styles.Wrapper}>
                  <div className={styles.Content}>
                      <div className={styles.ContentTitle}>TARGET TYPE</div>
                      <div>—</div>
                  </div>
                  <div className={styles.Content}>
                      <div className={styles.ContentTitle}>TARGET</div>
                      <div>—</div>
                  </div>
                  <div className={styles.Content}>
                      <div className={styles.ContentTitle}>ACADEMIC</div>
                      <div>—</div>
                  </div>
                  <div className={styles.Content}>
                      <div className={styles.ContentTitle}>COMMUNITY</div>
                      <div>—</div>
                  </div>
              </div>
          </div>)
          : ""

    let hashes = this.props.data;
    let id = this.props.data.ORG_ID;

    let aggspendActive = hashes.spend || this.props.data.AGGSPEND_FLAG === 'Y';
    let aggspendToggle = (
      <Toggle
        active={aggspendActive}
        click={() => {
          this.props.addTo(this.props.number, 0, aggspendActive);
          this.updateNotification(aggspendActive, 'Spend');
        }}
        disabled={aggspendActive}
        errorCallback={() => {
          this.updateError(aggspendActive, 'Spend', id);
        }}
        error={this.state.errorByID === id && this.state.notificationType === 'Spend'}
      />
    );

    let isAdmin = ['ADMIN'].includes(this.props.user.role);
    let regex = new RegExp(`^${this.props.user.terr}`, 'g')
   
      let matchesTerritory = false 

    let overRide = this.props.data.MDM_OVERRIDE !== 'X';

      if (overRide) {
          matchesTerritory = (["51", "53"].includes(this.props.user.team)) || (this.props.data.TERR_ID && this.props.data.TERR_ID.match(regex))
      } else {
          matchesTerritory = ["51", "53"].includes(this.props.user.team) ||
              (this.props.data.TERR_ID && (this.props.data.TERR_ID.match(regex) || (this.props.data.MDM_TERR_ID && this.props.data.MDM_TERR_ID.match(regex))))
      }


    let universeFlag = this.props.data.UNIVERSE_FLAG === 'Y';
    let teamUniverseFlag = this.props.data[`UNIVERSE_FLAG_TM${this.props.user.team}`] === 'Y';
    //let crmActive = hashes.crm !== undefined ? hashes.crm : overRide && ((isAdmin && universeFlag) || teamUniverseFlag);
    let isPRT = ['PRT'].includes(this.props.user.role)
      let isDisabled = !isAdmin && !this.props.user == 'PRT' && !matchesTerritory && !["51", "53"].includes(this.props.user.team);
      let isGlobal = !isAdmin && !matchesTerritory && teamUniverseFlag && overRide;


      let crmActive = false;
      if (this.props.user.isDermAdmin == 'true' || this.props.user.role == 'COMMERCIAL DERM' || this.props.user.role == 'PRT') {
          crmActive = hashes.crm !== undefined ? hashes.crm : teamUniverseFlag;
          if (teamUniverseFlag == true) { isGlobal = false; }
      } else {
          //crmActive = hashes.crm[id] !== undefined ? hashes.crm[id] : overRide && ((isAdmin && universeFlag) || teamUniverseFlag);
          crmActive = hashes.crm !== undefined ? hashes.crm : overRide && ((isAdmin && universeFlag) || teamUniverseFlag);
      }

      let hasAffiliations = this.props.hasAffiliations == true;
     // let sameTeam = this.props.data[`TARGET_FLAG_TM${this.props.user.team}`] === "Y";

    let crmToggle = (
      <Toggle
        active={crmActive}
            click={() => {
                if (crmActive === true && hasAffiliations) {
                    this.setState({
                        showWarning: true,
                        crmActiveState: crmActive,
                        aggActive: aggspendActive
                    });
                }
                else {
                    this.props.addTo(this.props.number, 1, crmActive, !aggspendActive ? true : false);
                   // this.updateNotification(this.state.crmActiveState, !this.state.aggActive ? 'BOTH' : 'CRM');
                    this.updateNotification(crmActive, !aggspendActive ? 'BOTH' : 'CRM');
                }
                
            }}
        overRide={!overRide}
        disabled={!isAdmin && !matchesTerritory && !isPRT}
            global={isGlobal}
        errorCallback={() => {
          this.updateError(crmActive, 'CRM', id);
        }}
        error={this.state.errorByID === id && this.state.notificationType === 'CRM'}
      />
    );

  
      

      let affWarning = 
              (<YesNoDialog
              rightClick={this.rightClick.bind(this)}
              leftClick={this.leftClick.bind(this)}
                  rightButton={'YES'}
                  leftButton={'NO'}
                  title={'Remove From CRM'}
                  msgFirstLine={'The selected organization has one or more affiliations.'}
                  msgSecondLine={'Select No to go back.  Select Yes to remove from CRM.'} />)
              
      

    let ctmsActive = hashes.ctms !== undefined ? hashes.ctms : this.props.data.CTMS_FLAG === 'Y';
    let ctmsToggle = (
      <Toggle
            active={ctmsActive}
            overRide={!overRide}
        click={() => {
          this.props.addTo(this.props.number, 2, ctmsActive);
          this.updateNotification(ctmsActive, 'CTMS');
        }}
      />
    );

    let crmView = hco.details.crm.view;
    let spendView = hco.details.spend.view;
    let ctmsView = hco.details.ctms.view;
    let universe = (
      <div className={styles.MiniSection}>
        <div className={styles.TitleBar}>
          <div>UNIVERSE</div>
        </div>
        <div className={styles.WrapperToggle}>
                {(this.props.user.role.includes('ADMIN') ||
                    this.props.user.role.includes('PRT') ||
                 
          crmView.roles.includes('ALL') ||
          crmView.teams.includes(this.props.user.team) ||
                    crmView.roles.includes(this.props.user.role)) && !this.props.user.role.includes('PHARMFORCE') ? (
            <div className={styles.Content}>
              <div className={styles.ContentTitle}>CRM</div>
              <div className={styles.Toggle}>
                {crmToggle}
                <div className={styles.ErrorMessage}>{this.state.CRMErrorMessage}</div>
              </div>
            </div>
          ) : (
            ''
          )}
          {this.props.user.role.includes('ADMIN') ||
          spendView.roles.includes('ALL') ||
          spendView.teams.includes(this.props.user.team) ||
          spendView.roles.includes(this.props.user.role) ? (
            <div className={styles.Content}>
              <div className={styles.ContentTitle}>SPEND</div>
              <div className={styles.Toggle}>
                {aggspendToggle}
                <div className={styles.ErrorMessage}>{this.state.SpendErrorMessage}</div>
              </div>
            </div>
          ) : (
            ''
          )}
          {this.props.user.role.includes('ADMIN') ||
          ctmsView.roles.includes('ALL') ||
          ctmsView.teams.includes(this.props.user.team) ||
          ctmsView.roles.includes(this.props.user.role) ? (
            <div className={styles.Content}>
              <div className={styles.ContentTitle}>CTMS</div>
              <div className={styles.Toggle}>{ctmsToggle}</div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    );

      let name = (<div className={styles.Content}>
          <div className={styles.ContentTitle}>NAME</div>
          <div className={styles.Name}>{this.props.data.ORG_NAME}</div>
      </div>)
      let preferedName = (<div className={styles.FullContent}>
          <div className={styles.ContentTitle}>PREFERRED NAME</div>
          <div className={styles.PreferredName}>{this.props.data.PREFERRED_NAME}</div>
      </div>)

      let ctmsNameView = hco.details.ctmsName.view
      let ctmsNameEdit = hco.details.ctmsName.edit
      let ctmsName = (this.props.user.role.includes("ADMIN") || ctmsNameView.teams.includes(this.props.user.team) || ctmsNameEdit.teams.includes(this.props.user.team)) || (ctmsNameView.roles.includes(this.props.user.role) || ctmsNameEdit.roles.includes(this.props.user.role)) ?
          (<div className={styles.Content}>
              <div className={styles.ContentTitle}>CTMS NAME</div>
              <div className={styles.Name}>{this.props.data.CTMS_NAME}</div>
          </div>) :
          preferedName

      let editDetailsEdit = hco.details.editDetails.edit
      let editDetails = this.props.user.role.includes("ADMIN") || editDetailsEdit.teams.includes(this.props.user.team) || editDetailsEdit.roles.includes(this.props.user.role) ?
          (<i className="far fa-edit" onClick={() => {
              this.props.editScreen(1);
          }}></i>) : ''

      let editContactEdit = hco.details.editContact.edit
      let editContact = this.props.user.role.includes("ADMIN") || editContactEdit.teams.includes(this.props.user.team) || editContactEdit.roles.includes(this.props.user.role) ?
          (<i className="far fa-edit" onClick={() => {
              this.props.editScreen(2);
          }}></i>) : ''

      let notification = this.state.notificationStatus ? (
          <NotificationBox
              message={this.state.notificationMessage}
              callback={this.updateNotificationStatus.bind(this)}
              errorStatus={this.state.errorStatus}
          />
        ) : ( ''
      );
     
      return (
          <div className={styles.Information}>
              
              <div className={styles.MiniSection}>
                  <div className={styles.TitleBar}>
                      <div>DETAILS</div>
                      {editDetails}
                  </div>
                  <div className={styles.Wrapper}>
                      {name}
                      {ctmsName}
                      {['ADMIN'].includes(this.props.user.role) ? preferedName : ''}
                      <div className={styles.Content}>
                          <div className={styles.ContentTitle}>STATUS</div>
                          <div>{this.props.data.ORG_STATUS ? 'Inactive' : 'Active'}</div>
                      </div>
                      <div className={styles.Content}>
                          <div className={styles.ContentTitle}>ORG TYPE</div>
                          <div>{this.props.data.ORG_TYPE ? this.props.data.ORG_TYPE : '—'}</div>
                      </div>
                  </div>
              </div>
              <div className={[styles.Long, styles.MiniSection].join(' ')}>
                  <div className={styles.TitleBar}>
                      <div>CONTACT</div>
                      {editContact}
                  </div>
                  <div className={[styles.Wrapper, styles.Column].join(' ')}>
                      <div className={styles.Content}>
                          <div className={styles.ContentTitle}>EMAIL</div>
                          <div>{this.props.data.EMAIL ? this.props.data.EMAIL : '—'}</div>
                      </div>
                      <div className={styles.Content}>
                          <div className={styles.ContentTitle}><i className="fab fa-twitter"></i> &nbsp; TWITTER</div>
                          <div>{this.props.data.TWITTER ? this.props.data.TWITTER : '—'}</div>
                      </div>
                      <div className={styles.Content}>
                          <div className={styles.ContentTitle}>PHONE</div>
                          <div>{this.props.data.PHONE ? this.props.data.PHONE : '—'}</div>
                      </div>
                      <div className={styles.Content}>
                          <div className={styles.ContentTitle}>FAX</div>
                          <div>{this.props.data.FAX ? this.props.data.FAX : '—'}</div>
                      </div>
                      <div className={styles.Content}>
                          <div className={styles.ContentTitle}>WEBSITE</div>
                          <div>{this.props.data.WEBSITE ? this.props.data.WEBSITE : '—'}</div>
                      </div>
                  </div>
              </div>
              {this.state.showWarning ? affWarning : "" }
              {identifiers}
              {universe}
              {address}
              {this.secondaryAddresses}
              {lym}
              {notification}
          </div>
      );
  }
}

export default Details_Information;
