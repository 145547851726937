import React from 'react'
import styles from './../css/StatusLock.module.css'
import Axios from '../../../../components/Axios'

function StatusLock(props) {
    const submit = (index) => {
        let temp = {
            statusID: index,
            ID: props.id,
            terr: props.terr
        }
        Axios('/api/best-address-exercise/submit-status', temp, (data) => {
            props.getTotals()
        })
    }
    if (!props.approved) {
        if (["ADMIN", "OTS"].includes(props.user.role) && (props.statusIndex === 1 || props.statusIndex === 2)) {
            return (
                <div key={"Lock"} className={styles.wrapper}>
                    <div className={styles.statusText}>Progress is automatically saved.</div>
                    <button className={styles.submit} onClick={() => {
                            submit(3)
                        }}>SUBMIT</button>
                </div>
            )
        }
        else if (["ADMIN", "OAS"].includes(props.user.role) && (props.statusIndex === 3 || props.statusIndex === 5)) {
            return (
                <div key={"Lock"} className={styles.wrapper}>
                    <div className={styles.statusText}>Progress is automatically saved.</div>
                    <button className={styles.submit} onClick={() => {
                            submit(4)
                        }}>SUBMIT</button>
                </div>
            )
        }
        else if (["ADMIN", "OBD"].includes(props.user.role) && (props.statusIndex === 4 || props.statusIndex === 5)) {
            return (
                <div key={"Lock"} className={styles.wrapper}>
                    <div className={styles.statusText}>Progress is automatically saved.</div>
                    <button className={styles.submit} onClick={() => {
                                submit(5)
                        }}>REJECT</button>
                    <button className={styles.submit} onClick={() => {
                                submit(6)
                        }}>APPROVE</button>
                </div>
            )
        }
    }
    return (
        <div key={"Lock"} className={styles.wrapper}>
            <i className={["fas", "fa-lock", styles.icon].join(" ")}></i>
            <div className={styles.statusText}>Exercise is now locked for editing</div>
            <button disabled className={styles.deactive}>SUBMIT</button>
        </div>
    )
}

export default StatusLock
