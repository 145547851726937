import React, {Component} from 'react';
import styles from './css/Search.module.css'
import SearchTopBar from './components/SearchTopBar'
import SearchResults from './components/SearchResults'
import SearchLanding from '../../globalcomponents/SearchLanding'
import Loading from './../../globalcomponents/Loading'
import Axios from './../../components/Axios'
import authService from '../../api/api-authorization/AuthorizeService'

class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            spinning: false,
            ready: false,
            authenticated: false,
            criteria: null
        };
    }

    search(state) {
        this.startSearch()
        let temp = {}
        
        temp.state = Object.assign(state.search, state.filter)
        temp.user = this.props.user
        Axios(`/api/search/xus`, temp, (data) => {


            if (this.props.hashes !== undefined) {
                for (let i = 0; i < data.length; i++) {
                    let id = data[i].PROF_ID
                    
                    data[i].ctms = this.props.hashes.ctms[id]
                    data[i].crm = this.props.hashes.crm[id]
                    data[i].spend = this.props.hashes.spend[id]
                }
            }







            this.setState({ data: data, loaded: true, spinning: false, criteria: state.search});
        })
    }

    startSearch() {
        this.setState({spinning: true});
    }

    showDetails(i) {
        this.setState({Details: true, DetailsIndex: i})
    }

    showResults() {
        this.setState({Details: false})
    }

    clearResults() {
        this.setState({loaded: false})
    }

    saveState() {
        this.setState({state: this.state})
    }

    componentWillMount() {
        if (this.props.history.location.state !== undefined) {
            if (this.props.history.location.state.from.includes(this.props.match.path + '/details')) {
                this.setState(this.props.searchState)
            }
        }
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.authenticationChanged());
        this.populateAuthenticationState();

        if (this.props.history.location.state !== undefined) {
            if (this.props.history.location.state.from.includes(this.props.match.path + '/details')) {
                this.setState({reload: true})
            }
        }
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
        this.props.saveState(this.state)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.path !== this.props.match.path) {
            this.setState({
                data: null,
                loaded: false,
                spinning: false
            })
        }
    }

    addTo(index, i, added, addSpend) {
        //console.log('add to')
        let json = {}
        if (added) {
            if (i === 0) {
                json.REQUEST_TYPE = "EXUS Remove from - Spend";
            }
            else if (i === 1) {
                json.REQUEST_TYPE = "EXUS Remove from - CRM";
            }
            else if (i === 2) {
                json.REQUEST_TYPE = "EXUS Remove from - CTMS";
            }
        }
        else {
            if (i === 0) {
                json.REQUEST_TYPE = "EXUS Add to - Spend";
            }
            else if (i === 1) {
                json.REQUEST_TYPE = "EXUS Add to - CRM";
            }
            else if (i === 2) {
                json.REQUEST_TYPE = "EXUS Add to - CTMS";
            }
        }
        
        
        json.PROF_ID = this.state.data[index].PROF_ID
        this.props.hash(this.state.data[index].PROF_ID, i, !added)
        
        json.REQUESTOR_ID = this.props.user.email
        json.REQUESTOR_NAME = this.props.user.userName
        json.REQUESTOR_TEAM = this.props.user.team
        json.REQUESTOR_TERR = this.props.user.terr

        Axios('/api/global/change-request', json, (data) => {
            if (addSpend) {
                let json = {}
                json.REQUEST_TYPE = "EXUS Add to - Spend";
                if (this.props.path === '/hcp') {
                    json.PROF_ID = this.state.data[index].PROF_ID
                    this.props.hash(this.state.data[index].PROF_ID, 0, !added)
                }
                else {
                    json.ORG_ID = this.state.data[index].ORG_ID
                    this.props.hash(this.state.data[index].ORG_ID, 0, !added)
                }
                json.REQUESTOR_ID = this.props.user.email
                json.REQUESTOR_NAME = this.props.user.userName
                json.REQUESTOR_TEAM = this.props.user.team
                json.REQUESTOR_TERR = this.props.user.terr
                Axios('/api/global/change-request', json, (data) => {})
            }
        })
    }

    render() {
        let view = ""
        if (this.state.spinning) {
            view = <Loading/>
        }
        else if (this.state.loaded) {
            view = <SearchResults searchcriteria={this.state.criteria} type={this.props.match.path} addTo={this.addTo.bind(this)} saveState={this.props.saveState} searchState={this.props.searchState}  user={this.props.user} role={this.props.user.role} data={this.state.data} showDetails={this.showDetails.bind(this)} hashes={this.props.hashes}/>
        }
        else {
            view = <SearchLanding type={this.props.match.path}/>
        }
        return (
            <div className={styles.HCP_Search}>
                <SearchTopBar type={this.props.match.path} clearResults={this.clearResults.bind(this)} search={this.search.bind(this)} startSearch={this.startSearch.bind(this)} user={this.props.user}/>
                {view}
            </div>
        );
    }

    async populateAuthenticationState() {
        const authenticated = await authService.isAuthenticated();
        this.setState({ ready: true, authenticated });
    }

    async authenticationChanged() {
        this.setState({ ready: false, authenticated: false });
        await this.populateAuthenticationState();
    }
}

export default Search;
