import React, {Component} from 'react';
import styles from '../css/Details_Edit_Contact.module.css';
import validation from '../../../globalcomponents/validation';
import hcp from '../../../lookup/hcp.json'
import { buildBatchedRequests } from '../../../helper/handleBatchedRequests';
import validateRequestNotes from '../../../helper/validateRequestNotes';

class Details_Edit_Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ERROR_MESSAGE: '',
            EMAIL: this.props.data.EMAIL,
            CTMS_EMAIL: this.props.data.CTMS_EMAIL,
            TWITTER: this.props.data.TWITTER,
            MOBILE: this.props.data.MOBILE,
            WEBSITE: this.props.data.WEBSITE,
            PREV_EMAIL: this.props.data.EMAIL,
            PREV_CTMS_EMAIL: this.props.data.CTMS_EMAIL,
            PREV_TWITTER: this.props.data.TWITTER,
            PREV_MOBILE: this.props.data.MOBILE,
            PREV_WEBSITE: this.props.data.WEBSITE,
            PROF_ID: this.props.data.PROF_ID,
            REQUEST_NOTE: "",
            ISVALID: true,
            ISVALID_EMAIL: true,
            ISVALID_CTMS_EMAIL: true,
            ISVALID_TWITTER: true,
            ISVALID_WEBSITE: true,
            ISVALID_MOBILE: true,
            ISVALID_REQUEST_NOTE: true,
            changed: {},
        };

    }

    firstCapital(str) {
        if (str !== undefined) {
            return str.toLowerCase().replace(/\w\S*/g, function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            })
        }
        return "";
    }

    render() {
       // let ctmsEmailDisabled = !hcp.details.ctmsEmail.edit.teams.includes(this.props.user.team) && !hcp.details.ctmsEmail.edit.roles.includes(this.props.user.role) ? true : ''
       // let editDetailsException = !hcp.details.editDetails.edit.teams.includes(this.props.user.team) && !hcp.details.editDetails.edit.roles.includes(this.props.user.role) ? true : ''

        let editDetailsException = false;
        let ctmsEmailDisabled = false;

        let normalEmail = (<div className={styles.set}>
            <div className={styles.inputTitle}>EMAIL</div>
            <input type="email" className={this.state.ISVALID_EMAIL
                    ? styles.DefaultInputForm
                    : styles.ErrorInputForm} value={this.state.EMAIL !== "-1"
                    ? this.state.EMAIL
                    : "-1"} onChange={(event) => {
                    let temp = this.state.changed;
                    if (this.state.PREV_EMAIL !== event.target.value) {
                        if (event.target.value === "-1") {
                            temp.EMAIL = "";
                        } else {
                            temp.EMAIL = event.target.value;
                        }
                        this.setState({changed: temp});
                    } else {
                        delete temp.EMAIL;
                        this.setState({changed: temp});
                    }
                    if (event.target.value === "-1") {
                        this.setState({EMAIL: ""})
                    } else {
                        this.setState({EMAIL: event.target.value})
                    }
                }} disabled={editDetailsException}/>
        </div>)

        let ctmsEmailView = hcp.details.ctmsEmail.view
        let ctmsEmailEdit = hcp.details.ctmsEmail.edit
        let ctmsEmail = (ctmsEmailView.teams.includes(this.props.user.team) || ctmsEmailEdit.teams.includes(this.props.user.team)) || (ctmsEmailView.roles.includes(this.props.user.role) || ctmsEmailEdit.roles.includes(this.props.user.role)) ? 
        (<div className={styles.set}>
            <div className={styles.inputTitle}>CTMS EMAIL</div>
            <input type="email" className={this.state.ISVALID_CTMS_EMAIL
                    ? styles.DefaultInputForm
                    : styles.ErrorInputForm} value={this.state.CTMS_EMAIL !== "-1"
                    ? this.state.CTMS_EMAIL
                    : "-1"} onChange={(event) => {
                    let temp = this.state.changed;
                    if (this.state.PREV_CTMS_EMAIL !== event.target.value) {
                        if (event.target.value === "-1") {
                            temp.CTMS_EMAIL = "";
                        } else {
                            temp.CTMS_EMAIL = event.target.value;
                        }
                        this.setState({changed: temp});
                    } else {
                        delete temp.CTMS_EMAIL;
                        this.setState({changed: temp});
                    }
                    if (event.target.value === "-1") {
                        this.setState({CTMS_EMAIL: ""})
                    } else {
                        this.setState({CTMS_EMAIL: event.target.value})
                    }
                }} disabled={ctmsEmailDisabled}/>
        </div>) : ""

        let twitterDisabled = !hcp.details.twitter.edit.teams.includes(this.props.user.team) && !hcp.details.twitter.edit.roles.includes(this.props.user.role) ? true : ''

        let twitterView = hcp.details.twitter.view
        let twitterEdit = hcp.details.twitter.edit
        let twitter = (twitterView.teams.includes(this.props.user.team) || twitterEdit.teams.includes(this.props.user.team)) || (twitterView.roles.includes(this.props.user.role) || twitterEdit.roles.includes(this.props.user.role)) ?
            (<div className={styles.set}>
                <div className={styles.inputTitle}><i className="fab fa-twitter"></i> &nbsp; TWITTER</div>
                <input type="text" className={this.state.ISVALID_TWITTER
                    ? styles.DefaultInputForm
                    : styles.ErrorInputForm} value={this.state.TWITTER !== "-1"
                        ? this.state.TWITTER
                        : "-1"} onChange={(event) => {
                            let temp = this.state.changed;
                            if (this.state.PREV_TWITTER !== event.target.value) {
                                if (event.target.value === "-1") {
                                    temp.TWITTER = "";
                                } else {
                                    temp.TWITTER = event.target.value;
                                }
                                this.setState({ changed: temp });
                            } else {
                                delete temp.TWITTER;
                                this.setState({ changed: temp });
                            }
                            if (event.target.value === "-1") {
                                this.setState({ TWITTER: "" })
                            } else {
                                this.setState({ TWITTER: event.target.value })
                            }
                        }} disabled={twitterDisabled} placeholder={"@twitterHandle"} />
            </div>) : ""

        return (<div>
            <div className={styles.title}>
                <div></div>
                <div className={styles.TitleText}>Edit Contact</div>
                <div className={styles.close} onClick={() => {
                        this.props.close()
                    }}>
                    <i className="fas fa-times"></i>
                </div>
            </div>
            <div className={styles.body}>
            <div className={styles.required}>{this.state.ERROR_MESSAGE}</div>
                <div className={styles.middlerow}>
                    {normalEmail}
                    {ctmsEmail}
                    {twitter}
                    <div className={styles.set}>
                        <div className={styles.inputTitle}>MOBILE</div>
                        <input className={this.state.ISVALID_MOBILE
                                ? styles.DefaultInputForm
                                : styles.ErrorInputForm} value={this.state.MOBILE !== "-1"
                                ? this.state.MOBILE
                                : "-1"} onChange={(event) => {
                                let temp = this.state.changed;
                                if (this.state.PREV_MOBILE !== event.target.value) {
                                    if (event.target.value === "-1") {
                                        temp.MOBILE = "";
                                    } else {
                                        temp.MOBILE = event.target.value;
                                    }
                                    this.setState({changed: temp});
                                } else {
                                    delete temp.MOBILE;
                                    this.setState({changed: temp});
                                }
                                if (event.target.value === "-1") {
                                    this.setState({MOBILE: ""})
                                } else {
                                    this.setState({MOBILE: event.target.value})
                                }
                            }} disabled={editDetailsException} placeholder={"xxx-xxx-xxxx"}/>
                    </div>
                    <div className={styles.set}>
                        <div className={styles.inputTitle}>WEBSITE</div>
                        <input className={this.state.ISVALID_WEBSITE
                                ? styles.DefaultInputForm
                                : styles.ErrorInputForm} value={this.state.WEBSITE !== "-1"
                                ? this.state.WEBSITE
                                : "-1"} onChange={(event) => {
                                let temp = this.state.changed;
                                if (this.state.PREV_WEBSITE !== event.target.value) {
                                    if (event.target.value === "-1") {
                                        temp.WEBSITE = "";
                                    } else {
                                        temp.WEBSITE = event.target.value;
                                    }
                                    this.setState({changed: temp});
                                } else {
                                    delete temp.WEBSITE;
                                    this.setState({changed: temp});
                                }
                                if (event.target.value === "-1") {
                                    this.setState({WEBSITE: ""})
                                } else {
                                    this.setState({WEBSITE: event.target.value})
                                }
                            }} disabled={editDetailsException}/>
                    </div>

                </div>

                <div className={styles.set}>
                    <div className={styles.inputTitle}>REVISION REASON</div>
                    <input className={this.state.ISVALID_REQUEST_NOTE
                            ? styles.DefaultInputForm
                            : styles.ErrorInputForm} value={this.state.REQUEST_NOTE} onChange={(event) => {
                            let temp = this.state.changed;
                            if (this.state.REQUEST_NOTE !== event.target.value) {
                                if (event.target.value === "-1") {
                                    temp.REQUEST_NOTE = "";
                                } else {
                                    temp.REQUEST_NOTE = event.target.value;
                                }
                                this.setState({changed: temp});
                            } else {
                                delete temp.REQUEST_NOTE;
                                this.setState({changed: temp});
                            }
                            if (event.target.value === "-1") {
                                this.setState({REQUEST_NOTE: ""})
                            } else {
                                this.setState({ REQUEST_NOTE: validateRequestNotes(event.target.value) });
                            }

                        }}/>
                </div>

                <div className={styles.buttonRow}>
                    <div id={styles.cancel} onClick={() => {
                            this.props.close();
                        }}>CANCEL</div>
                    <div className={this.state.Wiggle && this.state.Wiggle !== undefined
                            ? styles.Wiggle
                            : ""} id={styles.submit} onClick={() => {

                            validation.validateContactForm(this.state.changed, this.state, (updatedState) => {
                                this.setState(updatedState);
                                if (Object.keys(this.state.changed).length > 0 && updatedState.ISVALID) {
                                    let changed = this.state.changed
                                    let requestData = {
                                        PROF_ID: this.state.PROF_ID,
                                        REQUESTOR_ID: this.props.user.email,
                                        REQUESTOR_NAME: this.props.user.userName,
                                        REQUESTOR_TEAM: this.props.user.team,
                                        REQUESTOR_TERR: this.props.user.terr,
                                        REQUEST_NOTE: this.state.REQUEST_NOTE
                                    }
                                    let batch = {
                                        PROF_ID: this.state.PROF_ID,
                                        REQUESTOR_ID: this.props.user.email,
                                        REQUESTOR_NAME: this.props.user.userName,
                                        REQUESTOR_TEAM: this.props.user.team,
                                        REQUESTOR_TERR: this.props.user.terr,
                                        REQUEST_TYPE: 'HCP Update Info'
                                    }
                                    buildBatchedRequests(requestData, changed, batch, this.props.submit.bind(this), "HCP")
                                } else {
                                    let errorMessages = ''
                                    if (!updatedState.ISVALID_EMAIL) {
                                        errorMessages += 'Please enter a valid email address. A valid email address must contain an \'@\' symbol.\n'
                                    }
                                    if (!updatedState.ISVALID_CTMS_EMAIL) {
                                        errorMessages += 'Please enter a valid CTMS email address. A valid email address must contain an \'@\' symbol.\n'
                                    }
                                    if (!updatedState.ISVALID_TWITTER) {
                                        errorMessages += 'Please enter a valid twitter handle. A valid twitter handle must begin with an \'@\' symbol.\n'
                                    }
                                    if (!updatedState.ISVALID_MOBILE) {
                                        errorMessages += 'Please enter a valid mobile number. A valid mobile number must contain only digits and follow this format: xxx-xxx-xxxx.\n'
                                    }
                                    if (!updatedState.ISVALID_WEBSITE) {
                                        errorMessages += 'Please enter a valid website.\n'
                                    }
                                    this.setState({
                                        Wiggle: true, 
                                        ISVALID_EMAIL: updatedState.ISVALID_EMAIL, 
                                        ISVALID_CTMS_EMAIL: updatedState.ISVALID_CTMS_EMAIL, 
                                        ISVALID_TWITTER: updatedState.ISVALID_TWITTER,
                                        ISVALID_MOBILE: updatedState.ISVALID_MOBILE, 
                                        ISVALID_WEBSITE: updatedState.ISVALID_WEBSITE, 
                                        ISVALID_REQUEST_NOTE: updatedState.ISVALID_REQUEST_NOTE, 
                                        ERROR_MESSAGE: errorMessages})
                                    setTimeout(() => {
                                        this.setState({Wiggle: false})
                                    }, 500);
                                }

                            })

                        }}>SUBMIT</div>
                </div>

            </div>
        </div>)
    }

}

export default Details_Edit_Contact;
