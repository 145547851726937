import React, { Component } from 'react';
import styles from '../css/AddExUs_P3.module.css';
import Axios from './../../../components/Axios'
import CountryLookup from '../../../lookup/countrylookup';
import countrystatelookup from '../../../lookup/countrystatelookup';
import StateLookUp from './../../../globalcomponents/StateLookUp'

class AddEXUS_P3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedAddress: 'validated'

        };
    }

    componentDidMount() {



    }
    addTo() {

        let json = {}

        let team = this.props.user.team == "unassigned" || this.props.user.team == '' || this.props.user.team == '00' ? 11 : this.props.user.team;
        json.REQUEST_TYPE = this.props.info.REQUEST_TYPE;
        json.FIRST_NAME = this.props.info.FIRST_NAME;
        json.MIDDLE_NAME = this.props.info.MIDDLE_NAME;
        json.LAST_NAME = this.props.info.LAST_NAME;
        json.COUNTRY_CODE = this.props.info.entCOUNTRY_CODE;
        json.NEW_VALUE = this.props.info.entCOUNTRY_CODE;

        if (this.props.info.validated == true) {


            if (this.state.selectedAddress == 'validated') {
                json.ADDRESS1 = this.props.info.valADDRESS1;
                json.ADDRESS2 = this.props.info.valADDRESS2;
                json.CITY = this.props.info.valCITY;
                json.STATE = this.props.info.entSTATE;
                json.ZIPCODE = this.props.info.valZIPCODE;
            }else {

                json.ADDRESS1 = this.props.info.entADDRESS1;
                json.ADDRESS2 = this.props.info.entADDRESS2;
                json.CITY = this.props.info.entCITY;
                json.STATE = this.props.info.entSTATE;
                json.ZIPCODE = this.props.info.entZIPCODE;
            }


        } else {
            json.ADDRESS1 = this.props.info.entADDRESS1;
            json.ADDRESS2 = this.props.info.entADDRESS2;
            json.CITY = this.props.info.entCITY;
            json.STATE = this.props.info.entSTATE;
            json.ZIPCODE = this.props.info.entZIPCODE;


        }
        json.EMAIL = this.props.info.EMAIL;
        json.DEGREE = this.props.info.DEGREE;
        json.SPECIALTY = this.props.info.SPECIALTY;
        json.MELISSA_ENGINE_INFO = this.props.info.MELISSA_ENGINE_INFO;
        json.REQUEST_NOTE = this.props.info.REQUEST_NOTE;
        json.SUFFIX = this.props.info.SUFFIX;
        json.REQUESTOR_ID = this.props.user.email
        json.REQUESTOR_NAME = this.props.user.userName
        json.REQUESTOR_TEAM = team
        json.REQUESTOR_TERR = this.props.user.terr

        Axios('/api/global/change-request', json, (data) => {

            console.log(data);

        })
    }
    getStateName(stateKey) {
        let StateName = "";
        if (stateKey != "") {

            StateName = countrystatelookup[stateKey];

        }
        return StateName;
    }

    addressAreSame() {

        if (this.props.info.entADDRESS1.trim() == this.props.info.valADDRESS1.trim() &&
            this.props.info.entZIPCODE.trim() == this.props.info.valZIPCODE.trim() &&
            this.props.info.entCITY.trim() == this.props.info.valCITY.trim()) {

            return true

        }
        else {
            return false;
        }

    }
    noValidatedAddress() {

        if (this.props.info.valADDRESS1.trim() == '' &&
            this.props.info.valZIPCODE.trim() == '' &&
            this.props.info.valCITY.trim() == '') {

            return true

        }
        else {
            return false;
        }

    }
    render() {

        let statusMsg = "";
        if (this.props.info.validated) {
            statusMsg = "verified";
        } else {
            statusMsg = "not verified";
        }

        let addressBlock = [];

        if (this.props.info.validated == true) {


            if (this.addressAreSame()) {

                addressBlock = (
                    <div className={styles.set} >
                        <div className={styles.contentTitle}>ADDRESS</div>
                        <div className={styles.content}>
                            <div className={styles.addressblocktitle}>Address Validated</div>
                            {this.props.info.valADDRESS1} &nbsp;

                        {this.props.info.entADDRESS2} <br />
                            {this.props.info.valCITY} &nbsp;
                                        {CountryLookup[this.props.info.entCOUNTRY_CODE]} &nbsp;
                                        {this.getStateName(this.props.info.valSTATE)} &nbsp;
                                        {this.props.info.valZIPCODE}

                        </div>

                    </div>)


            } else {


                addressBlock = (<div className={styles.set} >
                    <div className={styles.contentTitle}>ADDRESS</div>
                    <div className={styles.content}>

                        <div className={styles.addressblocktitle}> <input defaultChecked type="radio" id="addType" name="addType" value="verified"
                            onClick={() => {this.setState({selectedAddress: 'verified'})
                            }}

                        />&nbsp; VALIDATED ADDRESS</div>


                        {this.props.info.valADDRESS1} &nbsp;

                        {this.props.info.entADDRESS2} <br />
                        {this.props.info.valCITY} &nbsp;
                                        {CountryLookup[this.props.info.entCOUNTRY_CODE]} &nbsp;
                                        {this.getStateName(this.props.info.valSTATE)} &nbsp;
                                        {this.props.info.valZIPCODE}

                        <div className={styles.addressblocktitle}> <br /><input type="radio" id="addType" name="addType" value="entered"
                            onClick={() => {this.setState({selectedAddress: 'entered'})
                            }}/>&nbsp; ENTERED  ADDRESS</div>

                        {this.props.info.entADDRESS1} &nbsp;
                        {this.props.info.entADDRESS2} <br />
                        {this.props.info.entCITY} &nbsp;
                                        {CountryLookup[this.props.info.entCOUNTRY_CODE]} &nbsp;
                                        {this.getStateName(this.props.info.entSTATE)} &nbsp;
                                        {this.props.info.entZIPCODE}

                    </div>

                </div>)

            }



        } else {

                addressBlock = (<div className={styles.set} >
                    <div className={styles.contentTitle}>ADDRESS</div>
                    <div className={styles.content}>
                        <div className={styles.addressblocktitle}>Address could not be validated</div>

                        {this.props.info.entADDRESS1} &nbsp;

                        {this.props.info.entADDRESS2} <br />
                        {this.props.info.entCITY} &nbsp;
                                        {CountryLookup[this.props.info.entCOUNTRY_CODE]} &nbsp;
                                        {this.getStateName(this.props.info.entSTATE)} &nbsp;
                                        {this.props.info.entZIPCODE}

                        </div>

                </div>)
        }

        return (
            <div className={styles.editScreen}>

                <div className={styles.wrapper}>
                    <div className={styles.title}>
                        <div></div>
                        <div className={styles.TitleText}>Add Ex-US Professional</div>
                        <div className={styles.close} onClick={() => {
                            this.props.close()
                        }}><i className="fas fa-times"></i></div>
                    </div>
                    <div className={styles.body}>
                        <div className={styles.toprow}>
                            <div className={[styles.set, styles.mainTitle].join(' ')}>
                                <div className={styles.titleText}>Please confirm the details:</div>
                            </div>
                        </div>

                        <div className={styles.middlerow}>
                            <div className={[styles.set, styles.odd].join(' ')}>
                                <div className={styles.contentTitle}>NAME</div>
                                <div className={styles.content}>{this.props.info.FIRST_NAME}{this.props.info.MIDDLE_NAME !== -1 ? ` ${this.props.info.MIDDLE_NAME} ` : " "}{this.props.info.LAST_NAME}</div>
                            </div>

                            {addressBlock}

                            <div className={[styles.set, styles.odd].join(' ')}>
                                <div className={styles.contentTitle}>SPECIALTY</div>
                                <div className={styles.content}>{this.props.info.SPECIALTY}</div>
                            </div>
                            <div className={styles.set}>
                                <div className={styles.contentTitle}>DEGREE</div>
                                <div className={styles.content}>{this.props.info.DEGREE}</div>
                            </div>

                            <div className={[styles.set, styles.odd].join(' ')}>
                                <div className={styles.contentTitle}>COMMENTS</div>
                                <div className={styles.content}>{this.props.info.REQUEST_NOTE === -1 ? "" : this.props.info.REQUEST_NOTE}</div>
                            </div>
                        </div>

                        <div className={styles.buttonRow}>
                            <div id={styles.cancel} onClick={() => {
                                this.props.nextPage(1);
                            }}>BACK</div>
                            <div id={styles.submit} onClick={() => {

                                this.addTo();
                                this.props.nextPage(3);


                            }}>SUBMIT</div>
                        </div>
                    </div>
                </div>

            </div>

        )
    }

}

export default AddEXUS_P3;
