import React, {useState} from 'react'
import styles from '../css/EditStatus.module.css'

function Input(props) {
    const [data, setData] = useState(props.data)
    return (
        <input className={props.isValid || data[props.type] !== "" ? styles.default : styles.error} value={data[props.type]} onChange={(event) => {
            let temp = {...data}
            temp[props.type] = event.target.value
            setData(temp)
            let changes = props.changes
            if (changes[temp.STATUS_ID - 1]) {
                changes[temp.STATUS_ID - 1][props.type] = event.target.value
            }
            else {
                changes[temp.STATUS_ID - 1] = temp
            }
            props.setChanges(changes)
        }}></input>
    )

}

export default Input
