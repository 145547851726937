import React, {Component} from 'react';
import styles from '../css/AdminRegionalDelete.module.css';
import Axios from '../../../components/Axios';

class AdminRegionalAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    deleteExercise() {
        let temp = {}
        temp = this.props.data
        Axios('/api/admin/delete-regional-plan', temp, (data) => {
            this.props.setData(data);
        });
        this.props.close()
    }

    render() {
        return (
            <div className={styles.editScreen}>
                <div className={styles.title}>
                    <div></div>
                    <div className={styles.TitleText}>Delete Exercise</div>
                    <div className={styles.close} onClick={() => {
                        this.props.close()
                    }}><i className="fas fa-times"></i></div>
                </div>
                <form>
                    <div className={styles.body}>
                        <div className={styles.errorMessage}>{this.state.ERROR_MESSAGE}</div>
                        <div className={styles.toprow}>
                            <div className={styles.set}>
                                <div className={styles.inputTitle}>Are you sure you want to delete</div>
                                <div className={styles.subTitle}>{this.props.data.NAME}</div>
                            </div>
                        </div>
                        <div className={styles.buttonRow}>
                            <div id={styles.cancel}
                                onClick={() => {
                                    this.props.close()
                                }
                            }>CANCEL</div>
                            <div type="submit"
                                id={styles.submit}
                                className={this.state.Wiggle && this.state.Wiggle !== undefined ? styles.Wiggle : ""}
                                onClick={() => {
                                    this.deleteExercise()
                                }
                            }>DELETE</div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }

}

export default AdminRegionalAdd;
