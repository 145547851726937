import React, {useState} from 'react'
import {Link} from "react-router-dom";
import styles from '../css/Result.module.css'
import TargetColorBox from './TargetColorBox'
import ChangeTargetDrop from './ChangeTargetDrop'
import Axios from '../../../../components/Axios/Axios'

function Result(props) {

    const [custom, setCustom] = useState(props.data)

    let name = null
    let address = null
    name = `${props.data.FIRST_NAME}${props.data.MIDDLE_NAME ? " " + props.data.MIDDLE_NAME : ""} ${props.data.LAST_NAME}`

    let address1 = props.data.ADDRESS !== null && props.data.ADDRESS !== undefined ? `${props.data.ADDRESS}, ` : ""
    let city = props.data.CITY !== null && props.data.CITY !== undefined ? `${props.data.CITY}, ` : ""
    let state = props.data.STATE !== null && props.data.STATE !== undefined ? `${props.data.STATE} ` : ""
    let zip = props.data.ZIP_CODE !== null && props.data.ZIP_CODE !== undefined ? `${props.data.ZIP_CODE}` : ""

    address = address1 + city + state + zip

    const updateCustomColumn = (temp) => {

      //  console.log('1');

        Axios(`/api/targeting/update-custom-columns`, temp, (data) => {
            if (data) {

             //   console.log('2');

                //for (let i = 0; i < data.length; i++) {
                //    let customColumns = JSON.parse(data[i].CUSTOM_COLUMNS)
                //    data[i] = {
                //        ...data[i],
                //        ...customColumns
                //    }
                //}
//
         //       console.log('3');

                props.getTotals()

                //console.log('4');
            }
        })
    }

    let cols = []
    for (let i = 0; i < Object.keys(props.config).length; i++) {
        if (Object.values(props.config)[i].show) {
            let value = Object.values(props.config)[i]
            if (value.title === "HEALTHCARE PROFESSIONAL") {
                cols.push(
                    <td key={value.title} className={[styles.col, props.selected === i ? styles.selected : ""].join(" ")} style={{width: window.innerWidth * (value.flex / 100)}}>
                        <div className={styles.info}>
                            {props.preview
                                ?
                                <div className={styles.name}>
                                    {name}
                                </div>
                                :
                                <Link to={{
                                    pathname: `/hcp/details/${props.data.PROF_ID}`,
                                    state: {
                                        from: props.location.pathname
                                    }
                                }}>
                                    <div className={styles.name}>
                                        {name}
                                    </div>
                                </Link>
                            }
                            <div className={styles.address}>{address}</div>
                        </div>
                    </td>
                )
            }
            else if (value.col === "CURRENT_TARGET_VALUE") {
                cols.push(
                    <td className={[styles.col, styles.center, props.selected === i ? styles.selected : ""].join(' ')} key={value.title} style={{width: window.innerWidth * (value.flex / 100)}}>
                        <div className={styles.flexcenter}>
                            <TargetColorBox small target={props.data[value.col]} />
                        </div>
                    </td>
                )
            }
            else if (value.col === "CHANGED_TARGET_VALUE") {
                cols.push(
                    <td className={[styles.col, styles.center, props.selected === i ? styles.selected : ""].join(' ')} key={value.title} style={{width: window.innerWidth * (value.flex / 100)}}>
                        <div className={styles.flexcenter}>
                            <ChangeTargetDrop {...props}/>
                        </div>
                    </td>
                )
            }
            else {
                if (value.options === "LOV") {
                    let options = [<option key={"blank"} value={""}></option>]
                    let start = value.headerChecked ? 1 : 0
                    for (let j = start; j < value.LOV.length; j++) {
                        options.push(
                            <option key={value.LOV[j]} value={value.LOV[j]}>{value.LOV[j]}</option>
                        )
                    }
                    cols.push(
                        <td key={value.title} className={[styles.col, styles.center, props.selected ? styles.selected === i : ""].join(' ')} style={{width: window.innerWidth * (value.flex / 100)}}>
                            <div className={styles.flexcenter}>
                                <select className={styles.setInput}  value={custom[value.col]} onChange={(event) => {
                                        let temp = {
                                            targeting_id: props.id,
                                            source_id: props.data.SOURCE_ID,
                                            terr_id: props.data.TERR_ID,
                                            status_id: props.status.STATUS_ID,
                                            mdmID: props.data.PROF_ID,
                                            key: value.col,
                                            value: event.target.value
                                    }
                                  //  alert('click - updateCustomColumn is next');
                                    updateCustomColumn(temp)
                                  //  alert('setCustom next');
                                        setCustom(event.target.value)
                                    }} disabled={props.lock}>
                                    {options}
                                </select>
                            </div>
                        </td>
                    )
                }
                else if (value.options === "FreeText") {
                    cols.push(
                        <td key={value.title} className={[styles.col, styles.center, props.selected ? styles.selected === i : ""].join(' ')} style={{width: window.innerWidth * (value.flex / 100)}}>
                            <div className={styles.flexcenter}>
                                <input className={styles.setInput} value={custom[value.col]} onChange={(event) => {
                                    //let temp = {
                                    //    targeting_id: props.id,
                                    //    source_id: props.data.SOURCE_ID,
                                    //    terr_id: props.data.TERR_ID,
                                    //    status_id: props.status.STATUS_ID,
                                    //    mdmID: props.data.PROF_ID,
                                    //    key: value.col,
                                    //    value: event.target.value
                                    //}
                                    //updateCustomColumn(temp)

                                    setCustom(event.target.value)
                                }} onBlur={(event) => {
                                    let temp = {
                                        targeting_id: props.id,
                                        source_id: props.data.SOURCE_ID,
                                        terr_id: props.data.TERR_ID,
                                        status_id: props.status.STATUS_ID,
                                        mdmID: props.data.PROF_ID,
                                        key: value.col,
                                        value: event.target.value
                                    }
                                    updateCustomColumn(temp)
                                    setCustom(event.target.value)
                                }}disabled={props.lock}/>
                            </div>
                        </td>
                    )
                }
                else {
                    cols.push(<td className={[styles.col, styles.center, props.selected === i ? styles.selected : ""].join(' ')} key={value.title} style={{width: window.innerWidth * (value.flex / 100)}}>
                        <div className={styles.flexcenter}>
                            {props.data[value.col]}
                        </div>
                    </td>)
                }

            }
        }
    }
    return (
        <tr className={styles.Result}>
            {cols}
        </tr>
    )
}

export default Result
