import React, { Component } from 'react';
import styles from '../css/OneKeyConfirmation.module.css';
import Axios from './../../../components/Axios'
import CountryLookup from '../../../lookup/countrylookup';
import countrystatelookup from '../../../lookup/countrystatelookup';
import StateLookUp from './../../../globalcomponents/StateLookUp'
import SpecialtyLookUp from '../../../globalcomponents/SpecialtyLookUp';

class OneKeyConfirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedAddress: 'validated'

        };
    }

    componentDidMount() {



    }
    addTo() {

        let json = {}

        let j = this.props.data.SPECIALTY.toUpperCase();

        let team = this.props.user.team == "unassigned" || this.props.user.team == '' || this.props.user.team == '00' ? 11 : this.props.user.team;
        json.REQUEST_TYPE = 'OneKey HCP Add - Spend';
        json.FIRST_NAME = this.props.data.FIRST_NAME;
        json.LAST_NAME = this.props.data.LAST_NAME;
        json.COUNTRY = this.props.data.COUNTRY;
        json.REQUEST_REASON = this.state.REQUEST_NOTE;
        json.ADDRESS1 = this.props.data.ADDRESS1;
        json.ADDRESS2 = this.props.data.ADDRESS2;
        json.CITY = this.props.data.CITY;
        json.STATE = this.props.data.STATE;
        json.ZIPCODE = this.props.data.POSTAL_CODE;
        json.REQUESTOR_ID = this.props.user.email
        json.REQUESTOR_NAME = this.props.user.userName
        json.REQUESTOR_TEAM = team
        json.REQUESTOR_TERR = this.props.user.terr
        json.ONEKEY_ID = this.props.data.ONEKEY_ID;
        json.DEGREE = 'UNK';
        json.ONEKEY_ID = this.props.data.ONEKEY_ID;
        json.ONEKEY_JSON = `${this.props.data.FULL_REQUEST}`;
        json.SPECIALTY = this.props.data.SPECIALTY;
        

        //for (let i = 0; i < Object.keys(SpecialtyLookUp).length; i++) {


        //    let x = Object.values(SpecialtyLookUp)[i].toUpperCase();



        //    if (x == j) {

                

        //        json.SPECIALTY = Object.keys(SpecialtyLookUp)[i];
        //    }
        //}


       
        Axios('/api/global/change-request', json, (data) => {





            console.log(data);



        })


        let jsonadd = {}
        jsonadd.ONEKEY_ID = this.props.data.ONEKEY_ID;

        Axios('/api/search/addtoonekey', jsonadd, (retdata) => {

            console.log(retdata);
        })

    }

    render() {

        let addressBlock = [];

        addressBlock = (
            <div className={styles.set} >
                <div className={styles.contentTitle}>ADDRESS</div>
                <div className={styles.newcontent}>

                    {this.props.data.ADDRESS1} &nbsp;
                    {this.props.data.ADDRESS2} <br />
                    {this.props.data.CITY} &nbsp;
                    {CountryLookup[this.props.data.COUNTRY]} &nbsp;
                    {this.props.data.STATE} &nbsp;
                    {this.props.data.POSTAL_CODE}

                </div>

            </div>)

        return (
            <div className={styles.editScreen}>

                <div className={styles.wrapper}>
                    <div className={styles.title}>
                        <div></div>
                        <div className={styles.TitleText}>Add OneKey Professional</div>
                        <div className={styles.close} onClick={() => {
                            this.props.close()
                        }}><i className="fas fa-times"></i></div>
                    </div>
                    <div className={styles.body}>
                        <div className={styles.toprow}>
                            <div className={[styles.mainTitle].join(' ')}>
                                <div className={styles.titleText}>Please confirm the details:</div>
                            </div>
                        </div>

                        <div className={styles.middlerow}>
                            <div className={[styles.set, styles.odd].join(' ')}>
                                <div className={styles.contentTitle}>NAME</div>
                                <div className={styles.newcontent}>{this.props.data.FIRST_NAME} {this.props.data.LAST_NAME}</div>
                            </div>
                            <div >
                                {addressBlock}


                            </div>

                        </div> <div className={styles.middlerow}>
                            <div className={styles.set}>
                                <div className={styles.inputTitle}>COMMENTS</div>
                                <textarea className={styles.DefaultInputForm} onChange={(event) => {
                                    if (event.target.value === "") {
                                        this.setState({ REQUEST_NOTE: "" })
                                    }
                                    else {
                                        this.setState({ REQUEST_NOTE: event.target.value })
                                    }
                                }} />
                            </div>

                        </div>

                        <div className={styles.buttonRow}>
                            <div id={styles.cancel} onClick={() => {
                                this.props.close();
                            }}>CANCEL</div>
                            <div id={styles.submit} onClick={() => {

                                this.addTo();
                                this.props.showSuccess();
                            }}>SUBMIT</div>
                        </div>
                    </div>
                </div>

            </div>

        )
    }

}

export default OneKeyConfirmation;
