import React, { Component } from 'react';
import styles from '../css/Details_Edit_Personal.module.css';
import DegreeLookUp from '../../../globalcomponents/DegreeLookUp';
import firstCapital from '../../../helper/firstCapital';
import { buildBatchedRequests } from '../../../helper/handleBatchedRequests';
import validateRequestNotes from '../../../helper/validateRequestNotes';

class Details_Edit_Personal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            FIRST_NAME: firstCapital(this.props.data.FIRST_NAME),
            MIDDLE_NAME: firstCapital(this.props.data.MIDDLE_NAME),
            LAST_NAME: firstCapital(this.props.data.LAST_NAME),
            STATUS: this.getStatus(this.props.data.STATUS),
            DEGREE: this.props.data.DEGREE,
            BIRTHYEAR: firstCapital(this.props.data.BIRTHYEAR),
            GENDER: this.props.data.GENDER,
            PREV_FIRST_NAME: firstCapital(this.props.data.FIRST_NAME),
            PREV_MIDDLE_NAME: firstCapital(this.props.data.MIDDLE_NAME),
            PREV_LAST_NAME: firstCapital(this.props.data.LAST_NAME),
            PREV_STATUS: firstCapital(this.props.data.STATUS),
            PREV_DEGREE: this.props.data.DEGREE,
            PREV_BIRTHYEAR: firstCapital(this.props.data.BIRTHYEAR),
            PREV_GENDER: this.props.data.GENDER,
            REQUEST_NOTE: '',
            changed: {},
            // STATUS_CHANGED: false,
            ERROR_MESSAGE: "",
            IsValid: true
        };
    }

    getStatus(status) {
        let newStatus = ''
        if (status === undefined || status === null) {
            newStatus = 'Active'
        }
        else if (status === 'DEAD') {
            newStatus = 'Deceased'
        }
        else if (status === 'RETIRED') {
            newStatus = 'Retired'
        }
        else {
            newStatus = 'Inactive'
        }
        return newStatus
    }

    isValidForm() {


        let isValid = false;

        if (Object.keys(this.state.changed).length < 1) {
            this.setState({ ERROR_MESSAGE: 'No changes exist \n' });
            return false;

        }
        else {

            //required
            if (this.state.FIRST_NAME === -1) {
                this.setState({ ERROR_MESSAGE: 'First name is required \n' });
                return false;
            }
            if (this.state.LAST_NAME === -1) {
                this.setState({ ERROR_MESSAGE: 'Last name is required \n' });
                return false;
            }


            //Max length
            if (this.state.FIRST_NAME !== -1 && this.state.FIRST_NAME.length > 50) {
                this.setState({ ERROR_MESSAGE: 'First name should be less than 50 characters \n' });
                return false;
            }
            if (this.state.LAST_NAME !== -1 && this.state.LAST_NAME.length > 50) {
                this.setState({ ERROR_MESSAGE: 'Last name should be less than 50 characters \n' });
                return false;
            }
            if (this.state.MIDDLE_NAME.length > 50) {
                this.setState({ ERROR_MESSAGE: 'Middle name should be less than 50 characters \n' });
                return false;
            }


            //required for status change
            if (this.state.changed.hasOwnProperty('STATUS') && this.state.REQUEST_NOTE.length < 1) {
                this.setState({ ERROR_MESSAGE: 'Request Reason is required for a STATUS change \n' });
                return false;
            }


            //cleanup checks

            if (this.state.FIRST_NAME !== -1 && (this.state.FIRST_NAME.includes("\t") || this.state.FIRST_NAME.includes("\r\n"))) {
                this.setState({ ERROR_MESSAGE: 'First name contains tab or line space characters. Please remove the  characters \n' });
                return false;
            }


            if (this.state.LAST_NAME !== -1 && (this.state.LAST_NAME.includes("\t") || this.state.LAST_NAME.includes("\r\n"))) {
                this.setState({ ERROR_MESSAGE: 'Last name contains tab or line characters. Please remove the characters \n' });
                return false;
            }
            if (this.state.MIDDLE_NAME !== -1 && (this.state.MIDDLE_NAME.includes("\t") || this.state.MIDDLE_NAME.includes("\r\n"))) {
                this.setState({ ERROR_MESSAGE: 'Middle name contains tab or line characters. Please remove characters \n' });
                return false;
            }

        }

        return true;

    }

    render() {
        let DegreeOptions = []
        for (var i = 0; i < Object.keys(DegreeLookUp).length; i++) {
            DegreeOptions.push(<option key={i} value={Object.keys(DegreeLookUp)[i]}>{Object.keys(DegreeLookUp)[i]} - {Object.values(DegreeLookUp)[i]}</option>)
        }
        return (
            <div>
                <div className={styles.title}>
                    <div></div>
                    <div className={styles.TitleText}>Edit Personal</div>
                    <div className={styles.close} onClick={() => {
                        this.props.close()
                    }}><i className="fas fa-times"></i></div>
                </div>
                <div className={styles.body}>
                    <div className={styles.required}>{this.state.ERROR_MESSAGE}</div>
                    <div className={styles.required}>REQUIRED FIELD *</div>
                    <div className={styles.toprow}>
                        <div className={styles.set}>
                            <div className={styles.inputTitle}>FIRST NAME <div className={styles.required}>*</div></div>
                            <input className={this.state.IsValid ? styles.DefaultInputForm : styles.ErrorInputForm} value={this.state.FIRST_NAME !== -1 ? this.state.FIRST_NAME : ""} onChange={(event) => {
                                let temp = this.state.changed;
                                if (this.state.PREV_FIRST_NAME !== event.target.value) {
                                    temp.FIRST_NAME = event.target.value;
                                    this.setState({ changed: temp });
                                }
                                else {
                                    delete temp.FIRST_NAME;
                                    this.setState({ changed: temp });
                                }
                                if (event.target.value === "") {
                                    this.setState({ FIRST_NAME: -1 })
                                }
                                else {
                                    this.setState({ FIRST_NAME: event.target.value })
                                }
                            }} />
                        </div>
                        <div className={styles.set}>
                            <div>MIDDLE NAME</div>
                            <input className={styles.DefaultInputForm} value={this.state.MIDDLE_NAME !== -1 ? this.state.MIDDLE_NAME : ""} onChange={(event) => {
                                let temp = this.state.changed;
                                if (this.state.PREV_MIDDLE_NAME !== event.target.value) {
                                    temp.MIDDLE_NAME = event.target.value;
                                    this.setState({ changed: temp });
                                }
                                else {
                                    delete temp.MIDDLE_NAME;
                                    this.setState({ changed: temp });
                                }
                                if (event.target.value === "") {
                                    this.setState({ MIDDLE_NAME: -1 })
                                }
                                else {
                                    this.setState({ MIDDLE_NAME: event.target.value })
                                }
                            }} />
                        </div>
                        <div className={styles.set}>
                            <div className={styles.inputTitle}>LAST NAME <div className={styles.required}>*</div></div>
                            <input className={this.state.IsValid ? styles.DefaultInputForm : styles.ErrorInputForm} value={this.state.LAST_NAME !== -1 ? this.state.LAST_NAME : ""} onChange={(event) => {
                                let temp = this.state.changed;
                                if (this.state.PREV_LAST_NAME !== event.target.value) {
                                    temp.LAST_NAME = event.target.value;
                                    this.setState({ changed: temp });
                                }
                                else {
                                    delete temp.LAST_NAME;
                                    this.setState({ changed: temp });
                                }
                                if (event.target.value === "") {
                                    this.setState({ LAST_NAME: -1 })
                                }
                                else {
                                    this.setState({ LAST_NAME: event.target.value })
                                }
                            }} />
                        </div>
                    </div>

                    <div className={styles.middlerow}>
                        <div className={styles.set}>
                            <div className={styles.inputTitle}>STATUS</div>
                            <select className={styles.DefaultInputForm} value={this.state.STATUS} onChange={(event) => {
                                let temp = this.state.changed;

                                if (this.state.PREV_STATUS !== event.target.value) {
                                    temp.STATUS = event.target.value;
                                    this.setState({ changed: temp });
                                }
                                else {
                                    delete temp.STATUS;
                                    this.setState({ changed: temp });
                                }
                                this.setState({ STATUS: event.target.value })
                            }}>
                                <option value="Active">Active</option>
                                <option value="Retired">Retired</option>
                                <option value="Deceased">Deceased</option>
                                <option value="Inactive">Inactive</option>
                            </select>
                        </div>
                        <div className={styles.set}>
                            <div className={styles.inputTitle}>DEGREE</div>
                            <select className={styles.DefaultInputForm} value={this.state.DEGREE !== -1 ? this.state.DEGREE : ""} onChange={(event) => {
                                let temp = this.state.changed;
                                if (this.state.PREV_DEGREE !== event.target.value) {
                                    temp.DEGREE = event.target.value;
                                    this.setState({ changed: temp });
                                }
                                else {
                                    delete temp.DEGREE;
                                    this.setState({ changed: temp });
                                }
                                if (event.target.value === "") {
                                    this.setState({ DEGREE: -1 })
                                }
                                else {
                                    this.setState({ DEGREE: event.target.value })
                                }
                            }}>
                                <option value="-1"></option>
                                {DegreeOptions}
                            </select>
                        </div>

                    </div>

                    <div className={styles.set}>
                        <div className={styles.inputTitle}>REVISION REASON</div>
                        <input className={styles.DefaultInputForm} value={this.state.REQUEST_NOTE} onChange={(event) => {
                            this.setState({ REQUEST_NOTE: validateRequestNotes(event.target.value) });
                        }} />
                    </div>

                    <div className={styles.buttonRow}>
                        <div id={styles.cancel} onClick={() => {
                            this.props.close();
                        }}>CANCEL</div>
                        <div className={this.state.Wiggle && this.state.Wiggle !== undefined ? styles.Wiggle : ""} id={styles.submit} onClick={() => {


                            if (this.isValidForm()) {

                                // console.log('Y');

                                let changed = this.state.changed
                                let requestData = {
                                    PROF_ID: this.props.data.PROF_ID,
                                    REQUESTOR_ID: this.props.user.email,
                                    REQUESTOR_NAME: this.props.user.userName,
                                    REQUESTOR_TEAM: this.props.user.team,
                                    REQUESTOR_TERR: this.props.user.terr,
                                    REQUEST_NOTE: this.state.REQUEST_NOTE
                                }
                                let batch = {
                                    PROF_ID: this.props.data.PROF_ID,
                                    REQUESTOR_ID: this.props.user.email,
                                    REQUESTOR_NAME: this.props.user.userName,
                                    REQUESTOR_TEAM: this.props.user.team,
                                    REQUESTOR_TERR: this.props.user.terr,
                                    REQUEST_TYPE: 'HCP Update Info'
                                }
                                buildBatchedRequests(requestData, changed, batch, this.props.submit.bind(this), "HCP")


                            }
                            else {
                                //  console.log('N');
                                this.setState({
                                    Wiggle: true,
                                    IsValid: false
                                })
                                setTimeout(() => {
                                    this.setState({ Wiggle: false })
                                }, 500);
                            }




                        }}>SUBMIT</div>
                    </div>
                </div>
            </div>
        )
    }

}

export default Details_Edit_Personal;
