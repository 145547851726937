import React from 'react'
import {Link} from "react-router-dom";
import styles from '../css/Result.module.css';
import Axios from '../../../../components/Axios'

function Result(props) {
    if (props.data) {
        let links = []
        links.push(
            <Link key={"view"} to={`${props.path}/search/${props.targetID}/${props.data.TERR_ID}`}>
                <div className={styles.link}>View</div>
            </Link>
        )
        let conflict = false
        if (props.conflictsHash && props.conflictsHash[props.data.TERR_ID]) {
            conflict = true
        }

        if (["ADMIN"].includes(props.user.role)) {
            links.push(<div className={styles.link} key={'A2'} onClick={() => {
                    if (!props.approved) {
                        if (props.data.STATUS_ID < 6) {
                            let val = props.data.STATUS_ID
                            if (val === 4) {
                                val = 6
                            }
                            else {
                                val = val + 1
                            }
                            let temp = {
                                statusID: val,
                                ID: props.targetID,
                                terr: props.data.TERR_ID
                            }
                            Axios('/api/best-address-exercise/submit-status', temp, (data) => {
                                props.getData()
                            })
                        }
                    }
                }}>Approve</div>)
            links.push(<div className={styles.link} key={'A3'} onClick={() => {
                    if (!props.approved) {
                        if (props.data.STATUS_ID > 1) {
                            let val = props.data.STATUS_ID
                            if (val === 4) {
                                val = 5
                            }
                            else {
                                val = val - 1
                            }
                            let temp = {
                                statusID: val,
                                ID: props.targetID,
                                terr: props.data.TERR_ID
                            }
                            Axios('/api/best-address-exercise/submit-status', temp, (data) => {
                                props.getData()
                            })
                        }
                    }
                }}>Reject</div>)
        }
        let statusOptions = []
        for (let i = 0; i < props.statusData.length; i++) {
            statusOptions.push(<option key={props.statusData[i].STATUS} value={i+1}>{props.statusData[i].STATUS}</option>)
        }

        let status
        if (["ADMIN"].includes(props.user.role)) {
            status = (
                <select className={[styles.setInput, props.data.STATUS === "APPROVED" ? styles.approved : "", props.data.STATUS === "REJECTED" ? styles.rejected : ""].join(" ")} value={props.data.STATUS_ID} disabled={props.approved} onChange={(event) => {
                        let val = parseInt(event.target.value)
                        let temp = {
                            statusID: val,
                            ID: props.targetID,
                            terr: props.data.TERR_ID
                        }
                        Axios('/api/best-address-exercise/submit-status', temp, (data) => {
                            props.getData()
                        })
                    }}>
                    {statusOptions}
                </select>
            )
        }
        else {
            status = props.data.STATUS
        }

        return (<div className={[styles.result, conflict ? styles.conflict : ""].join(" ")}>
            <div className={[styles.col, styles.short].join(' ')}>
                {conflict ?
                    <i className={[styles.icon, "fas", "fa-exclamation-circle"].join(" ")}></i>
                    : <div className={styles.placeholder}></div>}
                {`${props.data.FIRST_NAME} ${props.data.LAST_NAME}` || "-"}
                <div className={styles.placeholder}></div>    
            </div>
            <div className={[styles.col, styles.short, styles.center].join(' ')}>{`${props.data.TERR_ID} - ${props.data.TERR_NAME}` || "-"}</div>
            <div className={[styles.col, styles.short, styles.center].join(' ')}>{props.data.TOTAL_MOVED_IN || "-"}</div>
            <div className={[styles.col, styles.short, styles.center].join(' ')}>{props.data.TOTAL_MOVED_OUT || "-"}</div>
            <div className={[styles.col, styles.select, styles.center].join(' ')}>{status}</div>
            <div className={[styles.links, styles.col, styles.short, styles.center].join(' ')}>
                {links}
            </div>
        </div>);
    }
    else if (props.totals) {
        return (
            <div className={styles.result} style={{fontWeight: "bold"}}>
                {props.totals}
            </div>
        )
    }
    else {
        return ""
    }
}

export default Result
