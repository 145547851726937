import React, {useState, useEffect} from 'react'
import Popup from '../../../components/Popup/Popup'
import Spinner from '../../../components/Spinner/Spinner'
import styles from '../css/Dispute.module.css'
import DisputeCard from './DisputeCard'
import Axios from '../../../components/Axios/Axios'

function Dispute(props) {
    const [selected, setSelected] = useState(props.finalMoveType ? props.finalMoveType === "N" ? 0 : 1 : undefined)
    const [users, setUsers] = useState()
    const [error, setError] = useState("")
    const [wiggle, setWiggle] = useState(false)

    useEffect(() => {
        let temp = {
            id: props.id,
            profId: props.data.PROF_ID
        }
        Axios('/api/best-address-exercise/get-conflict', temp, (data) => {
            let tempUsers = {}
            tempUsers[data[0].MOVE_TYPE === "Y" ? 'BEST' : 'PRIMARY'] = data[0]
            tempUsers[data[1].MOVE_TYPE === "Y" ? 'BEST' : 'PRIMARY'] = data[1]
            setUsers(tempUsers)
        })
    }, [])

    let optionsList = [<option value=""></option>]
    if (props.options) {
        for (let i = 0; i < props.options.length; i++) {
            optionsList.push(<option value={props.options[i]}>{props.options[i]}</option>)
        }
    }

    const newLocation = props.data.BEST_CITY + ", " + props.data.BEST_STATE 
    const oldLocation = props.data.PRIMARY_CITY + ", " + props.data.PRIMARY_STATE
    const primaryUserName = users ? `${users.PRIMARY.FIRST_NAME} ${users.PRIMARY.LAST_NAME}` : 'Loading...'
    const bestUserName = users ? `${users.BEST.FIRST_NAME} ${users.BEST.LAST_NAME}` : 'Loading...'
    const primaryUserReason = users ? users.PRIMARY.REASON : 'Loading...'
    const bestUserReason = users ? users.BEST.REASON : 'Loading...'

    let body = <Spinner />
    if (props.success) {
        body = (
            <div className={"pop-body"}>
                <div className={"pop-required"}>REQUIRED FIELD *</div>
                <div className={"pop-required"}>{error}</div>
                <div className={"pop-titleRow"}>
                    <div className={"pop-side"}></div>
                    <div className={"pop-directions"}>Select how you would like to resolve this dispute below.</div>
                    <div className={"pop-side"}></div>
                </div>
                <div className={"pop-content"}>
                    <div className={styles.name}>{`${props.data.FIRST_NAME}${props.data.MIDDLE_NAME ? " " + props.data.MIDDLE_NAME + " " : " "}${props.data.LAST_NAME}, ${props.data.DEGREE}`}</div>
                    <div className={styles.middle}>
                        <div className={styles.setmiddle}>
                            <DisputeCard
                                title={'CURRENT ADDRESS'}
                                selected={selected}
                                setSelected={setSelected}
                                type={0}
                                current={true}
                                location={oldLocation}
                                selectedBy={primaryUserName}
                                reason={primaryUserReason}
                                users={users}
                            />
                        </div>
                        <div className={styles.setmiddle}>
                            <DisputeCard
                                title={'BEST ADDRESS'}
                                selected={selected}
                                setSelected={setSelected}
                                type={1}
                                current={true}
                                location={newLocation}
                                selectedBy={bestUserName}
                                reason={bestUserReason}
                                users={users} 
                            />
                        </div>
                    </div>
                    <div className={"pop-buttonRow"}>
                        <button type="button" id={"pop-cancel"} onClick={() => {
                                props.close()
                            }
                        }>CLOSE</button>
                    <button className={wiggle ? "wiggle" : ""} id={"pop-submit"} onClick={() => {
                            if (selected !== undefined) {
                                props.submit(selected)
                            }
                            else {
                                setWiggle(true)
                                setError("PLEASE SELECT A RESOLUTION")
                                setTimeout(() => {
                                    setWiggle(false)
                                }, 500);
                            }
                        }} disabled={props.lock} style={props.lock ? {backgroundColor: "#d9d5d4", border: "none"} : {}}>RESOLVE</button>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <Popup {...props} body={body} title={"Edit Target"} />
    )
}

export default Dispute
