import React, {Component} from 'react';
import styles from '../css/Details_Edit_Identifiers.module.css';
import validateRequestNotes from '../../../helper/validateRequestNotes';

class Details_Edit_Additional extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ORG_ID: this.props.data.ORG_ID,
            NPI: this.props.data.NPI,
                TIN: this.props.data.TIN,
                PREV_ORG_ID: this.props.data.ORG_ID,
                PREV_NPI: this.props.data.NPI,
                ORG_NAME: this.props.data.ORG_NAME,
                PREV_TIN: this.props.data.TIN,
            REQUEST_NOTE: null,
            changed: {

            }
        };
    }

    render() {
        return (
            <div>
                <div className={styles.title}>
                    <div></div>
                    <div className={styles.TitleText}>Edit Identifiers</div>
                    <div className={styles.close} onClick={() => {
                        this.props.close()
                    }}><i className="fas fa-times"></i></div>
                </div>
                <div className={styles.body}>
                <div className={styles.required}>REQUIRED FIELD *</div>
                    <div className={styles.middlerow}>
                        <div className={styles.set}>
                            <div className={styles.inputTitle}>ORG ID</div>
                            <input value={this.state.ORG_ID} disabled />
                        </div>
                        <div className={styles.set}>
                            <div className={styles.inputTitle}>NPI</div>
                            <input value={this.state.NPI} disabled />
                        </div>
                        <div className={styles.set}>
                            <div className={styles.inputTitle}>TIN</div>
                            <input value={this.state.TIN !== -1 ? this.state.TIN : "-1"} onChange={(event) => {
                                let temp = this.state.changed;
                                if (this.state.PREV_TIN !== event.target.value) {
                                    if (event.target.value === "-1") {
                                        temp.TIN = "";
                                    }
                                    else {
                                        temp.TIN = event.target.value;
                                    }
                                    this.setState({changed: temp});
                                }
                                else {
                                    delete temp.TIN;
                                    this.setState({changed: temp});
                                }
                                if (event.target.value === "-1") {
                                    this.setState({TIN: ""})
                                }
                                else {
                                    this.setState({TIN: event.target.value})
                                }
                            }} />
                        </div>
                    </div>

                    <div className={styles.set}>
                        <div className={styles.inputTitle}>REVISION REASON<div className={styles.required}>*</div></div>
                        <input value={this.state.REQUEST_NOTE} onChange={(event) => {
                            this.setState({ REQUEST_NOTE: validateRequestNotes(event.target.value) });
                        }} />
                    </div>

                    <div className={styles.buttonRow}>
                        <div id={styles.cancel} onClick={() => {
                            this.props.close();
                        }}>CANCEL</div>
                        <div className={this.state.Wiggle && this.state.Wiggle !== undefined ? styles.Wiggle : ""} id={styles.submit} onClick={() => {
                            if (Object.keys(this.state.changed).length > 0 && !this.state.TIN.includes('-')) {
                                let temp = this.state.changed
                                temp.REQUEST_TYPE = "Update HCO TIN"
                                temp.REQUEST_NOTE = this.state.REQUEST_NOTE
                                temp.TIN = this.state.TIN
                                temp.ORG_NAME=  this.state.ORG_NAME
                                this.props.submit(temp);
                            }
                            else {
                                this.setState({
                                    Wiggle: true
                                })
                                setTimeout(() => {
                                    this.setState({Wiggle: false})
                                }, 500);
                            }
                        }}>SUBMIT</div>
                    </div>

                </div>
            </div>
        )
    }

}

export default Details_Edit_Additional;
