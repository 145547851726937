import React from 'react'
import styles from './TopBar.module.css'
import ExportButton from './ExportButton'
import BackButton from './BackButton'
import SearchBar from './SearchBar'

function TopBar(props) {
    return (<div className={styles.topBar}>
        <div className={styles.title}>
            <div className={styles.name}>
                <BackButton {...props} />
                <div>{props.name}</div>
            </div>
            <ExportButton {...props} />
        </div>
        <SearchBar {...props} />
    </div>)
}

export default TopBar