import React, {Component} from 'react';
import styles from '../css/AdminWorkflow.module.css';
import AuditTrailResult from './AuditTrailResult'
import Axios from './../../../components/Axios'
import WorkflowStatus from '../../Workflow/WorkflowStatus'
import WorkflowResult from '../../Workflow/WorkflowResult'
import { Link } from 'react-router-dom'

class AdminWorkflow extends Component {
    constructor(props) {
        super(props)
        this.state = {
            view: ''
        };
    }

    componentDidMount() {

        let json = {};
        json.user = this.props.user;


        Axios('/api/workflow/get-my-workflows', json, (data) => {


            this.setState({ data: data });



        });

    }
    showStatus(id) {

        this.setState({ view: (<WorkflowStatus user={this.props.user} id={id} close={this.close.bind(this)} />) });

    }
    close() {
        this.setState({
            view: ''
        })
    }
    render() {

        let results = []
        let isOdd = false;

        if (this.state.data) {
            for (let i = 0; i < this.state.data.length; i++) {
                results.push(<WorkflowResult key={i} data={this.state.data[i]} showStatus={this.showStatus.bind(this)} odd={isOdd} />);
                isOdd = !isOdd;
            }
        }

        return (
            <div className={styles.container}>

                {this.state.view}


                <div className={styles.TopBar}>
                    <div className={styles.title}>Exhibits & Displays</div>

                </div>

               
                <br />
                <div className={styles.content}>

                    <div className={styles.titlerow}>
                        {/*     <div className={styles.titleitem}>ID</div>*/}
                        <div className={styles.titleitemshort}>STATUS</div>
                        <div className={styles.titleitemshort}>SUBMIT DATE</div>
                        <div className={styles.titleitemlong}>ORGANIZATION</div>

                        <div className={styles.titleitemlong}>PROGRAM TITLE</div>

                        <div className={styles.titleitemshort}>START DATE</div>
                        <div className={styles.titleitemshort}>END DATE</div>
                        <div className={styles.actiontitle}>ACTIONS</div>

                    </div>


                    {results}

                </div>
            </div>

        );
    }
}

export default AdminWorkflow;
