import React, { Component } from "react";
import styles from "../css/Details_Edit_SLN.module.css";
import DegreeLookUp from "../../../globalcomponents/DegreeLookUp";
import StateLookUp from "./../../../globalcomponents/StateLookUp";
import Axios from "./../../../components/Axios";

class Details_SLN_Edit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            LICENSE: "",
            EXP: "",
            LIC_STATE: this.props.address[0].PRI_STATE,
            STATE: this.props.address[0].PRI_STATE,
            DEGREE: this.props.degree,
            ADDRESS1: this.props.address[0].PRI_ADDRESS1,
            ADDRESS2: this.props.address[0].PRI_ADDRESS2,
            CITY: this.props.address[0].PRI_CITY,
            ZIP: this.props.address[0].PRI_ZIP5,
            STATUS: "",
            OVERALL_SAMPLEABILITY: "",
            SLN_REASON: "",
            LAST_VAL_DATE: "",
            ISVALID: false
        };
    }

    render() {

        let stateOptions = []
        for (var i = 0; i < Object.keys(StateLookUp).length; i++) {
            stateOptions.push(<option key={i} value={Object.keys(StateLookUp)[i]}>{Object.values(StateLookUp)[i]}</option>)
        }

        let degreeOptions = []
        for (let i = 0; i < Object.keys(DegreeLookUp).length; i++) {
            degreeOptions.push(<option key={i} value={Object.keys(DegreeLookUp)[i]}>{Object.keys(DegreeLookUp)[i]} - {Object.values(DegreeLookUp)[i]}</option>)
        }

        let statusOptions = []
        statusOptions.push(<option key="A" value="A">A</option>)
        statusOptions.push(<option key="I" value="I">I</option>)

        let sampleOptions = []
        sampleOptions.push(<option key="YES" value="YES">YES</option>)
        sampleOptions.push(<option key="NO" value="NO">NO</option>)

        let reasonOptions = []
        reasonOptions.push(<option key="0" value="SLN per State Directory Is Valid">SLN per State Directory Is Valid</option>)
        reasonOptions.push(<option key="1" value="Original Expiration Date Incorrect">Original Expiration Date Incorrect</option>)
        reasonOptions.push(<option key="2" value="Sanction Override">Sanction Override</option>)

        return (
            <div className={styles.editScreen}>
                <div className={styles.wrapper}>
                    <div className={styles.title}>
                        <div></div>
                        <div className={styles.TitleText}>Add State Licenses</div>
                        <div className={styles.close} onClick={() => {
                                this.props.close();
                            }}><i className="fas fa-times"></i>
                        </div>
                    </div>

                    <form>
                        <div className={styles.body}>
                            <div className={this.state.ISVALID ? styles.DefaultMessage : styles.ErrorMessage}>{this.state.MESSAGE}</div>
                            <div className={styles.toprow}>
                            <div className={styles.set}>
                                <div className={styles.inputTitle}>LICENSE NUMBER  <div className={styles.required}>*</div></div>
                                <input className={this.state.ISVALID ? styles.DefaultInputForm : styles.ErrorInputForm} value={this.state.LICENSE !== "" ? this.state.LICENSE : ""} onChange={(event) => {
                                    if (event.target.value === "") {
                                        this.setState({ LICENSE: "" })
                                    }
                                    else {
                                        this.setState({ LICENSE: event.target.value })
                                    }
                                }} />

                                </div>

                                <div className={styles.set}>
                                    <div className={styles.inputTitle}>EXPIRATION DATE  <div className={styles.required}>*</div></div>
                                    <input type="date" className={this.state.ISVALID ? styles.DefaultInputForm : styles.ErrorInputForm} onChange={(event) => {
                                        if (event.target.value === "") {
                                            this.setState({ EXP: "" })
                                        }
                                        else {
                                            this.setState({ EXP: event.target.value })
                                        }
                                    }} />
                                </div>
                           
                            </div>

                            <div className={styles.middlerow}>
                                <div className={styles.set}>
                                    <div className={styles.inputTitle}>LICENSE STATE  <div className={styles.required}>*</div></div>
                                    <select className={this.state.ISVALID ? styles.DefaultInputForm : styles.ErrorInputForm} onChange={(event) => {
                                        if (event.target.value === "") {
                                            this.setState({ LIC_STATE: "" })
                                        }
                                        else {
                                            this.setState({ LIC_STATE: event.target.value })
                                        }
                                    }} value={this.state.LIC_STATE}>
                                        <option value=""></option>
                                        {stateOptions}
                                    </select>
                                </div>
                                <div className={styles.set}>
                                    <div className={styles.inputTitle}>DEGREE  <div className={styles.required}>*</div></div>
                                    <select className={this.state.ISVALID ? styles.DefaultInputForm : styles.ErrorInputForm} onChange={(event) => {
                                        if (event.target.value === "") {
                                            this.setState({ DEGREE: "" })
                                        }
                                        else {
                                            this.setState({ DEGREE: event.target.value })
                                        }
                                    }} value={this.state.DEGREE}>
                                        <option value=""></option>
                                        {degreeOptions}
                                    </select>
                                </div>
                            </div>





                            <div className={styles.middlerow}>


                                <div className={styles.set}>
                                    <div className={styles.inputTitle}>ADDRESS: <div className={styles.required}>*</div></div>
                                    <input className={this.state.ISVALID ? styles.DefaultInputForm : styles.ErrorInputForm} value={this.state.ADDRESS1 !== "" ? this.state.ADDRESS1 : ""} onChange={(event) => {
                                        if (event.target.value === "") {
                                            this.setState({ ADDRESS1: "" })
                                        }
                                        else {
                                            this.setState({ ADDRESS1: event.target.value })
                                        }
                                    }} />

                                </div>

                                <div className={styles.set}>
                                    <div className={styles.inputTitle}>ADDRESS 2:</div>
                                    <input className={styles.DefaultInputForm } value={this.state.ADDRESS2 !== "" ? this.state.ADDRESS2 : ""} onChange={(event) => {
                                        if (event.target.value === "") {
                                            this.setState({ ADDRESS2: "" })
                                        }
                                        else {
                                            this.setState({ ADDRESS2: event.target.value })
                                        }
                                    }} />

                                </div>

                                <div className={styles.set}>
                                    <div className={styles.inputTitle}>CITY: <div className={styles.required}>*</div></div>
                                    <input className={this.state.ISVALID ? styles.DefaultInputForm : styles.ErrorInputForm} value={this.state.CITY !== "" ? this.state.CITY : ""} onChange={(event) => {
                                        if (event.target.value === "") {
                                            this.setState({ CITY: "" })
                                        }
                                        else {
                                            this.setState({ CITY: event.target.value })
                                        }
                                    }} />

                                </div>

                                <div className={styles.set}>
                                    <div className={styles.inputTitle}>STATE  <div className={styles.required}>*</div></div>
                                    <select className={this.state.ISVALID ? styles.DefaultInputForm : styles.ErrorInputForm} onChange={(event) => {
                                        if (event.target.value === "") {
                                            this.setState({ STATE: "" })
                                        }
                                        else {
                                            this.setState({ STATE: event.target.value })
                                        }
                                    }} value={this.state.STATE}>
                                        <option value=""></option>
                                        {stateOptions}
                                    </select>
                                </div>
                                <div className={styles.set}>
                                    <div className={styles.inputTitle}>ZIP: <div className={styles.required}>*</div></div>
                                    <input className={this.state.ISVALID ? styles.DefaultInputForm : styles.ErrorInputForm} value={this.state.ZIP !== "" ? this.state.ZIP : ""} onChange={(event) => {
                                        if (event.target.value === "") {
                                            this.setState({ ZIP: "" })
                                        }
                                        else {
                                            this.setState({ ZIP: event.target.value })
                                        }
                                    }} />

                                </div>
                                <div className={styles.set}>
                                    <div className={styles.inputTitle}>STATUS  <div className={styles.required}>*</div></div>
                                    <select className={this.state.ISVALID ? styles.DefaultInputForm : styles.ErrorInputForm} onChange={(event) => {
                                        if (event.target.value === "") {
                                            this.setState({ STATUS: "" })
                                        }
                                        else {
                                            this.setState({ STATUS: event.target.value })
                                        }
                                    }} value={this.state.STATUS}>
                                        <option value=""></option>
                                        {statusOptions}
                                    </select>
                                </div>

                                <div className={styles.set}>
                                    <div className={styles.inputTitle}>OVERALL SAMPLEABILITY  <div className={styles.required}>*</div></div>
                                    <select className={this.state.ISVALID ? styles.DefaultInputForm : styles.ErrorInputForm} onChange={(event) => {
                                        if (event.target.value === "") {
                                            this.setState({ OVERALL_SAMPLEABILITY: "" })
                                        }
                                        else {
                                            this.setState({ OVERALL_SAMPLEABILITY: event.target.value })
                                        }
                                    }} value={this.state.OVERALL_SAMPLEABILITY}>
                                        <option value=""></option>
                                        {sampleOptions}
                                    </select>
                                </div>

                                <div className={styles.set}>
                                    <div className={styles.inputTitle}>REASON  <div className={styles.required}>*</div></div>
                                    <select className={this.state.SLN_REASON ? styles.DefaultInputForm : styles.ErrorInputForm} onChange={(event) => {
                                        if (event.target.value === "") {
                                            this.setState({ SLN_REASON: "" })
                                        }
                                        else {
                                            this.setState({ SLN_REASON: event.target.value })
                                        }
                                    }} value={this.state.SLN_REASON}>
                                        <option value=""></option>
                                        {reasonOptions}
                                    </select>
                                </div>


                            </div>

                  
                            <div className={styles.buttonRow}>
                                <div id={styles.cancel} onClick={() => {
                                    this.props.close()
                                }
                                }>CANCEL</div>
                                <div type="submit" id={styles.submit} className={this.state.Wiggle && this.state.Wiggle !== undefined ? styles.Wiggle : ""} onClick={() => {
                                    if (this.state.LICENSE !== "" && this.state.STATE !== "" && this.state.DEGREE !== "" && this.state.STATUS !== "" && this.state.EXP !== "" && this.state.SLN_REASON !== "") {
                                        let temp = this.state
                                        this.props.addSln(temp)
                                    }
                                    else {
                                        this.setState({
                                            Wiggle: true,
                                            ISVALID: false
                                        })
                                        setTimeout(() => {
                                            this.setState({ Wiggle: false })
                                        }, 500);
                                    }
                                    
                                    }}>SUBMIT</div>
                            </div>
                        </div>
                    </form>
                   
                </div>
            </div>
        );
    }
}

export default Details_SLN_Edit;
