import React, {useState, useEffect} from 'react'
import styles from '../css/UploadLov.module.css'
import LovReviewBox from './LovReviewBox'

function UploadLov(props) {
    const [defaultIndexes, setDefaultIndexes] = useState()
    const [customIndexes, setCustomIndexes] = useState()
    const [defaultCols, setDefaultCols] = useState(props.defaultColumns)
    const [customCols, setCustomCols] = useState(props.customColumns)

    const [wiggle, setWiggle] = useState(false)
    const [valid, setValid] = useState(true)
    useEffect(() => {
        let defaultIndexes = []
        for (let i = 0; i < Object.keys(props.defaultColumns).length; i++) {
            let temp = Object.values(props.defaultColumns)[i]
            if (temp.options === "LOV") {
                defaultIndexes.push(Object.keys(props.defaultColumns)[i])
            }
        }
        let customIndexes = []
        for (let i = 0; i < Object.keys(props.customColumns).length; i++) {
            let temp = Object.values(props.customColumns)[i]
            if (temp.options === "LOV") {
                customIndexes.push(i)
            }
        }
        setDefaultIndexes(defaultIndexes)
        setCustomIndexes(customIndexes)
    }, [props])

    let lovColumnBoxes = []
    if (defaultIndexes) {
        for (let i = 0; i < defaultIndexes.length; i++) {
            let temp = defaultCols[defaultIndexes[i]]
            lovColumnBoxes.push(<LovReviewBox key={temp.col} data={temp} setData={(data) => {
                setDefaultCols({
                    ...defaultCols,
                    [defaultIndexes[i]]: data
                })
            }} valid={valid} headerChecked={props.headerChecked} setHeaderChecked={props.setHeaderChecked}/>)
        }
    }
    if (customIndexes) {
        for (let i = 0; i < customIndexes.length; i++) {
            let temp = customCols[customIndexes[i]]
            lovColumnBoxes.push(<LovReviewBox key={temp.col} data={temp} setData={(data) => {
                setCustomCols({
                    ...customCols,
                    [customIndexes[i]]: data
                })
            }} valid={valid} headerChecked={props.headerChecked} setHeaderChecked={props.setHeaderChecked}/>)
        }
    }


    return (
        <div className={styles.editScreen}>
            <div className={styles.title}>
                <div></div>
                <div className={styles.titleText}>Add Exercise - Customize Columns - Upload LOV</div>
                <div className={styles.close} onClick={() => {
                    props.close()
                }}><i className="fas fa-times"></i></div>
            </div>
            <form>
                <div className={styles.body}>
                    <div className={styles.titleRow}>
                        <div className={styles.directions}>Your upload was successful. Please confirm the LOV in the preview below.</div>
                    </div>
                    <div className={styles.content}>
                        {lovColumnBoxes}
                    </div>
                    <div className={styles.buttonRow}>
                        <div id={styles.cancel}
                            onClick={() => {
                                props.setDefaultColumns(defaultCols)
                                props.setCustomColumns(customCols)
                                props.setView(props.view - 1)
                            }
                        }>BACK</div>
                        <div type="submit" id={styles.submit} className={wiggle ? styles.Wiggle : ""} onClick={() => {
                                let temp = true
                                if (customCols) {
                                    for (let i = 0; i < Object.keys(customCols).length; i++) {
                                        let value = Object.values(customCols)[i]
                                        if (value.show && !value.title) {
                                            temp = false
                                        }
                                        if (value.options === "LOV" && value.LOV === undefined) {
                                            temp = false
                                        }
                                    }
                                }
                                if (defaultCols) {
                                    for (let i = 0; i < Object.keys(defaultCols).length; i++) {
                                        let value = Object.values(defaultCols)[i]
                                        if (value.show && !value.title) {
                                            temp = false
                                        }
                                        if (value.options === "LOV" && value.LOV === undefined) {
                                            temp = false
                                        }
                                    }
                                }
                                if (temp)  {
                                    props.setDefaultColumns(defaultCols)
                                    props.setCustomColumns(customCols)
                                    props.setHasLov(true)
                                    props.setView(props.view + 1)
                                }
                                else {
                                    setWiggle(true)
                                    setValid(false)
                                    setTimeout(() => {
                                        setWiggle(false)
                                    }, 500);
                                }
                            }
                        }>CONFIRM</div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default UploadLov
