import React, {Component} from 'react';
import styles from '../css/RegionalPlanView.module.css';
import Result from './Result'
import Axios from './../../../components/Axios'
import focusarea from '../../../globalcomponents/focusarea.js';
import Loading from './../../../globalcomponents/Loading';
import Preview from './Preview'
import { Link } from 'react-router-dom';

class RegionalPlanView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            CurrentPage: 0,
            ResultsPerPage: 20,
            TotalResults: 0,
            current: 0,
            final: 0,
            warning: [],
            UNIVERSE: "MyTer",
            REGIONAL_PLAN_CURR: true,
            user: this.props.user,
            added: [],
            des: false,
            status: this.props.status,
            statusid: this.props.statusid,
            data : [],
            spinning: true,
            preview: false
        };
    }

    componentDidMount() {
        let temp = {}
        temp = {
            REGIONAL_PLAN_CURR: 'true',
            UNIVERSE: "MyTer",
            FIRST_NAME: '',
            LAST_NAME: '',
            CITY: '',
            STATE: '',
            GOAL: '',
            TIER: '',
            DEGREE: '',
            FOCUS_AREA: '',
            user: this.props.user
        }
        if (temp.user.empID) {
            temp.user.id = temp.user.empID
        }
        Axios('/api/regional-plan/view-curfin', {id: this.props.user.id}, (data) => {
            this.setState({
                current: data[0].CURRENT_TOGGLES,
                final: data[0].CURRENT_TOGGLES + data[0].YES_TOGGLES + -data[0].NO_TOGGLES
            })
        })
        Axios('/api/regional-plan/view-first', temp, (data) => {
            this.setState({
                loaded: true,
                TotalResults: data.length,
                spinning: false
            })
            this.props.setData(data)
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.status !== this.props.status) {
            this.setState({status: this.props.status});
        }
        if (prevProps.data !== this.props.data) {
            this.setState({
                data: this.props.data
            });
        }
        if (this.props.update) {
            if (prevProps.update !== this.props.update) {
                this.setState({
                    final: this.props.update ? this.state.final + 1 : this.state.final
                   //, status: this.props.update.status
                });
            }
        }
      
    }

    addTo(i) {
        let temp = this.state.data
        if (this.state.statusid === 1 || this.state.statusid === 3 || this.state.statusid === 2 || this.props.statusid === 4) {
            if (this.state.statusid === 3 && this.props.user.role == 'MSL') {
                return 
            }

            //if no owner -> checks for if they are tog_owner ? showEdit/off : not yours
            if (temp[i].TOG_OWNER === null) {
                if (parseInt(temp[i].REGIONAL_PLAN_OWNER) === parseInt(this.props.user.id) || temp[i].REGIONAL_PLAN_OWNER === null) {
                    //if you are the owner of the toggle but hasnt been APPROVED
                    if (temp[i].REGIONAL_PLAN_CURR === 'Y') {
                        //you have toggle on
                        //turn off toggle
                        //console.log(1);
                        this.addRemovedTog(temp, i)
                    }
                    else {
                        //you have toggle OFF
                        //showedit
                        //console.log(2);
                        this.props.showEdit(this.state.data[i], i)
                    }
                }
                else {
                    //error, someone else has this toggled on but has not been approved
                    //console.log(3);
                    let w = this.state.warning
                    w.push(i)
                    this.setState({warning: w})
                }
            }
            else {
                if (parseInt(temp[i].TOG_OWNER) === parseInt(this.props.user.id)) {
                    if (temp[i].TOG === 'Y') {
                        //you have toggle on
                        //turn off toggle
                        //console.log(4);
                        this.removeTog(temp, i)
                    }
                    else {
                        //you have toggle OFF
                        //showedit
                        //console.log(5);
                        this.props.showEdit(this.state.data[i], i)
                    }
                }
                else {
                    //error, someone else has this toggled on but has not been approved
                    //console.log(6);
                    let w = this.state.warning
                    w.push(i)
                    this.setState({warning: w})
                }
            }
        }
    }

    addRemovedTog(temp, i) {
        temp[i].TOG_OWNER = this.props.user.id
        temp[i].TOG = 'N'
        let send = {
            REGIONAL_PLAN_ID: this.props.RPID,
            PROF_ID: temp[i].PROF_ID,
            keys: ['TOGGLE'],
            values: [temp[i].TOG],
            id: this.props.user.id
        }
        Axios('/api/regional-plan/add-toggle', send, (data) => {
            this.setState({
                data: temp,
                final: this.state.final - 1
            })
        })
    }

    removeTog(temp, i) {
        temp[i].TOG_OWNER = this.props.user.id
        temp[i].TOG = 'N'
        let send = {
            REGIONAL_PLAN_ID: this.props.RPID,
            PROF_ID: temp[i].PROF_ID,
            keys: ['TOGGLE'],
            values: [temp[i].TOG],
            id: this.props.user.id
        }
        Axios('/api/regional-plan/update-toggle', send, (data) => {
            this.setState({
                data: temp,
                final: this.state.final - 1
            })
        })
    }

    search() {
        let temp = {}
        if (this.state.FIRST_NAME !== null) {
            temp.FIRST_NAME = this.state.FIRST_NAME
        }
        if (this.state.LAST_NAME !== null) {
            temp.LAST_NAME = this.state.LAST_NAME
        }
        if (this.state.CITY !== null) {
            temp.CITY = this.state.CITY
        }
        if (this.state.STATE !== null) {
            temp.STATE = this.state.STATE
        }
        if (this.state.DEGREE !== null) {
            temp.DEGREE = this.state.DEGREE
        }
        if (this.state.TIER !== null) {
            temp.TIER = this.state.TIER
        }
        if (this.state.GOAL !== null) {
            temp.GOAL = this.state.GOAL
        }
        if (this.state.UNIVERSE !== null) {
            temp.UNIVERSE = this.state.UNIVERSE
        }
        if (this.state.FOCUS_AREA !== null) {
            temp.FOCUS_AREA = this.state.FOCUS_AREA
        }
        if (this.state.REGIONAL_PLAN_CURR !== null) {
            temp.REGIONAL_PLAN_CURR = this.state.REGIONAL_PLAN_CURR
        }
        if (this.props.user.team !== null) {
            temp.user = this.props.user
        }
        this.setState({
            spinning: true
        })
        Axios('/api/regional-plan/view-first', temp, (data) => {
            this.setState({
                loaded: true,
                data: data,
                TotalResults: data.length,
                warning: [],
                spinning: false
            })
        })
    }

    sort(i) {
        let temp = [
            'FIRST_NAME',
            'REGIONAL_PLAN_OWNER',
            'FOCUS_AREA_PRIMARY',
            'FOCUS_AREA_PRIMARY_TIER',
            'GOAL1',
            'FOCUS_AREA_SECONDARY',
            'FOCUS_AREA_SECONDARY_TIER',
            'FOCUS_AREA_TERTIARY',
            'FOCUS_AREA_TERTIARY_TIER'
        ]
        let tog = [
            'FIRST_NAME',
            'TOG_OWNER',
            'TOG_FOCUS_AREA_PRIMARY',
            'TOG_FOCUS_AREA_PRIMARY_TIER',
            'TOG_GOAL1',
            'TOG_FOCUS_AREA_SECONDARY',
            'TOG_FOCUS_AREA_SECONDARY_TIER',
            'TOG_FOCUS_AREA_TERTIARY',
            'TOG_FOCUS_AREA_TERTIARY_TIER'
        ]
        let ret = {
            data: []
        }
        let des = !this.state.des
        if (i !== this.state.sortBy) {
            des = true
        }
        this.setState({sortBy: i, des: des});
        let j = 0
        if (des) {
            ret.data = this.state.data.sort((a, b) => {
                j = j + 1
                if (i === 0) {
                    if (a[temp[i]] === null) {
                        return 1
                    }
                    if (b[temp[i]] === null) {
                        return -1
                    }
                    if (`${a[temp[i]]} ${a['LAST_NAME']}` < `${b[temp[i]]} ${b['LAST_NAME']}`) {
                        return -1;
                    }
                    if (`${a[temp[i]]} ${a['LAST_NAME']}` > `${b[temp[i]]} ${b['LAST_NAME']}`) {
                        return 1;
                    }
                } else if (i === 1) {
                    let key = temp[i]
                    let aOwn = false
                    let bOwn = false
                    if (a.TOG_OWNER !== null) {
                        if (parseInt(a.TOG_OWNER) === parseInt(this.props.user.id)) {
                            aOwn = true
                        }
                        // key = tog[i]
                    }
                    if (b.TOG_OWNER !== null) {
                        if (parseInt(b.TOG_OWNER) === parseInt(this.props.user.id)) {
                            bOwn = true
                        }
                        // key = tog[i]
                    }
                    if (aOwn && bOwn) {
                        return 0
                    }
                    if (aOwn) {
                        return -1
                    }
                    if (bOwn) {
                        return 1
                    }
                    if (a[key] === null) {
                        return 1;
                    }
                    if (b[key] === null) {
                        return -1;
                    }
                    if (parseInt(a[key]) === parseInt(this.props.user.id) && parseInt(b[key]) === parseInt(this.props.user.id)) {
                        return 0;
                    }
                    if (parseInt(a[key]) === parseInt(this.props.user.id)) {
                        return -1;
                    }
                    if (parseInt(b[key]) === parseInt(this.props.user.id)) {
                        return 1;
                    }
                } else {
                    let aOwn = false
                    let bOwn = false
                    if (a.TOG_OWNER !== null) {
                        if (parseInt(a.TOG_OWNER) === parseInt(this.props.user.id)) {
                            aOwn = true
                        }
                        // key = tog[i]
                    }
                    if (b.TOG_OWNER !== null) {
                        if (parseInt(b.TOG_OWNER) === parseInt(this.props.user.id)) {
                            bOwn = true
                        }
                        // key = tog[i]
                    }
                    if (aOwn && bOwn) {
                        if (a[temp[i]] < b[temp[i]]) {
                            return -1;
                        }
                        if (a[temp[i]] > b[temp[i]]) {
                            return 1;
                        }
                    } else if (aOwn) {
                        if (a[tog] === null) {
                            if (a[temp[i]] < b[temp[i]]) {
                                return -1;
                            }
                            if (a[temp[i]] > b[temp[i]]) {
                                return 1;
                            }
                        } else {
                            if (a[tog] < b[temp[i]]) {
                                return -1;
                            }
                            if (a[tog] > b[temp[i]]) {
                                return 1;
                            }
                        }
                    } else if (bOwn) {
                        if (b[tog] === null) {
                            if (a[temp[i]] < b[temp[i]]) {
                                return -1;
                            }
                            if (a[temp[i]] > b[temp[i]]) {
                                return 1;
                            }
                        } else {
                            if (a[temp[i]] < b[tog]) {
                                return -1;
                            }
                            if (a[temp[i]] > b[tog]) {
                                return 1;
                            }
                        }
                    } else {
                        if (a[temp[i]] === null) {
                            return 1
                        }
                        if (b[temp[i]] === null) {
                            return -1
                        }
                        if (a[temp[i]] < b[temp[i]]) {
                            return -1;
                        }
                        if (a[temp[i]] > b[temp[i]]) {
                            return 1;
                        }
                    }
                }
                return 0;
            })
        } else {
            ret.data = this.state.data.sort((a, b) => {
                if (i === 0) {
                    if (a[temp[i]] === null) {
                        return -1
                    }
                    if (b[temp[i]] === null) {
                        return 1
                    }
                    if (`${a[temp[i]]} ${a['LAST_NAME']}` < `${b[temp[i]]} ${b['LAST_NAME']}`) {
                        return 1;
                    }
                    if (`${a[temp[i]]} ${a['LAST_NAME']}` > `${b[temp[i]]} ${b['LAST_NAME']}`) {
                        return -1;
                    }
                } else if (i === 1) {
                    let key = temp[i]
                    let aOwn = false
                    let bOwn = false
                    if (a.TOG_OWNER !== null) {
                        if (parseInt(a.TOG_OWNER) === parseInt(this.props.user.id)) {
                            aOwn = true
                        }
                        // key = tog[i]
                    }
                    if (b.TOG_OWNER !== null) {
                        if (parseInt(b.TOG_OWNER) === parseInt(this.props.user.id)) {
                            bOwn = true
                        }
                        // key = tog[i]
                    }
                    if (aOwn && bOwn) {
                        return 0
                    }
                    if (aOwn) {
                        return 1
                    }
                    if (bOwn) {
                        return -1
                    }
                    if (a[key] === null) {
                        return -1;
                    }
                    if (b[key] === null) {
                        return 1;
                    }
                    if (parseInt(a[key]) === parseInt(this.props.user.id) && parseInt(b[key]) === parseInt(this.props.user.id)) {
                        return 0;
                    }
                    if (parseInt(a[key]) === parseInt(this.props.user.id)) {
                        return 1;
                    }
                    if (parseInt(b[key]) === parseInt(this.props.user.id)) {
                        return -1;
                    }
                } else {
                    let aOwn = false
                    let bOwn = false
                    if (a.TOG_OWNER !== null) {
                        if (parseInt(a.TOG_OWNER) === parseInt(this.props.user.id)) {
                            aOwn = true
                        }
                        // key = tog[i]
                    }
                    if (b.TOG_OWNER !== null) {
                        if (parseInt(b.TOG_OWNER) === parseInt(this.props.user.id)) {
                            bOwn = true
                        }
                        // key = tog[i]
                    }
                    if (aOwn && bOwn) {
                        if (a[temp[i]] < b[temp[i]]) {
                            return 1;
                        }
                        if (a[temp[i]] > b[temp[i]]) {
                            return -1;
                        }
                    } else if (aOwn) {
                        if (a[tog] === null) {
                            if (a[temp[i]] < b[temp[i]]) {
                                return 1;
                            }
                            if (a[temp[i]] > b[temp[i]]) {
                                return -1;
                            }
                        } else {
                            if (a[tog] < b[temp[i]]) {
                                return 1;
                            }
                            if (a[tog] > b[temp[i]]) {
                                return -1;
                            }
                        }
                    } else if (bOwn) {
                        if (b[tog] === null) {
                            if (a[temp[i]] < b[temp[i]]) {
                                return 1;
                            }
                            if (a[temp[i]] > b[temp[i]]) {
                                return -1;
                            }
                        } else {
                            if (a[temp[i]] < b[tog]) {
                                return 1;
                            }
                            if (a[temp[i]] > b[tog]) {
                                return -1;
                            }
                        }
                    } else {
                        if (a[temp[i]] === null) {
                            return -1
                        }
                        if (b[temp[i]] === null) {
                            return 1
                        }
                        if (a[temp[i]] < b[temp[i]]) {
                            return 1;
                        }
                        if (a[temp[i]] > b[temp[i]]) {
                            return -1;
                        }
                    }
                }
                return 0;
            })
        }
        this.setState({
            data: ret.data,
            warning: []
        })
    }

    showPreview(i) {
        this.setState({
            preview: true,
            previewIndex: i
        })
    }

    closePreview() {
        this.setState({
            preview: false,
            previewIndex: 0
        })
    }

   
    render() {

        let results = []

        if (!this.state.spinning) {
            let tempStart = Math.round(this.state.CurrentPage * this.state.ResultsPerPage);
            for (let i = tempStart; (i < this.state.data.length) && (i < parseInt(tempStart) + parseInt(this.state.ResultsPerPage)); i++) {
                results.push(<Result key={i} id={this.props.user.id} showPreview={this.showPreview.bind(this)} showEdit={this.props.showEdit} warning={this.state.warning} user={this.props.user} addTo={this.addTo.bind(this)} data={this.state.data[i]} status={this.state.status}
                    statusid={this.state.statusid} number={i} />)
            }
        }
        else {
            results = <Loading />
        }

        let resultsview = <div className={styles.ResultsView}>{
                this.state.data
                    ? results
                    : ""
            }</div>

        let buttons = []

        if (["ADMIN", "DIR"].includes(this.props.actualUser.role) || (["MSL"].includes(this.props.actualUser.role) && this.props.actualUser.terr.length === 5)) {
            buttons.push(<button key={1} className={this.state.statusid !== 5 ? styles.reject : styles.greyedout} onClick={() => {
                    if (this.state.statusid !== 5) {
                        this.props.reject(this.props.number, this.props.user.id, () => {})
                        this.setState({
                            statusid: 4
                        })
                      
                    }
                }}>REJECT</button>)
            buttons.push(<button key={2} className={this.state.statusid !== 5 ? styles.approve : styles.greyedout} onClick={() => {
                    if (this.state.statusid !== 5) {
                        this.props.approve(this.props.number, this.props.user.id, () => {})
                        
                }
               
                }}>APPROVE</button>)
        } else {
            buttons.push(<button key={3} className={this.state.statusid !== 5 ? styles.submit : styles.greyedout} onClick={() => {
                    
                if (this.state.statusid !== 5) {
                    let temp = {
                        REGIONAL_PLAN_ID: this.props.RPID,
                        id: this.props.user.id
                    }
                    Axios('/api/regional-plan/submit', temp, (data) => {
                        this.setState({
                            status: "PENDING APPROVAL",
                            statusid: 3
                        })
                    })

                    this.sendEmail(3, this.props.user.userName, this.props.user.team, this.props.user.email)

                }


                }}>SUBMIT</button>)
        }

        let focusoptions = []
        for (let i = 0; i < focusarea.length; i++) {
            focusoptions.push(<option key={i} value={focusarea[i]}>{focusarea[i]}</option>)
        }

        return (<div className={styles.RegionalPlanView}>
            <div className={styles.topBar}>
                <div className={styles.topBarRow1}>


                    {(["ADMIN", "DIR"].includes(this.props.actualUser.role) || (["MSL"].includes(this.props.actualUser.role) && this.props.actualUser.terr.length === 5)) ? (
                       <div onClick={() => { this.props.backToAdmin() }}>
                            <Link to={`#`}>
                                <div className={styles.backButton}>
                                    <i className="fas fa-angle-left"></i>
                                </div>
                            </Link>
                        </div>
                    ) : ""}

                    <div className={styles.row1}>{this.props.name}</div>
                </div>

                <div className={styles.topBarRow2}>
                    <div className={styles.filter}>
                        <i className={["fas", "fa-sliders-h", styles.Icon].join(' ')}></i>
                    </div>
                    <div className={styles.set}>
                        <div className={styles.setTitle}>FIRST NAME</div>
                        <input className={styles.setInput} value={this.state.FIRST_NAME} onKeyDown={(e) => { if (e.keyCode == 13) { this.search() } }}

                            onChange={(event) => {
                                if (event.target.value === "") {
                                    this.setState({ FIRST_NAME: null})
                                } else {
                                    this.setState({ FIRST_NAME: event.target.value })
                                    
                                }
                            }}/>
                    </div>
                    <div className={styles.set}>
                        <div className={styles.setTitle}>LAST NAME</div>
                        <input className={styles.setInput} value={this.state.LAST_NAME} onKeyDown={(e) => { if (e.keyCode == 13) { this.search() } }} onChange={(event) => {
                            if (event.target.value === "") {
                                this.setState({ LAST_NAME: null })
                            } else {
                                this.setState({ LAST_NAME: event.target.value })
                               
                            }
                        }} />
                    </div>

                    <div className={styles.set}>
                        <div className={styles.setTitle}>DEGREE</div>
                        <select className={styles.setInput} value={this.state.DEGREE} onChange={(event) => {
                            if (event.target.value === "") {
                                this.setState({ DEGREE: null })
                            } else {
                                this.setState({ DEGREE: event.target.value })
                            }
                        }}>
                            <option value={""}></option>
                            <option value={"MD"}>MD</option>
                            <option value={"DO"}>DO</option>
                            <option value={"NP"}>NP</option>
                            <option value={"PA"}>PA</option>
                            <option value={"APRN"}>APRN</option>
                            <option value={"RN"}>RN</option>
                            <option value={"PHAR"}>PHAR</option>
                            <option value={"PHARD"}>PHARD</option>
                            <option value={"PHD"}>PHD</option>
                            <option value={"URC"}>URC</option>
                            <option value={"CRA"}>CRA</option>
                        </select>
                    </div>





                    <div className={styles.set}>
                        <div className={styles.setTitle}>CITY</div>
                        <input className={styles.setInput} value={this.state.CITY} onChange={(event) => {
                            if (event.target.value === "") {
                                this.setState({ CITY: null })
                            } else {
                                this.setState({ CITY: event.target.value })
                            }
                        }} />
                    </div>
                    <div className={styles.set}>
                        <div className={styles.setTitle}>STATE</div>
                        <select className={styles.setInput} value={this.state.STATE} onChange={(event) => {
                            if (event.target.value === "") {
                                this.setState({ STATE: null })
                            } else {
                                this.setState({ STATE: event.target.value })
                            }
                        }}>
                            <option value={""}></option>

                            <option value={"AL"}>AL</option>
                            <option value={"AK"}>AK</option>
                            <option value={"AZ"}>AZ</option>
                            <option value={"AR"}>AR</option>
                            <option value={"CA"}>CA</option>

                            <option value={"CO"}>CO</option>
                            <option value={"CT"}>CT</option>
                            <option value={"DE"}>DE</option>
                            <option value={"DC"}>DC</option>
                            <option value={"FL"}>FL</option>

                            <option value={"GA"}>GA</option>
                            <option value={"HI"}>HI</option>
                            <option value={"ID"}>ID</option>
                            <option value={"IL"}>IL</option>
                            <option value={"IN"}>IN</option>

                            <option value={"IA"}>IA</option>
                            <option value={"KS"}>KS</option>
                            <option value={"KY"}>KY</option>
                            <option value={"LA"}>LA</option>
                            <option value={"ME"}>ME</option>

                            <option value={"MD"}>MD</option>
                            <option value={"MA"}>MA</option>
                            <option value={"MI"}>MI</option>
                            <option value={"MN"}>MN</option>
                            <option value={"MS"}>MS</option>

                            <option value={"MO"}>MO</option>
                            <option value={"MT"}>MT</option>
                            <option value={"NE"}>NE</option>
                            <option value={"NV"}>NV</option>
                            <option value={"NH"}>NH</option>

                            <option value={"NJ"}>NJ</option>
                            <option value={"NM"}>NM</option>
                            <option value={"NY"}>NY</option>
                            <option value={"NC"}>NC</option>
                            <option value={"ND"}>ND</option>

                            <option value={"OH"}>OH</option>
                            <option value={"OK"}>OK</option>
                            <option value={"OR"}>OR</option>
                            <option value={"PA"}>PA</option>
                            <option value={"PR"}>PR</option>

                            <option value={"RI"}>RI</option>
                            <option value={"SC"}>SC</option>
                            <option value={"SD"}>SD</option>
                            <option value={"TN"}>TN</option>
                            <option value={"TX"}>TX</option>

                            <option value={"UT"}>UT</option>
                            <option value={"VT"}>VT</option>
                            <option value={"VI"}>VI</option>
                            <option value={"VA"}>VA</option>
                            <option value={"WA"}>WA</option>
                            <option value={"WV"}>WV</option>
                            <option value={"WI"}>WI</option>
                            <option value={"WY"}>WY</option>

                        </select>
                    </div>
                    <div className={styles.set}>
                        <div className={styles.setTitle}>UNIVERSE</div>
                        <select className={styles.setInput} value={this.state.UNIVERSE} onChange={(event) => {
                            if (event.target.value === "") {
                                this.setState({ UNIVERSE: null })
                            } else {
                                this.setState({ UNIVERSE: event.target.value })
                            }
                        }}>
                            <option value={""}></option>
                            <option value="ALL">All</option>
                            <option value="MyCRM">CRM</option>
                            <option value="MyTer">My Territory</option>
                        </select>
                    </div>
                    <div className={styles.set}>
                        <div className={styles.setTitle}>Regional Plan</div>
                        <select className={styles.setInput} value={this.state.REGIONAL_PLAN_CURR} onChange={(event) => {
                            if (event.target.value === "") {
                                this.setState({ REGIONAL_PLAN_CURR: null })
                            } else {
                                this.setState({ REGIONAL_PLAN_CURR: event.target.value })
                            }
                        }}>
                            <option key={0} value={""}></option>
                            <option key={1} value={true}>Yes</option>
                            <option key={2} value={false}>No</option>
                        </select>
                    </div>
                    <div className={styles.set}>
                        <div className={styles.setTitle}>FOCUS AREA</div>
                        <select className={styles.setInput} value={this.state.FOCUS_AREA} onChange={(event) => {
                                if (event.target.value === "") {
                                    this.setState({FOCUS_AREA: null})
                                } else {
                                    this.setState({FOCUS_AREA: event.target.value})
                                }
                            }}>
                            {focusoptions}
                        </select>
                    </div>
                    <div className={styles.set}>
                        <div className={styles.setTitle}>TIER</div>
                        <select className={styles.setInput} value={this.state.TIER} onChange={(event) => {
                                if (event.target.value === "") {
                                    this.setState({TIER: null})
                                } else {
                                    this.setState({TIER: event.target.value})
                                }
                            }}>
                            <option value={""}></option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                        </select>
                    </div>
                    <div className={styles.set}>
                        <div className={styles.setTitle}>GOAL</div>
                        <select className={styles.setInput} value={this.state.GOAL} onChange={(event) => {
                                if (event.target.value === "") {
                                    this.setState({GOAL: null})
                                } else {
                                    this.setState({GOAL: event.target.value})
                                }
                            }}>
                            <option value={null}></option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                        </select>
                    </div>
                    <div className={styles.placeholder}></div>
                    <div className={[styles.set, styles.button].join(' ')}>
                        <button className={this.state.sWiggle && this.state.sWiggle !== null
                                ? styles.Wiggle
                                : ""} id={styles.searchButton} type="submit" onClick={(event) => {
                                this.search()
                            }}>APPLY FILTER</button>
                    </div>
                    <div className={styles.Line}></div>
                    <div className={[styles.set, styles.button].join(' ')}>
                        <button id={styles.searchButton} type="button" onClick={() => {
                                this.setState({
                                    FIRST_NAME: "",
                                    LAST_NAME: "",
                                    DEGREE: "",
                                    CITY: "",
                                    STATE: "",
                                    UNIVERSE: "",
                                    REGIONAL_PLAN_CURR: "",
                                    FOCUS_AREA: "",
                                    TIER: "",
                                    GOAL: "",
                                    data: [],
                                    CurrentPage: 0,
                                    ResultsPerPage: 0,
                                    TotalResults: 0
                                })

                            }}>CLEAR ALL</button>
                    </div>

                </div>
            </div>
            <div className={styles.statusBar}>
                <div className={[styles.box, styles.left].join(' ')}>
                    <div className={styles.statusBoxColor}>Regional Plan</div>
                    <div className={styles.setmiddle}>
                        <div className={styles.key}>CURRENT</div>
                        <div className={styles.value}>{this.state.current}</div>
                    </div>
                    <div className={styles.arrow}>
                        <i className="fas fa-long-arrow-alt-right"></i>
                    </div>
                    <div className={styles.statusBox}></div>
                    <div className={styles.setmiddle}>
                        <div className={styles.key}>FINAL</div>
                        <div className={styles.value}>{this.state.final}</div>
                    </div>
                </div>
                <div className={[styles.box, styles.middle].join(' ')}>
                    <div className={styles.statusTopBar}>STATUS</div>
                    <div className={styles.status}>{this.state.status}</div>
                </div>
                <div className={styles.placeholder}></div>
                <div className={styles.right}>
                    <div className={styles.save}>Progress is automatically saved.</div>
                    {buttons}
                </div>
            </div>


            <div className={styles.resWindow}>
                <div className={styles.restitle}>
                    <div className={styles.titles}>
                        <div className={styles.long} onClick={() => {
                                this.sort(0)
                            }}>
                            <div>Health Care Professional</div>
                            <div className={this.state.sortBy === 0
                                    ? [styles.down, styles.sortBy].join(' ')
                                    : styles.down}>
                                {
                                    this.state.des
                                        ? <i className="fas fa-caret-down"></i>
                                        : <i className="fas fa-caret-up"></i>
                                }
                            </div>
                        </div>
                        <div className={styles.short} onClick={() => {
                                this.sort(1)
                            }}>
                            <div>Regional Plan</div>
                            <div className={this.state.sortBy === 1
                                    ? [styles.down, styles.sortBy].join(' ')
                                    : styles.down}>
                                {
                                    this.state.des
                                        ? <i className="fas fa-caret-down"></i>
                                        : <i className="fas fa-caret-up"></i>
                                }
                            </div>
                        </div>
                        <div className={styles.short} onClick={() => {
                                this.sort(2)
                            }}>
                            <div>Primary Focus Area</div>
                            <div className={this.state.sortBy === 2
                                    ? [styles.down, styles.sortBy].join(' ')
                                    : styles.down}>
                                {
                                    this.state.des
                                        ? <i className="fas fa-caret-down"></i>
                                        : <i className="fas fa-caret-up"></i>
                                }
                            </div>
                        </div>
                        <div className={styles.short} onClick={() => {
                                this.sort(3)
                            }}>
                            <div>Primary Tier</div>
                            <div className={this.state.sortBy === 3
                                    ? [styles.down, styles.sortBy].join(' ')
                                    : styles.down}>
                                {
                                    this.state.des
                                        ? <i className="fas fa-caret-down"></i>
                                        : <i className="fas fa-caret-up"></i>
                                }
                            </div>
                        </div>
                        <div className={styles.short} onClick={() => {
                                this.sort(4)
                            }}>
                            <div>Goal</div>
                            <div className={this.state.sortBy === 4
                                    ? [styles.down, styles.sortBy].join(' ')
                                    : styles.down}>
                                {
                                    this.state.des
                                        ? <i className="fas fa-caret-down"></i>
                                        : <i className="fas fa-caret-up"></i>
                                }
                            </div>
                        </div>
                        <div className={styles.short} onClick={() => {
                                this.sort(5)
                            }}>
                            <div>Secondary Focus Area</div>
                            <div className={this.state.sortBy === 5
                                    ? [styles.down, styles.sortBy].join(' ')
                                    : styles.down}>
                                {
                                    this.state.des
                                        ? <i className="fas fa-caret-down"></i>
                                        : <i className="fas fa-caret-up"></i>
                                }
                            </div>
                        </div>
                        <div className={styles.short} onClick={() => {
                                this.sort(6)
                            }}>
                            <div>Secondary Tier</div>
                            <div className={this.state.sortBy === 6
                                    ? [styles.down, styles.sortBy].join(' ')
                                    : styles.down}>
                                {
                                    this.state.des
                                        ? <i className="fas fa-caret-down"></i>
                                        : <i className="fas fa-caret-up"></i>
                                }
                            </div>
                        </div>
                        <div className={styles.short} onClick={() => {
                                this.sort(7)
                            }}>
                            <div>Other Focus Area</div>
                            <div className={this.state.sortBy === 7
                                    ? [styles.down, styles.sortBy].join(' ')
                                    : styles.down}>
                                {
                                    this.state.des
                                        ? <i className="fas fa-caret-down"></i>
                                        : <i className="fas fa-caret-up"></i>
                                }
                            </div>
                        </div>
                        <div className={styles.short} onClick={() => {
                                this.sort(8)
                            }}>
                            <div>Other Tier</div>
                            <div className={this.state.sortBy === 8
                                    ? [styles.down, styles.sortBy].join(' ')
                                    : styles.down}>
                                {
                                    this.state.des
                                        ? <i className="fas fa-caret-down"></i>
                                        : <i className="fas fa-caret-up"></i>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={styles.res}>
                        {resultsview}
                    </div>
                </div>
                {this.state.preview ? (
                    <div className={styles.preview}>
                        {<Preview data={this.state.data[this.state.previewIndex]} close={this.closePreview.bind(this)}/>}
                    </div>
                ) : ""}
            </div>

            <div className={styles.BottomBar}>
                <div className={styles.bottomBarPlace}></div>
                <div className={styles.Pages}>
                    <button className={styles.Arrow} onClick={() => {
                            if (this.state.CurrentPage > 0) {
                                if (this.state.CurrentPage - 1 < 0) {
                                    this.setState({CurrentPage: 0})
                                } else {
                                    this.setState({
                                        CurrentPage: this.state.CurrentPage - 1
                                    })
                                }
                            }
                        }}>
                        <i className="fas fa-angle-left"></i>
                    </button>
                    <div>
                        {
                            `${Math.round(this.state.CurrentPage * this.state.ResultsPerPage) + 1}-${this.state.TotalResults > ((this.state.CurrentPage * this.state.ResultsPerPage) + this.state.ResultsPerPage)
                                ? Math.round((this.state.CurrentPage * this.state.ResultsPerPage) + this.state.ResultsPerPage)
                                : this.state.TotalResults} of ${this.state.TotalResults}`
                        }

                    </div>
                    <button className={styles.Arrow} onClick={() => {
                            if (this.state.CurrentPage + 1 < this.state.TotalResults / this.state.ResultsPerPage) {
                                this.setState({
                                    CurrentPage: this.state.CurrentPage + 1
                                })
                            }
                        }}>
                        <i className="fas fa-angle-right"></i>
                    </button>
                </div>
                <div className={styles.ViewWrapper}>
                    <div className={styles.Views}>View</div>
                    <div className={this.state.ResultsPerPage === 20 ? [styles.Views, styles.Active].join(' ') : styles.Views} onClick={() => {
                            this.setState({
                                ResultsPerPage: 20,
                                CurrentPage: (this.state.CurrentPage * this.state.ResultsPerPage) / 20
                            })
                        }}>20</div>
                    <div className={styles.Views}>|</div>
                    <div className={this.state.ResultsPerPage === 40 ? [styles.Views, styles.Active].join(' ') : styles.Views} onClick={() => {
                        this.setState({
                            ResultsPerPage: 40,
                            CurrentPage: (this.state.CurrentPage * this.state.ResultsPerPage) / 40
                        })
                        }}>40</div>
                    <div className={styles.Views}>|</div>
                    <div className={this.state.ResultsPerPage === 60 ? [styles.Views, styles.Active].join(' ') : styles.Views} onClick={() => {
                        this.setState({
                            ResultsPerPage: 60,
                            CurrentPage: (this.state.CurrentPage * this.state.ResultsPerPage) / 60
                        })
                        }}>60</div>
                    <div className={styles.Views}>|</div>
                    <div className={this.state.ResultsPerPage === 80 ? [styles.Views, styles.Active].join(' ') : styles.Views} onClick={() => {
                        this.setState({
                            ResultsPerPage: 80,
                            CurrentPage: (this.state.CurrentPage * this.state.ResultsPerPage) / 80
                        })
                        }}>80</div>
                    <div className={styles.Views}>|</div>
                    <div className={this.state.ResultsPerPage === 100 ? [styles.Views, styles.Active].join(' ') : styles.Views} onClick={() => {
                        this.setState({
                            ResultsPerPage: 100,
                            CurrentPage: (this.state.CurrentPage * this.state.ResultsPerPage) / 100
                        })
                        }}>100</div>
                </div>
            </div>
        </div>);
    }
}

export default RegionalPlanView;
