import React, { Component } from 'react';
import styles from './AddressLookUp.module.css';
import Axios from './Axios';
import StateLookUp from './StateLookUp'
import CountryLookup from '../lookup/countrylookup'
import countrystatelookup from '../lookup/countrystatelookup'

class AddressLookUpExUs extends Component {
    constructor(props) {
        super(props);

        let countryCode = this.props.info.entCOUNTRY_CODE ? this.props.info.entCOUNTRY_CODE : '';

        //if (countryCode == '' && this.props.info.STATE !== '') {
        //    countryCode = this.props.info.STATE.substr(0, 2);

        //}
        this.state = {
            FIRST_NAME: `${this.props.info.FIRST_NAME ? this.props.info.FIRST_NAME : ''}`,
            LAST_NAME: `${this.props.info.LAST_NAME ? this.props.info.LAST_NAME : ''}`,
            MIDDLE_NAME: `${this.props.info.MIDDLE_NAME ? this.props.info.MIDDLE_NAME : ''}`,
            entADDRESS1: `${this.props.info.entADDRESS1 ? this.props.info.entADDRESS1 : ''}`,
            entADDRESS2: `${this.props.info.entADDRESS2 ? this.props.info.entADDRESS2 : ''}`,
            DEGREE: `${this.props.info.DEGREE ? this.props.info.DEGREE : ''}`,
            SPECIALTY: `${this.props.info.SPECIALTY ? this.props.info.SPECIALTY : ''}`,
            entCITY: `${this.props.info.entCITY ? this.props.info.entCITY : ''}`,
            entCOUNTRY_CODE: countryCode,
            entSTATE: `${this.props.info.entSTATE ? this.props.info.entSTATE : ''}`,
            entZIPCODE: `${this.props.info.entZIPCODE ? this.props.info.entZIPCODE : ''}`,
            addresses: [],
            valADDRESS1: `${this.props.info.valADDRESS1 ? this.props.info.valADDRESS1 : ''}`,
            valADDRESS2: `${this.props.info.valADDRESS2 ? this.props.info.valADDRESS2 : ''}`,
            valCITY: `${this.props.info.valCITY ? this.props.info.valCITY : ''}`,
            valCOUNTRY_CODE: `${this.props.info.valCOUNTRY_CODE ? this.props.info.valCOUNTRY_CODE : ''}`,
            valSTATE: `${this.props.info.valSTATE ? this.props.info.valSTATE : ''}`,
            valZIPCODE: `${this.props.info.valZIPCODE ? this.props.info.valZIPCODE : ''}`,
            REQUEST_TYPE: `${this.props.info.REQUEST_TYPE ? this.props.info.REQUEST_TYPE : ''}`,
            REQUEST_NOTE: `${this.props.info.REQUEST_NOTE ? this.props.info.REQUEST_NOTE : ''}`,
            MELISSA_ENGINE_INFO: '',
            clicked: false,
            clickedAddress: 0,
            selectedcountry: countryCode,
            validated: false,
            validatedMessage: 'Address is not verified.',
            melissaMessage: "",
            countrylookup: [],
            loaded: false
        };
    }

    componentDidMount() {

        var countries = []
        for (const [key, value] of Object.entries(CountryLookup)) {
            countries.push({ key, value });
        }
        var cou = []
        cou = countries.sort(this.compare)
        this.setState({
            countrylookup: JSON.parse(JSON.stringify(cou))
        })

        if (this.state.valSTATE !== '') {
            this.setState({
                selectedcountry: this.state.valSTATE.substr(0, 2),
                entCOUNTRY_CODE: this.state.valSTATE.substr(0, 2)
            })
        }

        //if (this.state.ADDRESS1 !== '' || this.state.ADDRESS2 !== '' || this.state.CITY !== '' || this.state.STATE !== '' || this.state.ZIPCODE !== '') {
        //    this.checkAddress();
        //}
        //else {
        this.setState({
            loaded: true
        })
        //}
    }
    //checkAddress() {
    //    let address = {
    //        entADDRESS1: this.state.entADDRESS1,
    //        entADDRESS2: this.state.entADDRESS2,
    //        entCITY: this.state.entCITY,
    //        entSTATE: this.state.entSTATE,
    //        entZIPCODE: this.state.entZIPCODE,
    //        entCOUNTRY_CODE: this.state.entCOUNTRY_CODE,
    //        valADDRESS1: this.state.valADDRESS1,
    //        valADDRESS2: this.state.valADDRESS2,
    //        valCITY: this.state.valCITY,
    //        valSTATE: this.state.valSTATE,
    //        valZIPCODE: this.state.valZIPCODE,
    //        valCOUNTRY_CODE: this.state.valCOUNTRY_CODE,
    //        MELISSA_ENGINE_INFO: this.state.MELISSA_ENGINE_INFO
    //    };
    //    //Axios('/api/global/address-check', address, (response) => {
    //    //    if (response.status) {
    //    //        this.setState({
    //    //            validated: true,
    //    //            validatedMessage: 'Address is verified.',
    //    //            loaded: true
    //    //        });
    //    //    }
    //    //    else {
    //            this.setState({
    //                validated: false,
    //                validatedMessage: 'Address is not verified.',
    //                loaded: true
    //            });
    //    //    }
    //    //});
    //    this.props.updateAddress(address)
    //}
    updateAddress(address) {

        this.props.updateAddress(address);

        if (this.validateAddress(address)) {
            this.setState({
                validated: true,
                validatedMessage: 'Address is verified.',
                loaded: true
            });
        }
        else {
            this.setState({
                validated: false,
                validatedMessage: 'Address is not verified.',
                loaded: true
            });
        }
    }
    getMelissaStateKey(statename) {
        statekey = "";
        if (statename != "") {
            var statekey = Object.keys(countrystatelookup).find(itemkey => countrystatelookup[itemkey] === statename)

            if (statekey != "") {
                statekey = statekey.substr(-2);
            }

        }

        return statekey;
    }

    getMelissaCountryKey(countryname) {
        var countrykey = Object.keys(CountryLookup).find(itemkey => CountryLookup[itemkey] === countryname)
        return countrykey;
    }

    getMelissaUserInput(address) {
        var userInput = "";
        userInput = "&a1=" + address.entADDRESS1 +
            "&a2=" + address.entADDRESS2 +
            "&a3=&a4=&a5=&a6=&a7=&a8=&ddeploc=&deploc=" +
            "&loc=" + address.entCITY +
            "&subadmarea=" +
            "&admarea=" + address.entSTATE +
            "&postal=" + address.entZIPCODE +
            "&subnatarea=" +
            "&ctry=" + address.entCOUNTRY_CODE +
            "&last=";

        return userInput;
    }

    getOpt() {
        var opt = "";
        opt += "DeliveryLines:" + "On" + ",";
        opt += "OutputGeo:" + "On";
        return opt;
    }
    returnCodesValid(codes) {

        let isValidated = false;
        let msg = 'Address has not been validated';

        //(wiki.melissadata.com/index.php?title=Result_Codes)
        let hasGeneralError = codes.includes("GE");  //also called Geocode Error
        let hasGeneralWarning = codes.includes("GW");
        let hasServiceError = codes.includes("SE");

        let hasAddressError = codes.includes("AE");
        let hasAddressChange = codes.includes("AC");
        let hasAddressValidation = codes.includes("AV");

        //this function will validate the return codes from Melissa
        //known error codes - error first

        if (hasGeneralError || hasGeneralWarning || hasServiceError || hasAddressError) {

            msg = "Service Error: " + codes;
            if (hasAddressError) { msg = "Address could not be validated."; }
        }

        if (hasAddressChange || hasAddressValidation) {

            //valid address - could be changes made
            if (hasAddressChange) {
                msg = 'Address has been updated: ';
                let hasOne = false;

                if (codes.includes("AC03")) { msg += 'City '; hasOne = true; }
                if (codes.includes("AC02")) { msg += hasOne ? ', State ' : 'State '; hasOne = true; }
                if (codes.includes("AC01")) { msg += hasOne ? ', Postal Code ' : 'Postal Code '; hasOne = true; }

                this.setState({ melissaMessage: msg })
            }

            if (codes.includes("GS05")) {
                msg = 'Validated Address';
            }
            if (codes.includes("GS05", "AV24")) {
                msg = 'Validated to Premise';
            }
            isValidated = true;
        }

        this.setState({ melissaMessage: msg })
        return isValidated;
    }
    validateAddress(address) {


        if (address.entADDRESS1 !== '' && address.entCITY !== '' && address.entCOUNTRY_CODE !== '' && address.entSTATE !== '' && address.entZIPCODE !== '' && address.entZIPCODE.length > 6) {

            var url = "https://address.melissadata.net/v3/WEB/GlobalAddress/doGlobalAddress?";
            url += "t=&id=" + encodeURIComponent("or26EJurRbIVKwq0_ERuQU**");
            url += "&opt=" + this.getOpt().replace(/,\s*$/, "");
            url += this.getMelissaUserInput(address);
            url += "&format=JSON";
            fetch(url)
                .then(response => response.json())
                .then((jsonData) => {


                    let recCount = jsonData.TotalRecords;
                    if (recCount == 1) {

                        // let returnCodes = jsonData.Records[0].Results;
                        let mKey = jsonData.Records[0].MelissaAddressKey;

                        if (mKey !== '') {

                            console.log('key = ' + mKey);
                            
                            this.setState({
                                validated: true,
                                validatedMessage: 'Address is verified.',
                                loaded: true
                            });


                            return true;



                        } else {
                            console.log('key = ' + mKey);


                            this.setState({
                                validated: false,
                                validatedMessage: 'Address is not verified.',
                                loaded: true
                            });
                            return false;
                        }
                    }



                })
                .catch((error) => {
                    console.error(error)
                    this.setState({ verified: -1 });
                });

            console.log(url);
        }
    }
    render() {
        let stateOptions = []
        for (var i = 0; i < Object.keys(StateLookUp).length; i++) {
            stateOptions.push(<option key={i} value={Object.keys(StateLookUp)[i]}>{Object.keys(StateLookUp)[i]}</option>)
        }

        let countryOptions = []

        let countrylookup = this.state.countrylookup
        for (let i = 0; i < countrylookup.length; i += 1) {
            var obj = countrylookup[i]
            let keyValue = obj.key;
            countryOptions.push(<option key={obj.key} value={keyValue}>{obj.value}</option>)
        }

        let countrystateOptions = [];
        let selectedCountryCode = this.state.entCOUNTRY_CODE;

        if (selectedCountryCode.length > 0) {

            // var key = Object.keys(CountryLookup).find(itemkey => CountryLookup[itemkey] === this.state.selectedcountry)

            var countrystateinfo = Object.keys(countrystatelookup).filter(item => item.substring(0, 2) == selectedCountryCode)
            countrystateinfo.forEach(element => {
                countrystateOptions.push(<option key={element} value={element}>{countrystatelookup[element]}</option>);
            });
        }
        let verificationStatus = []
        //if (this.state.loaded) {
        verificationStatus = (<div id={styles.validationIcon} className={this.state.validated ? styles.valid : styles.invalid}>
            <i className="far fa-check-circle"></i> <span className={styles.validationMessage}>{this.state.validatedMessage}</span>
        </div>);
        //}
        //else {
        //    verificationStatus = (<div className={styles.loading}>
        //        <i className="fas fa-spinner fa-pulse"></i>
        //    </div>);
        //}

        return (
            <div className={styles.addressLookUp}>
                {verificationStatus}
                <div className={styles.required}>REQUIRED FIELD<span className={styles.required}>*</span></div>
                <div id={styles.inputStyles}>
                    <div className={styles.container}>
                        <div className={styles.long}>
                            <div>STREET 1</div>
                            <input type="text" className={this.props.error ? styles.defaultInputForm : styles.errorInputForm} onChange={(event) => {

                                this.setState({ entADDRESS1: event.target.value });

                                let address = {
                                    entADDRESS1: event.target.value,
                                    entADDRESS2: this.state.entADDRESS2,
                                    entCITY: this.state.entCITY,
                                    entSTATE: this.state.entSTATE,
                                    entZIPCODE: this.state.entZIPCODE,
                                    entCOUNTRY_CODE: this.state.entCOUNTRY_CODE,
                                    valADDRESS1: this.state.valADDRESS1,
                                    valADDRESS2: this.state.valADDRESS2,
                                    valCITY: this.state.valCITY,
                                    valSTATE: this.state.valSTATE,
                                    valZIPCODE: this.state.valZIPCODE,
                                    valCOUNTRY_CODE: this.state.valCOUNTRY_CODE,
                                    MELISSA_ENGINE_INFO: this.state.MELISSA_ENGINE_INFO
                                };

                                this.updateAddress(address);
                            }} value={this.state.entADDRESS1} />
                        </div>
                    </div>

                    <div className={styles.container}>
                        <div className={styles.long}>
                            <div>STREET 2 (Optional)</div>
                            <input type="text" className={styles.defaultInputForm} onChange={(event) => {
                                this.setState({ entADDRESS2: event.target.value });

                                let address = {
                                    entADDRESS1: this.state.entADDRESS1,
                                    entADDRESS2: event.target.value,
                                    entCITY: this.state.entCITY,
                                    entSTATE: this.state.entSTATE,
                                    entZIPCODE: this.state.entZIPCODE,
                                    entCOUNTRY_CODE: this.state.entCOUNTRY_CODE,
                                    valADDRESS1: this.state.valADDRESS1,
                                    valADDRESS2: this.state.valADDRESS2,
                                    valCITY: this.state.valCITY,
                                    valSTATE: this.state.valSTATE,
                                    valZIPCODE: this.state.valZIPCODE,
                                    valCOUNTRY_CODE: this.state.valCOUNTRY_CODE,
                                    MELISSA_ENGINE_INFO: this.state.MELISSA_ENGINE_INFO
                                };

                                this.updateAddress(address);


                            }} value={this.state.entADDRESS2} />
                        </div>
                    </div>
                    <div className={styles.container}>
                        <div className={styles.long}>
                            <div>CITY</div>
                            <input type="text" className={this.props.error ? styles.defaultInputForm : styles.errorInputForm} onChange={(event) => {
                                //  this.updatecity(event.target.value)
                                this.setState({ loaded: false, entCITY: event.target.value })

                                let address = {
                                    entADDRESS1: this.state.entADDRESS1,
                                    entADDRESS2: this.state.entADDRESS2,
                                    entCITY: event.target.value,
                                    entSTATE: this.state.entSTATE,
                                    entZIPCODE: this.state.entZIPCODE,
                                    entCOUNTRY_CODE: this.state.entCOUNTRY_CODE,
                                    valADDRESS1: this.state.valADDRESS1,
                                    valADDRESS2: this.state.valADDRESS2,
                                    valCITY: this.state.valCITY,
                                    valSTATE: this.state.valSTATE,
                                    valZIPCODE: this.state.valZIPCODE,
                                    valCOUNTRY_CODE: this.state.valCOUNTRY_CODE,
                                    MELISSA_ENGINE_INFO: this.state.MELISSA_ENGINE_INFO
                                };

                                this.updateAddress(address);

                            }} value={this.state.entCITY} />
                        </div>
                    </div>
                    <div className={styles.container}>
                        <div className={styles.long}>
                            <div>COUNTRY</div>
                            <select className={this.props.error ? styles.defaultInputForm : styles.errorInputForm} onChange={(event) => {

                                this.setState({ selectedcountry: event.target.value, entCOUNTRY_CODE: event.target.value });


                                let address = {
                                    entADDRESS1: this.state.entADDRESS1,
                                    entADDRESS2: this.state.entADDRESS2,
                                    entCITY: this.state.entCITY,
                                    entSTATE: this.state.entSTATE,
                                    entZIPCODE: this.state.entZIPCODE,
                                    entCOUNTRY_CODE: event.target.value,
                                    valADDRESS1: this.state.valADDRESS1,
                                    valADDRESS2: this.state.valADDRESS2,
                                    valCITY: this.state.valCITY,
                                    valSTATE: this.state.valSTATE,
                                    valZIPCODE: this.state.valZIPCODE,
                                    valCOUNTRY_CODE: this.state.valCOUNTRY_CODE,
                                    MELISSA_ENGINE_INFO: this.state.MELISSA_ENGINE_INFO
                                };

                                this.updateAddress(address);

                            }} value={this.state.entCOUNTRY_CODE}>
                                <option value=""></option>
                                {countryOptions}
                            </select>
                        </div>
                        <div className={styles.short}>
                            <div>STATE / PROVINCE</div>
                            <select className={styles.defaultInputForm} onChange={(event) => {
                                this.setState({ entSTATE: event.target.value });

                                let address = {
                                    entADDRESS1: this.state.entADDRESS1,
                                    entADDRESS2: this.state.entADDRESS2,
                                    entCITY: this.state.entCITY,
                                    entSTATE: event.target.value,
                                    entZIPCODE: this.state.entZIPCODE,
                                    entCOUNTRY_CODE: this.state.entCOUNTRY_CODE,
                                    valADDRESS1: this.state.valADDRESS1,
                                    valADDRESS2: this.state.valADDRESS2,
                                    valCITY: this.state.valCITY,
                                    valSTATE: this.state.valSTATE,
                                    valZIPCODE: this.state.valZIPCODE,
                                    valCOUNTRY_CODE: this.state.valCOUNTRY_CODE,
                                    MELISSA_ENGINE_INFO: this.state.MELISSA_ENGINE_INFO
                                };

                                this.updateAddress(address);

                            }} value={this.state.entSTATE} >
                                <option value=""></option>
                                {countrystateOptions}
                            </select>
                        </div>
                        <div className={styles.short}>
                            <div>POSTAL CODE</div>
                            <input type="text" className={this.props.error ? styles.defaultInputForm : styles.errorInputForm} onChange={(event) => {
                                //this.updatezip(event.target.value)
                                this.setState({ entZIPCODE: event.target.value });


                                let address = {
                                    entADDRESS1: this.state.entADDRESS1,
                                    entADDRESS2: this.state.entADDRESS2,
                                    entCITY: this.state.entCITY,
                                    entSTATE: this.state.entSTATE,
                                    entZIPCODE: event.target.value,
                                    entCOUNTRY_CODE: this.state.entCOUNTRY_CODE,
                                    valADDRESS1: this.state.valADDRESS1,
                                    valADDRESS2: this.state.valADDRESS2,
                                    valCITY: this.state.valCITY,
                                    valSTATE: this.state.valSTATE,
                                    valZIPCODE: this.state.valZIPCODE,
                                    valCOUNTRY_CODE: this.state.valCOUNTRY_CODE,
                                    MELISSA_ENGINE_INFO: this.state.MELISSA_ENGINE_INFO
                                };

                                this.updateAddress(address);

                            }} value={this.state.entZIPCODE} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AddressLookUpExUs;
