import React, { Component } from 'react'
import Styles from './ReportUI.module.css'
import Axios from '../../components/Axios'
import axios from 'axios'
import DatePicker from 'react-datepicker';
class ReportUI extends Component {

    constructor(props) {
        super(props);

        this.state = {
            title: this.props.title,
            to: new Date(),
            from: new Date()
        };

       
    }
    componentDidMount() {
        
    }



    render() {

        return (

            <div className={Styles.editScreen}>
                <div className={Styles.title}>

                    <div className={Styles.TitleText}>{this.state.title}</div>
                    <div className={Styles.close} onClick={() => {
                        this.props.close()
                    }}><i className="fas fa-times"></i></div>
                </div>
                <div className={Styles.body}>

                    <div className={Styles.screenTitle}>{this.state.title}</div>


                    <div className={Styles.filter}>
                            EXPORT FORMAT
                            <select >
                     
                                <option value='Excel' selected>Excel</option>
                            </select>

                    </div>

                    <div className={Styles.filter}>
                        FROM
                        <DatePicker className={Styles.reactdatetime} maxDate={new Date()} selected={this.state.from} onChange={(event) => {

                            this.setState({ from: event })
                        }} />
                    </div>


                    <div className={Styles.filter}>
                       TO
                        <DatePicker className={Styles.reactdatetime} selected={ this.state.to} onChange={(event) => {
                            this.setState({ to: event })
                        }} />
                    </div>

                  

                    {/*<div className={Styles.databody}>*/}



                    {/*</div>*/}

                </div>


                <div className={Styles.buttonRow}>
                    <div className={Styles.cancel}  onClick={() => {
                        this.props.close();
                    }}>CANCEL</div>
                    
                    <div className={Styles.submit} onClick={() => {
                    

                        this.props.export(this.state.to, this.state.from);

                    }}>EXPORT</div>
                </div>
            </div>


        )
    }

}
export default ReportUI;







