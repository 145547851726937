import React from 'react'
import styles from './../css/StatusBox.module.css'
import arrow from '../../../../assets/purple-arrow.png'
import TargetColorBox from './TargetColorBox'

function StatusBar(props) {

    let view =  <div className={styles.setmiddle}><div className={styles.key}>CURRENT</div><div className={styles.value}>{props.current || "0"}</div></div>;
    let arr = <img className={styles.arrow} src={arrow} alt={<i class="fas fa-long-arrow-alt-right"></i>} />;
    let team = props.team;

    if (team == '11') {
        view = '';
        arr = '';
    }

    return (
        <div className={[styles.box, styles.left].join(' ')}>
            <TargetColorBox target={props.target} />

            {view}

            {arr}

           

            <div className={styles.setmiddle}>
                <div className={styles.key}>FINAL</div>
                <div className={styles.value}>{props.final || "0"}</div>
            </div>
        </div>
    )
}

export default StatusBar
