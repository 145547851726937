import React, { Component } from 'react'
import Styles from './WorkflowStatus.module.css'

class WorkflowStatusDiagramBlock extends Component {
    constructor(props) {
        super(props);
        this.state = {
          
        };
    }
   
   
    render() {

        var arrow = (<div className={Styles['arrow' + this.props.color]}></div>);

        return (   
          
            <li className={Styles.progressnavliparent}>
                <div className={[Styles.progressnavli, Styles[this.props.color]].join(" ")}>{this.props.text}</div>
                {this.props.isLast == 'true' ? '' : arrow}
            </li>

        )
    }

}
export default WorkflowStatusDiagramBlock;







