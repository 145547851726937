import React, { Component } from 'react'
import Styles from './PharmActionDialog.module.css'
import Axios from '../../components/Axios'
import DatePicker from 'react-datepicker';

import { Checkbox } from 'semantic-ui-react';

class PharmAction extends Component {
    constructor(props) {
        super(props);

        this.state = {
            alignment: '',
            isHCP: this.props.is_hcp,
            mdm_id: this.props.mdm_id,
            orgname: this.props.org_name,
            err: '',
            dtvalue: '',
            duedate: '',
            user_name: this.props.user,
            terrid: this.props.terrid,
            terrname: this.props.terrname,
            curr_state: '',
            selected: '',
            state_desc: '',
            checkboxes: []
        };


       
    }
    setAffiliation(id, isRemove) {
        
        var arr = this.state.selected_affiliation_ids;

        if (isRemove) {

            var newArr = [];

            arr.forEach((temp) => {

                if (temp != id) {
                    newArr.push(temp);
                }
            });
            this.setState({ selected_affiliation_ids: newArr });
        } else {
            arr.push(id);
            this.setState({ selected_affiliation_ids: arr });
        }
       
    }
   
    componentDidMount() {

       // if (this.state.isHCP) {

            Axios(`/api/fpm/get-affiliations`, ({
                mdm_id: this.props.mdm_id,
                is_hcp: false
            }), (data) => {


                var affiliation_array = [];

                data.forEach((temp) => {
                    affiliation_array.push(<div ><input className={Styles.check1} type="Checkbox" id={temp.PROF_ID} onChange={(event) => {
                        if (event.target.checked) {
                            this.setAffiliation(event.target.id, false);
                        }
                        else {
                            this.setAffiliation(event.target.id, true);
                        }
                    }}  /> <b>{temp.NAME}</b><i>,  {temp.DEGREE} ({temp.SPECIALTY}) </i></div>)

                    });

                this.setState({ affiliations: affiliation_array})


            })

        let params = {
           
            mdm_id: this.props.mdm_id,
            is_hcp: false
        }
        Axios('/api/fpm/get-alignment', params, (data) => {


            var alignString = data[0].TERRITORY;
            this.setState({ alignment: alignString })


            }, (error) => console.error(error));


       // }
        

    }
    allFieldsAreValid() {

        //this routine validates on submit click

        var dtval = this.state.dtvalue;

        var desc = this.state.state_desc;
        var currstate = this.state.curr_state;
        var due = this.state.duedate;


        //if (dtval == '' || dtval == undefined || dtval == null ) {

        //    this.setState({ err: 'Engagement Date is required' });
        //    return false;

        //} 
       
        //if (due == '') {

        //    this.setState({ err: 'Due Date is required' });
        //    return false;

        //}
        if (currstate == '') {

            this.setState({ err: 'Current State is required' });
            return false;

        }
        //if (desc == '') {

        //    this.setState({ err: 'Current State Description is required' });
        //    return false;

        //}

       
        this.setState({ err: '' });
        return true;
    }
    validateFields() {

        //this roputive validates contact fields only 

        var cname = this.state.contactname;
        var ctitle = this.state.contacttitle;
        var cphone = this.state.contactphone;
        var cemail = this.state.contactmail;

        if (cname == '') {
           
            this.setState({err:'Contact Name is required'});
            return false;
        }

        var isDup = false;

        var arr = this.state.contact_arr;
        arr.forEach((temp) => {

            if (temp.NAME.toUpperCase() === cname.toUpperCase()) {

                isDup = true;
               
            }

        });

        if (isDup) {
            this.setState({ err: 'Contact already exists.' });
            return false;
        }


        if (ctitle == '') {
          
            //this.setState({ err: 'Contact Title is required' });
            //return false;

        }
        if (cphone != '') {

         

            if (!this.isValidPhone(cphone)) {
                this.setState({ err: 'Contact Phone is not in the required format' });
                return false;
            }
           

        }
        if (cemail != '' && !this.isValidEmail(cemail)) {
           
            this.setState({ err: 'Contact Email is not in the required format' });
            return false;

        }


       

        this.setState({ err: '' });
        return true;

    }
    isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }
    isValidPhone(phone) {

       
       // alert(phone.length);
        if (phone.length == 16) {
            return true;
        }



        return false;
    }
    formatPhoneNumber(input) {
    // Strip all characters from the input except digits
    input = input.replace(/\D/g, '');

    // Trim the remaining input to ten characters, to preserve phone number format
    input = input.substring(0, 10);

    // Based upon the length of the string, we add formatting as necessary
    var size = input.length;
    if (size == 0) {
        input = input;
    } else if (size < 4) {
        input = '(' + input;
    } else if (size < 7) {
        input = '(' + input.substring(0, 3) + ') ' + input.substring(3, 6);
    } else {
        input = '(' + input.substring(0, 3) + ') ' + input.substring(3, 6) + ' - ' + input.substring(6, 10);
    }
    return input;
    }
    //removeContact(name) {


    //    var submitArr = this.state.contact_arr;
       
    //    //var nm = this.state.contactname;

    //    var newArr = [];
    //    var newSubmitArr = [];


    //    submitArr.forEach((temp) => {


    //        if (temp.NAME === name) {

    //            //remove it

    //        } else {

    //            let ctx = {
    //                NAME: temp.NAME,
    //                TITLE: temp.TITLE,
    //                PHONE: temp.PHONE,
    //                EMAIL: temp.EMAIL
    //            };


    //            newSubmitArr.push(ctx);

    //            newArr.push(<div className={this.state.contact_is_odd ? [Styles.odd, Styles.contactRow].join(" ") : [Styles.even, Styles.contactRow].join(" ")}>

    //                <div className={Styles.contactName} >{temp.NAME}</div>
    //                <div className={Styles.contactTitle} >{temp.TITLE}</div>
    //                <div className={Styles.contactPhone} >{temp.PHONE}</div>
    //                <div className={Styles.contactEmail} >{temp.EMAIL}</div>
    //                <div className={Styles.searchrowadd} onClick={() => {

    //                    this.removeContact(temp.NAME);
    //                }} ><i className={Styles.remove} >Remove</i></div>
    //            </div>);
    //        }

    //    });

    //    this.setState({ contact_arr: newSubmitArr, contacts: newArr })
    //}
    //addContact(data) {

    //    if (data) {

    //        var fieldsValid = this.validateFields();       
    //        var arr = this.state.contacts;
    //        var submitArr = this.state.contact_arr;
    //        var name = data.NAME;

    //        if (fieldsValid === true) {
          
    //            arr.push(<div className={this.state.contact_is_odd ? [Styles.odd, Styles.contactRow].join(" ") :  [Styles.even, Styles.contactRow].join(" ")}>

    //                <div className={Styles.contactName} >{this.state.contactname}</div>
    //                <div className={Styles.contactTitle} >{this.state.contacttitle}</div>
    //                <div className={Styles.contactPhone} >{this.state.contactphone}</div>
    //                <div className={Styles.contactEmail} >{this.state.contactmail}</div>
    //                <div className={Styles.searchrowadd}  ><i className={Styles.remove} onClick={() => {

    //                    this.removeContact(name);
    //                }}>Remove</i></div>
    //            </div>);


    //            let ctx = {
    //                NAME: this.state.contactname,
    //                TITLE: this.state.contacttitle,
    //                PHONE: this.state.contactphone,
    //                EMAIL: this.state.contactmail
    //            };


    //            submitArr.push(ctx);
    //            this.setState({ contact_arr: submitArr,contacts: arr, contactname: '', contacttitle: '', contactphone: '', contactmail: '', contact_is_odd: !this.state.contact_is_odd})
    //        }
    //    }

    //}

    render() {

        var sixtyDaysAgo = new Date(new Date().setDate(new Date().getDate() - 60));
        var ninetyDays = new Date(new Date().setDate(new Date().getDate() + 90));
        const changeSelectOptionHandler = (event) => {

            this.setState({ curr_state: event.target.value, selected: event.target.value });

        };
        const changeDescOptionHandler = (event) => {

            this.setState({ state_desc: event.target.value });

        };
        const checkHandlerCo = (event) => {
            this.setState({ Covered_Avg_Patient_Benefit: event.target.checked });
        };
        const checkHandlerRe = (event) => {
            this.setState({ Pct_Replenishment: event.target.checked });
        };
        const checkHandlerSD = (event) => {
            this.setState({ Supply_and_Demand_Coverage: event.target.checked });
        };
        const checkHandlerCCC = (event) => {
            this.setState({ Copay_Card_Coverage: event.target.checked });
        };
        const checkHandlerOtwo = (event) => {
            this.setState({ Covered_Pt_Benefit_Under_200: event.target.checked });
        };
        const checkHandlerUtwo = (event) => {
            this.setState({ Covered_Pt_Benefit_Under_200: event.target.checked });
        };
        const checkHandlerNI = (event) => {
            this.setState({ Negative_Inventory: event.target.checked });
        };
        const assessment = [
            {
                value: "Assessment 1",
                label: "Assessment 1"
            },
            {
                value: "Assessment 2",
                label: "Assessment 2"
            }
        ];
        let intervention = [
            {
                value: "Intervention 1",
                label: "Intervention 1"
            },
            {
                value: "Intervention 2",
                label: "Intervention 2"
            }
        ];
        let escalation = [{
            value: "Escalation 1",
            label: "Escalation 1"
        },
            {
                value: "Escalation 2",
                label: "Escalation 2"
            }];
        let corrective = [{
            value: "Corrective 1",
            label: "Corrective 1"
        },
        {
            value: "Corrective 2",
            label: "Corrective 2"
            }];
        let closed = [{
            value: "Closed 1",
            label: "Closed 1"
        },
        {
            value: "Closed 2",
            label: "Closed 2"
            }]
        //let options = null; 
        let type = null;

        if (this.state.selected === "Assessment") {
            type = assessment;
        } else if (this.state.selected === "Intervention") {
            type = intervention;
        } else if (this.state.selected === "Escalation") {
            type = escalation;
        } else if (this.state.selected === "Corrective Action") {
            type = corrective;
        } else if (this.state.selected === "Closed") {
            type = closed;
        } 
        let ops = [<option key="000" value={''}></option>]
        if (type) {

            
            for (let i = 0; i < type.length; i++) {
                ops.push(<option key={type[i].value} value={type[i].value}>{type[i].label}</option>)
            }
            //options = type.map((el) => <option key={el}>{el}</option>);
            //this.setState({options: options})
        } 


        return (

            <div className={Styles.editScreen}>
                <div className={Styles.title}>
                    <div></div>
                    <div className={Styles.TitleText}>Pharmacy Action - {this.state.orgname}</div>
                    <div
                        className={Styles.close}>
                    </div>
                </div>
                <div className={Styles.body}>
                    <div className={Styles.formdiv}>
                        <div className={Styles.formtitleleft}>ACCOUNT:  <b>{this.state.orgname} </b></div>
                    
                    
                       
                      
                    </div>
                    <hr />
                    <div className={Styles.formdiv}>
                        <div className={Styles.formtitleleft}>PHARMACY DATE OF ENGAGEMENT</div>
                        <div className={Styles.formcontrolleft}>
                            <DatePicker className={Styles.reactdatetime}  selected={this.state.dtvalue} onChange={(event) => {
                                this.setState({ dtvalue: event });
                            }}  />
                        </div>
                    </div>
                    <div className={Styles.formdiv}>
                        <div className={Styles.formtitleleft}>DUE DATE</div>
                        <div className={Styles.formcontrolleft}>
                            <DatePicker className={[Styles.reactdatetime]} selected={this.state.duedate} onChange={(event) => {
                                this.setState({ duedate: event });
                            }} />

                        </div>
                    </div>
                    <hr />
                    <div className={Styles.formtitleleftflag}>LIST ALL FLAGS THAT APPLY</div>
                    <div  >
                        <div className={Styles.checkrow}> <input type="checkbox" onChange={checkHandlerNI} id="Negative Inventory" /><div className={Styles.checkdivtext}> Negative Inventory </div ></div >
                        <div className={Styles.checkrow}><input type="checkbox" onChange={checkHandlerSD} id="Supply and Demand Coverage" /><div className={Styles.checkdivtext}> Supply and Demand Coverage</div></div>
                        <div className={Styles.checkrow}><input type="checkbox" onChange={checkHandlerCCC} id="Copay Card Coverage" /><div className={Styles.checkdivtext}> Copay Card Coverage</div></div>
                        <div className={Styles.checkrow}><input type="checkbox" onChange={checkHandlerCo} id="Covered Avg Patient Benefit"/><div className={Styles.checkdivtext }> Covered Avg Patient Benefit</div></div>
                        <div className={Styles.checkrow}><input type="checkbox" onChange={checkHandlerUtwo} id="Covered Pt Benefit Under 200" /><div className={Styles.checkdivtext} > Covered Pt Benefit Under 200 </div></div>
                        <div className={Styles.checkrow}><input type="checkbox" onChange={checkHandlerOtwo} id="Covered Pt Benefit Over 1500" /><div className={Styles.checkdivtext}> Covered Pt Benefit Over 1500</div ></div>
                       
                    </div>

                    <hr />

                    <div className={Styles.formdiv}>
                        <div className={Styles.formtitleleft}>CURRENT STATE</div>
                        <div className={Styles.formcontrolleft}>

                            <select id="curr_state" onChange={changeSelectOptionHandler}>
                                <option></option>
                                <option key="assessment">Assessment</option>
                                <option key="intervention">Intervention</option>
                                <option key="escalation">Escalation</option>
                                <option key="corrective">Corrective Action</option>
                                <option key="closed">Closed</option>
                            </select>
                        </div>

                    </div>

                    {/*<div className={Styles.formdiv}>*/}
                    {/*    <div className={Styles.formtitleleft}>DESCRIPTION</div>*/}
                    {/*    <div className={Styles.formcontrolleft}>*/}

                    {/*        <select id="state_desc" onChange={changeDescOptionHandler}>*/}
                    {/*            {ops } */}
                    {/*        </select>*/}

                    {/*    </div>*/}

                    {/*</div>*/}
                    <div className={Styles.error}>{this.state.err}</div>
                </div>
                <div className={Styles.buttonRow}>
                    <div id={Styles.submit} onClick={() => {
                       this.props.close();
                    }}>CANCEL</div>
                    <div id={this.state.IsSummaryScreen || this.state.nextScreenIndex == this.state.currentScreenIndex ? Styles.submit : Styles.disable} onClick={() => {

                        if (this.allFieldsAreValid()) {

                            var chkString = '';
                            if (this.state.Covered_Avg_Patient_Benefit) { chkString += 'Covered_Avg_Patient_Benefit;'; }
                            //if (this.state.Pct_Replenishment) { chkString += 'Pct_Replenishment;'; }
                            if (this.state.Supply_and_Demand_Coverage) { chkString += 'Supply_and_Demand_Coverage;'; }
                            if (this.state.Copay_Card_Coverage) { chkString += 'Copay_Card_Coverage;'; }
                            if (this.state.Covered_Pt_Benefit_Over_200) { chkString += 'Covered_Pt_Benefit_Over_200;'; }
                            if (this.state.Covered_Pt_Benefit_Under_200) { chkString += 'Covered_Pt_Benefit_Under_200;'; }
                            if (this.state.Negative_Inventory) { chkString += 'Negative_Inventory;'; }

                            let temp = {
                                CALL_DATE: this.state.dtvalue,
                                ENGAGEMENT_DATE: this.state.dtvalue,
                                DUE_DATE: this.state.duedate,
                                ALIGNMENT: 'NA',
                                ORG_ID: this.state.mdm_id,
                                USER_NAME: this.state.user_name,
                                TERRID: this.state.terrid,
                                CURRENT_STATE:this.state.curr_state,
                                STATE_DESCRIPTION: this.state.state_desc,
                                FLAG_LIST: chkString,
                                IS_PHARM: 'Y'

                            };


                            this.props.submit(temp);

                        }
                        
                    }}>SUBMIT</div>
                </div>
            </div>
        )
    }

}
export default PharmAction;







