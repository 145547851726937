import React, {useState, useEffect} from 'react'
import styles from './../css/StatusBar.module.css'
import StatusBox from './StatusBox.js'
import StatusLock from './StatusLock.js'
import Axios from '../../../../components/Axios'

function StatusBar(props) {
    const [statusTable, setStatusTable] = useState()

    useEffect(() => {
        Axios('/api/best-address-exercise/get-statuses', {}, (data) => {
            setStatusTable(data)
        })
    }, [])

    const statusIndex = props.status ? props.status.STATUS_ID : 0
    const moveIn = props.status ? props.status.TOTAL_MOVED_IN : 0
    const moveOut = props.status ? props.status.TOTAL_MOVED_OUT : 0

    return (<div className={styles.statusBar}>
        <div className={styles.box}>
            <div className={styles.statusTopBar}>STATUS</div>
            <div className={styles.status}>{statusTable && props.status ? statusTable[statusIndex-1].STATUS : "Loading..."}</div>
        </div>
        <StatusBox title='Territory Count' moveIn={moveIn} moveOut={moveOut} />
        <div className={styles.description}>By default, HCPs will be moved to the Best Address unless ALLOW MOVE TO NEW TERRITORY is changed to “No”</div>
        <StatusLock {...props} statusIndex={statusIndex}/>
    </div>)
}

export default StatusBar
