import React, { Component } from 'react';
import styles from '../css/Pagination.module.css';

class Pagination extends Component {
    constructor(props) {
        super(props);
        this.state = {
           
        };



    }

    componentDidMount() {
        
    }
    render() {


        return(
            <div className={styles.pagination}>
                <div className={styles.bottomBarPlace}></div>
                <div className={styles.pages}>
                    <button className={styles.arrow} onClick={() => {
                        // alert('-1');
                        //if (props.currentPage > 0) {
                        //    if (props.currentPage - 1 < 0) {
                        //        props.setCurrentPage(0)
                        //    } else {
                        this.props.setCurrentPage(this.props.currentPage - 1, this.props.searchData)
                        //    }
                        //}
                    }}>
                        <i className="fas fa-angle-left"></i>
                    </button>
                    <div>
                        {
                            `${Math.round(this.props.currentPage * this.props.resultsPerPage) + 1}-${this.props.totalResults > ((this.props.currentPage * this.props.resultsPerPage) + this.props.resultsPerPage)
                                ? Math.round((this.props.currentPage * this.props.resultsPerPage) + this.props.resultsPerPage)
                                : this.props.totalResults} of ${this.props.totalResults}`
                        }

                    </div>
                    <button className={styles.arrow} onClick={() => {
                        //if (props.currentPage + 1 < props.totalResults / props.resultsPerPage) {
                        this.props.setCurrentPage(this.props.currentPage + 1, this.props.searchData)
                        //}

                    }}>
                        <i className="fas fa-angle-right"></i>
                    </button>
                </div>
                <div className={styles.viewWrapperRed}>{this.props.totalResults > 249 ? '250 record limit has been exceeded.' : ''}</div>
            </div>
        )
    }
}

export default Pagination
