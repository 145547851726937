import React from 'react'
import styles from '../css/DemotionReason.module.css'

function AddNewReason(props) {
    return (
        <div className={[styles.wrapper, styles.addNew].join(" ")} onClick={() => {
            props.addReason()
        }}>
            <i className={["fas", "fa-plus", styles.plus].join(" ")}></i>
            <div>Add New Reason</div>
        </div>
    )
}

export default AddNewReason
