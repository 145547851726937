import React, { useState } from 'react'
import Styles from './WorkflowApprovalDialog.module.css';


export default function WorkflowConfirmationDialog(props) {

    const [notes, setNotes] = useState('')
    const [id, setID] = useState(props.id)
    const [program, setProgram] = useState(props.program)
    const [org, setOrg] = useState(props.org)
    const [start, setStart] = useState(props.start)
    const [approve, setApprove] = useState(props.approve)

  
  
  return (
    <div className={Styles.editScreen}>
      <div className={Styles.title}>
        <div></div>
              <div className={Styles.TitleText}> {props.title}</div>
        <div
          className={Styles.close}>
        </div>
      </div>
      <div className={Styles.body}>
       
        <div className={Styles.firstLine}>
                  {props.firstline}<br /><br />
               
                  {props.secondline}<br /><br />
        </div>
      </div>
          <div className={Styles.buttonRow}>
              <div id={Styles.submit} onClick={() => {
                  props.close();
              }}>OK</div>
          
          </div>
    </div>
  );
}
