import React, {useState} from 'react'
import Popup from '../../Popup/Popup'
import Success from '../../Success/Success'
import Axios from '../../Axios/Axios'

function DeleteExercise(props) {
    const [success, setSuccess] = useState()

    const submit = () => {
        setSuccess(null)
        let temp = {
            id: props.data.ID,
            type: props.data.CUSTOMER_TYPE
        }
        Axios(props.endpoints.deleteExercise, temp, (data) => {
            props.setData(data)
            setSuccess(true)
        })
    }
    let body
    if (props.data) {
        body = (
            <div className={"pop-body"}>
                <div className={"pop-titleRow"}>
                    <div className={"pop-directions"}>Are you sure you want to delete</div>
                </div>
                <div className={"pop-content"} style={{width: "100%"}}>
                    <div className={"flex-row"} style={{justifyContent: "center", fontWeight: "bold", fontSize: "18px"}}>
                        {props.data.NAME}
                    </div>
                    <div className={"pop-buttonRow"}>
                        <button type="button" id={"pop-cancel"} onClick={() => {
                                props.close()
                            }
                        }>CLOSE</button>
                    <button id={"pop-submit"} onClick={submit}>SUBMIT</button>
                    </div>
                </div>
            </div>
        )
    }
    if (success !== undefined) {
        return (
            <Success {...props} success={success} successMessage={"Success! You have successfully deleted the exercise."}/>
        )
    }
    else {
        return (
            <Popup {...props} body={body} title={"Delete Exercise"} />
        )
    }
}

export default DeleteExercise
