import React, {Component} from 'react';
import styles from './ResultTitles.module.css';

class ResultTitles extends Component {
    constructor(props) {
        super(props);

        this.state = {
            Titles: this.props.Titles,
            NewSearch: this.props.NewSearch
        };
    }

    render() {
        let arr = [];
        if (this.props.even) {
            for (let i = 0; i < this.state.Titles.length; i++) {
                if (this.props.skip !== undefined && this.props.skip.includes(i)) {
                    arr.push(<div key={i} className={styles.Title}>{this.state.Titles[i]}</div>)
                }
                else {
                    arr.push(<div key={i} onClick={() => {
                        this.props.Sort(i)
                    }} className={[styles.Title, styles.hasArrow].join(' ')}><div className={styles.holder}></div>{this.state.Titles[i]}<div className={this.props.sortBy === i ? [styles.down, styles.sortBy].join(' ') : styles.down}><i className="fas fa-caret-down"></i></div></div>)
                }
            }
        }
        else {
            for (let i = 0; i < this.state.Titles.length; i++) {
                if (this.props.flag) {
                    if (i !== 8 && i !== 9) {
                        arr.push(<div key={i} onClick={() => {
                            this.props.Sort(i)
                        }} className={[styles.Title, styles.hasArrow].join(' ')}><div className={styles.holder}></div>{this.state.Titles[i]}<div className={this.props.sortBy === i ? [styles.down, styles.sortBy].join(' ') : styles.down}><i className="fas fa-caret-down"></i></div></div>)
                    }
                    else {
                        arr.push(<div key={i} className={styles.Title}>{this.state.Titles[i]}</div>)
                    }
                }
                else {
                    if (i !== 0 && i !== 2) {
                        if (i === 1) {
                            arr.push(<div key={i} onClick={() => {
                                this.props.Sort(i)
                            }} className={[styles.Title, styles.hasArrow, styles.name].join(' ')}><div className={styles.holder}></div>{this.state.Titles[i]}<div className={this.props.sortBy === i ? [styles.down, styles.sortBy].join(' ') : styles.down}><i className="fas fa-caret-down"></i></div></div>)
                        }
                        else {
                            arr.push(<div key={i} onClick={() => {
                                this.props.Sort(i)
                            }} className={[styles.Title, styles.hasArrow].join(' ')}><div className={styles.holder}></div>{this.state.Titles[i]}<div className={this.props.sortBy === i ? [styles.down, styles.sortBy].join(' ') : styles.down}><i className="fas fa-caret-down"></i></div></div>)
                        }
                    }
                    else {
                        if (i === 0) {
                            arr.push(<div key={i} className={[styles.Title, styles.status].join(' ')}>{this.state.Titles[i]}</div>)
                        }
                        else {
                            arr.push(<div key={i} className={styles.Title}>{this.state.Titles[i]}</div>)
                        }
                    }
                }
            }
        }
        return (
        <div className={styles.ResultTitles}>
            {arr}
        </div>
        );
    }
}

export default ResultTitles;
