import React, { Component } from 'react'
import Styles from './ExUsDetails.module.css'
import Axios from '../../components/Axios/Axios'
import Loading from '../../components/Loading/Loading'
import DetailsInformation from './components/Details_Information'
//import DetailsPriAddress from './components/Details_PriAddress';
import AddressAlignment from "./components/address-alignment/AddressAlignment";
import DetailsAffiliations from './components/Details_Affiliations'
import DetailsChangeRequests from './components/Details_ChangeRequests'
import DetailsEdit from './components/Details_Edit'
import AddressHistory from './components/Details_Address_History'
import AddExUsSuccess from './components/AddExUsSuccess'

class ExUsDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            spinning: false,
            addslnsuccess: false,
            prof_id: this.props.match.params.PROF_ID,
            data: []
        };
    }
    addTo(index, i, added, addSpend) {
        let json = {}
        let id = this.state.data.data[0].PROF_ID;
        if (added) {
            if (i === 0) {
                json.REQUEST_TYPE = "EXUS Remove from - Spend";
            } else if (i === 1) {
                json.REQUEST_TYPE = "EXUS Remove from - CRM";
            } else if (i === 2) {
                json.REQUEST_TYPE = "EXUS Remove from - CTMS";
            }
        } else {
            if (i === 0) {
                json.REQUEST_TYPE = "EXUS Add to - Spend";
            } else if (i === 1) {
                json.REQUEST_TYPE = "EXUS Add to - CRM";
            } else if (i === 2) {
                json.REQUEST_TYPE = "EXUS Add to - CTMS";
            }
        }

       // this.props.hash(id, i, !added)
        this.props.hash(this.state.data.data[0].PROF_ID, i, !added)

        json.PROF_ID = id
        json.REQUESTOR_ID = this.props.user.email
        json.REQUESTOR_NAME = this.props.user.userName
        json.REQUESTOR_TEAM = this.props.user.team
        json.REQUESTOR_TERR = this.props.user.terr

        Axios('/api/global/change-request', json, (data) => {
            if (addSpend) {
                let json = {}
                json.REQUEST_TYPE = "EXUS Add to - Spend";
                json.PROF_ID = id
                json.REQUESTOR_ID = this.props.user.email
                json.REQUESTOR_NAME = this.props.user.userName
                json.REQUESTOR_TEAM = this.props.user.team
                json.REQUESTOR_TERR = this.props.user.terr
                this.props.hash(id, 0, !added)
                Axios('/api/global/change-request', json, (data) => { })
            }
        })
    }

    
    closeEdit() {
        this.setState({ editScreen: undefined, addslnsuccess: true })
    }

   submitEdit(json, list) {
       // alert('submitEdit');
        if (!list) {
            json.PROF_ID = this.state.data.data[0].PROF_ID
            json.REQUESTOR_ID = this.props.user.email
            json.REQUESTOR_NAME = this.props.user.userName
            json.REQUESTOR_TEAM = this.props.user.team
            json.REQUESTOR_TERR = this.props.user.terr
        }
        Axios('/api/global/change-request', json, (data) => {
            this.closeEdit();
          
            //this.setChange(data);
        })
    }

    editScreen(i, id) {

      //  console.log('here');

        this.setState({
            editScreen: <DetailsEdit
                editScreen={this.editScreen.bind(this)}
                //affiliations={this.state.affiliations}
                data={this.state.data}
                submit={this.submitEdit.bind(this)}
                //change={this.state.change}
                addresses={this.state.addresses}
                close={this.close.bind(this)}
                //id={id}
                index={i}
                user={this.props.user}
                //msl={this.state.msl}
                //setChange={this.setChange.bind(this)}
                //updateChanges={this.updateChanges.bind(this)}
            />
        })
    }


    componentDidMount() {

        this.loadChange();


    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data !== this.props.data || prevProps.hashes !== this.props.hashes) {
            this.loadChange();
        }
    }

    loadChange() {



        let json = {
            PROF_ID: this.state.prof_id,
            TEAM: this.props.user.team
        }

        
        Axios('/api/exus/xus', json, (results) => {

            let profID = results.data[0].PROF_ID

           // let addresses = [results.addresses, results.secondaryAddresses]
            results.data[0].ctms = this.props.hashes.ctms[profID]
            results.data[0].crm = this.props.hashes.crm[profID]
            results.data[0].spend = this.props.hashes.spend[profID]

            this.setState({ data: results, loaded: true, addresses: results.secondaryAddresses });



        })
    }

    setData(newData) {

        //let alignment = [newData.team, newData.ots, newData.oas, newData.ocne, newData.msl]
        //let addresses = [newData.primaryAddress, newData.bestAddress, newData.secondaryAddresses, newData.addresses]
        //let affiliations = newData.affiliations
        //let change = []
        //let msl = {}
        //for (let i = 0; i < alignment.length; i++) {
        //    if (alignment[i] === null || alignment[i] === undefined) {
        //        alignment[i] = []
        //    }
        //}
        //for (let i = 0; i < addresses.length; i++) {
        //    if (addresses[i] === null) {
        //        addresses[i] = []
        //    }
        //}
        //if (newData.affiliations) {
        //    affiliations = newData.affiliations
        //}
        //else {
        //    affiliations = []
        //}
        //if (newData.changeRequests) {
        //    change = newData.changeRequests
        //}
        //else {
        //    change = []
        //}
        //if (newData.regionalPlan && newData.regionalPlan !== null) {
        //    for (let i = 0; i < newData.regionalPlan.length; i++) {
        //        msl[newData.regionalPlan[i].ATTR_NAME] = newData.regionalPlan[i].ATTR_VALUE
        //    }
        //}

        //this.setState({
        //    addresses: addresses,
        //    affiliations: affiliations,
        //    alignment: alignment,
        //    change: change,
        //    msl: msl,
        //    loaded: true,
        //    data: newData.data[0]
        //})
    }

    //setChange() {
    //    Axios('/api/details/e/change-requests', {PROF_ID: this.state.data.PROF_ID}, (data) => {
    //        if (this.state.editScreen) {
    //            this.editScreen(11)
    //        }
    //        this.setState({
    //            change: data
    //        })
    //    })
    //}

    //updateChanges() {
    //    this.loadChange()
    //}

    close() {
        this.setState({ editScreen: undefined })
    }
    closeSlnSuccess() {
        this.setState({ addslnsuccess: false });
    }
    render() {
        let successDialog = this.state.addslnsuccess ? (<AddExUsSuccess close={this.closeSlnSuccess.bind(this)} />) : ""

       if (this.state.loaded) {
        return (
            <div className={Styles.Details}>
                {this.state.editScreen ? this.state.editScreen : ""}
                <div className={Styles.TopBar}>
                    <div className={Styles.Back} onClick={() => {



                        //console.log(this.props.history.location.state);
                       // console.log(this.props.history.location.state.from);
                       
                     //   alert(this.props.history.location.state.from);
                        if (this.props.history.location.state !== undefined && this.props.history.location.state.from === '/xus') {
                            //alert('1');
                            this.props.history.push({
                                pathname: '/xus',
                                state: {
                                    from: '/xus/details'
                                }
                            })
                        }
                        else {
                            //alert('2');
                            //if (document.referrer !== window.location.origin) {
                            //    window.location.href = "/";
                            //}
                            //else {
                                //alert('3');
                            this.props.history.go(-1);
                           //}
                        }
                    }}><i className="fas fa-angle-left"></i>BACK TO SEARCH</div>
                </div>{successDialog}
                <div className={Styles.pageWrapper}>
                    <div className={Styles.Wrapper}>
                        {/*<DetailsInformation editScreen={this.editScreen.bind(this)} submitEdit={this.submitEdit.bind(this)} user={this.props.user} loadChange={this.loadChange.bind(this)} number={this.props.number} addresses={this.state.addresses} msl={this.state.msl} addTo={this.addTo.bind(this)} />*/}
                        <DetailsInformation data={this.state.data.data} editScreen={this.editScreen.bind(this)} submitEdit={this.submitEdit.bind(this)} user={this.props.user} number={this.props.number} addTo={this.addTo.bind(this)} addresses={this.state.data.addresses}    />
                    </div>
                    <div className={Styles.Wrapper}>
                        <AddressAlignment />
                    </div>
                    <div className={Styles.Wrapper}>
                        <AddressHistory addresses={this.state.data.addresses} />
                    </div>
                    <div className={Styles.Wrapper}>
                        <DetailsChangeRequests data={this.state.data.data}/>
                    </div>
                </div>
                
            </div>
        );
        }
        else {
            return (<Loading/>)
        }
    }
}

export default ExUsDetails;
