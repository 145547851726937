import React, { useState } from 'react'
import Styles from './WorkflowApprovalDialog.module.css';


export default function WorkflowApprovalDialog(props) {

    const [notes, setNotes] = useState('')
    const [id, setID] = useState(props.id)
    const [program, setProgram] = useState(props.program)
    const [org, setOrg] = useState(props.org)
    const [start, setStart] = useState(props.start)
    const [approve, setApprove] = useState(props.approve)
    const [title, setTitle] = useState(props.title)
    const [rep, setRep] = useState(props.rep)
    const [err, setErr] = useState('')
  
  return (
    <div className={Styles.editScreen}>
      <div className={Styles.title}>
        <div></div>
              <div className={Styles.TitleText}>{props.title} {org} {program}</div>
        <div
          className={Styles.close}>
        </div>
      </div>
      <div className={Styles.body}>
       
        <div className={Styles.firstLine}>
                  Do you wish to <b> {title.toLowerCase()} </b> the following E&D event?  <br /> <br />{org} <br />{program} <br />EVENT START:  {start}
          <br />
                  <div className={Styles.secondLine}>
                      <div >NOTES { approve == 'REJECTED' ? '(REQUIRED)' : ''}
                          </div>
                      <textarea onChange={(event) => {
                          setNotes(event.target.value)
                      }}
                      >
                      </textarea>

                  </div>
                <div className={Styles.error}>  {err}</div>
        </div>
      </div>
          <div className={Styles.buttonRow}>
              <div id={Styles.cancel} onClick={() => {
                  props.close();
              }}>CANCEL</div>
              <div id={Styles.submit} onClick={() => {

                  if (notes.length == 0 && approve=='REJECTED') {
                      { setErr('Notes are required for Rejection')}
                  } else {
                      //alert(rep);
                      props.submit(id, approve, org,program, start, notes, rep);
                  }
                 
              }}>OK</div>
          </div>
    </div>
  );
}
