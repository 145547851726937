import React, {Component} from 'react';
import styles from '../css/SearchResults.module.css';
import Result from './Result';
import Preview from './Preview';
import ResultTitles from './ResultTitles.jsx';
import AddHcp from './AddHcp';
import AddHco from './AddHco';
import Pagination from'./../../../components/Pagination';
import hcp from '../../../lookup/hcp.json'
import hco from '../../../lookup/hco.json'
import Axios from './../../../globalcomponents/Axios';
import NotificationBox from '../../../globalcomponents/NotificationBox';

class SearchResults extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active: -1,
            sortBy: -1,
            ascending: false,
            preview: false,
            data: this.props.data,
            resultsPerPage: 20,
            currentPage: 0,
            totalResults: this.props.data.length,
            notificationStatus: false,
            errorStatus: false,
            notificationType: "",
            errorByID: null
        };
    }

    updateError(status, type, id) {
        console.error('update error')
        let message = `You cannot ${!status ? 'add' : 'remove'} this ${this.props.type === '/hcp' ? 'professional' : 'organization'} from your ${type}.`
        this.setState({
            notificationMessage: message,
            notificationStatus: true,
            errorStatus: true,
            notificationType: type,
            errorByID: id
        })
    }

    updateNotification(status, type) {
        let message = `You switched ${type} to ${!status ? 'ON' : 'OFF'} for this ${this.props.type === '/hcp' ? 'professional' : 'organization'}.`
        if (type === 'BOTH') {
            message = `You switched CRM and Spend to ON for this ${this.props.type === '/hcp' ? 'professional' : 'organization'}.`
        }
        this.setState({
            notificationMessage: message,
            notificationStatus: true,
            errorStatus: false,
            notificationType: type
        })
    }

    updateNotificationStatus() {
        this.setState({
            notificationStatus: false,
            notificationType: "",
            errorByID: null
        })
    }

    deactivate = (number) => {
        this.setState({active: number})
    }

    display = (number) => {
        if (this.state.preview) {
            if (number === this.state.previewIndex) {
                this.setState({
                    preview: false,
                    previewIndex: -1
                })
            }
            else {
                this.deactivate(number);
                this.setState({
                    preview: true,
                    previewIndex: number
                })
            }
        }
        else {
            this.deactivate(number);
            this.setState({
                preview: true,
                previewIndex: number
            })
        }
    }

    close = () => {
        this.setState({ preview: false })
    }

    sortColumn(i) {
        let type = (this.props.type === '/hcp' ? hcp : hco)
        let temp = []
        for (let i = 0; i < type.resultTitles.length; i++) {
            if (type.resultTitles[i].roles.includes('ALL') || type.resultTitles[i].roles.includes(this.props.user.role)) {
                if (["UNIVERSE_FLAG_TM"].includes(type.resultTitles[i].column)) {
                    temp.push(["ADMIN"].includes(this.props.user.role) ? 'UNIVERSE_FLAG' : `UNIVERSE_FLAG_TM${this.props.user.team}`)
                }
                else {
                    temp.push(type.resultTitles[i].column)
                }
            }
        }

        let ret = {data: []}
        let regex = new RegExp(`^${this.props.user.terr}`, 'g');
        let ascending
        if (i !== this.state.sortBy) {
            ascending = false
        }
        else {
            ascending = !this.state.ascending
        }
        if (ascending) {
            ret.data = this.props.data.sort((a, b) => {
                if (i === 1) {
                    if (a[temp[i]] === null) {
                        return -1
                    }
                    if (b[temp[i]] === null) {
                        return 1
                    }
                    if (`${a[temp[i]]} ${a['LAST_NAME']}` < `${b[temp[i]]} ${b['LAST_NAME']}`) {
                        return 1;
                    }
                    if (`${a[temp[i]]} ${a['LAST_NAME']}` > `${b[temp[i]]} ${b['LAST_NAME']}`) {
                        return -1;
                    }
                }
                else if (["UNIVERSE_FLAG", `UNIVERSE_FLAG_TM${this.props.user.team}`, ].includes(temp[i])) {
                    if (a[temp[i]] === null) {
                        return -1
                    }
                    if (b[temp[i]] === null) {
                        return 1
                    }
                    if (!["ADMIN"].includes(this.props.user.role)) {
                        if (a.TERR_ID !== null && a.TERR_ID !== undefined && b.TERR_ID !== null && b.TERR_ID !== undefined) {
                            if (a.TERR_ID.match(regex) && b.TERR_ID.match(regex)) {
                                return 0
                            }
                            else {
                                if (a.TERR_ID.match(regex)) {
                                    return 1
                                }
                                if (b.TERR_ID.match(regex)) {
                                    return -1
                                }
                            }
                        }
                    }
                    if (a[temp[i]] < b[temp[i]]) {
                        return 1;
                    }
                    if (a[temp[i]] > b[temp[i]]) {
                        return -1;
                    }
                }
                else {
                    if (a[temp[i]] === null) {
                        return -1
                    }
                    if (b[temp[i]] === null) {
                        return 1
                    }
                    if (a[temp[i]] < b[temp[i]]) {
                        return 1;
                    }
                    if (a[temp[i]] > b[temp[i]]) {
                        return -1;
                    }
                }
                return 0;
            })
        }
        else {
            ret.data = this.props.data.sort((a, b) => {
                if (i === 1) {
                    if (a[temp[i]] === null) {
                        return 1
                    }
                    if (b[temp[i]] === null) {
                        return -1
                    }
                    if (`${a[temp[i]]} ${a['LAST_NAME']}` < `${b[temp[i]]} ${b['LAST_NAME']}`) {
                        return -1;
                    }
                    if (`${a[temp[i]]} ${a['LAST_NAME']}` > `${b[temp[i]]} ${b['LAST_NAME']}`) {
                        return 1;
                    }
                }
                else if (["UNIVERSE_FLAG", `UNIVERSE_FLAG_TM${this.props.user.team}`, ].includes(temp[i])) {
                    if (a[temp[i]] === null) {
                        return 1
                    }
                    if (b[temp[i]] === null) {
                        return -1
                    }
                    if (!["ADMIN"].includes(this.props.user.role)) {
                        if (a.TERR_ID !== null && a.TERR_ID !== undefined && b.TERR_ID !== null && b.TERR_ID !== undefined) {
                            if (a.TERR_ID.match(regex) && b.TERR_ID.match(regex)) {
                                return 0
                            }
                            else {
                                if (a.TERR_ID.match(regex)) {
                                    return -1
                                }
                                if (b.TERR_ID.match(regex)) {
                                    return 1
                                }
                            }
                        }
                    }
                    if (a[temp[i]] < b[temp[i]]) {
                        return -1;
                    }
                    if (a[temp[i]] > b[temp[i]]) {
                        return 1;
                    }
                }
                else {
                    if (a[temp[i]] === null) {
                        return 1
                    }
                    if (b[temp[i]] === null) {
                        return -1
                    }
                    if (a[temp[i]] < b[temp[i]]) {
                        return -1;
                    }
                    if (a[temp[i]] > b[temp[i]]) {
                        return 1;
                    }
                }
                return 0;
            })
        }
        if (i !== this.state.sortBy) {
            this.setState({
                data: ret.data,
                sortBy: i,
                ascending: false
            })
        }
        else {
            this.setState({
                data: ret.data,
                ascending: !this.state.ascending
            })
        }
    }

    submit(json) {
        var userRole = this.props.user.role;

        json.REQUESTOR_ID = this.props.user.email
        json.REQUESTOR_NAME = this.props.user.userName
        json.REQUESTOR_TEAM = this.props.user.team
        json.REQUESTOR_TERR = this.props.user.terr

        if (userRole == 'PRT' || userRole == 'COMMERCIAL DERM' || this.props.user.isDermAdmin == 'true') { json.REQUESTOR_TEAM = '31' }
        Axios('/api/global/change-request', json)
    }

    closeAdd() {
        this.setState({add: ""})
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data !== this.props.data) {
            this.setState({
                data: this.props.data,
                totalResults: this.props.data.length,
                currentPage: 0,
                sortBy: -1
            });
        }
    }

    buildResults() {
        let tempStart = Math.round(this.state.currentPage * this.state.resultsPerPage);
        let arr = []
        for (let i = tempStart; (i < this.state.data.length) && (i < parseInt(tempStart) + parseInt(this.state.resultsPerPage)); i++) {
            arr.push(<Result
                user={this.props.user}
                type={this.props.type}
                addTo={this.props.addTo}
                role={this.props.role}
                showDetails={this.props.showDetails.bind(this)}
                data={this.state.data[i]}
                key={i}
                number={i}
                active={this.state.active}
                deactivate={this.deactivate.bind(this, i)}
                display={this.display.bind(this, i)}
                preview={this.state.preview}
                hashes={this.props.hashes}
                updateNotification={this.updateNotification.bind(this)}
                updateNotificationStatus={this.updateNotificationStatus.bind(this)}
                updateError={this.updateError.bind(this)}
                errorByID={this.state.errorByID}
                notificationType={this.state.notificationType}
        
                />
            )
        }
        return arr
    }
    
    render() {
        let type = (this.props.type === '/hcp' ? hcp : hco)
        let titles = []
        for (let i = 0; i < type.resultTitles.length; i++) {
            if (type.resultTitles[i].roles.includes('ALL') || type.resultTitles[i].roles.includes(this.props.user.role) || type.resultTitles[i].teams.includes(this.props.user.team)) {
                if (type.resultTitles[i].title == 'CRM' && this.props.user.role == 'PHARMFORCE') {
                } else {

                    titles.push(type.resultTitles[i].title)
                }
            }
        }
        titles.push('ACTIONS');
        let notification = this.state.notificationStatus ? (
            <NotificationBox 
                message={this.state.notificationMessage} 
                callback={this.updateNotificationStatus.bind(this)} 
                errorStatus={this.state.errorStatus}/>
        ) : ""

        let results = this.buildResults();

        return (
            <div className={styles.SearchResults}>
                {this.state.add ? this.state.add : ""}
                <div className={this.state.preview ? styles.Display : styles.SearchTitles}>
                    <ResultTitles ascending={this.state.ascending} long={[1]} nonsortable={[0,2]} Sort={this.sortColumn.bind(this)} sortBy={this.state.sortBy} Titles={titles}/>
                    <div className={styles.Wrapper}>
                        <div className={styles.WrapperLine}>
                            {results}
                        </div>
                    </div>
                    <Pagination
                        currentPage={this.state.currentPage}
                        resultsPerPage={this.state.resultsPerPage}
                        totalResults={this.state.totalResults}
                        button={true}
                        buttonName={this.props.type === '/hcp' ? 'ADD PROFESSIONAL' : 'ADD ORGANIZATION'}
                        buttonIcon={this.props.type === '/hcp' ? <i className={["fas", "fa-user-plus", styles.Icon].join(' ')}></i> : <i className={["fas", "fa-building", styles.Icon].join(' ')}/>}
                        buttonClick={() => {
                            if (this.props.type === '/hcp') {
                                this.setState({add: <AddHcp submit={this.submit.bind(this)} close={this.closeAdd.bind(this)} user={this.props.user}/>})
                            } else {
                                this.setState({add: <AddHco submit={this.submit.bind(this)} close={this.closeAdd.bind(this)} user={this.props.user}/>})
                            }
                        }}
                        setProperties={(currentPage, resultsPerPage) => {
                            this.setState({
                                currentPage: currentPage,
                                resultsPerPage: resultsPerPage
                            })
                        }}/>
                </div>
                {this.state.preview ? <Preview user={this.props.user} type={this.props.type} index={this.state.active} className={styles.Preview} showDetails={this.props.showDetails.bind(this)} close={this.close.bind(this)} data={this.state.data[this.state.active]}/> : <div></div>}
                {notification}
            </div>
        );
    }
}

export default SearchResults;
