import React, {Component} from 'react';
import styles from '../css/Details_Edit.module.css';
import DetailsEditPersonal from './Details_Edit_Personal';
import DetailsEditSpecialty from './Details_Edit_Specialty';
import DetailsEditAddress from './Details_Edit_Address';
import DetailsEditContact from './Details_Edit_Contact';
import DetailsEditAdditional from './Details_Edit_Additional';
import DetailsEditHem from './Details_Edit_Hem';
import ChangeRequest from '../../ChangeRequests/ChangeRequest';
import DetailsEditAffiliations from './Details_Edit_Affiliations';
import DetailsEditMsl from './Details_Edit_Msl';
import Popup from '../../../globalcomponents/Popup';
import AddHCPSuccess from './AddHcpSuccess'

class Details_Edit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data
        };
    }

    render() {
        let edit = ""
        if (this.props.index === 1) {
            edit = <DetailsEditPersonal data={this.state.data} user={this.props.user} submit={this.props.submit} close={this.props.close} />
        }
        else if (this.props.index === 2) {
            edit = <DetailsEditSpecialty data={this.state.data} user={this.props.user} submit={this.props.submit} close={this.props.close} />
        }
        else if (this.props.index === 4) {
            edit = <DetailsEditAddress data={this.state.data} updateChanges={this.props.updateChanges} user={this.props.user} addresses={this.props.addresses} submit={this.props.submit} close={this.props.close} />
        }
        else if (this.props.index === 5) {
            edit = <DetailsEditContact data={this.state.data} user={this.props.user} submit={this.props.submit} close={this.props.close} />
        }
        else if (this.props.index === 6) {
            edit = <DetailsEditAdditional data={this.state.data} user={this.props.user} submit={this.props.submit} close={this.props.close} />
        }
        else if (this.props.index === 7) {
            edit = <DetailsEditHem data={this.state.data} user={this.props.user} submit={this.props.submit} close={this.props.close} />
        }
        else if (this.props.index === 8) {
            if (this.props.id) {
                edit = <ChangeRequest callback={this.props.setChange} next={this.props.change[this.props.id]} user={this.props.user} data={this.props.data} close={this.props.close} details={true} id={this.props.id}/>
            }
            else {
                edit = <ChangeRequest callback={this.props.setChange} next={this.props.change} user={this.props.user} data={this.props.data} close={this.props.close} details={true}/>
            }

        }
        else if (this.props.index === 9) {
            edit = <DetailsEditAffiliations affiliations={this.props.affiliations} data={this.state.data} submit={this.props.submit} close={this.props.close} user={this.props.user}/>
        }
        else if (this.props.index === 10) {
            edit = <DetailsEditMsl affiliations={this.props.affiliations} data={this.state.data} submit={this.props.submit} close={this.props.close} user={this.props.user} msl={this.props.msl}/>
        }
        if (parseInt(this.props.index) === parseInt(11)) {
            edit = <AddHCPSuccess close={this.props.close} />
        }
        return (
            <div className={styles.editScreen}>
                <Popup component={edit} />
            </div>
        )
    }

}

export default Details_Edit;
