import React, { Component } from 'react'
import Styles from './WorkflowStatus.module.css'


class TransactionHistoryItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
          
        };
    }
   

    render() {

        
        return (   

            <div className={this.props.odd ? [Styles.historyoddrow, Styles.odd].join(" ") : [Styles.historyrow]}>

                <div className={Styles.historyitem}><b>{this.props.type}</b> ({this.props.user}) <i>{new Date(this.props.date).toLocaleDateString('en-US')}  <b>{this.props.note}</b></i>  </div>
              
             </div>

        )
    }

}
export default TransactionHistoryItem;







