import React, {useState, useEffect} from 'react'
import styles from './css/ResolveDisputes.module.css'
import TopBar from '../../../components/TopBar'
import ResultsPannel from '../../../components/ResultsPannel'
import Result from './components/Result'
import Axios from '../../../components/Axios/Axios'

function ResolveDisputes(props) {

    const [data, setData] = useState([])
    const [loaded, setLoaded] = useState(false)
    const keys = ['HEALTH CARE PROFESSIONAL', 'CURRENT ADDRESS', 'CURRENT TERRITORY', 'BEST ADDRESS', 'NEW TERRITORY', 'QUICK RESOLVE', 'ACTIONS']

    const getData = () => {
        let sendObject = {
            terr: props.user.role === "ADMIN" ? props.exercise.TEAM : props.user.terr,
            id: props.exercise.ID,
            byTerr: true
        }
        Axios('/api/best-address-exercise/get-all-conflicts-for-quick-disputes', sendObject, (data) => {
            setData(data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        getData()
    }, [])
    
    return (
        <div className={styles.admin}>
            <TopBar hasBackButton={true} url={props.path} name={props.exercise.NAME}/>
            <div className={styles.pannel}>
                <div className={styles.wrapper}>
                    <ResultsPannel {...props} headerTitle={"All Dispute Records"} getData={getData} data={data} loaded={loaded} keys={keys} result={Result} />
                </div>
            </div>
        </div>
    )
}

export default ResolveDisputes
