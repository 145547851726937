import React, { Component } from 'react';
import styles from '../css/SubmittedRecord.module.css';
import t from '../../../assets/t-arrow_v2.png'
import arrow from '../../../assets/arrow_v2.png'
import button from '../../../assets/light-button_v2.png'
import clickedButton from '../../../assets/Dark_Button_line_v2.png'
const MAX_LENGTH = 50

class SubmittedRecord extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showChild: false
        };
        // console.log(props);
    }

    mapStatus(status) {
        let ret = {}
        ret.status = 'Open'
        switch (status) {
            case 'Automatically Approved':
                ret.status = 'Approved'
                ret.comments = false
                break;
            case 'Approved':
                ret.status = 'Approved'
                ret.comments = false
                break;
            case 'NEEDS MR':
                ret.status = 'Open'
                ret.comments = false
                break;
            case 'REQUEST_RCVD':
                ret.status = 'Open'
                ret.comments = true
                break;
            case 'In Progress':
                ret.status = 'In Progress'
                ret.comments = false
                break;
            case 'InProcess':
            case 'IN PROCESS':
                ret.status = 'In Process'
                ret.comments = false
                break;
            case 'DECLINED':
                ret.status = 'Cancelled'
                ret.comments = true
                break;
            case 'Rubber Stamped':
                ret.status = 'Approved'
                ret.comments = true
                break;
            case 'COMPLETED':
                ret.status = 'Approved'
                ret.comments = true
                break;
           
           
        }
        return ret
    }

    getActionID(action_id) {
        let ret
        if (action_id == null)
            ret = (<i>Pending</i>);
        else
            ret = (<div id={styles.Link} onClick={() => {
                this.props.setEditID(this.props.data)
            }}>
                {action_id}
            </div>);
        return ret;
    }

    getName(tempdata) {

        var tempname = "";

        if (tempdata.PREV_FIRST_NAME) {
            tempname = (`${tempdata.PREV_FIRST_NAME} ${tempdata.PREV_LAST_NAME}`).substring(0, MAX_LENGTH)
        }
        else if (tempdata.FIRST_NAME) {
            tempname = (`${tempdata.FIRST_NAME} ${tempdata.LAST_NAME ? tempdata.LAST_NAME : ""}`).substring(0, MAX_LENGTH)
        }
        else {
            if (tempdata.PREV_ORG_NAME) {
                tempname = tempdata.PREV_ORG_NAME.substring(0, MAX_LENGTH)
            }
            else if (tempdata.ORG_NAME) {
                tempname = tempdata.ORG_NAME.substring(0, MAX_LENGTH)
            }
            else {
                tempname = "—"
            }
        }
        return tempname;
    }
    render() {
        let children = []
        if (this.props.children) {
            for (let i = 0; i < this.props.children.length; i++) {
                children.push(<div className={[styles.result, styles.child].join(' ')}>
                    <div className={styles.content}>
                        <div className={styles.icon}>{this.props.children.length > i + 1 ? <img src={t} alt={"Couldn't Load"} /> : <img src={arrow} alt={"Couldn't Load"} />}</div>
                        <div id={styles.Link} onClick={() => {
                            this.props.setEditID(this.props.children[i])
                        }}>
                            {this.props.children[i].ACTION_ID}
                        </div>
                    </div>
                    <div className={styles.content}>{this.props.children[i].RESOLVE_TYPE ? this.mapStatus(this.props.children[i].RESOLVE_TYPE).status : "—"}</div>
                    <div className={styles.content}>{this.props.children[i].REQUEST_DATE ? this.props.children[i].REQUEST_DATE.replace(/[Tt].*/, "") : "—"}</div>
                    <div className={styles.content}>{this.props.children[i].PROF_ID ? this.props.children[i].PREV_FIRST_NAME ? (`${this.props.children[i].PREV_FIRST_NAME} ${this.props.children[i].PREV_LAST_NAME}`).substring(0, MAX_LENGTH) : "—" : this.props.children[i].PREV_ORG_NAME ? this.props.children[i].PREV_ORG_NAME.substring(0, MAX_LENGTH) : "—"}</div>
                    <div className={styles.content}>{this.props.children[i].ORIGINAL_REQUST_TYPE ? this.props.children[i].ORIGINAL_REQUST_TYPE : "—"}</div>
                    <div className={styles.content}>{this.props.children[i].RESOLVE_TYPE ? this.mapStatus(this.props.children[i].RESOLVE_TYPE).comments : "—"}</div>
                    <div className={styles.content}>{this.props.children[i].RESOLVE_DATE ? this.props.children[i].RESOLVE_DATE.replace(/[Tt].*/, "") : "—"}</div>
                </div>)
            }
        }


        var name = this.getName(this.props.data);

        var requestType = this.props.data.REQUEST_TYPE ? this.props.data.REQUEST_TYPE : "—";

        return (
            <div>
                <div className={this.props.odd ? [styles.result, styles.odd].join(' ') : styles.result}>

                    <div className={styles.content}>
                        <div className={styles.icon} onClick={() => {
                            this.setState({ showChild: !this.state.showChild })
                        }}>{this.props.children !== undefined ? this.state.showChild ? <img src={clickedButton} alt={"Couldn't Load"} /> : <img src={button} alt={"Couldn't Load"} /> : ""}</div>

                        {this.getActionID(this.props.data.ACTION_ID) }
                        
                        <div className={styles.icon}></div>
                    </div>
                    <div className={styles.content}>{this.props.data.RESOLVE_TYPE ? this.mapStatus(this.props.data.RESOLVE_TYPE).status : "—"}</div>
                    <div className={styles.content}>{this.props.data.REQUEST_DATE ? this.props.data.REQUEST_DATE.replace(/[Tt].*/, "") : "—"}</div>
                    <div className={styles.content}>{name}</div>
                    <div className={styles.content}>{requestType}</div>
                    <div className={styles.content}>{this.props.data.RESOLVE_TYPE ? this.props.data.RESOLVE_TYPE : "—"}</div>
                    <div className={styles.content}>{this.props.data.RESOLVE_DATE ? this.props.data.RESOLVE_DATE.replace(/[Tt].*/, "") : "—"}</div>
                </div>
                {this.state.showChild ? children : ""}
            </div>
        );
    }
}

export default SubmittedRecord;
