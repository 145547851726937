import React, {useState} from 'react'
import styles from './../css/ChangeMoveTypeDrop.module.css'
import Edit from './EditMove'
import Dispute from '../../components/Dispute'
import Axios from '../../../../components/Axios/Axios'

function ChangeMoveTypeDrop(props) {

    const [edit, setEdit] = useState(false)
    const [dispute, setDispute] = useState(false)
    const [targetType, setTargetType] = useState(props.data.MOVE_TYPE || "")
    const [finalMoveType, setFinalMoveType] = useState(props.data.FINAL_MOVE_TYPE || undefined)
    const [reason, setReason] = useState(props.data.REASON || "")
    const [notes, setNotes] = useState(props.data.NOTES || "")
    const [tempTarget, setTempTarget] = useState()
    
    const submit = (target, reasons) => {
        let temp = {
            ...reasons,
            moveType: target,
            ID: props.data.TARGETING_EXERCISE_ID,
            terr: props.terr,
            profID: props.data.PROF_ID,
            user: props.user,
            statusID: props.status.STATUS_ID,
            revert: target === "" ? true : false
        }
        if (props.data.MOVE_TYPE || targetType || props.data.MOVE_TYPE === "") {
            Axios('/api/best-address-exercise/update-target-type', temp, (data) => {
                if (reasons) {
                    setReason(reasons.reason)
                    setNotes(reasons.notes)
                }
                setTargetType(target)
                setEdit(null)
                props.getTotals()
            })
        }
        else {
            Axios('/api/best-address-exercise/add-target-type', temp, (data) => {
                if (reasons) {
                    setReason(reasons.reason)
                    setNotes(reasons.notes)
                }
                setTargetType(target)
                setEdit(null)
                props.getTotals()
            })
        }
    }

    const resolve = (finalMoveType) => {
        let temp = {
            ID: props.id,
            profID: props.data.PROF_ID,
            finalMoveType: finalMoveType === 0 ? "N" : "Y",
            finalReasons: null
        }
        Axios('/api/best-address-exercise/resolve-conflict', temp, (data) => {
            close()
            setFinalMoveType(finalMoveType === 0 ? "N" : "Y")
            props.getTotals()
            props.setCustom({
                ...props.custom,
                recordConflicts: false
            })
        })
    }

    const close = () => {
        setTempTarget(null)
        setEdit(false)
        setDispute(false)
    }

    let popup = ""
    let placeholder = ""
    let editIcon = ""
    if (edit) {
        popup = <Edit {...props} targetType={tempTarget || targetType} reason={reason} notes={notes} success={true} successMessage={"Test"} submit={submit} close={close}/>
    }
    if (dispute) {
        popup = <Dispute {...props} finalMoveType={finalMoveType} targetType={tempTarget || targetType} reason={reason} notes={notes} success={true} successMessage={"Test"} submit={resolve} close={close}/>
    }
    placeholder = (
        <>
            <div className={styles.placeholder} style={{width: '60px'}}></div>
            <div className={styles.placeholder}></div>
        </>
    )
    
    if (props.data.recordConflicts || props.data.FINAL_MOVE_TYPE) {
        editIcon = (
            <>
                <div className={styles.placeholder}><div className={styles.line}></div></div>
                <div className={styles.edit}>
                    <div className={styles.link} onClick={() => {
                        setDispute(true)
                    }}>VIEW DISPUTE DETAILS</div>
                </div>

            </>
        )
    }
    else if (targetType === "N" || props.custom.recordConflicts) {
        // placeholder = (
        //     <>
        //         <div className={styles.placeholder} style={{width: '60px'}}></div>
        //         <div className={styles.placeholder}></div>
        //     </>
        // )
        editIcon = (
            <>
                <div className={styles.placeholder}><div className={styles.line}></div></div>
                <div className={styles.edit}>
                    <i className={"far fa-edit"} onClick={() => {
                        setEdit(true)
                    }}></i>
                </div>

            </>
        )
    }
    else {
        editIcon = (
            <>
                <div className={styles.placeholder}></div>
                <div className={styles.placeholder} style={{width: '60px'}}></div>
            </>
        )
    }
    return (
        <div>
            {popup}
            <div className={styles.changeTargetDrop}>
                {placeholder}
                <select className={[styles.dropdown, styles[targetType]].join(" ")} value={finalMoveType || targetType} onChange={(event) => {
                        if (event.target.value === "N") {
                            setEdit(true)
                            setTempTarget(event.target.value)
                        }
                        else if (event.target.value === targetType) {
                            setTempTarget(event.target.value)
                        }
                        else {
                            setTargetType(event.target.value)
                            submit(event.target.value)
                        }
                    }} disabled={props.lock || finalMoveType !== undefined}>
                    <option value={"Y"}>Yes</option>
                    <option value={"N"}>No</option>
                </select>
                {editIcon}
            </div>
        </div>
    )
}

export default ChangeMoveTypeDrop
