import React, {Component} from 'react';
import styles from '../css/AddHCO_P2.module.css';
import AddressLookUp from './../../../globalcomponents/AddressLookUp'

class AddHCO_P2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ADDRESS1: '',
            ADDRESS2: '',
            CITY: '',
            STATE: '',
            ZIPCODE: '',
            ISVALID: true
        };
    }

    componentDidMount() {
        let temp = {}
        for (let i = 0; i < Object.keys(this.props.info).length; i++) {
            if (Object.keys(this.props.info)[i]) {
                temp[Object.keys(this.props.info)[i]] = this.props.info[Object.keys(this.props.info)[i]]
            }
        }
        this.setState(temp)
    }

    updateAddress(address) {
        this.setState({
            ADDRESS1: address.streetOne,
            ADDRESS2: address.streetTwo,
            CITY: address.city,
            STATE: address.state,
            ZIPCODE: address.zip
        });
    }

    render() {
        return (
            <div>
                <div className={styles.wrapper}>
                    <div className={styles.title}>
                        <div></div>
                        <div className={styles.TitleText}>Add Organization</div>
                        <div className={styles.close} onClick={() => {
                            this.props.close()
                        }}><i className="fas fa-times"></i></div>
                    </div>

                    <div className={styles.body}>
                    <AddressLookUp updateAddress={this.updateAddress.bind(this)} error={this.state.ISVALID} info={this.props.info}/>
                        <div className={styles.buttonRow}>
                            <div id={styles.cancel} onClick={() => {
                                this.props.nextPage(0);
                            }}>BACK</div>
                            <div className={this.state.Wiggle && this.state.Wiggle !== undefined ? styles.Wiggle : ""} id={styles.submit} onClick={() => {
                                if (this.state.ADDRESS1 !== '' && this.state.ADDRESS1.length <= 100
                                    // && (this.state.ADDRESS2 == '' || this.state.ADDRESS2.length <= 100)
                                    && this.state.CITY !== '' && this.state.CITY.length <= 100
                                    && this.state.STATE !== '' && this.state.ZIPCODE.length === 5) {
                                    if (this.state.ADDRESS2 && this.state.ADDRESS2.length <= 100) {
                                        let temp = this.state
                                        delete temp.ISVALID
                                        this.props.nextPage(2, temp);
                                    }
                                    let temp = this.state
                                    delete temp.ISVALID
                                    this.props.nextPage(2, temp);
                                }
                                else {
                                    this.setState({
                                        Wiggle: true,
                                        ISVALID: false
                                    })
                                    setTimeout(() => {
                                        this.setState({Wiggle: false})
                                    }, 500);
                                }
                            }}>NEXT</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default AddHCO_P2;
