import React, {useState, useEffect} from 'react'
import styles from '../css/OrderColumns.module.css'
import ColumnRecord from './ColumnRecord'

function OrderColumns(props) {
    const [order, setOrder] = useState(props.order || null)
    const [columns, setColumns] = useState()
    const [dragged, setDragged] = useState(-1)
    const [dropped, setDropped] = useState(-1)

    useEffect(() => {
        if (dragged !== -1 && dropped !== -1) {
            let newOrder = order
            let temp = newOrder[dropped]
            newOrder[dropped] = newOrder[dragged]
            newOrder[dragged] = temp
            setOrder(newOrder)
            setDragged(dropped)
        }
    }, [dragged, dropped])

    useEffect(() => {
        let keys = []
        for (let i = 0; i < Object.keys(props.defaultColumns).length; i++) {
            if (Object.values(props.defaultColumns)[i].show) {
                keys.push(Object.keys(props.defaultColumns)[i])
            }
        }
        if (props.customColumns) {
            for (let i = 0; i < Object.keys(props.customColumns).length; i++) {
                if (Object.values(props.customColumns)[i].show) {
                    keys.push(Object.keys(props.customColumns)[i])
                }
            }
        }
        let cols = {
            ...props.defaultColumns,
            ...props.customColumns
        }

        for (let i = 0; i < keys.length; i++) {
            if (i === 0) {
                cols[keys[i]].flex = (100 / (keys.length + 1)) * 2
            }
            else {
                cols[keys[i]].flex = 100 / (keys.length + 1)
            }
        }

        setOrder(keys)
        setColumns(cols)
    }, [])

    let columnsList = []
    if (columns) {
        for (let i = 0; i < Object.keys(order).length; i++) {
            let temp = columns[order[i]]
            columnsList.push(<ColumnRecord key={temp.col} order={i} setDragged={setDragged} setDropped={setDropped} data={temp}/>)
        }
    }


    return (
        <div className={styles.editScreen}>
            <div className={styles.title}>
                <div></div>
                <div className={styles.titleText}>Add Exercise - Customize Columns - Order Columns</div>
                <div className={styles.close} onClick={() => {
                    props.close()
                }}><i className="fas fa-times"></i></div>
            </div>
            <form>
                <div className={styles.body}>
                    <div className={styles.titleRow}>
                        <div className={styles.side}></div>
                        <div className={styles.directions}>Below is the list of columns that will be in the exercise. Drag and drop the columns to place them in the order that you wish to see them on the page.</div>
                        <div className={styles.side}></div>
                    </div>
                    <div className={styles.content}>
                        {columnsList}
                    </div>
                    <div className={styles.buttonRow}>
                        <div id={styles.cancel}
                            onClick={() => {
                                props.setOrder(order)
                                props.setColumns(columns)
                                props.setView(props.view - 1)
                            }
                        }>BACK</div>
                        <div type="submit" id={styles.submit} onClick={() => {
                                props.setOrder(order)
                                props.setColumns(columns)
                                props.setView(props.view + 1)
                            }
                        }>CONFIRM</div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default OrderColumns
