import React, { useState, useEffect } from 'react'
import styles from './../css/Status.module.css'
import StatusRecord from './StatusRecord'
import EditStatus from './EditStatus'
import Spinner from '../../../components/Spinner'
import Axios from '../../../components/Axios'

function Status(props) {

    const [loaded, setLoaded] = useState(false)
    const [editStatus, setEditStatus] = useState(false)

    useEffect(() => {

        Axios(props.endpoints.status, { team: props.user.role === "ADMIN" || (props.user.role == 'DBD' && props.user.team == '31') ? props.adminExercise.TEAM : props.exercise.TEAM }, (data) => {
            props.setStatusData(data)
            setLoaded(true)
        })
    }, [])

    let results = []
    for (let i = 0; i < Object.keys(props.statusData).length; i++) {
        if (props.statusData[i].STATUS.trim() !== "-" && props.statusData[i].STATUS.trim() !== "") {
            results.push(<StatusRecord key={props.statusData[i].STATUS_ID} data={props.statusData[i]} odd={true} />)
        }
    }

    return (<div className={styles.statusWrapper}>
        {editStatus ? <EditStatus endpoints={props.endpoints} user={props.user} exercise={props.exercise} adminExercise={props.adminExercise} status={props.statusData} setStatus={props.setStatusData} success close={() => { setEditStatus(false) }} /> : ""}
        <div className={styles.sectionTitleBar}>
            <div className={styles.sectionTitle}>Guide</div>
            {
                props.user.role === "ADMIN" 
                    ? (<div className={"icon"} onClick={() => {
                        setEditStatus(true)
                    }}>
                        <i className="far fa-edit"></i>
                    </div>)
                    : ""
            }
        </div>
        <div className={styles.results}>
            <div className={styles.contentTitles}>
                <div className={[styles.col, styles.short].join(' ')}>STATUS</div>
                <div className={[styles.col, styles.long].join(' ')}>DESCRIPTION</div>
            </div>
            {
                loaded
                    ? results
                    : <Spinner />
            }
        </div>
    </div>)
}

export default Status
