import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import styles from '../css/Preview.module.css';
import SpecialtyLookUp from '../../../globalcomponents/SpecialtyLookUp';
import DegreeLookUp from '../../../globalcomponents/DegreeLookUp';
import CountryLookUp from '../../../lookup/countrylookup';
import Axios from './../../../components/Axios'
import firstCapital from '../../../helper/firstCapital';

class Preview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false
        };
    }


    componentDidMount() {
    }

    getPreviewData(SRC_ID) {
        let temp = {
            SRC_ID: SRC_ID
        }
    }

    componentDidUpdate(prevProps, prevState) {

    }

   
    window() {
        let ret = ''

        ret = (<div className={styles.window}>
            <div className={styles.Name}>{`${this.props.data.FIRST_NAME} ${this.props.data.LAST_NAME}, ${this.props.data.DEGREE}`}</div>
            <div className={styles.Status}>
                {
                    <i className="fas fa-check-circle" id={styles.StatusActive}></i>
                }
                <div className={styles.Content}>ACTIVE</div>
            </div>
            <div className={styles.scroll}>
                <div className={styles.SectionColumn}>
                    <div className={[styles.ContentWrap, styles.Address].join(' ')}>
                        <div className={styles.ContentTitle}>PRIMARY ADDRESS</div>
                        <div className={styles.Content}>{`${firstCapital(this.props.data.ADDRESS1)} ${firstCapital(this.props.data.ADDRESS2)}`}</div>
                        <div className={styles.Content}>{`${firstCapital(this.props.data.CITY)}, ${CountryLookUp[this.props.data.COUNTRY_CODE]} ${this.props.data.POSTAL_CODE}`}</div>
                    </div>
                </div>
                <div className={styles.Bar}></div>
                <div className={styles.SectionColumn}>
                    <div className={styles.ContentWrap}>
                        <div className={styles.ContentTitle}>DEGREE</div>
                        <div className={styles.Content}>{
                            this.props.data.DEGREE
                                ? firstCapital(DegreeLookUp[this.props.data.DEGREE])
                                : "None"
                        }</div>
                    </div>
                    <div className={styles.ContentWrap}>
                        <div className={styles.ContentTitle}>SPECIALTY</div>
                        <div className={styles.Content}>{
                            this.props.data.SPECIALTY
                                ? firstCapital(SpecialtyLookUp[this.props.data.SPECIALTY])
                                : "None"
                        }</div>
                    </div>
                </div>
                <div className={styles.Bar}></div>
                <div className={styles.SectionRow}>
                    <div className={styles.ContentWrap}>
                        <div className={styles.ContentTitle}>PROF ID</div>
                        <div className={styles.Content}>{this.props.data.PROF_ID}</div>
                    </div>

                </div>
                <div className={styles.Bar}></div>


            </div>
        </div>)






        return ret
    }

    render() {
        return (<div className={styles.Preview}>
            <div className={styles.Close}>
                <div className={styles.CloseButton} onClick={() => {
                    this.props.close()
                }}>CLOSE PREVIEW<i className="fas fa-times"></i>
                </div>
            </div>
            {this.window()}




        </div>)
    }
}

export default Preview;
