import React from 'react'
import styles from './../css/ColorBox.module.css'

function ColorBox(props) {
    return (
        <div className={styles.statusBoxColor}>{props.title}</div>
    )
}

export default ColorBox
