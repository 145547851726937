import React, {Component} from 'react';
import styles from '../css/ChangeRequestEdit.module.css';
import EditAddress from './EditAddress';
import EditField from './EditField';

class ChangeRequestEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ADDRESS1: this.props.next.ADDRESS1,
            ADDRESS2: this.props.next.ADDRESS2,
            CITY: this.props.next.CITY,
            STATE: this.props.next.STATE,
            ZIPCODE: this.props.next.ZIPCODE,
            values: Object.values(this.props.next),
            keys: Object.keys(this.props.next)
        };
    }

    setValue(index, data) {
        let temp = this.state.values
        temp[index] = data
        this.setState({
            values: temp
        })
    }

    setAddress(data) {
        this.setState({
            ADDRESS1: data.ADDRESS1,
            ADDRESS2: data.ADDRESS2,
            CITY: data.CITY,
            STATE: data.STATE,
            ZIPCODE: data.ZIPCODE
        })
    }

    render() {

            let details = []
            if (this.props.next.ADDRESS1) {
                details.push(<EditAddress key={1} {...this.state} setAddress={this.setAddress.bind(this)} next={this.props.next}/>);
            }
            else {
                for (let i = 0; i < Object.keys(this.props.next).length; i++) {
                    if (Object.keys(this.props.next)[i] !== 'REQUEST_TYPE' && Object.keys(this.props.next)[i] !== 'PROF_ID' && Object.keys(this.props.next)[i] !== 'ORG_ID') {
                        details.push(<div key={i} className={styles.row}>
                            <div className={[styles.prev, styles.item].join(' ')}>
                                <div className={styles.subtitle}>{`PREVIOUS ${Object.keys(this.props.next)[i]}`}</div>
                                <div className={styles.content}>{this.props.prev[Object.keys(this.props.next)[i]]}</div>
                            </div>
                            <div className={[styles.arrow, styles.item].join(' ')}>
                                <i className="fas fa-long-arrow-alt-right"></i>
                            </div>
                            <div className={[styles.next, styles.item].join(' ')}>
                                <div className={styles.subtitle}>{`UPDATED ${Object.keys(this.props.next)[i]}`}</div>
                                <EditField type={this.props.type} index={i} setValue={this.setValue.bind(this)} value={this.state.values ? this.state.values[i] : ""}/>
                            </div>
                        </div>)
                    }
                }
            }

        return (<div className={this.props.type === "Add Secondary Address" ? styles.expanded : styles.window}>
            <div className={styles.title}>
                <div></div>
                <div className={styles.TitleText}>Edit Change Request</div>
                <div className={styles.close} onClick={() => {
                        this.props.close()
                    }}>
                    <i className="fas fa-times"></i>
                </div>
            </div>

            <div className={styles.body}>
                <div className={styles.contentWrapper}>
                    {details}
                </div>
                <div className={styles.buttonRow}>
                    <div id={styles.cancel} onClick={() => {
                            this.props.changeView(1)
                        }}>CANCEL REQUEST</div>
                    <div className={this.state.Wiggle && this.state.Wiggle !== undefined
                            ? styles.Wiggle
                            : ""} id={styles.submit} onClick={() => {
                            this.props.submitRequest(this.state, this.props.type)
                        }}>UPDATE REQUEST</div>
                </div>
            </div>
        </div>)
    }

}

export default ChangeRequestEdit;
