import React, {Component} from 'react';
import styles from '../css/RegionalPlanResult.module.css';

class AuditTrailResult extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: props.data,
            odd : (props.number % 2 === 0 ? false : true)
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data !== this.props.data) {
            this.setState({data: this.props.data});
        }
    }

    render() {
       
        return (
            <div className={this.state.odd ? [styles.Result, styles.odd].join(' ') : styles.Result}>

                <div className={styles.long}>{this.state.data.USER_NAME}</div>
                <div className={styles.long}>{this.state.data.EVENT_NAME}</div>
                <div className={styles.long}>{this.state.data.EVENT_DT}</div>
                <div className={styles.long}>{this.state.data.EVENT_DESC}</div>
                <div className={[styles.long, styles.links].join(' ')}>
                    <div className={styles.link} onClick={() => {
                           // this.props.edit(this.props.number);
                        }}> * </div>
                    
                </div>
            </div>
        );
    }
}

export default AuditTrailResult;
