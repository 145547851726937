import React from 'react'
import styles from '../css/Success.module.css'
import Spinner from '../../Spinner/Spinner'

function Success(props) {
    if (props.success === null) {
        return (
            <div className={styles.editScreen}>
                <div className={styles.title}>
                    <div></div>
                    <div className={styles.titleText}>Success</div>
                    <div className={styles.close} onClick={() => {
                        props.close()
                    }}><i className="fas fa-times"></i></div>
                </div>
                <form>
                    <div className={styles.body}>
                        <Spinner />
                    </div>
                </form>
            </div>
        )
    }
    else if (props.success) {
        return (
            <div className={styles.editScreen}>
                <div className={styles.title}>
                    <div></div>
                    <div className={styles.titleText}>Success</div>
                    <div className={styles.close} onClick={() => {
                        props.close()
                    }}><i className="fas fa-times"></i></div>
                </div>
                <form>
                    <div className={styles.body}>
                        <div className={styles.titleRow}>
                            <div className={styles.side}></div>
                            <div className={styles.directions}></div>
                            <div className={styles.side}>
                                <div className={styles.link} onClick={() => {
                                        props.setView(props.view + 1)
                                    }}>
                                    {"PREVIEW EXERCISE AND ADJUST COLUMN SIZING>>"}
                                </div>
                            </div>
                        </div>
                        <div className={styles.content}>
                            <div className={styles.success}><i className={["fas", "fa-check-circle", styles.icon].join(" ")}></i></div>
                            <div className={styles.text}>Success! You have successfully created an exercise.</div>
                        </div>
                        <div className={styles.buttonRow}>
                            <div type="submit" id={styles.submit} onClick={() => {
                                    props.close()
                                }
                            }>CLOSE</div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
    else {
        return (
            <div className={styles.editScreen}>
                <div className={styles.title}>
                    <div></div>
                    <div className={styles.titleText}>Success</div>
                    <div className={styles.close} onClick={() => {
                        props.close()
                    }}><i className="fas fa-times"></i></div>
                </div>
                <form>
                    <div className={styles.body}>
                        <div className={styles.titleRow}>
                            <div className={styles.side}></div>
                            <div className={styles.directions}></div>
                            <div className={styles.side}>
                                <div className={styles.link}>
                                    {"PREVIEW EXERCISE AND ADJUST COLUMN SIZING>>"}
                                </div>
                            </div>
                        </div>
                        <div className={styles.content}>
                            <div className={styles.text}>Something went wrong. Close and try again.</div>
                        </div>
                        <div className={styles.buttonRow}>
                            <div type="submit" id={styles.submit} onClick={() => {
                                    props.close()
                                }
                            }>CONFIRM</div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

export default Success
