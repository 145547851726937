import React, { Component } from 'react'
import Styles from './WorkflowStatus.module.css'


class CloseoutItem extends Component {
    constructor(props) {
        super(props);
     
    }
   

    render() {

        
        return (   

            <div className={this.props.odd ? [Styles.historyoddrow, Styles.odd].join(" ") : [Styles.historyrow]}>

                <div className={Styles.closeblocktitle}><b>{this.props.title} </b> &nbsp;&nbsp;&nbsp;&nbsp;{this.props.value}</div>

             </div>

        )
    }

}
export default CloseoutItem;







