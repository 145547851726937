import React, {useState, useEffect} from 'react'
import Popup from '../../Popup/Popup'
import Success from '../../Success/Success'
import Axios from '../../Axios/Axios'
import DemotionReason from './DemotionReason.js'
import AddNewReason from './AddNewReason.js'
import Spinner from '../../Spinner/Spinner'

function EditReasons(props) {
    const [loading, setLoading] = useState(true)
    const [reasons, setReasons] = useState()
    const [reasonIndexes, setReasonIndexes] = useState()
    const [success, setSuccess] = useState()

    useEffect(() => {
        Axios(props.endpoints.getDemotionReasons, {id: props.data.ID, type: props.type}, (data) => {
            let newData = []
            let indexes = []
            data.forEach((temp, i) => {
                newData.push(temp.REASON)
                indexes.push(i)
            });
            setLoading(false)
            setReasons(newData)
            setReasonIndexes(indexes)
        })
    }, [])

    const changeReason = (value, i) => {
        let temp = JSON.parse(JSON.stringify(reasons))
        temp[i] = value
        setReasons(temp)
    }

    const addReason = () => {
        let tempReason = JSON.parse(JSON.stringify(reasons))
        let tempReasonIndexes = JSON.parse(JSON.stringify(reasonIndexes))
        tempReason.push("")
        tempReasonIndexes.push(reasonIndexes.length)
        setReasons(tempReason)
        setReasonIndexes(tempReasonIndexes)
    }

    const deleteReason = (index, alreadyRemoved) => {
        let tempReasonIndexes = []
        for (let i = 0; i < reasonIndexes.length; i++) {
            let valueIndex = reasonIndexes[i]
            if (valueIndex !== index) {
                tempReasonIndexes.push(valueIndex)
            }
        }
        if (alreadyRemoved) {
            tempReasonIndexes.push(index)
        }
        setReasonIndexes(tempReasonIndexes)
    }

    const submit = () => {
        setSuccess(null)
        let newReasons = []
        for (let i = 0; i < reasonIndexes.length; i++) {
            newReasons.push(reasons[reasonIndexes[i]])
        }
        Axios(props.endpoints.setDemotionReasons, {reasons: newReasons, id: props.data.ID, type: props.type}, (data) => {
            setSuccess(true)
        })
    }

    let rows = []
    if (reasons) {
        for (let i = 0; i < reasons.length; i++) {
            rows.push(<DemotionReason key={i} data={reasons[i]} index={i} changeReason={changeReason} deleteReason={deleteReason} />)
        }
        rows.push()
    }

    let body
    if (props.data) {
        body = (
            <div className={"pop-body"}>
                <div className={"pop-titleRow"}>
                    <div className={"pop-directions"} style={{fontWeight: "bold"}}>DOWNGRADE REASONS</div>
                </div>
                <div className={"pop-content"} style={{width: "100%"}}>
                    <div className={"flex-row"}>
                        <div className={"flex-col"}>
                            <div style={{maxHeight: "380px", overflow: "auto"}}>
                                {loading ? <Spinner /> : rows}
                            </div>
                            <AddNewReason addReason={addReason}/>
                        </div>
                    </div>
                </div>
                <div className={"pop-buttonRow"}>
                    <button type="button" id={"pop-cancel"} onClick={() => {
                            props.close()
                        }
                    }>CLOSE</button>
                <button id={"pop-submit"} onClick={submit}>SUBMIT</button>
                </div>
            </div>
        )
    }
    if (success !== undefined) {
        return (
            <Success {...props} success={success} successMessage={"Success! You have successfully updated the Downgrade Reasons."}/>
        )
    }
    else {
        return (
            <Popup {...props} body={body} title={"Edit Reasons for Downgrade"} />
        )
    }
}

export default EditReasons
