import React, {Component} from 'react';
import styles from '../css/SearchBar.module.css';
import Axios from './../../../components/Axios'
import ResultTitles from '../../../globalcomponents/ResultTitles';

class SearchBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            USER_ID: '',
            NAME: '',
            ROLE: '',
            TERR_NAME: '',
            TEAM_ID: '',
            sortBy: -1
        };
    }

    componentDidMount() {
        Axios('/api/admin/get-user-roles', {}, (response) => {
            let roleOptions = [];
            for (let i = 0; i < response.length; i++) {
                roleOptions.push(<option key={i} value={response[i].Name}>{response[i].Name}</option>)
            }
            this.setState({
                roleOptions: roleOptions
            })
        })
        Axios('/api/admin/get-user-teams', {}, (response) => {
            let teamOptions = [];
            for (let i = 0; i < response.length; i++) {
                teamOptions.push(<option key={i} value={response[i].TEAM_ID}>{response[i].TEAM_ID}</option>)
            }
            this.setState({
                teamOptions: teamOptions
            })
        })
    }

    search() {
        let json = this.state;
        json.USER_ID = this.state.USER_ID.trim();
        json.NAME = this.state.NAME.trim();
        json.TERR_NAME = this.state.TERR_NAME.trim();

        Axios('/api/admin/search', json, (data) => {
            this.props.setData(data)
        })
    }

    sortColumn(i) {
        let temp = ['USER_ID', 'FIRST_NAME', 'EMP_TYPE', 'TERR_ID', 'TERR_NAME', 'PARENT_ID', 'TEAM_ID', undefined, undefined]
        let ret = {data: []}
        this.setState({
            sortBy: i
        });
        ret.data = this.props.data.sort((a, b) => {
            // Strings
            if (i === 0 || i === 1 || i === 2 || i === 5) {
                if (a[temp[i]] === undefined || a[temp[i]] === null) {
                    return 1;
                }
                if (b[temp[i]] === undefined || b[temp[i]] === null) {
                    return -1;
                }
                if (`${a[temp[i]].toLowerCase()}` < `${b[temp[i]].toLowerCase()}`) {
                    return -1;
                }
                if (`${a[temp[i]].toLowerCase()}` > `${b[temp[i]].toLowerCase()}`) {
                    return 1;
                }
            }
            // Numbers
            if (i === 3 || i === 4 || i === 6 || i === 7) {
                if (a[temp[i]] === undefined) {
                    return 1;
                }
                if (b[temp[i]] === undefined) {
                    return -1;
                }
                if (parseInt(a[temp[i]]) < parseInt(b[temp[i]])) {
                    return -1;
                }
                if (parseInt(a[temp[i]]) > parseInt(b[temp[i]])) {
                    return 1;
                }
            }
            // Booleans
            else {
                if (a[temp[i]] === undefined) {
                    return 1;
                }
                if (b[temp[i]] === undefined) {
                    return -1;
                }
            }
            return 0;
        })
        this.props.setData(ret.data);
    }

    render() {

        return (<div className={styles.SearchBar}>
            <form>
            <div className={styles.topBar}>
                <div className={styles.titleBar}>User List</div>
                <div className={styles.searchRow}>
                    <div className={styles.set}>
                        <div>USERNAME</div>
                        <input id={styles.USER_ID} type="text" value={this.state.USER_ID === ''
                                ? ""
                                : this.state.USER_ID} onChange={(event) => {
                                if (event.target.value === "") {
                                    this.setState({USER_ID: ''})
                                } else {
                                    this.setState({USER_ID: event.target.value, ActiveButton: true})
                                }
                            }}autoFocus/>
                    </div>
                    <div className={styles.set}>
                        <div>NAME</div>
                        <input id={styles.NAME} type="text" value={this.state.NAME === ''
                                ? ""
                                : this.state.NAME} onChange={(event) => {
                                if (event.target.value === "") {
                                    this.setState({NAME: ''})
                                } else {
                                    this.setState({NAME: event.target.value, ActiveButton: true})
                                }
                            }}/>
                    </div>
                    <div className={styles.set}>
                        <div>ROLE</div>
                        <select id={styles.ROLE} type="text" value={this.state.ROLE === ''
                                ? ""
                                : this.state.ROLE} onChange={(event) => {
                                if (event.target.value === "") {
                                    this.setState({ROLE: ''})
                                } else {
                                    this.setState({ROLE: event.target.value, ActiveButton: true})
                                }
                            }}>
                            <option value=""></option>
                            {this.state.roleOptions}
                        </select>
                    </div>
                    <div className={styles.set}>
                        <div>TERRITORY NAME</div>
                        <input id={styles.TERR_NAME} type="text" value={this.state.TERR_NAME === ''
                                ? ""
                                : this.state.TERR_NAME} onChange={(event) => {
                                if (event.target.value === "") {
                                    this.setState({TERR_NAME: ''})
                                } else {
                                    this.setState({TERR_NAME: event.target.value, ActiveButton: true})
                                }
                            }}/>
                    </div>
                    <div className={styles.set}>
                        <div>TEAM</div>
                        <select id={styles.TEAM_ID} type="text" value={this.state.TEAM_ID === ''
                                ? ""
                                : this.state.TEAM_ID} onChange={(event) => {
                                if (event.target.value === "") {
                                    this.setState({TEAM_ID: ''})
                                } else {
                                    this.setState({TEAM_ID: event.target.value, ActiveButton: true})
                                }
                            }}>
                            <option value=""></option>
                            {this.state.teamOptions}
                        </select>
                    </div>
                    <div className={[styles.set, styles.buttonSet].join(' ')}>
                        <button className={this.state.sWiggle && this.state.sWiggle !== undefined ? styles.Wiggle : ""} id="searchButton" type="submit" onClick={(event) => {
                                event.preventDefault();
                                if (this.state.ActiveButton) {
                                    this.search();
                                }
                                else {
                                    this.setState({
                                        ActiveButton: false,
                                        sWiggle: true
                                    })
                                    setTimeout(() => {
                                        this.setState({sWiggle: false})
                                    }, 500);

                                }
                            }}><i className={["fas", "fa-search", styles.IconButton].join(' ')}></i>  SEARCH</button>
                    </div>
                    <div className={styles.Line}></div>
                    <div className={[styles.set, styles.buttonSet].join(' ')}>
                        <button id={styles.clearButton} type="button" onClick={() => {
                                this.setState({
                                    USER_ID: '',
                                    NAME: '',
                                    ROLE: '',
                                    TERR_NAME: '',
                                    TEAM_ID: '',
                                    ActiveButton: false
                                }, () => {
                                    this.search();
                                } )

                        }
                       }>CLEAR ALL</button>
                    </div>
                </div>
            </div>

            <div className={styles.columnTitles}>
                <ResultTitles className={styles.SearchTitles} flag={true} Sort={this.sortColumn.bind(this)} sortBy={this.state.sortBy} Titles={['USERNAME', 'NAME', 'ROLE', 'TERRITORY ID', 'TERRITORY NAME', 'PARENT TERRITORY ID', 'TEAM', 'STATUS', 'ACTIONS']}/>
            </div>
        </form>
        </div>);
    }
}

export default SearchBar;
