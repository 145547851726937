import React, { Component } from 'react';
import styles from '../css/SearchResults.module.css';
import Result from './Result';
import Preview from './Preview';
import ResultTitles from './ResultTitles.jsx';
import xus from '../../../lookup/xus.json'
import Pagination from './../../../components/Pagination';
import AddExUs from './AddExUs.js'
import Axios from './../../../components/Axios'
import NotificationBox from '../../../globalcomponents/NotificationBox';
import OneKey from './OneKeySearch';
import OneKeyConfirmation from './OneKeyConfirmation';
import AddExUsSuccess from './AddExUsSuccess';

class SearchResults extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active: -1,
            sortBy: -1,
            ascending: false,
            preview: false,
            data: this.props.data,
            resultsPerPage: 20,
            currentPage: 0,
            totalResults: this.props.data.length,
            notificationStatus: false,
            errorStatus: false,
            notificationType: "",
            errorByID: null,
            previewName: "",
            previewAddress: "",
            previewDegree: "",
            previewSpecialty: "",
            previewProfID: "",
            oneKeyCount: 0
        };

        //this.getOneKeyCounts();
    }
    closeAdd() {
        this.setState({ add: "" })
    }

    switchOneKey(data) {


        this.setState({ add: <AddExUs submit={this.submit.bind(this)} searchcriteria={this.props.searchcriteria} close={this.closeAdd.bind(this)} user={this.props.user} /> })

    }
    showSuccess() {
        this.setState({ add: <AddExUsSuccess close={this.closeAdd.bind(this)} /> })
    }
    //display = (number) => {
    //    this.setState({
    //        preview: true,
    //        previewIndex: number
    //    })
    //}
    deactivate = (number) => {
        this.setState({ active: number })
    }
    submit(data) {

        this.setState({ add: <OneKeyConfirmation showSuccess={this.showSuccess.bind(this)} data={data} close={this.closeAdd.bind(this)} user={this.props.user} /> })

    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data !== this.props.data) {
            this.setState({
                data: this.props.data,
                totalResults: this.props.data.length,
                currentPage: 0,
                sortBy: -1
            });
        }
    }
    close = () => {
        this.setState({ preview: false })
    }
    showDetails = (number) => {


        window.location.replace(`/xus/details/${number}`);


    }
    display = (number) => {
        if (this.state.preview) {
            if (number === this.state.previewIndex) {
                this.setState({
                    preview: false,
                    previewIndex: -1
                })
            }
            else {
                this.deactivate(number);
                this.setState({
                    preview: true,
                    previewIndex: number
                })
            }
        }
        else {
            this.deactivate(number);
            this.setState({
                preview: true,
                previewIndex: number
            })
        }
    }
    updateNotification(status, type) {
        let message = `You switched ${type} to ${!status ? 'ON' : 'OFF'} for this professional.`

        this.setState({
            notificationMessage: message,
            notificationStatus: true,
            errorStatus: false,
            notificationType: type
        })
    }
    updateNotificationStatus() {
        this.setState({
            notificationStatus: false,
            notificationType: "",
            errorByID: null
        })
    }
    updateError(status, type, id) {
        console.error('update error')
        let message = `You cannot ${!status ? ' add this professional to ' : 'remove this professional from '}  ${type}.`
        this.setState({
            notificationMessage: message,
            notificationStatus: true,
            errorStatus: true,
            notificationType: type,
            errorByID: id
        })
    }
    //getOneKeyCounts() {

    //    let temp = {
    //        user: this.props.user,
    //        FIRST_NAME: this.props.searchcriteria.FIRST_NAME,
    //        LAST_NAME: this.props.searchcriteria.LAST_NAME, //'Binder',
    //        ADDRESS1: this.props.searchcriteria.ADDRESS1,
    //        CITY: this.props.searchcriteria.CITY,
    //        STATE: this.props.searchcriteria.STATE,
    //        COUNTRY: this.props.searchcriteria.COUNTRY,
    //        POSTAL_CODE: this.props.searchcriteria.ZIP
    //    }

    //    var j = 0;

    //    Axios('/api/search/onekey', temp, (results) => {

    //        this.setState({ oneKeyCount: results.length });

    //    });


    //}
    buildResults() {
        let tempStart = Math.round(this.state.currentPage * this.state.resultsPerPage);
        let arr = []
        for (let i = tempStart; (i < this.state.data.length) && (i < parseInt(tempStart) + parseInt(this.state.resultsPerPage)); i++) {
            arr.push(<Result
                user={this.props.user}
                type={this.props.type}
                addTo={this.props.addTo}
                role={this.props.role}
                showPreview={this.display.bind(this)}
                data={this.state.data[i]}
                key={i}
                number={i}
                active={this.state.active}
                display={this.display.bind(this, i)}
                showDetails={this.showDetails.bind(this)}
                preview={this.state.preview}
                hashes={this.props.hashes}
                updateNotification={this.updateNotification.bind(this)}
                updateError={this.updateError.bind(this)}
                deactivate={this.deactivate.bind(this, i)}
                notificationType={this.state.notificationType}
            />)

        }
        return arr
    }
    render() {
        let type = '/xus'
        let notification = this.state.notificationStatus ? (
            <NotificationBox
                message={this.state.notificationMessage}
                callback={this.updateNotificationStatus.bind(this)}
                errorStatus={this.state.errorStatus} />
        ) : ""

        let results = this.buildResults();


        let titles = []
        for (let i = 0; i < xus.resultTitles.length; i++) {
            titles.push(xus.resultTitles[i].title)
        }

        return (

            <div className={styles.SearchResults}>
                {this.state.add ? this.state.add : ""}
                <div className={this.state.preview ? styles.Display : styles.SearchTitles}>
                    <ResultTitles ascending={this.state.ascending} long={[1]} nonsortable={[0, 2]} sortBy={this.state.sortBy} Titles={titles} />
                    <div className={styles.Wrapper}>
                        <div className={styles.WrapperLine}>
                            {results}
                        </div>
                    </div>

                    <Pagination
                        currentPage={this.state.currentPage}
                        resultsPerPage={this.state.resultsPerPage}
                        totalResults={this.state.totalResults}
                        button={true}
                        buttonName={'ADD Ex-US Professional'}
                        buttonIcon={this.props.type === '/xus' ? <i className={["fas", "fa-user-plus", styles.Icon].join(' ')}></i> : <i className={["fas", "fa-building", styles.Icon].join(' ')} />}
                        buttonClick={() => {


                            //need check here>?


                            if (this.props.searchcriteria.COUNTRY_CODE == 'CA') {
                                this.setState({ add: <OneKey switchOneKey={this.switchOneKey.bind(this)} searchcriteria={this.props.searchcriteria} data={this.state.data} submit={this.submit.bind(this)} close={this.closeAdd.bind(this)} /> })
                            } else {
                                this.setState({ add: <AddExUs submit={this.submit.bind(this)} close={this.closeAdd.bind(this)} searchcriteria={this.props.searchcriteria} user={this.props.user} /> })
                            }
                        }}
                        setProperties={(currentPage, resultsPerPage) => {
                            this.setState({
                                currentPage: currentPage,
                                resultsPerPage: resultsPerPage
                            })
                        }} />
                </div>
                {this.state.preview ? <Preview
                    user={this.props.user}
                    type={this.props.type}
                    index={this.state.active}
                    className={styles.Preview}
                    close={this.close.bind(this)}
                    data={this.state.data[this.state.previewIndex]} /> : <div></div>}
                {notification}
            </div>




        );
    }
}

export default SearchResults;
