import React, {Component} from 'react';
import styles from '../css/Details_Edit_Details.module.css';
import hco from '../../../lookup/hco.json'
import firstCapital from '../../../helper/firstCapital';
import validateRequestNotes from '../../../helper/validateRequestNotes';

class Details_Edit_Details extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ORG_NAME: this.props.data.ORG_NAME,
            PREFERRED_NAME: firstCapital(this.props.data.PREFERRED_NAME),
            CTMS_NAME: this.props.data.CTMS_NAME,
            STATUS: firstCapital(this.props.data.STATUS),
            ORG_TYPE: this.props.data.ORG_TYPE,
            PREV_ORG_NAME: this.props.data.ORG_NAME,
            PREV_PREFERRED_NAME: firstCapital(this.props.data.PREFERRED_NAME),
            PREV_STATUS: firstCapital(this.props.data.STATUS),
            PREV_ORG_TYPE: this.props.data.ORG_TYPE,
            Wiggle: false,
            REQUEST_NOTE: null,
            ERROR_MESSAGE: "",
            changed: {}
        };
    }

    render() {
        let orgNameDisabledView = hco.details.orgName.view
        let orgNameDisabled = orgNameDisabledView.teams.includes(this.props.user.team) || orgNameDisabledView.roles.includes(this.props.user.team)
            ? true
            : ''
        let ctmsNameDisabledView = hco.details.ctmsName.view
        let ctmsNameDisabled = ctmsNameDisabledView.teams.includes(this.props.user.team) || ctmsNameDisabledView.roles.includes(this.props.user.role)
            ? true
            : ''
        let editDetailsExceptionEdit = hco.details.editDetails.edit
        let editDetailsException = editDetailsExceptionEdit.teams.includes(this.props.user.team) || editDetailsExceptionEdit.roles.includes(this.props.user.role) ? '' : true

            let ctmsNameView = hco.details.ctmsName.view
            let ctmsNameEdit = hco.details.ctmsName.edit
            let ctmsName = (ctmsNameView.teams.includes(this.props.user.team) || ctmsNameEdit.teams.includes(this.props.user.team)) || (ctmsNameView.roles.includes(this.props.user.role) || ctmsNameEdit.roles.includes(this.props.user.role)) ?
            (<React.Fragment>
                <div className={styles.requiredwrap}>CTMS NAME<div className={styles.required}>{
                            ctmsNameDisabled
                                ? ''
                                : ' *'
                        }</div>
                </div>
                <input value={this.state.CTMS_NAME !== -1
                        ? this.state.CTMS_NAME
                        : ""} onChange={(event) => {
                        let temp = this.state.changed;
                        if (this.props.data.CTMS_NAME !== event.target.value) {
                            temp.CTMS_NAME = event.target.value;
                            this.setState({changed: temp});
                        } else {
                            delete temp.CTMS_NAME;
                            this.setState({changed: temp});
                        }
                        if (event.target.value === "") {
                            this.setState({CTMS_NAME: -1})
                        } else {
                            this.setState({CTMS_NAME: event.target.value})
                        }
                    }} disabled={ctmsNameDisabled}/>
            </React.Fragment>)
            : (<React.Fragment>
                <div className={styles.inputTitle}>PREFERRED NAME</div>
                <input value={this.state.PREFERRED_NAME !== -1
                        ? this.state.PREFERRED_NAME
                        : ""} onChange={(event) => {
                        let temp = this.state.changed;
                        if (this.state.PREV_PREFERRED_NAME !== event.target.value) {
                            temp.PREFERRED_NAME = event.target.value;
                            this.setState({changed: temp});
                        } else {
                            delete temp.PREFERRED_NAME;
                            this.setState({changed: temp});
                        }
                        if (event.target.value === "") {
                            this.setState({PREFERRED_NAME: -1})
                        } else {
                            this.setState({PREFERRED_NAME: event.target.value})
                        }
                    }} disabled={orgNameDisabled}/>
            </React.Fragment>)


        return (
        <div>
            <div className={styles.title}>
                <div></div>
                <div className={styles.TitleText}>Edit Details</div>
                <div className={styles.close} onClick={() => {
                    this.props.close()
                }}><i className="fas fa-times"></i></div>
            </div>
            <div className={styles.body}>
                <div className={styles.required}>{this.state.ERROR_MESSAGE}</div>
                <div className={styles.required}>REQUIRED FIELD *</div>
                <div className={styles.toprow}>
                    <div className={styles.set}>
                        <div className={styles.inputTitle}>ORG NAME</div>
                        <input value={this.state.ORG_NAME !== -1
                                ? this.state.ORG_NAME
                                : ""} onChange={(event) => {
                                let temp = this.state.changed;
                                if (this.state.ORG_NAME !== event.target.value) {
                                    temp.ORG_NAME = event.target.value;
                                    this.setState({changed: temp});
                                } else {
                                    delete temp.ORG_NAME;
                                    this.setState({changed: temp});
                                }
                                if (event.target.value === "") {
                                    this.setState({ORG_NAME: -1})
                                } else {
                                    this.setState({ORG_NAME: event.target.value})
                                }
                            }} disabled={editDetailsException}/>
                    </div>
                    <div className={styles.set}>
                        {ctmsName}
                    </div>
                </div>

                <div className={styles.middlerow}>
                    <div className={styles.set}>
                        <div className={styles.inputTitle}>STATUS</div>
                        <select value={this.state.STATUS
                                ? "INACTIVE"
                                : "ACTIVE"} onChange={(event) => {
                                let temp = this.state.changed;
                                if (this.state.PREV_STATUS !== event.target.value) {
                                    temp.STATUS = event.target.value;
                                    this.setState({changed: temp});
                                } else {
                                    delete temp.STATUS;
                                    this.setState({changed: temp});
                                }
                                this.setState({STATUS: event.target.value})
                            }} disabled={editDetailsException}>
                            <option value="ACTIVE">Active</option>
                            <option value="INACTIVE">Inactive</option>
                        </select>
                    </div>
                    <div className={styles.set}>
                        <div className={styles.inputTitle}>ORG TYPE</div>
                        <select value={this.state.ORG_TYPE !== -1
                                ? this.state.ORG_TYPE
                                : ""} onChange={(event) => {
                                let temp = this.state.changed;
                                if (this.state.PREV_ORG_TYPE !== event.target.value) {
                                    temp.ORG_TYPE = event.target.value;
                                    this.setState({changed: temp});
                                } else {
                                    delete temp.ORG_TYPE;
                                    this.setState({changed: temp});
                                }
                                if (event.target.value === "") {
                                    this.setState({ORG_TYPE: -1})
                                } else {
                                    this.setState({ORG_TYPE: event.target.value})
                                }
                            }} disabled={editDetailsException}>
                            <option value="-1"></option>
                            <option value="ACADEMIC INSTITUTION">ACADEMIC INSTITUTION</option>
                                <option value="ASC">ASC</option>
                                <option value="FOUNDATION">FOUNDATION</option>
                            <option value="GPO">GPO</option>
                            <option value="GRP">GRP</option>
                            <option value="HEALTH NETWORK">HEALTH NETWORK</option>
                            <option value="HOME HEALTH">HOME HEALTH</option>
                            <option value="HOSPICE">HOSPICE</option>
                            <option value="HOSPITAL">HOSPITAL</option>
                            <option value="KAISER">KAISER</option>
                            <option value="MCO">MCO</option>
                            <option value="MEDICAL ASSOCIATION">MEDICAL ASSOCIATION</option>
                            <option value="MILITARY HOSPITAL">MILITARY HOSPITAL</option>
                            <option value="ONCOLOGY NETWORK">ONCOLOGY NETWORK</option>
                            <option value="PATIENT GROUP">PATIENT GROUP</option>
                            <option value="PHARMACY">PHARMACY</option>
                            <option value="RADIOLOGY CENTER">RADIOLOGY CENTER</option>
                            <option value="TEACHING INSTITUTION">TEACHING INSTITUTION</option>
                        </select>
                    </div>
                </div>

                <div className={styles.set}>
                    <div className={styles.inputTitle}>REVISION REASON
                    </div>
                    <input value={this.state.REQUEST_NOTE} onChange={(event) => {
                            this.setState({ REQUEST_NOTE: validateRequestNotes(event.target.value) });
                        }}/>
                </div>

                <div className={styles.buttonRow}>
                    <div id={styles.cancel} onClick={() => {
                            this.props.close();
                        }}>CANCEL</div>
                    <div className={this.state.Wiggle && this.state.Wiggle !== undefined
                            ? styles.Wiggle
                            : ""} id={styles.submit} onClick={() => {
                            if (Object.keys(this.state.changed).length > 0 && this.state.PREFERRED_NAME !== -1 && this.state.CTMS_NAME !== -1) {
                                let temp = this.state.changed
                                if (temp.ORG_NAME) {
                                    temp.REQUEST_TYPE = 'Update HCO Org Name'
                                }
                                if (temp.CTMS_NAME) {
                                    temp.REQUEST_TYPE = 'Update HCO CTMS Name'
                                }
                                if (temp.PREFERRED_NAME) {
                                    temp.REQUEST_TYPE = 'Update HCO Preferred Name'
                                }
                                if (temp.STATUS) {
                                    temp.REQUEST_TYPE = 'Update HCO Status'
                                }
                                if (temp.ORG_TYPE) {
                                    temp.REQUEST_TYPE = 'Update Org Type'
                                }
                                if (this.state.ORG_NAME.length > 255) {
                                    this.setState({ ERROR_MESSAGE: 'Org name should be less than 255 characters \n' });
                                }
                                else if (this.state.ORG_NAME.includes("\t")) {
                                    this.setState({ ERROR_MESSAGE: 'Org name contains tab space characters. Please remove the tab space characters \n' });
                                }
                                else if (this.state.ORG_NAME.includes("\r\n")) {
                                    this.setState({ ERROR_MESSAGE: 'Org name contains line feed characters. Please remove the line feed characters \n' });
                                }
                                else {
                                    temp.REQUEST_NOTE = this.state.REQUEST_NOTE
                                    this.props.submit(temp);
                                }
                            } else {
                                this.setState({Wiggle: true})
                                setTimeout(() => {
                                    this.setState({Wiggle: false})
                                }, 500);
                            }
                        }}>SUBMIT</div>
                </div>
            </div>
        </div>)
    }

}

export default Details_Edit_Details;
