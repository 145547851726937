import React, { Component } from 'react';
import styles from '../css/Activity.module.css';
import ActivityRecord from './ActivityRecord';

class Activity extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: this.props.activities
        };
    }

    componentWillReceiveProps(newProps) {


        this.buildResult(newProps.activities)


    }

    openChangeRequest(action_id) {


        this.props.setEditID(action_id);

    }
    buildResult(data) {


        if (data !== undefined && data !== null) {


            let temp = []
            let _odd = false;

            for (let i = 0; i < data.length; i++) {
                _odd = !_odd;
                temp.push(<ActivityRecord key={i} odd={_odd} user={this.props.user} openChangeRequest={this.openChangeRequest.bind(this)} data={data[i]} />);
            }
            this.setState({ results: temp })


        }
    }

    render() {
        //return (
        //    <div className={styles.activity}>
        //    </div>
        //);
        return (
            <div className={styles.activity}>
                <div className={styles.titles}>
                    <div className={styles.contentsmall}>ACTION ID</div>
                    <div className={styles.contentsmall}>DATE</div>
                    <div className={styles.content}>REQUEST TYPE</div>


                </div>
                {this.state.results}
            </div>
        );
    }
}

export default Activity;
