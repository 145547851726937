let OrgTypeLookUp = {
    'ACADEMIC INSTITUTION':'ACADEMIC INSTITUTION',
    'AMBULANCE': 'AMBULANCE',
    'ANALYTICAL LAB': 'ANALYTICAL LAB',
    'ANIMAL SHELTER': 'ANIMAL SHELTER',
    'ASC': 'ASC',
    'CHAIN HOSPITAL': 'CHAIN HOSPITAL',
    'CHEMPACK': 'CHEMPACK',
    'CLINIC': 'CLINIC',
    'COMPOUNDER': 'COMPOUNDER',
    'DETOXIFICATION': 'DETOXIFICATION',
    'DISTRIBUTOR': 'DISTRIBUTOR',
    'EXPORTER': 'EXPORTER',
    'FOUNDATION':'FOUNDATION',
    'GPO': 'GPO',
    'GRP': 'GRP',
    'HEALTH NETWORK': 'HEALTH NETWORK',
    'HOME HEALTH': 'HOME HEALTH',
    'HOSPICE': 'HOSPICE',
    'HOSPITAL': 'HOSPITAL',
    'CLINIC': 'CLINIC',
    'ASC': 'ASC',
    'HOSPICE': 'HOSPICE',
    'IMPORTER': 'IMPORTER',
    'KAISER': 'KAISER',
    'LONG TERM CARE': 'LONG TERM CARE',
    'MAINTENANCE': 'MAINTENANCE',
    'MANUFACTURER': 'MANUFACTURER',
    'MAINTENANCE': 'MAINTENANCE',
    'MCO': 'MCO',
    'MEDICAL ASSOCIATION': 'MEDICAL ASSOCIATION',
    'NH': 'NH',
    'NURSING HOME': 'NURSING HOME',
    'MEDICAL ASSOCIATION': 'MEDICAL ASSOCIATION',
    'MILITARY HOSPITAL': 'MILITARY HOSPITAL',
    'NURSING HOME': 'NURSING HOME',
    'ONCOLOGY NETWORK': 'ONCOLOGY NETWORK',
    'PATIENT GROUP': 'PATIENT GROUP',
    'PHARMACY': 'PHARMACY',
    'RADIOLOGY CENTER': 'RADIOLOGY CENTER',
    'TEACHING INSTITUTION': 'TEACHING INSTITUTION',
    'WAREHOUSE':'WAREHOUSE'
}

export default OrgTypeLookUp;
