import React from 'react'
import styles from '../css/Result.module.css'

function Result(props) {
    let start = new Date(props.data.START_DATE)
    let end = new Date(props.data.END_DATE)
    let today = new Date()
    let active = start.getTime() < today.getTime() && today.getTime() < end.getTime()
    return (
        <div className={styles.result}>
            <div className={[styles.col, styles.short, styles.center, styles.icon, active ? styles.green : styles.grey].join(' ')}>
                <i className={["fas", active ? "fa-check" : "fas-times"].join(" ")}></i>
            </div>
            <div className={[styles.col, styles.short, styles.center].join(' ')}>{props.data.NAME}</div>
            <div className={[styles.col, styles.short, styles.center].join(' ')}>{props.data.TEAM}</div>
            <div className={[styles.col, styles.short, styles.center].join(' ')}>{start.toJSON().slice(0,10).replace(/-/g,'/')}</div>
            <div className={[styles.col, styles.short, styles.center].join(' ')}>{end.toJSON().slice(0,10).replace(/-/g,'/')}</div>
            <div className={[styles.col, styles.short, styles.center].join(' ')}>{props.data.CUSTOMER_TYPE}</div>
            <div className={[styles.col, styles.short, styles.center].join(' ')}>
                <div className={styles.link} onClick={() => {
                    props.setEditIndex(props.index)
                    props.setEditExercise(true)
                }}>Edit</div>
                <div className={styles.link} onClick={() => {
                    props.setDeleteIndex(props.index)
                    props.setDeleteExercise(true)
                }}>Delete</div>
            </div>
        </div>
    )
}

export default Result
