import React from 'react'
import styles from './Results.module.css'
import Spinner from '../Spinner'

function Results(props) {
    if (props.loaded) {
        let results = []
        let start = props.currentPage ? Math.round(props.currentPage * props.resultsPerPage) : 0
        for (let i = start; (i < props.data.length) && (props.currentPage === undefined || i < start + props.resultsPerPage); i++) {
            results.push(<props.result key={props.data[i][props.idField]} {...props} data={props.data[i]} />)
        }
        return (
            <>
                {results}
            </>
        )
    }
    else {
        return (
            <tr className={[styles.wrapper, styles.results].join(" ")}>
                <td colSpan="100%">
                    <Spinner />
                </td>
            </tr>
        )
    }
}

export default Results
