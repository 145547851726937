import React from 'react'
import styles from '../css/ColumnEditBox.module.css'
import Toggle from '../../../globalcomponents/Toggle'

function ColumnEditBox(props) {
    let data = props.data[props.dataKey]
    return (
        <div className={styles.columnEditBox}>
            <input type="text" className={styles.disabled} value={data.col} disabled/>
            <div className={styles.set}>
                <div className={styles.inputTitle}>TITLE <div className={styles.required}>*</div></div>
                <input type="text" className={props.valid || data.title !== "" ? styles.default : styles.error} value={data.title} onChange={(event) => {
                        if (event.target.value.length < 25) {
                            props.setData({
                                ...props.data,
                                [props.dataKey]: {
                                    ...data,
                                    title: event.target.value
                                }
                            })
                        }
                    }} autoFocus disabled={data.disabled}/>
            </div>
            <div className={styles.set}>
                <div className={styles.inputTitle}>SELECT COLUMN FUNCTIONALITY <div className={styles.required}>*</div></div>
                <select type="text" className={props.valid || data.options !== "" ? styles.default : styles.error} value={data.options} onChange={(event) => {
                    props.setData({
                        ...props.data,
                        [props.dataKey]: {
                            ...data,
                            options: event.target.value
                        }
                    })
                }} disabled={data.disabled || data.disabledOptions}>
                    <option value="Show">Show</option>
                    <option value="LOV">List of values</option>
                    <option value="FreeText">Free Text</option>
                </select>
            </div>
            <div className={styles.set}>
                <Toggle active={data.show} global={data.disabled} click={() => {
                    if (!data.disabled) {
                        props.setData({
                            ...props.data,
                            [props.dataKey]: {
                                ...data,
                                show: !data.show
                            }
                        })
                    }
                }}/>
            </div>
        </div>
    )
}

export default ColumnEditBox
