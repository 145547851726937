import React, {Component} from 'react';
import styles from '../css/Details_Edit.module.css';
import DetailsEditPersonal from './Details_Edit_Personal';
import DetailsEditSpecialty from './Details_Edit_Specialty';
import DetailsEditAddress from './Details_Edit_Address';
import DetailsEditContact from './Details_Edit_Contact';
import DetailsEditAdditional from './Details_Edit_Additional';
//import DetailsEditHem from './Details_Edit_Hem';
import ChangeRequest from '../../ChangeRequests/ChangeRequest';
import DetailsEditAffiliations from './Details_Edit_Affiliations';
import DetailsEditMsl from './Details_Edit_Msl';
import Popup from '../../../globalcomponents/Popup';
//import AddHCPSuccess from './AddHcpSuccess'

class Details_Edit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data
        };
    }

    render() {
        let edit = ""
        if (this.props.index === 1) {
            edit = <DetailsEditPersonal data={this.state.data.data[0]} user={this.props.user} submit={this.props.submit} close={this.props.close} />
        }
        else if (this.props.index === 2) {
            edit = <DetailsEditSpecialty data={this.state.data.data[0]} user={this.props.user} submit={this.props.submit} close={this.props.close} />
        }
        else if (this.props.index === 4) {
            edit = <DetailsEditAddress data={this.state.data} user={this.props.user} close={this.props.close} submit={this.props.submit} addresses={this.props.addresses} />
        }
        else if (this.props.index === 5) {
            edit = <DetailsEditContact data={this.state.data.data[0]} user={this.props.user} close={this.props.close} submit={this.props.submit} />
        
        }
        return (
            <div className={styles.editScreen}>
                <Popup component={edit} />
            </div>
        )
    }

}

export default Details_Edit;
