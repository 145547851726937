import React from 'react'
import Popup from '../Popup'
import Spinner from '../Spinner'

function Success(props) {
    let body = ""
    if (props.success === null) {
        body = (
            <div className={"pop-body"}>
                <Spinner />
            </div>
        )
    }
    else if (props.success) {
        body = (
            <div className={"pop-body"}>
                <div className={"pop-titleRow"}>
                    <div className={"pop-side"}></div>
                    <div className={"pop-directions"}></div>
                    <div className={"pop-side"}></div>
                </div>
                <div className={"pop-content"}>
                    <div className={"pop-success"}><i className={["fas", "fa-check-circle", "pop-icon"].join(" ")}></i></div>
                    <div className={"pop-text"}>{props.successMessage}</div>
                </div>
                <div className={"pop-buttonRow"}>
                    <button type="button" id={"pop-submit"} onClick={() => {
                            props.close()
                        }
                    }>CLOSE</button>
                </div>
            </div>
        )
    }
    else {
        body = (
            <div className={"pop-body"}>
                <div className={"pop-titleRow"}>
                    <div className={"pop-side"}></div>
                    <div className={"pop-directions"}></div>
                    <div className={"pop-side"}></div>
                </div>
                <div className={"pop-content"}>
                    <div className={"pop-success"}><i className={["fas", "fa-check-circle", "pop-icon"].join(" ")}></i></div>
                    <div className={"pop-text"}>Something went wrong. Close and try again.</div>
                </div>
                <div className={"pop-buttonRow"}>
                    <button type="button" id={"pop-submit"} onClick={() => {
                            props.close()
                        }
                    }>CLOSE</button>
                </div>
            </div>
        )
    }
    return (
        <Popup {...props} body={body} title={"Success"} />
    )

}

export default Success
