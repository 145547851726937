import React, {Component} from 'react';
import styles from '../css/BottomBar.module.css';

class BottomBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            result: [],
            ready: false,
            ResultsPerPage: 20,
            CurrentPage: 0,
            TotalResults: props.data.length,
            sortBy: -1,
            DetailsView: false
        };
    }

    componentWillReceiveProps(newProp) {
        if (newProp.CurrentPage === 0) {
            this.setState({
                TotalResults: newProp.data.length,
                CurrentPage: 0
            });
        }
        else {
            this.setState({TotalResults: newProp.data.length});
        }
    }

    render() {
        return (<div className={styles.BottomBar}>
            <div className={styles.e}></div>
            <div className={styles.Pages}>
                <button className={styles.Arrow} onClick={() => {
                        if (this.state.CurrentPage > 0) {
                            if (this.state.CurrentPage - 1 < 0) {
                                this.setState({CurrentPage: 0})
                            } else {
                                this.setState({
                                    CurrentPage: this.state.CurrentPage - 1
                                })
                                this.props.setPage(this.state.CurrentPage - 1)
                            }
                        }
                    }}>
                    <i className="fas fa-angle-left"></i>
                </button>
                <div>
                    {`${
                        `${Math.round(this.state.CurrentPage * this.state.ResultsPerPage) + 1}-${this.state.TotalResults > ((this.state.CurrentPage * this.state.ResultsPerPage) + this.state.ResultsPerPage)
                            ? Math.round((this.state.CurrentPage * this.state.ResultsPerPage) + this.state.ResultsPerPage)
                            : this.state.TotalResults}`
                    } of ${this.state.TotalResults}`}
                </div>
                <button className={styles.Arrow} onClick={() => {
                        if (this.state.CurrentPage + 1 < this.state.TotalResults / this.state.ResultsPerPage) {
                            this.setState({
                                CurrentPage: this.state.CurrentPage + 1
                            })
                            this.props.setPage(this.state.CurrentPage + 1)
                        }
                    }}>
                    <i className="fas fa-angle-right"></i>
                </button>
            </div>
            <div className={styles.ViewWrapper}>
                <div className={styles.Views}>View</div>
                <div className={this.state.ResultsPerPage === 20
                        ? [styles.Views, styles.Active].join(' ')
                        : styles.Views} onClick={() => {
                        this.setState({
                            ResultsPerPage: 20,
                            CurrentPage: (this.state.CurrentPage * this.state.ResultsPerPage) / 20
                        })
                        this.props.setPageViewCount(20, (this.state.CurrentPage * this.state.ResultsPerPage) / 20)
                    }}>20</div>
                <div className={styles.Views}>|</div>
                <div className={this.state.ResultsPerPage === 40
                        ? [styles.Views, styles.Active].join(' ')
                        : styles.Views} onClick={() => {
                        this.setState({
                            ResultsPerPage: 40,
                            CurrentPage: (this.state.CurrentPage * this.state.ResultsPerPage) / 40
                        })
                        this.props.setPageViewCount(40, (this.state.CurrentPage * this.state.ResultsPerPage) / 40)
                    }}>40</div>
                <div className={styles.Views}>|</div>
                <div className={this.state.ResultsPerPage === 60
                        ? [styles.Views, styles.Active].join(' ')
                        : styles.Views} onClick={() => {
                        this.setState({
                            ResultsPerPage: 60,
                            CurrentPage: (this.state.CurrentPage * this.state.ResultsPerPage) / 60
                        })
                        this.props.setPageViewCount(60, (this.state.CurrentPage * this.state.ResultsPerPage) / 60)
                    }}>60</div>
                <div className={styles.Views}>|</div>
                <div className={this.state.ResultsPerPage === 80
                        ? [styles.Views, styles.Active].join(' ')
                        : styles.Views} onClick={() => {
                        this.setState({
                            ResultsPerPage: 80,
                            CurrentPage: (this.state.CurrentPage * this.state.ResultsPerPage) / 80
                        })
                        this.props.setPageViewCount(80, (this.state.CurrentPage * this.state.ResultsPerPage) / 80)
                    }}>80</div>
                <div className={styles.Views}>|</div>
                <div className={this.state.ResultsPerPage === 100
                        ? [styles.Views, styles.Active].join(' ')
                        : styles.Views} onClick={() => {
                        this.setState({
                            ResultsPerPage: 100,
                            CurrentPage: (this.state.CurrentPage * this.state.ResultsPerPage) / 100
                        })
                        this.props.setPageViewCount(100, (this.state.CurrentPage * this.state.ResultsPerPage) / 100)
                    }}>100</div>
            </div>
        </div>);
    }
}

export default BottomBar;
