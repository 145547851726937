const validation = {
    validateOrgContactForm: function(changed, input, callback) {
        input.ISVALID_EMAIL = this.isValidEmail(changed.EMAIL);
        input.ISVALID_CTMS_EMAIL = this.isValidEmail(changed.CTMS_EMAIL);
        input.ISVALID_TWITTER = this.isValidTwitter(changed.TWITTER);
        input.ISVALID_WEBSITE = this.isValidWebsite(this.sanitizeHTML(changed.WEBSITE));
        input.ISVALID_PHONE = this.isValidPhoneNumber(changed.PHONE);
        input.ISVALID_FAX = this.isValidPhoneNumber(changed.FAX);
        input.ISVALID_REQUEST_NOTE = this.validRequestNote(changed.REQUEST_NOTE);
        if (!input.ISVALID_EMAIL || !input.ISVALID_CTMS_EMAIL || !input.ISVALID_PHONE || !input.ISVALID_FAX || !input.ISVALID_WEBSITE || !input.ISVALID_REQUEST_NOTE) {
            input.ISVALID = false;
        } else {
            input.ISVALID = true;
        }
        callback(input);
    },
    validateContactForm: function(changed, input, callback) {
        input.ISVALID_EMAIL = this.isValidEmail(changed.EMAIL);
        input.ISVALID_CTMS_EMAIL = this.isValidEmail(changed.CTMS_EMAIL);
        input.ISVALID_TWITTER = this.isValidTwitter(changed.TWITTER);
        input.ISVALID_WEBSITE = this.isValidWebsite(this.sanitizeHTML(changed.WEBSITE));
        input.ISVALID_MOBILE = this.isValidPhoneNumber(changed.MOBILE);
        input.ISVALID_REQUEST_NOTE = this.validRequestNote(changed.REQUEST_NOTE);
        if (!input.ISVALID_EMAIL || !input.ISVALID_CTMS_EMAIL || !input.ISVALID_MOBILE || !input.ISVALID_WEBSITE || !input.ISVALID_REQUEST_NOTE) {
            input.ISVALID = false;
        } else {
            input.ISVALID = true;
        }
        callback(input);
    },

    //Given a String, checks to whether it is a valid Email type
    isValidEmail: function(email) {
        var isValid = false;
        if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email) || (email === undefined || email === "")) {
            isValid = true;
        }
        return isValid;
    },

    isValidTwitter: function (twitterHandle) {
        var isValid = true;
        //if (/^[a-zA-Z0-9_]{1,15}$/.test(twitterHandle) || (twitterHandle === undefined || twitterHandle === "")) {
        //    isValid = true;
        //}
        return isValid;
    },

    isValidPhoneNumber: function(pnumber) {
        var isValid = false;
        if (/^\d{3}\-\d{3}\-\d{4}$/.test(pnumber) || (pnumber === undefined || pnumber === "")) {
            isValid = true;
        }
        return isValid;
    },

    isValidWebsite: function(website) {
        var isValid = false;
        if (/^(?:http(s)?:\/\/)?[\w.-]+(?:.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/.test(website) || (website === undefined || website === "")) {
            isValid = true;
        }
        return isValid;
    },

    validRequestNote: function(requestNote) {
        var isValid = false;
        if (requestNote !== "" || requestNote !== undefined) {
            isValid = true;
        }
        return isValid;
    },

    sanitizeHTML: function(str) {
        var temp = document.createElement('div');
        temp.textContent = str;
        return temp.innerHTML;
    }
}

export default validation;
