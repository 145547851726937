import React, { Component } from 'react';
import styles from '../css/SearchTopBar.module.css';
import Set from '../../../components/Set'
import WiggleButton from '../../../components/WiggleButton'
import hcp from '../../../lookup/hcp.json'
import hco from '../../../lookup/hco.json'
import xus from '../../../lookup/xus.json'
import countrystatelookup from '../../../lookup/countrystatelookup.json'
import { keys } from 'underscore';

class SearchTopBar extends Component {
    constructor(props) {
        super(props);
        if (props.savedSearchParams !== undefined) {
            this.state = {
                search: {
                    FIRST_NAME: this.props.savedSearchParams.FIRST_NAME,
                    LAST_NAME: this.props.savedSearchParams.LAST_NAME,
                    CITY: this.props.savedSearchParams.CITY,
                    COUNTRY_CODE: this.props.savedSearchParams.COUNTRY_CODE,
                    STATE: this.props.savedSearchParams.STATE,
                    ZIP: this.props.savedSearchParams.ZIP,
                    PROF_ID: this.props.savedSearchParams.PROF_ID,
                    NPINUM: this.props.savedSearchParams.NPINUM,
                    DEANUM: this.props.savedSearchParams.DEANUM
                },
                filter: {
                    UNIVERSE: this.props.savedSearchParams.UNIVERSE,
                    DEGREE: this.props.savedSearchParams.DEGREE,
                    SPECIALTY: this.props.savedSearchParams.SPECIALTY,
                    TARGET: this.props.savedSearchParams.TARGET,
                    COMPLIANCE: this.props.savedSearchParams.COMPLIANCE
                }
            };
        }
        else {
           
                this.state = {
                    search: {
                        FIRST_NAME: "",
                        LAST_NAME: "",
                        COUNTRY_CODE: this.props.user.team === '81' || this.props.user.team === '82' ? "CA" : "",
                        STATE: "",
                        ZIP: "",
                        PROF_ID: ""
                    },
                    filter: {
                        DEGREE: "",
                        SPECIALTY: "",
                    }
                };
            
        }
    }

    clearSearch() {
        let search = this.state.search
        for (let i = 0; i < Object.keys(search).length; i++) {
            search[Object.keys(search)[i]] = ""
        }
        this.setState({ search: search })
    }

    clearFilter() {
        let filter = this.state.filter
        for (let i = 0; i < Object.keys(filter).length; i++) {
            filter[Object.keys(filter)[i]] = ""
        }
        this.setState({ filter: filter })
    }

    cleanSearchParams() {
        let json = this.state;
        for (var key in json) {
            var item = json[key];
            for (var subkey in json[key]) {
                item[subkey] = item[subkey].trim()
            }
        }
        return json
    }

    setstateoptions(country) {

        let countrystateOptions = [];
        var localObj = {}
        var localArrays = [];
        var jsonstr = "";
        if (country.length > 0) {
            var countrystateinfo = Object.keys(countrystatelookup).filter(item => item.substr(0, 2) == country);

            countrystateinfo.forEach(element => {

                if (jsonstr.length > 0)
                    jsonstr = jsonstr + ", ";
                jsonstr = jsonstr + '"' + element + '"' + ": " + '"' + countrystatelookup[element] + '"';
            });

            localObj = JSON.parse("{" + jsonstr + "}");

            return localObj;

        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.type !== this.props.type) {
            this.clearSearch();
            this.clearFilter();
           
                this.setState({
                    search: {
                        FIRST_NAME: "",
                        LAST_NAME: "",
                        COUNTRY_CODE: "",
                        STATE: "",
                        ZIP: ""
                    },
                    filter: {
                        DEGREE: "",
                        SPECIALTY: "",
                    }
                })
            
        }
    }

    render() {
        let searchbar = []
        let countryvalue = ""
        let stateoptions = {}
        let type = xus;

        let countrystateOptions = [];
        let selectedCountryCode = this.state.search.COUNTRY_CODE;

        if (selectedCountryCode != undefined && selectedCountryCode != "" ) {

            // var key = Object.keys(CountryLookup).find(itemkey => CountryLookup[itemkey] === this.state.selectedcountry)

            var countrystateinfo = Object.keys(countrystatelookup).filter(item => item.substring(0, 2) == selectedCountryCode)
            countrystateinfo.forEach(element => {
                countrystateOptions.push(<option key={element} value={element}>{countrystatelookup[element]}</option>);
            });
        }

        for (let i = 0; i < type.searchParams.length; i++) {
            if (type.searchParams[i].type === "select") {
                if (type.searchParams[i].column === "STATE" && selectedCountryCode != undefined && selectedCountryCode != "" ) {
                    //stateoptions = this.setstateoptions(countryvalue)
                    stateoptions = this.setstateoptions(selectedCountryCode);
                    searchbar.push(<Set key={i} type={type.searchParams[i].type} user={this.props.user} roles={type.searchParams[i].roles} teams={[]} title={type.searchParams[i].title} value={this.state.search[type.searchParams[i].column]} options={stateoptions} setValue={(data) => {
                        let search = this.state.search
                        search[type.searchParams[i].column] = data
                        this.setState({
                            search: search
                        })
                    }} />);
                }
                else {
                    searchbar.push(<Set key={i} type={type.searchParams[i].type} user={this.props.user} roles={type.searchParams[i].roles} teams={[]} title={type.searchParams[i].title} value={this.state.search[type.searchParams[i].column]} options={type.searchParams[i].options} setValue={(data) => {
                        let search = this.state.search
                        search[type.searchParams[i].column] = data
                        this.setState({
                            search: search
                        })
                    }} />)
                }
                if (i == 2) {
                    if (searchbar[i].props.value != undefined) {
                        countryvalue = searchbar[i].props.value
                    }
                }
            }
            else {
                searchbar.push(<Set key={i} type={type.searchParams[i].type} user={this.props.user} roles={type.searchParams[i].roles} teams={[]} title={type.searchParams[i].title} value={this.state.search[type.searchParams[i].column]} setValue={(data) => {
                    let search = this.state.search
                    search[type.searchParams[i].column] = data
                    this.setState({
                        search: search
                    })
                }} />)
            }
        }

        let filterbar = []
        for (let i = 0; i < type.filterParams.length; i++) {

            if (type.filterParams[i].type === "select") {

                filterbar.push(<Set key={i} type={type.filterParams[i].type} user={this.props.user} roles={type.filterParams[i].roles} teams={type.filterParams[i].teams} title={type.filterParams[i].title} value={this.state.filter[type.filterParams[i].column]} options={type.filterParams[i].options} teamOptions={type.filterParams[i].teamOptions} role={this.props.user.role} team={this.props.user.team} setValue={(data) => {
                    let filter = this.state.filter
                    filter[type.filterParams[i].column] = data
                    this.setState({
                        filter: filter
                    })
                }} />)
            }
            else {

                filterbar.push(<Set key={i} type={type.filterParams[i].type} user={this.props.user} roles={type.filterParams[i].roles} teams={type.filterParams[i].teams} title={type.filterParams[i].title} value={this.state.filter[type.filterParams[i].column]} setValue={(data) => {
                    let filter = this.state.filter
                    filter[type.filterParams[i].column] = data
                    this.setState({
                        filter: filter
                    })
                }} />)
            }
        }

        return (<div className={styles.TopBar}>
            <div className={styles.Bar}>
                <div className={styles.IconWrapper}>
                    <i className={["fas", "fa-search", styles.Icon].join(' ')}></i>
                </div>
                <form>
                    <div className={styles.row}>
                        {searchbar}
                        <div className={[styles.set, styles.buttonSet].join(' ')}>
                            <WiggleButton required={Object.values(this.state)} icon={<i className={["fas", "fa-search", styles.IconButton].join(' ')}></i>} text='SEARCH' action={() => {
                                this.props.search(this.cleanSearchParams())
                            }} />
                        </div>
                        <div className={styles.Line}></div>
                        <div className={[styles.set, styles.buttonSet].join(' ')}>
                            <button id={styles.clearButton} type="button" onClick={() => {
                                this.clearSearch();
                                this.clearFilter();
                                this.props.clearResults();
                            }}>CLEAR ALL</button>
                        </div>
                    </div>
                </form>
            </div>
            <div className={styles.Bar} id={styles.filterBar}>
                <div className={styles.IconWrapper}>
                    <i className={["fas", "fa-sliders-h", styles.Icon].join(' ')}></i>
                </div>
                <div className={styles.row}>
                    {filterbar}
                    <div className={[styles.set, styles.buttonSet].join(' ')}>
                        <WiggleButton required={Object.values(this.state)} text='APPLY FILTER' action={() => {
                            this.props.search(this.state)
                        }} />
                    </div>
                    <div className={styles.Line}></div>
                    <div className={[styles.set, styles.buttonSet].join(' ')}>
                        <button id={styles.clearButton} onClick={() => {
                            this.clearFilter();
                        }} type="button">CLEAR FILTER</button>
                    </div>
                </div>
            </div>
        </div>);
    }
}

export default SearchTopBar;
