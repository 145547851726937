import Axios from "../components/Axios/Axios";

export function buildBatchedRequests(
    requestData,
    changes,
    batch,
    submit,
    type
) {
    let requestTypeMap = {
        FIRST_NAME: `${type} Name Change`,
        MIDDLE_NAME: `${type} Name Change`,
        LAST_NAME: `${type} Name Change`,
        DEGREE: `Degree Update`,
        GENDER: `Gender Update`,
        EMAIL: `Update ${type} Email`,
        CTMS_EMAIL: `Update ${type} CTMS Email`,
        CTMS_NAME: `Update ${type} CTMS Name`,
        TWITTER: `Update ${type} Twitter`,
        MOBILE: `Update ${type} Phone`,
        WEBSITE: `Update ${type} Website`,
        SPECIALTY: `Specialty Update`,
        OTHER_SPECIALTY: `Secondary Specialty Update`,
        COMPLIANCE_SPECIALTY: `Update HCP Compliance Specialty`,
        PHONE: `Update ${type} Phone`,
        FAX: `Update ${type} Fax`,
        ORG_NAME: `Update ${type} Org Name`,
        ORG_TYPE: `Update Org Type`,
        PREFERRED_NAME: `Update ${type} Preferred Name`,
        STATUS: `Update ${type} Status`,
        Retired: `${type} Status Change`,
        Active: `${type} Status Change`,
        Deceased: `${type} Status Change`,
        Inactive: `${type} Status Change`,
    };
    let requests = [];
    let nameChange = {};
    for (let i = 0; i < Object.keys(changes).length; i++) {
        const key = Object.keys(changes)[i];
        const value = Object.values(changes)[i];
        if (["FIRST_NAME", "MIDDLE_NAME", "LAST_NAME"].includes(key)) {
            nameChange = {
                ...nameChange,
                ...requestData,
                [key]: value,
                REQUEST_TYPE: requestTypeMap[key],
            };
        } else if (["REQUEST_NOTE"].includes(key)) {
            //do nothing
        } else {
            requests.push({
                ...requestData,
                [key]: value,
                REQUEST_TYPE: requestTypeMap[key === "STATUS" ? value : key],
            });
        }
    }
    if (Object.keys(nameChange).length > 0) {
        requests.push(nameChange);
    }
    sendBatchedRequests(batch, requests, submit);
}

export function sendBatchedRequests(batch, requests, submit) {
    if (requests.length > 1) {
        Axios("/api/global/change-request", batch, (parent) => {
            for (let i = 0; i < requests.length; i++) {
                //console.log(requests[i]);
               // requests[i].ACTION_NUM = parent.ACTION_ID;
            }
            submit(requests, true);
        });
    } else {
        submit(requests[0]);
    }
}
