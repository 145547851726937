import React from 'react'
import { Link } from 'react-router-dom'
import Styles from '../css/PlanCard.module.css'

export default function PlanCard(props) {

    let NAME = '';

    switch (props.exerciseType) {
        case "Targeting Exercise":
            NAME = NAME = props.name
            break;
        case "Regional Plan Exercise":
            NAME = props.exercises.regionalPlan.NAME
            break;
        case "Best Address Exercise":
            NAME = props.exercises.bestAddress.NAME
            break;
        default:
            NAME = "No Name"
    }

    return (
        <Link className={Styles.wrapper} to={{
            pathname: props.destination,
            state: {
                from: '/'
            }
        }}>
            <div className={Styles.nowActive}>Now Active</div>
            <div className={Styles.icon}>{props.icon}</div>
            <div className={Styles.exerciseType}>{NAME}</div>
            <div className={Styles.date}>{props.date}</div>
        </Link>
    )
}