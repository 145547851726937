import React, {Component} from 'react';
import styles from '../css/EditResult.module.css';
import Axios from '../../../components/Axios';
import focusarea from '../../../globalcomponents/focusarea.js'

class EditResults extends Component {
    constructor(props) {
        super(props);
        this.state = {
            FOCUS_AREA_PRIMARY: props.data.FOCUS_AREA_PRIMARY !== null
                ? props.data.FOCUS_AREA_PRIMARY
                : props.data.TOG_FOCUS_AREA_PRIMARY,
            FOCUS_AREA_PRIMARY_TIER: props.data.FOCUS_AREA_PRIMARY_TIER !== null
                ? props.data.FOCUS_AREA_PRIMARY_TIER
                : props.data.TOG_FOCUS_AREA_PRIMARY_TIER,
            FOCUS_AREA_SECONDARY: props.data.FOCUS_AREA_SECONDARY !== null
                ? props.data.FOCUS_AREA_SECONDARY
                : props.data.TOG_FOCUS_AREA_SECONDARY,
            FOCUS_AREA_SECONDARY_TIER: props.data.FOCUS_AREA_SECONDARY_TIER !== null
                ? props.data.FOCUS_AREA_SECONDARY_TIER
                : props.data.TOG_FOCUS_AREA_SECONDARY_TIER,
            FOCUS_AREA_TERTIARY: props.data.FOCUS_AREA_TERTIARY !== null
                ? props.data.FOCUS_AREA_TERTIARY
                : props.data.TOG_FOCUS_AREA_TERTIARY,
            FOCUS_AREA_TERTIARY_TIER: props.data.FOCUS_AREA_TERTIARY_TIER !== null
                ? props.data.FOCUS_AREA_TERTIARY_TIER
                : props.data.TOG_FOCUS_AREA_TERTIARY_TIER,
            GOAL1: props.data.Goal1 !== null
                ? props.data.Goal1
                : props.data.TOG_GOAL1,
            GOAL2: props.data.Goal2 !== null
                ? props.data.Goal2
                : props.data.TOG_GOAL2,
            GOAL3: props.data.Goal3 !== null
                ? props.data.Goal3
                : props.data.TOG_GOAL3,
            GOAL4: props.data.Goal4 !== null
                ? props.data.Goal4
                : props.data.TOG_GOAL4,
            GOAL5: props.data.Goal5 !== null
                ? props.data.Goal5
                : props.data.TOG_GOAL5,
            GOAL6: props.data.Goal6 !== null
                ? props.data.Goal6
                : props.data.TOG_GOAL6,
            GOAL7: props.data.Goal7 !== null
                ? props.data.Goal7
                : props.data.TOG_GOAL7,
            ISVALID: true,
            ISVALID_PRIMARY: true,
            ISVALID_SECONDARY: true,
            ISVALID_TERTIARY: true,
            ISVALID_GOALS: true
        }
    }

    checkFocus() {
        let primary = false
        if (this.state.FOCUS_AREA_PRIMARY !== "" && this.state.FOCUS_AREA_PRIMARY !== null) {
            if (this.state.FOCUS_AREA_PRIMARY_TIER !== "" && this.state.FOCUS_AREA_PRIMARY_TIER !== null) {
                primary = true
            }
         
        }
        let secondary = false
        if (this.state.FOCUS_AREA_SECONDARY !== "" && this.state.FOCUS_AREA_SECONDARY !== null) {
            if (this.state.FOCUS_AREA_SECONDARY_TIER !== "" && this.state.FOCUS_AREA_SECONDARY_TIER !== null) {
              
                secondary = true
            }
           
        }
        else {
            if (this.state.FOCUS_AREA_SECONDARY_TIER !== "" && this.state.FOCUS_AREA_SECONDARY_TIER !== null) {
             
            }
            else {
                
                secondary = true
            }
        }
        let other = false
        if (this.state.FOCUS_AREA_TERTIARY !== "" && this.state.FOCUS_AREA_TERTIARY !== null) {
            if (this.state.FOCUS_AREA_TERTIARY_TIER !== "" && this.state.FOCUS_AREA_TERTIARY_TIER !== null) {
               
                other = true
            }
          
        }
        else {
            if (this.state.FOCUS_AREA_TERTIARY_TIER !== "" && this.state.FOCUS_AREA_TERTIARY_TIER !== null) {
              
                other = false
            }
            else {
                
                other = true
            }
        }

        let goal1 = false
        let goal2 = false
        let goal3 = false
        let goal4 = false
        let goal5 = false
        let goal6 = false
        let goal7 = false

        if (this.state.GOAL1) { goal1 = true }
        if (this.state.GOAL2) { goal2 = true }
        if (this.state.GOAL3) { goal3 = true }
        if (this.state.GOAL4) { goal4 = true }
        if (this.state.GOAL5) { goal5 = true }
        if (this.state.GOAL6) { goal6 = true }
        if (this.state.GOAL7) { goal7 = true }

        this.setState({ ISVALID_PRIMARY: primary });
        this.setState({ ISVALID_TERTIARY: other });
        this.setState({ ISVALID_SECONDARY: secondary });

        if (!goal1 && !goal2 && !goal3 && !goal4 && !goal5 && !goal6 && !goal7) {
            this.setState({ ISVALID_GOALS: false, ISVALID: false });
            return false
        } else {
            this.setState({ ISVALID_GOALS: true });
        }

        if (primary && secondary && other) {
            this.setState({ ISVALID: true });
            return true
        }
        else {
            this.setState({ ISVALID: false });
            return false
        }
    }

    render() {
        let focusoptions = []
        for (let i = 0; i < focusarea.length; i++) {
            focusoptions.push(<option value={focusarea[i]}>{focusarea[i]}</option>)
        }

        return (<div className={styles.editScreen}>
            <div className={styles.wrapper}>
                <div className={styles.title}>
                    <div></div>
                    <div className={styles.TitleText}>{
                            `${this.props.data.FIRST_NAME}${this.props.data.MIDDLE_NAME
                                ? " " + this.props.data.MIDDLE_NAME + " "
                                : " "}${this.props.data.LAST_NAME}`
                        }</div>
                    <div className={styles.close} onClick={() => {
                            this.props.close()
                        }}>
                        <i className="fas fa-times"></i>
                    </div>
                </div>
                <form>
                    <div className={styles.body}>
                        <div className={styles.instructions}>If you select a focus area, you must select a corresponding tier. At least one goal must be selected.</div>
                        <div className={styles.errorMessage}>REQUIRED FIELD *</div>
                        <div className={styles.toprow}>
                            <div className={styles.set}>
                                <div className={styles.inputTitle}>PRIMARY FOCUS AREA
                                    <div className={styles.required}>*</div>
                                </div>
                                <select type="text" className={this.state.ISVALID_PRIMARY
                                        ? styles.DefaultInputForm
                                        : styles.ErrorInputForm} value={this.state.FOCUS_AREA_PRIMARY} onChange={(event) => {
                                        this.setState({FOCUS_AREA_PRIMARY: event.target.value})
                                    }} autoFocus="autoFocus">
                                    {focusoptions}
                                </select>
                            </div>
                            <div className={styles.set}>
                                <div className={styles.inputTitle}>PRIMARY TIER
                                    <div className={styles.required}>*</div>
                                </div>
                                <select type="text" className={this.state.ISVALID_PRIMARY
                                        ? styles.DefaultInputForm
                                        : styles.ErrorInputForm} value={this.state.FOCUS_AREA_PRIMARY_TIER} onChange={(event) => {
                                        this.setState({FOCUS_AREA_PRIMARY_TIER: event.target.value})
                                    }}>
                                    <option value=""></option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                </select>
                            </div>

                            <div className={styles.set}>
                                <div className={styles.inputTitle}>SECONDARY FOCUS AREA</div>
                                <select type="text" className={this.state.ISVALID_SECONDARY ? styles.DefaultInputForm: styles.ErrorInputForm} value={this.state.FOCUS_AREA_SECONDARY} onChange={(event) => {
                                        this.setState({FOCUS_AREA_SECONDARY: event.target.value})
                                    }}>
                                    {focusoptions}
                                </select>
                            </div>
                            <div className={styles.set}>
                                <div className={styles.inputTitle}>SECONDARY TIER</div>
                                <select type="text" className={this.state.ISVALID_SECONDARY
                                        ? styles.DefaultInputForm
                                        : styles.ErrorInputForm} value={this.state.FOCUS_AREA_SECONDARY_TIER} onChange={(event) => {
                                        this.setState({FOCUS_AREA_SECONDARY_TIER: event.target.value})
                                    }}>
                                    <option value=""></option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                </select>
                            </div>

                            <div className={styles.set}>
                                <div className={styles.inputTitle}>OTHER FOCUS AREA</div>
                                <select type="text" className={this.state.ISVALID_TERTIARY
                                        ? styles.DefaultInputForm
                                        : styles.ErrorInputForm} value={this.state.FOCUS_AREA_TERTIARY} onChange={(event) => {
                                        this.setState({FOCUS_AREA_TERTIARY: event.target.value})
                                    }}>
                                    {focusoptions}
                                </select>
                            </div>
                            <div className={styles.set}>
                                <div className={styles.inputTitle}>OTHER TIER</div>
                                <select type="text" className={this.state.ISVALID_TERTIARY
                                        ? styles.DefaultInputForm
                                        : styles.ErrorInputForm} value={this.state.FOCUS_AREA_TERTIARY_TIER} onChange={(event) => {
                                        this.setState({FOCUS_AREA_TERTIARY_TIER: event.target.value})
                                    }}>
                                    <option value=""></option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                </select>
                            </div>


                            <div className={[styles.set, styles.checkbox].join(' ')}>
                                <div className={this.state.ISVALID_GOALS ? styles.inputTitle : styles.inputTitleError }>GOAL 1
                                    <div className={styles.required}>*</div>
                                </div>
                                <input type="checkbox" className={styles.DefaultInputForm }    defaultChecked={this.state.GOAL1} onChange={(event) => {
                                        this.setState({
                                            GOAL1: !this.state.GOAL1
                                        })
                                    }}></input>
                            </div>
                            <div className={[styles.set, styles.checkbox].join(' ')}>
                                <div className={this.state.ISVALID_GOALS ? styles.inputTitle : styles.inputTitleError}>GOAL 2</div>
                                <input type="checkbox" className={styles.DefaultInputForm }  defaultChecked={this.state.GOAL2} onChange={(event) => {
                                        this.setState({
                                            GOAL2: !this.state.GOAL2
                                        })
                                    }}></input>
                            </div>
                            <div className={[styles.set, styles.checkbox].join(' ')}>
                                <div className={this.state.ISVALID_GOALS ? styles.inputTitle : styles.inputTitleError}>GOAL 3</div>
                                <input type="checkbox" className={ styles.DefaultInputForm }   defaultChecked={this.state.GOAL3} onChange={(event) => {
                                        this.setState({
                                            GOAL3: !this.state.GOAL3
                                        })
                                    }}></input>
                            </div>
                            <div className={[styles.set, styles.checkbox].join(' ')}>
                                <div className={this.state.ISVALID_GOALS ? styles.inputTitle : styles.inputTitleError}>GOAL 4</div>
                                <input type="checkbox" className={this.state.ISVALID_GOALS ? styles.DefaultInputForm : styles.ErrorInputForm}  defaultChecked={this.state.GOAL4} onChange={(event) => {
                                        this.setState({
                                            GOAL4: !this.state.GOAL4
                                        })
                                }}></input>


                            </div>

                            <div className={[styles.set, styles.checkbox].join(' ')}>
                                <div className={this.state.ISVALID_GOALS ? styles.inputTitle : styles.inputTitleError}>GOAL 5</div>
                                <input type="checkbox" className={this.state.ISVALID_GOALS ? styles.DefaultInputForm : styles.ErrorInputForm} defaultChecked={this.state.GOAL5} onChange={(event) => {
                                    this.setState({
                                        GOAL5: !this.state.GOAL5
                                    })
                                }}></input>


                            </div>


                            <div className={[styles.set, styles.checkbox].join(' ')}>
                                <div className={this.state.ISVALID_GOALS ? styles.inputTitle : styles.inputTitleError}>GOAL 6</div>
                                <input type="checkbox" className={this.state.ISVALID_GOALS ? styles.DefaultInputForm : styles.ErrorInputForm} defaultChecked={this.state.GOAL6} onChange={(event) => {
                                    this.setState({
                                        GOAL6: !this.state.GOAL6
                                    })
                                }}></input>


                            </div>


                            <div className={[styles.set, styles.checkbox].join(' ')}>
                                <div className={this.state.ISVALID_GOALS ? styles.inputTitle : styles.inputTitleError}>GOAL 7</div>
                                <input type="checkbox" className={this.state.ISVALID_GOALS ? styles.DefaultInputForm : styles.ErrorInputForm} defaultChecked={this.state.GOAL7} onChange={(event) => {
                                    this.setState({
                                        GOAL7: !this.state.GOAL7
                                    })
                                }}></input>


                            </div>

                            <div className={[styles.set, styles.checkbox].join(' ')}>
                               

                            </div>
                        </div>
                        <div className={styles.buttonRow}>
                            <div id={styles.cancel} onClick={() => {
                                    this.props.close()
                                }
                            }>CANCEL</div>
                            <div type="submit" id={styles.submit} className={this.state.Wiggle && this.state.Wiggle !== undefined
                                    ? styles.Wiggle
                                    : ""} onClick={() => {
                                    let valid = false
                                   
                                    if ((this.state.FOCUS_AREA_PRIMARY !== '' && this.state.FOCUS_AREA_PRIMARY !== '') && (this.state.FOCUS_AREA_PRIMARY_TIER !== '' && this.state.FOCUS_AREA_PRIMARY_TIER !== null)) {
                                        if ((this.state.GOAL1 !== '' && this.state.GOAL1 !== null) || (this.state.GOAL2 !== '' && this.state.GOAL2 !== null) || (this.state.GOAL3 !== '' && this.state.GOAL3 !== null) || (this.state.GOAL4 !== '' && this.state.GOAL4 !== null) || (this.state.GOAL4 !== '' && this.state.GOAL4 !== null)) {
                                            if (this.checkFocus()) {
                                                let temp = {}
                                                for (let i = 0; i < Object.keys(this.state).length; i++) {
                                                    if (Object.keys(this.state)[i] !== "ISVALID" && (Object.keys(this.state)[i] !== "Wiggle")) {
                                                        if (Object.values(this.state)[i] !== undefined) {
                                                            if (Object.values(this.state)[i] === true || Object.values(this.state)[i] === false) {
                                                                temp[Object.keys(this.state)[i]] = Object.values(this.state)[i]
                                                                    ? 'Y'
                                                                    : null
                                                            } else {
                                                                if (temp[Object.keys(this.state)[i]] === "TOG") {
                                                                    Object.values(this.state)[i] = (Object.values(this.state)[i] === "Y" ? null : "Y")
                                                                }
                                                                temp[Object.keys(this.state)[i]] = Object.values(this.state)[i]
                                                            }

                                                        }
                                                    }
                                                }
                                                let keyholder = Object.keys(temp)
                                                let valholder = Object.values(temp)
                                                temp.keys = keyholder
                                                temp.values = valholder
                                                temp.REGIONAL_PLAN_ID = this.props.REGIONAL_PLAN_ID
                                                temp.PROF_ID = this.props.data.PROF_ID
                                                temp.id = this.props.user.id
                                                let record = this.props.data;
                                                for (let i = 0; i < keyholder.length; i++) {
                                                    if (temp.values[i] === "") {
                                                        record[`TOG_${keyholder[i]}`] = null
                                                    }
                                                    else {
                                                        record[`TOG_${keyholder[i]}`] = temp.values[i]
                                                    }
                                                }

                                                temp.keys.push("TOGGLE")
                                                temp.values.push("Y")
                                                if (parseInt(record.TOG_OWNER) === parseInt(this.props.user.id)) {
                                                    Axios('/api/regional-plan/update-toggle', temp, (data) => {
                                                        record.TOG_OWNER = this.props.user.id
                                                        record.TOG = "Y"
                                                        this.props.setData(record, this.props.number)
                                                    })
                                                } else {
                                                    Axios('/api/regional-plan/add-toggle', temp, (data) => {
                                                        record.TOG_OWNER = this.props.user.id
                                                        record.TOG = "Y"
                                                        this.props.setData(record, this.props.number)
                                                    })
                                                }
                                                valid = true
                                            }
                                        }
                                    }
                                        if (!valid) {
                                            this.setState({ Wiggle: true, ISVALID: false });
                                            setTimeout(() => {
                                                this.setState({ Wiggle: false })
                                            }, 500);
                                        } else {
                                            this.props.close()
                                        }

                                }
                            }>SUBMIT</div>
                        </div>
                    </div>
                </form>
            </div>
        </div>)
    }

}

export default EditResults;
