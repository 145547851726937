import React, {Component} from 'react';
import styles from '../css/Details_ChangeRequests.module.css';
import DetailsChangeRequestsRecord from './Details_ChangeRequests_Record'

class Details_ChangeRequests extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    firstCapital(str) {
        if (str !== undefined) {
            return str.toLowerCase().replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();})
        }
        return "";
    }

    mapStatus(status) {
        let ret = {}
        if (status === "Automatically Approved") {
            ret.status = "Approved"
            ret.comments = false
        }
        if (status === "Approved") {
            ret.status = "Approved"
            ret.comments = false
        }
        if (status === "NEEDS MR") {
            ret.status = "Open"
            ret.comments = false
        }
        if (status === "REQUEST_RCVD") {
            ret.status = "Open"
            ret.comments = true
        }
        if (status === "In Progress") {
            ret.status = "In Progress"
            ret.comments = false
        }
        if (status === "DECLINED") {
            ret.status = "Declined"
            ret.comments = true
        }
        if (status === "Rubber Stamped") {
            ret.status = "Approved"
            ret.comments = true
        }
        if (status === "Completed") {
            ret.status = "Approved"
            ret.comments = true
        }
        return ret
    }

    render() {
        let temp = [] //the returned records
        if (this.props.data) {

            let reversed = []
            if (Array.isArray(this.props.data[0].ChangeRequests)) {
                reversed = this.props.data[0].ChangeRequests.slice().reverse();
            } else {
                reversed = this.props.data[0].ChangeRequests;
            }
         //   let reversed = this.props.data.slice().reverse() //reverses the records to show latest first
            let hash = {}
            let parents = [] //array of all parent requests
            //for (let i = 0; i < reversed.length; i++) {
            //    hash[reversed[i].ACTION_ID] = {record: reversed[i], children: [], childrenRecords: []}
            //    if (!reversed[i].ACTION_NUM && !reversed[i].REQUEST_ID) {
            //        parents.push(reversed[i].ACTION_ID)
            //    }
            //}
            //for (let i = 0; i < reversed.length; i++) {
            //    if (reversed[i].ACTION_NUM) {
            //        if (hash[reversed[i].ACTION_NUM]) {
            //            hash[reversed[i].ACTION_NUM].children.push(reversed[i].ACTION_ID)
            //        }
            //    }
            //    if (reversed[i].REQUEST_ID) {
            //        if (hash[reversed[i].REQUEST_ID]) {
            //            hash[reversed[i].REQUEST_ID].children.push(reversed[i].ACTION_ID)
            //        }
            //    }
            //}
            //for (let i = 0; i < Object.keys(hash).length; i++) {
            //    let key = Object.keys(hash)[i]
            //    let element = Object.values(hash)[i]
            //    for (let j = 0; j < Object.keys(hash).length; j++) {
            //        if (i !== j) {
            //            if (element.children.includes(parseInt(Object.keys(hash)[j]))) {
            //                for (let k = 0; k < Object.values(hash)[j].children.length; k++) {
            //                    if (element.record.REQUEST_TYPE === hash[Object.values(hash)[j].children[k]].record.REQUEST_TYPE) {
            //                        hash[key] = hash[Object.values(hash)[j].children[k]]
            //                    }
            //                    else {
            //                        hash[key].children.push(Object.values(hash)[j].children[k])
            //                    }
            //                }
            //            }
            //        }
            //    }
            //    for (let j = 0; j < element.children.length; j++) {
            //        if ((!["Cancel Request", "Update Request"].includes(hash[element.children[j]].record.ORIGINAL_REQUST_TYPE)) && element.record.ORIGINAL_REQUST_TYPE.includes("HCP Update")) {
            //            if (hash[element.children[j]].pointer) {
            //                hash[key].childrenRecords.push(hash[hash[element.children[j]].pointer].record)
            //            }
            //            else {
            //                hash[key].childrenRecords.push(hash[element.children[j]].record)
            //            }
            //        }
            //    }
            //}
            //for (let i = 0; i < parents.length; i++) {
            //    if (hash[parents[i]].childrenRecords.length > 0) {
            //        temp.push(<DetailsChangeRequestsRecord key={parents[i]} odd={i % 2 === 0 ? true : false} hash={hash} data={hash[parents[i]].record} children={hash[parents[i]].childrenRecords} editScreen={this.props.editScreen}/>);
            //    }
            //    else {
            //        temp.push(<DetailsChangeRequestsRecord key={parents[i]} odd={i % 2 === 0 ? true : false} data={hash[parents[i]].record} editScreen={this.props.editScreen}/>);
            //    }
            //}
        }
        return (
            <div className={styles.ChangeRequests}>
                <div className={styles.TopBar}>Change Requests</div>
                <div className={styles.titles}>
                    <div className={styles.icon}></div>
                    <div className={styles.Title}>ACTION ID</div>
                    <div className={styles.Title}>STATUS</div>
                    <div className={styles.Title}>REQUEST DATE</div>
                    <div className={styles.Title}>REQUEST TYPE</div>
                    <div className={styles.Title}>REQUESTOR NAME</div>
                    <div className={styles.Title}>COMMENTS</div>
                    <div className={styles.Title}>LAST MODIFIED DATE</div>
                </div>
                <div className={styles.ResultsWrapper}>
                    
                        <div className={styles.Results}>
                            <div className={[styles.Odd, styles.record].join(" ")}>
                                <div className={styles.icon}></div>
                                <div className={styles.Content}>—</div>
                                <div className={styles.Content}>—</div>
                                <div className={styles.Content}>—</div>
                                <div className={styles.Content}>—</div>
                                <div className={styles.Content}>—</div>
                                <div className={styles.Content}>—</div>
                                <div className={styles.Content}>—</div>
                            </div>
                        </div>
 
                </div>
            </div>
        );
    }
}

export default Details_ChangeRequests;
