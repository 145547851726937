import React, { Component } from 'react'
import Styles from './WorkflowDialogBox.module.css'
import WorkflowDialogField from './WorkflowDialogField'
import WorkflowDialogSummaryItem from './WorkflowDialogSummaryItem'
import WorkflowConfirmationDialog from './WorkflowConfirmationDialog'
import Axios from '../../components/Axios'

import axios from 'axios'

class WorkflowDialogBox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentScreenIndex: 1,
            prevScreenIndex: 1,
            nextScreenIndex: 1,
            IsSummaryScreen: false,
            is_submitted: false,
            maxScreenIndex: 1,
            dialogtitle: '',
            screentitle: '',
            fields: [],
            values: [],
            wfdata_ProgramName: '',
            files: [],
            fileNames: [],
            showConfirmation: false,
            confDialog: '',
            userEmail: '',
            is_edit: this.props.is_edit,
            error: '',
            isSAP: false,
            hasFileEdits: false,
            Tin: this.props.tin
        };

        this.EnableAddressEdit = this.EnableAddressEdit.bind(this);
        this.funcMap = {
            'EnableAddressEdit': this.EnableAddressEdit

        };
    }
    componentDidMount() {
        let json = {};
        json.user = this.props.user;
        json.org_id = this.props.org_id;
        json.is_edit = this.props.is_edit;
        json.instance_id = this.props.id;
        var isSap = false;

        if (this.props.isSAP && this.props.isSAP == true) {
            isSap = true;
        }

        let confDialog =
            (<WorkflowConfirmationDialog org_id={this.props.org_id} title={'Event Submitted'} firstline={'The E&D event  has been submitted for approval.'} secondline={''} close={this.closeConf.bind(this)} approver={this.props.user} />)

        Axios('/api/workflow/get-latest-workflow', json, (data) => {


            this.setState({ data: data, confDialog: confDialog, userEmail: this.props.user, isSAP: isSap });

            this.setDefaults(data);

            this.buildUI(this.state.currentScreenIndex, false, false, true);

            
        });


        Axios('/api/workflow/get-system-settings', { }, (systemdata) => {

            this.setState({ test_mode: systemdata.IS_ACTIVE, test_email: systemdata.TEST_EMAIL, email_on : systemdata.EMAIL_ON });

            //this.state.test_mode = systemdata.IS_ACTIVE;
            //test_email = systemdata.TEST_EMAIL;
            //test_user = systemdata.TEST_USER;
            //email_on = systemdata.EMAIL_ON;
        });

    }
    setDefaults(data) {

        var fileArr = [];

        data.Nodes.forEach(node => {
            node.Fields.forEach(field => {
                if (field.DefaultValue && field.DefaultValue != '') {
                    this.setState({ [field.FieldName]: field.DefaultValue });
                }

                //if (field.DataType == 'datetime') {
                //    this.setState({ [field.FieldName]: new Date() });
                //}
            })

        });
        

    }
    buildUI(idx, wiggle, overideDisabled, addDefaultFiles) {
        var fields = [];
        var wf = this.state.data;

        var title = wf.WorkflowName;
        var screenTitleTemp = '';
        var next = 1;
        var prev = 1;
        var wiggleTemp = false;
        var controlVal;
        var currentIndex = idx;
        var isSummaryScreen = false;
        var isRequired = false;
        var isDisabled = false;
        var pn = this.state["wfdata_ProgramName"];

        var fileArr = [];
        var fileDisplay = [];
        var fileNames = [];

        fileArr = this.state.files;

        // alert( ' overideDisabled: ' + overideDisabled);
        if (addDefaultFiles) {
            
            if (wf.Files) {
                wf.Files.forEach((element) => {

                    var object = { "title": element.WORKFLOW_FILE_NAME, "name": element.FILE_NAME, "file": null }

                    fileArr.push(object);

                });
            }
            

        }

        wf.Nodes.forEach(node => {

            if (idx == node.NodeIndex) {
                screenTitleTemp = node.NodeName;
                isRequired = false;
                prev = node.PrevIndex;
                next = node.NextIndex;
                var arrFileNames = [];
         

                if (node.IsSummaryScreen) {
                    isSummaryScreen = true
                } else {
                    node.Fields.forEach(field => {

                        controlVal = this.state[field.FieldName];

                        if (field.DataType == "fileuploadgroup") {


                           // var arrTemp = [];
                           // arrTemp = this.state.files;


                           

                           //arrTemp.forEach((element) => {
                           //     var object = { "title": element.title, "name": element.name, "file": element.file }
                           //   //  fileDisplay.push(<div className={Styles.filerow}> <div className={Styles.fileleft}>{element.WORKFLOW_FILE_NAME} </div> <div className={Styles.fileright}>{element.FILE_NAME}</div><br /></div>);
                           //     fileArr.push(object);

                           // });
                            
                           

                           
                        }
                        

                        isRequired = field.IsRequired;
                        isDisabled = field.IsDisabled;

                        if (overideDisabled) {
                            isDisabled = false;
                        }

                        fields.push(<WorkflowDialogField
                            pn={pn}
                            disabled={isDisabled}
                            field={field}
                            key={field.FieldName}
                            action={this.callFieldAction.bind(this)}
                            program_name={this.title}
                            required={isRequired}
                            setValue={this.setControlValue.bind(this)}
                            setDateValue={this.setDateValue.bind(this)}
                            setCheckValue={this.setCheckValue.bind(this)}
                            setUploadFile={this.setUploadFile.bind(this)}
                            value={controlVal}
                            fileArr={fileArr}
                           
                        />);

                    })

                }

            }

        })

        if (isSummaryScreen) {
            var name = "";
            var value = "";
            var isSap = "false";

            if (this.props.isSAP && this.props.isSAP == true) {
                isSap = "true";
            }

          //  fields.push(<WorkflowDialogSummaryItem key={'ISSAP'} name={'IS SAP'} value={isSap} datatype={'text'} />);

            wf.Nodes.forEach(node => {
                node.Fields.forEach(field => {

                    if (field.DataType == 'datetime') { value = new Date(this.state[field.FieldName]).toLocaleDateString('en-US') } else { value = this.state[field.FieldName]; }

                    if (field.DataType != 'fileupload' && field.DataType != 'fileuploadgroup') {
                        fields.push(<WorkflowDialogSummaryItem key={field.FieldName} name={field.FieldTitle} value={value} datatype={field.DataType} />);
                    }

                })

            })



            var fileArr = this.state.files;
            var stringarr = "";
            fileArr.forEach((node) =>  {

               // console.log("s");
                fields.push(<WorkflowDialogSummaryItem key={node.name} name={node.name} value={node.name} />);

            });


        }

        this.setState({ fields: fields, dialogtitle: title, screentitle: screenTitleTemp, currentScreenIndex: idx, nextScreenIndex: next, prevScreenIndex: prev, IsSummaryScreen: isSummaryScreen, files: fileArr });
    }
    movePrev() {

        var idx = this.state.currentScreenIndex;
        var previdx = this.state.prevScreenIndex;

        if (idx != previdx && idx != 1) {
            this.buildUI(previdx, false, false, false);
        }
    }
    moveNext() {
        var idx = this.state.currentScreenIndex;
        var nextidx = this.state.nextScreenIndex;

        var isValid = false;
        isValid = this.validateFields();

        if (isValid) {

            if (idx != nextidx) {

                this.buildUI(nextidx, false, false, false);


            }

            // this.buildUI(nextidx, false);
        }
        else {
            this.buildUI(idx, true, false, false);
        }


    }
    closeConf() {

        this.setState({ showConfirmation: false });
        this.props.close();
    }
    submit() {

        let post_json = {};
        var jsonString = this.state;
        let arr = [];
          
        let test_mode = this.state.test_mode;
        let test_email = this.state.test_email;
        
        let email_on = this.state.email_on;

      
        Object.keys(this.state).map(key => {

            let valString = '';

            if (key.startsWith('wfdata_')) {

                if (key.startsWith('wfdata_Program') && test_mode) {
                    valString = this.state[key] + ' **TEST**';
                } else {
                    valString = this.state[key];
                }
                arr.push(key + "=" + valString);
                
            }

        })

        var tinNew = this.state["wfdata_TIN"];
        var tinOld = this.state.Tin;

        let startDate = new Date(this.state["wfdata_StartDT"]);
        let endDate = new Date(this.state["wfdata_EndDT"]);
        let orgname = this.state["wfdata_OrgName"];
        let uname = this.props.user.email;
        let programname = '';


        if (test_mode === true) {
            programname = this.state["wfdata_ProgramName"] + ' **TEST**';
        }
        else {
            programname = this.state["wfdata_ProgramName"];
        }

        let amt = this.state["wfdata_Amount"];

       
       
        post_json.user = this.props.user;
        post_json.Json = arr.join("|");

        post_json.approvaljson = '';

        post_json.OrgName = orgname;
        post_json.OrgID = this.props.org_id;
        
        post_json.ProgramName = programname;
        post_json.Amount = amt;
        post_json.PaymentMethod = this.state["wfdata_PaymentMethod"];
        post_json.StartDT = startDate;
        post_json.EndDT = endDate;
        post_json.WFID = this.props.id;
        post_json.IsEdit = this.state.is_edit;

        var editRemit = this.state["wfdata_EditRemit"];
        post_json.IsRemitAddress = editRemit;
        post_json.IsSAP = this.state.isSAP ? 1 : 0;
        post_json.TIN = tinNew;
        


        Axios('/api/workflow/submit-workflow', post_json, (data) => {

            var fileArr = this.state.files;

            var formattedStartDT = new Date(post_json.StartDT).toLocaleDateString();
            var formattedEndDT = new Date(post_json.StartDT).toLocaleDateString();

            const formData = new FormData();

            formData.append('wfid', data);
            formData.append('orgid', this.props.org_id);
          
            var onefile = false;

            fileArr.forEach((file) => {

                if (file.file != null) {
                    onefile = true;
                    var filename = file.name;
                    formData.append('file', file.file, filename);
                }

            });
            if (onefile == true) {
                axios.post('/api/workflow/upload_workflow_files', formData)
                    .then(function (response) { })
                    .catch(function (response) { });
            }
           
            let approver_json = {};
            approver_json.workflow_id = data;
            approver_json.user = this.props.user;

            axios.post('/api/workflow/get-next-two-approvers', approver_json)
                .then(function (approverData) {

                    let email_json = {};

                    let approverName = approverData.data.current_approver_name;
                    let approverEmail = approverData.data.current_approver_email;
                    let requestor = approverData.data.requestor_email;
                 

                    let sysNote = "<br/><p><i><b>Note:</b> This is system generated mail. Please do not reply.  If you require any further assistance, please email exhibits.displays@incyte.com.</i></p>";
                    let wflink = "<br/><p>Click on the following link to access the application: <a href='https://incyte-mdm-portal.bellcyn.com/workflow?id={data}'>Exhibits & Displays Approval.</a></p>";

                    email_json.to = approverEmail;
                    email_json.subject = "An exhibit and display request has been submitted";

                    email_json.test_mode = test_mode;
                    email_json.test_email = test_email;
                    email_json.email_on = email_on;
       
                    email_json.body =  "<p>An exhibit and display request has been submitted from " + requestor + " that requires Your approval.  Please review and respond within three business days. </p>" +
                        "<br /> <b>ORG NAME: </b>" +
                        orgname + "<br /> <b>PROGRAM NAME: </b> " +
                        programname + "<br /> <b>AMOUNT: </b> $" +
                        amt + "<br /> <b>DATE: </b> " +
                        formattedStartDT + " - " +
                        formattedEndDT + "<br /><br />" + wflink + sysNote;

                    axios.post('/api/workflow/send-email', email_json)
                        .then(function (response) {


                        })
                        .catch(function (response) {

                            //  alert(response);
                        });

                    console.log('email sent' + approverEmail);

                })
                .catch(function (response) { });

            if (editRemit == "true" || !this.state.isSAP ) {

                let ops_json = {};
                ops_json.team = this.props.user.team;
                let optionalTXT = "";
                
                if (editRemit == "true") {
                    optionalTXT = "This exhibit was outside of the Incyte domain and the remit address was effected.";

                }

                if (!this.state.isSAP) {
                    optionalTXT = optionalTXT + "  This exhibit was outside of the Incyte SAP domain.";

                }

                axios.post('/api/workflow/get-market-ops', ops_json)
                    .then(function (opsData) {

                        let email_jsonOPS = {};
                        let email_jsonED = {};

                        let approverNameOPS = opsData.data.market_ops_name;
                        let approverEmailOPS = opsData.data.market_ops_email;

                        email_jsonOPS.test_mode = test_mode;
                        email_jsonOPS.test_email = test_email;
                        email_jsonOPS.email_on = email_on;

                        email_jsonOPS.to = approverEmailOPS;
                        email_jsonOPS.subject = "An exhibit and display request has been submitted & requires attention";
                        email_jsonOPS.body = "<p>An exhibit and display request has been submitted that requires your review. Please review and respond within three business days. </p>" +
                            "<br /><p>" + optionalTXT + " </p>" +
                            "<br /> <b>E&D Requestor: </b>" + uname +
                            "<br /> <b>ORG NAME: </b>" + orgname +
                            "<br /> <b>PROGRAM NAME: </b>" + programname +
                            "<br /> <b>AMOUNT: </b> $" + amt + "<br /> <b>DATE: </b> " +
                            formattedStartDT + " - " + formattedEndDT + "<br /><br />" +
                            "<br/><p><i><b>Note:</b> This is system generated mail. Please do not reply.  If you require any further assistance, please email exhibits.displays@incyte.com.</i></p>" +
                            "<br/><p>Click on the following link to access the application: <a href='https://incyte-mdm-portal.bellcyn.com/workflow?id={data}'>Exhibits & Displays Approval.</a></p>";

                        console.log('email sent' + approverEmailOPS);

                        axios.post('/api/workflow/send-email', email_jsonOPS)
                            .then(function (responseOPS) {


                            })
                            .catch(function (responseOPS) {

                                //  alert(response);
                            });
                       

                        email_jsonED.to = "exhibits.displays@incyte.com";
                        email_jsonED.subject = "An exhibit and display request has been submitted & requires attention";

                        email_jsonED.test_mode = test_mode;
                        email_jsonED.test_email = test_email;
                        email_jsonED.email_on = email_on;

                        email_jsonED.body = "<p>An exhibit and display request has been submitted that requires your review. Please review and respond within three business days. </p>" +
                            "<br /><p>" + optionalTXT + " </p>" +
                            "<br /> <b>E&D Requestor: </b>" + uname +
                            "<br /> <b>ORG NAME: </b>" + orgname +
                            "<br /> <b>PROGRAM NAME: </b>" + programname +
                            //"<br /> <b>AMOUNT: </b> $" + amt + "<br /> <b>DATE: </b> " +
                            formattedStartDT + " - " + formattedEndDT + "<br /><br />" +
                            "<br/><p><i><b>Note:</b> This is system generated mail. Please do not reply.  If you require any further assistance, please email exhibits.displays@incyte.com.</i></p>" +
                            "<br/><p>Click on the following link to access the application: <a href='https://incyte-mdm-portal.bellcyn.com/workflow?id={data}'>Exhibits & Displays Approval.</a></p>";


                        axios.post('/api/workflow/send-email', email_jsonED)
                            .then(function (responseOPS) {


                            })
                            .catch(function (responseOPS) {

                                //  alert(response);
                            });

                        console.log('email sent exhibits.displays@incyte.com');

                    })
                    .catch(function (responseOPS) { });
            }
         
            this.setState({ showConfirmation: true });
    

        });

       

   
        if (tinNew !== tinOld ) {

                let json = {}

                json.REQUEST_TYPE = "Update HCO TIN";
                json.REQUESTOR_NAME = this.props.user.userName;
                json.PROF_ID = '';
                json.REQUESTOR_TEAM = this.props.user.team;
                json.REQUEST_NOTE = ''  //this.state.REQUEST_NOTE''
                json.TIN = tinNew;
                json.ORG_NAME = this.state["wfdata_OrgName"];
                json.ORG_ID = this.props.org_id;
                Axios('/api/global/change-request', json, (data) => { })

        }


    }
    setControlValue(target, action, disabled) {

        this.setState({ [target.name]: target.value });

        if (action) {
            this.callFieldAction(action, target);
        }


    }
    setCheckValue(target, action, value) {

        var stringValue = 'false';
        if (value) {
            stringValue = 'true';
        }

        this.setState({ [target.name]: stringValue });

        if (action) {
            this.callFieldAction(action, target);
        }


    }
    setDateValue(name, date) {

        this.setState({ [name]: date });

    }
    setUploadFile(title, file, filename) {

        var alreadyExists = false;
        var new_object = { "title": title.toUpperCase(), "name": filename, "file": file }
 
        var fileArr = this.state.files;
        var newArr = [];
        //var _hasFileEdits = false;

        fileArr.forEach((temp, i) => {
          
            if (title.toUpperCase() == temp.title.toUpperCase() ) {
                //already exists
                 alreadyExists = true;
                newArr.push(new_object);
            } else {
                var object = { "title": temp.title, "name": temp.name, "file": temp.file }
                newArr.push(object);
            }

           


        });

        if (!alreadyExists) {
            newArr.push(new_object);
        }

        //    if (this.state.is_edit) {

        //       // _hasFileEdits = true;
        //      //  newArr.push(object);
                
        //    }

        //} else {

        //    fileArr.push(new_object);
            

        //}
        this.setState({ files: newArr, ['wffile_' + filename]: file.name});
        return newArr;

    }
    EnableAddressEdit(target) {

        this.setState({ overrideDisabled: true });

        this.buildUI(this.state.currentScreenIndex, false, target.checked, false);

    }
    callFieldAction(ActionName, target) {
        var content;
        const method = this.funcMap[ActionName];
        if (typeof method === 'function') {
            content = method(target);
        }
        return content;
    }
    exportData(id) {

    }


    validateFields() {

        var wf = this.state.data;
        var idx = this.state.currentScreenIndex;
        var title = wf.WorkflowName;
        var err = '';
        var isValid = true;

        //refactor this
        wf.Nodes.forEach(node => {

            node.Fields.forEach(field => {


                if (node.NodeIndex == idx) {


                    //alert(err);

                    if (field.DataType == 'fileuploadgroup') {

                        var fileArr = this.state.files;
                        var foundIt = false;

                        fileArr.forEach((file) => {

                            if (file.title == "W9") {
                                foundIt = true;
                            }
                        });

                        isValid = foundIt;

                        if (!isValid) { err = 'W9 is required.'; }
                    }
                    if (field.FieldName == "wfdata_StartDT") {

                        var startval = this.state["wfdata_StartDT"];

                        if (startval == undefined) {
                            isValid = false;
                        }

                        if (!isValid) { err = 'The Start and End Dates are required'; }
                    }
                    if (field.FieldName == "wfdata_EndDT") {

                        var endval = this.state["wfdata_EndDT"];

                        if (endval == undefined) {
                            isValid = false;
                        }

                        if (!isValid) { err = 'The Start and End Dates are required.'; }
                    }
                    if (field.FieldName == "wfdata_EndDT") {

                       

                        var startval = this.state["wfdata_StartDT"];
                        var endval = this.state["wfdata_EndDT"];

                        var ed = new Date(endval);
                        var sd = new Date(startval);

                        if (ed < sd) {

                            err = 'The End Date cannot be before Start Date.';
                            isValid = false;
                        }
                        else {

                        }


                    }

                    if (field.FieldName == "wfdata_TIN") {

                        var tinVal = this.state["wfdata_TIN"];


                        if (tinVal.toString().length != 9) {
                            isValid = false;
                        }

                        if (!isValid) { err = 'TIN is required and must be 9 characters'; }
                    }

                    if (field.FieldName == "wfdata_OtherSpecialty") {

                        var value1 = this.state["wfdata_Specialty"];
                        var value2 = this.state["wfdata_OtherSpecialty"];


                        if (value1 == "Other Specialty" && (value2 == undefined) || value2 == "") {

                            isValid = false;
                            err = 'Please specify the other specialty'
                        }
                    }
                    if (field.FieldName == "wfdata_Levels") {

                        var value1 = this.state["wfdata_Levels"];
                        var value2 = this.state["wfdata_LevelExplaination"];


                        if (value1 == 'true' && value2 == undefined) {

                            isValid = false;
                            err = 'Please provide an explanation for the various levels.'
                        }
                    }
                    if (field.FieldName == "wfdata_ContactPhone" ) {

                        var phone = this.state["wfdata_ContactPhone"];
                      

                       

                        if (phone != undefined && phone != "") {
                            if (!(phone.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im))) {

                                isValid = false;
                                err = 'Please provide Contact Phone in the correct format'
                            }

                        } else {
                            isValid = false;
                            err = 'Please provide a Contact Phone.'

                        } 
                    }
                    if (field.FieldName == "wfdata_ContactEmail") {

               
                        var email = this.state["wfdata_ContactEmail"];

                        if (email != undefined && email != "") {
                            if (!(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email))) {
                                isValid = false;
                                err = 'Please provide Contact Email in the correct format'
                            }
                        }

                    }
                    if (field.IsRequired) {


                        var value = this.state[field.FieldName];

                        if (field.DataType == 'datetime') {

                            //if (Object.prototype.toString.call(value) === "[object Date]") {


                            //    if (!isNaN(value.getTime())) {

                            //        // date is valid
                            //    } else {
                            //        isValid = false;
                            //    }


                            //} else {

                            //    isValid = false;
                            //}
                        }
                        else {

                            if (value == undefined || value == null || value == '') {

                                isValid = false;
                            }
                        }


                    }




                }





            })

        });

        var Amount = this.state["wfdata_Amount"];
        var PaymentMethod = this.state["wfdata_PaymentMethod"];

        if (Amount > 10000 && PaymentMethod == "Credit") {
            // alert("Payment Method must be CHECK for all amounts above $10,000");
            err = 'Payment Method must be CHECK for all amounts above $10,000';
            isValid = false;

        }

        this.setState({ error: err });
        return isValid;

    }



    render() {

        return (

            <div className={Styles.editScreen}>
                <div className={Styles.title}>
                    <div></div>
                    <div className={Styles.TitleText}>{this.state.dialogtitle}</div>
                    <div className={Styles.close} onClick={() => {
                        this.props.close()
                    }}><i className="fas fa-times"></i></div>
                </div>
                <div className={Styles.body}>

                    <div className={Styles.screenTitle}>{this.state.screentitle}</div>

                    {this.state.showConfirmation ? this.state.confDialog : ''}
                    <div className={this.state.IsSummaryScreen ? Styles.red : Styles.blue}>

                        {this.state.fields}

                    </div>
                    <div className={Styles.error}>{ this.state.error}</div>

                </div>
                <div className={Styles.buttonRow}>
                    <div id={Styles.submit} onClick={() => {
                        this.props.close();
                    }}>CANCEL</div>
                    <div id={this.state.prevScreenIndex == this.state.currentScreenIndex ? Styles.disable : Styles.submit} onClick={() => {
                        this.movePrev();
                    }}>PREV</div>
                    <div id={this.state.nextScreenIndex > this.state.currentScreenIndex ? Styles.submit : Styles.disable} onClick={() => {
                        this.moveNext();
                    }}>NEXT</div>
                    <div id={this.state.IsSummaryScreen || this.state.nextScreenIndex == this.state.currentScreenIndex ? Styles.submit : Styles.disable} onClick={() => {

                        if (this.state.IsSummaryScreen && this.state.is_submitted == false) {
                            this.setState({ is_submitted: true });
                            this.submit();
                        }
                        



                    }}>SUBMIT</div>
                </div>
            </div>


        )
    }

}
export default WorkflowDialogBox;







