import React, {useState, useEffect} from 'react'
import styles from './../css/ChangeTargetDrop.module.css'
import Edit from './EditTarget'
import Axios from '../../../../components/Axios'

function ChangeTargetDrop(props) {

    const [edit, setEdit] = useState(false)
    const [targetType, setTargetType] = useState(props.data.TARGET_TYPE || "")
    const [reason, setReason] = useState(props.data.REASON || "")
    const [notes, setNotes] = useState(props.data.NOTES || "")
    const [tempTarget, setTempTarget] = useState()

    useEffect(() => {
        setTargetType(targetType !== props.data.CURRENT_TARGET_VALUE ? targetType : "")
    }, [])

    const submit = (target, reasons) => {
        let temp = {
            ...reasons,
            ID: props.data.TARGETING_EXERCISE_ID,
            terr: props.terr,
            type: props.exercise.CUSTOMER_TYPE,
            mdmID: (props.user.role === "ADMIN" ? props.adminExercise.CUSTOMER_TYPE : props.exercise.CUSTOMER_TYPE === "HCP") ? props.data.PROF_ID : props.data.ORG_ID,
            target: target !== "" ? target : props.data.CURRENT_TARGET_VALUE,
            oldTarget: targetType !== "" ? targetType : props.data.CURRENT_TARGET_VALUE,
            user: props.user,
            role: props.user.role,
            username: props.user.userName
        }
        if (props.data.TARGET_TYPE || targetType) {
            Axios('/api/targeting/update-target-type', temp, (data) => {
                if (reasons) {
                    setReason(reasons.reason)
                    setNotes(reasons.notes)
                }
                setTargetType(target)
                props.data.TARGET_TYPE = target
                setEdit(false)
                props.getTotals()
            })
        }
        else {
            Axios('/api/targeting/add-target-type', temp, (data) => {
                if (reasons) {
                    setReason(reasons.reason)
                    setNotes(reasons.notes)
                }
                setTargetType(target)
                props.data.TARGET_TYPE = target
                setEdit(false)
                props.getTotals()
            })
        }
    }

    const close = () => {
        setTempTarget(null)
        setEdit(false)
    }

    let popup = ""
    let placeholder = ""
    let editIcon = ""
    if (edit) {
        popup = <Edit {...props} targetType={tempTarget || targetType} reason={reason} notes={notes} success={true} successMessage={"Test"} submit={submit} close={close}/>
    }
    if (targetType  > props.data.CURRENT_TARGET_VALUE) {
        placeholder = (
            <>
                <div className={styles.placeholder}></div>
                <div className={styles.placeholder}></div>
            </>
        )
        editIcon = (
            <>
                <div className={styles.placeholder}><div className={styles.line}></div></div>
                <div className={styles.edit}>
                    <i className={"far fa-edit"} onClick={() => {
                        setEdit(true)
                    }}></i>
                </div>

            </>
        )
    }
    return (
        <div>
            {popup}
            <div className={styles.changeTargetDrop}>
                {placeholder}
                <select className={[styles.dropdown, styles[targetType]].join(" ")} value={targetType} onChange={(event) => {
                        if (props.data.CURRENT_TARGET_VALUE !== "E") {
                            if (event.target.value > props.data.CURRENT_TARGET_VALUE) {
                                setEdit(true)
                                setTempTarget(event.target.value)
                            }
                            else {
                                setTargetType(event.target.value)
                                submit(event.target.value, {})
                            }
                        }
                        else if (props.data.CURRENT_TARGET_VALUE === "E") {
                            if ((event.target.value > props.data.CURRENT_TARGET_VALUE) && event.target.value !== "NT") {
                                setEdit(true)
                                setTempTarget(event.target.value)
                            }
                            else if (event.target.value.includes("NT")) {
                                props.updateError()
                            }
                            else {
                                setTargetType(event.target.value)
                                submit(event.target.value, {})
                            }
                        }
                    }} disabled={props.lock}>

                    {props.data.CURRENT_TARGET_VALUE === "E"
                        ? <><option value={""}></option><option value={"A"}>A</option><option value={"B"}>B</option><option value={"C"}>C</option></>
                        : <><option value={""}></option><option value={"A"}>A</option><option value={"B"}>B</option><option value={"C"}>C</option><option value={"NT"}>NT</option></>
                    }
               
                </select>
                {editIcon}
            </div>
        </div>
    )
}

export default ChangeTargetDrop
