import React, {useEffect} from 'react'
import styles from './TopBar.module.css'
import states from '../../lookup/states.json'

function SearchBar(props) {
    const search = () => props.search(props.searchParams)
    const clear = () => props.clear()
    
    useEffect(() => {
        if (props.loaded) {
            search()
        }
    }, [props.loaded])

    if (!props.search) {
        return null
    } else {
        let stateOptions = [<option key="000" value={''}></option>]
        for (let i = 0; i < Object.keys(states).length; i++) {
            stateOptions.push(<option key={Object.keys(states)[i]} value={Object.keys(states)[i]}>{Object.values(states)[i]}</option>)
        }
        return (
            <form className={styles.search}>
                <div className={styles.filter}>
                    <i className={["fas", "fa-sliders-h", styles.Icon].join(' ')}></i>
                </div>

                {props.children}

                <div className={styles.placeholder}></div>
                <div className={[styles.set].join(' ')}>
                    <button id={styles.searchButton} type="submit" onClick={(event) => {
                            event.preventDefault()
                            search()
                        }}>SEARCH</button>
                </div>
                <div className={styles.Line}></div>
                <div className={[styles.set, styles.clearButton].join(' ')}>
                    <button id={styles.clearButton} type="button" onClick={(event) => {
                            event.preventDefault()
                            clear()
                        }}>CLEAR ALL</button>
                </div>
            </form>
        )
    }
}

export default SearchBar