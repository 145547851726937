import React from 'react'
import styles from './../css/StatusBox.module.css'
import ColorBox from './ColorBox'

function StatusBar(props) {
    return (
        <div className={[styles.box, styles.left].join(' ')}>
            <ColorBox title={props.title}/>
            <div className={styles.setmiddle}>
                <div className={styles.key}>MOVE IN</div>
                <div className={[styles.value, styles.green].join(" ")}>+{props.moveIn}</div>
            </div>
            <div className={styles.setmiddle}>
                <div className={styles.key}>MOVE OUT</div>
                <div className={[styles.value, styles.red].join(" ")}>-{props.moveOut}</div>
            </div>
        </div>
    )
}

export default StatusBar
