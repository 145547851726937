import React, {useContext, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {RootStoreContext} from '../../../../_stores/rootStore';
import {observer} from "mobx-react";
import Styles from "./AddressAlignment.module.css";
import Loading from '../../../../globalcomponents/Loading';

const AddressAlignment = (props) => {
    const rootStore = useContext(RootStoreContext);
    const { user } = rootStore.userStore;
    const { profStore } = rootStore;
    const { loading, addressAlignment, alignment } = profStore;
    const { PROF_ID } = useParams();

    var lut = []; for (var i = 0; i < 256; i++) { lut[i] = (i < 16 ? '0' : '') + (i).toString(16); }
    function generateGuid() {
        var d0 = Math.random() * 0xffffffff | 0;
        var d1 = Math.random() * 0xffffffff | 0;
        var d2 = Math.random() * 0xffffffff | 0;
        var d3 = Math.random() * 0xffffffff | 0;
        return lut[d0 & 0xff] + lut[d0 >> 8 & 0xff] + lut[d0 >> 16 & 0xff] + lut[d0 >> 24 & 0xff] + '-' +
            lut[d1 & 0xff] + lut[d1 >> 8 & 0xff] + '-' + lut[d1 >> 16 & 0x0f | 0x40] + lut[d1 >> 24 & 0xff] + '-' +
            lut[d2 & 0x3f | 0x80] + lut[d2 >> 8 & 0xff] + '-' + lut[d2 >> 16 & 0xff] + lut[d2 >> 24 & 0xff] +
            lut[d3 & 0xff] + lut[d3 >> 8 & 0xff] + lut[d3 >> 16 & 0xff] + lut[d3 >> 24 & 0xff];
    }

    useEffect(() => {
        addressAlignment(PROF_ID, user);
    }, [PROF_ID, profStore])

    let columnHeaders = []
    let rowValues = []

    if (!!alignment) {

        var o = Object.fromEntries(Object.entries(alignment).filter(([_, v]) => v != null));

        var rows = Object.values(o);

        if (rows.length > 0) {

            var cols = Object.keys(rows[0]);

            if (cols.length > 0) {
                for (let i = 0; i < cols.length; i++) {

                    var col = cols[i];
                    if (cols[i] !== 'isDermTeam') {
                        columnHeaders.push(
                            <div key={i} className={Styles.Title}>{col.toUpperCase()}</div>
                        )
                    }
                }
            }

            for (let i = 0; i < rows.length; i++) {

                if (['ADMIN'].includes(user.role) || ['SPHASE'].includes(user.role)) {
                    rowValues.push(
                        <div key={generateGuid()} className={Styles.Results}>
                            <div key={generateGuid()} className={i % 2 == 0 ? Styles.Odd : Styles.Content}>{rows[i].TEAM}</div>
                            <div key={generateGuid()} className={i % 2 == 0 ? Styles.Odd : Styles.Content}>{rows[i].TERRITORY}</div>
                            <div key={generateGuid()} className={i % 2 == 0 ? Styles.Odd : Styles.Content}>{rows[i].MANAGER}</div>
                            <div key={generateGuid()} className={i % 2 == 0 ? Styles.Odd : Styles.Content}>{rows[i].ADDRESS}</div>
                        </div>

                    )
                } else {

                    rowValues.push(
                        <div key={generateGuid()} className={Styles.Results}>
                            <div key={generateGuid()} className={i % 2 == 0 ? Styles.Odd : Styles.Content}>{rows[i].TEAM}</div>
                            <div key={generateGuid()} className={i % 2 == 0 ? Styles.Odd : Styles.Content}>{rows[i].TERRITORY}</div>
                            <div key={generateGuid()} className={i % 2 == 0 ? Styles.Odd : Styles.Content}>{rows[i].MANAGER}</div>
                        </div>

                    )
                }

                
            }

        }
    }

    if (loading) {
        return (
            <div className={Styles.PriAddress}>
                <div className={Styles.TopBar}>Address Alignment</div>
                <Loading />
            </div>
        ) 
    }
    else {
        return (
            <div className={Styles.PriAddress}>
                <div className={Styles.TopBar}>Address Alignment</div>
                <div className={Styles.titles}>
                    {columnHeaders}
                </div>
                <div className={Styles.ResultsWrapper}>
                    {rowValues}
                </div>
            </div>
        )
    }
}

export default observer(AddressAlignment);