import React, {Component} from 'react';
import styles from '../css/Details_Edit.module.css';
import DetailsEditContact from './Details_Edit_Contact';
import DetailsEditDetails from './Details_Edit_Details';
import DetailsEditPriAddress from './Details_Edit_PriAddress';
import DetailsEditAffiliations from './Details_Edit_Affiliations';
import DetailsEditIdentifiers from './Details_Edit_Identifiers';
import Popup from '../../../globalcomponents/Popup';
import AddHCOSuccess from './AddHcoSuccess';
import ChangeRequest from '../../ChangeRequests/ChangeRequest';

class Details_Edit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data
        };
    }

    render() {
        let edit = ""
        if (this.props.index === 1) {
            edit = <DetailsEditDetails user={this.props.user} data={this.state.data} submit={this.props.submit} close={this.props.close} />
        }
        else if (this.props.index === 2) {
            edit = <DetailsEditContact user={this.props.user} data={this.state.data} submit={this.props.submit} close={this.props.close} />
        }
        else if (this.props.index === 3) {
            edit = <DetailsEditPriAddress user={this.props.user} data={this.state.data} submit={this.props.submit} close={this.props.close} />
        }
        else if (this.props.index === 4) {
            edit = <DetailsEditAffiliations user={this.props.user} data={this.state.data} submit={this.props.submit} close={this.props.close} />
        }
        else if (this.props.index === 5) {
            edit = <DetailsEditIdentifiers user={this.props.user} data={this.state.data} submit={this.props.submit} close={this.props.close} />
        }
        else if (this.props.index === 7) {
            edit = <DetailsEditContact user={this.props.user} data={this.state.data} submit={this.props.submit} close={this.props.close} />
        }
        else if (this.props.index === 9) {
            edit = <DetailsEditAffiliations affiliations={this.props.affiliations} data={this.state.data} submit={this.props.submit} close={this.props.close} user={this.props.user}/>
        }
        else if (this.props.index === 10) {
            if (this.props.id) {
                edit = <ChangeRequest callback={this.props.setChange} next={this.props.change[this.props.id]} user={this.props.user} data={this.props.data} close={this.props.close} details={true} id={this.props.id}/>
            }
            else {
                edit = <ChangeRequest callback={this.props.setChange} next={this.props.change} user={this.props.user} data={this.props.data} close={this.props.close} details={true}/>
            }

        }
        if (parseInt(this.props.index) === parseInt(11)) {
            edit = <AddHCOSuccess close={this.props.close} />
        }
        return (
            <div className={styles.editScreen}>
                <Popup component={edit} />
            </div>
        )
    }

}

export default Details_Edit;
