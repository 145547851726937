import React, { Component } from 'react'
import Styles from './NewCallCaptureDialog.module.css'
import Axios from '../../components/Axios'
import DatePicker from 'react-datepicker';

import axios from 'axios'
import { Checkbox } from 'semantic-ui-react';

class NewCallCaptureDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            affiliations: null,
            selected_affiliation_ids: [],
            alignment: '',
            isHCP: this.props.is_hcp,
            mdm_id: this.props.mdm_id,
            orgname: this.props.org_name,
            contactname: '',
            contacttitle: '',
            contactphone: '',
            contactmail: '',
            contacts: [],
            contact_is_odd: false,
            err: '',
            dtvalue: '',
            discussionTopic: '',
            contactMethod: '',
            contact_arr: [],
            user_name: this.props.user,
            terrid: this.props.terrid,
            terrname: this.props.terrname

        };


       
    }
    setAffiliation(id, isRemove) {
        
        var arr = this.state.selected_affiliation_ids;

        if (isRemove) {

            var newArr = [];

            arr.forEach((temp) => {

                if (temp != id) {
                    newArr.push(temp);
                }
            });
            this.setState({ selected_affiliation_ids: newArr });
        } else {
            arr.push(id);
            this.setState({ selected_affiliation_ids: arr });
        }
       
    }
   
    componentDidMount() {

       // if (this.state.isHCP) {

            Axios(`/api/fpm/get-affiliations`, ({
                mdm_id: this.props.mdm_id,
                is_hcp: false
            }), (data) => {


                var affiliation_array = [];

                data.forEach((temp) => {
                    affiliation_array.push(<div ><input className={Styles.check1} type="Checkbox" id={temp.PROF_ID} onChange={(event) => {
                        if (event.target.checked) {
                            this.setAffiliation(event.target.id, false);
                        }
                        else {
                            this.setAffiliation(event.target.id, true);
                        }
                    }}  /> <b>{temp.NAME}</b><i>,  {temp.DEGREE} ({temp.SPECIALTY}) </i></div>)

                    });

                this.setState({ affiliations: affiliation_array})


            })

        let params = {
           
            mdm_id: this.props.mdm_id,
            is_hcp: false
        }
        Axios('/api/fpm/get-alignment', params, (data) => {


            var alignString = data[0].TERRITORY;
            this.setState({ alignment: alignString })


            }, (error) => console.error(error));


       // }
        

    }
    allFieldsAreValid() {

        //this routine validates on submit click

        var dtval = this.state.dtvalue;

        var discussion = this.state.discussionTopic;
        var contact = this.state.contactMethod;



        if (dtval == '' || dtval == undefined || dtval == null ) {

            this.setState({ err: 'Call Date is required' });
            return false;

        } 
       
        if (discussion == '') {

            this.setState({ err: 'Discussion Topic is required' });
            return false;

        }
        if (contact == '') {

            this.setState({ err: 'Contact Method is required' });
            return false;

        }

        //ensure at least one contact is selected or entered
        var atLeastOne = false;
        var arrTemp = this.state.selected_affiliation_ids;
        if (arrTemp.length > 0) {
            atLeastOne = true;
        }

        var arrCts = this.state.contacts;
        if (arrCts.length > 0) {
            atLeastOne = true;
        }

        if (!atLeastOne) {
            this.setState({ err: 'At least one attendee is required' });
            return false;
        }
        this.setState({ err: '' });
        return true;
    }
    validateFields() {

        //this roputive validates contact fields only 

        var cname = this.state.contactname;
        var ctitle = this.state.contacttitle;
        var cphone = this.state.contactphone;
        var cemail = this.state.contactmail;

        if (cname == '') {
           
            this.setState({err:'Contact Name is required'});
            return false;
        }

        var isDup = false;

        var arr = this.state.contact_arr;
        arr.forEach((temp) => {

            if (temp.NAME.toUpperCase() === cname.toUpperCase()) {

                isDup = true;
               
            }

        });

        if (isDup) {
            this.setState({ err: 'Contact already exists.' });
            return false;
        }


        if (ctitle == '') {
          
            //this.setState({ err: 'Contact Title is required' });
            //return false;

        }
        if (cphone != '') {

         

            if (!this.isValidPhone(cphone)) {
                this.setState({ err: 'Contact Phone is not in the required format' });
                return false;
            }
           

        }
        if (cemail != '' && !this.isValidEmail(cemail)) {
           
            this.setState({ err: 'Contact Email is not in the required format' });
            return false;

        }


       

        this.setState({ err: '' });
        return true;

    }
    isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }
    isValidPhone(phone) {

       
       // alert(phone.length);
        if (phone.length == 16) {
            return true;
        }



        return false;
    }
    formatPhoneNumber(input) {
    // Strip all characters from the input except digits
    input = input.replace(/\D/g, '');

    // Trim the remaining input to ten characters, to preserve phone number format
    input = input.substring(0, 10);

    // Based upon the length of the string, we add formatting as necessary
    var size = input.length;
    if (size == 0) {
        input = input;
    } else if (size < 4) {
        input = '(' + input;
    } else if (size < 7) {
        input = '(' + input.substring(0, 3) + ') ' + input.substring(3, 6);
    } else {
        input = '(' + input.substring(0, 3) + ') ' + input.substring(3, 6) + ' - ' + input.substring(6, 10);
    }
    return input;
    }
    removeContact(name) {


        var submitArr = this.state.contact_arr;
       
        //var nm = this.state.contactname;

        var newArr = [];
        var newSubmitArr = [];


        submitArr.forEach((temp) => {


            if (temp.NAME === name) {

                //remove it

            } else {

                let ctx = {
                    NAME: temp.NAME,
                    TITLE: temp.TITLE,
                    PHONE: temp.PHONE,
                    EMAIL: temp.EMAIL
                };


                newSubmitArr.push(ctx);

                newArr.push(<div className={this.state.contact_is_odd ? [Styles.odd, Styles.contactRow].join(" ") : [Styles.even, Styles.contactRow].join(" ")}>

                    <div className={Styles.contactName} >{temp.NAME}</div>
                    <div className={Styles.contactTitle} >{temp.TITLE}</div>
                    <div className={Styles.contactPhone} >{temp.PHONE}</div>
                    <div className={Styles.contactEmail} >{temp.EMAIL}</div>
                    <div className={Styles.searchrowadd} onClick={() => {

                        this.removeContact(temp.NAME);
                    }} ><i className={Styles.remove} >Remove</i></div>
                </div>);
            }

        });

        this.setState({ contact_arr: newSubmitArr, contacts: newArr })
    }
    addContact(data) {

        if (data) {

            var fieldsValid = this.validateFields();       
            var arr = this.state.contacts;
            var submitArr = this.state.contact_arr;
            var name = data.NAME;

            if (fieldsValid === true) {
          
                arr.push(<div className={this.state.contact_is_odd ? [Styles.odd, Styles.contactRow].join(" ") :  [Styles.even, Styles.contactRow].join(" ")}>

                    <div className={Styles.contactName} >{this.state.contactname}</div>
                    <div className={Styles.contactTitle} >{this.state.contacttitle}</div>
                    <div className={Styles.contactPhone} >{this.state.contactphone}</div>
                    <div className={Styles.contactEmail} >{this.state.contactmail}</div>
                    <div className={Styles.searchrowadd}  ><i className={Styles.remove} onClick={() => {

                        this.removeContact(name);
                    }}>Remove</i></div>
                </div>);


                let ctx = {
                    NAME: this.state.contactname,
                    TITLE: this.state.contacttitle,
                    PHONE: this.state.contactphone,
                    EMAIL: this.state.contactmail
                };


                submitArr.push(ctx);
                this.setState({ contact_arr: submitArr,contacts: arr, contactname: '', contacttitle: '', contactphone: '', contactmail: '', contact_is_odd: !this.state.contact_is_odd})
            }
        }

    }
    render() {

        var sixtyDaysAgo = new Date(new Date().setDate(new Date().getDate() - 60));
        
        return (

            <div className={Styles.editScreen}>
                <div className={Styles.title}>
                    <div></div>
                    <div className={Styles.TitleText}>Call Capture - {this.state.orgname}</div>
                    <div
                        className={Styles.close}>
                    </div>
                </div>
                <div className={Styles.body}>
                    <div className={Styles.formdiv}>
                        <div className={Styles.formtitle}>ACCOUNT:</div>
                        <div className={Styles.formcontrol}> <b>{this.state.orgname} </b></div>
                    </div>
                    <div className={Styles.formdiv}>
                        <div className={Styles.formtitle}>TERRITORY:</div>
                        <div className={Styles.formcontrol}> <b>{this.state.alignment} </b></div>
                    </div>
                    <div className={Styles.formdiv}>
                        <div className={Styles.formtitle}>CALL DATE</div>
                        <div className={Styles.formcontrol}>

                            <DatePicker minDate={sixtyDaysAgo} maxDate={new Date()} className={[Styles.reactdatetime, Styles.Wiggle].join(" ")} className={this.state.dtvalue == '' ? [Styles.reactdatetime, Styles.Wiggle].join(" ") : [Styles.reactdatetime, Styles.formcontrol].join(" ")} selected={this.state.dtvalue} onChange={(event) => {

                                this.setState({ dtvalue: event });
                            }}  />

                        </div>
                    </div>
                    <div className={Styles.formdiv}>
                        <div className={Styles.formtitle}>CONTACT METHOD:</div>
                        <div className={Styles.formcontrol}><select className={this.state.contactMethod == '' ? Styles.Wiggle : Styles.formcontrol} id="contactMethod" onChange={(event) => {

                          

                            this.setState({ contactMethod: event.target.value })
                        }}><option value=""></option><option value="In Person">In Person</option>  <option value="Remote">Remote</option></select></div>
                    </div>

                    <div className={Styles.formdiv}>
                        <div className={Styles.formtitle}>DISCUSSION TOPIC:</div>
                        <div className={Styles.formcontrol}>
                            <select id="discussionTopic" className={this.state.discussionTopic == '' ? Styles.Wiggle : Styles.formcontrol} onChange={(event) => {
                              //  alert(event.target.value);
                                this.setState({ discussionTopic: event.target.value })

                            }}>
                                <option value=""></option>
                                <option value="Payer Information">Payer Information</option>
                                <option value="Fullfillment Process">Fullfillment Process</option>
                                <option value="PA Process">PA Process</option>
                                <option value="Copay Cards">Copay Cards</option>
                                <option value="Contracted Pharmacies">Contracted Pharmacies</option>
                                <option value="Patient Support Services">Patient Support Services</option>
                                <option value="Other">Other</option>
                            </select></div>
                    </div>


                                 
                    <hr />
                    <div className={Styles.formdiv}>
                        <div className={Styles.formtitle}>SELECT CALL ATTENDEES:</div>
                        <div className={Styles.formcontrol}> <div className={Styles.afflist}>

                            {this.state.affiliations}

                        </div></div>
                    </div>
                    

                    <hr />

                    <div className={Styles.formdiv}>
                       

                        <div className={Styles.formcontrol}>

                           {/* <div className={Styles.formtitle2}>CONTACTS</div>*/}
                            <div >

                                <div className={Styles.searchbar}>
                                    
                                    <div className={Styles.searchrowname}>Name</div>
                                    <div className={Styles.searchrowtitle}>Title</div>
                                    <div className={Styles.searchrowphone}>Phone</div>
                                    <div className={Styles.searchrowmail}>Email</div>
                                    <div className={Styles.searchrowadd}></div>

                                </div>
                                <div className={Styles.searchbar}>


                                    <div className={Styles.searchrowname}>   <input type="text" className={[Styles.contactcontrol, Styles.Wiggle].join(" ")} onChange={(event) => {
                                        this.setState({ contactname: event.target.value })
                                    }} value={this.state.contactname} />
</div>
                                    <div className={Styles.searchrowtitle}> <input type="text" className={true ? Styles.contactcontrol : Styles.errorInputForm} onChange={(event) => {
                                        this.setState({ contacttitle: event.target.value })
                                    }} value={this.state.contacttitle} /></div>
                                    <div className={Styles.searchrowphone}> <input type="text"  className={true ? Styles.contactcontrol : Styles.errorInputForm} onChange={(event) => {


                                        this.setState({ contactphone: this.formatPhoneNumber(event.target.value) })

                                    }} value={this.state.contactphone} placeholder={"(xxx) xxx-xxxx"} /></div>




                                    <div className={Styles.searchrowmail}> <input type="email" className={true ? Styles.contactcontrol : Styles.errorInputForm} onChange={(event) => {
                                        this.setState({ contactmail: event.target.value })
                                    }} value={this.state.contactmail} /></div>
                                    {/*<div className={Styles.searchrowadd}><input type="button" value="ADD CONTACT" className={Styles.contactaddbut} /></div>*/}
                                    <div className={Styles.searchrowadd}><div className={Styles.contactaddbut} onClick={() => {


                                        let temp = {
                                            NAME: this.state.contactname,
                                            TITLE: this.state.contacttitle,
                                            PHONE: this.state.contactphone,
                                            EMAIL: this.state.contactmail
                                        };



                                        this.addContact(temp);
                                    }}>ADD ATTENDEE</div></div>
                                </div>

                            </div>

                            <div className={Styles.contactlist}>

                                { this.state.contacts }
                              

                            </div>
                           
                        </div>
                       
                    </div>
                    
                  
                    <div className={Styles.error}>{this.state.err}</div>
                </div>



                <div className={Styles.buttonRow}>
                    <div id={Styles.submit} onClick={() => {
                       this.props.close();
                    }}>CANCEL</div>
                    <div id={this.state.IsSummaryScreen || this.state.nextScreenIndex == this.state.currentScreenIndex ? Styles.submit : Styles.disable} onClick={() => {

             
                        if (this.allFieldsAreValid()) {

                            var arrTemp = this.state.selected_affiliation_ids;
                            var retString = "";

                            arrTemp.forEach((temp) => {
                                retString = retString + "," + temp;
                            });

                            let temp = {
                                CALL_DATE:this.state.dtvalue,
                                CONTACT_METHOD: this.state.contactMethod,
                                DISCUSSION_TOPIC: this.state.discussionTopic,
                                CONTACTS: this.state.contact_arr,
                                AFFILIATIONS: retString,
                                ALIGNMENT: this.state.alignment,
                                ORG_ID: this.state.mdm_id,
                                USER_NAME: this.state.user_name,
                                TERRITORY: this.state.terrname,
                                TERRID: this.state.terrid
                            };


                            this.props.submit(temp);

                        }
                        
                        
                    }}>SUBMIT</div>
                </div>
            </div>
        )
    }

}
export default NewCallCaptureDialog;







