import React from 'react';
import Styles from '../css/DialogBoxAddUserConfirmation.module.css';

export default function DialogBoxAddUserConfirmation(props) {
  return (
    <div className={Styles.editScreen}>
      <div className={Styles.title}>
        <div></div>
        <div className={Styles.TitleText}>Create User</div>
        <div
          className={Styles.close}
          onClick={() => {
            props.changeAdminView(-1);
          }}>
          <i className='fas fa-times'></i>
        </div>
      </div>
      <div className={Styles.body}>
        <div id={Styles.SuccessIcon}>
          <i className='far fa-check-circle'></i>
        </div>
        <div className={Styles.TextBox}>
          An account has been created for:
          <br />
          <div className={Styles.BoldText}>
            {props.confirmationData.FIRST_NAME} {props.confirmationData.LAST_NAME}
          </div>
          {props.confirmationData.EMAIL}
        </div>
      </div>
    </div>
  );
}
