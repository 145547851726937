import React, {Component} from 'react';
import styles from '../css/Details_Edit_Contact.module.css';
import validation from '../../../globalcomponents/validation';
import { buildBatchedRequests } from '../../../helper/handleBatchedRequests';
import validateRequestNotes from '../../../helper/validateRequestNotes';

class Details_Edit_Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            EMAIL: this.props.data.EMAIL,
            TWITTER: this.props.data.TWITTER,
            PHONE: this.props.data.PHONE,
            FAX: this.props.data.FAX,
            WEBSITE: this.props.data.WEBSITE,
            PREV_EMAIL: this.props.data.EMAIL,
            PREV_TWITTER: this.props.data.TWITTER,
            PREV_PHONE: this.props.data.PHONE,
            PREV_FAX: this.props.data.FAX,
            PREV_WEBSITE: this.props.data.WEBSITE,
            REQUEST_NOTE: "",
            ISVALID_EMAIL: true,
            ISVALID_TWITTER: true,
            ISVALID_WEBSITE: true,
            ISVALID_PHONE: true,
            ISVALID_FAX: true,
            ISVALID_REQUEST_NOTE: true,
            changed: {},
            ERROR_MESSAGE: ''
        };
    }

    firstCapital(str) {
        if (str !== undefined) {
            return str.toLowerCase().replace(/\w\S*/g, function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            })
        }
        return "";
    }

    render() {
        return (
        <div>
            <div className={styles.title}>
                <div></div>
                <div className={styles.TitleText}>Edit Contact</div>
                <div className={styles.close} onClick={() => {
                    this.props.close()
                }}><i className="fas fa-times"></i></div>
            </div>
            <div className={styles.body}>
                <div className={styles.required}>{this.state.ERROR_MESSAGE}</div>
                <div className={styles.toprow}>
                    <div className={styles.set}>
                        <div className={styles.inputTitle}>EMAIL</div>
                        <input className={this.state.ISVALID_EMAIL
                                ? styles.DefaultInputForm
                                : styles.ErrorInputForm} value={this.state.EMAIL !== "-1"
                                ? this.state.EMAIL
                                : "-1"} onChange={(event) => {
                                let temp = this.state.changed;
                                if (this.state.PREV_EMAIL !== event.target.value) {
                                    if (event.target.value === "-1") {
                                        temp.EMAIL = "";
                                    } else {
                                        temp.EMAIL = event.target.value;
                                    }
                                    this.setState({changed: temp});
                                } else {
                                    delete temp.EMAIL;
                                    this.setState({changed: temp});
                                }
                                if (event.target.value === "-1") {
                                    this.setState({EMAIL: ""})
                                } else {
                                    this.setState({EMAIL: event.target.value})
                                }
                            }}/>
                        </div>
                </div>
                <div className={styles.middlerow}>
                    <div className={styles.set}>
                        <div className={styles.inputTitle}>PHONE</div>
                        <input className={this.state.ISVALID_PHONE
                                ? styles.DefaultInputForm
                                : styles.ErrorInputForm} value={this.state.PHONE !== "-1"
                                ? this.state.PHONE
                                : "-1"} onChange={(event) => {
                                let temp = this.state.changed;
                                if (this.state.PREV_PHONE !== event.target.value) {
                                    if (event.target.value === "-1") {
                                        temp.PHONE = "";
                                    } else {
                                        temp.PHONE = event.target.value;
                                    }
                                    this.setState({changed: temp});
                                } else {
                                    delete temp.PHONE;
                                    this.setState({changed: temp});
                                }
                                if (event.target.value === "-1") {
                                    this.setState({PHONE: ""})
                                } else {
                                    this.setState({PHONE: event.target.value})
                                }
                            }} placeholder={"xxx-xxx-xxxx"}/>
                    </div>
                    <div className={styles.set}>
                        <div className={styles.inputTitle}>FAX</div>
                        <input className={this.state.ISVALID_FAX
                                ? styles.DefaultInputForm
                                : styles.ErrorInputForm} value={this.state.FAX !== "-1"
                                ? this.state.FAX
                                : "-1"} onChange={(event) => {
                                let temp = this.state.changed;
                                if (this.state.PREV_FAX !== event.target.value) {
                                    if (event.target.value === "-1") {
                                        temp.FAX = "";
                                    } else {
                                        temp.FAX = event.target.value;
                                    }
                                    this.setState({changed: temp});
                                } else {
                                    delete temp.FAX;
                                    this.setState({changed: temp});
                                }
                                if (event.target.value === "-1") {
                                    this.setState({FAX: ""})
                                } else {
                                    this.setState({FAX: event.target.value})
                                }
                            }} placeholder={"xxx-xxx-xxxx"}/>
                        </div>
                        <div className={styles.set}>
                            <div className={styles.inputTitle}><i className="fab fa-twitter"></i> &nbsp; TWITTER</div>
                            <input className={this.state.ISVALID_TWITTER
                                ? styles.DefaultInputForm
                                : styles.ErrorInputForm} value={this.state.TWITTER !== "-1"
                                    ? this.state.TWITTER
                                    : "-1"} onChange={(event) => {
                                        let temp = this.state.changed;
                                        if (this.state.PREV_TWITTER !== event.target.value) {
                                            if (event.target.value === "-1") {
                                                temp.TWITTER = "";
                                            } else {
                                                temp.TWITTER = event.target.value;
                                            }
                                            this.setState({ changed: temp });
                                        } else {
                                            delete temp.TWITTER;
                                            this.setState({ changed: temp });
                                        }
                                        if (event.target.value === "-1") {
                                            this.setState({ TWITTER: "" })
                                        } else {
                                            this.setState({ TWITTER: event.target.value })
                                        }
                                    }} placeholder={"@twitterHandle"}/>
                        </div>
                    <div className={styles.set}>
                        <div className={styles.inputTitle}>WEBSITE</div>
                        <input className={this.state.ISVALID_WEBSITE
                                ? styles.DefaultInputForm
                                : styles.ErrorInputForm} value={this.state.WEBSITE !== "-1"
                                ? this.state.WEBSITE
                                : "-1"} onChange={(event) => {
                                let temp = this.state.changed;
                                if (this.state.PREV_WEBSITE !== event.target.value) {
                                    if (event.target.value === "-1") {
                                        temp.WEBSITE = "";
                                    } else {
                                        temp.WEBSITE = event.target.value;
                                    }
                                    this.setState({changed: temp});
                                } else {
                                    delete temp.WEBSITE;
                                    this.setState({changed: temp});
                                }
                                if (event.target.value === "-1") {
                                    this.setState({WEBSITE: ""})
                                } else {
                                    this.setState({WEBSITE: event.target.value})
                                }
                            }}/>
                    </div>

                </div>

                <div className={styles.set}>
                    <div className={styles.inputTitle}>REVISION REASON</div>
                    <input className={this.state.ISVALID_REQUEST_NOTE
                            ? styles.DefaultInputForm
                            : styles.ErrorInputForm} value={this.state.REQUEST_NOTE} onChange={(event) => {
                            let temp = this.state.changed;
                            if (this.state.REQUEST_NOTE !== event.target.value) {
                                if (event.target.value === "-1") {
                                    temp.REQUEST_NOTE = "";
                                } else {
                                    temp.REQUEST_NOTE = event.target.value;
                                }
                                this.setState({changed: temp});
                            } else {
                                delete temp.REQUEST_NOTE;
                                this.setState({changed: temp});
                            }
                            if (event.target.value === "-1") {
                                this.setState({REQUEST_NOTE: ""})
                            } else {
                                this.setState({ REQUEST_NOTE: validateRequestNotes(event.target.value) });
                            }
                        }}/>
                </div>

                <div className={styles.buttonRow}>
                    <div id={styles.cancel} onClick={() => {
                            this.props.close();
                        }}>CANCEL</div>
                    <div className={this.state.Wiggle && this.state.Wiggle !== undefined
                            ? styles.Wiggle
                            : ""} id={styles.submit} onClick={() => {

                                validation.validateOrgContactForm(this.state.changed, this.state, (updatedState) => {
                                    this.setState(updatedState);
                                    if (Object.keys(this.state.changed).length > 0 && updatedState.ISVALID) {
                                        let changed = this.state.changed
                                        let requestData = {
                                            ORG_ID: this.props.data.ORG_ID,
                                            REQUESTOR_ID: this.props.user.email,
                                            REQUESTOR_NAME: this.props.user.userName,
                                            REQUESTOR_TEAM: this.props.user.team,
                                            REQUESTOR_TERR: this.props.user.terr,
                                            REQUEST_NOTE: this.state.REQUEST_NOTE
                                        }
                                        let batch = {
                                            ORG_ID: this.props.data.ORG_ID,
                                            REQUESTOR_ID: this.props.user.email,
                                            REQUESTOR_NAME: this.props.user.userName,
                                            REQUESTOR_TEAM: this.props.user.team,
                                            REQUESTOR_TERR: this.props.user.terr,
                                            REQUEST_TYPE: 'HCO Update Info'
                                        }
                                        buildBatchedRequests(requestData, changed, batch, this.props.submit.bind(this), "HCO")
                                    } else {
                                        let errorMessages = ''
                                        if (!updatedState.ISVALID_EMAIL) {
                                            errorMessages += 'Please enter a valid email address. A valid email address must contain an \'@\' symbol.\n'
                                        }
                                        if (!updatedState.ISVALID_TWITTER) {
                                            errorMessages += 'Please enter a valid twitter handle. A valid twitter handle must begin with an \'@\' symbol.\n'
                                        }
                                        if (!updatedState.ISVALID_PHONE) {
                                            errorMessages += 'Please enter a valid mobile number. A valid mobile number must contain only digits and follow this format: xxx-xxx-xxxx.\n'
                                        }
                                        if (!updatedState.ISVALID_FAX) {
                                            errorMessages += 'Please enter a valid fax number.\n'
                                        }
                                        if (!updatedState.ISVALID_WEBSITE) {
                                            errorMessages += 'Please enter a valid website.\n'
                                        }
                                        this.setState({
                                            Wiggle: true,
                                            ISVALID_EMAIL: updatedState.ISVALID_EMAIL,
                                            ISVALID_TWITTER: updatedState.ISVALID_TWITTER,
                                            ISVALID_PHONE: updatedState.ISVALID_PHONE,
                                            ISVALID_FAX: updatedState.ISVALID_FAX,
                                            ISVALID_WEBSITE: updatedState.ISVALID_WEBSITE,
                                            ISVALID_REQUEST_NOTE: updatedState.ISVALID_REQUEST_NOTE,
                                            ERROR_MESSAGE: errorMessages
                                        })
                                        setTimeout(() => {
                                            this.setState({ Wiggle: false })
                                        }, 500);
                                    }

                                })

                        }}>SUBMIT</div>
                </div>

            </div>
        </div>)
    }

}

export default Details_Edit_Contact;
