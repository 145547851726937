import React, {useState, useEffect} from 'react';
import {Switch, Route, useRouteMatch} from "react-router-dom";
import Search from './Search/Search'
import Admin from './Admin/Admin'
import Spinner from '../../components/Spinner'
import Axios from '../../components/Axios'

function Targeting(props) {
    let {path, url} = useRouteMatch()

    const [loaded, setLoaded] = useState(false)
    const [approved, setApproved] = useState(false)
    const [backUrl, setBackUrl] = useState("/")

    useEffect(() => {
        if (props.exercise) {
            setLoaded(true)
        }
    }, [props.exercise])

    useEffect(() => {
        if (loaded && props.user.role !== "ADMIN" && (props.user.role !== 'DBD' && props.user.team !== 31)) {
            Axios('/api/targeting/approval-check', {ID: props.exercise.ID}, (data) => {
                setApproved(data.length > 0)
            });
        }
    }, [loaded])

    if (loaded) {
        if (["ADMIN"].includes(props.user.role) || (props.user.role == 'DBD' && props.user.team == 31)) {
            return (<Switch>
                <Route path={`${path}/search/:idSlug/:terrSlug`}>
                    <Search url={backUrl} backUrl={backUrl} setBackUrl={setBackUrl} approved={approved} setApproved={setApproved} {...props} />
                </Route>
                <Route path={`${path}/admin/:idSlug`}>
                    <Admin path={`${path}/admin/:idSlug`} approved={approved} setApproved={setApproved} {...props} />
                </Route>
            </ Switch>)
        }
        else if (["ABD", "ASD", "OAS", "OBD", "VP", "DBD"].includes(props.user.role)) {
            return (<Switch>
                <Route path={`${path}/search/:idSlug/:terrSlug`}>
                    <Search url={url} approved={approved} {...props}/>
                </Route>
                <Route exact path={`${path}/`}>
                    <Admin path={`${path}`} approved={approved} setApproved={setApproved} {...props}/>
                </Route>
            </ Switch>)
        } else {
            return (<Search url={url} approved={approved} {...props}/>)
        }
    }
    return (<Spinner/>)
}

export default Targeting
