import React, {Component} from 'react';
import styles from './css/RegionalPlan.module.css';
import statusStyles from './css/Status.module.css';
import AdminResult from './components/AdminResult';
import Axios from './../../components/Axios';
import RegionalPlanView from './components/RegionalPlanView';
import EditResult from './components/EditResult';
import EditStatus from './components/EditStatus';

import StatusRecord from './components/StatusRecord';
import Popup from '../../globalcomponents/Popup';
import Loading from './../../globalcomponents/Loading';
import AdminRegionalUpdate from './components/AdminRegionalUpdate';
import AdminRegionalUpdateConfirmation from './components/AdminRegionalUpdateConfirmation';
import {Redirect} from 'react-router-dom';

class RegionalPlan extends Component {
    constructor(props) {
        super(props)
        this.state = {
            view: 1,
            approveID: undefined,
            spinning: true, 
            stateOptions: [],
            editGuide: false,
            statusDescRecords: [],
            refresh: false,
            gstatusid: 1
        };
        this.setEditGuide = this.setEditGuide.bind(this);
    
     
    }

    

    componentDidMount() {
        let temp = {}
        temp.id = this.props.match.params.RPID
        if (isNaN(temp.id)) {
            temp.id = this.props.rp.REGIONAL_PLAN_ID
        }

        Axios('/api/regional-plan/get-statuses', {}, (data) => {
            this.setState({ stateOptions: data })

            for (let i = 0; i < this.state.stateOptions.length; i++) {
                this.state.statusDescRecords.push(<StatusRecord key={this.state.stateOptions[i].STATUS_ID} data={this.state.stateOptions[i]} odd={i % 2 === 0} />)
            }
        })


        Axios('/api/regional-plan/admin', temp, (data) => {
            if (data.length === 0) {
                temp.id = this.props.rp.REGIONAL_PLAN_ID
                Axios('/api/regional-plan/admin', temp, (data) => {
                    if (["ADMIN", "DIR"].includes(this.props.user.role) || (["MSL"].includes(this.props.user.role) && this.props.user.terr.length === 5)) {
                        this.setState({
                            view: 0,
                            loaded: true,
                            data: data,
                            gstatusid: data[0].STATUS_ID,
                            spinning: false,
                            RPID: data[0].REGIONAL_PLAN_ID,
                            redirect: false,
                            statusDescRecords: []
                        })
                    }
                    else {
                        this.setState({
                            view: 1,
                            loaded: true,
                            data: data,
                            spinning: false,
                            gstatusid: data[0].STATUS_ID,
                            RPID: data[0].REGIONAL_PLAN_ID,
                            redirect: false,
                            statusDescRecords: []
                        })
                    }
                    this.props.history.push(`/regionalplan/${this.props.rp.REGIONAL_PLAN_ID}`)
                })
            }
            else {
                if (["ADMIN", "DIR"].includes(this.props.user.role) || (["MSL"].includes(this.props.user.role) && this.props.user.terr.length === 5)) {
                    this.setState({
                        view: 0,
                        loaded: true,
                        data: data,
                        spinning: false,
                        gstatusid: data[0].STATUS_ID,
                        RPID: data[0].REGIONAL_PLAN_ID,
                        redirect: false
                    })
                }
                else {
                    this.setState({
                        view: 1,
                        loaded: true,
                        data: data,
                        spinning: false,
                        gstatusid: data[0].STATUS_ID,
                        RPID: data[0].REGIONAL_PLAN_ID,
                        redirect: false
                    })
                }
            }
        })
    }

    close(type) {
        this.setState({[type]: null})
    }

    setViewOn(type) {
        this.setState({[type]: true})
    }

    setViewOff(type) {
        this.setState({[type]: false})
    }

    backToAdmin() {
        this.setState({
            view: 0
        })
    }

    setData(data) {
        this.setState({
            results: data
        })
    }

    setRecord(data, index) {
        let temp = this.state.results
        if (index !== undefined) {
            temp[index] = data
        }
        this.setState({
            newRecord: {
                added: temp[index].TOG === 'Y'
            },
            results: temp,
            edit: false
        })
    }

    changeView(data, username) {
        for (let i = 0; i < this.state.data.length; i++) {
            if (this.state.data[i].EMP_ID === data.EMP_ID) {
                this.setState({
                    view: 1,
                    user: {
                        id: data.EMP_ID,
                        terr: data.TERR_ID
                    },
                    approveID: i,

                    adminRes: this.state.data
                })
            }
        }
    }

    approve(id, username, callback) {
        if (username !== undefined) {
            for (let i = 0; i < this.state.data.length; i++) {
                if (this.state.data[i].EMP_ID === username) {
                    this.setState({
                        approve: true,
                        approveID: i,
                        editcallback: callback,
                        gstatusid:5
                    })
                }
            }
        }
        else {
            this.setState({
                approve: true,
                approveID: id
            })
        }
       
    }

    reject(id, username, callback) {
        if (username !== undefined) {
            for (let i = 0; i < this.state.data.length; i++) {
                if (this.state.data[i].EMP_ID === username) {
                    this.setState({
                        reject: true,
                        approveID: i,
                        gstatusid: 4
                    })
                }
            }
        }
        else {
            this.setState({
                reject: true,
                approveID: id
            })
        }
       
    }

    showEdit(data, index) {
        this.setState({
            edit: true,
            editData: data,
            editDataIndex: index
        })
    }

    closeEdit() {
        this.setState({
            edit: false
        })
    }
    setEditGuide(data) {
        
        this.setState({
            editGuide: data
        })
      
    }
    refreshStatusData(data) {


        let arr = []
        let dataArr = []
        dataArr = data

        for (let i = 0; i < dataArr.length; i++) {
            arr.push(<StatusRecord key={dataArr[i].STATUS_ID} data={dataArr[i]} odd={i % 2 === 0} />)
        }

        this.setState({

            statusDescRecords: arr
        })

    }

    refreshStatusAfterChanged(data) {

        this.setState({
            data: data
        })
        

    }

    sendEmail(index, username, team, email, terr) {

        let temp = {
            statusID: index
        }

        Axios('/api/regional-plan/get-status-by-id', temp, (status) => {

            let json = {}
            json.REQUEST_TYPE = "IMP RPEx Submit";
            json.EXERCISE_ID = this.state.current
            json.EXERCISE_TERR = this.props.user.terr
            json.STATUS_ID = index
            json.STATUS_NAME = status[0].STATUS
            json.STATUS_DESCRIPTION = status[0].DESCRIPTION
            json.REQUESTOR_ID = email //this.props.user.email
            json.REQUESTOR_NAME = username //this.props.user.userName
            json.REQUESTOR_TEAM = team //this.props.user.team
            json.REQUESTOR_TERR = terr

            Axios('/api/global/change-request', json, (data) => {

            })
        })
    }


    render() {
        const { editGuide} = this.state;
       
        let results = []
        if (this.state.loaded) {
            if (["ADMIN", "DIR"].includes(this.props.user.role)) {
                for (let i = 0; i < this.state.data.length; i++) {
                    results.push(<AdminResult key={i} refreshStatusAfterChanged={this.refreshStatusAfterChanged.bind(this)}  user={this.props.user} admin={true} approve={this.approve.bind(this)} reject={this.reject.bind(this)} changeView={this.changeView.bind(this)} data={this.state.data[i]} statusOptions={this.state.stateOptions} number={i}/>)
                }
            }
            else if (["MSL"].includes(this.props.user.role) && this.props.user.terr.length === 5) {
                let j = 0;
                for (let i = 0; i < this.state.data.length; i++) {
                    if (this.state.data[i].TERR_ID.startsWith(this.props.user.terr)) {
                        results.push(<AdminResult key={i} user={this.props.user}  admin={false} approve={this.approve.bind(this)} reject={this.reject.bind(this)} changeView={this.changeView.bind(this)} data={this.state.data[i]} statusOptions={this.state.stateOptions} number={j}/>);
                        j = j + 1;
                    }
                }
            }
        }

        let dir = (
        <div>
            <div className={styles.topBar}>{this.state.data ? this.state.data[0].NAME : ""}</div>
            {["ADMIN"].includes(this.props.user.role) ? (<div className={styles.topButton}>
                <div className={styles.addUser} onClick={() => {this.setState({update: true})}}>EXECUTE EXERCISE UPDATES</div>
            </div>) : ''}

            <div className={styles.MainView}>
                <div className={styles.exercises}>
                    <div className={styles.blueBar}>Regional Plan Exercises</div>
                    <div className={styles.titles}>
                        <div className={styles.long}>TERRITORY</div>
                        <div className={styles.long}>MSL NAME</div>
                        <div className={styles.long}>REGIONAL PLAN COUNT</div>
                        <div className={styles.short}>STATUS</div>
                        <div className={styles.middle}>ACTIONS</div>
                    </div>
                    <div className={styles.exerciseContent}>
                        {results}
                    </div>
                </div>
                    {editGuide ? <EditStatus refreshStatusData={this.refreshStatusData.bind(this)}  endpoints={this.props.endpoints} statusData={this.state.stateOptions} setStatus={this.setStatusData} success close={() => { this.setEditGuide(false) }} /> : ""}


                <div className={statusStyles.statusWrapper}>
                    <div className={statusStyles.sectionTitleBar}>
                        <div className={statusStyles.sectionTitle}>Guide</div>
                        {
                            this.props.user.role === "ADMIN"
                                    ? (<div className={"icon"} onClick={() => {
                                      
                                    this.setEditGuide(true)
                                }}>
                                    <i className="far fa-edit"></i>
                                </div>)
                                : ""
                        }
                    </div>
                    <div className={statusStyles.results}>
                        <div className={statusStyles.contentTitles}>
                            <div className={[statusStyles.col, statusStyles.short].join(' ')}>STATUS</div>
                            <div className={[statusStyles.col, statusStyles.long].join(' ')}>DESCRIPTION</div>
                        </div>
                        {
                            this.state.statusDescRecords
                        }
                    </div>
                </div>


            </div>
            </div>

        )



        let nonDirIndex = 0;
        if (this.state.data !== undefined) {
            if (this.state.approveID !== undefined) {
                nonDirIndex = this.state.approveID
            }
            else {
                for (let i = 0; i < this.state.data.length; i++) {
                    if (parseInt(this.state.data[i].EMP_ID) === parseInt(this.props.user.empID)) {
                        nonDirIndex = i
                    }
                }
            }
        }
        let statusTemp = this.state.data !== undefined ? this.state.data[nonDirIndex].STATUS : ""

        if (statusTemp == "" && this.state.status !== undefined) {
            statusTemp = this.state.status   
        }
        if (statusTemp == "" && this.props.status !== undefined) {
            statusTemp = this.props.status
        }
        let nonDir = <RegionalPlanView
            setData={this.setData.bind(this)}
            backToAdmin={this.backToAdmin.bind(this)}
            data={this.state.results}
            showEdit={this.showEdit.bind(this)}
            approve={this.approve.bind(this)}
            reject={this.reject.bind(this)}
            RPID={this.state.data !== undefined ? this.state.data[0].REGIONAL_PLAN_ID : ""}
            name={this.state.data !== undefined ? this.state.data[0].NAME : ""}
            status={statusTemp}
            statusid={this.state.data !== undefined ? this.state.data[nonDirIndex].STATUS_ID : ""}
            user={this.state.user ? this.state.user : this.props.user}
            actualUser={this.props.user}
            update={this.state.newRecord}
            stateOptions={this.state.stateOptions}
            />
        let views = [dir, nonDir]
        let approve = ""
        if (this.state.approve !== undefined) {
            approve = (
                <div className={styles.editScreen}>
                    <div className={styles.wrapper}>
                        <div className={styles.title}>
                            <div></div>
                            <div className={styles.TitleText}>Approve Exercise</div>
                            <div className={styles.close} onClick={() => {
                                this.setState({
                                    approve: false
                                })
                            }}><i className="fas fa-times"></i></div>
                        </div>
                        <form>
                            <div className={styles.body}>
                                <div className={styles.errorMessage}>{this.state.ERROR_MESSAGE}</div>
                                <div className={styles.toprow}>
                                    <div className={styles.set}>
                                        <div className={styles.inputTitle}>Are you sure you want to approve</div>
                                        <div className={styles.subTitle}>{`${this.state.data[this.state.approveID].EMP_FIRST_NAME} ${this.state.data[this.state.approveID].EMP_LAST_NAME}`}</div>
                                    </div>
                                </div>
                                <div className={styles.buttonRow}>
                                    <div id={styles.cancel}
                                        onClick={() => {

                                            this.setState({
                                                approve: false
                                            })


                                        }
                                    }>CANCEL</div>
                                    <div type="submit"
                                        id={styles.submit}
                                        className={this.state.Wiggle && this.state.Wiggle !== undefined ? styles.Wiggle : ""}
                                        onClick={() => {

                                            let stat = this.state.data
                                            let temp = {
                                                REGIONAL_PLAN_ID: this.state.RPID,
                                                id: this.state.data[this.state.approveID].EMP_ID,
                                                status: stat[nonDirIndex].STATUS,
                                                statusid: stat[nonDirIndex].STATUS_ID
                                            }
                                            Axios('/api/regional-plan/approve', temp, (data) => {
                                                stat[nonDirIndex].STATUS = data[0].STATUS
                                                this.setState({
                                                    data: stat,
                                                    approve: false,
                                                    statusid: 5
                                                })
                                            })

                                            this.sendEmail(5, this.props.user.userName, this.props.user.team, this.props.user.email, this.state.data[this.state.approveID].TERR_ID)
                                        }
                                    }>APPROVE</div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )
        }

        let reject = ""
        if (this.state.reject !== undefined) {
            reject = (
                <div className={styles.editScreen}>
                    <div className={styles.wrapper}>
                        <div className={styles.title}>
                            <div></div>
                            <div className={styles.TitleText}>Reject Exercise</div>
                            <div className={styles.close} onClick={() => {
                                this.setState({
                                    reject: false
                                })
                            }}><i className="fas fa-times"></i></div>
                        </div>
                        <form>
                            <div className={styles.body}>
                                <div className={styles.errorMessage}>{this.state.ERROR_MESSAGE}</div>
                                <div className={styles.toprow}>
                                    <div className={styles.set}>
                                        <div className={styles.inputTitle}>Are you sure you want to reject</div>
                                        <div className={styles.subTitle}>{`${this.state.data[this.state.approveID].EMP_FIRST_NAME} ${this.state.data[this.state.approveID].EMP_LAST_NAME}`}</div>
                                    </div>
                                </div>
                                <div className={styles.buttonRow}>
                                    <div id={styles.cancel}
                                        onClick={() => {
                                            this.setState({
                                                reject: false
                                            })
                                        }
                                    }>CANCEL</div>
                                    <div type="submit"
                                        id={styles.submit}
                                        className={this.state.Wiggle && this.state.Wiggle !== undefined ? styles.Wiggle : ""}
                                        onClick={() => {
                                            let stat = this.state.data
                                            let temp = {
                                                REGIONAL_PLAN_ID: this.state.RPID,
                                                id: this.state.data[this.state.approveID].EMP_ID,
                                                status: stat[nonDirIndex].STATUS,
                                                statusid: stat[nonDirIndex].STATUS_ID
                                            }
                                            Axios('/api/regional-plan/reject', temp, (data) => {
                                                stat[nonDirIndex].STATUS = data[0].STATUS
                                                this.setState({
                                                    data: stat,
                                                    reject: false,
                                                    statusid: 4
                                                })
                                            })

                                            this.sendEmail(4, this.props.user.userName, this.props.user.team, this.props.user.email, stat[nonDirIndex].TERR_ID)
                                        }
                                    }>REJECT</div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )
        }

        let update = (<div className={styles.editScreen}>
                        <div className={styles.wrapper}>
                            <AdminRegionalUpdate {...this.props} id={this.props.match.params.RPID} close={this.close.bind(this)} setViewOn={this.setViewOn.bind(this)} setViewOff={this.setViewOff.bind(this)}/>
                        </div>
                     </div>)

        let confirm = (<div className={styles.editScreen}>
            <div className={styles.wrapper}>
                <AdminRegionalUpdateConfirmation close={this.close.bind(this)}/>
            </div>
        </div>)

        let edit = ""
        if (this.state.edit) {
            edit = <EditResult number={this.state.editDataIndex} data={this.state.editData} setData={this.setRecord.bind(this)} REGIONAL_PLAN_ID={this.state.data ? this.state.data[0].REGIONAL_PLAN_ID : ""} user={this.state.user ? this.state.user : this.props.user} close={this.closeEdit.bind(this)}/>
        }

        if (this.state.spinning) {
            return (
                <Loading />
            )
        }
        else {
            return (<div className={styles.RegionalPlan}>
                {this.state.approve ? <Popup component={approve} /> : ""}
                {this.state.reject ? <Popup component={reject} /> : ""}
                {this.state.edit ? <Popup component={edit} /> : ""}
                {this.state.update ? <Popup component={update} /> : ""}
                {this.state.confirm ? <Popup component={confirm} /> : ""}
                {views[this.state.view]}
           </div>);
        }
    }
}

export default RegionalPlan;
