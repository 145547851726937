import React, { Component } from 'react'
import Styles from './WorkflowDialogBox.module.css'
import options from './options.json';


class WorkflowDialogSummaryItem extends Component {
    constructor(props) {
        super(props);

    }


    render() {
        var name = this.props.name;

        var value = this.props.value;

        var dt = this.props.datatype;

        if (dt == 'bool') {

            if (value) {
                value = 'true';
            } else {
                value = 'false';
            }
        }

        return (
            <div id={name} key={name} className={Styles.formdiv}>
                <div className={Styles.summaryformtitle}>{name} </div>
                <div className={Styles.summaryformcontrol}>{value}</div>
            </div>
        )


    }

}
export default WorkflowDialogSummaryItem;







