import React, {Component} from 'react';
import AddHCOP1 from './AddHCO_P1'
import AddHCOP2 from './AddHCO_P2'
import AddHCOP3 from './AddHCO_P3'
import AddHCOSuccess from './AddHcoSuccess'
import Popup from '../../../globalcomponents/Popup';



class AddHCO extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 0,
            info: props.data,
            loaded: false
        };
    }

    componentDidMount() {
        this.setState({
            loaded: true
        })
    }

    nextPage(i, info) {
        if (info !== undefined) {
            let temp = this.state.info
            Object.assign(temp, info)
            this.setState({
                activePage: i,
                info: temp
            });
        }
        else {
            this.setState({
                activePage: i
            });
        }
    }

    render() {

        let page = [
            <AddHCOP1 user={this.props.user} info={this.state.info} nextPage={this.nextPage.bind(this)} close={this.props.close} />,
            <AddHCOP2 user={this.props.user} info={this.state.info} nextPage={this.nextPage.bind(this)} close={this.props.close} />,
            <AddHCOP3 user={this.props.user} info={this.state.info} type={this.state.type} submit={this.props.submit} nextPage={this.nextPage.bind(this)} close={this.props.close} />,
            <AddHCOSuccess close={this.props.close} />,
        ]

        return (
            <div>
                {this.state.loaded > 0 ? <Popup component={page[this.state.activePage]} /> : ""}
            </div>
        )
    }
}

export default AddHCO;
