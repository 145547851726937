import React, {Component} from 'react';
import styles from './Loading.module.css';

class Loading extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div className={styles.Loading}>
                <i className="fas fa-spinner fa-pulse"></i>
            </div>
        );
    }
}

export default Loading;
