import React from 'react';
import './Spinner.css';

export default (props) => {
    return (
        <div className='Spinner'>
            <i className="fas fa-spinner fa-pulse"></i>
        </div>
    );
}
