import React, {Component} from 'react';
import AddressLookUp from './../../../globalcomponents/AddressLookUp'

class EditAddress extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ADDRESS1: props.next.ADDRESS1,
            ADDRESS2: props.next.ADDRESS2,
            CITY: props.next.CITY,
            STATE: props.next.STATE,
            ZIPCODE: props.next.ZIPCODE,
            values: Object.values(props.next),
            keys: Object.keys(props.next),
            ISVALID: true
        };
    }

    updateAddress(address) {
        this.setState({
            ADDRESS1: address.streetOne,
            ADDRESS2: address.streetTwo,
            CITY: address.city,
            STATE: address.state,
            ZIPCODE: address.zip
        }, () => {
            this.props.setAddress(this.state);
        });

    }

    render() {
        return (<AddressLookUp updateAddress={this.updateAddress.bind(this)} error={this.state.ISVALID} info={this.state}/>);
    }
}

export default EditAddress;
