import React, {Component} from 'react';
import styles from '../css/AddHCO_P3.module.css';

class AddHCO_P3 extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div>
                <div className={styles.wrapper}>
                    <div className={styles.title}>
                        <div></div>
                        <div className={styles.TitleText}>Add Organization</div>
                        <div className={styles.close} onClick={() => {
                            this.props.close()
                        }}><i className="fas fa-times"></i></div>
                    </div>
                    <div className={styles.body}>
                        <div className={styles.toprow}>
                            <div className={[styles.set, styles.mainTitle].join(' ')}>
                                <div className={styles.titleText}>Please confirm the details:</div>
                            </div>
                        </div>

                        <div className={styles.middlerow}>
                            <div className={[styles.set, styles.odd].join(' ')}>
                                <div className={styles.contentTitle}>ORG NAME</div>
                                <div className={styles.content}>{this.props.info.ORG_NAME}</div>
                            </div>
                            <div className={styles.set}>
                                <div className={styles.contentTitle}>ORG TYPE</div>
                                <div className={styles.content}>{this.props.info.ORG_TYPE}</div>
                            </div>
                            <div className={[styles.set, styles.odd].join(' ')}>
                                <div className={styles.contentTitle}>PRIMARY ADDRESS</div>
                                <div className={styles.content}>{this.props.info.ADDRESS1} {this.props.info.ADDRESS2 !== null ?  this.props.info.ADDRESS2 : ''} {this.props.info.CITY}, {this.props.info.STATE}, {this.props.info.ZIPCODE}</div>
                            </div>
                            <div className={styles.set}>
                                <div className={styles.contentTitle}>ADD REASON</div>
                                <div className={styles.content}>{this.props.info.REQUEST_TYPE}</div>
                            </div>
                            <div className={[styles.set, styles.odd].join(' ')}>
                                <div className={styles.contentTitle}>COMMENTS</div>
                                <div className={styles.content}>{this.props.info.REQUEST_NOTE === -1 ? "" : this.props.info.REQUEST_NOTE}</div>
                            </div>
                        </div>

                        <div className={styles.buttonRow}>
                            <div id={styles.cancel} onClick={() => {
                                this.props.nextPage(1);
                            }}>BACK</div>
                            <div id={styles.submit} onClick={() => {
                                this.props.submit(this.props.info, this.props.info.ORIGINAL_REQUST_TYPE);
                                this.props.nextPage(3);
                            }}>SUBMIT</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default AddHCO_P3;
